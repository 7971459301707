import React from 'react';
import { Card, Icon } from 'antd';

import { utils } from 'Lib/utils';

const ValueCard = (props) =>
{
	const CustomTitle = `${props.titleSize}`;

	return (

		<Card className='valueCard'>
			<>
				<p>
					<Icon type='api' className='greyIcon'/>
					{props.title} {props.unit && <>({props.unit})</>}
				</p>
				<CustomTitle
					style={{ color: props.number === '' ? 'grey' : '' }}
				>
					{props.number === '' ? utils.translate('componentValueCard.noValue') : props.number}
				</CustomTitle>
				<i
					style={{ color: props.date ? '#63bd77' : '#da7a87' }}
				>
					<Icon
						type='calendar'
						className='greyIcon'
					/>
					{props.date || utils.translate('componentValueCard.noData')}
				</i>
			</>
		</Card>
	);
}


export default ValueCard;