import React, { Component } from 'react';
import { Form } from 'reactstrap';
import { Divider, Card, Tabs, Tooltip, Icon } from 'antd';
import moment from 'moment';

import DataSourceConfigure from './DataSourceConfigure/DataSourceConfigure';
import GroupingOptionsConfigure from './GroupingOptionsConfigure/GroupingOptionsConfigure';

import StyleSwitchSection from '../FormSections/StyleSwitchSection/StyleSwitchSection';
import DateRangeSection from '../FormSections/DateRangeSection/DateRangeSection';
import SwitchSection from '../FormSections/SwitchSection/SwitchSection';
import WidgetStyle from 'Components/Studio/Dashboard/Widgets/Style/WidgetStyle.js';

import { ChartWidgetModel } from 'Models/ChartWidgetModel.js';

import { utils } from 'Lib/utils.js';

import './Chart.css';

const { TabPane } = Tabs;

class Chart extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dateRange: this.props.widget.DWC_DATE_RANGE,
			currentDate: false,
			checked: this.props.widget.DWC_TYPE === 'area',
			csvChecked: this.props.widget.DWC_HAS_EXPORT === '1',
			stackingChecked: this.props.widget.DWC_HAS_STACKING === '1',
			multiAxisChecked: this.props.widget.DWC_HAS_MULTI_AXIS === '1',
			slidingDateChecked: this.props.widget.DWC_HAS_SLIDING_DATE === '1',
		};
	}

	componentDidMount() {
		if (
			!this.props.widget.DWC_DATE_STOP ||
			this.props.widget.DWC_DATE_STOP === '2100-12-31 00:00:00' ||
			this.props.widget.DWC_DATE_STOP === '0000-00-00 00:00:00'
		) {
			this.setState({ currentDate: true });
		}

		this.setState(
			{
				dateRange: this.props.widget.DWC_DATE_RANGE
					? this.props.widget.DWC_DATE_RANGE
					: 'custom',
				csvChecked:
					!this.props.widget.DWC_HAS_EXPORT ||
					this.props.widget.DWC_HAS_EXPORT === '1',
				stackingChecked:
					!this.props.widget.DWC_HAS_STACKING === '0' ||
					this.props.widget.DWC_HAS_STACKING === '1',
				multiAxisChecked:
					!this.props.widget.DWC_HAS_MULTI_AXIS === '0' ||
					this.props.widget.DWC_HAS_MULTI_AXIS === '1',
				slidingDateChecked:
					!this.props.widget.DWC_HAS_SLIDING_DATE === '0' ||
					this.props.widget.DWC_HAS_SLIDING_DATE === '1',
			},
			() => {
				let currentConfiguration = this.getCurrentConfiguration();
				this.props.saveConfiguration(currentConfiguration);
			}
		);
	}

	handleDateRangeChange = (value) => {
		this.setState({ dateRange: value });

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.date_range = value;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleDatesChange = (date, type) => {
		let formatDate = '';

		try {
			formatDate = date.format('YYYY-MM-DD HH:mm:ss');
		} catch (error) {
			return;
		}

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration[type] = formatDate;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleCurrentDateChange = (event) => {
		this.setState({ currentDate: event.target.checked }, () => {
			let currentConfiguration = this.getCurrentConfiguration();
			this.props.saveConfiguration(currentConfiguration);
		});
	};

	handleSwitch = (checked) => {
		let currentConfiguration = this.getCurrentConfiguration();
		this.setState({ checked });
		currentConfiguration.fill_type = checked ? 'area' : 'line';

		this.props.saveConfiguration(currentConfiguration);
	};

	handleCsvSwitch = (checked) => {
		let currentConfiguration = this.getCurrentConfiguration();
		this.setState({ csvChecked: checked });
		currentConfiguration.has_export = checked ? 1 : 0;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleSlidingSwitch = (checked) => {
		let currentConfiguration = this.getCurrentConfiguration();
		this.setState({ slidingDateChecked: checked });
		currentConfiguration.has_sliding_date = checked ? 1 : 0;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleStackingSwitch = (checked) => {
		let currentConfiguration = this.getCurrentConfiguration();
		this.setState({ stackingChecked: checked });
		currentConfiguration.has_stacking = checked ? 1 : 0;
		currentConfiguration.has_multi_axis = checked
			? 0
			: this.state.has_multi_axis
			? 1
			: 0;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleMultiAxisSwitch = (checked) => {
		let currentConfiguration = this.getCurrentConfiguration();
		this.setState({ multiAxisChecked: checked });
		currentConfiguration.has_multi_axis = checked ? 1 : 0;
		currentConfiguration.has_stacking = checked
			? 0
			: this.state.stackingChecked
			? 1
			: 0;

		this.props.saveConfiguration(currentConfiguration);
	};

	getCurrentConfiguration = () => {
		return {
			...this.props.configuration,
			type:
				this.props.widget.DWG_TYPE === 'DWG_areachart'
					? 'areachart'
					: 'columnchart',
			fill_type:
				this.props.widget.DWG_TYPE === 'DWG_areachart'
					? this.getCurrentCheck()
						? 'area'
						: 'line'
					: 'column',
			date_range: this.getCurrentDateRange(),
			date_start: this.getCurrentDateStart(),
			date_stop: this.getCurrentDateStop(),
			has_export: this.state.csvChecked ? '1' : '0',
			has_stacking: this.state.stackingChecked ? '1' : '0',
			has_multi_axis: this.state.multiAxisChecked ? '1' : '0',
			has_sliding_date: this.state.slidingDateChecked ? '1' : '0',
		};
	};

	getCurrentDateRange = () => {
		return this.state.dateRange;
	};

	getCurrentDateStart = () => {
		let date = new Date();

		return this.props.configuration.date_start
			? this.props.configuration.date_start
			: this.props.widget.DWC_DATE_START &&
			  this.props.widget.DWC_DATE_START !== '0000-00-00 00:00:00'
			? this.props.widget.DWC_DATE_START
			: moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
					'YYYY-MM-DD HH:mm:ss'
			  );
	};

	getCurrentDateStop = () => {
		return utils.getWidgetDateStop(this.state, this.props, 'DWC_DATE_STOP');
	};

	getCurrentCheck = () => {
		return this.state.checked;
	};

	render() {
		const { widget } = this.props;

		const isColumnChart = widget.DWG_TYPE === ChartWidgetModel.COLUMN;
		const isAreaChart = widget.DWG_TYPE === ChartWidgetModel.AREA;

		const dateStart = moment(
			this.getCurrentDateStart(),
			'YYYY-MM-DD HH:mm:ss'
		);
		const dateStop = moment(
			this.getCurrentDateStop(),
			'YYYY-MM-DD HH:mm:ss'
		);

		return (
			<Tabs tabPosition="top" tabBarStyle={{ marginBottom: 25 }}>
				<TabPane
					tab={
						<span>
							<Icon type="wifi" className="greyIcon" />
							{utils.translate('componentWidgetConfigure.data')}
						</span>
					}
					key="1"
				>
					<Form>
						<Divider />

						<Card>
							<DateRangeSection
								handleDateRangeChange={
									this.handleDateRangeChange
								}
								handleDatesChange={this.handleDatesChange}
								handleCurrentDateChange={
									this.handleCurrentDateChange
								}
								dateRange={this.state.dateRange}
								dateStart={dateStart}
								dateStop={dateStop}
								currentDate={this.state.currentDate}
								upToDateDescription={utils.translate(
									'componentWidgetChartConfigure.upToDate'
								)}
							/>
							<Divider />
							<SwitchSection
								checked={this.state.slidingDateChecked}
								handleSwitch={this.handleSlidingSwitch}
								label={utils.translate(
									'componentWidgetChartConfigure.slidingDates'
								)}
								iconType="export"
								otherChecked={false}
							/>
						</Card>

						<Divider />

						<Card>
							<SwitchSection
								checked={this.state.csvChecked}
								handleSwitch={this.handleCsvSwitch}
								label={utils.translate('generic.exportCSV')}
								iconType="export"
								otherChecked={false}
							/>
							<Divider />
							<SwitchSection
								label={utils.translate(
									'componentWidgetChartConfigure.axis'
								)}
								iconType="pause"
								checked={this.state.multiAxisChecked}
								handleSwitch={this.handleMultiAxisSwitch}
								otherChecked={this.state.stackingChecked}
							/>
							<Divider />
							{isAreaChart && (
								<StyleSwitchSection
									iconType="area-chart"
									defaultChecked={this.getCurrentCheck()}
									handleSwitch={this.handleSwitch}
									checkedChildren={utils.translate(
										'componentWidgetChartConfigure.area'
									)}
									unCheckedChildren={utils.translate(
										'componentWidgetChartConfigure.line'
									)}
								/>
							)}
							{isColumnChart && (
								<SwitchSection
									label={utils.translate(
										'componentWidgetChartConfigure.stacking'
									)}
									iconType="bar-chart"
									checked={this.state.stackingChecked}
									handleSwitch={this.handleStackingSwitch}
									otherChecked={this.state.multiAxisChecked}
								/>
							)}
							{isColumnChart && (
								<>
									<Divider />
									<GroupingOptionsConfigure {...this.props} />
								</>
							)}
						</Card>

						<Divider />

						<Card>
							<DataSourceConfigure {...this.props} />
						</Card>

						<Divider />
					</Form>
				</TabPane>
				<TabPane
					tab={
						<Tooltip title="Style">
							<span>
								<Icon type="bg-colors" className="greyIcon" />
								{utils.translate(
									'componentWidgetConfigure.style'
								)}
							</span>
						</Tooltip>
					}
					key="2"
				>
					<WidgetStyle
						{...this.props}
						marginTop={20}
						configuration={this.getCurrentConfiguration()}
					/>
				</TabPane>
			</Tabs>
		);
	}
}

export default Chart;
