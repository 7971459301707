import React from 'react';
import { Input } from 'antd';
import { withTranslation } from 'react-i18next';
import i18n from "Config/i18nConfig";

const { Search } = Input;



const IconSearch = ({ allAvailableIcons, allIcons, setAllIcons }) =>
{
	
	const filterIcons = (input) =>
	{
		let filteredIcons = {};
		
		Object.keys(allAvailableIcons).forEach(iconFamily => // foreach icon family
		{
			filteredIcons[iconFamily] = Object.keys(allAvailableIcons[iconFamily]) // filtering keys of family's attributes (icon names)
			.reduce((newIconFamily, currentKey) =>
			{
				if (currentKey.includes(input)) // searched value is in name of icon
				{
					newIconFamily[currentKey] = iconFamily[currentKey]; // populating new, filtered family 
				}
				
				return newIconFamily; // returning filtered family
			}, {});
		});
		
		setAllIcons(filteredIcons);
	}
	
	
	const resetIcons = (event) =>
	{
		const input = event.target.value;
		
		if (input === '') // input has been reset
		{
			setAllIcons(allAvailableIcons);
		}
	}
	
	
	return (

		<Search
			placeholder={i18n.t('componentIconSearch.search')}
			onChange={resetIcons}
			onSearch={filterIcons}
			enterButton
			allowClear
		/>
	);
};


export default withTranslation()(IconSearch);
