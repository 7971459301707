import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';

import LabelItem from '../../FormItems/LabelItem/LabelItem';
import OperatorBarItem from '../../FormItems/OperatorBarItem/OperatorBarItem';
import { utils } from 'Lib/utils';


const OperatorSection = (props) =>
{

	return (

		<FormGroup>
			<Row
				className='align-items-center'
			>
				<LabelItem
					label={utils.translate('componentOperatorSection.operator')}
					iconType='calculator'
				/>
				<Col
					md={{ size: 9 }}
					className='text-center'
				>
					<OperatorBarItem
						{...props}
					/>
				</Col>
			</Row>
		</FormGroup>
	)
};


export default OperatorSection;
