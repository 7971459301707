import React from 'react';
import { Empty } from 'antd';


const NoData = (props) =>
{

	return (

		<div style={{ textAlign: 'center', marginTop: props.marginTop }}>
			<Empty description={props.description}/>
		</div>
	);
};


export default NoData;
