import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import hexToRgba from 'hex-to-rgba';

import EmptyWidget from '../../WidgetLib/EmptyWidget/EmptyWidget';
import Loader from '../../WidgetLib/Loader/Loader';
import NoData from '../../WidgetLib/NoData/NoData';

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils.js';
import { chartColors } from 'Components/Studio/StudioLib/GenericHTML/ChartColors.js';

import './Piechart.css';


class Piechart extends Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			datas: [],
			config: [],
			options: this.getDefaultOptions(),
			loading: false
		};

		this.timerId = null;
		this.componentIsMounted = false;
	}


	componentDidMount()
	{
		this.componentIsMounted = true;

		if (!this.props.widget.CFG_IDS) { return; } // Widget hasn't been configured yet

		this.timerId = utils.setRefresh(this.props.widget.DWG_REFRESH_FREQ, this.updateGraph);

		this.updateGraph();
	}


	componentDidUpdate(prevProps)
	{
		if (prevProps.widget.CFG_IDS !== this.props.widget.CFG_IDS ||
			prevProps.widget.DVC_IDS !== this.props.widget.DVC_IDS ||
			prevProps.widget.DWP_TYPE !== this.props.widget.DWP_TYPE ||
			JSON.stringify(prevProps.widget.JWC) !== JSON.stringify(this.props.widget.JWC)) { this.updateGraph(); }
		if (utils.didWidgetResize(prevProps, this.props) && this.refs.chart) { this.refs.chart.getChart().reflow(); }
	}


	componentWillUnmount()
	{
		this.componentIsMounted = false;
		if (this.timerId !== null) { utils.clearRefresh(this.timerId); }
	}


	updateGraph = () =>
	{
		let stateDatas = [];
		let stateConfigs = [];
		let dataIndex = 0;
		let configIndex = 0;
		let configIdsArr = this.props.widget.CFG_IDS.split(',');

		this.setState({ loading: true }, () =>
		{
			configIdsArr.forEach((configId, i) =>
			{
				if (configId && configId !== 'none')
				{
					request.get(
						'data/' + configId,
						{
							data_type: 'last_data',
						},
						(data) =>
						{
							stateDatas['configId_' + configId] = data;
						},
						(error) => {},
						() =>
						{
							dataIndex++;
							// only render when all datas loaded
							if (dataIndex === configIdsArr.length && this.componentIsMounted)
							{
								this.setState({
									datas: stateDatas,
									loading: false
								}, () =>
								{
									// If config finished loading, then render, if not, let config do the render
									if (configIndex === configIdsArr.length)
									{
										this.setPiechartOptions();
									}
								});
							}
						}
					);

					request.get(
						'config/' + configId,
						{},
						(config) =>
						{
							stateConfigs['configId_' + configId] = config;
						},
						(error) => {},
						() =>
						{
							configIndex++;

							if (configIndex === configIdsArr.length && this.componentIsMounted)
							{
								this.setState({ configs: stateConfigs }, () =>
								{
									if (dataIndex === configIdsArr.length)
									{
										this.setPiechartOptions();
									}
								});
							}
						}
					)
				}
			});
		});
	}


	setPiechartOptions = () =>
	{
		let data = Object.keys(this.state.configs).map((key, index )=>
		{
			const datatype = this.state.configs[key][key.replace('configId_', '')];
			let configId = datatype.id;
			let datas = this.state.datas[key];
			let configUnit = this.props.widget.JWC[configId] && this.props.widget.JWC[configId].JWC_UNIT !== '' ? ' (' + this.props.widget.JWC[configId].JWC_UNIT + ')' : datatype.unite_D1 ? ' (' + datatype.unite_D1 + ')' : '';
			let configLabel = this.props.widget.JWC[configId] && this.props.widget.JWC[configId].JWC_LABEL !== '' ? this.props.widget.JWC[configId].JWC_LABEL : datatype.label_D1;
			configLabel = utils.getValueFromTags(configLabel, datatype.device);

			let coeffA = this.props.widget.JWC[configId] ? this.props.widget.JWC[configId].JWC_COEFFA : 1;
			let coeffB = this.props.widget.JWC[configId] ? this.props.widget.JWC[configId].JWC_COEFFB : 0;

			let colors = this.props.widget.JWC[configId] ? this.props.widget.JWC[configId].JWC_COLORS.split(',') : ['#fafafa', '#dddf0d', '#ff5369'];

			if (!datas)
			{
				return {
					name: datatype.device.label + ' - ' + configLabel + configUnit,
					data: {
						y: 0
					}
				};
			}

			delete datas['_links'];

			return {
				name: datatype.device.label + ' - ' + configLabel + configUnit,
				y: datas[0].data === null || datas[0].data.length === 0 ? 0 : parseFloat(coeffA) * parseFloat(datas[0].data) + parseFloat(coeffB),
				//Display default color or custom if changed
				color: colors[0] !== '#fafafa' ? hexToRgba(colors[0], 0.4) : chartColors[index].replace('0.6', '0.4'),
			}
		});

		let series = [{ data: data }];
		let options = this.getDefaultOptions();
		options.series = series;

		this.setState({ options: options });
	}


	getDefaultOptions = () =>
	{
		let chartColors = [
			'rgba(80, 180, 50, 0.8)',
			'rgba(237, 86, 27, 0.8)',
			'rgba(221, 223, 0, 0.8)',
			'rgba(36, 203, 229, 0.8)',
			'rgba(100, 229, 114, 0.8)',
			'rgba(255, 150, 85, 0.8)',
			'rgba(255, 242, 99, 0.8)',
			'rgba(106, 249, 196, 0.8)'
		];

		return {
			chart: {
				type: 'pie',
				className: 'chart',
				backgroundColor: null
			},
			title: {
				text: ''
			},
			lang: {
				noData: 'No Data'
			},
			tooltip: {
				headerFormat: '<b>{point.name}</b><br>',
				pointFormat: '<b>{point.name}</b>: {point.y}',
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						format: '<b>{point.name}</b>: {point.percentage:.1f} %',
					},
					innerSize: this.props.widget.DWP_TYPE === 'pie' ? '0%' : '65%', // this is where we set pie type donut/pie
					borderColor: 'transparent',
					colors: chartColors.map((color, index) =>
					{
						return {
							linearGradient: {

								x1: 0,
								x2: 0,
								y1: 0,
								y2: 1
							},
							stops: [
								[0, chartColors[index]],
								[1, chartColors[index].replace('0.8', '0.4')]
							]
						}
					}),
				}
			},
			credits: {
				enabled: false
			}
		};
	}


	render()
	{
		return (

			<>
				{utils.isConfigMode() &&
				(this.props.widget.CFG_IDS === undefined || this.props.widget.CFG_IDS === '') &&
				<EmptyWidget
					{...this.props}
					callToAction={utils.translate('componentWidgetPiechart.start')}
				/>}
				
				{this.state.loading && this.props.widget.CFG_IDS && this.props.widget.CFG_IDS !== 'none' &&
				<Loader style={{ marginTop: `${this.props.size.height / 2}px` }}/>}
			
				{!this.state.loading && this.props.widget.CFG_IDS !== undefined && this.props.widget.CFG_IDS !== '' &&
				<div 
					id={this.props.getWidgetId + '_CONTENT'}
					className={'highcharts-wrapper CONTENT' + this.props.getWidgetClass + this.props.getWidgetClass + '_CONTENT'}
					style={{ height: `${this.props.size.height}px` }}
				>
					<ReactHighcharts
						config={this.state.options}
						isPureConfig
						ref='chart'
					/>
				</div>}
				{this.props.widget.CFG_IDS === 'none' &&
				<>
					<NoData></NoData>
				</>}
			</>
		);
	}
}


export default Piechart;
