import React, { Component } from 'react';
import { Form } from 'reactstrap';
import { Select, Divider, Tabs, Icon, Tooltip } from 'antd';

import StyleSwitchSection from '../FormSections/StyleSwitchSection/StyleSwitchSection';
import DeviceSection from '../FormSections/DeviceSection/DeviceSection';
import DatatypeSection from '../FormSections/DatatypeSection/DatatypeSection';
import DatatypeCustomizer from '../FormSections/DatatypeCustomizerSection/DatatypeCustomizer';
import WidgetStyle from 'Components/Studio/Dashboard/Widgets/Style/WidgetStyle.js';
import { request } from 'Lib/request.js';
import {
	multipleDevicesPH,
	multipleDatatypesPH,
} from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';
import { categoryDashboardInfo } from 'Components/Studio/StudioLib/GenericHTML/GenericText.js';
import { MAX_DATATYPES } from 'Lib/globals.js';
import { utils } from 'Lib/utils';

const { Option } = Select;

const { TabPane } = Tabs;

class Piechart extends Component {
	constructor(props) {
		super(props);

		this.state = {
			configs: [],
			categoryFilter: [],
			containerFilter: [],
			selectedDevices: [],
			selectedDatatypes: [],
			allDatatypesSelected: false,
			userWantsToCustomizeDatatypes: false,
			handleSwitch: false,
			filter: undefined,
		};

		this.allDatatypes = React.createRef();
	}

	componentDidMount() {
		if (!!this.props.widget.DVC_IDS && this.props.widget.DVC_IDS.length) {
			this.getConfigsOfDeviceIds(this.props.widget.DVC_IDS);
		}

		let currentConfiguration = this.getCurrentConfiguration();
		this.props.saveConfiguration(currentConfiguration);

		this.setState({
			selectedDevices: this.props.widget.DVC_IDS,
			selectedDatatypes: this.props.widget.CFG_IDS,
		});

		if (this.props.widget.JWC) {
			Object.keys(this.props.widget.JWC).forEach((key) => {
				var value = this.props.widget.JWC[key];

				if (
					value.JWC_COEFFA !== '1' ||
					value.JWC_COEFFB !== '0' ||
					value.JWC_LABEL !== '' ||
					value.JWC_UNIT !== '' ||
					value.JWC_COLORS !== '#fafafa,#dddf0d,#ff5369'
				) {
					// datatype has been customized
					if (key !== '') {
						this.setState({ userWantsToCustomizeDatatypes: true });
					}
				}
			});
		}
	}

	getConfigsOfDeviceIds = (deviceIds) => {
		if (deviceIds !== undefined && deviceIds !== '') {
			request.get(
				'config',
				{ device_id: deviceIds },
				(configs) => this.setState({ configs: configs }),
				() => console.error('load configs failed')
			);
		}
	};

	handleDeviceChange = (value) => {
		let deviceIdsStr = '';

		value.forEach((deviceId) => {
			deviceIdsStr += deviceId + ',';
		});

		deviceIdsStr = deviceIdsStr.slice(0, -1);

		this.setState({ selectedDevices: deviceIdsStr }, () => {
			if (this.state.selectedDevices.length === 0) {
				this.setState({ selectedDatatypes: [] });
			}
		});

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.device_ids = deviceIdsStr;

		this.props.saveConfiguration(currentConfiguration);
		this.getConfigsOfDeviceIds(deviceIdsStr);
	};

	handleConfigChange = (value) => {
		this.setState({
			allDatatypesSelected: false,
			datatypesCleared: false,
		});

		if (this.state.filter) {
			this.setFilter(undefined);
		}

		if (value.includes('all')) {
			this.setState({ allDatatypesSelected: true });

			value = [];
			const allDatatypes = this.allDatatypes.current;
			var options = allDatatypes.props.children;

			for (var i = 0, l = options.length; i < l; i++) {
				if (options[i].key !== 'all') {
					value.push(options[i].key);
				}
			}
		}

		let configIdsStr = '';

		value.forEach((configId) => {
			configIdsStr += configId + ',';
		});

		configIdsStr = configIdsStr.slice(0, -1);

		this.setState({ selectedDatatypes: configIdsStr });

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.config_ids = configIdsStr;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleCustomizerToggle = (userWantsToCustomizeDatatypes) => {
		this.setState({
			userWantsToCustomizeDatatypes: userWantsToCustomizeDatatypes,
		});
	};

	handleSwitch = (isPie) => {
		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.pie_type = isPie ? 'pie' : 'donut';

		this.props.saveConfiguration(currentConfiguration);
	};

	getCurrentConfiguration = () => {
		return {
			type: 'piechart',
			device_ids: this.getCurrentDeviceIds(),
			config_ids: this.getCurrentConfigIds(),
			datatypeCustomization: this.getCurrentDatatypeCustomization(),
		};
	};

	getCurrentDeviceIds = () => {
		if (this.state.devicesCleared) {
			return undefined;
		}

		if (this.props.configuration.device_ids === undefined) {
			return this.props.widget.DVC_IDS && !this.state.containerEdited
				? this.props.widget.DVC_IDS
				: undefined;
		} else if (this.props.configuration.device_ids === '') {
			return undefined;
		} else {
			return this.props.configuration.device_ids;
		}
	};

	getCurrentConfigIds = () => {
		if (this.state.datatypesCleared) {
			return undefined;
		}

		if (this.props.configuration.config_ids === undefined) {
			return this.props.widget.CFG_IDS && !this.state.deviceEdited
				? this.props.widget.CFG_IDS
				: undefined;
		} else if (this.props.configuration.config_ids === '') {
			return undefined;
		} else {
			return this.props.configuration.config_ids;
		}
	};

	getCurrentDatatypeCustomization = () => {
		return this.state.datatypeCustomization;
	};

	saveCustomizationToState = (datatypeCustomization) => {
		let datatypeCustomizationCopy = JSON.parse(
			JSON.stringify(datatypeCustomization)
		); // child state MUST remain immutable, so we have to use a copy
		// JSON stringify then parse is only way to make sure that all nested objects get cloned and below changes are not mutative... ikr, wtf
		datatypeCustomizationCopy.forEach((customizedDatatype) => {
			this.state.configs.forEach((config) => {
				if (config.id === customizedDatatype.key) {
					if (customizedDatatype.label === config.label_D1) {
						customizedDatatype.label = '';
					}
					if (customizedDatatype.unit === config.unite_D1) {
						customizedDatatype.unit = '';
					}
					if (customizedDatatype.colors === config.colors) {
						customizedDatatype.colors = '';
					}
				}
			});
		});

		this.setState(
			{ datatypeCustomization: datatypeCustomizationCopy },
			() => {
				let currentConfiguration = this.getCurrentConfiguration();
				this.props.saveConfiguration(currentConfiguration);
			}
		);
	};

	getDatatypeSelectOptions = (currentConfigIds) => {
		const datatypeSelectOptions = [];

		if (
			this.state.selectedDevices &&
			this.state.selectedDevices.length > 0 &&
			this.allDatatypes.current.props.children.length <= MAX_DATATYPES &&
			!this.state.allDatatypesSelected
		) {
			datatypeSelectOptions.push(
				<Option key="all" id="selectAllOption" value="all">
					Select All{' '}
					{this.state.filter
						? utils.translate(
								'componentWidgetSingleDevice.contain'
						  ) +
						  this.state.filter +
						  '"'
						: ''}
				</Option>
			); // The variable 'filter' in Select All function is important for ant design to show it when searching
		}
		if (
			this.state.selectedDevices &&
			this.state.selectedDevices.length > 0
		) {
			this.state.configs.forEach((config) => {
				let option = (
					<Option
						key={config.id}
						disabled={
							currentConfigIds &&
							!currentConfigIds.includes(config.id) &&
							currentConfigIds.length === MAX_DATATYPES
						}
						value={config.id}
					>
						{config.device.label} - {config.label_D1}{' '}
						{config.unite_D1 && <span>({config.unite_D1})</span>}
					</Option>
				);
				if (this.state.filter) {
					let toSearch = config.device.label + config.label_D1;
					let isFiltered = toSearch
						.toLowerCase()
						.includes(this.state.filter.toLowerCase());
					if (isFiltered) {
						datatypeSelectOptions.push(option);
					}
				} else {
					datatypeSelectOptions.push(option);
				}
			});
		}

		return datatypeSelectOptions;
	};

	setFilter = (value) => {
		this.setState({ filter: value });
	};

	render() {
		const currentDeviceIds = this.state.selectedDevices
			? this.state.selectedDevices.length > 0
				? this.state.selectedDevices.split(',')
				: undefined
			: undefined;
		const currentConfigIds = this.state.selectedDatatypes
			? this.state.selectedDatatypes.length > 0
				? this.state.selectedDatatypes.split(',')
				: undefined
			: undefined;
		const canCustomizeDatatypes =
			this.state.selectedDatatypes &&
			this.state.selectedDatatypes.length > 0 &&
			this.state.configs &&
			this.state.configs.length > 0;
		const entries = this.props.devices.length;
		const datatypeSelectlabel = (
			<>
				Datatype(s)
				<br />
				<small>Up to {MAX_DATATYPES}</small>
			</>
		);

		return (
			<Tabs tabPosition="top" tabBarStyle={{ marginBottom: 25 }}>
				<TabPane
					tab={
						<span>
							<Icon type="table" className="greyIcon" />
							{utils.translate('componentWidgetConfigure.data')}
						</span>
					}
					key="1"
				>
					<Form>
						<Divider />

						<StyleSwitchSection
							iconType="pie-chart"
							defaultChecked={
								this.props.widget.DWP_TYPE === 'pie' ||
								this.props.widget.DWP_TYPE === undefined
							}
							handleSwitch={this.handleSwitch}
							checkedChildren={utils.translate(
								'componentWidgetSingleDevice.pie'
							)}
							unCheckedChildren={utils.translate(
								'componentWidgetSingleDevice.donut'
							)}
						/>

						<Divider />

						{!this.props.isCategoryDashboard && (
							<DeviceSection
								label={utils.translate('generic.dvcs')}
								deviceSelectMode="multiple"
								deviceSelectRef={this.allDevices}
								entries={entries}
								deviceSelectPlaceholder={multipleDevicesPH}
								devices={this.props.devices}
								categories={this.props.categories}
								containers={this.props.containers}
								groups={this.props.groups}
								selectedDevices={currentDeviceIds}
								categoryFilter={this.props.categoryFilter}
								containerFilter={this.props.containerFilter}
								groupFilter={this.props.groupFilter}
								loading={this.props.loading}
								disabled={this.props.loading}
								allowClearDeviceSelect={true}
								handleDeviceChange={this.handleDeviceChange}
								handleCategoryFilterChange={
									this.props.handleCategoryFilterChange
								}
								handleContainerFilterChange={
									this.props.handleContainerFilterChange
								}
								handleGroupFilterChange={
									this.props.handleGroupFilterChange
								}
								api_getDevices={this.props.api_getDevices}
							/>
						)}

						{this.props.isCategoryDashboard && (
							<div>{categoryDashboardInfo}</div>
						)}

						<Divider />

						<DatatypeSection
							selectRef={this.allDatatypes}
							label={datatypeSelectlabel}
							mode="multiple"
							allowClear={true}
							datatypeSelectPlaceholder={multipleDatatypesPH}
							selectOptions={this.getDatatypeSelectOptions(
								currentConfigIds
							)}
							selectedDatatypes={currentConfigIds}
							loading={
								this.props.isCategoryDashboard
									? false
									: this.props.loading
							}
							datatypeSelectDisabled={
								this.props.isCategoryDashboard
									? false
									: this.props.loading
							}
							handleConfigChange={this.handleConfigChange}
							maxTagCount={20}
							setFilter={this.setFilter}
						/>

						{canCustomizeDatatypes && (
							<DatatypeCustomizer
								label={utils.translate(
									'componentWidgetSingleDevice.customize'
								)}
								description={utils.translate(
									'componentWidgetSingleDevice.select'
								)}
								allDatatypes={this.state.configs}
								selectedDatatypes={this.state.selectedDatatypes}
								widget={this.props.widget}
								userWantsToCustomizeDatatypes={
									this.state.userWantsToCustomizeDatatypes
								}
								handleCustomizerToggle={
									this.handleCustomizerToggle
								}
								saveCustomizationToParentState={
									this.saveCustomizationToState
								}
							/>
						)}

						<Divider />
					</Form>
				</TabPane>
				<TabPane
					tab={
						<Tooltip title="Style">
							<span>
								<Icon type="bg-colors" className="greyIcon" />
								{utils.translate(
									'componentWidgetConfigure.style'
								)}
							</span>
						</Tooltip>
					}
					key="2"
				>
					<WidgetStyle
						{...this.props}
						marginTop={20}
						configuration={this.getCurrentConfiguration()}
					/>
				</TabPane>
			</Tabs>
		);
	}
}

export default Piechart;
