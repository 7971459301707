import React from 'react';
import { Button, Icon, Tooltip } from 'antd';
import { utils } from 'Lib/utils';


const ZoomResetButton = (props) => {

	return (
		
		<Tooltip title={utils.translate('componentZoomResetButton.reset')} placement='right'>
			<Button
				shape='circle'
				onClick={props.onClickReset}
				style={{ right: 20 , position: 'absolute', zIndex: 5 }}
			>
				<Icon type='sync' style={{ paddingTop: 3 }} />
			</Button>
		</Tooltip>
	)
};


export default ZoomResetButton;
