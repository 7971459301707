import React from 'react';

import CustomizerSwitchItem from '../../FormItems/CustomizerSwitchItem/CustomizerSwitchItem';
import GeoTrackingSection from '../GeoTrackingSection/GeoTrackingSection';
import IconCustomizerSection from '../IconCustomizerSection/IconCustomizerSection';


const DeviceCustomizerSection = (props) =>
{
	return (

		<>
			<CustomizerSwitchItem
				{...props}
				userWantsToCustomize={props.userWantsToCustomizeDevices}
			/>

			{props.customizerType === 'geoTracking' &&
			<GeoTrackingSection
				{...props}
			/>}
			
			{props.customizerType === 'icons' &&
			<IconCustomizerSection
				{...props}
			/>}
		</>
	);
};


export default DeviceCustomizerSection;
