import React from 'react';
import { Table, Input, InputNumber, Form } from 'antd';
import ColorPicker from 'rc-color-picker';

import { chartColors } from 'Components/Studio/StudioLib/GenericHTML/ChartColors.js';
import './DatatypeCustomizerTable.css';
import { utils } from 'Lib/utils';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
	<EditableContext.Provider value={form}>
		<tr {...props} />
	</EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);


class EditableCell extends React.Component {


	state = {
		editing: false,
		colors: this.props.record.colors,
	};


	toggleEdit = () => {

		const editing = !this.state.editing;

		this.setState({ editing }, () => {
			if (editing) {
				this.input.focus();
			}
		});
	};


	save = e => {

		const { record, handleSave } = this.props;

		this.form.validateFields((error, values) => {

			if (error && error[e.currentTarget.id]) { return; }
			this.toggleEdit();
			handleSave({ ...record, ...values });
		});
	};


	saveColor = e => {
		const { record, handleSave } = this.props;
		const colors = this.state.colors
		this.form.validateFields((error, values) => {
			if (error && error[e.currentTarget.id]) { return; }
			this.toggleEdit();
			handleSave({ ...record, ...values, colors });
		});
	};


	handleColor1Change = color => {

		let colors = [...this.props.record.colors.split(',')];
		let colorToUpdate = { ...colors[0] };
		colorToUpdate = color.color;
		colors[0] = colorToUpdate;

		this.setColorstoStateandConfig(colors);
	}


	handleColor2Change = color => {

		let colors = [...this.props.record.colors.split(',')];
		let colorToUpdate = { ...colors[1] };
		colorToUpdate = color.color;
		colors[1] = colorToUpdate;

		this.setColorstoStateandConfig(colors);
	}


	handleColor3Change = color => {

		let colors = [...this.props.record.colors.split(',')];
		let colorToUpdate = { ...colors[2] };
		colorToUpdate = color.color;
		colors[2] = colorToUpdate;

		this.setColorstoStateandConfig(colors);
	}


	setColorstoStateandConfig = colors => {
		let colorsStr = "";

		colors.map((color) => {

			colorsStr += color + ",";
			return colorsStr;
		})

		colorsStr = colorsStr.slice(0, -1);
		this.setState({ colors: colorsStr });

	}


	renderCell = form => {

		this.form = form;
		const { children, dataIndex, record } = this.props;
		const { editing } = this.state;

		return editing ? (

			<Form.Item style={{ margin: 0 }}>
				{form.getFieldDecorator(dataIndex, {
					rules: [
						{
							required: false,
							message: ``,
						},
					],
					initialValue: record[dataIndex],
				})(dataIndex === 'coefficient' || dataIndex === 'offset' || dataIndex === 'lowThres' || dataIndex === 'highThres' ?
					<InputNumber ref={node => (this.input = node)} precision={3}  onPressEnter={this.save} onBlur={this.save} /> : <Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} /> )}
			</Form.Item>
		)
		:
		dataIndex === 'color1' ?
			<ColorPicker
				onClose={this.saveColor}
				placement="bottomLeft"
				enableAlpha={false}
				style={{ zIndex: 9999 }}
				animation="slide-up"
				color={this.state.colors.split(',')[0] === '#fafafa' ? chartColors[this.props.record.index] : this.state.colors.split(',')[0]}
				onChange={this.handleColor1Change} />
		:
		dataIndex === 'color2' ?
			<ColorPicker
				onClose={this.saveColor}
				placement="bottomLeft"
				enableAlpha={false}
				style={{ zIndex: 9999 }}
				animation="slide-up"
				color={this.props.record.colors.split(',')[0] === '#fafafa' &&  this.state.colors.split(',')[1] === '#dddf0d' ? chartColors[this.props.record.index] :  this.state.colors.split(',')[1]=== '#dddf0d' ? this.props.record.colors.split(',')[0] :  this.state.colors.split(',')[1]}
				onChange={this.handleColor2Change} />
		:
		dataIndex === 'color3' ?
			<ColorPicker
				onClose={this.saveColor}
				placement="bottomLeft"
				enableAlpha={false}
				style={{ zIndex: 9999 }}
				animation="slide-up"
				color={this.props.record.colors.split(',')[0] === '#fafafa' &&this.state.colors.split(',')[2] === '#ff5369' ? chartColors[this.props.record.index] : this.state.colors.split(',')[2] === '#ff5369' ? this.props.record.colors.split(',')[0] : this.state.colors.split(',')[2]}
				onChange={this.handleColor3Change} />
		: dataIndex === 'lowThres' ?
		(
			<div
				className="editable-cell-value-wrap"
				style={{ paddingRight: 24 }}
				onClick={this.toggleEdit}
			>
				{children} {this.props.record.lowThres ? this.props.record.unit : ''}
			</div>
		)
		: dataIndex === 'highThres' ?
		(
			<div
				className="editable-cell-value-wrap"
				style={{ paddingRight: 24 }}
				onClick={this.toggleEdit}
			>
				{children} {this.props.record.highThres ? this.props.record.unit : ''}
			</div>
		)
		: (
			<div
				className="editable-cell-value-wrap"
				style={{ paddingRight: 24 }}
				onClick={this.toggleEdit}
			>
				{children}
			</div>
		)
	};


	render() {

		const {
			editable,
			dataIndex,
			title,
			record,
			index,
			handleSave,
			children,
			...restProps
		} = this.props;

		return (

			<td {...restProps}>
				{editable ? (
					<EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
				) : (
						children
					)}
			</td>
		);
	}
}


class DatatypeCustomizerTable extends React.Component {


	constructor(props) {

		super(props);

		this.columns = [
			{
				title: utils.translate('generic.dvc'),
				dataIndex: 'deviceLabel',
				editable: this.props.widget.DWV_TYPE === 'boolean' || this.props.widget.DWV_TYPE === 'index' || this.props.widget.DWV_TYPE === 'value' ? true : false,
			},
			{
				title: utils.translate('componentDatatypeCustomizerTable.label'),
				dataIndex: 'label',
				editable: true,
			},
			{
				title: utils.translate('componentDatatypeCustomizerTable.unit'),
				dataIndex: 'unit',
				editable: true,
			},
			{
				title: utils.translate('componentDatatypeCustomizerTable.coef'),
				dataIndex: 'coefficient',
				editable: this.props.widget.DWG_TYPE !== 'DWG_boolean' && this.props.widget.DWV_TYPE !== 'boolean' ? true : false,
			},
			{
				title: utils.translate('componentDatatypeCustomizerTable.offset'),
				dataIndex: 'offset',
				editable: this.props.widget.DWG_TYPE !== 'DWG_boolean' && this.props.widget.DWV_TYPE !== 'boolean' ? true : false,
			},
		];
		if (this.props.widget.DWG_TYPE === 'DWG_areachart') {
			this.columns.push(
				{
					title: utils.translate('generic.color'),
					dataIndex: 'color1',
					editable: true
				},
				{
					title: utils.translate('componentDatatypeCustomizerTable.lowThres'),
					editable: true,
					children: [
						{
							title: utils.translate('generic.value'),
							dataIndex: 'lowThres',
							editable: true
						},
						{
							title: utils.translate('generic.color'),
							dataIndex: 'color2',
							editable: true
						},
					]
				},
				{
					title: utils.translate('componentDatatypeCustomizerTable.highThres'),
					editable: true,
					children: [
						{
							title: utils.translate('generic.value'),
							dataIndex: 'highThres',
							editable: true
						},
						{
							title: utils.translate('generic.color'),
							dataIndex: 'color3',
							editable: true
						},
					]
				},
			);
		} if (this.props.widget.DWG_TYPE === 'DWG_piechart' || this.props.widget.DWG_TYPE === 'DWG_columnchart') {
			this.columns.push(
				{
					title: utils.translate('generic.color'),
					dataIndex: 'color1',
					editable: true
				})
		}

		this.state = {
			dataSource: [],
			count: 2,
		};

		this.getCustomizedDatatypes = this.getCustomizedDatatypes.bind(this);
	}


	componentDidMount()
	{
		this.getCustomizedDatatypes();
	}


	componentDidUpdate(prevprops, prevState)
	{
		if (prevprops.userWantsToCustomizeDatatypes === true && this.props.userWantsToCustomizeDatatypes === false)
		{
			this.getCustomizedDatatypes(true);
		}
		if (prevprops.selectedDatatypes !== this.props.selectedDatatypes)
		{
			this.getCustomizedDatatypes();
		}
		if (prevprops.colors !== this.props.colors)
		{
			this.getCustomizedDatatypes();
		}
		if (prevState.colors !== this.state.colors)
		{
			this.getCustomizedDatatypes(true);
		}
	}


	getCustomizedDatatypes(reset) {

		var selectedDatatypesArray = this.props.selectedDatatypes.length === 1 ? this.props.selectedDatatypes[0] : this.props.selectedDatatypes.split(',');
		let index = 0;
		if (!selectedDatatypesArray) { return; }

		var stateDatasource = [];
		var allDatatypesSorted = this.props.allDatatypes.sort((a, b) => (a.id > b.id) ? 1 : -1); // sorting so that order matches display order
		// so that colors match
		allDatatypesSorted.forEach(datatype => {

			if (selectedDatatypesArray.includes(datatype.id)) {
				var row = {
					index: index,
					key: datatype.id,
					deviceLabel: this.props.widget.DWV_TYPE === 'boolean' || this.props.widget.DWV_TYPE === 'index' || this.props.widget.DWV_TYPE === 'value' ? reset !== true && this.props.widget.JWC && this.props.widget.JWC[datatype.id] && this.props.widget.JWC[datatype.id].JWC_DEVICE_LABEL !== '' ? this.props.widget.JWC[datatype.id].JWC_DEVICE_LABEL : datatype.device.label : datatype.device.label,
					label: reset !== true && this.props.widget.JWC && this.props.widget.JWC[datatype.id] && this.props.widget.JWC[datatype.id].JWC_LABEL !== '' ? this.props.widget.JWC[datatype.id].JWC_LABEL : datatype.label_D1,
					unit: reset !== true && this.props.widget.JWC && this.props.widget.JWC[datatype.id] && this.props.widget.JWC[datatype.id].JWC_UNIT !== '' ? this.props.widget.JWC[datatype.id].JWC_UNIT : datatype.unite_D1,
					coefficient: reset !== true && this.props.widget.JWC && this.props.widget.JWC[datatype.id] && this.props.widget.JWC[datatype.id].JWC_COEFFA !== '' ? this.props.widget.JWC[datatype.id].JWC_COEFFA : '1',
					offset: reset !== true && this.props.widget.JWC && this.props.widget.JWC[datatype.id] && this.props.widget.JWC[datatype.id].JWC_COEFFB !== '' ? this.props.widget.JWC[datatype.id].JWC_COEFFB : '0',
					colors: reset !== true && this.props.widget.JWC && this.props.widget.JWC[datatype.id] && this.props.widget.JWC[datatype.id].JWC_COLORS !== "#fafafa,#dddf0d,#ff5369" ? this.props.widget.JWC[datatype.id].JWC_COLORS : "#fafafa,#dddf0d,#ff5369",
					lowThres: reset !== true && this.props.widget.JWC && this.props.widget.JWC[datatype.id] && this.props.widget.JWC[datatype.id].JWC_LOW_THRES !== '' ? parseFloat(this.props.widget.JWC[datatype.id].JWC_LOW_THRES) : '',
					highThres: reset !== true && this.props.widget.JWC && this.props.widget.JWC[datatype.id] && this.props.widget.JWC[datatype.id].JWC_HIGH_THRES !== '' ? parseFloat(this.props.widget.JWC[datatype.id].JWC_HIGH_THRES) : ''
				}

				stateDatasource.push(row);
				index++;
			}
		});

		this.setState({ dataSource: stateDatasource }, () => {
			this.props.saveCustomizationToParentState(this.state.dataSource);
		});
	}


	handleSave = row => {

		const newData = [...this.state.dataSource];

		const index = newData.findIndex(item => row.key === item.key);
		const item = newData[index];

		newData.splice(index, 1, {
			...item,
			...row,
		});

		this.props.saveCustomizationToParentState(newData);
		this.setState({ dataSource: newData });
	};


	render() {

		if (!this.props.userWantsToCustomizeDatatypes) { return null; }

		const { dataSource } = this.state;

		const components = {
			body: {
				row: EditableFormRow,
				cell: EditableCell,
			},
		};

		const mapColumns = col => {
		
			const newCol = {
				...col,
				onCell: record => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					title: col.title,
					key: col.key,
					handleSave: this.handleSave
				})
			};
			if (col.children) {
				newCol.children = col.children.map(mapColumns);
			}
			return newCol;
		};

		const columns = this.columns.map(mapColumns);

		return (
			<div className='tableContainer'>
				<Table
					components={components}
					rowClassName={() => 'editable-row'}
					scroll={{ x: 130 }}
					dataSource={dataSource}
					columns={columns}
					pagination={{ pageSize: 10 }}
				/>
			</div>

		);
	}
}


export default DatatypeCustomizerTable;
