import React from 'react';
import { Row, FormGroup } from 'reactstrap';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { utils } from 'Lib/utils';


const AttributeEditorButton = (props) =>
{

	const { toggleEditor } = props;


	return (

		<FormGroup>
			<Row>
				<Button
					type='primary'
					className='drawerButton-theme'
					onClick={toggleEditor}>
						<FontAwesomeIcon className="drawerIcon" icon='pencil-alt'/>
						{utils.translate('componentAttributeEditorButton.1')}
				</Button>{' '}
			</Row>
		</FormGroup>
	)
};


export default AttributeEditorButton;
