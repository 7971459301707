import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Switch, Popconfirm, Icon, Tooltip } from 'antd';

import LabelItem from '../LabelItem/LabelItem';
import { utils } from 'Lib/utils';


const CustomizerSwitchItem = (props) =>
{

	const {
		handleCustomizerToggle,
		userWantsToCustomize,
		unCheckedChildren,
		checkedChildren,
		switchDisabled,
		disabledTooltipText,
		customizerInfo,
	} = props;


	return (

		<FormGroup>
			<Row>
				<LabelItem
					{...props}
				/>
				<Col md={{size: 9}}>
					<Popconfirm
						placement='rightTop'
						icon={<Icon type='question-circle-o' style={{ color: 'red' }} />}
						title={utils.translate('componentCustomizerSwitchItem.confirm')}
						onConfirm={() => handleCustomizerToggle(false)}
						okText='Yes'
						cancelText='No'
						disabled={!userWantsToCustomize}
					>
						{unCheckedChildren &&
						<span style={{ marginRight: '10px' }}>
							{unCheckedChildren}
						</span>}
						<Tooltip
							title={switchDisabled ? disabledTooltipText : undefined}
						>
							<Switch
								onChange={userWantsToCustomize ? undefined : handleCustomizerToggle}
								checked={userWantsToCustomize}
								disabled={switchDisabled}
							/>
						</Tooltip>
						{checkedChildren &&
						<span style={{ marginLeft: '10px' }}>
							{checkedChildren}
						</span>}
					</Popconfirm>
				</Col>
			</Row>
			<Row>
				<Col>
					{customizerInfo}
				</Col>
			</Row>
		</FormGroup>
	)
};


export default CustomizerSwitchItem;
