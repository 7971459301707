import React, { useState } from 'react';
import { withSize } from 'react-sizeme';
import { CardBody } from 'reactstrap';

import WidgetHeader from './WidgetLib/WidgetHeader/WidgetHeader';
import Configure from './Configure/Configure';
import Html from './Widgets/Html/Html';
import Value from './Widgets/Value/Value';
import Chart from './Widgets/Chart/Chart';
import Piechart from './Widgets/Piechart/Piechart';
import Gauge from './Widgets/Gauge/Gauge';
import Table from './Widgets/Table/Table';
import Status from './Widgets/Status/Status';
import Map from './Widgets/Map/Map';
import Sitemap from './Widgets/Sitemap/Sitemap';
import SitemapMarker from './Widgets/Sitemap/SitemapMarker/SitemapMarker';
import Command from './Widgets/Command/Command';
import Scheduler from './Widgets/Scheduler/Scheduler';
import DetailsScheduledCommand from './Widgets/Scheduler/DetailsScheduledCommand';
import AlarmRule from './Widgets/AlarmRule/AlarmRule';
import Camera from './Widgets/Camera/Camera';
import moment from 'moment';

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils';

import './Widget.css';


const Widget = (props) => {

	const {
		widget,
		getWidgets,
		saveToState,
		headerStyle,
		size,
		getWidgetClass,
		getWidgetId
	} = props;

	const [modalConfigure, setModalConfigure] = useState(false);
	const [modalDetails, setModalDetails] = useState(false);
	const [modalConfigureScheduler, setmodalConfigureScheduler] = useState(false);
	const [headerSize, setHeaderSize] = useState({ width: 0, height: 0 });
	const [newEvent, setNewEvent] = useState(undefined);
	const [schedulerDevices, setschedulerDevices] = useState([]);
	const [schedulerDevicesIds, setschedulerDevicesIds] = useState('');
	const [schedulerCategories, setschedulerCategories] = useState([]);
	const [existingEvent, setExistingEvent] = useState(undefined);
	const [newEventDetails, setEventDetails] = useState(undefined);
	const [eventId, setEventId] = useState(undefined);
	const [eventRecurringDetails, setEventRecurringDetails] = useState(undefined);
	const [isLoaded, setisLoaded] = useState(false);
	const [recurringDate, setRecurringDate] = useState(undefined);


	const widgetSize = {
		width: size.width - 40,
		height: (size.height - headerSize.height) - 52,
	};

	const markerSize = {
		width: size.width,
		height: (size.height - headerSize.height),
	};


	const toggleModalConfigure = (date = null) => {

		if (date !== null) {
			setNewEvent(date);
		}
		setModalConfigure(!modalConfigure);
	}


	const toggleModalConfigureEvent = () => {
		setModalConfigure(!modalConfigure);
	}

	const toggleModalDetailsEvent = () => {
		setModalDetails(!modalDetails);
	}


	const toggleModalConfigureScheduler = () => {
		setmodalConfigureScheduler(!modalConfigureScheduler);
	}


	const getDevicesToDisplay = () => {
		setisLoaded(false);
		let devices = schedulerDevicesIds !== '' ? schedulerDevicesIds : props.isCategoryDashboard ? props.catCurrentDevice.id : widget.DVC_IDS;
		let deviceArray = [];
		devices.split(',').forEach(id => {
			request.get(
				'device/' + id,
				{
					'dependencies': 'available_commands'
				},
				(device) => {
					deviceArray.push(device[0]);
				},
				(e) => console.error(e),
				() => { }
			);
		})
		setschedulerDevices(deviceArray);

		setisLoaded(true);
	}


	const getCategoriesToDisplay = () => {
		getCategory(widget.CAT_IDS[0]);
	}


	const getCategory = (id) => {

		request.get(
			'category/' + id,
			{
				'dependencies': 'available_commands'
			},
			(cat) => {
				setschedulerCategories(cat[0]);
			},
			(e) => console.error(e),
			() => { }
		);
	}


	const deleteWidget = () => {
		request.delete(
			'widget/' + widget.DWG_ID,
			{},
			() => getWidgets(),
			(e) => {
				utils.openNotification(
					'error',
					utils.translate('componentWidget.error1'),
					utils.translate('generic.rights'),
					'bottomRight',
					5
				);
			},
		);
	}

	const deleteMarker = () => {
		request.delete(
			'widgetsitemapmarker/' + widget.DWG_ID,
			{},
			() => getWidgets(),
			(e) => console.error(e),
		);
	}


	const getEvent = (arg, mode) => {

		let id = '';
		if (mode !== 'configure') {
			id = arg.event._def.extendedProps.scm_id;
			setRecurringDate(moment.utc(arg.el.fcSeg.start).format('YYYY-MM-DD HH:mm:ss'));
		}else {
			id = arg;
		}

		request.get(
			'scheduledcommand/' + id,
			{},
			(res) => {


				if (mode === 'recurring') {
					request.get(
						'orderhistory',
						{
							ohi_id: arg.event._def.extendedProps.ohi_id
						},
						(res) => {
							setEventRecurringDetails(res[0]);
						},
						(e) => {
							utils.openNotification(
								'error',
								utils.translate('componentConfigure.cannot'),
								utils.translate('generic.rights'),
								'bottomRight',
								5
							);
						}, () => {
							setExistingEvent(res);
							setEventDetails(res);
							setEventId(id);
							toggleModalDetailsEvent();
						}
					)
				} else {
					setExistingEvent(res);
					setEventDetails(res);
					setEventId(id);
					if (mode === 'configure') {
						toggleModalConfigureEvent();
					}
					if (mode === 'details') {
						toggleModalDetailsEvent();
					}
				}
			},
			(e) => {
				utils.openNotification(
					'error',
					utils.translate('componentConfigure.cannot'),
					utils.translate('generic.rights'),
					'bottomRight',
					5
				);
			},
		);
	}


	const saveHeaderSize = (size) => {
		setHeaderSize(size);
	}


	const saveToParentState = (id) => {
		request.get(
			'widget/' + id,
			{},
			(widget) => {
				widget.DWG_DECOR = (utils.isValidJson(widget.DWG_DECOR) && !widget.DWG_DECOR === null) ? JSON.parse(widget.DWG_DECOR) : {};
				saveToState(widget, widget.DWG_ID)
			},
			(e) => console.error(e),
		);
	}

	const getWidgetColors = (location) => {
		let colors = widget.DWG_COLORS ? widget.DWG_COLORS.split(',') : '';
		if (location === 'background') {
			return colors[0]
		} else if (location === 'text') {
			return colors[1]
		}
	}


	const getWidgetFontSize = () => {
		let fontSize = widget.DWG_FONT_SIZE ? widget.DWG_FONT_SIZE + 'px' : '';
		return fontSize;
	}

	let titlePosition = widget.DWG_DECOR ? widget.DWG_DECOR.titlePosition ? widget.DWG_DECOR.titlePosition : 'default' : 'default';
	titlePosition += '-title ';

	return (

		<div className='widget' style={{ backgroundColor: props.block && props.block.length > 1 ? '' : getWidgetColors('background') }}>
			<CardBody
				className={titlePosition + (widget.DWG_TYPE === 'DWG_sitemapMarker' ? 'widget-content, sitemap' : 'widget-content')}
				style={{ color: getWidgetColors('text') }}>
				<WidgetHeader
					getWidgets={props.getWidgets}
					toggleModalConfigure={toggleModalConfigure}
					toggleModalConfigureScheduler={toggleModalConfigureScheduler}
					delete={widget.DWG_TYPE === 'DWG_sitemapMarker' ?
						deleteMarker : deleteWidget}
					widget={widget}
					setSize={saveHeaderSize}
					style={headerStyle}
					getWidgetClass={getWidgetClass}
					getWidgetId={getWidgetId}
					getWidgetColors={getWidgetColors}
					block={props.block}
					addWidget={props.addWidget}
					moveWidgetUp={props.moveWidgetUp}
					moveWidgetDown={props.moveWidgetDown}
				/>
				{widget.DWG_TYPE === 'DWG_html' &&
					<Html
						{...props}
						size={widgetSize}
						toggleModalConfigure={toggleModalConfigure}
						getWidgetClass={getWidgetClass}
						getWidgetId={getWidgetId}
						getWidgetColors={getWidgetColors}
						getWidgetFontSize={getWidgetFontSize}
					/>}
				{(widget.DWG_TYPE === 'DWG_value' ||
					widget.DWG_TYPE === 'DWG_index' ||
					widget.DWG_TYPE === 'DWG_boolean') &&
					<Value
						{...props}
						size={widgetSize}
						toggleModalConfigure={toggleModalConfigure}
						getWidgetClass={getWidgetClass}
						getWidgetId={getWidgetId}
						getWidgetColors={getWidgetColors}
						getWidgetFontSize={getWidgetFontSize}
					/>}
				{(widget.DWG_TYPE === 'DWG_areachart' ||
					widget.DWG_TYPE === 'DWG_columnchart') &&
					<Chart
						{...props}
						size={widgetSize}
						toggleModalConfigure={toggleModalConfigure}
						getWidgetClass={getWidgetClass}
						getWidgetId={getWidgetId}
						getWidgetColors={getWidgetColors}
						getWidgetFontSize={getWidgetFontSize}
					/>}
				{widget.DWG_TYPE === 'DWG_piechart' &&
					<Piechart
						{...props}
						size={widgetSize}
						toggleModalConfigure={toggleModalConfigure}
						getWidgetClass={getWidgetClass}
						getWidgetId={getWidgetId}
						getWidgetColors={getWidgetColors}
						getWidgetFontSize={getWidgetFontSize}
					/>}
				{widget.DWG_TYPE === 'DWG_gauge' &&
					<Gauge
						{...props}
						size={widgetSize}
						toggleModalConfigure={toggleModalConfigure}
						getWidgetClass={getWidgetClass}
						getWidgetId={getWidgetId}
						getWidgetColors={getWidgetColors}
						getWidgetFontSize={getWidgetFontSize}
					/>}
				{widget.DWG_TYPE === 'DWG_table' &&
					<Table
						{...props}
						size={widgetSize}
						toggleModalConfigure={toggleModalConfigure}
						getWidgetClass={getWidgetClass}
						getWidgetId={getWidgetId}
						getWidgetColors={getWidgetColors}
						getWidgetFontSize={getWidgetFontSize}
					/>}
				{widget.DWG_TYPE === 'DWG_status' &&
					<Status
						{...props}
						size={widgetSize}
						toggleModalConfigure={toggleModalConfigure}
						getWidgetClass={getWidgetClass}
						getWidgetId={getWidgetId}
						getWidgetColors={getWidgetColors}
						getWidgetFontSize={getWidgetFontSize}
					/>}
				{widget.DWG_TYPE === 'DWG_map' &&
					<Map
						{...props}
						size={widgetSize}
						toggleModalConfigure={toggleModalConfigure}
						getWidgetClass={getWidgetClass}
						getWidgetId={getWidgetId}
						getWidgetColors={getWidgetColors}
						getWidgetFontSize={getWidgetFontSize}
					/>}
				{widget.DWG_TYPE === 'DWG_sitemap' &&
					<Sitemap
						{...props}
						size={widgetSize}
						toggleModalConfigure={toggleModalConfigure}
						getWidgetClass={getWidgetClass}
						getWidgetId={getWidgetId}
						getWidgetColors={getWidgetColors}
						getWidgetFontSize={getWidgetFontSize}
					/>}
				{widget.DWG_TYPE === 'DWG_sitemapMarker' &&
					<SitemapMarker
						{...props}
						size={markerSize}
						getWidgetClass={getWidgetClass}
						getWidgetId={getWidgetId}
						getWidgetColors={getWidgetColors}
						getWidgetFontSize={getWidgetFontSize}
					/>}
				{widget.DWG_TYPE === 'DWG_command' &&
					<Command
						{...props}
						size={widgetSize}
						toggleModalConfigure={toggleModalConfigure}
						getWidgetClass={getWidgetClass}
						getWidgetId={getWidgetId}
						getWidgetColors={getWidgetColors}
						getWidgetFontSize={getWidgetFontSize}
					/>}
				{widget.DWG_TYPE === 'DWG_scheduler' &&
					<Scheduler
						{...props}
						size={widgetSize}
						toggleModalConfigure={toggleModalConfigure}
						toggleModalConfigureScheduler={toggleModalConfigureScheduler}
						toggleModalConfigureEvent={toggleModalConfigureEvent}
						getEvent={getEvent}
						setExistingEvent={setExistingEvent}
						schedulerDevices={schedulerDevices}
						setschedulerDevices={setschedulerDevices}
						getDevicesToDisplay={getDevicesToDisplay}
						schedulerDevicesIds={schedulerDevicesIds}
						setschedulerCategories={setschedulerCategories}
						getCategoriesToDisplay={getCategoriesToDisplay}
						isLoaded={isLoaded}
					/>}
				{widget.DWG_TYPE === 'DWG_alarmRule' &&
					<AlarmRule
						{...props}
						size={widgetSize}
						toggleModalConfigure={toggleModalConfigure}
						getWidgetClass={getWidgetClass}
						getWidgetId={getWidgetId}
						getWidgetColors={getWidgetColors}
						getWidgetFontSize={getWidgetFontSize}
					/>}
				{widget.DWG_TYPE === 'DWG_camera' &&
					<Camera
						{...props}
						size={widgetSize}
						toggleModalConfigure={toggleModalConfigure}
						getWidgetClass={getWidgetClass}
						getWidgetId={getWidgetId}
						getWidgetColors={getWidgetColors}
						getWidgetFontSize={getWidgetFontSize}
					/>}
			</CardBody>
			<Configure
				{...props}
				modal={modalConfigure}
				modalConfigureScheduler={modalConfigureScheduler}
				schedulerDevices={schedulerDevices}
				schedulerCategories={schedulerCategories}
				saveWidgetToState={saveToParentState}
				toggleModal={toggleModalConfigure}
				toggleModalConfigureScheduler={toggleModalConfigureScheduler}
				getWidgetClass={getWidgetClass}
				getWidgetId={getWidgetId}
				newEvent={newEvent}
				getCategory={getCategory}
				setschedulerDevices={setschedulerDevices}
				getDevicesToDisplay={getDevicesToDisplay}
				getCategoriesToDisplay={getCategoriesToDisplay}
				existingEvent={existingEvent}
				eventId={eventId}
				setExistingEvent={setExistingEvent}
				setNewEvent={setNewEvent}
				setschedulerDevicesIds={setschedulerDevicesIds}
				setisLoaded={setisLoaded}
			/>
			<DetailsScheduledCommand
				{...props}
				setNewEvent={setEventDetails}
				modal={modalDetails}
				newEvent={newEventDetails}
				eventRecurringDetails={eventRecurringDetails}
				recurringDate={recurringDate}
				eventId={eventId}
				toggleModal={toggleModalDetailsEvent}
				setEventRecurringDetails={setEventRecurringDetails}
			/>
		</div>
	);
}


export default withSize({ monitorHeight: true })(Widget);
