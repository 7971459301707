import React, { Component } from 'react';
import { Row, Col, FormGroup, Form } from 'reactstrap';
import { Divider, DatePicker, Select, Input, InputNumber } from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/fr_FR';

import LabelItem from 'Components/Studio/Dashboard/Widgets/Configure/FormItems/LabelItem/LabelItem';
import DeviceSection from '../FormSections/DeviceSection/DeviceSection';
import DualColorsSection from '../FormSections/ColorSections/DualColorsSection/DualColorsSection';
import { oneDevicePH, oneCommandPH, selectOptionPH } from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';
import { utils } from 'Lib/utils';
import { request } from 'Lib/request.js';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

class ScheduledCommand extends Component {

	constructor(props) {
		super(props);

		this.state = {
			device: undefined,
			selectedDevice: undefined,
			selectedDeviceId: undefined,
			selectedCategoryId: undefined,
			colors: ['#C9D9D2', '#28a745', '#ff5369'],
			displayAllColors: true,
			date: undefined,
			formatDate: undefined,
			loadingCommands: true,
			selectedCommand: undefined,
			selectedCommandId: undefined,
			deviceCommands: [],
			categoryCommands: [],
			label: '',
			currentEvent: null,
			commandParam: '',
			portNumber: undefined,
			commandSelectOption: undefined,
			repeatPeriod: undefined,
			repeatMode: 'SCM_RM_OFF',
			repeatEnd: undefined,
			repeatExclude: ''
		}
	}


	componentDidMount = () => {

		if (this.props.newEvent) {
			this.setState({ formatDate: this.props.newEvent, label: this.props.t('componentWidgetScheduler.newEvent') }, () => this.props.setNewEvent(undefined));
			if (this.props.isCategoryDashboard) {
				this.setState({ selectedDeviceId: this.props.catCurrentDevice.id });
				this.getDeviceCommands();
			}
			if (this.props.widget.CAT_IDS.length > 0) {
				this.setState({ selectedCategoryId: this.props.widget.CAT_IDS[0], categoryCommands: this.props.schedulerCategories[0].commands, label: this.props.t('componentWidgetScheduler.newEvent') });
			}
		}


		if (this.props.existingEvent) {
			this.setState({
				selectedDeviceId: this.props.existingEvent.DVC_ID,
				selectedCategoryId: this.props.existingEvent.CAT_ID,
				selectedCommandId: this.props.existingEvent.CMD_ID
			}, () => {
				if (this.props.widget.DVC_IDS) {
					this.getDeviceCommands();
				} else {
					this.setState({ categoryCommands: this.props.schedulerCategories[0].commands });
					this.props.schedulerCategories[0].commands.forEach((command, index) => {
						if (command.cmdId === this.state.selectedCommandId) {
							this.setState({ selectedCommand: command })
						}
					});
				}

			})

			this.setState({
				label: this.props.existingEvent.SCM_LABEL,
				date: moment(this.props.existingEvent.SCM_SCHEDULEDDATE),
				formatDate: moment(this.props.existingEvent.SCM_SCHEDULEDDATE),
				startDate: moment(this.props.existingEvent.SCM_REPEAT_START),
				colors: this.props.existingEvent.SCM_COLORSTATUS.split(','),
				commandParam: this.props.existingEvent.SCM_CMDPARAM,
				commandSelectOption: this.props.existingEvent.SCM_CMDPARAM,
				portNumber: this.props.existingEvent.SCM_CMDPORT,
				param: this.props.existingEvent.SCM_CMDPARAM,
				repeatPeriod: this.props.existingEvent.SCM_REPEAT_PERIOD,
				repeatMode: this.props.existingEvent.SCM_REPEAT_MODE,
				repeatEnd:this.props.existingEvent.SCM_REPEAT_END ? moment(this.props.existingEvent.SCM_REPEAT_END) : undefined,
			})
		} else {
			if (!this.props.widget.DVC_IDS) {
				this.setState({ categoryCommands: this.props.schedulerCategories[0].commands })
			}
		}

		let currentConfiguration = this.getCurrentConfiguration();
		this.props.saveConfiguration(currentConfiguration);
		this.componentIsMounted = true;
	}

	componentDidUpdate = () => {
		let currentConfiguration = this.getCurrentConfiguration();
		this.props.saveConfiguration(currentConfiguration);
	}


	getScheduledCommand = (id, callback) => {
		this.setState({ loading: true }, () => {
			request.get(
				'scheduledcommand/' + id,
				{},
				(res) => {
					this.setState({ currentEvent: res }, () => callback)
				},
				(e) => console.error(e),
				() => { }
			);
		});
	}


	getDeviceCommands = () => {
		this.setState({
			loadingCommands: true,
		}, () => {
			request.get(
				'device/' + this.state.selectedDeviceId,
				{
					'dependencies': 'available_commands'
				},
				(device) => {
					if (this.componentIsMounted) {
						this.setState({
							device: device[0],
							deviceCommands: device[0].available_commands ? device[0].available_commands : [],
						});
					}
				},
				(error) => { console.error(error); },
				() =>
					this.state.deviceCommands.forEach((command, index) => {
						if (command.cmdId === this.state.selectedCommandId) {
							this.setState({ selectedCommand: command })
						}
					}),
				this.setState({ loadingCommands: false })
			);
		});
	}


	handleDeviceChange = (value) => {
		this.setState({
			selectedDeviceId: value,
			selectedCommand: undefined
		});
		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.device_id = value;
		this.getDeviceCommands();
		this.props.saveConfiguration(currentConfiguration);
	}


	handleCommandChange = (value) => {
		this.setState({
			selectedCommand: this.props.widget.DVC_IDS ? this.state.deviceCommands[value] : this.state.categoryCommands[value],
			selectedCommandId: this.props.widget.DVC_IDS ? this.state.deviceCommands[value].cmdId : this.state.categoryCommands[value].cmdId,
			commandParam: '',
			commandSelectOption: undefined,
			portNumber: undefined
		});
		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.cmd_id = this.props.widget.DVC_IDS ? this.state.deviceCommands[value].cmdId : this.state.categoryCommands[value].cmdId;
		this.props.saveConfiguration(currentConfiguration);
	}


	handleRecurrChange = (event) => {

		let currentConfiguration = this.getCurrentConfiguration();

		if(event === 'SCM_RP_NONE'){
			this.setState({ repeatPeriod: 'SCM_RP_DAY' });
			currentConfiguration.repeat_period = 'SCM_RP_DAY';
			this.setState({ repeatMode: 'SCM_RM_OFF' });
			currentConfiguration.repeat_mode = 'SCM_RM_OFF';
			this.setState({ repeatExclude: '' });
			currentConfiguration.repeat_exclude = '';
		} else {
			this.setState({ repeatPeriod: event });
			currentConfiguration.repeat_period = event;
			this.setState({ repeatMode: 'SCM_RM_ON' });
			currentConfiguration.repeat_mode = 'SCM_RM_ON';
		}
		this.props.saveConfiguration(currentConfiguration);
	}


	handleParamChange = (event) => {
		this.setState({ commandParam: event.target.value });
		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.param = event.target.value;
		this.props.saveConfiguration(currentConfiguration);
	}


	handleSelectOptionChange = (value) => {
		this.setState({ commandSelectOption: value });
		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.param = value;
		this.props.saveConfiguration(currentConfiguration);
	}


	handlePortNumberChange = (value) => {
		this.setState({ portNumber: value });
		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.port = value;
		this.props.saveConfiguration(currentConfiguration);
	}


	getCurrentConfiguration = () => {
		return {
			type: 'scheduledcommand',
			device_id: this.props.isCategoryDashboard ? this.props.catCurrentDevice.id : this.state.selectedDeviceId,
			set: this.props.widget.DVC_IDS ? 'SCM_ST_ONEDVC' : 'SCM_ST_ONECAT',
			cmd_id: this.state.selectedCommandId ? this.state.selectedCommandId : undefined,
			date: this.state.formatDate ? moment(this.state.formatDate).format('YYYY-MM-DD HH:mm:ss') : this.props.existingEvent ? this.props.existingEvent.SCM_SCHEDULEDDATE : undefined,
			label: this.state.label ? this.state.label : this.props.existingEvent ? this.props.existingEvent.SCM_LABEL : this.props.t('componentWidgetScheduler.newEvent'),
			colors: this.state.colors ? this.state.colors.join() : '#C9D9D2,#28a745,#ff5369',
			param: this.state.commandParam || this.state.commandSelectOption,
			port: this.state.portNumber,
			repeat_period: this.state.repeatPeriod,
			repeat_mode: this.state.repeatMode,
			repeat_end: this.state.repeatEnd ? moment(this.state.repeatEnd).format('YYYY-MM-DD HH:mm:ss') : undefined,
			repeat_exclude: this.state.repeatExclude,
		}
	}


	handleDateChange = (date) => {
		let formatDate = '';

		try {
			formatDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
		}
		catch (error) {
			return;
		}
		if (moment(new Date(Date.now())).format('YYYY-MM-DD HH:mm:ss') < formatDate) {
			let currentConfiguration = this.getCurrentConfiguration();
			currentConfiguration['date'] = formatDate;

			this.setState({
				date: date,
				formatDate: formatDate,
			});
			this.props.saveConfiguration(currentConfiguration);
		}

	}


	handleEndDateChange = (date) => {
		let formatDate = '';

		try {
			formatDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
		}
		catch (error) {
			return;
		}

		if(!date){
			let currentConfiguration = this.getCurrentConfiguration();
			currentConfiguration.repeat_end = '';

			this.setState({
				repeatEnd: undefined
			});
			this.props.saveConfiguration(currentConfiguration);
		}else if (moment(Date.now()).format('YYYY-MM-DD HH:mm:ss') < formatDate) {
			let currentConfiguration = this.getCurrentConfiguration();
			currentConfiguration.repeat_end = formatDate;
			this.setState({
				repeatEnd: date,
			});
			this.props.saveConfiguration(currentConfiguration);
		}

	}


	handleLabelChange = (label) => {
		this.setState({
			label: label
		});

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.label = label;
		this.props.saveConfiguration(currentConfiguration);
	}


	getCurrentColors = () => {
		return this.props.configuration.colors ?
			this.props.configuration.colors :
			(this.props.widget.DWZ_COLORS ?
				this.props.widget.DWZ_COLORS :
				'#C9D9D2,#28a745,#ff5369'
			);
	}


	handleColorChange = (color, index) => {
		let colors = this.state.colors;
		colors[index + 1] = color.color;
		this.setState({ colors: colors });
		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.colors = colors.join();
		this.props.saveConfiguration(currentConfiguration);
	}


	getCommandOptions = () => {


		let commandOptions;
		if (this.props.widget.DVC_IDS) {
			commandOptions = this.state.deviceCommands.map((command, index) =>
				<Option key={command.code} value={index}>{command.label}</Option>
			);
		} else {
			commandOptions = this.state.categoryCommands.map((command, index) =>
				<Option key={command.code} value={index}>{command.label}</Option>
			);
		}
		return commandOptions;
	}


	range(start, end) {
		const result = [];
		for (let i = start; i < end; i++) {
			result.push(i);
		}
		return result;
	}

	getRecurrOptions = () => {
		let options =  {
			SCM_RP_NONE: utils.translate('componentWidgetScheduler.no'),
			SCM_RP_HOUR: utils.translate('componentWidgetScheduler.hourly'),
			SCM_RP_DAY: utils.translate('componentWidgetScheduler.daily'),
			SCM_RP_WEEK: utils.translate('componentWidgetScheduler.weekly'),
			SCM_RP_MONTH: utils.translate('componentWidgetScheduler.monthly')
		  };

		let recurrOptions = []
		
		for (const [key, value] of Object.entries(options)) {
			recurrOptions.push(<Option key={key} value={key}>{value}</Option>)
		}

		return recurrOptions;
	}


	render() {

		const entries = this.props.devices.length;

		const selectParamTypeOptions = this.state.selectedCommand && this.state.selectedCommand.selectValues ? Object.keys(this.state.selectedCommand.selectValues).map((optionCode) =>
			<Option key={optionCode} value={optionCode}>{this.state.selectedCommand.selectValues[optionCode]}</Option>
		) : null;

		const selectedCommandHasOpenArgument = this.state.selectedCommand && this.state.selectedCommand.paramType === 'text';
		const selectedCommandHasSelectArgument = this.state.selectedCommand && this.state.selectedCommand.paramType === 'select';
		const selectedCommandHasPortArgument = this.state.selectedCommand && this.state.selectedCommand.havePortParam === 1;
		const isCategoryWidget = this.props.widget && this.props.widget.CAT_IDS.length > 0;
		const isCategoryDashboard = this.props.isCategoryDashboard;

		return (

			<Form>
				<Divider />
				<FormGroup>
					<Row>
						<LabelItem
							label={this.props.t('componentWidgetScheduler.label')}
							iconType=''
						/>
						<Col md={{ size: 9 }}>
							<Input
								placeholder={utils.translate('componentWidgetScheduler.enterLabel')}
								value={this.state.label}
								onChange={(label) => this.handleLabelChange(label.target.value)}
							/>
						</Col>
					</Row>
				</FormGroup>
				<FormGroup>
					<Row>
						<LabelItem
							iconType='clock-circle'
							label={utils.translate('componentDateRangeSection.startDate')}
						/>
						<Col md={{ size: 9 }}>
							<DatePicker
								locale={utils.getCurrentLanguage() === 'en' ? '' : locale}
								allowClear={false}
								onChange={(date) => this.handleDateChange(date)}
								format='YYYY-MM-DD HH:mm:ss'
								value={this.state.date ? this.state.date : this.state.formatDate}
								showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
								style={{ width: '100%' }}
								disabledDate={current => {
									let date = moment(new Date(Date.now())).format('YYYY-MM-DD HH:mm:ss');
									return moment(current).format('YYYY-MM-DD HH:mm:ss') < date;
								}}
							/>
						</Col>
					</Row>
				</FormGroup>
				<FormGroup>
					<Row>
						<LabelItem
							iconType='sync'
							label={utils.translate('componentWidgetScheduler.repeat')}
						/>
						<Col md={{ size: 9 }}>
							<Select
								showSearch
								optionFilterProp="children"
								style={{ width: '100%' }}
								placeholder={oneCommandPH}
								value={this.state.repeatPeriod && this.state.repeatMode !== 'SCM_RM_OFF' ? this.state.repeatPeriod : 'SCM_RP_NONE'}
								onChange={this.handleRecurrChange}
							>
								{this.getRecurrOptions()}
							</Select>
						</Col>
					</Row>
				</FormGroup>
				{this.state.repeatMode !== 'SCM_RM_OFF' &&
				<FormGroup>
					<Row>
						<LabelItem
							iconType='clock-circle'
							label={utils.translate('generic.endDate')}
						/>
						<Col md={{ size: 9 }}>
							<DatePicker
								locale={utils.getCurrentLanguage() === 'en' ? '' : locale}
								allowClear={true}
								onChange={(date) => this.handleEndDateChange(date)}
								format='YYYY-MM-DD HH:mm:ss'
								value={this.state.repeatEnd ? this.state.repeatEnd : undefined}
								showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
								style={{ width: '100%' }}
								disabledDate={current => {
									let date = moment(new Date(Date.now())).format('YYYY-MM-DD HH:mm:ss');
									return moment(current).format('YYYY-MM-DD HH:mm:ss') < date;
								}}
							/>
						</Col>
					</Row>
				</FormGroup>}
				{!isCategoryWidget && !isCategoryDashboard &&
					<DeviceSection
						type={'scheduledCommand'}
						label={utils.translate('generic.dvc')}
						entries={entries}
						deviceSelectPlaceholder={oneDevicePH}
						devices={this.props.devices}
						categories={this.props.categories}
						containers={this.props.containers}
						greoups={this.props.groups}
						selectedDevices={this.state.selectedDeviceId}
						categoryFilter={this.props.categoryFilter}
						containerFilter={this.props.containerFilter}
						groupFilter={this.props.groupFilter}
						loading={this.props.loading}
						disabled={this.props.loading}
						handleDeviceChange={this.handleDeviceChange}
						handleCategoryFilterChange={this.props.handleCategoryFilterChange}
						handleContainerFilterChange={this.props.handleContainerFilterChange}
						handleGroupFilterChange={this.props.handleGroupFilterChange}
						api_getDevices={this.props.api_getDevices}
					/>}
				<FormGroup>
					<Row>
						<Col sm="12" md={{ size: 6, offset: 3 }}>
							<Select
								showSearch
								disabled={!isCategoryWidget && !isCategoryDashboard && this.state.loadingCommands}
								optionFilterProp="children"
								style={{ width: '100%' }}
								placeholder={oneCommandPH}
								value={this.state.selectedCommand ? this.state.selectedCommand.label : undefined}
								onChange={this.handleCommandChange}
							>
								{this.getCommandOptions()}
							</Select>
						</Col>
					</Row>
					<Row style={{ marginTop: "20px" }}>
						{selectedCommandHasOpenArgument &&
							<Col sm="12" md={{ size: 3, offset: 3 }}>
								<Input
									value={this.state.commandParam}
									onChange={this.handleParamChange}
									placeholder={this.props.t('componentWidgetCommand.openOption')}
									onMouseDown={e => e.stopPropagation()}
								/>
							</Col>}
						{selectedCommandHasSelectArgument &&
							<Col sm="12" md={{ size: 3, offset: 3 }}>
								<Select
									value={this.state.commandSelectOption}
									showSearch
									optionFilterProp="children"
									style={{ width: '100%' }}
									placeholder={selectOptionPH}
									onChange={this.handleSelectOptionChange}
								>
									{selectParamTypeOptions}
								</Select>
							</Col>}
						{selectedCommandHasPortArgument &&
							<Col sm="12" md={{ size: selectedCommandHasSelectArgument || selectedCommandHasOpenArgument ? 3 : 4, offset: selectedCommandHasSelectArgument || selectedCommandHasOpenArgument ? '' : 4 }} onMouseDown={e => e.stopPropagation()}>
								<InputNumber
									min={0}
									defaultValue={0}
									value={this.state.portNumber}
									onChange={this.handlePortNumberChange}
									style={{ width: "100%" }}
									placeholder={this.props.t('componentWidgetCommand.portNum')}
								/>
							</Col>}
					</Row>
				</FormGroup>
				<DualColorsSection
					label={utils.translate('componentWidgetScheduler.status')}
					active={utils.translate('componentWidgetScheduler.doneOk')}
					inactive={utils.translate('componentWidgetScheduler.doneFailed')}
					colorZero={this.state.colors && this.state.colors[1]}
					colorOne={this.state.colors && this.state.colors[2]}
					handleColorChange={this.handleColorChange}
					widget={this.props.widget}
					displayAllColors={this.state.displayAllColors}
					enableAlpha={false}
				/>
			</Form>
		);
	}
}


export default withTranslation()(ScheduledCommand);
