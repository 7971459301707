import React from 'react';
import { Card, Icon, Divider, Tag } from 'antd';


const ValueCard = (props) =>
{
	return (

		<Card className='valueCard'>
			<>
				<p>
					<Icon type='alert' className='greyIcon'/>
					{props.alarm.type}
				</p>
				<p>
					<Tag color={props.alarm.status === 'NEW' ? 'volcano' : 'green'}>{props.alarm.status}</Tag>
					<Tag color={'red'}>{props.alarm.level}</Tag>
				</p>
				<Divider/>
				<p>
					Received : {props.alarm.date_creation}
				</p>
				{props.alarm.date_resolution &&
				<p>
					Resolved : {props.alarm.date_resolution}
				</p>}
				{props.alarm.description &&
				<>
					<Divider/>
				
					<i>
						<Icon
							type='info-circle' 
							className='greyIcon'
						/>
						{props.alarm.description}
					</i>
				</>}
			</>
		</Card>
	);
}


export default ValueCard;