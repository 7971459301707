import React from 'react';
import { Col, Label } from 'reactstrap';
import { Icon } from 'antd';


const LabelItem = (props) =>
{
	return (

		<Col md={{size: 3}}>
			<Label className='label'>
				{props.iconType !== undefined && <Icon type={props.iconType} className='greyIcon'/>}{props.label}
			</Label>
		</Col>
	)
};


export default LabelItem;
