import React from 'react';
import { Row, FormGroup } from 'reactstrap';

import CustomizerSwitchItem from '../../FormItems/CustomizerSwitchItem/CustomizerSwitchItem';
import CommandCustomizerTable from './CommandCustomizerTable/CommandCustomizerTable';



const CommandCustomizer = (props) =>
{
    return (

		<div>
			<CustomizerSwitchItem
				label={props.label}
				userWantsToCustomize={props.userWantsToCustomizeCommands}
				handleCustomizerToggle={props.handleCustomizerToggle}
			/>
			<FormGroup>
                <Row>
                    <CommandCustomizerTable
						userWantsToCustomizeCommands={props.userWantsToCustomizeCommands}
						widget={props.widget}
						allCommands={props.allCommands}
						selectedCommands={props.selectedCommands}
						saveCustomizationToParentState={props.saveCustomizationToParentState}
						getCurrentConfiguration={props.getCurrentConfiguration}
					/>
            	</Row>
        	</FormGroup>
		</div>
	);
}


export default CommandCustomizer;
