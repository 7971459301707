import React from 'react';
import { Modal, Button, Result, Alert } from 'antd';
import { utils } from 'Lib/utils';


const ResultModal = (props) =>
{
	const resultSubtitle = (
		<span>
			<br/><b>{utils.translate('componentResultModal.feedback')}</b><br/><br/>{props.commandResponse}<br/><br/>
			{props.downlinkIsSuccess &&
				<>
					<Alert
					  description={utils.translate('componentResultModal.note')}
					  type='warning'
					/>
					<br/>
					<b>{utils.translate('componentResultModal.info1')}{props.device.label}{utils.translate('componentResultModal.info2')}</b>
				</>}
		</span>
	);

	return (

		<Modal
			title={props.widget.DWG_LABEL}
			visible={props.modalIsVisible}
			onCancel={props.handleModalClose}
			onMouseDown={e => e.stopPropagation()}
			width={700}
			footer={[
				<Button type='primary' onClick={props.handleModalClose}>
					{utils.translate('generic.close')}
				</Button>
			]}
		>
			{props.downlinkIsSuccess &&
				<Result
					status='success'
					title={utils.translate('componentResultModal.success')}
					subTitle={resultSubtitle}
				/>
			}
			{props.downlinkIsError &&
				<Result
					status='warning'
					title={utils.translate('componentResultModal.fail')}
					subTitle={resultSubtitle}
				/>
			}
		</Modal>
	)
};


export default ResultModal;
