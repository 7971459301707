import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/fr_FR';
import { Button, Icon, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';
import { utils } from 'Lib/utils';

const toDate = () => {
	let date = new Date();
	date = moment(moment(date).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss')
	return date;
}

const DateSelected = (props) => {
	return (

		<div className='date-selected'>
			<DatePicker
				placeholder={props.t('componentDateRangeSection.firstData')}
				locale={utils.getCurrentLanguage() === 'en' ? '' : locale}
				allowClear={false}
				onChange={(dateStart) => props.setDateStart(dateStart)}
				onOk={() => props.updateGraph()}
				format='YYYY-MM-DD HH:mm:ss'
				value={props.dateStart === "1900-01-01 00:00:00" ? "" : moment(moment(props.dateStart).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss')}
				showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
			/>
			<DatePicker
				placeholder={props.t('componentDateRangeSection.lastData')}
				locale={utils.getCurrentLanguage() === 'en' ? '' : locale}
				allowClear={false}
				onChange={(dateStop) => props.setDateStop(dateStop)}
				onOk={() => props.updateGraph()}
				format='YYYY-MM-DD HH:mm:ss'
				value={props.dateStop === "9999-12-31 00:00:00" ? toDate() : props.dateStop === "3000-01-01 00:00:00" ? "" : moment(moment(props.dateStop).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss')}
				showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
			/>
			<Tooltip title={utils.translate('componentDateRangeSection.allData')} placement='left'>
				<Button
					className='button-all-data'
					shape='circle'
					onClick={() => props.getAllData()}
					value='All'><Icon type='column-width' />
				</Button>
			</Tooltip>
		</div>
	)
};


export default withTranslation()(DateSelected);
