import React, { Component } from 'react';
import { CardBody, Container } from 'reactstrap';
import { Row, Col, Card } from 'antd';
import { utils } from 'Lib/utils';

import './NoAccess.css';
class NoAccess extends Component
{

	render()
	{
		const hasImage = this.props.appSettings.app_url_icon !== window.ENV.REACT_APP_CS_URL;
		return (
			<Container
				fluid
				style={{
					width: '100%',
				}}
			>
				<Row
					type='flex'
					justify="center"
				>
					<Col
						xl={24}
						lg={24}
						md={24}
						sm={24}
						xs={24}
						className='text-center'
					>
						<img
							src={window.location.origin + '/403-error.png'}
							alt=''
							style={{ maxWidth: '100%', borderRadius: '15px', marginTop:'5px' }}
						/>
					</Col>
				</Row>
				<Row
					type='flex'
					justify="center"
				>
					{hasImage &&
						<>
							<Col
								xl={5}
								lg={24}
								md={24}
								sm={24}
								xs={24}
								className='text-center'
							>
								<img
									src={this.props.appSettings.app_url_icon}
									alt=''
									style={{ maxHeight: '1',maxWidth: '100%', borderRadius: '15px', marginTop: '15px' }}
								/>
							</Col>
						</>}
					<Col
						xl={{ span: hasImage ? 5 : 24}}
						lg={{ span: 24, offset: 0 }}
						md={{ span: 24, offset: 0 }}
						sm={{ span: 24, offset: 0 }}
						xs={{ span: 24, offset: 0 }}
						className='text-center'
					>
						<Card
							bordered={false}
							style={{ backgroundColor: this.props.appSettings.color, marginTop: '5px'  }}
						>
							<CardBody
							id='noAccessCard'
							style={{ backgroundColor: this.props.appSettings.color, margin: '-10px'  }}>
								<div style={{ fontSize: '15px' }}>
									<p>{utils.translate('componentNoAccess.1')}</p>
									<p>{this.props.appSettings.app_label ? this.props.appSettings.app_label : ''}</p>
									<p>{utils.translate('componentNoAccess.2')}</p>
									<small>IoT App Studio®</small>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default NoAccess;

