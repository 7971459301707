export const SkinModel = {
	SKIN_DEFAULT: {
		value: 'SKIN_DEFAULT',
		label: 'Default',
		theme: 'plaza',
		image: 'default',
		maxAfter: 3
	},
	SKIN_WATER: {
		value: 'SKIN_WATER',
		label: 'Water',
		theme: 'car',
		image: 'water',
		maxBefore: 5,
		maxAfter: 3
	},
	SKIN_GAZ: {
		value: 'SKIN_GAZ',
		label: 'Gaz',
		theme: 'minimal',
		image: 'gaz',
		maxBefore: 5,
		maxAfter: 3
	},
	SKIN_ELECTRICITY: {
		value: 'SKIN_ELECTRICITY',
		label: 'Electricity',
		theme: 'digital',
		image: 'electricity',
		maxBefore: 5,
		maxAfter: 3
	},
	SKIN_LINKY: {
		value: 'SKIN_LINKY',
		label: 'Linky',
		theme: 'train-station',
		image: 'linky',
		maxBefore: 5,
		maxAfter: 3
	},
	SKIN_GAZPAR: {
		value: 'SKIN_GAZPAR',
		label: 'Gazpar',
		theme: 'gazpar',
		image: 'gazpar',
		maxBefore: 5,
		maxAfter: 3
	},
	SKIN_HEAT: {
		value: 'SKIN_HEAT',
		label: 'Heat',
		theme: 'heat',
		image: 'heat',
		maxBefore: 5,
		maxAfter: 3
	},
};