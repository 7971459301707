import React, { Component } from 'react';

import EmptyWidget from '../../WidgetLib/EmptyWidget/EmptyWidget';
import ClassicValue from './ClassicValue/ClassicValue';
import BooleanValue from './BooleanValue/BooleanValue';
import IndexValue from './IndexValue/IndexValue';

import { OperatorModel } from 'Models/OperatorModel.js';
import { OperandSourceModel } from 'Models/OperandSourceModel.js';

import { DEFAULT_PRECISION } from 'Config/config';

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils.js';

import './Value.css';


class Value extends Component
{

    constructor(props)
	{
        super(props);

        this.state = {
			config: {},
            attribute: {},
            device: {},
			loading: false,
			valueSource: undefined,
			secondOperandValue: undefined,
			secondOperandLabel: undefined,
        };

		this.timerId = null;
		this.componentIsMounted = false;
    }


    componentDidMount()
	{
		this.findValueSource(this.getValue);
		this.setOperationParameters();

		this.componentIsMounted = true;
        this.timerId = utils.setRefresh(this.props.widget.DWG_REFRESH_FREQ, this.getValue);
    }


    componentDidUpdate(prevProps)
	{
		const { widget } = this.props;

		if (prevProps.widget.DVC_ID !== widget.DVC_ID ||
			prevProps.widget.CFG_ID !== widget.CFG_ID ||
			prevProps.widget.VAL_ID !== widget.VAL_ID ||
			JSON.stringify(prevProps.widget.JWC) !== JSON.stringify(widget.JWC))
			{
            	this.findValueSource(this.getValue);
        	}

		if (prevProps.widget.DWG_SOURCE_OPERAND2 !== widget.DWG_SOURCE_OPERAND2 ||
			prevProps.widget.DWG_SOURCE_OPERAND2_ID !== widget.DWG_SOURCE_OPERAND2_ID)
			{
				this.setOperationParameters();
			}
    }


    componentWillUnmount()
	{
		this.componentIsMounted = false;
        if (this.timerId !== null) { utils.clearRefresh(this.timerId); }
    }


	setConfig = (device) =>
	{
		let config = this.getCurrentConfigOfDevice(device);
		if (this.componentIsMounted) { this.setState({device: device[0], config: config}); }
	}


	setAttribute = (value) =>
	{
		if (this.componentIsMounted) { this.setState({ attribute: value[0] }); }
	}


	// setDatatypeOperand = (device) =>
	// {
	// 	// if (this.componentIsMounted) { this.setState({ secondOperandValue: value[0] }); }
	// }


	setAttributeOperand = (value) =>
	{
		if (this.componentIsMounted && value && value['0'])
		{
			const attributeObject = value['0']['attributeObject'];

			this.setState({
				secondOperandValue: value['0']['value'],
				secondOperandLabel: attributeObject && attributeObject['label'],
			});
		}
	}


	valueSourceIsAtr = () =>
	{
		return this.state.valueSource === 'ATR';
	}


	valueSourceIsCfg = () =>
	{
		return this.state.valueSource === 'CFG';
	}


	valueSourceIsUndefined = () =>
	{
		return this.state.valueSource === undefined;
	}


	shouldApplyOperation = () =>
	{
		const { widget } = this.props;

		return  widget.DWG_SOURCE_OPERAND2 !== OperandSourceModel.NONE &&
			widget.DWG_OPERATOR !== OperatorModel.NONE && widget.DWG_SOURCE_OPERAND2_ID;
	}


	resetAttribute = () =>
	{
		this.setState({ attribute: {} });
	}


	resetConfig = () =>
	{
		this.setState({ config: {} });
	}


	resetDevice = () =>
	{
		this.setState({ device: {} });
	}


	findValueSource = (callback) =>
	{
		this.setState({
			valueSource: this.props.widget.CFG_ID ?
				'CFG' : (this.props.widget.VAL_ID ? 'ATR' : undefined)
		}, () =>
		{
			callback();
		});
	}


	setOperationParameters = () =>
	{
		const { widget } = this.props;

		if (this.shouldApplyOperation())
		{
			switch (widget.DWG_SOURCE_OPERAND2)
			{
				case OperandSourceModel.ATR:
					this.getAttribute(
						widget.DWG_SOURCE_OPERAND2_ID,
						this.setAttributeOperand
					);
					break;

				// case OperandSourceModel.CFG:
				// 	this.getDatatype(
					// 		widget.DWG_SOURCE_OPERAND2_ID,
					// 		this.setDatatypeOperand
					// 	);

				default:
					break;
			}
		}
	}


    getValue = () =>
	{
		if (this.valueSourceIsUndefined()) { return; }

		this.setState({ loading: true }, () =>
		{
			if (this.valueSourceIsCfg())
			{
				this.resetAttribute();
				this.getDatatype(this.props.widget.DVC_ID, this.setConfig);
			}

			else if (this.valueSourceIsAtr())
			{
				this.resetDevice();
				this.resetConfig();
				this.getAttribute(this.props.widget.VAL_ID, this.setAttribute);
			}
		});
    }


	getDatatype = (id, callback) =>
	{
		request.get(
			'device/' + id,
			{},
			(device) =>
			{
				callback(device);
			},
			() => console.error('load datatype failed'),
			() => this.setState({ loading: false })
		);
	}


	getAttribute = (id, callback) =>
	{
		request.get(
			'value',
			{
				value: id,
			},
			(value) =>
			{
				callback(value);
			},
			() => console.error('load value failed'),
			() => this.setState({ loading: false })
		);
	}


    getCurrentConfigOfDevice = (device) =>
	{
        let configs = device[0].configs;

        for (let i = 0; i < configs.length; i++)
		{
            if (configs[i].id === this.props.widget.CFG_ID) { return configs[i]; }
        }
    }


	applyOperation = (value) =>
	{
		const { widget } = this.props;
		let firstOperandValue = parseFloat(value);
		let secondOperandValue = parseFloat(this.state.secondOperandValue);

		if (this.shouldApplyOperation() && secondOperandValue)
		{
			const operations = {
				[OperatorModel.TIMES]: () => { return firstOperandValue * secondOperandValue },
				[OperatorModel.DIV]: () => { return firstOperandValue / secondOperandValue },
				[OperatorModel.PLUS]: () => { return firstOperandValue + secondOperandValue },
				[OperatorModel.MINUS]: () => { return firstOperandValue - secondOperandValue },
			};

			value = operations[widget.DWG_OPERATOR]();
		}

		return value;
	}


	getOperatorSign = (operator) =>
	{
		const operatorSign = ' / ';

		const operatorSigns = {
			[OperatorModel.TIMES]: ' * ',
			[OperatorModel.DIV]: ' / ',
			[OperatorModel.PLUS]: ' + ',
			[OperatorModel.MINUS]: ' - ',
		};

		return operatorSigns[operator] || operatorSign;
	}



	getWidgetImage = (value) => {
		let all = [];
		let image = '';
		let label = '';
		let array = this.props.widget.JWI;

		if(array && array.length > 0){
			if(value !== undefined && value !== '' && !isNaN(value)){
				Object.values(array).map((item, index) => {
					const itemVal = Object.keys(JSON.parse(item.JWI_VAL));
					const itemKey = Object.values(JSON.parse(item.JWI_VAL));
	
					const operator1 = itemKey[0] === '=' ? '===' : itemKey[0];
					const operator2 = itemKey[1] === '=' ? '===' : itemKey[1];
					// eslint-disable-next-line
					if(!itemKey[1] && eval(value + operator1 + itemVal[0])){
						image = item.JWI_IMAGE;
						label = item.JWI_LABEL;
						all.push(image);
						all.push(label);
						// eslint-disable-next-line
					} else if(eval(value + operator1 + itemVal[0]) && eval(value + operator2 + itemVal[1])) {
						image = item.JWI_IMAGE;
						label = item.JWI_LABEL;
						all.push(image);
						all.push(label);
					}
					return array;
				})
			}
			
		} else {
			image = this.props.widget.DWV_IMAGE;
			all.push(image);
			all.push(label);
		}
		return all;
	}


    render()
	{
		const { config, loading, device, attribute, secondOperandLabel } = this.state;
		const { widget, size } = this.props;

		const precision = widget && widget.DWV_PRECISION === undefined ?
			DEFAULT_PRECISION : widget.DWV_PRECISION;
		const showData = (config !== undefined && Object.entries(config).length !== 0) ||
			(attribute !== undefined && Object.entries(attribute).length !== 0);
		const showEmptyWidget = !loading && utils.isConfigMode() && !showData;
		const configId = config !== undefined ? config.id : undefined;
		let configLabel = config && configId && widget.JWC && widget.JWC[configId] && widget.JWC[configId].JWC_LABEL !== '' ? widget.JWC[configId].JWC_LABEL : config && config.label ? config.label : '';
		const configUnit = config && configId && widget.JWC && widget.JWC[configId] && widget.JWC[configId].JWC_UNIT !== '' ? widget.JWC[configId].JWC_UNIT : config && config.unite_D1 ? config.unite_D1 : '';
		const coeffA = config && configId && widget.JWC && widget.JWC[configId] ? widget.JWC[configId].JWC_COEFFA : 1;
		const coeffB = config && configId && widget.JWC && widget.JWC[configId] ? widget.JWC[configId].JWC_COEFFB : 0;
		let configValue = config === undefined || !config.value ? 0 : this.applyOperation(parseFloat(coeffA) * parseFloat(config.value) + parseFloat(coeffB));
		configValue = config === undefined || config.value === undefined ? undefined : Number(configValue.toFixed(2)); // cast to Number removes insignificant trailing zeroes
		configLabel = device && utils.getValueFromTags(configLabel, device);
		const attributeValue = attribute && this.applyOperation(attribute.value);
		const attributeLabel = attribute && attribute.attributeObject && attribute.attributeObject.label;
		let widgetLabel = (configLabel || attributeLabel);

		widgetLabel = this.shouldApplyOperation() && secondOperandLabel ? widgetLabel + this.getOperatorSign(widget.DWG_OPERATOR) + secondOperandLabel : widgetLabel;
		let widgetValue = configValue === undefined ? (attributeValue === undefined ? undefined : attributeValue) : configValue;
		widgetValue = widgetValue && !isNaN(widgetValue) ? Number(widgetValue).toFixed(precision) : widgetValue;
		widgetValue = widgetValue && !isNaN(widgetValue) ? parseFloat(widgetValue) : widgetValue;
		const showHelp = !loading && this.shouldApplyOperation() && !showEmptyWidget && (isNaN(widgetValue) || widgetValue === Infinity);
		const showLastDate = widget && widget.DWV_SHOW_LAST_DATE === '1';
		let deviceLabel = config && configId && widget.JWC && widget.JWC[configId] && widget.JWC[configId].JWC_DEVICE_LABEL !== '' ? widget.JWC[configId].JWC_DEVICE_LABEL : device && device.label ? device.label : '';
		deviceLabel = device && utils.getValueFromTags(deviceLabel, device);

		return (

			<>
				{showEmptyWidget &&
				<EmptyWidget
					{...this.props}
					callToAction={utils.translate('componentWidgetValue.start')}
				/>}

				{!showEmptyWidget &&
				<div className='value' style={{color: this.props.getWidgetColors('text'), fontSize: this.props.getWidgetFontSize()}}>
					{widget.DWV_TYPE === 'value' &&
					<ClassicValue
						size={size}
						device={device}
						config={config}
						widget={widget}
						label={widgetLabel}
						deviceLabel={deviceLabel}
						getWidgetImage={this.getWidgetImage}
						getWidgetLabel={this.getWidgetLabel}
						value={widgetValue}
						configUnit={configUnit}
						showHelp={showHelp}
						getWidgetId={this.props.getWidgetId}
						showLastDate={showLastDate}
						getWidgetFontSize={this.props.getWidgetFontSize}
					/>}
					{widget.DWV_TYPE === 'boolean' &&
					<BooleanValue
						size={size}
						device={device}
						config={config}
						widget={widget}
						label={widgetLabel}
						deviceLabel={deviceLabel}
						value={widgetValue}
						configUnit={configUnit}
						getWidgetId={this.props.getWidgetId}
						showLastDate={showLastDate}
						getWidgetFontSize={this.props.getWidgetFontSize}
						getWidgetImage={this.getWidgetImage}
					/>}
					{widget.DWV_TYPE === 'index' &&
					<IndexValue
						size={size}
						device={device}
						config={config}
						widget={widget}
						label={widgetLabel}
						deviceLabel={deviceLabel}
						value={widgetValue}
						configUnit={configUnit}
						getWidgetId={this.props.getWidgetId}
						showLastDate={showLastDate}
						getWidgetFontSize={this.props.getWidgetFontSize}
						getWidgetImage={this.getWidgetImage}
					/>}
				</div>}
			</>
        );
    }
}


export default Value;
