import React from 'react';
import { ReactComponent as MarkerIcon } from 'Assets/Icons/marker.svg';

import { allAvailableIcons } from './Icons';

import './SvgIcon.css';


const SvgIcon = ({ type, onClick, height, fill, styleClass }) =>
{
	let Icon = MarkerIcon;
	
	Object.values(allAvailableIcons).forEach(iconFamily =>
	{
		if (iconFamily[type] !== undefined) { Icon = iconFamily[type]; } 
	});
	
	return (
		
		<Icon
			key={type}
			onClick={() => onClick && onClick(type)}
			className={styleClass}
			style={{
				height: height,
				fill: fill,
				cursor: 'pointer',
			}}
		/>
    );
};


export default SvgIcon;