import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Select } from 'antd';

import SuperSelect from 'Components/Studio/StudioLib/SuperSelect/SuperSelect';
import LabelItem from '../../FormItems/LabelItem/LabelItem';

const { Option } = Select;


const GroupSection = (props) => 
{

    const {
        iconType,
        label,
        selectedGroupIds,
        groupSelectMode,
        groupSelectPlaceholder,
        handleGroupChange,
        groups,
        loading,
    } = props;


    return (

        <>
            <FormGroup>
                <Row
                    className='align-items-center'
                >
                    <LabelItem
                        iconType={iconType}
                        label={label}
                    />
                    <Col md={{size: 9}}>
                        <SuperSelect
                            value={selectedGroupIds}
                            onChange={handleGroupChange}
                            allowClear
                            showSearch
                            showArrow
                            optionFilterProp='children'
                            mode={groupSelectMode}
                            style={{ width: '100% '}}
                            placeholder={groupSelectPlaceholder}
                            loading={loading}
                            maxTagCount={20}
                            disabled={loading}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                        {groups && groups.map((group) => 
                            <Option
                                key={group.id}
                                value={group.id}
                            >
                                {group.label}
                            </Option>
                        )}
                        </SuperSelect>
                    </Col>
                </Row>
            </FormGroup>
        </>
    )
};

export default GroupSection;