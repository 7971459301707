import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Select } from 'antd';

import SuperSelect from 'Components/Studio/StudioLib/SuperSelect/SuperSelect';
import LabelItem from '../../FormItems/LabelItem/LabelItem';
import { utils } from 'Lib/utils';

const { Option } = Select;


const ContainerSection = (props) =>
{

	const {
		iconType,
		label,
		containerSelectRef,
		selectedContainerIds,
		containerSelectMode,
		containerSelectPlaceholder,
		handleContainerChange,
		containers,
		loading
	} = props;

	return (

		<>
			<FormGroup>
				<Row
					className='align-items-center'
				>
					<LabelItem
						iconType={iconType}
						label={label}
					/>
					<Col md={{size: 9}}>
						<SuperSelect
							ref={containerSelectRef}
							value={selectedContainerIds}
							onChange={handleContainerChange}
							allowClear
							showSearch
							showArrow
							optionFilterProp='children'
							mode={containerSelectMode}
							style={{ width: '100%' }}
							placeholder={containerSelectPlaceholder}
							loading={loading}
							maxTagCount={20}
							disabled={loading}
							filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						>
						{containers && containers.map((container) =>
							<Option
								key={container.id}
								value={container.id}
								disabled={!container.attribs}
								title={!container.attribs && utils.translate('componentContainerSection.attributes')}
							>
								{container.name}
							</Option>
						)}
						</SuperSelect>
					</Col>
				</Row>
			</FormGroup>
		</>
	)
};


export default ContainerSection;
