import { TOGGLE_DRAWER, TOGGLE_THEME_BUILDER } from "actions/types";

const initialState = {
  isDrawerVisible:false,
  isThemeBuilderOpen: false,
};

// Listen to the alerts actions & modify the states if needed
export default function drawerReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case TOGGLE_DRAWER:
      return {...state,   
        isDrawerVisible: state.isDrawerVisible ? false : true,
        isThemeBuilderOpen: false,
      };

    case TOGGLE_THEME_BUILDER:
      return {
      ...state,
      isDrawerVisible : true,
      isThemeBuilderOpen : state.isThemeBuilderOpen ? false : true,
    };
    
    default:
      return state;
  }
}
