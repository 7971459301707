import React from 'react';
import { Row, Col, FormGroup, Form } from 'reactstrap';
import { Divider } from 'antd';

import ContainerSection from '../ContainerSection/ContainerSection';
import AttributeSection from '../AttributeSection/AttributeSection';

import LabelItem from '../../FormItems/LabelItem/LabelItem';
import { utils } from 'Lib/utils';


const OperandSection = (props) =>
{

	return (

		<FormGroup>
			<Row
				className='align-items-center'
			>
				<LabelItem
					label={utils.translate('componentOperandSection.operand')}
					iconType='number'
				/>
				<Col
					md={{size: 4}}
					className='text-center'
				>
					<Form>
						<ContainerSection
							{...props}
							label={utils.translate('generic.ctr')}
						/>
						<Divider/>
						<AttributeSection
							{...props}
							label={utils.translate('generic.attr')}
						/>
					</Form>
				</Col>
				<Col
					md={{size: 4}}
					className='text-center'
				>
				</Col>
			</Row>
		</FormGroup>
	)
};


export default OperandSection;
