import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Select, Tooltip, Icon } from 'antd';

import SuperSelect from 'Components/Studio/StudioLib/SuperSelect/SuperSelect';
import LabelItem from '../../FormItems/LabelItem/LabelItem';
import { utils } from 'Lib/utils';

const { Option } = Select;


const MultipleAttributeSection = (props) =>
{

	const {
		label,
		iconType,
		attributeOptions,
		selectedValueIds,
		handleAttributeChange,
		attributeSelectMode,
		attributeSelectPlaceholder,
		loading,
		disabled,
		onlyAllowNumericAttributes,
	} = props;


	const attributeOptionIsDisabled = (attribute) =>
	{
		return onlyAllowNumericAttributes &&
			attribute && isNaN(attribute.value);
	}


	const attributeIsNotHistorized = (attribute) =>
	{
		return attribute && attribute.is_historized !== '1';
	}


	const nonHistorizedWarning = (
		<Tooltip
			title={utils.translate('componentMultipleAttributeSection.notHistorized')}
			placement='right'
		>
			<Icon
				type='warning'
				className='greyIcon'
				theme="twoTone"
				twoToneColor='orange'
			/>
		</Tooltip>
	);


	return (

		<>
			<FormGroup>
				<Row
					className='align-items-center'
				>
					<LabelItem
						label={label}
						iconType={iconType}
					/>
					<Col md={{size: 9}}>
						<Tooltip
							title={
								disabled ?
								utils.translate('MultipleAttributeSection.noContainer') : ''
							}
						>
							<SuperSelect
								value={selectedValueIds}
								onChange={handleAttributeChange}
								mode={attributeSelectMode}
								placeholder={attributeSelectPlaceholder}
								optionFilterProp='children'
								loading={loading}
								disabled={disabled}
								showSearch
								allowClear
								showArrow
								style={{ width: '100%' }}
								filterOption={(input, option) => option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0}
							>
							{attributeOptions &&
							attributeOptions.map((attribute) =>
								<Option
									key={attribute.value_id}
									value={attribute.value_id}
									disabled={attributeOptionIsDisabled(attribute)}
									title={
										attributeOptionIsDisabled(attribute) &&
										utils.translate('MultipleAttributeSection.nonNumerical')
									}
								>
									{attribute.label} {' '}
									{!attributeOptionIsDisabled(attribute) &&
										attributeIsNotHistorized(attribute) ?
											nonHistorizedWarning : null}
								</Option>
							)}
							</SuperSelect>
						</Tooltip>
					</Col>
				</Row>
			</FormGroup>
		</>
	);
};


export default MultipleAttributeSection;
