import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';
import { withTranslation } from 'react-i18next';

import EmptyWidget from '../../WidgetLib/EmptyWidget/EmptyWidget';
import Loader from '../../WidgetLib/Loader/Loader';
import Marker from './Marker/Marker';
import DeviceDataExporter from '../../WidgetLib/DeviceDataExporter/DeviceDataExporter';
import Legend from 'Components/Studio/Dashboard/Widgets/WidgetLib/Legend/Legend';

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils.js';
import { GMAP_KEY } from 'Config/config';

import './Map.css';

const decodePolyline = require('decode-google-map-polyline');


class Map extends Component
{

    constructor(props)
	{
        super(props);

        this.state = {
            devices: [],
            center: {
                lat: 59.95,
                lng: 30.33
            },
            zoom: 8,
			loading: false,
			dateStart: undefined,
			dateStop: undefined,
			allDeviceIds: [],
        };

		this.timerId = null;
		this.componentIsMounted = false;
    }


    componentDidMount()
	{
		this.componentIsMounted = true;

		if (!this.props.widget.DVC_IDS) { return; }
		if (this.props.widget.DVC_IDS !== undefined) { this.api_getDevices(); }

        this.timerId = utils.setRefresh(this.props.widget.DWG_REFRESH_FREQ, this.api_getDevices);
		this.setDateRangeToState();
    }


    componentDidUpdate(prevProps)
	{
        if (prevProps.widget.DVC_IDS !== this.props.widget.DVC_IDS ||
			JSON.stringify(prevProps.widget.JWD) !== JSON.stringify(this.props.widget.JWD) ||
			JSON.stringify(prevProps.widget.DTY_IDS) !== JSON.stringify(this.props.widget.DTY_IDS) ||
			JSON.stringify(prevProps.widget.ATR_IDS) !== JSON.stringify(this.props.widget.ATR_IDS) ||
			prevProps.widget.DWM_DATE_START !== this.props.widget.DWM_DATE_START ||
			prevProps.widget.DWM_DATE_STOP !== this.props.widget.DWM_DATE_STOP ||
			prevProps.widget.DWM_DATE_RANGE !== this.props.widget.DWM_DATE_RANGE ||
			prevProps.widget.DTH_THRESHOLD_VALUE !== this.props.widget.DTH_THRESHOLD_VALUE ||
			prevProps.widget.DTH_OPERATOR !== this.props.widget.DTH_OPERATOR ||
			prevProps.widget.DTH_INACTIVE_COLOR !== this.props.widget.DTH_INACTIVE_COLOR ||
			prevProps.widget.DTH_ACTIVE_COLOR !== this.props.widget.DTH_ACTIVE_COLOR ||
			prevProps.widget.DTH_DEFAULT_COLOR !== this.props.widget.DTH_DEFAULT_COLOR ||
			prevProps.widget.GZO_IDS !== this.props.widget.GZO_IDS
		)
		{
			this.setDateRangeToState();
			this.api_getDevices();
		}
    }


    componentWillUnmount()
	{
		this.componentIsMounted = false;
        if (this.timerId !== null) { utils.clearRefresh(this.timerId); }
    }


	api_getDevices = () =>
	{
		let devices = [];
		let index = 0;

		if (!this.props.widget.DVC_IDS)
		{
			this.setState({
				loading: false,
				devices: []
			});

			return;
		}

		const deviceIdsArray = this.props.widget.DVC_IDS.split(',') || [];

		this.setState({
			loading: true,
			devices: []
		}, () =>
		{
			deviceIdsArray.forEach((deviceId) =>
			{
				if (!deviceId) return;

				request.get(
					'device/' + deviceId,
					{},
					(api_device) => {
						let device = api_device[0];
						let alternativeGeoloc = this.getAlternativeGeoloc(api_device[0]);
	
						if(alternativeGeoloc.lat !== 'undefined' && alternativeGeoloc.lon !== 'undefined'){
							device.alternativeGeoloc = alternativeGeoloc;
						}
						devices.push(device);
					},
					() => console.log('load device failed'),
					() =>
					{
						index ++;

						if (index === deviceIdsArray.length && this.componentIsMounted)
						{
							this.setState({
								devices: devices,
								loading: false,
								allDeviceIds: deviceIdsArray,
							}, () => this.setBounds());
						}
					}
				);
			});
		});
    }


    setBounds = (geolocData = undefined) =>
	{
		if (!this.state.devices.length) { return; }
	
        if (this.state.devices.length === 1 && geolocData === undefined && this.props.widget.DTH_SHOW_GEOLOC_ONLY !== '1')
		{
            this.setState({
                center: {
					lat: parseFloat(this.state.devices[0].latitude),
					lng: parseFloat(this.state.devices[0].longitude),
				},
                zoom: 10,
            })

            return;
        }

        let latitudes = geolocData === undefined ? this.state.devices.map(device => device.latitude)
		: geolocData.map(point => point.latitude);
        let longitudes = geolocData === undefined ? this.state.devices.map(device => device.longitude)
		: geolocData.map(point => point.longitude);
        let bounds = {
            se: {
                lat: Math.min(...latitudes),
                lng: Math.max(...longitudes)
            },
            nw: {
                lat: Math.max(...latitudes),
                lng: Math.min(...longitudes)
            }
        }

        const {center, zoom} = Object.keys(bounds).length > 0 ?
            fitBounds(bounds, {width: this.props.size.width, height: this.props.size.height}) :
            this.state;
		if(this.props.widget.DTH_SHOW_GEOLOC_ONLY !== '1'){
			this.setState({ center: center, zoom: zoom || 10 });
		}
       
    }


	getMapObjects = (map, maps) =>
	{
		this.renderTracking(map, maps);
		this.renderGeoZones(map, maps);
	}


	renderTracking = (map, maps) =>
	{
		const { widget } = this.props;
		if (widget.JWD === undefined || widget.DVC_IDS === undefined) { return; }
		const deviceIdsArray = widget.DVC_IDS.split(',');

		Object.keys(widget.JWD).forEach(key =>
		{

			const device = widget.JWD[key];
			const showTracking = device.JWD_SHOW_TRACKING === '1';
			const showPolyline = device.JWD_SHOW_POLYLINE === '1';

			if (showTracking || showPolyline)
			{
				const rawColor = device.JWD_TRACKING_COLOR === '' ? '#000000' : device.JWD_TRACKING_COLOR;
				const markerColor = rawColor.substring(1);
				let path = [];
				let polylineInfoWindowContent = '';
				let polylineInfowindow = new maps.InfoWindow();

				request.get(
					'geoloc',
					{
						device_id: !this.props.isCategoryDashboard ? key : deviceIdsArray[0],
						date_start: this.state.dateStart,
						date_stop: this.state.dateStop,
					},
					(geolocData) =>
					{
						geolocData = geolocData.filter(point => point.latitude !== '0' && point.longitude !== '0');
						const geolocDataLength = geolocData.length;

						geolocData.forEach((point, index) =>
						{
							const lat = parseFloat(point.latitude);
							const lng = parseFloat(point.longitude);

							if (showPolyline)
							{
								path.push(
									{
										lat: lat,
										lng: lng,
									}
								);

								if (index === geolocDataLength - 1) // only at final iteration, no need to overwrite each loop
								{
									polylineInfoWindowContent = (
										'<span style="float: right; text-align: center">' +
											'<span style="color: ' + rawColor + ';">' +
											point.device.label + '</span></span>'
									);

									polylineInfowindow = new maps.InfoWindow({
										content: polylineInfoWindowContent
									});
								}
							}

							if (showTracking && geolocDataLength > 1) // don't show if only last postion is known
							{
								let marker = new maps.Marker({
									icon: {
										url: 'https://chart.googleapis.com/chart?chst=d_simple_text_icon_left&chld=|14|000|location|24|fff|' + markerColor,
										size: new maps.Size(20, 20),
									},
									opacity: 0.4,
									position: new maps.LatLng({lat: lat, lng: lng}),
								});

								let infoWindowContent = (
									'<span style="float: right; text-align: center">' +
										'<span style="color: ' + rawColor + ';">' +
										point.device.label + '</span><br/><b>' +
										point.date + '</b><br/><hr>' +
										point.latitude + ' ' + point.longitude +
									'</span>'
								);

								let infowindow = new maps.InfoWindow({
									content: infoWindowContent
								});

								maps.event.addListener(marker, 'mouseover', function() {
									infowindow.open(map, marker);
								});
								maps.event.addListener(marker, 'mouseout', function() {
									infowindow.close(map, marker);
								});
								maps.event.addListener(marker, 'click', function() {
									let latLng = marker.getPosition();
									map.setZoom(15);
									map.panTo(latLng);
								});

								marker.setMap(map);
							}
						});

						if (showPolyline)
						{
							let geodesicPolyline = new maps.Polyline({
								path: path,
								geodesic: true,
								strokeColor: rawColor,
								strokeOpacity: 0.5,
								strokeWeight: 5,
							});

							geodesicPolyline.setMap(map);

							maps.event.addListener(geodesicPolyline, 'mouseover', function(event) {
        						polylineInfowindow.setPosition(event.latLng);
        						polylineInfowindow.open(map);
    						});
							maps.event.addListener(geodesicPolyline, 'mouseout', function() {
        						polylineInfowindow.close(map);
    						});
						}
					},
					() => {},
					() => {}
				);
			}
		});
	}


	renderGeoZones = (map, maps) =>
	{
		const { widget } = this.props;
		if (widget.GZO_IDS === undefined) { return; }

		widget.GZO_IDS.split(',').forEach((geoZoneId) =>
		{
			if (geoZoneId)
			{
				request.get(
					'geozone/' + geoZoneId,
					{},
					(geoZone) =>
					{
						let infoWindowContent = (
							'<span style="float: right; text-align: center">' +
								'<span style="color: ' + geoZone.color + ';"><b>' +
								geoZone.label + this.props.t('componentWidgetMap.html')
						);

						let infowindow = new maps.InfoWindow({
							content: infoWindowContent,
							disableAutoPan: true
						});

						let marker = new maps.Marker({
							map: map
						});

						if (geoZone.type === 'POLYLINE')
						{
							let polygon = new maps.Polygon({
								paths: decodePolyline(geoZone.latlng_encode),
								strokeColor: geoZone.color,
								strokeOpacity: 0.8,
								strokeWeight: 1,
								fillColor: geoZone.color,
								fillOpacity: 0.2
							});

							polygon.setMap(map);

							let bounds = new maps.LatLngBounds();

	    					for (var i = 0; i < polygon.getPath().getLength(); i++) {
								bounds.extend(polygon.getPath().getAt(i));
							}

							maps.event.addListener(polygon, 'mouseover', function() {
								infowindow.setPosition(bounds.getCenter());
								infowindow.open(map);
								this.setOptions({ fillOpacity: 0.4 });
							});
							maps.event.addListener(polygon, 'mouseout', function() {
								infowindow.close();
								this.setOptions({ fillOpacity: this.fillOpacity === 0.01 ? 0.01 : 0.2 });
							});
							maps.event.addListener(polygon, 'click', function() {
								let latLng = bounds.getCenter();
								map.fitBounds(bounds);
								map.panTo(latLng);
							});
							maps.event.addListener(polygon, 'rightclick', function() {
								infowindow.close();
								this.setOptions({ fillOpacity: 0.01 });
							});
						}

						else if (geoZone.type === 'CIRCLE')
						{
							let circleCenter = {};
							let centerCoordinateString = '';
							const regExp = /\(([^)]+)\)/;
							let matches = regExp.exec(geoZone.latlng);

							if (matches !== undefined) { centerCoordinateString = matches[1]; }// getting substring between ()
							centerCoordinateString = centerCoordinateString.replace(' ', '');
							const centerCoordinateArray = centerCoordinateString.split(',');

							if (centerCoordinateArray !== undefined &&
								centerCoordinateArray[0] !== undefined &&
								centerCoordinateArray[1] !== undefined)
							{
								circleCenter = {
									lat: parseFloat(centerCoordinateArray[0]),
									lng: parseFloat(centerCoordinateArray[1])
								};
							}

							let circle = new maps.Circle({
								center: circleCenter,
								radius: parseFloat(geoZone.radius),
								strokeColor: geoZone.color,
								strokeOpacity: 0.8,
								strokeWeight: 1,
								fillColor: geoZone.color,
								fillOpacity: 0.2
							});

							circle.setMap(map);

							maps.event.addListener(circle, 'mouseover', function() {
								marker.setPosition(this.getCenter());
								infowindow.open(map, marker);
								marker.setVisible(false);
								this.setOptions({ fillOpacity: 0.4 });
							});
							maps.event.addListener(circle, 'mouseout', function() {
								infowindow.close(map, circle);
								this.setOptions({ fillOpacity: this.fillOpacity === 0.01 ? 0.01 : 0.2 });
							});
							maps.event.addListener(circle, 'click', function() {
								let latLng = this.getCenter();
								map.panTo(latLng);
								map.fitBounds(circle.getBounds());
							});
							maps.event.addListener(circle, 'rightclick', function() {
								infowindow.close();
								this.setOptions({ fillOpacity: 0.01 });
							});
						}
					},
					() => console.error('load geo-zone failed'),
					() => {},
				);
			}
		});
	}


	setDateRangeToState = () =>
	{
		const dateRange = utils.getDatesFromPreset(this.props.widget, 'DWM');

		this.setState({
			dateStart: dateRange.startTime,
			dateStop: dateRange.endTime
		});
	}


	createMapOptions = (maps) =>
	{
		return {
			mapTypeControl: true,
			scaleControl: true,
			fullscreenControl: true,
			panControl: false,
			overviewMapControl: false,
			zoomControl: true,
			zoomControlOptions: {
				position: maps.ControlPosition.LEFT_BOTTOM,
				style: maps.ZoomControlStyle.SMALL
			},
			streetViewControl: false,
			rotateControl: false
		};
	}


	getAlternativeGeoloc = (device) =>
	{
		let alternativeGeoloc = { lat: undefined, lon: undefined };
		if (this.props.widget.ATR_IDS)
		{
			let geoSource = undefined;
			const geoAtr = this.props.widget.ATR_IDS.find((atr) => atr.GEO_ALTERNATIVE === true);

			if (geoAtr && device.attributes) // one or the other !!
			{
				geoSource = device.attributes.find((a) => a.atr_id === geoAtr.ID);
			}

			if (geoSource)
			{
				if (this.props.widget.DTH_SHOW_GEOLOC_ONLY === '1')
				{
					this.setState({
						center: {
							lat: parseFloat(String(geoSource.value.split(';')[0])),
							lng: parseFloat(String(geoSource.value.split(';')[1])),
						},
						zoom: 1,
					})
				}
				alternativeGeoloc.lat = String(geoSource.value.split(';')[0]);
				alternativeGeoloc.lon = String(geoSource.value.split(';')[1]);
				alternativeGeoloc.alt = String(geoSource.value.split(';')[2]);
			}
		}

		return alternativeGeoloc;
	}


	getAlternativeMarkers = (device) => {
		const { widget, isCategoryDashboard } = this.props;
		const getIcon = (device) => { return widget.JWD && widget.JWD[device] ? widget.JWD[device].JWD_ICON : 'marker'; }
		const showGeolocOnly = widget.DTH_SHOW_GEOLOC_ONLY === '1';
		if(device.alternativeGeoloc && device.alternativeGeoloc.lat && device.alternativeGeoloc.lon) {
			return (
				<Marker
					key={device.id}
					lat={device.alternativeGeoloc.lat}
					lng={device.alternativeGeoloc.lon}
					alt={device.alternativeGeoloc.alt}
					device={device}
					widget={widget}
					showGeolocOnly={showGeolocOnly}
					icon={showGeolocOnly ? !isCategoryDashboard ? getIcon(device.id) : widget.JWD && getIcon(Object.keys(widget.JWD)[0]) : 'compass'}
					isAlternative={true}
				/>
			)
		}
	}


	getMarkers = (device) => {
		const { widget, isCategoryDashboard } = this.props;
		const showGeolocOnly = widget.DTH_SHOW_GEOLOC_ONLY === '1';
		const getIcon = (device) => { return widget.JWD && widget.JWD[device] ? widget.JWD[device].JWD_ICON : 'marker'; }

		return (
			<Marker
		    	key={device.id}
		        lat={device.latitude}
		        lng={device.longitude}
				showGeolocOnly={showGeolocOnly}
				device={device}
		        widget={widget}
				icon={!isCategoryDashboard ? getIcon(device.id) : widget.JWD && getIcon(Object.keys(widget.JWD)[0])}
				isAlternative={false}
		    />
		)
	}


    render()
	{
		const { widget, size, isCategoryDashboard } = this.props;
		const { devices, loading, dateStart, dateStop, center, zoom } = this.state;
		const trackedDevices = devices.filter(device => widget.JWD && widget.JWD[device.id] &&
			(widget.JWD[device.id]['JWD_SHOW_TRACKING'] === '1' || widget.JWD[device.id]['JWD_SHOW_POLYLINE'] === '1'));
		const allDeviceIds = trackedDevices.map(device => { return device.id });
		const isNarrowWidget = size.width < 375;
		const haveGeoAlternative = this.props.widget.ATR_IDS.find((atr) => atr.GEO_ALTERNATIVE === true) ? true : false;
		const showGeolocOnly = widget.DTH_SHOW_GEOLOC_ONLY === '1';
	
        return (

			<>
				{!loading &&
				<div 
					id={this.props.getWidgetId + '_CONTENT'}
					className={'CONTENT' + this.props.getWidgetClass + this.props.getWidgetClass + '_CONTENT'}
					style={{ width: size.width, height: size.height }} 
					onMouseDown={e => e.stopPropagation()}
				>
	                {devices && devices.length > 0 &&
	                    <GoogleMapReact
							ref={(map) => this._map = map}
	                        bootstrapURLKeys={{ key: GMAP_KEY, language: utils.getCurrentLanguage() === 'en' ? 'en' : 'fr' }}
	                        center={center}
	                        zoom={zoom}
	                        yesIWantToUseGoogleMapApiInternals
							onGoogleApiLoaded={({map, maps}) => this.getMapObjects(map, maps)}
							options={this.createMapOptions}
	                    >
	                    {!showGeolocOnly &&
							devices.map(device => 
								this.getMarkers(device)
						)}
						{haveGeoAlternative &&
							devices.map(device => 
								this.getAlternativeMarkers(device)	
							)
						}
	                    </GoogleMapReact>
	                }
					{widget.DWM_HAS_EXPORT === '1' && widget.DVC_IDS && !loading
					&& devices && devices.length > 0 &&
					<DeviceDataExporter
						exportType='geoloc'
						devices={trackedDevices}
						allDeviceIds={allDeviceIds}
						widget={widget}
						dateStart={dateStart}
						dateStop={dateStop}
						infoText={utils.translate('componentWidgetMap.infoText')}
						popOverTitle={utils.translate('componentWidgetMap.popOverTitle')}
						iconType='flag'
						tooltipTitle={utils.translate('componentWidgetMap.tooltipTitle')}
						buttonType='primary'
						buttonStyle={{ position: 'absolute', right: '35px', bottom: '55px' }}
					/>}
					{devices && devices.length > 0 && !loading &&
					<Legend
						widget={widget}
						top={isNarrowWidget ? 115 : 65}
						right={isNarrowWidget ? 29 : 80}
						haveGeoAlternative={haveGeoAlternative}
						showGeolocOnly={showGeolocOnly}
					/>}
					{utils.isConfigMode() && devices.length === 0 && !isCategoryDashboard &&
					<EmptyWidget
						{...this.props}
						callToAction={utils.translate('componentWidgetMap.callToAction')}
					/>}
	            </div>}
				{loading &&
				<Loader style={{ marginTop: `${size.height / 2}px` }}/>}
			</>
        )
    }
}


export default withTranslation()(Map);
