import React, { useState } from 'react';

import AttributeEditorButton from './AttributeEditorButton/AttributeEditorButton';
import AttributeEditorModal from './AttributeEditorModal/AttributeEditorModal';

import { request } from 'Lib/request';
import { utils } from 'Lib/utils';


const AttributeEditor = (props) =>
{

	const {
		allContainers,
		api_getContainers,
	} = props;

	const [editorIsOpen, setEditorIsOpen] = useState(false);
	const [valuesChanged, setValuesChanged] = useState(false);
	const [selectedContainerId, setSelectedContainerId] = useState(undefined);
	const [attributes, setAttributes] = useState([]);


	const toggleEditor = () =>
	{
		setEditorIsOpen(!editorIsOpen);
		resetForm();
	}


	const resetForm = () =>
	{
		setAttributes([]);
		setSelectedContainerId(undefined);
		setValuesChanged(false);
	}


	const handleContainerChange = (containerId) =>
	{
		setSelectedContainerId(containerId);
		setValuesChanged(false);

		const containerObject = allContainers &&
			allContainers.find(container => container.id === containerId);

		setAttributes(containerObject && containerObject.attribs &&
			containerObject.attribs.map((attribute) =>
			{
				return {
					label: attribute.label,
					type: attribute.type,
					valueId: attribute.value_id,
					value: attribute.value,
					options: attribute.options,
				}
			})
		);
	}


	const handleAttributeEdit = (event, index) =>
	{
		const value = event.target.value;
		const stateAttributes = [...attributes];

		stateAttributes[index].value = value;

		setAttributes(stateAttributes);
		setValuesChanged(true);
	}


	const api_saveAttributeValues = () =>
	{
		if (attributes && attributes.length > 0)
		{
			request.put(
	            'value',
	            {
	                values: attributes
	            },
	            () =>
				{
					api_getContainers();
					setValuesChanged(false);

					utils.openNotification(
						'success',
						utils.translate('componentAttributeEditor.success1'),
						utils.translate('componentAttributeEditor.success2'),
						'bottomRight',
						5
					);
				},
	            (e) =>
				{
					utils.openNotification(
						'error',
						utils.translate('componentAttributeEditor.error1'),
						utils.translate('componentAttributeEditor.error2'),
						'bottomRight',
						5
					);

					console.error(e);
				}
	        )
		}
	}


	return (

		<>
			<AttributeEditorButton
				toggleEditor={toggleEditor}
			/>
			<AttributeEditorModal
				attributes={attributes}
				containers={allContainers}
				toggleEditor={toggleEditor}
				editorIsOpen={editorIsOpen}
				selectedContainerId={selectedContainerId}
				handleContainerChange={handleContainerChange}
				handleAttributeEdit={handleAttributeEdit}
				saveChanges={api_saveAttributeValues}
				saveButtonDisabled={!valuesChanged}
			/>
		</>
	)
};


export default AttributeEditor;
