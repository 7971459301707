import React from 'react';
import { Col } from 'antd';

import DeviceImagePlaceholder from './DeviceImagePlaceholder/DeviceImagePlaceholder';
import { utils } from 'Lib/utils';


const DeviceImage = ({ device }) =>
{
	return (

		<Col
			xl={12}
			lg={24}
			md={24}
			sm={24}
			xs={24}
			key={device.id + 'image'}
			className='text-center'
		>
		
			{device.image === '' &&
			<DeviceImagePlaceholder/>}
			
			{device.image !== '' &&
			<img
				src={window.ENV.REACT_APP_CS_URL + device.image}
				alt={utils.translate('generic.dvc')}
				style={{ maxWidth: '100%' }}
			/>}
			
		</Col>
	);
};


export default DeviceImage;
