import React, { Component } from 'react';
import { Drawer } from 'antd';

import MenuMaker from '../MenuMaker/MenuMaker';

import './MenuDrawer.css';

class MenuDrawer extends Component
{

    render() {

        return(
            <Drawer 
                className='menu-drawer'
                visible={this.props.showMenu}
                placement='left'
                onClose={this.props.toggleMenu}
                title={this.props.image}
                closable={false}
                width='70vw'
                maskStyle={{backgroundColor: 'rgba(0, 0, 0, 0.45)'}}
                zIndex='1003'					
            >
                <MenuMaker
                    menuItems={this.props.menuItems}
                    defaultSelectedKeys={this.props.defaultSelectedKeys}
                    closeDrawer={this.props.toggleMenu}
                    currentPage={this.props.currentPage}
                />
            </Drawer>
        );
    }

}

export default MenuDrawer;