
export const chartColors = [
	'rgba(31, 208, 224, 0.6)',
	'rgba(155, 242, 75, 0.6)',
	'rgba(192, 115, 214, 0.6)',
	'rgba(237, 122, 120, 0.6)',
	'rgba(249, 84, 54, 0.6)',
	'rgba(252, 196, 83, 0.6)',
	'rgba(50, 114, 242, 0.6)',
	'rgba(249, 37, 207, 0.6)',
	'rgba(242, 234, 240, 0.6)',
	'rgba(56, 247, 47, 0.6)', //
	'rgba(31, 218, 224, 0.6)',
	'rgba(155, 242, 75, 0.6)',
	'rgba(102, 115, 214, 0.6)',
	'rgba(237, 142, 120, 0.6)',
	'rgba(249, 84, 84, 0.6)',
	'rgba(212, 196, 13, 0.6)',
	'rgba(10, 114, 202, 0.6)',
	'rgba(149, 37, 287, 0.6)',
	'rgba(222, 244, 247, 0.6)',
	'rgba(56, 217, 247, 0.6)', //
	'rgba(31, 248, 224, 0.6)',
	'rgba(155, 242, 175, 0.6)',
	'rgba(112, 215, 214, 0.6)',
	'rgba(237, 112, 80, 0.6)',
	'rgba(219, 44, 50, 0.6)',
	'rgba(22, 196, 13, 0.6)',
	'rgba(10, 114, 292, 0.6)',
	'rgba(249, 77, 257, 0.6)',
	'rgba(138, 81, 77, 0.6)',
	'rgba(156, 247, 270, 0.6)'
];
