import React, { Component } from 'react';
import { Modal, Button, Select, Result, Card } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SuperSelect from 'Components/Studio/StudioLib/SuperSelect/SuperSelect';

import { request } from 'Lib/request';
import { utils } from 'Lib/utils';
import { oneCategoryPH } from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js'

const { Option } = Select;


class CategorySelectorModal extends Component
{

	constructor(props)
	{
        super(props);

        this.state = {
			currentCategoryId: props.category.id,
			modalIsVisible: false
        }

		this.componentIsMounted = false;
    }


	componentDidMount()
	{
		this.componentIsMounted = true;
	}


	componentDidUpdate(prevProps)
	{
		if (prevProps.category !== this.props.category)
		{
			this.setState({ currentCategoryId: this.props.category.id });
        }
    }


	componentWillUnmount()
	{
		this.componentIsMounted = false;
    }


	api_saveCategory = (removingCategory) =>
	{
		let newCategory = removingCategory ? '0' : this.state.currentCategoryId;

		request.patch(
            'page/' + this.props.dpgId,
            {
                category_id: newCategory
            },
            () =>
			{
				if (removingCategory) { this.props.toggleCategoryDashboardManager(); }

				this.props.closeModal();
				this.props.api_getCategory(newCategory);

				utils.openNotification(
					"success",
					removingCategory ? utils.translate('componentCategorySelectorModal.dashInfo') : utils.translate('componentCategorySelectorModal.newCat'),
					removingCategory ? utils.translate('componentCategorySelectorModal.deviceInfo') : utils.translate('componentCategorySelectorModal.catInfo'),
					"bottomRight",
					removingCategory ? 5 : 8
				);
			},
            () => {}
        )
	}


	handleCategoryChange = (categoryId) =>
	{
		this.setState({ currentCategoryId: categoryId });
	}


    render()
	{
		const title = (
			<div>
				<FontAwesomeIcon className="widget-icon" icon="pencil-alt"/>
				{this.props.removingCategory ? utils.translate('componentCategorySelectorModal.unbind') : utils.translate('componentCategorySelectorModal.catSelect')}
			</div>
		);

        return (

			<div>
				<Modal
					title={title}
					visible={this.props.modalIsVisible}
					onCancel={this.props.closeModal}
					width={900}
					footer={[
						<div>
							<Button type="default" onClick={this.props.closeModal}>
							 {utils.translate('generic.close')}
							</Button>
							<Button type="primary" onClick={() => this.api_saveCategory(this.props.removingCategory)}>
								{this.props.removingCategory ? utils.translate('componentCategorySelectorModal.confirm1') : utils.translate('generic.save')}
							</Button>
						</div>
					]}
				>
					<Card>
						{!this.props.removingCategory &&
						<SuperSelect
							showSearch
							optionFilterProp="children"
							style={{ width: '100%' }}
							placeholder={oneCategoryPH}
							onChange={this.handleCategoryChange}
							value={this.state.currentCategoryId}
						>
						{this.props.allCategories.map((category) =>
							<Option key={category.id} value={category.id}>{category.label}</Option>
						)}
						</SuperSelect>}
						{this.props.removingCategory &&
						<Result
							status="warning"
							title={utils.translate('componentCategorySelectorModal.confirm2')}
							subTitle={utils.translate('componentCategorySelectorModal.confirm3')}
						/>}
					</Card>
				</Modal>
			</div>
        );
    }
}


export default CategorySelectorModal;
