import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd';
import { utils } from 'Lib/utils';

import NoData from '../../../WidgetLib/NoData/NoData';

const BooleanValue = (props) =>
{	
	const layout = props.widget.DWG_DECOR.widgetLayout ? props.widget.DWG_DECOR.widgetLayout : 'default';

	const tooltipText = props.config ?
			<span>
				{utils.translate('componentWidget.currentVal')}<b>{props.value}</b> {props.configUnite_D1 && <span>{props.configUnite_D1}</span>}
			</span> : '';

	const errorTooltipText =
			<span>
				{utils.translate('componentWidgetBooleanValue.info1')} {'\n'}
				{utils.translate('componentWidgetBooleanValue.info2')}
			</span>;

	const colors = props.widget.DWV_COLORS ? props.widget.DWV_COLORS.split(',') : ['#28a745', '#ff5369'];


	const image = props.getWidgetImage(props.value)[0] &&
		<div>
			<img
				src={props.widget.DWV_IMAGE}
				alt=''
				style={{width: `${Math.min(props.size.height, props.size.width) / 2}px`, maxWidth: '100%'}}
			/>
		</div>;

	const info = typeof props.config !== 'undefined' &&
		<div className='status-text'>
			<div className='title'>{props.deviceLabel}</div>
			<>{props.label}</>
		</div>;

	const data = typeof props.config !== 'undefined' &&
		<div>
			<div className='value'>
				{props.value === 1 &&
					<Tooltip placement='top' title={tooltipText}>
						<FontAwesomeIcon
							id='booleanValueGreen'
							icon='toggle-on'
							color={colors[0]}
							style={{fontSize: `${props.size.height / 7}px`}}
						/>
					</Tooltip>
				}
				{props.value === 0 &&
					<Tooltip placement='top' title={tooltipText}>
						<FontAwesomeIcon
							id='booleanValueRed'
							icon='toggle-off'
							color={colors[1]}
							style={{fontSize: `${props.size.height / 7}px`}}
						/>
					</Tooltip>
				}
				{props.value !== 1 && props.value !== 0 &&
					<Tooltip placement='top' title={errorTooltipText}>
						<FontAwesomeIcon
							id='booleanValueGrey'
							icon='toggle-off'
							color='rgb(245, 245, 245)'
							style={{fontSize: `${props.size.height / 7}px`}}
						/>
					</Tooltip>
				}
			</div>
			{props.showLastDate &&
			<div className='date'>
				{props.config.date}
			</div>}
		</div>;


	return (

		<div
			id={props.getWidgetId + '_CONTENT'}
			className={'CONTENT BOOLEAN BOOLEAN_CONTENT'}
		>
			<div className='value-content'>
				{layout === 'default' &&
				<>
					{image}
					{info}
					{data}
				</>}

				{layout === 'col-2' &&
				<>
					{info}
					<div className='DWG_DIS_COL-2'>
						{image}
						{data}
					</div>
				</>}

				{layout === 'col-3' &&
				<div className='DWG_DIS_COL-3'>
					{info}
					{image}
					{data}
				</div>}
				{typeof props.config === 'undefined' &&
				<>
					<NoData></NoData>
				</>
				}
			</div>
		</div>
    );
    
}


export default BooleanValue;
