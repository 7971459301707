import React from 'react';
import ColorPicker from 'rc-color-picker';

import SmallLabelItem from '../SmallLabelItem/SmallLabelItem';

import 'rc-color-picker/assets/index.css';


const ColorPickerItem = (props) =>
{

	const {
		label,
		color,
		onClose,
		alpha,
		enableAlpha
	} = props;


	return (

		<>
			<SmallLabelItem
				label={label}
			/>
			<ColorPicker
				animation='slide-up'
				color={color}
				style={{ zIndex: 9999 }}
				alpha={alpha}
				enableAlpha={enableAlpha}
				placement='bottomRight'
				onClose={onClose}
			/>
		</>
	)
};


export default ColorPickerItem;
