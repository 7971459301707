import React from 'react';
import { Row, Col } from 'antd';

import ValueCard from './ValueCard/ValueCard';
import NoData from '../../../NoData/NoData';
import { utils } from 'Lib/utils';


const AttributePane = ({ device }) =>
{	
	return device.attributes && device.attributes.length > 0 ?
	(
		<Row type='flex'>
			{device.attributes.map(attribute =>
				<Col
					xl={12}
					lg={24}
					md={24}
					sm={24}
					key={attribute.id}
					style={{ padding: 12 }}
				>
					<ValueCard
						key={attribute.id}
						title={attribute.name}
						titleSize='h5'
						number={attribute.value}
						description={attribute.description}
					/>
				</Col>
			)}
		</Row>
	) : (
		<NoData
			marginTop={50}
			description={utils.translate('componentAttributePane.noAttr')}
		/>
	);
};


export default AttributePane;
