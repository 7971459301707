import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';

import LabelItem from '../../../FormItems/LabelItem/LabelItem';
import ColorPickerItem from '../../../FormItems/ColorPickerItem/ColorPickerItem';


const DualColorsSection = (props) => 
{

	return (

		<>
			<FormGroup>
				<Row style={{paddingTop: '15px'}}>
					<LabelItem
						iconType='bg-colors'
						label={props.label}
					/>
					<Col md={{ size: 9 }}>
					{props.displayAllColors &&
						<ColorPickerItem
							label={props.active}
							color={props.colorZero}
							alpha={props.alphaZero}
							enableAlpha={props.enableAlpha === undefined ? true : props.enableAlpha}
							onClose={(color) => props.handleColorChange(color, 0)}
						/>}
						<ColorPickerItem
							label={props.inactive}
							color={props.colorOne}
							alpha={props.alphaOne}
							enableAlpha={props.enableAlpha === undefined ? true : props.enableAlpha}
							onClose={(color) => props.handleColorChange(color, 1)}
						/>
					</Col>
				</Row>
			</FormGroup>
		</>
	)
};


export default DualColorsSection;
