import React, { useState, useEffect } from 'react';
import { Tabs, Icon, Tooltip } from 'antd';

import DatatypePane from './Datatype/DatatypePane';
import AttributePane from './Attribute/AttributePane';
import WidgetStyle from 'Components/Studio/Dashboard/Widgets/Style/WidgetStyle.js'
import { utils } from 'Lib/utils';

const { TabPane } = Tabs;


const DataSourceConfigure = (props) =>
{

	const keys = {
		'CFG': '1',
		'ATR': '2',
		'COL': '3',
	};

	const [activeKey, setActiveKey] = useState(keys['CFG']);
	const [datatypePaneIsDisabled, setDatatypePaneIsDisabled] = useState(false);
	const [attributePaneIsDisabled, setAttributePaneIsDisabled] = useState(false);

	const sourceIsAttribute = props.widget.JWA_TYPE === 'CTR';

	useEffect(() =>
		{
			if (sourceIsAttribute)
			{
				setActiveKey(keys['ATR']);
			}
		},
		[]
	);

	return (

		<Tabs
			activeKey={activeKey}
			onTabClick={(key, event) => setActiveKey(key)}
		>
			<TabPane
				tab={
					<Tooltip
						title={
							datatypePaneIsDisabled ?
							utils.translate('componentDatasourceConfigure.unselectAttr') : ''
						}
					>
						<span>
							<Icon type='api' className='greyIcon'/>
							{utils.translate('generic.dty')}
						</span>
					</Tooltip>
				}
				key='1'
				disabled={datatypePaneIsDisabled}
			>
				<DatatypePane
					{...props}
					setAttributePaneIsDisabled={setAttributePaneIsDisabled}
					marginTop={20}
				/>
			</TabPane>
			<TabPane
				tab={
					<Tooltip
						title={
							attributePaneIsDisabled ?
							utils.translate('componentDatasourceConfigure.unselectDty') : ''
						}
					>
						<span>
							<Icon type='unordered-list' className='greyIcon'/>
							{utils.translate('generic.attr')}
						</span>
					</Tooltip>
				}
				key='2'
				disabled={attributePaneIsDisabled}
			>
				<AttributePane
					{...props}
					setDatatypePaneIsDisabled={setDatatypePaneIsDisabled}
					marginTop={20}
				/>
			</TabPane>
			<TabPane
				tab={
					<Tooltip
						title='Style'
					>
						<span>
							<Icon type='bg-colors' className='greyIcon'/>
							Style
						</span>
					</Tooltip>
				}
				key='3'
			>
				<WidgetStyle
					{...props}
					marginTop={20}
				/>
			</TabPane>
		</Tabs>
	)
};


export default DataSourceConfigure;
