import React, { Component } from 'react';
import { utils } from 'Lib/utils';
import { Tabs, Card, Icon, Tooltip, Input } from 'antd';
import { Row, Col, FormGroup } from 'reactstrap';

import DeviceSection from '../FormSections/DeviceSection/DeviceSection';
import CategorySection from '../FormSections/CategorySection/CategorySection';
import WidgetStyle from 'Components/Studio/Dashboard/Widgets/Style/WidgetStyle.js';

import { categoryDashboardInfo } from 'Components/Studio/StudioLib/GenericHTML/GenericText.js';
import { withTranslation } from 'react-i18next';
import {
	multipleDevicesPH,
	multipleCategoriesPH,
} from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';
import LabelItem from 'Components/Studio/Dashboard/Widgets/Configure/FormItems/LabelItem/LabelItem';

const { TabPane } = Tabs;

class Scheduler extends Component {
	constructor(props) {
		super(props);

		this.state = {
			devices: [],
			selectedDevices: [],
			selectedCategoryIds: [],
			click: false,
			label: undefined,
		};
	}

	componentDidMount = () => {
		const { widget } = this.props;

		if (this.props.devices !== undefined && this.props.devices.length > 0) {
			this.setDevicesToState();
		}

		this.setState({
			label: this.props.widget.DWE_CATLABEL,
			selectedDevices: widget.DVC_IDS,
			selectedCategoryIds: widget.CAT_IDS,
			csvChecked: !widget.DWT_HAS_EXPORT || widget.DWT_HAS_EXPORT === '1',
			addDeviceChecked:
				!widget.DWT_HAS_ADD_DEVICE || widget.DWT_HAS_ADD_DEVICE === '1',
			activeKey:
				widget && widget.CAT_IDS && widget.CAT_IDS.length > 0
					? 'data_source_2'
					: 'data_source_1',
		});

		let currentConfiguration = this.getCurrentConfiguration();
		this.props.saveConfiguration(currentConfiguration);
	};

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.devices !== undefined &&
			prevProps.devices !== this.props.devices
		)
			this.setDevicesToState();
	}

	setDevicesToState = () => {
		let devices = [...this.props.devices];

		this.setState({ devices: devices });
	};

	handleDeviceChange = (value) => {
		let deviceIdsStr = '';

		value.forEach((deviceId) => (deviceIdsStr += deviceId + ','));
		deviceIdsStr = deviceIdsStr.slice(0, -1);

		this.setState({ selectedDevices: deviceIdsStr });

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.device_ids = deviceIdsStr;
		currentConfiguration.category_ids = [];

		this.props.saveConfiguration(currentConfiguration);
	};

	handleCategoryChange = (value = []) => {
		let catArray = [];
		catArray.push(value);

		let label = this.getLabelCat(catArray[0]);
		this.setState({ selectedCategoryIds: catArray });

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.category_ids = catArray;
		currentConfiguration.device_ids = '';
		currentConfiguration.cat_label = label;

		this.props.saveConfiguration(currentConfiguration);
	};

	getLabelCat = (catId) => {
		let label = '';
		this.props.categories &&
			this.props.categories.forEach((category) => {
				if (catId === category.id) {
					label = category.label;
				}
			});
		this.setState({ label: label });
		return label;
	};

	getCurrentConfiguration = () => {
		return {
			type: 'scheduler',
			device_ids: this.getCurrentDeviceIds(),
			category_ids:
				this.state.selectedCategoryIds.length !== 0
					? this.state.selectedCategoryIds
					: this.props.widget.CAT_IDS,
			cat_label: this.state.label,
		};
	};

	getCurrentDeviceIds = () => {
		if (this.props.configuration.device_ids === undefined) {
			return this.props.widget.DVC_IDS
				? this.props.widget.DVC_IDS
				: undefined;
		} else if (this.props.configuration.device_ids === '') {
			return undefined;
		} else {
			return this.props.configuration.device_ids;
		}
	};

	handleLabelChange = (event) => {
		const label = event.target.value;

		this.setState({ label: label });

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.cat_label = label;

		this.props.saveConfiguration(currentConfiguration);
	};

	handlePressEnter = () => this.setState({ click: false });

	render() {
		const { loading } = this.props;
		const { selectedDevices = [], selectedCategoryIds = [] } = this.state;
		const currentDeviceIds =
			selectedDevices.length > 0
				? this.state.selectedDevices.split(',')
				: undefined;
		const entries = this.state.devices.length;
		const widgetHasDvcIds = selectedDevices.length > 0;
		const widgetHasCatIds =
			selectedCategoryIds[0] !== undefined &&
			selectedCategoryIds[0].length > 0;
		const devicePaneDisabled = widgetHasCatIds;
		const categoryPaneDisabled = widgetHasDvcIds;

		return (
			<Tabs tabPosition="top" tabBarStyle={{ marginBottom: 25 }}>
				<TabPane
					tab={
						<span>
							<Icon type="table" className="greyIcon" />
							{utils.translate('componentWidgetConfigure.data')}
						</span>
					}
					key="1"
				>
					<Card bordered={false}>
						{!this.props.isCategoryDashboard && (
							<Tabs
								tabPosition="left"
								activeKey={this.state.activeKey}
								onChange={(key) =>
									this.setState({ activeKey: key })
								}
							>
								<TabPane
									tab={
										<Tooltip
											title={
												devicePaneDisabled
													? utils.translate(
															'componentWidgetTableConfigure.unselectCat'
													  )
													: ''
											}
										>
											<span>
												<Icon
													type="mobile"
													className="greyIcon"
												/>
												{utils.translate(
													'generic.dvcs'
												)}
											</span>
										</Tooltip>
									}
									key="data_source_1"
									disabled={devicePaneDisabled}
								>
									<DeviceSection
										{...this.props}
										{...this.state}
										label={utils.translate('generic.dvcs')}
										deviceSelectMode="multiple"
										deviceSelectRef={this.allDevices}
										entries={entries}
										deviceSelectPlaceholder={
											multipleDevicesPH
										}
										disabled={loading}
										allowClearDeviceSelect={true}
										handleDeviceChange={
											this.handleDeviceChange
										}
										selectedDevices={currentDeviceIds}
									/>
								</TabPane>
								<TabPane
									forceRender
									tab={
										<Tooltip
											title={
												categoryPaneDisabled
													? utils.translate(
															'componentWidgetTableConfigure.unselectDvc'
													  )
													: ''
											}
										>
											<span>
												<Icon
													type="deployment-unit"
													className="greyIcon"
												/>
												{utils.translate(
													'componentWidgetScheduler.cat'
												)}
											</span>
										</Tooltip>
									}
									key="data_source_2"
									disabled={categoryPaneDisabled}
								>
									<CategorySection
										{...this.props}
										{...this.state}
										iconType="deployment-unit"
										label={utils.translate(
											'componentWidgetScheduler.cat'
										)}
										categorySelectMode="single"
										categorySelectPlaceholder={
											multipleCategoriesPH
										}
										handleCategoryChange={
											this.handleCategoryChange
										}
										selectedCategoryIds={
											selectedCategoryIds[0]
										}
									/>
									<FormGroup>
										<Row className="align-items-center">
											<LabelItem
												iconType="plus"
												label="Label"
											/>
											<Col md={{ size: 9 }}>
												<Input
													autoFocus
													disabled={
														selectedCategoryIds[0] !==
															undefined &&
														selectedCategoryIds[0]
															.length <= 0
													}
													className={'NoDrag'}
													prefix={
														<Icon
															type="tag"
															className="greyIcon"
														/>
													}
													placeholder={this.props.t(
														'componentWidgetScheduler.labelCat'
													)}
													value={
														this.state.label
															? this.state.label
															: ''
													}
													onChange={
														this.handleLabelChange
													}
													onPressEnter={
														this.handlePressEnter
													}
													style={{
														height: '30px',
														marginBottom: '10px',
													}}
												/>
											</Col>
										</Row>
									</FormGroup>
								</TabPane>
							</Tabs>
						)}
						{this.props.isCategoryDashboard && (
							<div>{categoryDashboardInfo}</div>
						)}
					</Card>
				</TabPane>
				<TabPane
					tab={
						<Tooltip title="Style">
							<span>
								<Icon type="bg-colors" className="greyIcon" />
								{utils.translate(
									'componentWidgetConfigure.style'
								)}
							</span>
						</Tooltip>
					}
					key="2"
				>
					<WidgetStyle
						{...this.props}
						marginTop={20}
						configuration={this.getCurrentConfiguration()}
					/>
				</TabPane>
			</Tabs>
		);
	}
}

export default withTranslation()(Scheduler);
