import React from 'react';
import { utils } from 'Lib/utils.js';


const StatusTooltip = (props) =>
{	

	return (

		props.lastData ?
			<span>
				<div style={{ fontWeight: 'bold', marginBottom: 5 }}>{props.device.status}
				</div>
				<div>
					{utils.translate('componentStatusTooltip.received')}
				</div>
				<div style={{ fontStyle: 'italic' }}>
					{props.lastData.date}
				</div>
			</span>
			:
			<span>
				<div style={{ fontWeight: 'bold', marginBottom: 5 }}>
					{utils.translate(`deviceStatus.${props.device.status}`)}
				</div>
					{utils.translate('componentStatusTooltip.noData')}
				</span>
	)
};


export default StatusTooltip;
