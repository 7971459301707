import React, { Component } from 'react';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import frLocale from '@fullcalendar/core/locales/fr';
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import rrulePlugin from '@fullcalendar/rrule' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import timeGridPlugin from '@fullcalendar/timegrid';
import { Button, Popover, Popconfirm, Icon, Row, Col, List } from 'antd';
import { utils } from 'Lib/utils';
import { request } from 'Lib/request.js';
import EmptyWidget from '../../WidgetLib/EmptyWidget/EmptyWidget';
import DeviceSection from 'Components/Studio/Dashboard/Widgets/Configure/FormSections/DeviceSection/DeviceSection.js';
import { oneDevicePH } from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import './Scheduler.css';


class Scheduler extends Component {

	constructor(props) {
		super(props);

		this.state = {
			view: 'timeGridWeek',
			date: new Date(),
			events: undefined,
			canDrop: true,
			configuration: [],
			start: '',
			end: '',
			dvcFilter: undefined,
			devicesWithAlarms: [],
			range: undefined,
			schedulerDevices: [],
			devices: [],
			loaded: true
		};

		this.componentIsMounted = false;
	}

	setCanDrop = () => { };
	calendarComponentRef = React.createRef();

	componentDidMount() {

		this.componentIsMounted = true;
		this.timerId = utils.setRefresh(this.props.widget.DWG_REFRESH_FREQ, this.updateCalendar);

	}

	componentDidUpdate(prevProps, prevState) {

		if (this.props.widget.DVC_IDS && prevProps.widget.DVC_IDS !== this.props.widget.DVC_IDS && this.calendarComponentRef.current) {
			this.updateCalendar();
		}


		if (this.props.widget.CAT_IDS.length !== 0 && prevProps.widget.CAT_IDS !== this.props.widget.CAT_IDS && this.calendarComponentRef.current) {
			this.updateCalendar();
		}

		if (prevProps.isLoaded !== this.props.isLoaded && this.calendarComponentRef.current) {
			this.updateCalendar();
		}

		if (prevProps.catCurrentDevice.id !== this.props.catCurrentDevice.id && this.calendarComponentRef.current) {
			this.updateCalendar();
		}


		if (this.calendarComponentRef.current && this.calendarComponentRef.current._calendarApi.currentDataManager.state.dateProfile.renderRange !== this.state.range) {
			this.updateCalendar();
			this.setState({ range: this.calendarComponentRef.current._calendarApi.currentDataManager.state.dateProfile.renderRange })
		}
		//this.calendarComponentRef.current._calendarApi.currentDataManager.state.dateProfile.renderRange -- Get date range

	}


	componentWillUnmount() {
		this.componentIsMounted = false;
		if (this.timerId !== null) { utils.clearRefresh(this.timerId); }
	}


	updateCalendar = () => {
		//let range = this.calendarComponentRef.current._calendarApi.currentDataManager.state.dateProfile.renderRange;
		//let start = moment(range.start).utc().format('YYYY-MM-DD HH:mm:ss');
		//let end = moment(range.end).utc().format('YYYY-MM-DD HH:mm:ss');

		if (this.props.widget.DVC_IDS) {
			this.setState({
				dvcIds: this.props.widget.DVC_IDS,
			}, () => {
				this.getDevices();
			});
		}

		if (this.props.widget.CAT_IDS.length !== 0) {
			this.setState({
				catId: this.props.widget.CAT_IDS,
			}, () => {
				this.getCategory();
			});
		}

	}


	getDevices = () => {
		let deviceArray = [];
		this.state.dvcIds.split(',').forEach(id => {
			request.get(
				'device/' + id,
				{

				},
				(device) => {
					deviceArray.push(device[0]);
					this.setState({ devices: deviceArray })
					this.props.setschedulerDevices(deviceArray);
				},
				(e) => console.error(e),
				() => { this.buildEvents() }
			);
		})

	}



	getCategory = () => {
		request.get(
			'category/' + this.state.catId,
			{
				'dependencies': 'available_commands'
			},
			(cat) => {
				this.setState({ category: cat });
				this.props.setschedulerCategories(cat);
			},
			(e) => console.error(e),
			() => {
				this.buildEvents()
			}
		);
	}




	saveConfiguration = () => {
		let params = this.state.configuration;
		request.patch(
			'scheduledcommand/' + this.state.configuration.scm_id,
			params,
			() => { },
			(e) => {
				utils.openNotification(
					'error',
					utils.translate('componentConfigure.cannot'),
					utils.translate('generic.rights'),
					'bottomRight',
					5
				);
			},
		);
	}


	handleView = (arg) => { // bind with an arrow function
		this.setState({ view: arg })
	}


	getFreq = (arg) => { // bind with an arrow function
		if (arg === 'SCM_RP_HOUR') {
			return 'hourly';
		} else if (arg === 'SCM_RP_DAY') {
			return 'daily';
		} else if (arg === 'SCM_RP_WEEK') {
			return 'weekly';
		} else if (arg === 'SCM_RP_MONTH') {
			return 'monthly';
		} else {
			return 'daily';
		}
	}



	async buildEvents() {
		if(!this.calendarComponentRef.current) return;
		
		let array = [];
		let isconfigCat = this.props.isCategoryDashboard ? utils.isConfigMode() ? true : false : false;
		let range = this.calendarComponentRef.current._calendarApi.currentDataManager.state.dateProfile.renderRange;
		let start = moment(range.start).utc().format('YYYY-MM-DD HH:mm:ss');
		let end = moment(range.end).utc().format('YYYY-MM-DD HH:mm:ss');

		if (!isconfigCat) {
			if (this.props.widget.DVC_IDS && this.state.devices) {
				await Object.values(this.state.devices).forEach(async device => {
					if (device.active_alarms !== undefined) {
						if (!this.state.dvcFilter || this.state.dvcFilter === device.id) {
							device.active_alarms.forEach(alarm => {
								let newArray = {};
								newArray['title'] = 'ALARM'
								newArray['level'] = alarm.level;
								newArray['type'] = alarm.type;
								newArray['date'] = alarm.date_resolution;
								newArray['color'] = '#D3D3D3';
								array.push(newArray);
							})
						}
					}
					if (device.scm.length !== 0) {
						await Object.values(device.scm).forEach(async scm => {
								if (scm.SCM_REPEAT_MODE !== 'SCM_RM_OFF' && scm.SCM_SCHEDULEDDATE) {

									if (!this.state.dvcFilter || this.state.dvcFilter === device.id) {
										
										
										if(scm.SCM_EXECUTIONDATE > start && scm.SCM_EXECUTIONDATE < end){

										await request.get(
											'orderhistory',
											{
												scm_id: scm.SCM_ID
											},
											(orders) => {
												Object.values(orders).forEach(order => {
													let newArrayHis = {};
													this.setState({ loaded: false });
													newArrayHis['title'] = '⟳ ' + scm.SCM_LABEL + ' (' + this.getHistoriEventStatus(order) + ')';
													newArrayHis['titleMain'] = scm.SCM_LABEL;
													newArrayHis['scm_id'] = scm.SCM_ID;
													newArrayHis['dvc_id'] = scm.DVC_ID;
													newArrayHis['date'] = order.date;
													newArrayHis['dateText'] = order.date;
													newArrayHis['ohi_id'] = order.ohi_id;
													newArrayHis['command_label'] = scm.CMD_LABEL
													newArrayHis['set'] = scm.SCM_SET
													newArrayHis['color'] = this.getHistoricColor(scm, order);
													array.push(newArrayHis);
	
												})
											},
											(e) => console.error(e),
											() => { this.setState({ events: array, loaded: true }) }
										);}
	
										if (scm.SCM_REPEAT_START && scm.SCM_EXESTATUS === 'SCM_ES_INIT') {
											let newArray = {};
	
											newArray['title'] = '⟳ ' + scm.SCM_LABEL + ' (' + this.getEventStatus(scm) + ')';
											newArray['titleMain'] = scm.SCM_LABEL;
											newArray['scm_id'] = scm.SCM_ID;
											newArray['dvc_id'] = scm.DVC_ID;
											newArray['date'] = scm.SCM_REPEAT_START;
											newArray['rrule'] = {
												freq: this.getFreq(scm.SCM_REPEAT_PERIOD),
												dtstart: scm.SCM_SCHEDULEDDATE,
												until: scm.SCM_REPEAT_END,
											};
											newArray['exdate'] = scm.SCM_REPEAT_EXCLUDE && scm.SCM_REPEAT_EXCLUDE.split(',');
											newArray['dateText'] = scm.SCM_REPEAT_START;
											newArray['command_label'] = scm.CMD_LABEL
											newArray['set'] = scm.SCM_SET
											newArray['color'] = this.getEventColor(scm);
										
											await array.push(newArray);
										}
									}
								} else {
									if (!this.state.dvcFilter || this.state.dvcFilter === device.id) {
										let newArray = {};
										newArray['title'] = scm.SCM_LABEL + ' (' + this.getEventStatus(scm) + ')';
										newArray['titleMain'] = scm.SCM_LABEL;
										newArray['scm_id'] = scm.SCM_ID;
										newArray['dvc_id'] = scm.DVC_ID;
										newArray['date'] = scm.SCM_SCHEDULEDDATE;
										newArray['dateText'] = scm.SCM_SCHEDULEDDATE;
										newArray['command_label'] = scm.CMD_LABEL
										newArray['set'] = scm.SCM_SET
										newArray['color'] = this.getEventColor(scm);
										await array.push(newArray);
									}
								}
							
						})
					}
				});
			}

		}


		if (this.props.widget.CAT_IDS.length > 0 && this.state.category) {
			await Object.values(this.state.category).forEach(async cat => {
				if (cat.scm) {
					await Object.values(cat.scm).forEach(async scm => {
						if (scm.SCM_REPEAT_MODE !== 'SCM_RM_OFF' && scm.SCM_SCHEDULEDDATE) {
							if (!this.state.dvcFilter || this.state.dvcFilter === cat.id) {
								
								if(scm.SCM_EXECUTIONDATE > start && scm.SCM_EXECUTIONDATE < end){
									await request.get(
										'orderhistory',
										{
											scm_id: scm.SCM_ID
										},
										(orders) => {
											Object.values(orders).forEach(order => {
												this.setState({ loaded: false });
												let newArrayHis = {};
												newArrayHis['title'] = '⟳ ' + scm.SCM_LABEL + ' (' + this.getHistoriEventStatus(order) + ')';
												newArrayHis['titleMain'] = scm.SCM_LABEL;
												newArrayHis['scm_id'] = scm.SCM_ID;
												newArrayHis['dvc_id'] = scm.DVC_ID;
												newArrayHis['date'] = order.date;
												newArrayHis['dateText'] = order.date;
												newArrayHis['ohi_id'] = order.ohi_id;
												newArrayHis['command_label'] = scm.CMD_LABEL
												newArrayHis['set'] = scm.SCM_SET
												newArrayHis['color'] = this.getHistoricColor(scm, order);
												array.push(newArrayHis);

										})
									},
									(e) => console.error(e),
									() => { this.setState({ events: array, loaded: true }) }
								);}

								if (scm.SCM_REPEAT_START && scm.SCM_EXESTATUS === 'SCM_ES_INIT') {
									let newArray = {};

									newArray['title'] = '⟳ ' + scm.SCM_LABEL + ' (' + this.getEventStatus(scm) + ')';
									newArray['titleMain'] = scm.SCM_LABEL;
									newArray['scm_id'] = scm.SCM_ID;
									newArray['dvc_id'] = scm.DVC_ID;
									newArray['date'] = scm.SCM_REPEAT_START;
									newArray['rrule'] = {
										freq: this.getFreq(scm.SCM_REPEAT_PERIOD),
										dtstart: scm.SCM_SCHEDULEDDATE,
										until: scm.SCM_REPEAT_END,
									};
									newArray['exdate'] = scm.SCM_REPEAT_EXCLUDE && scm.SCM_REPEAT_EXCLUDE.split(',');
									newArray['dateText'] = scm.SCM_REPEAT_START;
									newArray['command_label'] = scm.CMD_LABEL
									newArray['set'] = scm.SCM_SET
									newArray['color'] = this.getEventColor(scm);
									await array.push(newArray);
								}
							}
						} else {
							if (!this.state.dvcFilter || this.state.dvcFilter === cat.id) {
								let newArray = {};
								newArray['title'] = scm.SCM_LABEL + ' (' + this.getEventStatus(scm) + ')';
								newArray['titleMain'] = scm.SCM_LABEL;
								newArray['scm_id'] = scm.SCM_ID;
								newArray['dvc_id'] = scm.DVC_ID;
								newArray['date'] = scm.SCM_SCHEDULEDDATE;
								newArray['dateText'] = scm.SCM_SCHEDULEDDATE;
								newArray['command_label'] = scm.CMD_LABEL
								newArray['set'] = scm.SCM_SET
								newArray['color'] = this.getEventColor(scm);
								array.push(newArray);
							}
						}
					})
				}
			});
		}
		
		this.setState({ events: array });
	}


	getEventColor = (scheduledCommand) => {
		let colors = scheduledCommand.SCM_COLORSTATUS.split(',');
		if (scheduledCommand.SCM_EXESTATUS === 'SCM_ES_INIT') {
			return colors[0];
		} else if (scheduledCommand.SCM_EXESTATUS === 'SCM_ES_DONEOK') {
			return colors[1];
		} else {
			return colors[2];
		}
	}


	getHistoricColor = (scheduledCommand, historicCommand) => {
		let colors = scheduledCommand.SCM_COLORSTATUS.split(',');

		if(historicCommand.return === ''){
			return colors[2];
		} else if (historicCommand.return === 'SUCCES') {
			return colors[1];
		} else {
			return colors[2];
		}
	}



	getEventStatus = (scheduledCommand) => {
		if (scheduledCommand.SCM_EXESTATUS === 'SCM_ES_INIT') {
			return this.props.t('componentWidgetScheduler.init');
		} else if (scheduledCommand.SCM_EXESTATUS === 'SCM_ES_DONEOK') {
			return this.props.t('componentWidgetScheduler.done');
		} else {
			return this.props.t('componentWidgetScheduler.failed');
		}
	}


	getHistoriEventStatus = (historicCommand) => {

		if(historicCommand.return === ''){
			return this.props.t('componentWidgetScheduler.failed');
		} else if (historicCommand.return === 'SUCCES') {
			return this.props.t('componentWidgetScheduler.done');
		} else {
			return this.props.t('componentWidgetScheduler.failed');
		}
	}


	handleDateClick = (date) => { // bind with an arrow function

		let isconfigCat = this.props.isCategoryDashboard ? utils.isConfigMode() ? true : false : false;
		let dateFormat = moment(date.dateStr).utc().format('YYYY-MM-DD HH:mm:ss');
		let nowFormat = moment(new Date(Date.now())).format('YYYY-MM-DD HH:mm:ss');

		this.props.setExistingEvent(undefined);
		if (dateFormat > nowFormat && !isconfigCat) {
			this.props.toggleModalConfigure(moment(date.dateStr).utc());
		}

	}



	handleEventClick = (arg) => { // bind with an arrow function
		if (arg.event._def.title !== 'ALARM' && !arg.event._def.extendedProps.ohi_id) {
			this.props.getEvent(arg, 'details')
		} else if (arg.event._def.extendedProps.ohi_id) {
			this.props.getEvent(arg, 'recurring')
		}
	}


	handleDateChange = (event) => {

		let date = event.event._instance.range.start.toUTCString();
		let currentConfiguration = {
			scm_id: event.event._def.extendedProps.scm_id,
			date: moment(date).utc().format('YYYY-MM-DD HH:mm:ss')
		}
		this.setState({ configuration: currentConfiguration });
		this.saveConfiguration();
	}


	handleRangeChange = (event) => {
		let start = moment(event.startStr).format('YYYY-MM-DD HH:mm:ss');
		let end = moment(event.endStr).format('YYYY-MM-DD HH:mm:ss');
		this.setState({ start: start, end: end });
	}


	handleDeviceChange = (value) => {
		this.setState({
			dvcFilter: value,
		}, () => this.buildEvents());
	}


	deleteEvent = (id) => {
		request.delete(
			'scheduledcommand/' + id,
			{},
			() => { },
			(e) => {
				utils.openNotification(
					'error',
					utils.translate('componentConfigure.cannot'),
					utils.translate('generic.rights'),
					'bottomRight',
					5
				);
			}, () => { this.updateCalendar(); },
		);
	}


	deleteSingleRecurringEvent = (id, date) => {
		request.patch(
			'scheduledcommand/' + id,
			{
				repeat_exclude: date
			},
			() => { },
			(e) => {
				utils.openNotification(
					'error',
					utils.translate('componentConfigure.cannot'),
					utils.translate('generic.rights'),
					'bottomRight',
					5
				);
			}, () => { this.updateCalendar(); },
		);
	}


	renderInnerContent(innerProps) {
		return (
			<div className='fc-event-main-frame'>
				{innerProps.timeText &&
					<div className='fc-event-time'>{innerProps.timeText}</div>
				}
				<div className='fc-event-title-container'>
					<div className='fc-event-title fc-sticky'>
						{innerProps.event.title}
					</div>
				</div>
			</div>
		);
	}


	getPopoverContent = (arg) => {
		let eventId = arg.event._def.extendedProps.scm_id;
		let set = arg.event._def.extendedProps.set;
		let isRecurr = arg.event._def.recurringDef;
		let date = new Date(arg.event._instance.range.start);
		let newDateToExclude = date.toISOString();
		let allDatesToExclude = [];
		if (isRecurr) {
			let dates = arg.event._def.recurringDef.typeData.rruleSet._exdate;
			dates.forEach(date => {
				allDatesToExclude.push(new Date(date).toISOString());
			})
			allDatesToExclude.push(newDateToExclude)
		}


		//allDatesToExclude.push
		let canModify = (set === 'SCM_ST_ONECAT' && this.props.widget.CAT_IDS.length > 0) || (set === 'SCM_ST_ONEDVC' && this.props.widget.DVC_IDS)
		return (
			<>
				{canModify &&
					<>
						<Row id='popSched'>
							<Col span={12}>
								<Button
									className='modalButton'
									type='primary'
									icon='save'
									onClick={() => this.props.getEvent(eventId, 'configure')}
									style={{
										width: 180,
										marginTop: 0,
									}}
								>
									{utils.translate('componentWidgetScheduler.modify')}
								</Button>
							</Col>
						</Row>
						<Row>
							<Popconfirm
								placement='rightTop'
								icon={<Icon type='question-circle-o' style={{ color: 'red' }} />}
								title='Are you sure?'
								onConfirm={() => this.deleteEvent(eventId)}
								okText={utils.translate('generic.yes')}
								cancelText={utils.translate('generic.no')}
							>
								<Col span={12}>
									<Button
										className='modalButton'
										type='danger'
										icon='close'
										style={{
											width: 180,
											marginTop: 10,
										}}
									>
										{isRecurr ? utils.translate('componentWidgetScheduler.deleteAll') : utils.translate('componentWidgetScheduler.delete')}
									</Button>
								</Col>
							</Popconfirm>
						</Row>
						{isRecurr &&
							<Row>

								<Popconfirm
									placement='rightTop'
									icon={<Icon type='question-circle-o' style={{ color: 'red' }} />}
									title='Are you sure?'
									onConfirm={() => this.deleteSingleRecurringEvent(eventId, allDatesToExclude.join())}
									okText={utils.translate('generic.yes')}
									cancelText={utils.translate('generic.no')}
								>
									<Col span={12} style={{ display: 'inline-block', textAlign: 'center' }}>
										<Button
											className='modalButton'
											type='danger'
											icon='close'
											style={{
												width: 180,
												marginTop: 10
											}}
										>
											{utils.translate('componentWidgetScheduler.delete')}
										</Button>
									</Col>
								</Popconfirm>
							</Row>}
					</>}
			</>
		)
	}


	getPopoverContentAlarm = (arg) => {

		let type = arg.event._def.extendedProps.type;
		let level = arg.event._def.extendedProps.level;

		return (
			<Row>
				<Col span={12}>
					<List>
						<List.Item>
							<List.Item.Meta
								title={utils.translate('componentWidgetScheduler.type')}
								description={type}
							/>
						</List.Item>
						<List.Item>
							<List.Item.Meta
								title={utils.translate('componentWidgetScheduler.level')}
								description={level}
							/>
						</List.Item>
					</List>
				</Col>
			</Row>
		)
	}


	render() {
		const entries = this.state.devices ? this.state.devices.length : 0;
		const showEmptyWidget = utils.isConfigMode() && !this.props.widget.DVC_IDS && this.props.widget.CAT_IDS.length === 0;
		const isCategoryWidget = this.props.widget.CAT_IDS.length !== 0;

		return (
			<>
				{showEmptyWidget &&
					<div
						id={this.props.getWidgetId + '_CONTENT'}
						className={'value-container CONTENT' + this.props.getWidgetClass + this.props.getWidgetClass + '_CONTENT'}
						style={{ height: `${this.props.size.height}px` }}>

						<EmptyWidget
							size={this.props.size}
							toggleModalConfigure={this.props.toggleModalConfigureScheduler}
							callToAction={utils.translate('componentWidgetStatus.start')}
						/>
					</div>}
				{(this.props.widget.DVC_IDS || this.props.widget.CAT_IDS.length !== 0) &&
					<>
						<Row style={{ marginBottom: '20px' }}>
							<Col span={6}>
								<Button onClick={() => this.handleView('timeGridDay')}>{utils.translate('componentWidgetScheduler.day')}</Button>
								<Button onClick={() => this.handleView('timeGridWeek')}>{utils.translate('componentWidgetScheduler.week')}</Button>
								<Button onClick={() => this.handleView('dayGridMonth')}>{utils.translate('componentWidgetScheduler.month')}</Button>
							</Col>
							<Col span={14}>
								<h3>{isCategoryWidget ? this.props.t('componentWidgetScheduler.perCat') + this.props.widget.DWE_CATLABEL : this.props.t('componentWidgetScheduler.perDvc')}</h3>
							</Col>
						</Row>
						<Row>
							<Col span={6}>
							</Col>
							{!isCategoryWidget && !this.props.isCategoryDashboard &&
								<Col span={10}>
									<DeviceSection
										type={'scheduledCommand'}
										label={utils.translate('generic.dvc')}
										entries={entries}
										deviceSelectPlaceholder={oneDevicePH}
										devices={this.state.devices.length !== 0 ? this.state.devices : []}
										selectedDevices={this.state.selectedDevice}
										handleDeviceChange={this.handleDeviceChange}
										allowClearDeviceSelect={true}
										loading={entries === 0}
									/>
								</Col>}
						</Row>
						<FullCalendar
							key={this.state.view}
							id='calendar'
							ref={this.calendarComponentRef}
							editable={true}
							height='85%'
							timeZone='UTC'
							locale={utils.getCurrentLanguage() === 'fr' ? frLocale : 'en'}
							datesSet={(event) => this.handleRangeChange(event)}
							plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, rrulePlugin]}
							initialView={this.state.view}
							initialDate={this.state.date}
							dateClick={this.handleDateClick}
							allDaySlot={false}
							events={this.state.loaded && this.state.events}
							eventDrop={info => {
								if (info.event._def.title !== 'ALARM' && !utils.isConfigMode() && !info.event._def.recurringDef) {
									const { start } = info.oldEvent._instance.range;
									const {
										start: newStart
									} = info.event._instance.range;
									let oldStartFormat = moment(new Date(start)).utc().format('YYYY-MM-DD HH:mm:ss');
									let newStartFormat = moment(new Date(newStart)).utc().format('YYYY-MM-DD HH:mm:ss');
									let nowFormat = moment(new Date(Date.now())).format('YYYY-MM-DD HH:mm:ss');
									if (newStartFormat <= nowFormat || oldStartFormat <= nowFormat) {
										info.revert();
									} else {
										this.handleDateChange(info);
									}
								} else {
									info.revert();
								}
							}}
							eventClick={this.handleEventClick}
							eventContent={(arg) => {
								if (arg.event._def.title !== 'ALARM') {
									if (new Date(arg.event._def.extendedProps.dateText) > new Date(Date.now()) || arg.event._def.recurringDef) {
										return (
											<Popover content={this.getPopoverContent(arg)}>
												{this.renderInnerContent(arg)}
											</Popover>
										);
									}
								} else {
									return (
										<Popover title={'Alarm: ' + arg.event._def.title} content={this.getPopoverContentAlarm(arg)}>
											{this.renderInnerContent(arg)}
										</Popover>
									)
								}
							}}
						/>
					</>}
			</>
		)
	}
}


export default withTranslation()(Scheduler);
