import React, { useState, useEffect } from 'react';
import { Popover, Row, Col, Button, Tooltip, Icon, Divider, Tabs } from 'antd';

import SvgIcon from '../../../../StudioLib/SvgIcon/SvgIcon';
import { allAvailableIcons } from '../../../../StudioLib/SvgIcon/Icons';
import IconSearch from './IconSearch/IconSearch';
import NoData from '../NoData/NoData';

import { utils } from 'Lib/utils.js';

const { TabPane } = Tabs;

const selectedIconFill = '#1890ff';


const IconPicker = ({ initialIcon, device, setIconToParent, disabled, props }) =>
{
	
	const [popOverVisible, setPopOverVisible] = useState(false);
	const [noIcons, setNoIcons] = useState(false);
	const [allIcons, setAllIcons] = useState({});
	const [selectedIcon, setSelectedIcon] = useState('');
	const [selectedFamily, setSelectedFamily] = useState('');
	
	
	useEffect(() =>
		{
			setSelectedIcon(initialIcon);
			setAllIcons(allAvailableIcons);
		},
		[]
	);
	
	
	useEffect(() =>
		{
			setNoIcons(!areThereIcons());
		},
		[allIcons]
	);
	
	
	useEffect(() =>
		{
			setSelectedIcon(initialIcon);
		},
		[initialIcon]
	);
	
	
	useEffect(() =>
		{
			if (selectedIcon !== '')
			{
				setSelectedFamily(findSelectedIconsFamily());
				if (selectedIcon !== initialIcon) { setIconToParent(selectedIcon, device); } // device my be undefined
			}
		},
		[selectedIcon]
	);
	
	
	useEffect(() =>
		{
			if (!popOverVisible)
			{
				setAllIcons(allAvailableIcons);
			}
		},
		[popOverVisible]
	);
	
	
	const togglePopover = (visible) =>
	{
		!disabled && setPopOverVisible(visible);
	}
	
	
	const getCleanIconName = (dirtyName) =>
	{
		let cleanName = '';
		
		dirtyName.split('-').forEach(dirtyNamePart => // each hyphen-separated word gets cleaned up
		{
			const cleanNamePart = utils.capitalizeFirstLetter(dirtyNamePart) + ' ';
			cleanName += cleanNamePart;
		});
		
		return cleanName;
	}
	
	
	const findSelectedIconsFamily = () =>
	{
		return Object.keys(allIcons).find(iconFamily =>
		{
			return Object.keys(allIcons[iconFamily]).find(iconType => iconType === selectedIcon);
		});
	}
	
	
	const areThereIcons = () =>
	{
		return Object.keys(allIcons).some(iconFamily => // finding out whether we have a non-empty family
		{
			return Object.keys(allIcons[iconFamily]).length !== 0; // family has at least one icon
		});
	}
	
	
	const getPickerContent = () =>
	{
		const rowStyle = {
			padding: 16,
			width: 400
		};
		
		return (
			
			<>
				<Row>
					<IconSearch
						{...props}
						allAvailableIcons={allAvailableIcons}
						allIcons={allIcons}
						setAllIcons={setAllIcons}
					/>
				</Row>
				<Row style={rowStyle}>
					{!noIcons &&
					<Tabs
						defaultActiveKey={selectedFamily}
					>
						{Object.keys(allIcons).map(iconFamily => allIcons[iconFamily] && Object.keys(allIcons[iconFamily]).length !== 0 &&
						<TabPane
							tab={
								<span>
									{iconFamily}
								</span>
							}
							key={iconFamily}
							style={{ padding: 10 }}
						>
							{Object.keys(allIcons[iconFamily]).map(icon =>
								<Col
									key={'col_' + icon}
									span={4}
									className='text-center'
									style={{ padding: 8 }}
								>
									<Tooltip title={getCleanIconName(icon)}>
										<Button
											className={icon === selectedIcon ? 'shadowedIcon-selected' : 'shadowedIcon'}
											shape='circle'
											ghost
											style={{ height: 50, width: 50 }}
											onClick={() => setSelectedIcon(icon)}
										>
											<SvgIcon
												type={icon}
												height={30}
												fill={icon === selectedIcon ? selectedIconFill : ''}
											/>
										</Button>
									</Tooltip>
								</Col>
							)}
						</TabPane>)}
					</Tabs>}
					{noIcons &&
					<NoData
						marginTop={20}
						description={utils.translate('componentIconPicker.noIcon')}
					/>}
				</Row>
				<Divider/>
				<Col className='text-center'>
					<Button
						type='primary'
						onClick={() => setPopOverVisible(false)}
						icon='check'
					>
						{utils.translate('generic.done')}
					</Button>
				</Col>
			</>
		);
	};
	
	
	return (

		<Popover
			content={getPickerContent()}
			trigger='click'
			placement='right'
			title={<span><Icon type='picture' className='greyIcon'/>{utils.translate('componentIconPicker.pick')}</span>}
			visible={popOverVisible}
			onVisibleChange={togglePopover}
		>
			<SvgIcon
				type={selectedIcon}
				fill={selectedIconFill}
				styleClass={disabled ? 'iconPicker-disabled' : ''}
				height={30}
			/>
		</Popover>
	);
};


export default IconPicker;
