import React, { Component } from 'react';
import { Switch } from 'antd';

import EmptyWidget from '../../WidgetLib/EmptyWidget/EmptyWidget';

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils.js';

import './Camera.css';


class Camera extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			checkedPower: true,
			checkedQuality: false,
			url: undefined,
			alarm: false,
			detection: false
		};

		this.timerId = null;
		this.componentIsMounted = false;


	}


	componentDidMount() {
		this.componentIsMounted = true;

		if (!this.props.widget.DVC_ID) { return; }
		this.timerId = utils.setRefresh(0.1, this.api_getDevice);
		this.setState({ url: window.ENV.REACT_APP_API_URL + 'devicefeature/' + this.props.widget.DVC_ID + '?mode=camera&res=160x90&token=' + sessionStorage.getItem('token') + '&time=' + Date.now() });
		this.api_getDevice();
	}

	componentDidUpdate(prevprops, prevState) {

		if (prevState.alarm !== this.state.alarm || prevState.detection !== this.state.detection) {
			if (this.state.checkedPower) {
				if (this.state.alarm || this.state.detection) {
					this.setState({ url: window.ENV.REACT_APP_API_URL + 'devicefeature/' + this.props.widget.DVC_ID + '?mode=camera&res=1920x1080&token=' + sessionStorage.getItem('token') + '&time=' + Date.now() });
				} else {
					if (this.state.checkedQuality) {
						this.setState({ url: window.ENV.REACT_APP_API_URL + 'devicefeature/' + this.props.widget.DVC_ID + '?mode=camera&res=1920x1080&token=' + sessionStorage.getItem('token') + '&time=' + Date.now() });
					} else {
						this.setState({ url: window.ENV.REACT_APP_API_URL + 'devicefeature/' + this.props.widget.DVC_ID + '?mode=camera&res=160x90&token=' + sessionStorage.getItem('token') + '&time=' + Date.now() });
					}
				}
			} else {
				this.setState({ url: window.ENV.REACT_APP_API_URL + 'devicefeature/' + this.props.widget.DVC_ID + '?mode=camera&res=0&token=' + sessionStorage.getItem('token') + '&time=' + Date.now(), loading: false });
			};
		}
	}

	componentWillUnmount() {
		this.componentIsMounted = false;
		if (this.timerId !== null) { utils.clearRefresh(this.timerId); }
	}


	api_getDevice = () => {
		request.get(
			'device/' + this.props.widget.DVC_ID,
			{},
			(device) => {
				if (this.componentIsMounted) { this.setState({ device: device[0] }); }
				device[0].attributes.forEach(element => {
					if (element.code === 'alarm') {
						if (element.value === '1') {
							this.setState({ alarm: true });
						} else {
							if (this.state.checkedQuality) {
								this.setState({ alarm: false });
							} else {
								this.setState({ alarm: false });
							}
						}
					};
					if (element.code === 'detection') {
						if (element.value === '1') {
							this.setState({ detection: true });
						} else {
							if (this.state.checkedQuality) {
								this.setState({ detection: false });
							} else {
								this.setState({ detection: false });
							}
						}
					};
				})

			},
			(e) => console.error(e),
			() => this.setState({ loading: false })
		);
	}


	handlePower = (checked) => {
		this.setState({ checkedPower: checked });
		if (checked) {
			if (this.state.checkedQuality || this.state.alarm || this.state.detection) {
				this.setState({ url: window.ENV.REACT_APP_API_URL + 'devicefeature/' + this.props.widget.DVC_ID + '?mode=camera&res=1920x1080y&token=' + sessionStorage.getItem('token') + '&time=' + Date.now() });
			} else {
				this.setState({ url: window.ENV.REACT_APP_API_URL + 'devicefeature/' + this.props.widget.DVC_ID + '?mode=camera&res=160x90&token=' + sessionStorage.getItem('token') + '&time=' + Date.now() });
			}
		} else {
			this.setState({ url: window.ENV.REACT_APP_API_URL + 'devicefeature/' + this.props.widget.DVC_ID + '?mode=camera&res=0&token=' + sessionStorage.getItem('token') + '&time=' + Date.now(), loading: false });
		}
	}


	async handleQuality(checked) {
		const sleep = (milliseconds) => {
			return new Promise(resolve => setTimeout(resolve, milliseconds))
		}
		this.setState({ checkedQuality: checked });

		if (this.state.checkedPower) {
			if (checked) {
				this.setState({ url: window.ENV.REACT_APP_API_URL + 'devicefeature/' + this.props.widget.DVC_ID + '?mode=camera&res=0&token=' + sessionStorage.getItem('token') + '&time=' + Date.now(), loading: false });
				await sleep(1000);
				this.setState({ url: window.ENV.REACT_APP_API_URL + 'devicefeature/' + this.props.widget.DVC_ID + '?mode=camera&res=1920x1080&token=' + sessionStorage.getItem('token') + '&time=' + Date.now(), loading: false });
			} else {
				this.setState({ url: window.ENV.REACT_APP_API_URL + 'devicefeature/' + this.props.widget.DVC_ID + '?mode=camera&res=0&token=' + sessionStorage.getItem('token') + '&time=' + Date.now(), loading: false });
				await sleep(1000);
				this.setState({ url: window.ENV.REACT_APP_API_URL + 'devicefeature/' + this.props.widget.DVC_ID + '?mode=camera&res=160x90&token=' + sessionStorage.getItem('token') + '&time=' + Date.now(), loading: false });
			}
		} else {
			this.setState({ url: window.ENV.REACT_APP_API_URL + 'devicefeature/' + this.props.widget.DVC_ID + '?mode=camera&res=0&token=' + sessionStorage.getItem('token') + '&time=' + Date.now(), loading: false });
		}

	}


	render() {
		const showEmptyWidget = utils.isConfigMode() && !this.props.widget.DVC_ID && !this.props.isCategoryDashboard;

		let powerOn = this.props.widget.LABEL_POWER.split(',')[0];
		let powerOff = this.props.widget.LABEL_POWER.split(',')[1];
		let highQuality = this.props.widget.LABEL_QUALITY.split(',')[0];
		let lowQuality = this.props.widget.LABEL_QUALITY.split(',')[1];

		return (

			<div
				id={this.props.getWidgetId + '_CONTENT'}
				className={'value-container CONTENT' + this.props.getWidgetClass + this.props.getWidgetClass + '_CONTENT'}
				style={{ height: `${this.props.size.height}px` }}>
				{showEmptyWidget &&
					<EmptyWidget
						size={this.props.widgetSize}
						{...this.props}
						callToAction={utils.translate('componentWidgetStatus.start')}
					/>}
				{this.props.widget.DVC_ID &&
					<>
						{!this.state.loading &&
							<div className='value-content'>
								<img
									id='video'
									alt='camera flux'
									src={this.state.url ? this.state.url : window.ENV.REACT_APP_API_URL + 'devicefeature/' + this.props.widget.DVC_ID + '?mode=camera&res=160x90&token=' + sessionStorage.getItem('token') + '&time=' + Date.now()}
									style={{ height: `${this.props.size.height}px`, width: 'auto' }}
								/>
								{!this.state.alarm && !this.state.detection &&
									<div className="caption" style={{ paddingLeft: '20px' }}>
										{this.state.checkedPower ? this.state.checkedQuality ? utils.translate('componentWidgetCamera.high') : utils.translate('componentWidgetCamera.low') : utils.translate('componentWidgetCamera.noFlux')}
									</div>}
								{(this.state.alarm || this.state.detection) &&
									<div className="caption" style={{ paddingLeft: '20px', color: 'red', fontWeight: 'bold' }}>
										{this.state.alarm && this.state.detection ? utils.translate('componentWidgetCamera.alert1') : this.state.alarm ? utils.translate('componentWidgetCamera.alert2') : utils.translate('componentWidgetCamera.alert3')}
									</div>}
							</div>}
						{this.state.loading &&
							<div className='value-content'>
								<div className="caption">{!this.state.checkedPower ? utils.translate('componentWidgetCamera.noFlux') : utils.translate('componentWidgetCamera.change')}</div>
							</div>}
						<div id='buttons'>
							<Switch
								onChange={this.handlePower}
								checkedChildren={powerOn}
								unCheckedChildren={powerOff}
								defaultChecked
							/>
							<Switch
								onChange={(checked) => this.handleQuality(checked)}
								checkedChildren={highQuality}
								unCheckedChildren={lowQuality}
								checked={this.state.checkedQuality || this.state.alarm || this.state.detection}
								disabled={this.state.alarm || this.state.detection}
							/>
						</div>
					</>}
			</div>

		)
	}
}


export default Camera;