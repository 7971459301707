import React from 'react';
import { Icon, Tag, Divider } from 'antd';

import HelpTag from '../HelpTag/HelpTag';
import { utils } from 'Lib/utils';

const AttributeTagInfo = ({
    type,
    contentType,
    example,
    isCategoryDashboard,
}) => {
    const infoIcon = 'info-circle';

    const tagInfoText = (type, contentType, example) => {
        return (
            <>
                <p>
                    <Icon type={infoIcon} className="formPHIcon" />
                    <b>
                        {utils.translate('componentAttributeTagInfo.info1')}{' '}
                        {type}.
                    </b>
                </p>
                <p>
                    <i>
                        {utils.translate('componentAttributeTagInfo.info2')}
                        <br />
                        {utils.translate(
                            'componentAttributeTagInfo.info3'
                        )}{' '}
                        {contentType}.
                    </i>
                </p>
                <Divider />
                <div className="text-center">
                    <p>
                        <b>
                            {utils.translate(
                                'componentAttributeTagInfo.supported'
                            )}
                        </b>
                    </p>
                    <p>
                        {utils.translate(
                            'componentAttributeTagInfo.attributeDvc'
                        )}{' '}
                        <Tag color="volcano">
                            [ATTRIBUTE_<i>attribute_code</i>]
                        </Tag>
                        <br />
                        <small>
                            <i>e.g.</i> '{example}[ATTRIBUTE_DEVEUI]'{' '}
                            {utils.translate('componentAttributeTagInfo.will')}{' '}
                            '{example}1234'
                        </small>
                    </p>

                    <p>
                        {utils.translate(
                            'componentAttributeTagInfo.attributeDvcCatNumber'
                        )}{' '}
                        <Tag color="volcano">
                            [DEVICES_<i>categoryId</i>]
                        </Tag>
                        <br />
                        <small>
                            <i>e.g.</i> '{example}[DEVICES_3]'{' '}
                            {utils.translate('componentAttributeTagInfo.will')}{' '}
                            '{example}1234'
                        </small>
                    </p>

                    {isCategoryDashboard && (
                        <p>
                            {utils.translate(
                                'componentAttributeTagInfo.attributeDvcThisCatNumber'
                            )}{' '}
                            <Tag color="volcano">[DEVICES]</Tag>
                            <br />
                            <small>
                                <i>e.g.</i> '{example}[DEVICES]'{' '}
                                {utils.translate(
                                    'componentAttributeTagInfo.will'
                                )}{' '}
                                '{example}42'
                            </small>
                        </p>
                    )}

                    <p>
                        {utils.translate(
                            'componentAttributeTagInfo.attributeDvcCatNumberByStatus'
                        )}{' '}
                        <Tag color="volcano">
                            [DEVICES_<i>STATUS_categoryId</i>]
                        </Tag>
                        <br />
                        <small>
                            <i>e.g.</i> '{example}[DEVICES_WORKING_1234]'{' '}
                            {utils.translate('componentAttributeTagInfo.will')}{' '}
                            '{example}42'
                        </small>
                    </p>

                    <p>
                        {utils.translate(
                            'componentAttributeTagInfo.attributeAlarmActiveNumber'
                        )}{' '}
                        <Tag color="volcano">[ALARMS_ACTIVE]</Tag>
                        <br />
                        <small>
                            <i>e.g.</i> '{example}[ALARMS_ACTIVE]'{' '}
                            {utils.translate('componentAttributeTagInfo.will')}{' '}
                            '{example}42'
                        </small>
                    </p>
                </div>
            </>
        );
    };

    return (
        <p style={{ marginTop: 10 }}>
            <HelpTag
                tagText={utils.translate('componentAttributeTagInfo.learn')}
                popoverContent={tagInfoText(type, contentType, example)}
                iconType={infoIcon}
                color="green"
                popoverPlacement="left"
            />
        </p>
    );
};

export default AttributeTagInfo;
