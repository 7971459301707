import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from '../../Map/Marker/Marker';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup, Label } from 'reactstrap';
import { Input, Divider, Select, Form, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import { GMAP_KEY } from 'Config/config';
import { request } from 'Lib/request';
import { utils } from 'Lib/utils';
import { timezones } from 'Lib/timezones';

import './EditForm.css';

const { Option } = Select;


class EditForm extends Component
{

    constructor(props)
	{
        super(props);

        this.state = {
			containers: [],
			modalToggled: false,
			deviceToEdit: undefined,
			justEditedDevice: undefined,
			formIsValid: true,
			labelExists: false,
        }

		this.componentIsMounted = false;
    }


	componentWillReceiveProps(newProps)
	{
		this.setState({
			deviceToEdit: newProps.deviceToEdit,
			formIsValid: true,
			labelExists: false,
		});
	}


	componentDidMount()
	{
		this.componentIsMounted = true;
		this.api_getContainers();
	}


	componentWillUnmount()
	{
		this.componentIsMounted = false;
    }


	api_getContainers = () =>
	{
		request.get(
	        'container',
	        {},
	        (containers) => {
				if (this.componentIsMounted) { this.setState({ containers: containers }); }
			},
	        () => console.error('load devices failed')
	    );
	}


	getCoordinatesFromMap = (value) =>
	{
		let deviceToEdit = this.state.deviceToEdit;
		deviceToEdit.latitude = value.lat;
		deviceToEdit.longitude = value.lng;

		this.setState({ deviceToEdit: deviceToEdit });
	}


	handleLabelChange = (event) =>
	{
		let newLabel = event.target.value;
		let deviceToEdit = this.state.deviceToEdit;
		deviceToEdit.label = newLabel;

		this.setState({
			deviceToEdit: deviceToEdit,
			labelExists: false
		});

		this.formIsValid();
	}


	handleContainerChange = (newContainerId) =>
	{
		let deviceToEdit = this.state.deviceToEdit;
		deviceToEdit.container.id = newContainerId;

		this.setState({ deviceToEdit: deviceToEdit });
	}


	handleTimezoneChange = (newTimezone) =>
	{
		let deviceToEdit = this.state.deviceToEdit;
		deviceToEdit.timezone = newTimezone;

		this.setState({ deviceToEdit: deviceToEdit });
	}


	handleLocationChange = (event, locationType) =>
	{
		let newLocationData = event.target.value;

		if (newLocationData && newLocationData.toString().match(/[a-z]/i)) { return; } // filtering out letters

		let deviceToEdit = this.state.deviceToEdit;
		deviceToEdit[locationType] = newLocationData;

		this.setState({ deviceToEdit: deviceToEdit });
	}


	handleAttributeChange = (event, index) =>
	{
		let newAttributeValue = event.target.value;
		let deviceToEdit = this.state.deviceToEdit;
		deviceToEdit.attributes[index].value = newAttributeValue;

		this.setState({ deviceToEdit: deviceToEdit });
	}


	formIsValid = () =>
	{
		let isValidForm = false;

		if (this.state.deviceToEdit.label && this.state.deviceToEdit.label.length > 0)
		{
			isValidForm = true;
		}

		this.setState({ formIsValid: isValidForm });

		return isValidForm;
	}


	api_editDevice = () =>
	{
		if (!this.formIsValid()) { return; }

		let apiAttributes = [];
		let apiAttribute = {};
		let deviceToEdit = this.state.deviceToEdit;

		deviceToEdit.attributes.forEach(stateAttribute =>
		{
			apiAttribute[stateAttribute.code] = stateAttribute.value;
			apiAttributes.push(apiAttribute);
			apiAttribute = {};
		});

		request.put(
			'device/' + deviceToEdit.id,
			{
				device_label: deviceToEdit.label,
				container: deviceToEdit.container && deviceToEdit.container.id,
				device_timezone: deviceToEdit.timezone,
				device_latitude: deviceToEdit.latitude,
				device_longitude: deviceToEdit.longitude,
				dynamic_attribs: apiAttributes
			},
			(res) =>
			{ // caching a local copy to ensure that next functions use a defined object (props may change before asynchronous treatment is done)
				this.setState({ justEditedDevice: deviceToEdit }, () =>
				{
					this.updateTable();
					this.props.closeModal();
				});

				utils.openNotification(
					"success",
					utils.translate('componentEditForm.editedDVC'),
					utils.translate('componentCreateForm.success1') + deviceToEdit.label + utils.translate('componentEditForm.success2'),
					"bottomRight"
				);
			},
			(error) =>
			{
				if (error.error_number === 132)
				{ // api is telling us that device label already exists for this spo (checks all devices but current one obv.) (see GET /error for all error codes)
					this.setState({ labelExists: true });

					utils.openNotification(
						"error",
						utils.translate('componentCreateForm.labelConflict1'),
						utils.translate('componentEditForm.labelConflict2'),
						"bottomRight"
					);
				}
			}
		);
	}


	updateTable = () =>
	{ // updates parent state, re-renders table
		let tableDevices = this.props.devices;
		let updatedDevice = {};

		tableDevices.forEach((device, index) =>
		{
			if (device.id === this.state.justEditedDevice.id)
			{
				tableDevices[index] = this.state.justEditedDevice; // will update table row without api call needed
			}
		});

		updatedDevice[this.state.justEditedDevice.id] = true;
		this.props.saveToParent("updated", updatedDevice); // // will switch row's "edit/updated" button to "updated"

		setTimeout(function()
		{ // will switch row's "edit/updated" button back to "edit" after delay
			updatedDevice[this.state.justEditedDevice.id] = false;
			this.props.saveToParent("updated", updatedDevice);
        }.bind(this), 1500);
	}


    render()
	{
        return (

            <Modal className="wideModal" onMouseDown={e => e.stopPropagation()} isOpen={this.props.isOpen} toggle={this.props.closeModal} size="lg">
                <ModalHeader toggle={this.props.closeModal} style={{paddingBottom: 0}}>
				<FontAwesomeIcon className="form-icon" icon="pencil-alt"/>
				{utils.translate('componentEditForm.edit')} {this.state.deviceToEdit ? this.state.deviceToEdit.label + " (" + this.state.deviceToEdit.category.label + ")" : utils.translate('generic.dvc')}
				<Divider />
				</ModalHeader>
                <ModalBody>
					<p className="formTitle">
						<FontAwesomeIcon className="form-icon" icon="info"/>{utils.translate('componentCreateForm.info2')}
					</p>
					<FormGroup className="deviceForm">
						<Row>
							<Col md={{size: 3}}>
								<Form.Item>
									<Label className="label">
										{utils.translate('generic.labelDvc')}<span style={{color: "red"}}>*</span>
									</Label>
								</Form.Item>
							</Col>
							<Col md={{size: 9}}>
								<Form.Item
									hasFeedback={this.state.deviceToEdit ? (this.state.deviceToEdit.label.length === 0 || this.state.labelExists ? true : false) : false}
									validateStatus={this.state.deviceToEdit ? (this.state.deviceToEdit.label.length === 0 || this.state.labelExists ? "error" : "") : ""}
									help={this.state.deviceToEdit ? (this.state.deviceToEdit.label.length === 0 ? utils.translate('componentCreateForm.label2') : (this.state.labelExists ? utils.translate('componentCreateForm.label3') : "")) : ""}
								>
									<Input placeholder={this.props.t('generic.labelDvc')} value={this.state.deviceToEdit ? this.state.deviceToEdit.label : ""} onChange={this.handleLabelChange}/>
								</Form.Item>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className="deviceForm">
						<Row>
							<Col md={{size: 3}}>
								<Form.Item>
									<Label className="label">
										{utils.translate('componentCreateForm.ctr1')}<span style={{color: "red"}}>*</span>
									</Label>
								</Form.Item>
							</Col>
							<Col md={{size: 9}}>
							{
										this.state.deviceToEdit  && 
										this.state.deviceToEdit.container && 
										(
											<Select
												showSearch
												optionFilterProp="children"
												filterOption={(input, option) => option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												placeholder={utils.translate('componentCreateForm.ctr2')}
												value={this.state.deviceToEdit.container.id}
												style={{width: "100%"}}
												onChange={this.handleContainerChange}
											>
											{this.state.containers.map((container) =>
												<Option key={container.name} value={container.id}>{container.name}</Option>
											)}
											</Select>
										)
									}
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className="deviceForm">
						<Row>
							<Col md={{size: 3}}>
								<Form.Item>
									<Label className="label">
										{utils.translate('componentCreateForm.tz1')}
									</Label>
								</Form.Item>
							</Col>
							<Col md={{size: 9}}>
								<Form.Item>
									<Select
										showSearch
										placeholder={utils.translate('componentCreateForm.tz2')}
										value={this.state.deviceToEdit ? this.state.deviceToEdit.timezone : ""}
										style={{width: "100%"}}
										onChange={this.handleTimezoneChange}
									>
									{timezones.map((timezone) =>
										<Option key={timezone} value={timezone}>{timezone}</Option>
									)}
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</FormGroup>
					<Divider />
					<p className="formTitle">
						<FontAwesomeIcon className="form-icon" icon="location-arrow"/>{utils.translate('componentCreateForm.loc2')}
						<small className="smallForm">
							{utils.translate('componentCreateForm.loc2')}
						</small>
					</p>
					<FormGroup className="deviceForm">
						<Row>
							<Col md={{size: 3}}>
								<Form.Item>
									<Label className="label">
										{utils.translate('componentCreateForm.lat1')}
									</Label>
								</Form.Item>
							</Col>
							<Col md={{size: 9}}>
								<Form.Item>
									<Input
										placeholder={this.props.t('componentCreateForm.lat2')}
										value={this.state.deviceToEdit ? this.state.deviceToEdit.latitude : ""}
										onChange={(event) => this.handleLocationChange(event, "latitude")}
									/>
								</Form.Item>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className="deviceForm">
						<Row>
							<Col md={{size: 3}}>
								<Form.Item>
									<Label className="label">
										{utils.translate('componentCreateForm.lon1')}
									</Label>
								</Form.Item>
							</Col>
							<Col md={{size: 9}}>
								<Form.Item>
									<Input
										placeholder={this.props.t('componentCreateForm.lon2')}
										value={this.state.deviceToEdit ? this.state.deviceToEdit.longitude : ""}
										onChange={(event) => this.handleLocationChange(event, "longitude")}
									/>
								</Form.Item>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className="deviceForm">
						<Row>
							<Col md={{size: 3}}>
							</Col>
							<Col md={{size: 9}}>
								{this.state.deviceToEdit &&
									<Form.Item>
										<div style={{ height: '250px', width: '100%' }}>
											<GoogleMapReact
												bootstrapURLKeys={{ key: GMAP_KEY }}
												center={{
									    			lat: (this.state.deviceToEdit.latitude === '' || this.state.deviceToEdit.longitude === '' || this.state.deviceToEdit.latitude === '0' || this.state.deviceToEdit.longitude === '0') ? 48.864716 : parseFloat(this.state.deviceToEdit.latitude),
									                lng: (this.state.deviceToEdit.latitude === '' || this.state.deviceToEdit.longitude === '' || this.state.deviceToEdit.latitude === '0' || this.state.deviceToEdit.longitude === '0') ? 2.349014 : parseFloat(this.state.deviceToEdit.longitude)
									            }}
												zoom={10}
												yesIWantToUseGoogleMapApiInternals
												onClick={this.getCoordinatesFromMap}
											>
											{this.state.deviceToEdit && (this.state.deviceToEdit.latitude !== "" && this.state.deviceToEdit.longitude !== "") &&
												<Marker
													widget={this.props.widget}
													icon='marker'
													lat={this.state.deviceToEdit.latitude}
							                        lng={this.state.deviceToEdit.longitude}
													key={this.state.deviceToEdit.id}
													device={this.state.deviceToEdit}
												/>}
											</GoogleMapReact>
										</div>
									</Form.Item>}
							</Col>
						</Row>
					</FormGroup>
					<Divider />
					<p className="formTitle">
						<FontAwesomeIcon className="form-icon" icon="bars"/>{utils.translate('generic.attrs')}
					</p>
					{this.state.deviceToEdit && this.state.deviceToEdit.attributes &&
					this.state.deviceToEdit.attributes.map((attribute, index) =>
						<FormGroup className="deviceForm" key={attribute.id}>
							<Row>
								<Col md={{size: 3}}>
									<Form.Item>
										<Label className="label">
											{attribute.name}
										</Label>
									</Form.Item>
								</Col>
								<Col md={{size: 9}}>
									<Form.Item>
										{attribute.type === 'LIST' ?
										<Select
											showSearch
											placeholder={utils.translate('componentEditForm.attributeVal')}
											value={attribute.value}
											style={{ width: '100%' }}
											onChange={(value) => this.handleAttributeChange({target: { value: value }}, index)}
											disabled={attribute.readOnly === '1'}
										>
										{attribute.list_items && Object.values(attribute.list_items).map((value) =>
											<Option key={value} value={value}>{value}</Option>
										)}
										</Select>
										:
										<Input
											placeholder={utils.translate('componentEditForm.attributeVal')}
											value={attribute.value}
											onChange={(event) => this.handleAttributeChange(event, index)}
											disabled={attribute.readOnly === '1'}
											title={attribute.readOnly === '1' ? utils.translate('componentEditForm.readOnly') : ''}
										/>}
									</Form.Item>
								</Col>
							</Row>
						</FormGroup>)}
                </ModalBody>
                <ModalFooter>
                    <Button className="modalButton" onClick={this.props.closeModal}>{utils.translate('generic.cancel')}</Button>
					<Button className="modalButton" type="primary" style={{cursor: this.state.formIsValid ? "pointer" : "not-allowed"}} disabled={!this.state.formIsValid} onClick={this.api_editDevice}>{utils.translate('componentEditForm.edit')}</Button>{' '}
                </ModalFooter>
				<small style={{textAlign: "right", display: this.state.formIsValid ? "none" : "", marginRight: "10px"}}><FontAwesomeIcon className="form-icon" icon="exclamation"/>{utils.translate('componentCreateForm.missing')}</small>
            </Modal>
        );
    }
}


export default withTranslation()(EditForm);
