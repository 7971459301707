import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';

import LabelItem from '../../FormItems/LabelItem/LabelItem';
import IconPicker from '../../../WidgetLib/IconPicker/IconPicker';
import DeviceCustomizerSection from '../DeviceCustomizerSection/DeviceCustomizerSection';

import IconHelpTag from './IconHelpTag/IconHelpTag';
import { utils } from 'Lib/utils';


const IconSection = (props) =>
{
	
	const showHelpTag = (userWantsToCustomizeDevices) =>
	{
		return userWantsToCustomizeDevices ? <IconHelpTag/> : null;
	}
	
	
	return (

		<>
			<FormGroup>
				<Row>
					<LabelItem
						iconType='picture'
						label={props.label}
					/>
					<Col md={{ size: 9 }}>
						<IconPicker
							{...props}
							disabled={props.switchDisabled}
						/>
					</Col>
				</Row>
			</FormGroup>
			<FormGroup>
				<DeviceCustomizerSection
					{...props}
					customizerType='icons'
					label={utils.translate('componentIconSection.customize')}
					customizerInfo={showHelpTag(props.userWantsToCustomizeDevices)}
				/>
			</FormGroup>
		</>
	);
};


export default IconSection;
