import React, { useState, useEffect } from 'react';

import { utils } from 'Lib/utils';
import { Input, InputNumber, Table, Button, Select } from 'antd';
import ImageUploader from 'react-images-upload';
import { withTranslation } from 'react-i18next';
import { Row, FormGroup } from 'reactstrap';
import './MultipleImageConfigure.css';

const { Option } = Select;

const MultipleImageConfigure = (props) => {

	const {
		widget,
		configuration,
		saveConfiguration,
	} = props;

	const [images, setImages] = useState({});
	const [newImage, setNewImage] = useState('');
	const [newMin, setNewMin] = useState('');
	const [newMax, setNewMax] = useState('');
	const [newMinOperator, setNewMinOperator] = useState(undefined);
	const [newMaxOperator, setNewMaxOperator] = useState(undefined);
	const [newLabel, setNewLabel] = useState('');
	const [buttonsVisibility, setButtonsVisibility] = useState('visible');

	const buttonStyles = {
		color: 'white',
		background: '#40a9ff',
		width: 150,
		visibility: buttonsVisibility,
	};


	useEffect(() => {
		if (widget)
			setImages(widget.JWI);
	},
		[]
	);


	const keys = {
		'1': '=',
		'2': '>',
		'3': '>=',
		'4': '<',
		'5': '<=',
	};


	const addImage = () => {
		if ((newMin || newMin === 0) && newMinOperator && newMin !== newMax && newImage) {
			let maxId = Math.max.apply(Math, images.map(function(o) { return o.JWI_ID; }));
			let newArray = [...images, { JWI_ID: maxId >= 0 ? maxId + 1 : 0, JWI_VAL: JSON.stringify({ [newMinOperator && newMin]: (newMin || newMin === 0) && newMinOperator, [newMaxOperator ? newMax : '']: (newMax || newMax === 0) ? newMaxOperator : '' }), JWI_LABEL: newLabel, JWI_IMAGE: newImage }];
			setImages(newArray);
			saveConfiguration({
				...configuration,
				jwi_images: newArray,
			});
			setNewLabel('');
			setNewMin('');
			setNewMax('');
			setNewMinOperator('');
			setNewMaxOperator('');
			setNewImage('');
			setButtonsVisibility('visible');
		} else {

		}

	}


	const handleMinChange = (event) => {
		setNewMin(event);
	}


	const handleMaxChange = (event) => {
		setNewMax(event);
	}



	const handleMinOperatorChange = (event) => {
		setNewMinOperator(event);
	}


	const handleMaxOperatorChange = (event) => {
		setNewMaxOperator(event);
	}


	const handleLabelChange = (event) => {
		setNewLabel(event.target.value);
	}


	const handleImageChange = async (rawImage) => {
		if (rawImage && !rawImage.length) {
			setNewImage('');
			setButtonsVisibility('visible');
		}

		else
			setButtonsVisibility('hidden');

		await getBase64(rawImage[0]);
	}


	const getBase64 = async (rawImage) => {
		return new Promise((resolve, reject) => {
			var fileReader = new FileReader();

			fileReader.onerror = () => { reject('Error'); }

			fileReader.onload = () => {
				if (fileReader.result !== undefined) { resolve(setNewImage(fileReader.result)); }
				else { reject('Error'); }
			}

			try { fileReader.readAsDataURL(rawImage); }
			catch (e) { }
		});
	}


	const removeImage = (image) => {
		let filter = images.filter(function (el) { return el.JWI_ID !== image.JWI_ID });
		setImages(filter);
		saveConfiguration({
			...configuration,
			jwi_images: filter,
		});
	}


	const getDataSource = () => {

		let dataSource = [];
		Object.values(images).map((item, index) => {
			const itemVal = Object.keys(JSON.parse(item.JWI_VAL));
			const itemKey = Object.values(JSON.parse(item.JWI_VAL));
			dataSource.push({
				key: index,
				label: item.JWI_LABEL ? item.JWI_LABEL : utils.translate('componentImageConfigure.no'),
				min: !itemKey[1] ? itemKey[0] + ' ' + itemVal[0] : itemVal[0] > itemVal[1] ? itemKey[1] + ' ' + itemVal[1] : itemKey[0] + ' ' + itemVal[0],
				max: !itemKey[1] ?  utils.translate('componentImageConfigure.no') : itemVal[0] > itemVal[1] ? itemKey[0] + ' ' + itemVal[0] : itemKey[1] + ' ' + itemVal[1],
				image: <img
					alt='valueImage'
					src={item.JWI_IMAGE}
					style={{ width: `50px` }}
				/>,
				delete: <Button onClick={() => removeImage(item)}
					type='danger'
					icon='close'
					style={{
						width: 150,
						marginTop: -50,
					}}
				>
					{utils.translate('componentImageUploadSection.remove')}
				</Button>
			})
			return dataSource;
		})
		dataSource.push({
			label: <Input type='text' style={{ width: '75%' }} id='label' placeholder={props.t('generic.label')} name='label' value={newLabel} onChange={handleLabelChange} required />,
			min:
				<>
					<Select
						mode='single'
						style={{ width: '45%' }}
						defaultValue={newMinOperator}
						onChange={handleMinOperatorChange}
						showArrow
						placeholder={'><='}
					>
						{Object.values(keys).map((operator) =>
							<Option
								key={operator}
								value={operator}
							>
								{operator}
							</Option>
						)}
					</Select>
					<InputNumber precision={3} placeholder={props.t('generic.value')} value={newMin} onChange={handleMinChange} />
				</>,
			max:
				<>
					<Select
						mode='single'
						style={{ width: '45%' }}
						defaultValue={newMaxOperator}
						onChange={handleMaxOperatorChange}
						showArrow
						placeholder={'><='}
					>
						{Object.values(keys).map((operator) =>
							<Option
								key={operator}
								value={operator}
							>
								{operator}
							</Option>
						)}
					</Select>
					<InputNumber precision={3} placeholder={props.t('generic.value')} value={newMax} onChange={handleMaxChange} />
				</>,
			image: <ImageUploader
				label={''}
				singleImage={true}
				withIcon={false}
				withPreview={true}
				fileSizeError={utils.translate('componentImageUploadSection.larger')}
				fileTypeError={utils.translate('componentImageUploadSection.formatInvalid')}
				buttonStyles={buttonStyles}
				fileContainerStyle={{ boxShadow: 'none' }}
				onChange={handleImageChange}
				imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
				maxFileSize={1242880}
				buttonText={utils.translate('componentImageConfigure.choose2')}
			/>,
			delete: <Button onClick={addImage}>{utils.translate('componentImageConfigure.add')}</Button>
		})
		return dataSource;
	}

	const columns = [
		{
			title: utils.translate('componentImageConfigure.label'),
			dataIndex: 'label',
			key: 'label',
			align: 'center',
		},
		{
			title: utils.translate('componentImageConfigure.min'),
			dataIndex: 'min',
			key: 'min',
			align: 'center',
		},
		{
			title: utils.translate('componentImageConfigure.max'),
			dataIndex: 'max',
			key: 'max',
			align: 'center',
		},
		{
			title: utils.translate('componentImageConfigure.image'),
			dataIndex: 'image',
			key: 'image',
			align: 'center',
		},
		{
			title: utils.translate('generic.action'),
			dataIndex: 'delete',
			key: 'delete',
			align: 'center',
		},
	];




	return (
		<FormGroup>
			<Row>
				<div className='tableContainer'>
					<Table dataSource={getDataSource()} columns={columns} />
				</div>
			</Row>
		</FormGroup>
	)
};


export default withTranslation()(MultipleImageConfigure);
