import i18n from "Config/i18nConfig";

export const getDefaultOptions = (widget, afterSetExtremes) =>
{
	return {
		
		chart: {
			type: widget.DWC_TYPE, // this is where we set chart type <COLUMN_CHART>
			className: 'chart',
			zoomType: 'x', // zoomable charts
			backgroundColor: null
		},
		title: {
			text: ''
		},
		legend: {
			itemStyle: {
				color: '#686868',
				fontWeight: 'bold'
			},
			itemHoverStyle: {
				color: null // remove legend onHover animation
			},
		},
		lang: {
			noData: i18n.t('generic.noData')
		},
		noData: {
			position: {
				'x': 0,
				'y': 0,
				'align': 'center',
				'verticalAlign': 'middle'
			}
		},
		xAxis: {
			type: 'datetime',
			dateTimeLabelFormats: { // don't display the dummy year
				month: '%e. %b',
				year: '%Y'
			},
			events: {
				setExtremes: afterSetExtremes
			},
			title: false,
		},
		tooltip: {
			useHTML:true,
			headerFormat: '<div style="text-align: center;font-weight: bold;color:#686868;">{series.name}</div>',
			pointFormat: '{point.x:%e. %b %Y}: {point.y:.2f}'
		},
		plotOptions: {
			series: {
				marker: {
					enabledThreshold: widget.DWC_TYPE === 'line' ? 2 : 99,
					fillColor: '#FFFFFF',
					lineWidth: 2,
					lineColor: null
				},
				stacking: widget.DWC_HAS_STACKING === '1' ? 'normal' : undefined,
			},
			area: {
				fillOpacity: 0.7,
			},
			column: {
				minPointLength: 3
			},
		},
		credits: {
			enabled: false
		}
	};
}
