import React from 'react';
import { Select, Form } from 'antd';
import { utils } from 'Lib/utils';

const { Option, OptGroup } = Select;


const ColumnSelect = (props) =>
{	
	
	return (

		<Form.Item>
			<Select
				ref={props.selectRef}
				mode='multiple'
				placeholder={utils.translate('componentWidgetTable.selectCol')}
				value={props.columnsToDisplay}
				onChange={props.api_handleColumnToDisplayChange}
				style={{ minWidth: '250px' }}
				filterOption={(input, option) =>
					String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
				}
				maxTagCount={3}
				onMouseDown={e => e.stopPropagation()}
			>
				<OptGroup label={utils.translate('componentWidgetTable.actions')}>
					<Option key='actions' value='actions'>{utils.translate('componentWidgetTable.actions')}</Option>
				</OptGroup>
				<OptGroup label='General Properties'>
					<Option key='id' value='id'>{utils.translate('componentWidgetTable.id')}</Option>
					<Option key='device' value='device'>{utils.translate('generic.labelDvc')}</Option>
					<Option key='category' value='category'>{utils.translate('componentWidgetTable.cat')}</Option>
					<Option key='container' value='container'>{utils.translate('componentWidgetTable.container')}</Option>
					<Option key='device_status' value='device_status'>{utils.translate('componentWidgetTable.statusDVC')}</Option>
					<Option key='active_alarms' value='active_alarms'>{utils.translate('componentWidgetTable.activeALA')}</Option>
					<Option key='resolved_alarms' value='resolved_alarms'>{utils.translate('componentWidgetTable.resolvedALA')}</Option>				
					<Option key='allAttributes' value='allAttributes'>{utils.translate('componentWidgetTable.nested')}</Option>
				 </OptGroup>
				 <OptGroup label='Attributes'>
					{props.allAvailableAttributes.map((availableAttribute) =>
						<Option key={availableAttribute.code} value={availableAttribute.code}>{availableAttribute.name}</Option>
					)}
				 </OptGroup>
				 <OptGroup label='Datatypes'>
					{props.allAvailableDatatypes.map((availableDatatype) =>
						<Option key={availableDatatype.number} value={availableDatatype.number}>{availableDatatype.label}</Option>
					)}
				 </OptGroup>
			</Select>
		</Form.Item>
	)
};


export default ColumnSelect;
