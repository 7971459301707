import React, { Component } from 'react';
import { Tooltip, Icon } from 'antd';
import { utils } from 'Lib/utils';

import CategorySelectorModal from './CategorySelectorModal/CategorySelectorModal'


class CategorySelector extends Component
{

	constructor(props)
	{
        super(props);

        this.state = {
			modalIsVisible: false,
			removingCategory: false
        }
    }


	openModal = (action) =>
	{
		this.setState({
			modalIsVisible: true,
			removingCategory: action === 'remove'
		});
	}


	closeModal = () =>
	{
		this.setState({ modalIsVisible: false });
	}


    render()
	{
		const canEdit = this.props.widgets && this.props.widgets.length === 0;

        return (

			<div>
				<Icon className="formPHIcon" type="deployment-unit"/>Category Dashboard - {this.props.category.label} Devices

				<Tooltip placement="bottom" title={canEdit ? utils.translate('componentCategorySelector.canEdit') : utils.translate('componentCategorySelector.cannotEdit')}>
					<Icon className={canEdit ? "editCategoryIcon" : "editCategoryIcon_disabled"} type="edit" onClick={canEdit ? this.openModal : ""}/>
				</Tooltip>

				<Tooltip placement="bottom" title={utils.translate('componentCategorySelector.unbind')}>
					<Icon className={'editCategoryIcon'} type="close-circle" onClick={() => this.openModal('remove')}/>
				</Tooltip>

				<CategorySelectorModal
					closeModal={this.closeModal}
					modalIsVisible={this.state.modalIsVisible}
					widgets={this.props.widgets}
					allCategories={this.props.allCategories}
					category={this.props.category}
					dpgId={this.props.dpgId}
					api_getCategory={this.props.api_getCategory}
					removingCategory={this.state.removingCategory}
					toggleCategoryDashboardManager={this.props.toggleCategoryDashboardManager}
				/>

			</div>
        );
    }
}


export default CategorySelector;
