import React, { Suspense } from 'react';
import { render } from "react-dom";
import { Router } from 'react-router';
import * as serviceWorker from './serviceWorker';
import App from "./App/App";
import history from './Lib/history';
import i18n from "./Config/i18nConfig";

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Provider } from "react-redux";
import store from "./store";

/* eslint no-unused-vars:0 */ 
render(
	<Provider store={store}>
		<Suspense fallback={(<div>Loading</div>)}>
    		<Router history={history}>	
    			<App />
    		</Router>
		</Suspense>
	</Provider>,
	document.getElementById("root")
);

serviceWorker.unregister();
