import React from 'react';
import { Card, Tabs, Icon, Badge } from 'antd';

import DatatypePane from './DatatypePane/DatatypePane';
import AttributePane from './AttributePane/AttributePane';
import AlarmPane from './AlarmPane/AlarmPane';

import './DeviceDetails.css';
import { utils } from 'Lib/utils';

const { TabPane } = Tabs;


const DeviceDetails = ({ device }) =>
{
	return (

		<Card bordered={false}>
			<Tabs defaultActiveKey='1'>
				<TabPane
					tab={
						<span>
							<Icon type='api' className='greyIcon'/>
							{utils.translate('generic.dta')}
						</span>
					}
					key='1'
				>
					<DatatypePane
						device={device}
					/>
				</TabPane>
				<TabPane
					tab={
						<span>
							<Icon type='unordered-list' className='greyIcon'/>
							{utils.translate('generic.attrs')}
						</span>
					}
					key='2'
				>
					<AttributePane
						device={device}
					/>
				</TabPane>
				<TabPane
					tab={
						<span>
							<Icon type='alert' className='greyIcon'/>
							{utils.translate('generic.alas')}
							<Badge
								count={device.active_alarms && device.active_alarms.length}
								showZero={false}
								offset={[5, -15]}
								style={{ backgroundColor: 'orange' }}
							/>
						</span>
					}
					key='3'
				>
					<AlarmPane
						device={device}
					/>
				</TabPane>
			</Tabs>
		</Card>
	);
};


export default DeviceDetails;
