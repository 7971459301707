import React, { Component } from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Select, Divider, Tabs, Tooltip, Icon } from 'antd';

import SuperSelect from 'Components/Studio/StudioLib/SuperSelect/SuperSelect';
import WidgetStyle from 'Components/Studio/Dashboard/Widgets/Style/WidgetStyle.js';

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils';
import LabelItem from 'Components/Studio/Dashboard/Widgets/Configure/FormItems/LabelItem/LabelItem';

const { Option } = Select;

const { TabPane } = Tabs;

class AlarmRule extends Component {
	constructor(props) {
		super(props);

		this.state = {
			allAlarmRules: [],
			selectedAlarmRules: '',
		};
	}

	componentDidMount() {
		this.api_getAlarmRules();
		this.setState({
			selectedAlarmRules: this.props.widget.ALR_IDS,
		});
	}

	api_getAlarmRules = () => {
		request.get(
			'alarmrule',
			{},
			(alarmrules) => {
				this.setState({ allAlarmRules: alarmrules });
			},
			() => console.error('load alarmrules failed')
		);
	};

	handleAlarmRuleChange = (value) => {
		let allAlarmRulesIdsStr = '';
		value.forEach(
			(allAlarmRulesId) => (allAlarmRulesIdsStr += allAlarmRulesId + ',')
		);
		allAlarmRulesIdsStr = allAlarmRulesIdsStr.slice(0, -1);

		this.setState({
			selectedAlarmRules: allAlarmRulesIdsStr,
		});

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.alarmrule_ids = allAlarmRulesIdsStr;
		this.props.saveConfiguration(currentConfiguration);
	};

	getCurrentConfiguration = () => {
		return {
			type: 'alarmRule',
			alarmrule_ids: this.getCurrentAlarmRuleIds(),
		};
	};

	getCurrentAlarmRuleIds = () => {
		if (this.props.configuration.alarmrule_ids === undefined) {
			return this.props.widget.ALR_IDS
				? this.props.widget.ALR_IDS
				: undefined;
		} else if (this.props.configuration.alarmrule_ids === '') {
			return undefined;
		} else {
			return this.props.configuration.alarmrule_ids;
		}
	};

	render() {
		const { selectedAlarmRules } = this.state;
		const currentAlarmRulesIds = selectedAlarmRules
			? selectedAlarmRules.length > 0
				? selectedAlarmRules.split(',')
				: undefined
			: undefined;

		return (
			<Tabs tabPosition="top" tabBarStyle={{ marginBottom: 25 }}>
				<TabPane
					tab={
						<span>
							<Icon type="wifi" className="greyIcon" />
							{utils.translate('componentWidgetConfigure.data')}
						</span>
					}
					key="1"
				>
					<FormGroup>
						<Divider />
						<Row>
							<LabelItem
								iconType="bell"
								label={utils.translate(
									'componentWidgetAlarmRule.alarmrules'
								)}
							/>
							<Col md={{ size: 9 }}>
								<SuperSelect
									value={currentAlarmRulesIds}
									allowClear={true}
									placeholder={utils.translate(
										'componentWidgetAlarmRule.select'
									)}
									onChange={this.handleAlarmRuleChange}
									showSearch
									mode="multiple"
									optionFilterProp="children"
									style={{ width: '100%' }}
									maxTagCount={20}
									showArrow={true}
									filterOption={(input, option) =>
										option.props.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
								>
									{this.state.allAlarmRules.map(
										(alarmrule) => (
											<Option
												key={alarmrule.id}
												value={alarmrule.id}
											>
												{alarmrule.id +
													' - ' +
													alarmrule.label}
											</Option>
										)
									)}
								</SuperSelect>
							</Col>
						</Row>
						<Divider />
					</FormGroup>
				</TabPane>
				<TabPane
					tab={
						<Tooltip title="Style">
							<span>
								<Icon type="bg-colors" className="greyIcon" />
								{utils.translate(
									'componentWidgetConfigure.style'
								)}
							</span>
						</Tooltip>
					}
					key="2"
				>
					<WidgetStyle
						{...this.props}
						marginTop={20}
						configuration={this.getCurrentConfiguration()}
					/>
				</TabPane>
			</Tabs>
		);
	}
}

export default AlarmRule;
