// Farm
import { ReactComponent as BarnIcon } from 'Assets/Icons/barn.svg';
import { ReactComponent as BullIcon } from 'Assets/Icons/bull.svg';
import { ReactComponent as CarriageIcon } from 'Assets/Icons/carriage.svg';
import { ReactComponent as CatIcon } from 'Assets/Icons/cat.svg';
import { ReactComponent as CornIcon } from 'Assets/Icons/corn.svg';
import { ReactComponent as CottageIcon } from 'Assets/Icons/cottage.svg';
import { ReactComponent as CowIcon } from 'Assets/Icons/cow.svg';
import { ReactComponent as DogIcon } from 'Assets/Icons/dog.svg';
import { ReactComponent as LambIcon } from 'Assets/Icons/lamb.svg';
import { ReactComponent as ScarecrowIcon } from 'Assets/Icons/scarecrow.svg';
import { ReactComponent as SiloIcon } from 'Assets/Icons/silo.svg';
import { ReactComponent as SunflowerIcon } from 'Assets/Icons/sunflower.svg';
import { ReactComponent as ToolsIcon } from 'Assets/Icons/tools.svg';
import { ReactComponent as TractorIcon } from 'Assets/Icons/tractor.svg';
import { ReactComponent as WheathercockIcon } from 'Assets/Icons/weathercock.svg';
import { ReactComponent as WellIcon } from 'Assets/Icons/well.svg';
import { ReactComponent as WheatIcon } from 'Assets/Icons/wheat.svg';
import { ReactComponent as WheelbarrowIcon } from 'Assets/Icons/wheelbarrow.svg';
import { ReactComponent as WindmillIcon } from 'Assets/Icons/windmill.svg';

// Energy
import { ReactComponent as AmericanPlugIcon } from 'Assets/Icons/american-plug.svg';
import { ReactComponent as AtomSymbolIcon } from 'Assets/Icons/atom-symbol.svg';
import { ReactComponent as ChargeElectricBatteryIcon } from 'Assets/Icons/charge-electric-battery.svg';
import { ReactComponent as ElectricPlugIcon } from 'Assets/Icons/electric-plug.svg';
import { ReactComponent as EnergyLightningIcon } from 'Assets/Icons/energy-lightning.svg';
import { ReactComponent as EnergyTowerIcon } from 'Assets/Icons/energy-tower.svg';
import { ReactComponent as FireFlameIcon } from 'Assets/Icons/fire-flame.svg';
import { ReactComponent as HydraulicEnergyIcon } from 'Assets/Icons/hydraulic-energy.svg';
import { ReactComponent as LightBulbIcon } from 'Assets/Icons/light-bulb.svg';
import { ReactComponent as ModernWindmillIcon } from 'Assets/Icons/modern-windmill.svg';
import { ReactComponent as PetrolCanIcon } from 'Assets/Icons/petrol-can.svg';
import { ReactComponent as RadioactiveAlertIcon } from 'Assets/Icons/radioactive-alert.svg';
import { ReactComponent as RecycleIcon } from 'Assets/Icons/recycle.svg';
import { ReactComponent as SunbeamsIcon } from 'Assets/Icons/sunbeams.svg';
import { ReactComponent as TreeLeafIcon } from 'Assets/Icons/tree-leaf.svg';

// Industry
import { ReactComponent as BarrowIcon } from 'Assets/Icons/barrow.svg';
import { ReactComponent as BulldozerIcon } from 'Assets/Icons/bulldozer.svg';
import { ReactComponent as ChimneyIcon } from 'Assets/Icons/chimney.svg';
import { ReactComponent as ConstructionTruckIcon } from 'Assets/Icons/construction-truck.svg';
import { ReactComponent as CraneIcon } from 'Assets/Icons/crane.svg';
import { ReactComponent as ElectricTowerIcon } from 'Assets/Icons/electric-tower.svg';
import { ReactComponent as FactoryIcon } from 'Assets/Icons/factory.svg';
import { ReactComponent as FaucetIcon } from 'Assets/Icons/faucet.svg';
import { ReactComponent as GasMaskIcon } from 'Assets/Icons/gas-mask.svg';
import { ReactComponent as GaugeIcon } from 'Assets/Icons/gauge.svg';
import { ReactComponent as MixerTruckIcon } from 'Assets/Icons/mixer-truck.svg';
import { ReactComponent as OffshoreRigIcon } from 'Assets/Icons/offshore-rig.svg';
import { ReactComponent as PipelineIcon } from 'Assets/Icons/pipeline.svg';
import { ReactComponent as RoboticArmIcon } from 'Assets/Icons/robotic-arm.svg';
import { ReactComponent as ShipWithCraneIcon } from 'Assets/Icons/ship-with-crane.svg';
import { ReactComponent as TankIcon } from 'Assets/Icons/tank.svg';
import { ReactComponent as WasteWaterIcon } from 'Assets/Icons/waste-water.svg';
import { ReactComponent as WaterSupplyIcon } from 'Assets/Icons/water-supply.svg';
import { ReactComponent as LargeWheelbarrowIcon } from 'Assets/Icons/large-wheelbarrow.svg';

// Transport
import { ReactComponent as DisabledSpotIcon } from 'Assets/Icons/disabled.svg';
import { ReactComponent as BicycleIcon } from 'Assets/Icons/bicycle.svg';
import { ReactComponent as BikeIcon } from 'Assets/Icons/bike.svg';
import { ReactComponent as AllTerrainVehicleIcon } from 'Assets/Icons/all-terrain-vehicle.svg';
import { ReactComponent as LargeAllTerrainVehicleIcon } from 'Assets/Icons/large-all-terrain-vehicle.svg';
import { ReactComponent as ElectricCarIcon } from 'Assets/Icons/electric-car.svg';
import { ReactComponent as CarIcon } from 'Assets/Icons/car.svg';
import { ReactComponent as Car2Icon } from 'Assets/Icons/car-2.svg';
import { ReactComponent as VanIcon } from 'Assets/Icons/van.svg';
import { ReactComponent as BusIcon } from 'Assets/Icons/bus.svg';
import { ReactComponent as Bus2Icon } from 'Assets/Icons/bus-2.svg';
import { ReactComponent as FiretruckIcon } from 'Assets/Icons/firetruck.svg';
import { ReactComponent as ForkliftIcon } from 'Assets/Icons/forklift.svg';
import { ReactComponent as TruckIcon } from 'Assets/Icons/truck.svg';
import { ReactComponent as TruckAndTrailerIcon } from 'Assets/Icons/truck-and-trailer.svg';
import { ReactComponent as LogisticsTruckIcon } from 'Assets/Icons/logistics-truck.svg';
import { ReactComponent as FuelDispenserIcon } from 'Assets/Icons/fuel-dispenser.svg';
import { ReactComponent as RailwayLineIcon } from 'Assets/Icons/railway-line.svg';
import { ReactComponent as TrainOnTracksIcon } from 'Assets/Icons/train-on-tracks.svg';
import { ReactComponent as TrainIcon } from 'Assets/Icons/train.svg';
import { ReactComponent as StreetcarIcon } from 'Assets/Icons/streetcar.svg';
import { ReactComponent as SubwayIcon } from 'Assets/Icons/subway.svg';
import { ReactComponent as AirlinerIcon } from 'Assets/Icons/airliner.svg';
import { ReactComponent as Airliner2Icon } from 'Assets/Icons/airliner-2.svg';
import { ReactComponent as HotAirBalloonIcon } from 'Assets/Icons/hot-air-balloon.svg';
import { ReactComponent as CanoeIcon } from 'Assets/Icons/canoe.svg';
import { ReactComponent as SailBoatIcon } from 'Assets/Icons/sailboat.svg';
import { ReactComponent as LargeBoatIcon } from 'Assets/Icons/large-boat.svg';
import { ReactComponent as SmallSubmarineIcon } from 'Assets/Icons/small-submarine.svg';

// Navigation
import { ReactComponent as MarkerIcon } from 'Assets/Icons/marker.svg';
import { ReactComponent as SearchMarkerIcon } from 'Assets/Icons/search-marker.svg';
import { ReactComponent as HomeMarkerIcon } from 'Assets/Icons/home-marker.svg';
import { ReactComponent as CheckInMarkerIcon } from 'Assets/Icons/check-in-marker.svg';
import { ReactComponent as PlaceIcon } from 'Assets/Icons/place.svg';
import { ReactComponent as ArrowIcon } from 'Assets/Icons/arrow.svg';
import { ReactComponent as CompassIcon } from 'Assets/Icons/compass.svg';
import { ReactComponent as WindRoseIcon } from 'Assets/Icons/wind-rose.svg';
import { ReactComponent as NavigationAppIcon } from 'Assets/Icons/navigation-app.svg';
import { ReactComponent as MapDirectionIcon } from 'Assets/Icons/map-direction.svg';
import { ReactComponent as StartAndFinishIcon } from 'Assets/Icons/start-and-finish.svg';
import { ReactComponent as AreaWithPinsIcon } from 'Assets/Icons/area-with-pins.svg';


export const allAvailableIcons = {
	'Farming': {
		'cow': CowIcon,
		'bull': BullIcon,
		'lamb': LambIcon,
		'dog': DogIcon,
		'cat': CatIcon,
		'sunflower': SunflowerIcon,
		'corn': CornIcon,
		'wheat': WheatIcon,
		'tools': ToolsIcon,
		'wheelbarrow': WheelbarrowIcon,
		'carriage': CarriageIcon,
		'tractor': TractorIcon,
		'scarecrow': ScarecrowIcon,
		'weathercock': WheathercockIcon,
		'well': WellIcon,
		'silo': SiloIcon,
		'barn': BarnIcon,
		'cottage': CottageIcon,
		'windmill': WindmillIcon,
	},
	'Energy': {
		'sunbeams': SunbeamsIcon,
		'modern-windmill': ModernWindmillIcon,
		'energy-tower': EnergyTowerIcon,
		'energy-lightning': EnergyLightningIcon,
		'charge-electric-battery': ChargeElectricBatteryIcon,
		'electric-plug': ElectricPlugIcon,
		'american-plug': AmericanPlugIcon,
		'light-bulb': LightBulbIcon,
		'atom-symbol': AtomSymbolIcon,
		'radioactive-alert': RadioactiveAlertIcon,
		'fire-flame': FireFlameIcon,
		'hydraulic-energy': HydraulicEnergyIcon,
		'tree-leaf': TreeLeafIcon,
		'petrol-can': PetrolCanIcon,
		'recycle': RecycleIcon,
	},
	'Industry': {
		'gauge': GaugeIcon,
		'faucet': FaucetIcon,
		'pipeline': PipelineIcon,
		'water-supply': WaterSupplyIcon,
		'waste-water': WasteWaterIcon,
		'gas-mask': GasMaskIcon,
		'robotic-arm': RoboticArmIcon,
		'barrow': BarrowIcon,
		'large-wheelbarrow': LargeWheelbarrowIcon,
		'bulldozer': BulldozerIcon,
		'construction-truck': ConstructionTruckIcon,
		'mixer-truck': MixerTruckIcon,
		'tank': TankIcon,
		'crane': CraneIcon,
		'electric-tower': ElectricTowerIcon,
		'chimney': ChimneyIcon,
		'factory': FactoryIcon,
		'offshore-rig': OffshoreRigIcon,
		'ship-with-crane': ShipWithCraneIcon,
	},
	'Transport': {
		'disabled-spot': DisabledSpotIcon,
		'bicycle': BicycleIcon,
		'bike': BikeIcon,
		'electric-car': ElectricCarIcon,
		'car': CarIcon,
		'car-2': Car2Icon,
		'all-terrain-vehicle': AllTerrainVehicleIcon,
		'large-all-terrain-vehicle': LargeAllTerrainVehicleIcon,
		'van': VanIcon,
		'bus': BusIcon,
		'bus-2': Bus2Icon,
		'firetruck': FiretruckIcon,
		'forklift': ForkliftIcon,
		'truck': TruckIcon,
		'truck-and-trailer': TruckAndTrailerIcon,
		'logistics-truck': LogisticsTruckIcon,
		'fuel-dispenser': FuelDispenserIcon,
		'railway-line': RailwayLineIcon,
		'train-on-tracks': TrainOnTracksIcon,
		'train': TrainIcon,
		'streetcar': StreetcarIcon,
		'subway': SubwayIcon,
		'airliner': AirlinerIcon,
		'airliner-2': Airliner2Icon,
		'hot-air-balloon': HotAirBalloonIcon,
		'canoe': CanoeIcon,
		'sailboat': SailBoatIcon,
		'large-boat': LargeBoatIcon,
		'small-submarine': SmallSubmarineIcon,
	},
	'Navigation': {
		'marker': MarkerIcon,
		'search-marker': SearchMarkerIcon,
		'home-marker': HomeMarkerIcon,
		'check-in-marker': CheckInMarkerIcon,
		'place': PlaceIcon,
		'arrow': ArrowIcon,
		'compass': CompassIcon,
		'wind-rose': WindRoseIcon,
		'navigation-app': NavigationAppIcon,
		'map-direction': MapDirectionIcon,
		'start-and-finish': StartAndFinishIcon,
		'area-with-pins': AreaWithPinsIcon,
	},
};
