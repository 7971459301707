import React from 'react';
import { Row, FormGroup } from 'reactstrap';

import AttributeTagInfo from 'Components/Studio/Dashboard/Widgets/WidgetLib/AttributeTagInfo/AttributeTagInfo';
import CustomizerSwitchItem from '../../FormItems/CustomizerSwitchItem/CustomizerSwitchItem';
import DatatypeCustomizerTable from './DatatypeCustomizerTable/DatatypeCustomizerTable';
import {
	chartWidgetColorsInfo,
	chartWidgetConfigInfo,
	chartWidgetLabelInfo,
	pieWidgetColorsInfo,
} from 'Components/Studio/StudioLib/GenericHTML/GenericText.js';

import './DatatypeCustomizer.css';


const DatatypeCustomizer = (props) =>
{
	const customizerInfo = (
		<>
			{chartWidgetConfigInfo}
			{!props.userWantsToCustomizeDatatypes && props.widget.DWG_TYPE === 'DWG_areachart' &&
			chartWidgetColorsInfo}
			{!props.userWantsToCustomizeDatatypes && props.widget.DWG_TYPE === 'DWG_columnchart' &&
			pieWidgetColorsInfo}
			{!props.userWantsToCustomizeDatatypes && props.widget.DWG_TYPE === 'DWG_piechart' &&
			pieWidgetColorsInfo}
			{props.userWantsToCustomizeDatatypes &&
			chartWidgetLabelInfo}
			{props.userWantsToCustomizeDatatypes && props.widget.DWG_TYPE === 'DWG_areachart' &&
			chartWidgetColorsInfo}
			{props.userWantsToCustomizeDatatypes && props.widget.DWG_TYPE === 'DWG_columnchart' &&
			pieWidgetColorsInfo}
			{props.userWantsToCustomizeDatatypes && props.widget.DWG_TYPE === 'DWG_piechart' &&
			pieWidgetColorsInfo}
			<AttributeTagInfo
				type='Labels'
				contentType='plain text'
				example='RSSI - '
			/>
		</>
	);

    return (

		<div>
			<CustomizerSwitchItem
				label={props.label}
				userWantsToCustomize={props.userWantsToCustomizeDatatypes}
				handleCustomizerToggle={props.handleCustomizerToggle}
				customizerInfo={customizerInfo}
			/>
			<FormGroup>
                <Row>
                    <DatatypeCustomizerTable
						userWantsToCustomizeDatatypes={props.userWantsToCustomizeDatatypes}
						widget={props.widget}
						allDatatypes={props.allDatatypes}
						selectedDatatypes={props.selectedDatatypes}
						saveCustomizationToParentState={props.saveCustomizationToParentState}
					/>
            	</Row>
        	</FormGroup>
		</div>
	);
}


export default DatatypeCustomizer;
