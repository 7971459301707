import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { auth } from 'Lib/auth';
import { utils } from 'Lib/utils';


function PrivateRoute({ component: Component, ...rest }) {

    return (

        <Route
            {...rest}
            render={props =>
                auth.isUserAuthenticated(props.location.search) ? (
                    <Component {...props}/>
                ) : (
                    <Redirect
                        to={{
                            pathname: utils.getBaseUrl() + "login",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
}


export default PrivateRoute;
