import React from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { utils } from 'Lib/utils';


const AddButton = (props) =>
{

	return (

		<Tooltip placement='left' autoAdjustOverflow='true' title={utils.translate('componentAddButton.start')}>
			<FontAwesomeIcon
				className='addButton'
				icon='plus-circle'
				onClick={props.togglePlacingMarker}
			/>
		</Tooltip>
	)
};


export default AddButton;
