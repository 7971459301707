import React, { useState, useEffect } from 'react';
import { Form } from 'reactstrap';
import { Button, Divider } from 'antd';

import SliderSection from 'Components/Studio/Dashboard/Widgets/Configure/FormSections/SliderSection/SliderSection.js';
import DualColorsSection from 'Components/Studio/Dashboard/Widgets/Configure/FormSections/ColorSections/DualColorsSection/DualColorsSection.js';
import SelectSection from 'Components/Studio/Dashboard/Widgets/Configure/FormSections/SelectSection/SelectSection.js';
import SwitchSection from 'Components/Studio/Dashboard/Widgets/Configure/FormSections/SwitchSection/SwitchSection.js';

import { utils } from 'Lib/utils.js';

const WidgetStyle = (props) => {

	const {
		widget,
		marginTop,
		configuration,
		saveConfiguration,
	} = props;

	const titlePositions = [
		{key: 'default', value: 'default', label: utils.translate('generic.default')},
		{key: 'center', value: 'center', label: utils.translate('componentWidgetStyle.titlePositions.center')},
		{key: 'left', value: 'left', label: utils.translate('componentWidgetStyle.titlePositions.left')}
	];

	const widgetLayouts = {
		'DWG_value': [
			{key: 'default', value: 'default', label: utils.translate('generic.default')},
			{key: 'col-2', value: 'col-2', label: utils.translate('componentWidgetStyle.widgetLayouts.col-2')},
			{key: 'col-3', value: 'col-3', label: utils.translate('componentWidgetStyle.widgetLayouts.col-3')}],
		'DWG_boolean': [
			{key: 'default', value: 'default', label: utils.translate('generic.default')},
			{key: 'col-2', value: 'col-2', label: utils.translate('componentWidgetStyle.widgetLayouts.col-2')},
			{key: 'col-3', value: 'col-3', label: utils.translate('componentWidgetStyle.widgetLayouts.col-3')}],
		'DWG_index': [
			{key: 'default', value: 'default', label: utils.translate('generic.default')},
			{key: 'col-2', value: 'col-2', label: utils.translate('componentWidgetStyle.widgetLayouts.col-2')},
			{key: 'col-3', value: 'col-3', label: utils.translate('componentWidgetStyle.widgetLayouts.col-3')}],
		'default': [{key: 'default', value: 'default', label: utils.translate('generic.default')}],
	};

	const defaultFontSize = 15;
	const colors = widget.DWG_COLORS ? widget.DWG_COLORS.split(',') : ['', ''];
	const displayAllColors = props.block && props.block.length === 1;
	
	const [colorBackground, setColorBackground] = useState(colors[0]);
	const [colorText, setColorText] = useState(colors[1]);
	const [fontSize, setFontSize] = useState(widget.DWG_FONT_SIZE ? widget.DWG_FONT_SIZE : defaultFontSize);
	const [titleFontSize, setTitleFontSize] = useState(widget.DWG_DECOR.titleFontSize ? widget.DWG_DECOR.titleFontSize : defaultFontSize);
	const [titlePosition, setTitlePosition] = useState(widget.DWG_DECOR.titlePosition ? widget.DWG_DECOR.titlePosition : 'default');
	const [widgetLayout, setWidgetLayout] = useState(widget.DWG_DECOR.widgetLayout ? widget.DWG_DECOR.widgetLayout : 'default');
	const [titleBold, setTitleBold] = useState(widget.DWG_DECOR.titleBold ? widget.DWG_DECOR.titleBold : false);


	useEffect(() => 
	{
		saveConfiguration({
			...configuration,
			widget_colors: colorBackground + ',' + colorText,
			font_size: fontSize,
			decor: {
				titlePosition: titlePosition,
				titleFontSize: titleFontSize,
				widgetLayout: widgetLayout,
				titleBold: titleBold
			}
		});
	},
		[colorBackground, colorText, fontSize, titlePosition, titleFontSize, widgetLayout, titleBold]
	);


	const handleColorChange = (colorObject, index) => 
	{
		const color = utils.convertColorAlphaToHex(colorObject.color, colorObject.alpha);
		index === 0 ? setColorBackground(color) : setColorText(color);
	}

	const handleFontSizeChange = (value) => setFontSize(value);

	const handleTitleFontSizeChange = (value) => setTitleFontSize(value);

	const handleTitlePositionChange = (value) => setTitlePosition(value);

	const handleWidgetLayoutChange = (value) => setWidgetLayout(value);

	const handleTitleBoldChange = (value) => setTitleBold(value);

	const resetStyle = () => 
	{
		setColorBackground('');
		setColorText('');
		setFontSize(15);
		setTitleFontSize(15);
		setTitlePosition('default');
		setWidgetLayout('default');
		setTitleBold(false);
	}

	const zero = utils.convertHexToColorAlpha(colorBackground);
	const one = utils.convertHexToColorAlpha(colorText);
	const [colorZero, alphaZero, colorOne, alphaOne] = [zero.color, zero.alpha, one.color, one.alpha];

	const showFontSizeSlider = widget.DWG_TYPE === 'DWG_value' || widget.DWG_TYPE === 'DWG_status' || widget.DWG_TYPE === 'DWG_gauge';

	const showTitlePositionSelect = widget.DWG_TYPE === 'DWG_value' || widget.DWG_TYPE === 'DWG_status' || widget.DWG_TYPE === 'DWG_command' || widget.DWG_TYPE === 'DWG_html';

	const widgetLayoutOptions = widgetLayouts[widget.DWG_TYPE] ? widgetLayouts[widget.DWG_TYPE] : widgetLayouts['default'];

	const showWidgetLayoutSelect = Object.keys(widgetLayouts).includes(widget.DWG_TYPE);

	return (

		<Form style={{ marginTop: marginTop }}>
			<Button onClick={resetStyle} size='small' style={{ width: 90 }}>
				{utils.translate('componentWidgetStyle.reset')}
			</Button>&nbsp;&nbsp;
			<i>{utils.translate('componentWidgetStyle.changesVisible')}</i>
			{showFontSizeSlider &&
			<>
				<Divider />
				<SliderSection
					label={utils.translate('componentWidgetStyle.font')}
					iconType='font-size'
					defaultValue={fontSize}
					onAfterChange={handleFontSizeChange}
					min={0}
					max={50}
					marks={{ 0: '0px', 50: '50px' }}
				/>
			</>}
			<Divider/>
			<SliderSection
				label={utils.translate('componentWidgetStyle.titleFont')}
				iconType='font-size'
				defaultValue={titleFontSize}
				onAfterChange={handleTitleFontSizeChange}
				min={0}
				max={50}
				marks={{ 0: '0px', 50: '50px' }}
			/>
			<SwitchSection
				label={utils.translate('componentWidgetStyle.titleBold')}
				checked={titleBold}
				handleSwitch={handleTitleBoldChange}
				iconType='bold'
				otherChecked={false}
			/>
			{showTitlePositionSelect &&
			<SelectSection
				icon='drag'
				label={utils.translate('componentWidgetStyle.titlePosition')}
				value={titlePosition}
				onChange={handleTitlePositionChange}
				options={titlePositions}
				/>
			}
			<Divider/>
			<DualColorsSection
				label={utils.translate('componentWidgetStyle.colors')}
				active={utils.translate('componentWidgetStyle.back')}
				inactive={utils.translate('componentWidgetStyle.text')}
				colorZero={colorZero}
				alphaZero={alphaZero}
				colorOne={colorOne}
				alphaOne={alphaOne}
				handleColorChange={handleColorChange}
				widget={widget}
				displayAllColors={displayAllColors}
			/>
			{showWidgetLayoutSelect &&
			<>
				<Divider/>
				<SelectSection
					icon='build'
					label={utils.translate('componentWidgetStyle.widgetLayout')}
					value={widgetLayout}
					onChange={handleWidgetLayoutChange}
					options={widgetLayoutOptions}
				/>
			</>}
		</Form>
	)
};


export default WidgetStyle;
