import React, { Component } from 'react';
import { CardBody, Container, CardTitle } from 'reactstrap';
import { Row, Col, Card } from 'antd';
import { utils } from 'Lib/utils';

import './Inactive.css';
class Inactive extends Component
{

	render()
	{
		const hasImage = this.props.DAP_ICON_URL !== window.ENV.REACT_APP_CS_URL;
		const icon = window.ENV.REACT_APP_CS_URL + this.props.DAP_ICON_URL;

		return (
			 <Container
				fluid
				style={{
					width: '100%',
				}}
			>
            	<Row
					type='flex'
					className='centeredRow'
					style={{
						alignItems: 'center',
					}}
				>
					{hasImage &&
					<>
						<Col
							xl={{ span: 8, offset: 4 }}
							lg={24}
							md={24}
							sm={24}
							xs={24}
							className='text-center'
						>
							<img
								ref='orgLogo'
								src={icon}
								alt=''
								style={{ maxHeight: '400px', maxWidth: '100%', padding: '10%', borderRadius: '15px' }}
							/>
						</Col>
					</>}
                    <Col
						xl={{ span: hasImage ? 8 : 12, offset: hasImage ? 0 : 6 }}
						lg={{ span: 24, offset: 0 }}
						md={{ span: 24, offset: 0 }}
						sm={{ span: 24, offset: 0 }}
						xs={{ span: 24, offset: 0 }}
						className='text-center'
					>
                        <Card
						 	bordered={false}
							style={{backgroundColor: 'white'}}
						>
                            <CardBody>
                                <CardTitle id='inactiveTitle' style={{color: 'black'}}>
                                    <h2>{this.props.APP_LABEL ? this.props.APP_LABEL : 'IoT App Studio'}</h2>
                                </CardTitle>
									<li>{utils.translate('componentInactive.1')}</li>
									<li>{utils.translate('componentInactive.2')}</li>
									<small>IoT App Studio®</small>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
				<div className='credits'>
					<small>{utils.translate('DEFAULT_REACT_APP_CREDITS.1')}</small>
				</div>
            </Container>
		);
	}
}

export default Inactive;