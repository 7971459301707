import React from 'react';
import { Checkbox } from 'antd';
import { Row, Col, FormGroup } from 'reactstrap';

import LabelItem from '../../FormItems/LabelItem/LabelItem';


const CheckboxGroupSection = (props) =>
{

	const {
		iconType,
		label,
		options,
		value,
		onChange,
	} = props;


	return (

		<FormGroup>
			<Row>
				<LabelItem
					iconType={iconType}
					label={label}
				/>
				<Col md={{size: 9}}>
					<Checkbox.Group
						options={options}
						value={value}
						onChange={onChange}
					/>
				</Col>
			</Row>
		</FormGroup>
	)
};


export default CheckboxGroupSection;
