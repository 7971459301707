import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Tooltip } from "antd";
import { utils } from 'Lib/utils.js';

const ColumnOrderButton = (props) => {
	return (
		<Form.Item>
			<Tooltip title={utils.translate('componentWidgetTable.toggleColumnReorder')}>
				<Button type="primary" onClick={props.onClick}>
					<FontAwesomeIcon
						className=""
						icon={
							props.updateColumnOrderIsOpen
								? "toggle-off"
								: "toggle-on"
						}
					/>
				</Button>
			</Tooltip>
		</Form.Item>
	);
};


export default ColumnOrderButton;