import React, { Component } from 'react';
import { Table, Radio, Form, Select, Tag, Icon } from 'antd';

import NoData from '../../../WidgetLib/NoData/NoData';
import DeviceDataExporter from '../../../WidgetLib/DeviceDataExporter/DeviceDataExporter';

import { request } from 'Lib/request';
import { utils } from 'Lib/utils';
import ColumnOrderButton from '../ControlBar/ColumnOrderButton/ColumnOrderButton';

const { Option } = Select;


class AlarmTable extends Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			loading: false,
			columnsToDisplay: [],
			size: this.props.widget.DWT_ROW_SIZE,
			devicesWithAlarms: [],
			visibleAlarms: [],
			updateColumnOrderIsOpen: false,
		};
	}


	static getDerivedStateFromProps(nextProps, prevState)
	{
		return { loading: nextProps.loading };
	}


	componentDidMount()
	{
		const allColumns = ['alarm_type', 'device', 'id', 'category','container', 'description', 'severity', 'status', 'date_creation', 'date_resolution', 'alr_label'];

		this.setState({
			columnsToDisplay: this.props.widget.DWT_COLUMNS ? this.props.widget.DWT_COLUMNS.split(',') : allColumns,
			visibleAlarms: this.props.widget.DWT_VISIBLE_ALARMS ? this.props.widget.DWT_VISIBLE_ALARMS : 'all'
		});

		this.updateDevicesWithAlarms();
	}


	componentDidUpdate(prevProps)
	{
		if (prevProps.devices !== this.props.devices) { this.updateDevicesWithAlarms(); }
	}


	updateDevicesWithAlarms = () =>
	{
		this.setState({
			devicesWithAlarms: []
		},() =>
		{
			if (this.props.devices)
			{
				this.props.devices.forEach((device) =>
				{
					if (device.active_alarms !== undefined)
					{
						this.setState(prevState => ({
							devicesWithAlarms: [...prevState.devicesWithAlarms, device]
						}));
					}
				});
			}
		});
	}


	api_handleSizeChange = (event) =>
	{
		this.setState({ size: event.target.value });

		request.patch(
            'widget/' + this.props.widget.DWG_ID,
            {
				type: 'table',
				row_size: event.target.value
			},
            (success) => {},
            (error) => console.error(error)
        );
	}


	api_handleVisibleAlarmsChange = (event) =>
	{
		this.setState({ visibleAlarms: event.target.value });

		request.patch(
            'widget/' + this.props.widget.DWG_ID,
            {
				type: 'table',
				visible_alarms: event.target.value
			},
            (success) => {},
            (error) => console.error(error)
        );
	}


	api_handleColumnToDisplayChange = (value) =>
	{
		this.setState({ columnsToDisplay: value });
		let columnsToDisplayString = '';

		value.forEach((column) => { columnsToDisplayString += column + ','; });
		columnsToDisplayString = columnsToDisplayString.slice(0, -1);

		request.patch(
            'widget/' + this.props.widget.DWG_ID,
            {
				type: 'table',
				columns: columnsToDisplayString
			},
            (success) => {},
            (error) => console.error(error)
        );
	}

	toggleUpdateColumnOrderDisplay = () => {
		this.setState({updateColumnOrderIsOpen: !this.state.updateColumnOrderIsOpen });
	}


	setColumnsToDisplay = () =>
	{
		const statusToColor = {
			RESOLVED: "green",
			NEW: "volcano",
		};

		const allAvailableColumns = {
			id: {
				title: utils.translate("componentWidgetTable.id"),
				dataIndex: "id",
				key: "id",
				width: 300,
				defaultSortOrder: "descend",
				sorter: (a, b) => {
					return a.id.localeCompare(b.id);
				},
			},
			device: {
				title: utils.translate("generic.labelDvc"),
				dataIndex: "device",
				key: "device",
				width: 300,
				sorter: (a, b) => {
					return a.device.localeCompare(b.device);
				},
			},
			category: {
				title: utils.translate("componentWidgetTable.cat"),
				dataIndex: "category",
				key: "category",
				width: 300,
				sorter: (a, b) => {
					return a.category.localeCompare(b.category);
				},
			},
			container: {
				title: utils.translate("componentWidgetTable.container"),
				dataIndex: "container",
				key: "container",
				width: 300,
				sorter: (a, b) => {
					return a.container.localeCompare(b.container);
				},
			},
			alarm_type: {
				title: utils.translate("componentWidgetTable.type"),
				dataIndex: "alarm_type",
				key: "alarm_type",
				width: 300,
				defaultSortOrder: "descend",
				sorter: (a, b) => {
					return a.alarm_type.localeCompare(b.alarm_type);
				},
			},
			date_creation: {
				title: utils.translate("componentWidgetTable.creation"),
				dataIndex: "date_creation",
				key: "date_creation",
				width: 300,
				defaultSortOrder: "descend",
				sorter: (a, b) => {
					return a.date_creation.localeCompare(b.date_creation);
				},
			},
			description: {
				title: utils.translate("componentWidgetTable.parameter"),
				dataIndex: "description",
				key: "description",
				width: 500,
				defaultSortOrder: "descend",
				sorter: (a, b) => {
					return a.description.localeCompare(b.description);
				},
			},
			alr_label: {
				title: utils.translate("componentWidgetTable.label"),
				dataIndex: "alr_label",
				key: "alr_label",
				width: 500,
				defaultSortOrder: "descend",
				sorter: (a, b) => {
					return a.alr_label.localeCompare(b.alr_label);
				},
			},
			severity: {
				title: utils.translate("componentWidgetTable.severity"),
				dataIndex: "severity",
				key: "severity",
				width: 300,
				defaultSortOrder: "descend",
				sorter: (a, b) => {
					return a.severity.localeCompare(b.severity);
				},
			},
			status: {
				title: utils.translate("componentWidgetTable.status"),
				dataIndex: "status",
				key: "status",
				width: 300,
				defaultSortOrder: "descend",
				sorter: (a, b) => {
					return a.status.localeCompare(b.status);
				},
				render: (text, record) => (
					<span>
						<Tag color={statusToColor[record.status]}>
							{record.status}
						</Tag>
					</span>
				),
			},
			date_resolution: {
				title: utils.translate("componentWidgetTable.resolution"),
				dataIndex: "date_resolution",
				key: "date_resolution",
				width: 300,
				defaultSortOrder: "descend",
				sorter: (a, b) => {
					return a.date_resolution.localeCompare(b.date_resolution);
				},
			},
		};

		let visibleColumns = [];
		let count = 0;

		const updateColumnOrder = (index, direction) => {
			index = parseInt(index);
			const columnsToDisplay = [...this.state.columnsToDisplay];

			if (direction === "<" && index > 0) {
				[columnsToDisplay[index - 1], columnsToDisplay[index]] = [
					columnsToDisplay[index],
					columnsToDisplay[index - 1],
				];
			}
			if (direction === ">" && index < columnsToDisplay.length - 1) {
				[columnsToDisplay[index], columnsToDisplay[index + 1]] = [
					columnsToDisplay[index + 1],
					columnsToDisplay[index],
				];
			}
			this.api_handleColumnToDisplayChange(columnsToDisplay);
		};

		this.state.columnsToDisplay.forEach((columnToDisplay) => {
			if (Object.keys(allAvailableColumns).includes(columnToDisplay)) {
				if (this.state.updateColumnOrderIsOpen) {
					if (count === 0) {
						visibleColumns.push({
							title: (
								<div>
									<Icon
										type="arrow-right"
										id={`R-${count}`}
										onClick={(e) =>
											updateColumnOrder(
												e.currentTarget.id.slice(2),
												">"
											)
										}
									/>
								</div>
							),
							children: [
								{ ...allAvailableColumns[columnToDisplay] },
							],
							align: "center",
						});
					} else if (
						count ===
						this.state.columnsToDisplay.length - 1
					) {
						visibleColumns.push({
							title: (
								<div>
									<Icon
										type="arrow-left"
										id={`L-${count}`}
										onClick={(e) =>
											updateColumnOrder(
												e.currentTarget.id.slice(2),
												"<"
											)
										}
									/>
								</div>
							),
							children: [
								{ ...allAvailableColumns[columnToDisplay] },
							],
							align: "center",
						});
					} else {
						visibleColumns.push({
							title: (
								<div>
									<Icon
										type="arrow-left"
										id={`L-${count}`}
										onClick={(e) =>
											updateColumnOrder(
												e.currentTarget.id.slice(2),
												"<"
											)
										}
									/>
									<Icon
										type="arrow-right"
										id={`R-${count}`}
										onClick={(e) =>
											updateColumnOrder(
												e.currentTarget.id.slice(2),
												">"
											)
										}
									/>
								</div>
							),
							children: [
								{ ...allAvailableColumns[columnToDisplay] },
							],
							align: "center",
						});
					}
				} else {
					visibleColumns.push({
						...allAvailableColumns[columnToDisplay],
					});
				}
				count++;
			}
		});

		return visibleColumns;
	}


	render()
	{
		const { widget, devices, allDeviceIds, loading, size } = this.props;
		const statusToRowClass = { NEW: 'redRow' };
		const data = [];

		this.state.devicesWithAlarms.forEach((device) =>
		{
			device.active_alarms.forEach((alarm) =>
			{
				if (this.state.visibleAlarms === 'all' ||
				(alarm.status && alarm.status.toLowerCase() === this.state.visibleAlarms))
				{
					data.push({
						key: alarm.id,
						id: device.id,
						device: device.label,
						container: device.container ? device.container.name : '',
						category: device.category.label,
						alarm_type: alarm.type,
						description: alarm.description,
						severity: alarm.level,
						status: alarm.status,
						date_creation: alarm.date_creation,
						date_resolution: alarm.date_resolution,
						alr_label: alarm.alr_label == null ? '' : alarm.alr_label,
					});
				}
			});
		});

        return (

			<>
				{(this.state.devicesWithAlarms.length > 0 || this.props.loading) &&
				<>
					<div className='components-table-demo-control-bar'>
		  				<Form layout='inline'>
							{widget.DWT_HAS_EXPORT === '1'
							&& widget.DVC_IDS && !loading && devices && devices.length > 0 &&
							<Form.Item>
								<DeviceDataExporter
									exportType='alarm'
									devices={devices}
									allDeviceIds={allDeviceIds}
									widget={widget}
									dateStart='1970-01-01 00:00:00'
									dateStop='2100-12-31 00:00:00'
									infoText={utils.translate('componentWidgetTable.download')}
									popOverTitle={utils.translate('componentWidgetTable.select')}
									iconType='alert'
									tooltipTitle={utils.translate('componentWidgetTable.export')}
								/>
							</Form.Item>}
							<Form.Item label='Row Height'>
						  		<Radio.Group size='default' value={this.state.size} onChange={this.api_handleSizeChange}>
									<Radio.Button value='default'>{utils.translate('componentWidgetTable.large')}</Radio.Button>
									<Radio.Button value='middle'>{utils.translate('componentWidgetTable.medium')}</Radio.Button>
									<Radio.Button value='small'>{utils.translate('componentWidgetTable.small')}</Radio.Button>
						  		</Radio.Group>
							</Form.Item>
							<Form.Item label='Alarm Type'>
								<Radio.Group size='default' value={this.state.visibleAlarms} onChange={this.api_handleVisibleAlarmsChange}>
									<Radio.Button value='new'>{utils.translate('componentWidgetTable.new')}</Radio.Button>
									<Radio.Button value='resolved'>{utils.translate('componentWidgetTable.resolved')}</Radio.Button>
									<Radio.Button value='all'>{utils.translate('componentWidgetTable.all')}</Radio.Button>
								</Radio.Group>
							</Form.Item>
							<Form.Item label={utils.translate('componentWidgetTable.columns')}>
								<Select
									mode='multiple'
									placeholder={utils.translate('componentWidgetTable.selectCol')}
									value={this.state.columnsToDisplay}
									onChange={this.api_handleColumnToDisplayChange}
									style={{minWidth: '250px'}}
									maxTagCount={3}
									onMouseDown={e => e.stopPropagation()}
								>
										<Option disabled key='devices' value='devices'>{utils.translate('componentWidgetTable.propDVC')}</Option>
										<Option key='id' value='id'>{utils.translate('componentWidgetTable.id')}</Option>
										<Option key='device' value='device'>{utils.translate('generic.labelDvc')}</Option>
										<Option key='container' value='container'>{utils.translate('componentWidgetTable.container')}</Option>
										<Option key='category' value='category'>{utils.translate('componentWidgetTable.cat')}</Option>
										<Option disabled key='alarms' value='alarms'>{utils.translate('componentWidgetTable.propALA')}</Option>
										<Option key='alarm_type' value='alarm_type'>{utils.translate('componentWidgetTable.type')}</Option>
										<Option key='description' value='description'>{utils.translate('componentWidgetTable.parameter')}</Option>
										<Option key='severity' value='severity'>{utils.translate('componentWidgetTable.severity')}</Option>
										<Option key='status' value='status'>{utils.translate('componentWidgetTable.status')}</Option>
										<Option key='date_creation' value='date_creation'>{utils.translate('componentWidgetTable.creation')}</Option>
										<Option key='date_resolution' value='date_resolution'>{utils.translate('componentWidgetTable.resolution')}</Option>
										<Option key='alr_label' value='alr_label'>{utils.translate('componentWidgetTable.label')}</Option>
								</Select>
							</Form.Item>

								<ColumnOrderButton
									{...this.state}
									onClick={this.toggleUpdateColumnOrderDisplay}
								/>
					
					
	  					</Form>
					</div>

					<Table
						{...this.state}
						columns={this.setColumnsToDisplay()}
						dataSource={data}
						pagination={{ pageSize: parseInt(this.props.widget.DWT_PAGE_SIZE) }}
						rowClassName={(record) => statusToRowClass[record.status] ? statusToRowClass[record.status] : ''}
						bordered={true}
						loading={this.state.loading}
						scroll={{ x: 130 }}
					/>
				</>}

				{this.state.devicesWithAlarms.length === 0 && !this.state.loading &&
				<NoData
					marginTop={size.height / 2 - 75}
					description={
						<>
							<div>{utils.translate('componentWidgetTable.noALA')}</div>
							<div>{utils.translate('componentWidgetTable.refresh')}{this.props.widget.DWG_REFRESH_FREQ} min</div>
						</>
					}
				/>}
			</>
        );
    }
}


export default AlarmTable;
