import React, { useState, useEffect } from 'react';
import { Form } from 'reactstrap';
import { Divider } from 'antd';

import ContainerSection from '../../../FormSections/ContainerSection/ContainerSection';
import AttributeSection from '../../../FormSections/AttributeSection/AttributeSection';

import { oneContainerPH, oneAttributePH } from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';
import { utils } from 'Lib/utils';


const AttributePane = (props) =>
{

	const {
		widget,
		containers,
		configuration,
		saveConfiguration,
		setDatatypePaneIsDisabled,
		marginTop,
	} = props;

	const [selectedContainerId, setSelectedContainerId] = useState(undefined);
	const [selectedValueId, setSelectedValueId] = useState(undefined);
	const [selectedContainerObject, setSelectedContainerObject] = useState(undefined);


	useEffect(() =>
		{
			const valId = widget.VAL_ID;

			if (valId)
			{
				const containerObject = containers && containers.find(container =>
					container.attribs && container.attribs.find(attribute =>
						attribute.value_id === valId));

				if (containerObject)
				{
					setSelectedContainerObject(containerObject);
					setSelectedContainerId(containerObject.id);
					setSelectedValueId(valId);
				}
			}
		},
		[]
	);


	useEffect(() =>
		{
			saveConfiguration({
				...configuration,
				type: 'value',
				jwa_type: 'CTR',
				value_ids: [selectedValueId],
				value_source: selectedValueId && 'ATR' // undefined if selectedValueId is undefined
			});
			setDatatypePaneIsDisabled(selectedValueId !== undefined); // if nothing selected, enable other pane
		},
		[selectedValueId]
	);


	const handleContainerChange = (containerId) =>
	{
		const selectedContainerObject = containers &&
			containers.find(container => container.id === containerId);

		setSelectedContainerId(containerId);
		setSelectedContainerObject(selectedContainerObject);
		setSelectedValueId(undefined);
	}


	const handleAttributeChange = (valueId) =>
	{
		setSelectedValueId(valueId);
	}


	return (

		<Form style={{ marginTop: marginTop }}>

			<ContainerSection
				{...props}
				label={utils.translate('generic.ctr')}
				iconType='container'
				containerSelectPlaceholder={oneContainerPH}
				selectedContainerId={selectedContainerId}
				handleContainerChange={handleContainerChange}
			/>

			<Divider/>

			<AttributeSection
				{...props}
				label={utils.translate('generic.attr')}
				iconType='unordered-list'
				attributeSelectMode='single'
				attributeSelectPlaceholder={oneAttributePH}
				selectedValueId={selectedValueId}
				selectedSourceObject={selectedContainerObject}
				handleAttributeChange={handleAttributeChange}
			/>

		</Form>
	)
};


export default AttributePane;
