import React from 'react';
import { Icon } from 'antd';
import { utils } from 'Lib/utils';

import { MAX_MARKER_DATA_ELEMENTS } from 'Config/config';

const infoIcon = 'info-circle';

export const categoryDashboardInfo = <div><span><Icon className='formPHIcon' type={infoIcon}/></span><span><i>{utils.translate('genericText.categoryDashboardInfo')}</i></span></div>;

export const categoryDashboardSitemapInfo = <div><span><Icon className='formPHIcon' type={infoIcon}/></span><span><i>{utils.translate('genericText.categoryDashboardSitemapInfo')}</i></span></div>;

export const chartWidgetConfigInfo = <small>{utils.translate('genericText.chartWidgetConfigInfo')}</small>;

export const chartWidgetColorsInfo = <small><br/>{utils.translate('genericText.chartWidgetColorsInfo')}</small>

export const chartWidgetLabelInfo =  <small><span><br/>{utils.translate('genericText.chartWidgetLabelInfo')}</span></small>;

export const pieWidgetColorsInfo = <small><br/>{utils.translate('genericText.pieWidgetColorsInfo')}</small>

export const geolocCsvInfo = <small>{utils.translate('genericText.geolocCsvInfo')}</small>
export const deviceProfileInfo = <small>{utils.translate('genericText.deviceProfileInfo')}</small>

export const iconCustomizationInfo = (
	<p>
		<i>{utils.translate('genericText.iconCustomizationInfo.1')}
		<br/><br/>{utils.translate('genericText.iconCustomizationInfo.2')}<b>{utils.translate('genericText.iconCustomizationInfo.3')}</b>{utils.translate('genericText.iconCustomizationInfo.4')}
		<br/>{utils.translate('genericText.iconCustomizationInfo.5')}<b>{utils.translate('genericText.iconCustomizationInfo.6')}</b>{utils.translate('genericText.iconCustomizationInfo.7')}
		<br/><br/>{utils.translate('genericText.iconCustomizationInfo.8')}</i>
	</p>
);

export const dataSourceLabelPopoverContent = (
	<span>
		{utils.translate('genericText.dataSourceLabelPopoverContent.1')}{MAX_MARKER_DATA_ELEMENTS}{utils.translate('genericText.dataSourceLabelPopoverContent.2')}
	</span>
);

export const bindLabelPopoverContent = (
	<span>
		{utils.translate('genericText.bindLabelPopoverContent.1')}<br/><br/>
		{utils.translate('genericText.bindLabelPopoverContent.2')}<br/><br/>
		{utils.translate('genericText.bindLabelPopoverContent.3')}
	</span>
);

export const geoLabelPopoverContent = (
	<span>
		{utils.translate('genericText.geoLabelPopoverContent.1')}<br/><br/>
		{utils.translate('genericText.geoLabelPopoverContent.2')}<br/><br/>
		{utils.translate('genericText.geoLabelPopoverContent.3')}<br/><br/>
		{utils.translate('genericText.geoLabelPopoverContent.4')}
	</span>
);

export const thresholdLabelPopoverContent = (
	<span>
		{utils.translate('genericText.thresholdLabelPopoverContent')}
	</span>
);

export const operatorLabelPopoverContent = (
	<span>
		{utils.translate('genericText.operatorLabelPopoverContent')}
	</span>
);

export const colorsLabelPopoverContent = (
	<span>
		{utils.translate('genericText.colorsLabelPopoverContent.inactive')}<br/>
		{utils.translate('genericText.colorsLabelPopoverContent.active')}<br/>
		{utils.translate('genericText.colorsLabelPopoverContent.default')}
	</span>
);

export const legendLabelPopoverContent = (
	<span>
		Optional Legend labels, up to 20 characters per label.<br/>
		If none of the labels are provided, Legend will not be displayed.
	</span>
);
