import React, { Component } from 'react';
import { Form, Col, Row } from 'reactstrap';
import { Divider, Select, Tabs, Icon, Tooltip } from 'antd';

import DeviceSection from '../FormSections/DeviceSection/DeviceSection';
import DualColorsSection from '../FormSections/ColorSections/DualColorsSection/DualColorsSection';
import SuperSelect from 'Components/Studio/StudioLib/SuperSelect/SuperSelect';
import LabelItem from 'Components/Studio/Dashboard/Widgets/Configure/FormItems/LabelItem/LabelItem';
import CameraSection from'Components/Studio/Dashboard/Widgets/Configure/FormSections/CameraSection/CameraSection'
import WidgetStyle from 'Components/Studio/Dashboard/Widgets/Style/WidgetStyle.js';
import CommandCustomizationConfigure from './Command/CommandCustomizationConfigure';
import SwitchSection from '../FormSections/SwitchSection/SwitchSection';

import { request } from 'Lib/request.js';
import { oneDevicePH } from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';
import { categoryDashboardInfo } from 'Components/Studio/StudioLib/GenericHTML/GenericText.js';
import { utils } from 'Lib/utils';

const { Option } = Select;

const { TabPane } = Tabs;

class SingleDevice extends Component {

	constructor(props) {
		super(props);

		this.state = {
			devices: [],
			deviceCommands: [],
			selectedDevices: [],
			selectedCommands: '',
			selectedCommand: '',
			loading: false,
			loadingCommands: false,
			colors: ['#28a745', '#ff5369'],
			displayAllColors: true,
			switchChecked: '1',
			power: ['ON', 'OFF'],
			quality: ['HIGH', 'LOW'],
		}

		this.timerId = null;
		this.componentIsMounted = false;
	}


	componentDidMount = () => {
		this.componentIsMounted = true;

		if (!this.props.widget.DVC_ID) { return; }

		this.getDeviceCommands(this.props.widget.DVC_ID);
		this.setState({ selectedDevices: this.props.widget.DVC_ID, selectedCommands: this.props.widget.CMD_IDS, switchChecked: this.props.widget.DWO_IS_ADVANCED });

		if (!!this.props.widget.DWZ_COLORS) {
			this.setState({ colors: this.props.widget.DWZ_COLORS.split(',') });
		}

		if (!!this.props.widget.LABEL_POWER)
		{
			this.setState({ power: this.props.widget.LABEL_POWER.split(',') });
		}

		if (!!this.props.widget.LABEL_QUALITY)
		{
			this.setState({ quality: this.props.widget.LABEL_QUALITY.split(',') });
		}

		let currentConfiguration = this.getCurrentConfiguration();
		this.props.saveConfiguration(currentConfiguration);
	}


	getDeviceCommands = (id) => {
		this.setState({
			loadingCommands: true,
		}, () => {
			request.get(
				'device/' + id,
				{
					'dependencies': 'available_commands'
				},
				(device) => {
					if (this.componentIsMounted) {
						this.setState({
							device: device[0],
							deviceCommands: device[0].available_commands ? device[0].available_commands : [],
						});
					}
				},
				(error) => { console.error(error); },
				() => this.setState({ loadingCommands: false })
			);
		});
	}


	handleDeviceChange = (value) => {
		this.setState({ selectedDevices: value });
		this.getDeviceCommands(value);
		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.device_id = value;

		this.setState({
			selectedCommands: '',
		});
		currentConfiguration.command_ids = [];
		if(this.props.widget.DWG_TYPE === 'DWG_camera'){
			currentConfiguration.power = this.state.power.join();
			currentConfiguration.quality = this.state.quality.join();
		}

		this.props.saveConfiguration(currentConfiguration);
	}


	handleCommandChange = (value) => {

		let allCommandIdsStr = '';
		value.forEach(allCommandsId => allCommandIdsStr += allCommandsId + ',');
		allCommandIdsStr = allCommandIdsStr.slice(0, -1);
		this.setState({
			selectedCommands: allCommandIdsStr,
		});

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.command_ids = allCommandIdsStr;
		this.props.saveConfiguration(currentConfiguration);
	}


	getCurrentCommandIds = () => {
		if (this.props.configuration.command_ids === undefined) {
			return this.props.widget.CMD_IDS ? this.props.widget.CMD_IDS : ''
		}
		else if (this.props.configuration.command_ids === '') { return ''; }
		else { return this.props.configuration.command_ids; }
	}


	refreshCurrentlySelectedCommand = () => {
		this.state.deviceCommands.forEach(deviceCommand => {
			if (deviceCommand.code === this.state.selectedCommand.code && deviceCommand.lastDeviceReturnDate !== this.state.selectedCommand.lastDeviceReturnDate) { // broker has received a reply and updated database reply timestamp
				this.setState({ selectedCommand: deviceCommand });
			}
		});
	}


	getCurrentConfiguration = () => {
		return {
			type: this.props.type,
			device_id: this.getCurrentDeviceId(),
			command_ids: this.getCurrentCommandIds(),
			colors: this.getCurrentColors(),
			is_advanced: this.state.switchChecked
		}
	}


	getCurrentDeviceId = () => {
		return this.props.configuration.device_id ? // if something is set in configuration, user has changed input values
			this.props.configuration.device_id : // configuration is the source of truth
			(this.props.widget.DVC_ID && !this.state.containerEdited ? // if not check if current widget has device ids, meaning we are editing an existing widget
				this.props.widget.DVC_ID :
				undefined
			);
	}


	getCurrentColors = () => {
		return this.props.configuration.colors ?
			this.props.configuration.colors :
			(this.props.widget.DWZ_COLORS ?
				this.props.widget.DWZ_COLORS :
				'#28a745,#ff5369'
			);
	}


	handleColorChange = (color, index) => {
		let colors = [...this.state.colors];
		let colorToUpdate = { ...colors[index] };
		colorToUpdate = color.color;
		colors[index] = colorToUpdate;

		this.setColorstoStateandConfig(colors);
	}


	setColorstoStateandConfig = (colors) => {
		this.setState({ colors: colors });

		let currentConfiguration = this.getCurrentConfiguration();
		let colorsStr = '';

		colors.forEach((color) => {
			colorsStr += color + ',';
		});

		colorsStr = colorsStr.slice(0, -1);
		currentConfiguration.colors = colorsStr;

		this.props.saveConfiguration(currentConfiguration);
	}


	handleSwitch = (checked) =>
	{
		let currentConfiguration = this.getCurrentConfiguration();
		this.setState({ switchChecked: checked ? '1' : '0' });
		currentConfiguration.is_advanced = checked ? '1' : '0';

		this.props.saveConfiguration(currentConfiguration);
	}


	handlePowerZeroChange = (power) =>
	{
		let powers = [...this.state.power];
		let powerToUpdate = { ...powers[0] };
		powerToUpdate = power.target.value;
		powers[0] = powerToUpdate;

		this.setCameraConfiguration(powers, this.state.quality);
	}


	handlePowerOneChange = (power) =>
	{
		let powers = [...this.state.power];
		let powerToUpdate = { ...powers[1] };
		powerToUpdate = power.target.value;
		powers[1] = powerToUpdate;
		
		this.setCameraConfiguration(powers, this.state.quality);
	}


	handleQualityZeroChange = (quality) =>
	{
		let qualities = [...this.state.quality];
		let qualityToUpdate = { ...qualities[0] };
		qualityToUpdate = quality.target.value;
		qualities[0] = qualityToUpdate;


		this.setCameraConfiguration(this.state.power, qualities);
	}


	handleQualityOneChange = (quality) =>
	{
		let qualities = [...this.state.quality];
		let qualityToUpdate = { ...qualities[1] };
		qualityToUpdate = quality.target.value;
		qualities[1] = qualityToUpdate;


		this.setCameraConfiguration(this.state.power, qualities);
	}


	setCameraConfiguration = (power, quality) =>
	{
		this.setState({ power: power, quality: quality});

		let currentConfiguration = this.getCurrentConfiguration();
		let powerStr = power.join();
		let qualityStr = quality.join();

		currentConfiguration.power = powerStr;
		currentConfiguration.quality = qualityStr;

		this.props.saveConfiguration(currentConfiguration);
	}


	render() {
		const entries = this.props.devices.length;
		const isStatus = this.props.widget.DWG_TYPE === 'status' || this.props.widget.DWG_TYPE === 'DWG_status';
		const isCommand = this.props.widget.DWG_TYPE === 'command' || this.props.widget.DWG_TYPE === 'DWG_command';
		const isCamera = this.props.widget.DWG_TYPE === 'camera' || this.props.widget.DWG_TYPE === 'DWG_camera';
		const { selectedCommands } = this.state;
		const currentCommandIds = selectedCommands ? (selectedCommands.length > 0 ? selectedCommands.split(',') : undefined) : undefined;
	
		const commandOptions = this.state.deviceCommands.map((command) =>
			<Option key={command.cmdId} value={command.cmdId}>{command.label}</Option>
		);

		return (

			<Tabs tabPosition="top" tabBarStyle={{ marginBottom: 25 }}>
				<TabPane
					forceRender
					tab={
						<span>
							<Icon type="wifi" className="greyIcon" />
							{utils.translate('componentWidgetConfigure.data')}
						</span>
					}
					key="1"
				>
					<Form>

						<Divider />

						{!this.props.isCategoryDashboard &&
							<DeviceSection
								label={utils.translate('generic.dvc')}
								entries={entries}
								deviceSelectPlaceholder={oneDevicePH}
								devices={this.props.devices}
								categories={this.props.categories}
								containers={this.props.containers}
								groups={this.props.groups}
								selectedDevices={this.state.selectedDevices}
								categoryFilter={this.props.categoryFilter}
								containerFilter={this.props.containerFilter}
								groupFilter={this.props.groupFilter}
								loading={this.props.loading}
								disabled={this.props.loading}
								handleDeviceChange={this.handleDeviceChange}
								handleCategoryFilterChange={this.props.handleCategoryFilterChange}
								handleContainerFilterChange={this.props.handleContainerFilterChange}
								handleGroupFilterChange={this.props.handleGroupFilterChange}
							/>}
						{isCommand &&
							<>
								<Divider />
								<Row>
									<LabelItem
										iconType='plus'
										label={utils.translate('componentWidgetCommand.command')}
									/>
									<Col md={{ size: 9 }}>
										<SuperSelect
											value={currentCommandIds}
											allowClear={true}
											placeholder={utils.translate('componentWidgetCommand.selectCommand')}
											onChange={this.handleCommandChange}
											showSearch
											mode='multiple'
											optionFilterProp='children'
											style={{ width: '100%' }}
											maxTagCount={20}
											showArrow={true}
											filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										>
											{commandOptions}
										</SuperSelect>
									</Col>
								</Row>
								<Divider/>
									<SwitchSection
										checked={this.state.switchChecked === '1' ? true : false}
										handleSwitch={this.handleSwitch}
										label={utils.translate('componentWidgetCommand.advanced')}
										iconType='setting'
										otherChecked={false}
									/>
								<Divider />
							</>}

						{this.props.isCategoryDashboard &&
							<div>{categoryDashboardInfo}</div>}

						{isStatus &&
							<DualColorsSection
								label={utils.translate('generic.colors')}
								active={utils.translate('generic.active')}
								inactive={utils.translate('generic.inactive')}
								colorOne={this.state.colors[1]}
								colorZero={this.state.colors[0]}
								handleColorChange={this.handleColorChange}
								widget={this.props.widget}
								displayAllColors={this.state.displayAllColors}
								enableAlpha={false}
							/>}
						{isCommand && !this.state.loadingCommands &&
							<>
								<CommandCustomizationConfigure
									{...this.props}
									selectedCommands={selectedCommands}
									allCommands={this.state.deviceCommands}
									getCurrentConfiguration={this.getCurrentConfiguration}
								/>
							</>}

						{isCamera &&
							<CameraSection
								widget={this.props.widget}
								powerOne={this.state.power[1]}
								powerZero={this.state.power[0]}
								qualityOne={this.state.quality[1]}
								qualityZero={this.state.quality[0]}
								quality={this.state.quality}
								handlePowerZeroChange={this.handlePowerZeroChange}
								handlePowerOneChange={this.handlePowerOneChange}
								handleQualityZeroChange={this.handleQualityZeroChange}
								handleQualityOneChange={this.handleQualityOneChange}
							/>}
					</Form>
				</TabPane>
				<TabPane
					tab={
						<Tooltip title="Style">
							<span>
								<Icon type="bg-colors" className="greyIcon" />
								{utils.translate(
									'componentWidgetConfigure.style'
								)}
							</span>
						</Tooltip>
					}
					key="2"
				>
					<WidgetStyle
						{...this.props}
						marginTop={20}
						configuration={this.getCurrentConfiguration()}
					/>
				</TabPane>
			</Tabs>

			
		);
	}
}


export default SingleDevice;