import React from 'react';

import DeviceImage from './DeviceImage/DeviceImage';
import DeviceInfo from './DeviceInfo/DeviceInfo';


const DeviceCard = ({ device, isAlternative, lat, lng, alt }) =>
{
	return (

		<>
			<DeviceImage
				device={device}
			/>
			<DeviceInfo
				device={device}
				isAlternative={isAlternative}
				lat={lat}
				lng={lng}
				alt={alt}
			/>
		</>
	);
};


export default DeviceCard;
