import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Switch } from 'antd';

import LabelItem from '../../FormItems/LabelItem/LabelItem';
import { utils } from 'Lib/utils';


const StyleSwitchSection = (props) =>
{

	const {
		label,
		iconType,
		defaultChecked,
		disabled,
		unCheckedChildren,
		checkedChildren,
		handleSwitch,
	} = props;


	return (

		<FormGroup>
			<Row className='align-items-center'>
				<LabelItem
					iconType={iconType}
					label={label || utils.translate('componentStyleSwitchSection.style')}
				/>
				<Col md={{ size: 9 }}>
					<span style={{ marginRight: '10px' }}>
						{unCheckedChildren}
					</span>
					<Switch
						defaultChecked={defaultChecked}
						onChange={handleSwitch}
						disabled={disabled}
					/>
					<span style={{ marginLeft: '10px' }}>
						{checkedChildren}
					</span>
				</Col>
			</Row>
		</FormGroup>
	)
};


export default StyleSwitchSection;
