export const LogicalOperatorModel = {
	NONE: {
		value: 'NONE',
		function: (a, b) => { return true },
	},
	LOWER_THAN: {
		value: 'LOWER_THAN',
		function: (a, b) => { return a < b }
	},
	GREATER_THAN: {
		value: 'GREATER_THAN',
		function: (a, b) => { return a > b }
	},
	EQUAL_TO: {
		value: 'EQUAL_TO',
		function: (a, b) => { return a === b }
	},
};

// import LogicalOperatorModel from 'Models/LogicalOperatorModel';
