import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd';

import StatusTooltip from './StatusTooltip/StatusTooltip.js';
import EmptyWidget from '../../WidgetLib/EmptyWidget/EmptyWidget';

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils.js';

import './Status.css';


class Status extends Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			device: undefined,
			loading: false,
		};

		this.timerId = null;
		this.componentIsMounted = false;
	}


	componentDidMount()
	{
		this.componentIsMounted = true;

		if (!this.props.widget.DVC_ID) { return; }

		this.timerId = utils.setRefresh(this.props.widget.DWG_REFRESH_FREQ, this.api_getDevice);
		this.api_getDevice();
	}


	componentDidUpdate(prevProps)
	{
		if (prevProps.widget.DVC_ID !== this.props.widget.DVC_ID
			|| prevProps.widget.CFG_ID !== this.props.widget.CFG_ID)
		{
			this.api_getDevice();
		}
	}


	componentWillUnmount()
	{
		this.componentIsMounted = false;
		if (this.timerId !== null) { utils.clearRefresh(this.timerId); }
	}


	api_getDevice = () =>
	{
		this.setState({ loading: true }, () =>
		{
			request.get(
				'device/' + this.props.widget.DVC_ID,
				{},
				(device) =>
				{
					if (this.componentIsMounted) { this.setState({ device: device[0] }); }
				},
				(e) => console.error(e),
				() => this.setState({ loading: false })
			);
		});
	}


	getTooltip()
	{
		const allDatatypes = this.state.device !== undefined ? this.state.device.configs : '';
		let dateData = [];
		let lastData = [];

		if (allDatatypes !== undefined)
		{
			allDatatypes.forEach((data) =>
			{
				if (data.date !== '') { dateData.push(data); }
			});
		}

		lastData = dateData.sort((a, b) => (a.date < b.date) ? 1 : -1)[0]; // get last date from array
		let tooltip = <StatusTooltip device={this.state.device} lastData={lastData} />

		return tooltip;
	}


	render()
	{
		const showEmptyWidget = !this.state.loading && utils.isConfigMode() && !this.state.device && !this.props.isCategoryDashboard;
		const colors = this.props.widget.DWZ_COLORS !== undefined ? this.props.widget.DWZ_COLORS.split(',') : ['#28a745', '#ff5369'];
		const colorIcon = this.state.device !== undefined ? this.state.device.status === 'CONNECT' || this.state.device.status === 'STARTED' ?
			colors[0] : this.state.device.status === 'UNAVAILABLE' ? '#f5f5f5' : colors[1] : '#f5f5f5';

		return (
			<>
				{showEmptyWidget &&
				<EmptyWidget
					size={this.props.widgetSize}
					{...this.props}
					callToAction={utils.translate('componentWidgetStatus.start')}
				/>}

				{!showEmptyWidget &&
				<div 
					id={this.props.getWidgetId + '_CONTENT'}
					className={'CONTENT' + this.props.getWidgetClass + this.props.getWidgetClass + '_CONTENT'}
					style={{ height: `${this.props.size.height}px`, fontSize: this.props.getWidgetFontSize() }}>
					{this.state.device &&
					<div className='status-content'>
						<Tooltip className='' placement='top' title={this.getTooltip()}>
							<FontAwesomeIcon
								icon='power-off'
								className='status-icon'
								style={{ fontSize: `min(100vh, ${Math.min(this.props.size.height, this.props.size.width) * 0.8}px)`, color: colorIcon }}
							/>
						</Tooltip>
						<div id='status-text'>
							<div>{this.state.device.label}</div>
						</div>
					</div>}
				</div>}
			</>
		)
	}
}


export default Status;
