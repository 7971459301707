import React, { useState, useEffect } from 'react';
import { withSize } from 'react-sizeme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardTitle, Col, Row } from 'reactstrap';
import { Tooltip, Icon, Popconfirm } from 'antd';

import BlockAdd from 'Components/Studio/Dashboard/Widgets/WidgetLib/BlockHeader/BlockAdd';
import NameRefresh from 'Components/Studio/Dashboard/Widgets/WidgetLib/WidgetHeader/WidgetHeaderConfig/NameRefresh';
import ColorPicker from 'rc-color-picker';

import { utils } from 'Lib/utils';

import './BlockHeader.css';

const BlockHeader = (props) => {

	const {
		deleteBlock,
		block,
		configuration,
		handleBlockChange,
	} = props;

	const isConfigMode = utils.isConfigMode();
	const color = block.length === 1 ? '' : block[0].DBK_COLOR ? block[0].DBK_COLOR : '';
	const [colorBackground, setColorBackground] = useState(color);

	
	useEffect(() => {
		if (colorBackground) { 
			setColorBackground(colorBackground); 
		}
	},
		[]
	);
	

	useEffect(() => {
		if (block.length === 1){
			setColorBackground(color);
		}
	},
		[block.length]
	);


	useEffect(() => {
		if (isConfigMode) {
			handleBlockChange(
				block[0].DBK_ID,
				{
					...configuration,
					block_color: colorBackground,
				});
		}
	},
		[colorBackground]
	);


	const handleColorChange = (color) => {
		setColorBackground(utils.convertColorAlphaToHex(color.color, color.alpha));
	}

	const colorAlpha = utils.convertHexToColorAlpha(color);

	return (
		<>
		{block.length > 1 &&
		<div
			style={{ zIndex: 9999, paddingTop: '10px', paddingLeft: '10px', marginBottom: '-15px' }}
			id={block[0].DBK_ID + '_HEADER'}
		>
			<Row>
				<Col md={{ size: isConfigMode ? 6 : 12 }}>
					{!isConfigMode && block.length > 1 &&
						<Tooltip
							title={block[0].DBK_LABEL}
							placement='topLeft'
						>
							<CardTitle className='widget-title'>
								{block[0].DBK_LABEL}
							</CardTitle>
						</Tooltip>}
					{isConfigMode &&
						<NameRefresh
							getWidgets={props.getWidgets}
							isBlock={true}
							block={block[0]}

						/>}
				</Col>
				{isConfigMode &&
					<Col md={{ size: 6 }}>
						<div className='widget-block' style={{ fontSize: '15px' }}>
							<Tooltip
								placement='topLeft'
								title={utils.translate('componentWidgetStyle.back')}
							>
								<div style={{display:'inline-block'}}>
									<ColorPicker
										color={colorAlpha.color}
										alpha={colorAlpha.alpha}
										onClose={(color) => handleColorChange(color)}
									/>
								</div>
							</Tooltip>
							
							<BlockAdd
								addWidget={props.addWidget}
								block={block}
							/>
								<Tooltip
									placement='rightBottom'
									title={utils.translate('componentWidgetHeader.delete')}
								>
									<Popconfirm
										placement='leftTop'
										icon={<Icon type='question-circle-o' style={{ color: 'red' }} />}
										title={utils.translate('componentWidgetHeader.delete2')}
										onConfirm={() => deleteBlock(block[0].DBK_ID)}
										okText={utils.translate('generic.yes')}
										cancelText={utils.translate('generic.no')}
									>
										<FontAwesomeIcon
											className='widget-icon'
											icon='trash-alt'
										/>
									</Popconfirm>
								</Tooltip>
						</div>
					</Col>}
			</Row>
		</div>}
		</>
	);
}


export default withSize({ monitorHeight: true })(BlockHeader);
