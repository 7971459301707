import React, { useState, useEffect } from 'react';

import ImageUploadSection from '../../FormSections/ImageUploadSection/ImageUploadSection';
import { utils } from 'Lib/utils';


const ImageConfigure = (props) =>
{

	const {
		widget,
		configuration,
		saveConfiguration,
	} = props;

	const [image, setImage] = useState('');
	const [buttonsVisibility, setButtonsVisibility] = useState('visible');

	const buttonStyles = {
		color: 'white',
		background: '#40a9ff',
		width: 150,
		visibility: buttonsVisibility,
	};

	const imageExists = widget.DWV_IMAGE;

	const buttonText = imageExists && image ? utils.translate('componentImageConfigure.change')  : utils.translate('componentImageConfigure.choose');

	const showRemoveButton = imageExists && image && buttonsVisibility === 'visible';


	useEffect(() =>
		{
			if (widget)
				setImage(widget.DWV_IMAGE);
		},
		[]
	);


	useEffect(() =>
		{
			if (configuration)
			{
				saveConfiguration({
					...configuration,
					image: image,
				});
			}
		},
		[image]
	);


	const getBase64 = async (rawImage) =>
	{
    	return new Promise((resolve, reject) =>
		{
      		var fileReader = new FileReader();

      		fileReader.onerror = () => { reject('Error'); }

      		fileReader.onload = () =>
			{
        		if (fileReader.result !== undefined) { resolve(setImage(fileReader.result)); }
				else { reject('Error'); }
      		}

			try { fileReader.readAsDataURL(rawImage); }
			catch (e) { }
    	});
  	}


	const handleImageChange = async (rawImage) =>
	{
		if (rawImage && !rawImage.length)
		{
			setImage('');
			setButtonsVisibility('visible');
		}

		else
			setButtonsVisibility('hidden');

        await getBase64(rawImage[0]);
    }


	const removeImage = () => setImage('');

	return (

		<ImageUploadSection
			showRemoveButton={showRemoveButton}
			handleImageChange={handleImageChange}
			removeImage={removeImage}
			buttonText={buttonText}
			buttonStyles={buttonStyles}
		/>
	)
};


export default ImageConfigure;
