import React from 'react';
import { NavItem, NavLink, Nav } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd';

import history from 'Lib/history';
import { auth } from 'Lib/auth';
import { utils } from 'Lib/utils';

import './HeaderButtons.css';

export const HeaderButtons = (props) => (

	<Nav className='ml-auto scrollNav' navbar>
		{!props.isMobileVersion && !props.isConfigMode && props.overflow === true &&
			<Tooltip
				placement='left'
				title={utils.translate('componentHeaderButtons.previous')}>
				<NavLink
					className='prev'
					disabled={props.arrowDisabled === 'left' ? true : false}
					style={props.arrowDisabled === 'left' ? { opacity: 0.3 } : { color: 'white' }}
					onClick={() => props.scroll(-1)}
				>
					&#10094;
				</NavLink>
			</Tooltip>}

		{!props.isMobileVersion && !props.isConfigMode && props.overflow === true &&
			<Tooltip placement='left' title={utils.translate('componentHeaderButtons.next')}>
				<NavLink
					className='next'
					disabled={props.arrowDisabled === 'right' ? true : false}
					style={props.arrowDisabled === 'right' ? { opacity: 0.3 } : { color: 'white' }}
					onClick={() => props.scroll(1)}
				>
					&#10095;
			</NavLink>
			</Tooltip>}

		{props.isConfigMode &&
			<Tooltip placement='left' title={utils.translate('componentHeaderButtons.visualization')}>
				<NavLink className='appConfig' onClick={props.switchMode}>
					<FontAwesomeIcon icon='eye' />
				</NavLink>
			</Tooltip>}

		{!props.isConfigMode && props.currentPage.ARC_RIGHT === 'rw' &&
			<Tooltip placement='left' title={utils.translate('componentHeaderButtons.configure')}>
				<NavLink className='appConfig' onClick={props.switchMode}>
					<FontAwesomeIcon icon='sliders-h' />
				</NavLink>
			</Tooltip>}

		{props.isConfigMode && props.appTheme !== undefined &&
			<Tooltip placement='bottom' title={props.isMobileVersion ? '' : utils.translate('componentHeaderButtons.appSetting')}>
				<NavLink className='appConfig' onClick={props.showDrawer}>
					<FontAwesomeIcon icon='cogs' />
				</NavLink>
			</Tooltip>}

		<Tooltip placement='bottomRight' title={utils.translate('componentHeaderButtons.signout')}>
			<NavItem>
				<NavLink className="logout-link header-link" onClick={() =>
				{
					auth.signout(() =>
					{

						if (typeof props.dapId === 'undefined') history.push('/notfound');

						else
						{
							history.push(utils.getBaseUrl() + 'login');
							sessionStorage.removeItem('token');
						}
					});
				}}>
					<FontAwesomeIcon id="signOut" icon="sign-out-alt" />
				</NavLink>
			</NavItem>
		</Tooltip>

	</Nav>

);
