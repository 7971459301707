import React, { Component } from 'react';
import { Divider, Tabs, Card, Icon, Tooltip } from 'antd';

import TypeSection from '../FormSections/TypeSection/TypeSection';
import PageSizeSection from '../FormSections/PageSizeSection/PageSizeSection';
import SwitchSection from '../FormSections/SwitchSection/SwitchSection';
import DeviceSelection from '../DeviceSelection/DeviceSelection';
import WidgetStyle from 'Components/Studio/Dashboard/Widgets/Style/WidgetStyle.js';

import { categoryDashboardInfo } from 'Components/Studio/StudioLib/GenericHTML/GenericText.js';
import { utils } from 'Lib/utils';
import { tableTypePH } from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';

const { TabPane } = Tabs;

const options = [
	{
		key: 'Status',
		value: 'status',
		label: utils.translate('componentWidgetTableConfigure.tableDVC'),
		title: utils.translate('componentWidgetTableConfigure.manage'),
	},
	{
		key: 'Alarm',
		value: 'alarm',
		label: utils.translate('componentWidgetTableConfigure.tableALA'),
		title: utils.translate('componentWidgetTableConfigure.view'),
	},
];

const all = {
	id: 'all',
	label:
		utils.getCurrentLanguage() === 'fr'
			? 'Tout Selectionner'
			: 'Select All',
};

class Table extends Component {
	constructor(props) {
		super(props);

		this.state = {
			devices: [],
			selectedDevices: [],
			selectedCategoryIds: [],
			selectedContainerIds: [],
			selectedGroupIds: [],
			categoryFilter: [],
			containerFilter: [],
			csvChecked: true,
			addDeviceChecked: true,
			activeKey: 'data_source_1',
		};

		this.allDevices = React.createRef();
	}

	componentDidMount() {
		const { widget } = this.props;

		if (this.props.devices !== undefined && this.props.devices.length > 0)
			this.setDevicesToState();

		this.setState({
			selectedDevices: widget.DVC_IDS,
			selectedCategoryIds: widget.CAT_IDS,
			selectedContainerIds: widget.CTR_IDS,
			selectedGroupIds: widget.GRP_IDS,
			csvChecked: !widget.DWT_HAS_EXPORT || widget.DWT_HAS_EXPORT === '1',
			addDeviceChecked:
				!widget.DWT_HAS_ADD_DEVICE || widget.DWT_HAS_ADD_DEVICE === '1',
			activeKey:
				widget && widget.CAT_IDS && widget.CAT_IDS.length > 0
					? 'data_source_2'
					: widget && widget.CTR_IDS && widget.CTR_IDS.length > 0
					? 'data_source_3'
					: widget && widget.GRP_IDS && widget.GRP_IDS.length > 0
					? 'data_source_4'
					: 'data_source_1',
		});

		let currentConfiguration = this.getCurrentConfiguration();
		this.props.saveConfiguration(currentConfiguration);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.devices !== undefined &&
			prevProps.devices !== this.props.devices
		)
			this.setDevicesToState();
	}

	setDevicesToState = () => {
		let devices = [...this.props.devices];
		if (devices !== undefined && devices.length > 0) {
			devices.unshift(all);
		}

		this.setState({ devices: devices });
	};

	handleDeviceChange = (value) => {
		if (value.includes('all')) {
			value = [];
			const allDevices = this.allDevices.current;
			var options = allDevices.props.children;

			for (var i = 0, l = options.length; i < l; i++) {
				if (options[i].key !== 'all') value.push(options[i].key);
			}
		}

		let deviceIdsStr = '';

		value.forEach((deviceId) => (deviceIdsStr += deviceId + ','));
		deviceIdsStr = deviceIdsStr.slice(0, -1);

		this.setState({ selectedDevices: deviceIdsStr });

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.device_ids = deviceIdsStr;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleCategoryChange = (value = []) => {
		this.setState({ selectedCategoryIds: value });

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.category_ids = value;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleContainerChange = (value = []) => {
		this.setState({ selectedContainerIds: value });

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.container_ids = value;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleGroupChange = (value = []) => {
		this.setState({ selectedGroupIds: value });

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.group_ids = value;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleActiveKeyChange = (key) => {
		this.setState({ activeKey: key });
	};

	handleTypeChange = (value) => {
		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.table_type = value;
		currentConfiguration.columns = '';

		this.props.saveConfiguration(currentConfiguration);
	};

	handlePageSizeChange = (value) => {
		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.page_size = value;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleCsvSwitch = (checked) => {
		let currentConfiguration = this.getCurrentConfiguration();
		this.setState({ csvChecked: checked });
		currentConfiguration.has_export = checked ? 1 : 0;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleAddDeviceSwitch = (checked) => {
		let currentConfiguration = this.getCurrentConfiguration();
		this.setState({ addDeviceChecked: checked });
		currentConfiguration.has_add_device = checked ? 1 : 0;

		this.props.saveConfiguration(currentConfiguration);
	};

	getCurrentConfiguration = () => {
		return {
			type: 'table',
			table_type: this.getCurrentType(),
			page_size: this.getCurrentPageSize(),
			device_ids: this.getCurrentDeviceIds(),
			category_ids: this.state.selectedCategoryIds,
			container_ids: this.state.selectedContainerIds,
			group_ids: this.state.selectedGroupIds,
			columns: this.getCurrentVisibleColumns(),
			has_export: this.state.csvChecked ? '1' : '0',
			has_add_device: this.state.addDeviceChecked ? '1' : '0',
		};
	};

	getCurrentDeviceIds = () => {
		if (this.props.configuration.device_ids === undefined) {
			return this.props.widget.DVC_IDS
				? this.props.widget.DVC_IDS
				: undefined;
		} else if (this.props.configuration.device_ids === '') {
			return undefined;
		} else {
			return this.props.configuration.device_ids;
		}
	};

	getCurrentType = () =>
		this.props.configuration.table_type
			? this.props.configuration.table_type
			: this.props.widget.DWT_TYPE
			? this.props.widget.DWT_TYPE
			: 'status';

	getCurrentPageSize = () =>
		this.props.configuration.page_size
			? this.props.configuration.page_size
			: this.props.widget.DWT_PAGE_SIZE
			? this.props.widget.DWT_PAGE_SIZE
			: '5';

	getCurrentVisibleColumns = () =>
		this.props.configuration.columns
			? this.props.configuration.columns
			: this.props.widget.DWT_COLUMNS
			? this.props.widget.DWT_COLUMNS
			: undefined;

	render() {
		const { loading } = this.props;
		const {
			selectedDevices = [],
			selectedCategoryIds = [],
			selectedContainerIds = [],
			selectedGroupIds = [],
		} = this.state;
		const currentDeviceIds =
			selectedDevices.length > 0
				? this.state.selectedDevices.split(',')
				: undefined;
		const entries = this.state.devices.length - 1;
		const isStatus = this.getCurrentType() === 'status';

		const widgetHasDvcIds = selectedDevices.length > 0;
		const widgetHasCatIds = selectedCategoryIds.length > 0;
		const widgetHasCtrIds = selectedContainerIds.length > 0;
		const widgetHasGrpIds = selectedGroupIds.length > 0;

		const devicePaneDisabled =
			widgetHasCatIds || widgetHasCtrIds || widgetHasGrpIds;
		const categoryPaneDisabled =
			widgetHasDvcIds || widgetHasCtrIds || widgetHasGrpIds;
		const containerPaneDisabled =
			widgetHasDvcIds || widgetHasCatIds || widgetHasGrpIds;
		const groupPaneDisabled =
			widgetHasDvcIds || widgetHasCatIds || widgetHasCtrIds;

		const unselect = widgetHasDvcIds
			? 'unselectDvc'
			: widgetHasCatIds
			? 'unselectCat'
			: widgetHasCtrIds
			? 'unselectCtr'
			: widgetHasGrpIds
			? 'unselectGrp'
			: '';

		return (
			<Tabs tabPosition="top" tabBarStyle={{ marginBottom: 25 }}>
				<TabPane
					tab={
						<span>
							<Icon type="table" className="greyIcon" />
							{utils.translate(
								'componentWidgetTableConfigure.setup'
							)}
						</span>
					}
					key="1"
				>
					<Card bordered={false}>
						<TypeSection
							iconType="branches"
							label={utils.translate(
								'componentWidgetTableConfigure.type'
							)}
							selectName="tableType"
							options={options}
							selectValue={this.getCurrentType()}
							onChange={this.handleTypeChange}
							selectPlaceholder={tableTypePH}
						/>
						<Divider />
						<PageSizeSection
							getCurrentPageSize={this.getCurrentPageSize()}
							handlePageSizeChange={this.handlePageSizeChange}
						/>
					</Card>
				</TabPane>
				<TabPane
					forceRender
					tab={
						<span>
							<Icon type="wifi" className="greyIcon" />
							{utils.translate('componentWidgetConfigure.data')}
						</span>
					}
					key="2"
				>
					<Card bordered={false}>
						{!this.props.isCategoryDashboard && (
							<DeviceSelection
								{...this.props}
								{...this.state}
								handleDeviceChange={this.handleDeviceChange}
								handleCategoryChange={this.handleCategoryChange}
								handleContainerChange={
									this.handleContainerChange
								}
								handleGroupChange={this.handleGroupChange}
								handleActiveKeyChange={
									this.handleActiveKeyChange
								}
								devicePaneDisabled={devicePaneDisabled}
								categoryPaneDisabled={categoryPaneDisabled}
								containerPaneDisabled={containerPaneDisabled}
								groupPaneDisabled={groupPaneDisabled}
								unselect={unselect}
								loading={loading}
								currentDeviceIds={currentDeviceIds}
								entries={entries}
								allDevices={this.allDevices}
							/>
						)}
						{this.props.isCategoryDashboard && (
							<div>{categoryDashboardInfo}</div>
						)}
					</Card>
				</TabPane>
				<TabPane
					forceRender
					tab={
						<span>
							<Icon type="unlock" className="greyIcon" />
							{utils.translate(
								'componentWidgetTableConfigure.feature'
							)}
						</span>
					}
					key="3"
				>
					<Card bordered={false}>
						<SwitchSection
							checked={this.state.csvChecked}
							handleSwitch={this.handleCsvSwitch}
							label={utils.translate('generic.exportCSV')}
							iconType="export"
						/>

						{isStatus && (
							<>
								<Divider />

								<SwitchSection
									checked={this.state.addDeviceChecked}
									handleSwitch={this.handleAddDeviceSwitch}
									label={utils.translate(
										'componentWidgetTableConfigure.creationDVC'
									)}
									iconType="plus"
								/>
							</>
						)}
					</Card>
				</TabPane>
				<TabPane
					tab={
						<Tooltip title="Style">
							<span>
								<Icon type="bg-colors" className="greyIcon" />
								{utils.translate(
									'componentWidgetConfigure.style'
								)}
							</span>
						</Tooltip>
					}
					key="4"
				>
					<WidgetStyle
						{...this.props}
						marginTop={20}
						configuration={this.getCurrentConfiguration()}
					/>
				</TabPane>
			</Tabs>
		);
	}
}

export default Table;
