import React, { Component } from 'react';
import { withSize } from 'react-sizeme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardTitle, Col, Row } from 'reactstrap';
import { Tooltip, Icon, Popconfirm } from 'antd';

import { utils } from 'Lib/utils';

import './WidgetHeader.css';
import NameRefresh from './WidgetHeaderConfig/NameRefresh';
import BlockAdd from 'Components/Studio/Dashboard/Widgets/WidgetLib/BlockHeader/BlockAdd';


class WidgetHeader extends Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			type: this.props.widget.DWG_TYPE.replace('DWG_', '')
		};
	}


	componentDidMount()
	{
		this.props.setSize(this.props.size);
	}


	componentWillReceiveProps(newProps)
	{
		if (newProps.size.height !== this.props.size.height)
		{
			this.props.setSize(newProps.size);
		}
	}


	isFirstInBlock = (block, widgetOrder) => {
		let list = [];
		block.every(e => list.push(e.DWG_ORDER));
		let min = Math.min.apply(null, list);
		
		let isFirst = parseFloat(widgetOrder) === min;
		return isFirst;
	}
	

	isLastInBlock = (block, widgetOrder) => {
		let list = [];
		block.every(e => list.push(e.DWG_ORDER));
		let max = Math.max.apply(null, list);

		let isLast = parseFloat(widgetOrder) === max;
		return isLast;
	}


    render()
	{
		const { widget, block } = this.props;
		const isMarker = this.props.widget.DWG_TYPE === 'DWG_sitemapMarker';
		const canHaveBlock = this.props.widget.DWG_TYPE === 'DWG_status' || this.props.widget.DWG_TYPE === 'DWG_value' || this.props.widget.DWG_TYPE === 'DWG_boolean' || this.props.widget.DWG_TYPE === 'DWG_index';
		const isConfigMode = utils.isConfigMode();
		const titleFontSize = widget.DWG_DECOR.titleFontSize ? widget.DWG_DECOR.titleFontSize : '15';
		const titleBold = widget.DWG_DECOR.titleBold ? 'bold' : 'normal';

        return (

			<div
				style={{ zIndex: 9999, marginRight: isMarker ? '12px' : '' }}
				id={this.props.getWidgetId + '_HEADER'}
				className={'HEADER' + this.props.getWidgetClass + this.props.getWidgetClass + '_HEADER'}
			>
				<Row>
					<Col md={{ size: isConfigMode ? 6 : 12 }}>
						{!isMarker && !isConfigMode &&
						<Tooltip
							title={widget.DWG_LABEL}
							placement='topLeft'
						>
							<CardTitle className='widget-title' style={{fontSize: titleFontSize, fontWeight: titleBold }}>
								{widget.DWG_LABEL}
							</CardTitle>
						</Tooltip>}
						{isConfigMode && !isMarker &&
						<NameRefresh
							{...this.props}
						/>}
					</Col>
					{isConfigMode &&
					<Col md={{size: isMarker ? 12 : 6}}>
						<div className='widget-config' style={{fontSize:'17px'}}>
							{!isMarker && this.props.block.length === 1 && canHaveBlock &&
							<BlockAdd
								addWidget={this.props.addWidget}
								block={block}
							/>}
							{!isMarker && !this.isLastInBlock(block, widget.DWG_ORDER) &&
							<Tooltip
							placement='rightBottom'
							title={utils.translate('componentWidgetHeader.down')}
							>
								<FontAwesomeIcon
									className='widget-icon'
									icon='caret-down' 
									onClick={()=>this.props.moveWidgetDown(widget, block)}/>
							</Tooltip>}
							{!isMarker && !this.isFirstInBlock(block, widget.DWG_ORDER) &&
							<Tooltip
							placement='rightBottom'
							title={utils.translate('componentWidgetHeader.up')}
							>
								<FontAwesomeIcon
									className='widget-icon'
									icon='caret-up' 
									onClick={()=>this.props.moveWidgetUp(widget, block)}/>
							</Tooltip>}
							<Tooltip
								placement='leftBottom'
								title={utils.translate('componentWidgetHeader.build')}
							>
								<FontAwesomeIcon
									className='widget-icon'
									icon='cog'
									onClick={this.props.widget.DWG_TYPE !== 'DWG_scheduler' ? this.props.toggleModalConfigure : this.props.toggleModalConfigureScheduler}
								/>
							</Tooltip>
							<Tooltip
								placement='rightBottom'
								title={utils.translate('componentWidgetHeader.delete')}
							>
								<Popconfirm
								 	placement='leftTop'
									icon={<Icon type='question-circle-o' style={{ color: 'red' }} />}
								    title={this.props.widget.DWG_TYPE !== 'DWG_sitemapMarker' ? utils.translate('componentWidgetHeader.delete2') : utils.translate('componentWidgetHeader.delete3')}
								    onConfirm={this.props.delete}
								    okText={utils.translate('generic.yes')}
								    cancelText={utils.translate('generic.no')}
							  	>
									<FontAwesomeIcon
										className='widget-icon'
										icon='trash-alt'
									/>
		  			  			</Popconfirm>
							</Tooltip>
	                	</div>
					</Col>}
				</Row>
            </div>
        );
    }
}


export default withSize({monitorHeight: true})(WidgetHeader);
