import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { InputNumber } from 'antd';

import LabelItem from '../../FormItems/LabelItem/LabelItem';
import { utils } from 'Lib/utils';


const MinMaxValuesSection = (props) =>
{

	return (

		<div>
			<FormGroup>
				<Row>
					<LabelItem
						iconType='minus'
						label={utils.translate('componentMinMaxValuesSection.min')}
					/>
					<Col md={{size: 9}}>
						<InputNumber
							name='min'
							defaultValue={0}
							value={props.getCurrentMinValue}
							onChange={props.handleMinValueChange}
							style={{width: '100%'}}
						/>
					</Col>
				</Row>
			</FormGroup>
			<FormGroup>
				<Row>
					<LabelItem
						iconType='plus'
						label={utils.translate('componentMinMaxValuesSection.max')}
					/>
					<Col md={{size: 9}}>
						<InputNumber
							min={props.getCurrentMinValue}
							name='max'
							defaultValue={200}
							value={props.getCurrentMaxValue}
							onChange={props.handleMaxValueChange}
							style={{width: '100%'}}
						/>
					</Col>
				</Row>
			</FormGroup>
		</div>
	)
};


export default MinMaxValuesSection;
