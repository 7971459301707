import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Input } from 'antd';

import LabelItem from '../../FormItems/LabelItem/LabelItem';
import { utils } from 'Lib/utils';

import './CameraSection.css';


const CameraSection = (props) => {
	return (

		<>
			<FormGroup>
				<Row>
					<LabelItem
						iconType='poweroff'
						label={utils.translate('componentWidgetCamera.power') }
					/>
					<Col md={{ size: 9 }}>
						<Row>
							<Col md={{ size: 6 }} className='alignInput'>
								<Input
									type='text'
									value={props.powerZero}
									onChange={props.handlePowerZeroChange}
								/>
								<Input
									type='text'
									value={props.powerOne}
									style={{ marginLeft: '10px' }}
									onChange={props.handlePowerOneChange}
								/>
							</Col>
						</Row>
					</Col>
					<LabelItem
						iconType='video-camera'
						label={utils.translate('componentWidgetCamera.quality') }
					/>
					<Col md={{ size: 9 }}>
						<Row>
							<Col md={{ size: 6 }} className='alignInput' style={{paddingTop: '15px'}}>
								<Input
									type='text'
									value={props.qualityZero}
									onChange={props.handleQualityZeroChange}
								/>
								<Input
									type='text'
									value={props.qualityOne}
									style={{ marginLeft: '10px' }}
									onChange={props.handleQualityOneChange}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</FormGroup>
		</>
	)
};


export default CameraSection;
