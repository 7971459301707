import React from 'react';
import { withTranslation } from 'react-i18next';
/* eslint no-unused-vars:0 */ 


const Translation = (props) =>
{

	const {
		language,
		t
	} = props;

	return (
		t(language)
	)
};


export default withTranslation()(Translation);
