import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Card } from 'reactstrap';
import Widget from '../../Widget';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AddButton from './AddButton/AddButton';
import EmptyWidget from '../../WidgetLib/EmptyWidget/EmptyWidget';
import Legend from 'Components/Studio/Dashboard/Widgets/WidgetLib/Legend/Legend';

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils';

import './Sitemap.css';


class Sitemap extends Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			markers: [],
			x: 0,
			y: 0,
			placingMarker: false,
		};

		this.componentIsMounted = false;
	}


    componentDidMount = () =>
	{
		this.componentIsMounted = true;
		this.api_getMarkers();
	}


	componentDidUpdate(prevProps)
	{
        if (JSON.stringify(prevProps.widget.DTY_IDS) !== JSON.stringify(this.props.widget.DTY_IDS) ||
			JSON.stringify(prevProps.widget.ATR_IDS) !== JSON.stringify(this.props.widget.ATR_IDS) ||
			prevProps.widget.DTH_THRESHOLD_VALUE !== this.props.widget.DTH_THRESHOLD_VALUE ||
			prevProps.widget.DTH_OPERATOR !== this.props.widget.DTH_OPERATOR ||
			prevProps.widget.DTH_INACTIVE_COLOR !== this.props.widget.DTH_INACTIVE_COLOR ||
			prevProps.widget.DTH_ACTIVE_COLOR !== this.props.widget.DTH_ACTIVE_COLOR ||
			prevProps.widget.DTH_DEFAULT_COLOR !== this.props.widget.DTH_DEFAULT_COLOR
		)
			this.api_getMarkers();
    }


	componentWillUnmount = () =>
	{
		this.componentIsMounted = false;
    }


	api_getMarkers = () =>
	{
		if (this.props.widget && this.props.widget.DWS_ID)
		{
			request.get(
				'widgetsitemap/' + this.props.widget.DWS_ID,
				{},
				(sitemap) =>
				{
					let apiMarkers = sitemap.sitemap.markers;
					let stateMarkers = [];

					apiMarkers.map(function(apiMarker)
					{
						let stateMarker = {
							DWG_ID: apiMarker['DSM_ID'],
							DWG_LABEL: apiMarker['DSM_LABEL'],
							DWG_TYPE: 'DWG_sitemapMarker',
							DSM_TYPE: apiMarker['DSM_TYPE'],
							DSM_ICON: apiMarker['DSM_ICON'],
							DVC_ID: apiMarker['DVC_ID'],
							DWG_REFRESH_FREQ: this.props.widget.DWG_REFRESH_FREQ, // refresh rate is configured at main widget level, markers inherit
							// coordinates
							x: apiMarker['DSM_COORDINATES'].split(',')[0], // left side of comma is x
							y: apiMarker['DSM_COORDINATES'].split(',')[1],
							DWG_DECOR: {}
						};

			    		stateMarkers.push(stateMarker);
						return stateMarkers;
					}.bind(this));

					if (this.componentIsMounted) { this.setState({markers: stateMarkers}); }
				},
				() => console.error('load sitemap failed')
			);
		}
	}


	togglePlacingMarker = () =>
	{
		this.setState(prevState => ({ placingMarker: !prevState.placingMarker }));
	}


	handleMarkerChange = async (event) =>
	{
		if (this.state.placingMarker)
		{
			if (event.type === 'mousemove')
			{ // a marker is being placed
				await this.setMarkerCoordinatesToState(event);
			}
			else if (event.type === 'click')
			{ // marker has been placed
				this.togglePlacingMarker(); // we're not placing any more
				this.api_createMarker();
			}
		}
	}


	setMarkerCoordinatesToState = async (event) =>
	{ // calculates marker position relative to image
		// 1) finding image div, we want to place markers relative to it so that markers retain position if image is resized
		// 2) getting div position object
		// 3) getting position of click event relative to image div (clientX is absolute position on screen)
		// 4) getting percentage relative to height/width
		// 5) rounding to nearest int, limiting to between 0 and 100
		// 6) setting state

		var rect = ReactDOM.findDOMNode(this.refs.sitemap);
		var sitemap = rect.getBoundingClientRect();

		this.setState({
			x: Math.min(Math.max(Math.round((event.clientX - sitemap.left) / sitemap.width * 100), 0), 100),
			y: Math.min(Math.max(Math.round((event.clientY - sitemap.top) / sitemap.height * 100),  0), 100)
		});
	}


	api_createMarker = () =>
	{
		request.post(
			'widgetsitemapmarker',
			{
				'DWG_ID': this.props.widget.DWG_ID,
				'DSM_COORDINATES': this.state.x + ',' + this.state.y,
				'DSM_LABEL': 'New Sitemap Marker',
				'DSM_ICON': 'marker'
			},
			(marker) =>
			{
				this.api_getMarkers();
			},
			() => console.error('marker creation failed')
		)
	}


	api_deleteMarker = (marker) =>
	{
		request.delete(
			'widgetsitemapmarker/' + marker,
			{},
			(response) => { // success callback
				this.api_getMarkers(); //refreshing markers
			},
			() => console.error('marker deletion failed') // error callback
		);
	}


	render()
	{
		const { widget, isCategoryDashboard, size } = this.props;
		const { markers = [], } = this.state;
		const isMobileVersion = utils.isMobileVersion(window);
		const isConfigMode = utils.isConfigMode();
		const hasDeviceProfile = widget.DWS_HAS_DEVICE_PROFILE;
		const DVC_ID = this.props.widget.DVC_ID ? this.props.widget.DVC_ID : this.props.category && this.props.category.devices ? Object.keys(this.props.category.devices)[0] : undefined;

		return (

			<div>
				<div className='sitemap-content' style={{marginTop: `2%`}}>

					{widget.DWS_IMAGE &&
					<div
						id='underlay'
						onMouseMove={this.handleMarkerChange.bind(this)}
						onClick={this.handleMarkerChange.bind(this)}
						style={{cursor: this.state.placingMarker ? 'pointer' : ''}}>

						<img
							ref='sitemap'
							src={widget.DWS_IMAGE}
							alt=''
							style={{
								height: size.height < size.width ?
									`${size.height - 20}px` : '',
								width: size.height > size.width ?
									`${size.width - 20}px` : '',
								maxHeight: isMobileVersion ? `${size.height - 20}px` : '',
							}}
						/>

						<div
							id={this.props.getWidgetId}
							className={'markers' + this.props.getWidgetClass}
						>

							{this.state.placingMarker &&
							<FontAwesomeIcon className='markerBeingPlaced' icon='wifi' style={{left: `${this.state.x}%`, top:`${this.state.y}%`}}/>}

							{markers.map((marker) =>
								<Card
									key={marker.DWG_ID}
									id={this.props.getWidgetId + '_CONTENT'}
									className={'marker CONTENT' + this.props.getWidgetClass + this.props.getWidgetClass + '_CONTENT'}
									style={{
										left: `${marker.x}%`,
										top:`${marker.y}%`,
										visibility: isCategoryDashboard && !isConfigMode ? marker.DVC_ID === DVC_ID ? 'visible' : 'hidden' : 'visible',
										backgroundColor: isConfigMode ? '' : 'transparent',
										borderRadius: isConfigMode ? '25px' : '50px',
										boxShadow: isConfigMode ? '0 3px 7px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22)' : ''
									}}
								>
									<Widget
										widget={marker}
										parentWidget={widget}
										allDevices={this.props.allDevices}
										allContainers={this.props.allContainers}
										allCategories={this.props.allCategories}
										allGroups={this.props.allGroups}
										layouts={this.state.layouts}
										category={this.props.category}
										isCategoryDashboard={isCategoryDashboard}
										hasDeviceProfile={hasDeviceProfile}
										loading={this.props.loading}
										api_getDevices={this.props.api_getDevices}
										getWidgets={this.api_getMarkers}
										addWidget={this.addWidget}
										saveToState={this.saveWidgetToState}
										delete={this.api_deleteMarker}
										headerStyle={{paddingRight: '12px'}}
										cardStyle={{padding: '0'}}
										getWidgetClass={this.props.getWidgetClass}
										getWidgetId={this.props.getWidgetId}
									/>
								</Card>
							)}

						</div>
					</div>}
				</div>

				{isConfigMode && widget.DWS_IMAGE &&
				<AddButton
					{...this.props}
					togglePlacingMarker={this.togglePlacingMarker}
				/>}

				{widget.DWS_IMAGE && markers.length > 0 &&
				<Legend
					widget={widget}
					bottom={20}
					left={20}
				/>}

				{isConfigMode && !widget.DWS_IMAGE &&
				<EmptyWidget
					{...this.props}
					callToAction={utils.translate('componentWidgetSitemap.start')}
				/>}

			</div>
		)
	}
}


export default Sitemap;
