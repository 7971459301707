import React from 'react';
import { Tooltip, Icon } from 'antd';

import { utils } from 'Lib/utils';

const DeviceImagePlaceholder = () =>
{
	return (
		
		<Tooltip title={utils.translate('componentDeviceImagePlaceholder.noImage')}>
			<Icon
				type='dashboard'
				theme='twoTone'
				twoToneColor='#ededed'
				style={{ fontSize: 150 }}
			/>
		</Tooltip>
	);
};


export default DeviceImagePlaceholder;
