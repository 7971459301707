import React from 'react';
import moment from 'moment';
import { Row, Col, FormGroup } from 'reactstrap';
import { Select, Checkbox, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/fr_FR';

import LabelItem from '../../FormItems/LabelItem/LabelItem';

import { dateRangePresets } from './DateRangePresets/DateRangePresets';
import { utils } from 'Lib/utils';



const DateRangeSection = (props) =>
{

	return (

		<>
			<FormGroup>
				<Row>
					<LabelItem
						iconType='calendar'
						label={utils.translate('componentDateRangeSection.dateRange')}
					/>
					<Col md={{ size: 9 }}>
						<Select
							optionFilterProp='children'
							style={{ width: '100%' }}
							placeholder={utils.translate('componentDateRangeSection.selectRange')}
							onChange={props.handleDateRangeChange}
							value={props.dateRange}
						>
							{utils.getOptionsFromArray(dateRangePresets)}
						</Select>
					</Col>
				</Row>
			</FormGroup>

			{props.dateRange === 'custom' &&
			<>
				<FormGroup>
					<Row>
						<LabelItem
							iconType='clock-circle'
							label={utils.translate('componentDateRangeSection.startDate')}
						/>
						<Col md={{ size: 9 }}>
							<DatePicker
								locale={utils.getCurrentLanguage() === 'en' ? '' : locale}
								allowClear={false}
								onChange={(dateStart) => props.handleDatesChange(dateStart, 'date_start')}
								format='YYYY-MM-DD HH:mm:ss'
								value={props.dateStart}
								showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
								style={{ width: '100%' }}
							/>
						</Col>
					</Row>
				</FormGroup>
				{!props.currentDate && (
				<FormGroup>
					<Row>
						<LabelItem
							iconType='clock-circle'
							label={utils.translate('componentDateRangeSection.stopDate')}
						/>
						<Col md={{ size: 9 }}>
							<DatePicker
								locale={utils.getCurrentLanguage() === 'en' ? '' : locale}
								allowClear={false}
								onChange={(dateStop) => props.handleDatesChange(dateStop, 'date_stop')}
								format='YYYY-MM-DD HH:mm:ss'
								value={props.dateStop}
								showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
								style={{ width: '100%' }}
							/>
						</Col>
					</Row>
				</FormGroup>)}
				<FormGroup>
					<Row>
						<LabelItem
							iconType='forward'
							label={utils.translate('componentDateRangeSection.upToDate')}
						/>
						<Col md={{ size: 9 }} className='checkBoxCol'>
							<Checkbox
								checked={props.currentDate}
								onChange={props.handleCurrentDateChange}>{''}
							</Checkbox>
							{props.currentDate &&
							(<small>
								{props.upToDateDescription}
							</small>)}
						</Col>
					</Row>
				</FormGroup>
			</>}
		</>
	)
};


export default DateRangeSection;
