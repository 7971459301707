import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { CardBody, CardTitle, Form, FormGroup, Label, Input, Container } from 'reactstrap';
import { Row, Col, Card, Alert, Button } from 'antd';

import { auth } from 'Lib/auth';
import { utils } from 'Lib/utils';

import './Login.css';


class Login extends Component
{

    constructor(props)
	{
        super(props);

        this.state = {
            redirectToReferrer: false,
            username: '',
            password: '',
            unauthorized: false,
        }
    }


    login = () =>
	{
		this.setState({ unauthorized: false }, () =>
		{
			auth.authenticate(
				this.state.username,
				this.state.password,
				() =>
				{
					this.setState(() => ({ redirectToReferrer: true }));
				},
				() =>
				{
					this.setState({ unauthorized: true });
				}
			);
		});
    }


    handleInputChange = (event) =>
	{
        this.setState({ [event.target.name]: event.target.value });
    }


	invertColor = (hex) =>
	{
		if (hex.indexOf('#') === 0)
		{
			hex = hex.slice(1);
		}
		if (hex.length === 3)
		{
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		if (hex.length !== 6) { return; }

		var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
		g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
		b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);

		return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
	}


	padZero = (str, len) =>
	{
		len = len || 2;
		var zeros = new Array(len).join('0');

		return (zeros + str).slice(-len);
	}


    render()
	{
		document.title = this.props.appSettings.app_label ? this.props.appSettings.app_label : 'IoT App Studio';
        const from = { pathname: utils.getBaseUrl() };
        const redirectToReferrer = this.state.redirectToReferrer;
		const invertedColor = this.props.appSettings.color ? this.invertColor(this.props.appSettings.color) : '#000000';
		const hasImage = this.props.appSettings.app_url_icon !== window.ENV.REACT_APP_CS_URL;
		const hasFavicon = this.props.appSettings.app_url_favicon !== window.ENV.REACT_APP_CS_URL;
		const favicon = document.getElementById("favicon");
		document.title = this.props.appSettings.app_label;
		favicon.href = hasFavicon && this.props.appSettings.app_url_favicon;
		
        if (redirectToReferrer)
		{
            return <Redirect to={from} />;
        }

		for (var i = 1; i < 99999; i++){
			window.clearInterval(i);
		}
		
        return (

            <Container
				fluid
				style={{
					width: '100%',
				}}
			>
            	<Row
					type='flex'
					className='centeredRow'
					style={{
						alignItems: 'center',
					}}
				>
					{hasImage &&
					<>
						<Col
							xl={{ span: 8, offset: 4 }}
							lg={24}
							md={24}
							sm={24}
							xs={24}
							className='text-center'
						>
							<img
								ref='orgLogo'
								src={this.props.appSettings.app_url_icon}
								alt=''
								style={{ maxHeight: '400px', maxWidth: '100%', marginTop: '10%', borderRadius: '15px' }}
							/>
						</Col>
					</>}
                    <Col
						xl={{ span: hasImage ? 8 : 12, offset: hasImage ? 0 : 6 }}
						lg={{ span: 24, offset: 0 }}
						md={{ span: 24, offset: 0 }}
						sm={{ span: 24, offset: 0 }}
						xs={{ span: 24, offset: 0 }}
						className='text-center'
					>
                        <Card
						 	bordered={false}
							style={{backgroundColor: this.props.appSettings.color}}
						>
                            <CardBody>
                                <CardTitle id='signInTitle' style={{color: invertedColor}}>
                                    <h2>{this.props.appSettings.app_label ? this.props.appSettings.app_label : 'Application'}</h2>
                                </CardTitle>
                                <Form>
                                    <FormGroup className='loginFormGroup' style={{color: invertedColor}}>
                                        <Label for='username'>
											{utils.translate('componentLogin.username')}
                                        </Label>
                                        <Input type='text' id='username' name='username' value={this.state.username} onChange={this.handleInputChange} required />
                                    </FormGroup>
                                    <FormGroup className='loginFormGroup' style={{color: this.props.appSettings.color ? this.invertColor(this.props.appSettings.color) : '#000000'}}>
                                        <Label for='password'>
											{utils.translate('componentLogin.password')}
                                        </Label>
                                        <Input type='password' id='password' name='password' value={this.state.password} onChange={this.handleInputChange} required />
                                    </FormGroup>
									<FormGroup id='signIn'>
                                    	<Button className='signInButton' onClick={this.login} icon='login'>{utils.translate('componentLogin.start')}</Button>
									</FormGroup>
                                </Form>
								{this.state.unauthorized &&
									<Alert
										message={utils.translate('componentLogin.invalid')}
										description={utils.translate('componentLogin.invalid2')}
										type='error'
										showIcon
										closable
									/>}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
				<div className='credits'>
					<small>{window.ENV.REACT_APP_CREDITS}</small>
				</div>
            </Container>
        );
    }
}


export default Login;
