import React from 'react';
import { Form, Radio, Tooltip } from 'antd';

import { ChartGroupingModel } from 'Models/ChartGroupingModel';
import { utils } from 'Lib/utils';

const GroupByControl = (props) =>
{

	const {
		widget,
		value,
		onChange,
		disabled,
		tooltipMessage,
	} = props;


	const getOptions = () =>
	{
		return Object.values(ChartGroupingModel).map((value) =>
		{
			const allowed = widget && widget.DWC_ALLOWED_GROUPING_OPTS &&
				widget.DWC_ALLOWED_GROUPING_OPTS.includes(value.value);

			return (
				<Tooltip
					title={allowed ? '' : utils.translate('componentGroupByControl.disabled')}
				>
					<Radio.Button
						disabled={!allowed}
						value={value.value}
					>
						{value.label}
					</Radio.Button>
				</Tooltip>
			);
		});
	}

	return (

		<div id='sliding-date' className={widget.DWC_HAS_SLIDING_DATE === '0' ? 'grouping-controls' : 'grouping-controls-sliding'}>
			<Form layout='inline'>
				<Form.Item label={utils.translate('componentGroupByControl.grouping')}>
					<Tooltip
						title={tooltipMessage}
					>
						<Radio.Group
							size='small'
							value={value}
							disabled={disabled}
							onChange={onChange}
						>
							{getOptions()}
						</Radio.Group>
					</Tooltip>
				</Form.Item>
			</Form>
		</div>
	)
};


export default GroupByControl;
