import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Select, Tooltip } from 'antd';

import LabelItem from '../../FormItems/LabelItem/LabelItem';
import { utils } from 'Lib/utils';


const DatatypeSection = (props) =>
{

	const {
		label,
		selectRef,
		mode,
		maxTagCount,
		allowClear,
		loading,
		datatypeSelectDisabled,
		datatypeSelectPlaceholder,
		selectedDatatypes,
		selectOptions,
		handleConfigChange,
		setFilter,
		disabled
	} = props;

	const noOptions = selectOptions && selectOptions.length === 0;

	return (

		<FormGroup>
			<Row>
				<LabelItem
					iconType='api'
					label={label}
				/>
				<Col md={{size: 9}}>
					<Tooltip title={noOptions ? utils.translate('componentDatatypeSection.noDevice') : ''}>
						<Select
							ref={selectRef}
							allowClear={allowClear}
							mode={mode}
							showSearch
							optionFilterProp='children'
							style={{ width: '100%' }}
							placeholder={datatypeSelectPlaceholder}
							value={selectedDatatypes}
							onChange={handleConfigChange}
							disabled={datatypeSelectDisabled || noOptions || disabled}
							loading={loading}
							showArrow={true}
							maxTagCount={maxTagCount}
							onSearch={(value)=>{setFilter(value)}}
							onDropdownVisibleChange={()=>{setFilter(undefined)}}
						>
						{selectOptions.map((selectOption) =>
							selectOption
						)}
						</Select>
					</Tooltip>
				</Col>
			</Row>
		</FormGroup>
	)
};


export default DatatypeSection;
