import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Divider, InputNumber } from 'antd';

import LabelItem from '../../FormItems/LabelItem/LabelItem';
import SmallLabelItem from '../../FormItems/SmallLabelItem/SmallLabelItem';
import ColorPickerItem from '../../FormItems/ColorPickerItem/ColorPickerItem';
import VerticalDivider from 'Components/Studio/StudioLib/VerticalDivider/VerticalDivider';
import { utils } from 'Lib/utils';


const ThresholdSection = (props) =>
{

	const {
		loading,
		lowThreshold,
		highThreshold,
		lowThresholdColor,
		highThresholdColor,
		handleLowThresholdChange,
		handleHighThresholdChange,
		handleLowThresholdColorChange,
		handleHighThresholdColorChange,
	} = props;


	return (

		<>
			<FormGroup>
				<Row
					className='align-items-center'
				>
					<LabelItem
						label={utils.translate('componentThresholdSection.low')}
						iconType='vertical-align-bottom'
					/>
					<Col
						md={{ size: 4 }}
						className='text-center'
					>
						<SmallLabelItem
							label={utils.translate('generic.value')}
							style={{ marginRight: 10 }}
						/>
						<InputNumber
							value={lowThreshold}
							onChange={handleLowThresholdChange}
							precision={2}
							disabled={loading}
						/>
					</Col>
					<Col
						md={{ size: 1 }}
						className='text-center'
					>
						<VerticalDivider
							height={30}
						/>
					</Col>
					<Col
						md={{ size: 4 }}
						className='text-center'
					>
						<ColorPickerItem
							label={utils.translate('componentThresholdSection.colorBelow')}
							color={lowThresholdColor}
							onClose={handleLowThresholdColorChange}
							enableAlpha={false}
						/>
					</Col>
				</Row>
			</FormGroup>
			<Divider/>
			<FormGroup>
				<Row
					className='align-items-center'
				>
					<LabelItem
						label={utils.translate('componentThresholdSection.high')}
						iconType='vertical-align-top'
					/>
					<Col
						md={{ size: 4 }}
						className='text-center'
					>
						<SmallLabelItem
							label={utils.translate('generic.value')}
							style={{ marginRight: 10 }}
						/>
						<InputNumber
							value={highThreshold}
							onChange={handleHighThresholdChange}
							precision={2}
							disabled={loading}
						/>
					</Col>
					<Col
						md={{ size: 1 }}
						className='text-center'
					>
						<VerticalDivider
							height={30}
						/>
					</Col>
					<Col
						md={{ size: 4 }}
						className='text-center'
					>
						<ColorPickerItem
							label={utils.translate('componentThresholdSection.colorAbove')}
							color={highThresholdColor}
							onClose={handleHighThresholdColorChange}
							enableAlpha={false}
						/>
					</Col>
				</Row>
			</FormGroup>
		</>
	)
};


export default ThresholdSection;
