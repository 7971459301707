import React, { useState, useEffect, useRef } from 'react';
import { Divider, Select } from 'antd';

import DatatypeCustomizationConfigure from
	'./DatatypeCustomizationConfigure/DatatypeCustomizationConfigure';
import DeviceSection from '../../../FormSections/DeviceSection/DeviceSection';
import DatatypeSection from '../../../FormSections/DatatypeSection/DatatypeSection';

import { MAX_DATATYPES } from 'Config/config';
import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils';
import { withTranslation } from 'react-i18next';

import { categoryDashboardInfo } from
	'Components/Studio/StudioLib/GenericHTML/GenericText.js';
import { multipleDevicesPH, multipleDatatypesPH } from
	'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';

const ALL_OPTION = 'all';

const datatypeSelectlabel = (
	<>{utils.translate('componentDatatypeConfigure.datatypes')}<br/><small>{utils.translate('componentDatatypeConfigure.upTo')} {MAX_DATATYPES}</small></>
);

const { Option } = Select;


const DatatypeConfigure = (props) =>
{

	const {
		widget,
		widgetType,
		configuration,
		devices,
		categories,
		containers,
		groups,
		categoryFilter,
		containerFilter,
		groupFilter,
		loading,
		isCategoryDashboard,
		handleCategoryFilterChange,
		handleContainerFilterChange,
		handleGroupFilterChange,
		saveConfiguration,
		api_getDevices,
	} = props;

	const [configs, setConfigs] = useState([]);
	const [selectedDevices, setSelectedDevices] = useState(undefined);
	const [selectedDatatypes, setSelectedDatatypes] = useState(undefined);
	const [filter, setFilter] = useState(undefined);

	const refAllDatatypes = useRef();

	const entries = devices && devices.length;
	const currentDeviceIds = selectedDevices ? selectedDevices.split(',') : undefined;
	const currentConfigIds = selectedDatatypes ? selectedDatatypes.split(',') : undefined;
	const canCustomizeDatatypes = selectedDatatypes &&
		selectedDatatypes.length > 0 && configs && configs.length > 0;


	useEffect(() =>
		{

			if (widget)
			{
				if (widget.DVC_IDS && widget.DVC_IDS.length)
				{
					getConfigsOfDeviceIds(widget.DVC_IDS);
				}

				setSelectedDevices(widget.DVC_IDS);
				setSelectedDatatypes(widget.CFG_IDS);
			}
		},
		[]
	);


	useEffect(() =>
		{
			getConfigsOfDeviceIds(selectedDevices);

			saveConfiguration({
				...configuration,
				type: widgetType,
				device_ids: selectedDevices,
			});
		},
		[selectedDevices]
	);


	useEffect(() =>
		{
			saveConfiguration({
				...configuration,
				type: widgetType,
				config_ids: selectedDatatypes,
			});
		},
		[selectedDatatypes]
	);


	const getConfigsOfDeviceIds = (deviceIds) =>
	{
		if (deviceIds)
		{
	        request.get(
	            'config',
	            {
					device_id: deviceIds,
				},
	            (configs) => setConfigs(configs),
	            (e) => console.error(e),
	        );
		}
    }


    const handleDeviceChange = (value) =>
	{
		setSelectedDevices(value.toString());
		value.length === 0 && setSelectedDatatypes(undefined);
    }


    const handleConfigChange = (value) =>
	{
		if(filter){ setFilter(undefined); }

		const allSelected = value && value.includes(ALL_OPTION);

        if (allSelected)
		{
            const allDatatypes = refAllDatatypes.current;
            let options = allDatatypes.props.children || [];
			options = options.filter(option => option.key !== ALL_OPTION);

			value = options.map((option) =>
			{
				return option.key;
			});
        }

		setSelectedDatatypes(value.toString());
    }


	const getDatatypeSelectOptions = () =>
	{
		const datatypeSelectOptions = [];
		const selectAll = 
						<Option
							key={ALL_OPTION}
							id='selectAllOption'
							value={ALL_OPTION}
						>
							{props.t('generic.selectAll')} {filter ? props.t('componentDatatypeConfigure.containing') + ' "' + filter + '"' : ''}
						</Option>

		if (selectedDevices && selectedDevices.length > 0 &&
			refAllDatatypes.current.props.children.length <= MAX_DATATYPES && configs.length > 0)
		{
			datatypeSelectOptions.push(selectAll);// The variable 'filter' in Select All function is important for ant design to show it when searching
		}

		if (selectedDevices && selectedDevices.length > 0)
		{
			configs.forEach((config) =>
			{
				let option = 
							<Option
								key={config.id}
								disabled={currentConfigIds && !currentConfigIds.includes(config.id)
									&& currentConfigIds.length === MAX_DATATYPES}
								value={config.id}
							>
								{config.device.label} - {config.label_D1} {config.unite_D1 &&
								<span>({config.unite_D1})</span>}
							</Option>;

				if(filter){
					let toSearch = config.device.label + config.label_D1;
					let isFiltered = toSearch.toLowerCase().includes(filter.toLowerCase());
					if(isFiltered){
						datatypeSelectOptions.push(option);
					}	
				}else{
					datatypeSelectOptions.push(option);
				}
			});
		}
		return datatypeSelectOptions;
	}


	return (

		<>
			{!isCategoryDashboard &&
			<DeviceSection
				label={utils.translate('generic.dvcs')}
				deviceSelectMode='multiple'
				entries={entries}
				deviceSelectPlaceholder={multipleDevicesPH}
				devices={devices}
				categories={categories}
				containers={containers}
				groups={groups}
				selectedDevices={currentDeviceIds}
				categoryFilter={categoryFilter}
				containerFilter={containerFilter}
				groupFilter={groupFilter}
				loading={loading}
				disabled={loading}
				allowClearDeviceSelect
				handleDeviceChange={handleDeviceChange}
				handleCategoryFilterChange={handleCategoryFilterChange}
				handleContainerFilterChange={handleContainerFilterChange}
				handleGroupFilterChange={handleGroupFilterChange}
				api_getDevices={api_getDevices}
			/>}

			{isCategoryDashboard &&
			<>{categoryDashboardInfo}</>}

			<Divider />

			<DatatypeSection
				selectRef={refAllDatatypes}
				label={datatypeSelectlabel}
				mode='multiple'
				allowClear
				datatypeSelectPlaceholder={multipleDatatypesPH}
				selectOptions={getDatatypeSelectOptions()}
				selectedDatatypes={currentConfigIds}
				loading={isCategoryDashboard ? false : loading}
				datatypeSelectDisabled={isCategoryDashboard ? false : loading}
				handleConfigChange={handleConfigChange}
				maxTagCount={20}
				setFilter={setFilter}
			/>

			{canCustomizeDatatypes &&
			<>
				<Divider/>
				<DatatypeCustomizationConfigure
					{...props}
					widgetType={widgetType}
					configs={configs}
					selectedDatatypes={selectedDatatypes}
				/>
			</>}
		</>
	)
};


export default withTranslation()(DatatypeConfigure);
