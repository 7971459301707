import React from 'react';
import { Form, Radio } from 'antd';
import { utils } from 'Lib/utils';


const SizeSelect = (props) =>
{

	return (

		<Form.Item>
			<Radio.Group size='default' value={props.size} onChange={props.onChange}>
				<Radio.Button value='default'>{utils.translate('componentWidgetTable.large')}</Radio.Button>
				<Radio.Button value='middle'>{utils.translate('componentWidgetTable.medium')}</Radio.Button>
				<Radio.Button value='small'>{utils.translate('componentWidgetTable.small')}</Radio.Button>
			</Radio.Group>
		</Form.Item>
	);
};


export default SizeSelect;
