import React, { Component } from 'react';
import moment from 'moment';
import i18n from "Config/i18nConfig";
import { Button, Tooltip, Icon, Popover, Checkbox, Row, Col, Divider, Form, Radio, DatePicker } from 'antd';
import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils';
import locale from 'antd/es/date-picker/locale/fr_FR';
import './DeviceDataExporter.css';

class DeviceDataExporter extends Component {

	constructor(props) {
		super(props);

		this.state = {
			popOverVisible: false,
			devicesToExport: [],
			datatypesToExport: [],
			downloading: false,
			checked: 'attr',
			dateStart: undefined,
			dateStop: undefined,
			currentDate: undefined,
			dateRange: undefined,
			allDeviceIds: [],
			allDatatypeIds: [],
		};
	}


	componentDidMount() {
		this.setState({ devicesToExport: this.props.devices });
		this.getDeviceDatatypeIds();
	}


	componentDidUpdate(prevProps) {
		if (prevProps.allDeviceIds !== this.props.allDeviceIds) {
			this.setState({ devicesToExport: this.props.devices });
			this.getDeviceDatatypeIds();
		}
	}


	exportToCSV = () => {
		let params = this.state.checked === 'dty' ? {
			export_type: 'datatype',
			datatype_ids: this.state.allDatatypeIds,
			date_start: this.state.dateStart,
			date_stop: this.state.dateStop
		} : {
			export_type: 'device',
			device_ids: this.state.allDeviceIds,
			date_start: this.props.dateStart,
			date_stop: this.props.dateStop
		}

		this.setState({ downloading: true }, () => {
			request.csvExport(
				params,
				() => {
					this.setState({ downloading: false });
				}
			);
		});
	}


	handlePopOverVisibleChange = (popOverVisible) => {
		this.setState({ popOverVisible });
	}


	onCheckboxChange = (checkedValues) => {
		this.setState({ devicesToExport: checkedValues }, () => this.getDeviceDatatypeIds());

	}


	toggleAllCheckboxes = () => {
		if (this.state.devicesToExport.length > 0) {
			this.setState({ devicesToExport: [] }, () => this.getDeviceDatatypeIds());
		}
		else {
			this.setState({ devicesToExport: this.props.devices }, () => this.getDeviceDatatypeIds());
		}
	}

	getDeviceDatatypeIds = () => {
		let allDatatypeIds = [];
		let allDeviceIds = [];
		this.state.devicesToExport.forEach((device) => {
			allDeviceIds.push(device.id)
			device.configs && device.configs.forEach((config) => {
				allDatatypeIds.push(config.id)
			})
		});
		
		this.setState({ allDeviceIds: allDeviceIds, allDatatypeIds: allDatatypeIds })
	}

	handleSourceChange = (event) => {
		if (event.target.checked) {
			this.setState({ checked: event.target.value });
		}

	}

	handleDatesChange = (date, extreme) => {
		if (extreme === 'date_start') {
			this.setState({ dateStart: date })
		} else {
			this.setState({ dateStop: date })
		}
	}


	getPopOverContent = () => {
		let options = this.props.devices.map(device => {
			return (
				<Col span={12} key={device.id}>
					<Checkbox className='shortSpan' value={device}>{device.label}</Checkbox>
				</Col>
			);
		});

		return (
			<>
				<Form.Item>
					<Icon className='formPHIcon' type='info-circle' />
					<i>{this.props.infoText}</i>
				</Form.Item>
				<Divider style={{ marginTop: '15px' }} />
				<Radio defaultChecked checked={this.state.checked === 'attr'} value='attr' onChange={this.handleSourceChange}>
					{utils.translate('componentDataExporter.deviceInfo')}
				</Radio>
				<Radio defaultChecked={false} checked={this.state.checked === 'dty'} value='dty' onChange={this.handleSourceChange}>
					{utils.translate('componentDataExporter.datatypes')}
				</Radio>
				{this.state.checked === 'dty' &&
				<Divider style={{ marginTop: '15px' }} />}
				{this.state.checked === 'dty' &&
				<Form layout="inline">
					<Form.Item>
							<DatePicker
								locale={utils.getCurrentLanguage() === 'en' ? '' : locale}
								placeholder={i18n.t('componentDateRangeSection.startDate')}
								allowClear={false}
								onChange={(dateStart) => this.handleDatesChange(dateStart, 'date_start')}
								format='YYYY-MM-DD HH:mm:ss'
								value={this.state.dateStart}
								showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
							/>
						</Form.Item>
						<Form.Item>
							<DatePicker
								locale={utils.getCurrentLanguage() === 'en' ? '' : locale}
								placeholder={i18n.t('componentDateRangeSection.stopDate')}
								allowClear={false}
								onChange={(dateStart) => this.handleDatesChange(dateStart, 'date_stop')}
								format='YYYY-MM-DD HH:mm:ss'
								value={this.state.dateStop}
								showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
							/>
							</Form.Item>
					</Form>}
				<Divider style={{ marginTop: '15px' }} />
				<Checkbox.Group
					value={this.state.devicesToExport}
					onChange={this.onCheckboxChange}
					style={{ width: '100%' }}
				>
					<Row>
						{options}
					</Row>
				</Checkbox.Group>
				<Row>
					<br />
					<Button
						size='small'
						icon={this.state.devicesToExport.length === 0 ? 'check' : 'close'}
						onClick={this.toggleAllCheckboxes}
					>
						{this.state.devicesToExport.length === 0 ? utils.translate('generic.selectAll') : utils.translate('generic.unselectAll')}
					</Button>
				</Row>
				<Divider />
				<Col className='text-center'>
					<Button
						type='primary'
						disabled={this.state.devicesToExport.length === 0 || (this.state.checked === 'dty' && (this.state.dateStart === undefined || this.state.dateStop === undefined))}
						onClick={() => this.exportToCSV()}
						icon='download'
						loading={this.state.downloading}
					>
						{utils.translate('generic.exportFile')}
					</Button>
				</Col>
			</>
		)
	}


	render() {
	
		const { devices } = this.props;
		const popOverTitle = (
			<span><Icon type={this.props.iconType} className='greyIcon' />{this.props.popOverTitle}</span>
		);

		return devices.length > 0 ? (

			<Tooltip title={this.props.tooltipTitle} placement='right'>
				<Popover
					content={this.getPopOverContent()}
					title={popOverTitle}
					trigger='click'
					placement='leftBottom'
					visible={this.state.popOverVisible}
					onVisibleChange={this.handlePopOverVisibleChange}
				>
					<Button
						shape='circle'
						type={this.props.buttonType}
						style={this.props.buttonStyle}
					>
						<Icon type='download' style={{ verticalAlign: '0.15em' }} />
					</Button>
				</Popover>
			</Tooltip>
		) : (
			null
		);
	}
}


export default DeviceDataExporter;
