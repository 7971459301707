import React, { useState, useEffect } from 'react';
import { withSize } from 'react-sizeme';
import { Drawer } from 'antd';

import DeviceProfile from '../../../WidgetLib/DeviceProfile/DeviceProfile';
import DeviceTooltip from '../../../WidgetLib/DeviceTooltip/DeviceTooltip';
import SvgIcon from '../../../../../StudioLib/SvgIcon/SvgIcon';

import { LogicalOperatorModel } from 'Models/LogicalOperatorModel';

import { utils } from 'Lib/utils';
import { request } from 'Lib/request';

import './Marker.css';


const Marker = (props) =>
{

	const {
		device,
		size,
		widget,
		icon
	} = props;

	const [drawerVisible, setDrawerVisible] = useState(false);
	const [apiDevice, setApiDevice] = useState(undefined);
	const [markerColor, setMarkerColor] = useState(undefined);
	const [masterValue, setMasterValue] = useState(undefined);
	const [masterUnit, setMasterUnit] = useState(undefined);

	const hasDeviceProfile = widget.DWM_HAS_DEVICE_PROFILE === '1';
	const widgetHasDthConfig = widget.DTH_THRESHOLD_VALUE && widget.DTH_OPERATOR &&
		widget.DTH_ACTIVE_COLOR && widget.DTH_INACTIVE_COLOR;
	const showValueNextToMarkers = widget.DTH_SHOW_VALUE === '1';
	const useDataMarkers = (widget.DTY_IDS && widget.DTY_IDS.length > 0) ||
		(widget.ATR_IDS && widget.ATR_IDS.length > 0);

	const statusRelatedInfo = {
		CONNECT: ["WORKING", "activeDeviceMarkerIcon", "rgb(31, 189, 67)"],
		STARTED: ["WORKING", "activeDeviceMarkerIcon", "rgb(31, 189, 67)"],
		STOPPED: ["STOPPED", "inactiveDeviceMarkerIcon", "rgba(255, 83, 105, 0.85)"],
		UNAVAILABLE: ["UNAVAILABLE", "unavailableDeviceMarkerIcon", "rgba(209, 207, 207, 0.9)"],
	}


	useEffect(() =>
		{
			const info = getMarkerInfo();
			if (info)
			{
				if(props.isAlternative && !props.showGeolocOnly){
					setMarkerColor('black');
					setMasterValue(info.value);
					setMasterUnit(info.unit);
				} else {
					setMarkerColor(info.color);
					setMasterValue(info.value);
					setMasterUnit(info.unit);
				}
			}
		},
		[props.showGeolocOnly, props.isAlternative]
	);


	const openDrawer = () =>
	{
		if (hasDeviceProfile)
		{
			setDrawerVisible(true);
			api_getDevice();
		}
	}


	const api_getDevice = () =>
	{
		request.get(
			'device/' + device.id,
			{},
			(fullDevice) => setApiDevice(fullDevice['0']),
			(e) => console.error(e)
		);
	}


	const getMarkerInfo = () =>
	{
		let markerColor = statusRelatedInfo[device.status][2]; // defaults to red / green status colors
		let masterDataSourceValue = undefined;
		let masterDataSourceUnit = undefined;

		if (useDataMarkers)
		{
			const masterDataSourceData = getMasterDataSourceData();
			const isApplicable = isApplicableToDevice(masterDataSourceData);

			if (isApplicable)
			{
				masterDataSourceValue = masterDataSourceData.value;
				masterDataSourceUnit = masterDataSourceData.unit;

				if (widgetHasDthConfig)
				{
					if (meetsActiveCondition(masterDataSourceValue))
						markerColor = widget.DTH_ACTIVE_COLOR;

					else markerColor = widget.DTH_INACTIVE_COLOR;
				}
			}

			if (!isApplicable && widgetHasDthConfig)
				markerColor = widget.DTH_DEFAULT_COLOR;
		}

		return {
			color: markerColor,
			value: masterDataSourceValue,
			unit: masterDataSourceUnit,
		};
	}


	const getMasterDataSourceData = () =>
	{
		let masterDataSourceData = { value: undefined, unit: undefined };

		if (widget.DTY_IDS && widget.ATR_IDS)
		{
			let masterSource = undefined;
			const masterDty = widget.DTY_IDS.find((dty) => dty.IS_MASTER === true);
			const masterAtr = widget.ATR_IDS.find((atr) => atr.IS_MASTER === true);

			if (masterDty && device.configs)
			{
				masterSource = device.configs.find(c => c.dty_id === masterDty.ID);
			}

			else if (masterAtr && device.attributes) // one or the other !!
			{
				masterSource = device.attributes.find((a) => a.atr_id === masterAtr.ID);
			}

			if (masterSource)
			{
				masterDataSourceData.value = String(masterSource.value);
				masterDataSourceData.unit = masterSource.unite_D1;
			}
		}

		return masterDataSourceData;
	}


	const meetsActiveCondition = (value) =>
	{ // uses chosen function to compare current value to set threshold
		if (value !== undefined)
		{
			return LogicalOperatorModel[widget.DTH_OPERATOR].function(
				value, widget.DTH_THRESHOLD_VALUE
			);
		}
	}


	const isApplicableToDevice = (data) => data && data.value !== undefined;


	const markerIcon = (
		<span
			className={statusRelatedInfo[device.status][1]}
			onClick={openDrawer}
			style={{
				top: `-${size.height}px`,
				left: `-${size.width / 2}px`
			}}
		>
			<SvgIcon
				type={icon}
				height={25}
				fill={markerColor}
			/>
			{masterValue !== undefined && showValueNextToMarkers &&
			<div className='valueSnippetDiv'>
				<span>{masterValue} {masterUnit}</span>
			</div>}
		</span>
	);

    return (

		<>
			<DeviceTooltip
				widget={widget}
				device={device}
				children={markerIcon}
				hasDeviceProfile={hasDeviceProfile}
				useDataMarkers={useDataMarkers}
				showGeoloc
				isAlternative={props.isAlternative}
				lat={props.lat}
				lng={props.lng}
				alt={props.alt}
			/>
			<Drawer
				title=''
				placement='left'
				closable
				onClose={() => setDrawerVisible(false)}
				visible={drawerVisible}
				width={utils.isMobileVersion(window) ? '100%' : '65%'}
			>
				<DeviceProfile
					device={apiDevice}
					isAlternative={props.isAlternative}
					lat={props.lat}
					lng={props.lng}
					alt={props.alt}
				/>
			</Drawer>
		</>
    );
}


export default withSize({monitorHeight: true})(Marker);
