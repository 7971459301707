import React, { useState, useEffect } from 'react';

import CheckboxGroupSection from
	'../../FormSections/CheckboxGroupSection/CheckboxGroupSection';

import { ChartGroupingModel } from 'Models/ChartGroupingModel';
import { utils } from 'Lib/utils';


const GroupingOptionsConfigure = (props) =>
{

	const {
		widget,
		configuration,
		saveConfiguration,
	} = props;

	const [selectedOptions, setSelectedOptions] = useState(undefined);


	useEffect(() =>
		{
			setSelectedOptions(getDefaultValues());
		},
		[]
	);


	useEffect(() =>
		{
			if (selectedOptions)
			{
				let newProps = {
					allowed_grouping_options: selectedOptions.toString(),
				};

				if (widget && !selectedOptions.includes(widget.DWC_GROUPING))
				{ // an unauthorized grouping option had been previously set
					newProps.grouping = selectedOptions[0] || ChartGroupingModel.NONE.value;
				} // setting chart grouping to first selected or 'none' if nothing selected

				saveConfiguration({
					...configuration,
					...newProps,
				});
			}
		},
		[selectedOptions]
	);


	const getOptions = () =>
	{
		return Object.values(ChartGroupingModel).map((value, index) =>
		{
			return {
				label: value.label,
				value: value.value,
			};
		});
	}


	const getDefaultValues = () =>
	{
		return widget && widget.DWC_ALLOWED_GROUPING_OPTS === undefined ?
			Object.values(ChartGroupingModel).map((value) => value.value) :
				widget.DWC_ALLOWED_GROUPING_OPTS.split(',');
	}


	const handleCheckboxChange = (value) => setSelectedOptions(value);


	return (

		<CheckboxGroupSection
			label={utils.translate('componentGroupingOptionsConfigure.grouping')}
			iconType='clock-circle'
			options={getOptions()}
			value={selectedOptions}
			onChange={handleCheckboxChange}
		/>
	)
};


export default GroupingOptionsConfigure;
