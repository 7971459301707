import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from   'Components/Routing/PrivateRoute/PrivateRoute';
import Login from          'Components/Login/Login';
import EmptyPage from      'Components/Routing/EmptyPage/EmptyPage';
import Dashboard from      'Components/Studio/Dashboard/Dashboard';
import NotFound from       'Components/Routing/NotFound/NotFound';

import { getConfig, transformConfig } from 'Config/config';
import { request } from 'Lib/request';
import { utils } from 'Lib/utils';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faPencilAlt,
    faTrashAlt,
    faChartArea,
	faChartBar,
    faChartPie,
    faTachometerAlt,
    faNewspaper,
    faHome,
    faCog,
	faCogs,
    faPowerOff,
    faToggleOff,
    faToggleOn,
    faMapMarked,
    faMapMarker,
    faSitemap,
    faSyncAlt,
	faPlus,
	faPlusCircle,
	faCheckCircle,
	faCheck,
	faBell,
	faWifi,
	faSignal,
	faIndustry,
	faSignInAlt,
	faSignOutAlt,
	faLightbulb,
	faTable,
	faSortNumericUp,
	faInfo,
	faLocationArrow,
	faBars,
	faExclamation,
	faArrowUp,
	faTerminal,
	faArrowAltCircleLeft,
	faSave,
	faHdd,
	faUndo,
	faCloudUploadAlt,
	faCaretUp,
	faCaretDown,
	faEye,
	faSlidersH,
	faFileCsv,
	faEquals,
	faGreaterThan,
	faLessThan,
	faFont,
	faCalendar,
	faCamera,
} from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';


library.add(
    faPencilAlt,
    faTrashAlt,
    faChartArea,
	faChartBar,
    faChartPie,
    faNewspaper,
    faHome,
    faCog,
	faCogs,
    faTachometerAlt,
    faPowerOff,
    faToggleOff,
    faToggleOn,
    faMapMarked,
    faMapMarker,
    faSitemap,
    faSyncAlt,
    fab,
	faPlus,
	faPlusCircle,
	faCheckCircle,
	faCheck,
	faBell,
	faWifi,
	faSignal,
	faIndustry,
	faSignInAlt,
	faSignOutAlt,
	faLightbulb,
	faTable,
	faSortNumericUp,
	faInfo,
	faLocationArrow,
	faBars,
	faExclamation,
	faArrowUp,
	faTerminal,
	faArrowAltCircleLeft,
	faSave,
	faHdd,
	faUndo,
	faCloudUploadAlt,
	faCaretUp,
	faCaretDown,
	faEye,
	faSlidersH,
	faFileCsv,
	faEquals,
	faGreaterThan,
	faLessThan,
	faFont,
	faCalendar,
	faCamera,
);


class App extends Component
{
	constructor(props)
	{
        super(props);
		this.state = {
			isLoaded: false,
			prefix: '',
			appSettings: {},
		};

		getConfig(this.scriptTag); // script injection for building config before render
    }


	api_getAppInfo = (appId) =>
	{
		request.get(
            'appsettings/' + appId,
            {},
            (appSettings) =>
			{
				utils.getLanguage(appSettings.app_language);
				appSettings.app_url_icon = window.ENV.REACT_APP_CS_URL + appSettings.app_url_icon;
				appSettings.app_url_favicon = window.ENV.REACT_APP_CS_URL + appSettings.app_url_favicon;
				this.setState({ appSettings: appSettings, isLoaded:true })
            },
			(error) => { console.error(error); }
        );
	}


    render()
	{
		transformConfig(() => this.api_getAppInfo(window.ENV.REACT_APP_ID)); // wait for config to be build before rendering
        return (
			<>
				{this.state.isLoaded &&
            		<Switch>
						<Route exact path={utils.pathConstruct(window.ENV.REACT_APP_PREFIX) + '/login'} component={() => <Login {... this.state} />}/>
						<PrivateRoute exact path={utils.pathConstruct(window.ENV.REACT_APP_PREFIX) + '?'} component={EmptyPage} />
						<PrivateRoute exact path={utils.pathConstruct(window.ENV.REACT_APP_PREFIX) + '/:dpg_id'} component={Dashboard}/>
                		<PrivateRoute component={NotFound} />
            		</Switch>
				}
			</>
        );
    }
}


export default App;
