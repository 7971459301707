import React, { Component } from 'react';
import { Table, Form, Checkbox, Button, Tooltip, Input, Icon } from 'antd';
import ColorPicker from 'rc-color-picker';
import { withTranslation } from 'react-i18next';

import { markerColors } from 'Components/Studio/StudioLib/GenericHTML/MarkerColors.js';
import { utils } from 'Lib/utils';

import './GeoTrackingTable.css';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
	<EditableContext.Provider value={form}>
		<tr {...props} />
	</EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);


class EditableCell extends Component
{

	state = {
		editing: false,
	};


	toggleEdit = () =>
	{
		const editing = !this.state.editing;

		this.setState({ editing }, () =>
		{
			if (editing) {
				this.input.focus();
			}
		});
	};


	save = (e) =>
	{
		const { record, handleSave } = this.props;

		this.form.validateFields((error, values) =>
		{
			if (error && error[e.currentTarget.id]) { return; }
			this.toggleEdit();
			handleSave({ ...record, ...values });
		});
	};


	renderCell = (form) =>
	{
		this.form = form;

		const { dataIndex, record } = this.props;

		if (dataIndex === 'points' || dataIndex === 'polyline')
		{
			return (
				<Form.Item style={{ margin: 0, textAlign: 'center' }}>
					<Checkbox
						name={dataIndex}
						checked={record[dataIndex]}
						onChange={(event) => this.props.handleCheckboxChange(event, record)}
					/>
				</Form.Item>
			);
		}
		if (dataIndex === 'color')
		{
			return (
				<Form.Item style={{ margin: 0, textAlign: 'center' }}>
					<ColorPicker
						color={record.color}
						onChange={(color) => this.props.handleColorChange(color, record)}
						enableAlpha={false}
						placement='bottomLeft'
						animation='slide-up'
						style={{ zIndex: 9999 }}
					/>
				</Form.Item>
			);
		}
	}


	render()
	{
		const {
			editable,
			dataIndex,
			title,
			record,
			index,
			handleSave,
			children,
			...restProps
		} = this.props;

		return (

			<td {...restProps}>
				{editable ? (
					<EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
				) : (
						children
					)}
			</td>
		);
	}
}


class GeoTrackingTable extends Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			dataSource: [],
			allPointsChecked: false,
			allPolylinesChecked: false,
			searchText: '',
		};

		this.getColumns();
	}


	componentDidMount = () =>
	{
		this.getCustomizedDevices();
	}


	componentDidUpdate = (prevprops, prevState) =>
	{
		if (prevprops.devices !== this.props.devices || prevprops.loading !== this.props.loading)
		{
			this.getCustomizedDevices();
		}
		if (prevprops.userWantsToCustomizeDevices === true &&
			this.props.userWantsToCustomizeDevices === false)
		{
			this.getCustomizedDevices(true);
		}
	}


	getColumns = () =>
	{
		this.columns = [
			{
				title: utils.translate('generic.labelDvc'),
				dataIndex: 'label',
				editable: false,
				...this.getColumnSearchProps('label')
			},
			{
				title: this.getColumnTitle('points'),
				dataIndex: 'points',
				editable: true,
			},
			{
				title: this.getColumnTitle('polyline'),
				dataIndex: 'polyline',
				editable: true,
			},
			{
				title: utils.translate('generic.color'),
				dataIndex: 'color',
				editable: true,
			},
		];
	}


	getColumnTitle = (columnType) =>
	{
		const stateName = columnType === 'points' ? 'allPointsChecked' : 'allPolylinesChecked';

		return (
			<>
				<span>
					{utils.translate('generic.show1')} {columnType === 'points' ? utils.translate('componentGeoTrackingTable.points') : utils.translate('componentGeoTrackingTable.polyline')}
				</span>
				<span style={{ marginLeft: '20px' }}>
					<Tooltip title={!this.state[stateName] ? utils.translate('generic.selectAll') : utils.translate('generic.unselectAll')}>
						<Button
							size='small'
							shape='circle'
							icon={this.state[stateName] ? 'close' : 'check'}
							onClick={() => this.toggleForAllDevices(columnType)}
						/>
					</Tooltip>
				</span>
			</>
		);
	}


	getCustomizedDevices = (reset = false) =>
	{
		const { widget, devices, allDevices } = this.props;
		let index = 0;

		if (devices === undefined || widget === undefined || allDevices === undefined) { return; }

		const selectedDevicesArray = devices.split(',');
		let stateDatasource = [];

		allDevices.forEach((device) =>
		{
			if (Array.isArray(selectedDevicesArray) && selectedDevicesArray.includes(device.id) && device.id !== 'all')
			{
				const deviceJWD = widget.JWD ? widget.JWD[device.id] : undefined;
				let row = {
					key: device.id,
					label: device.label,
					points: deviceJWD && !reset ? deviceJWD['JWD_SHOW_TRACKING'] === '1' : false, // boolean
					polyline: deviceJWD && !reset ? deviceJWD['JWD_SHOW_POLYLINE'] === '1' : false, // boolean
					color: deviceJWD && !reset ? deviceJWD['JWD_TRACKING_COLOR'] : markerColors[(index + selectedDevicesArray.length) % markerColors.length], // string
					// modulo operator enables us to 'loop' over color array as many times as needed
				};

				stateDatasource.push(row);
				index = index + 1;
			}
		});

		let allPointsChecked = stateDatasource.find(item => item.points === false);
		let allPolylinesChecked = stateDatasource.find(item => item.polyline === false);

		this.setState({
			dataSource: stateDatasource,
			allPointsChecked: allPointsChecked === undefined,
			allPolylinesChecked: allPolylinesChecked === undefined
		}, () =>
		{
			this.props.saveCustomizationToParentState(stateDatasource);
			this.getColumns();
		});
	}


	handleColorChange = (color, record) =>
	{
		const stateDatasource = [...this.state.dataSource];
		const index = stateDatasource.findIndex(item => record.key === item.key);
		const rowToUpdate = stateDatasource[index];

		rowToUpdate.color = color.color;
		stateDatasource.splice(index, 1, rowToUpdate);

		this.setState({ dataSource: stateDatasource });
		this.props.saveCustomizationToParentState(stateDatasource);
	}


	handleCheckboxChange = (event, record) =>
	{
		const checked = event.target.checked;
		const name = event.target.name;
		let stateDatasource = [...this.state.dataSource];
		const index = stateDatasource.findIndex(item => record.key === item.key);
		const rowToUpdate = stateDatasource[index];

		rowToUpdate[name] = checked;
		stateDatasource.splice(index, 1, rowToUpdate);

		this.setState({ dataSource: stateDatasource });
		this.props.saveCustomizationToParentState(stateDatasource);
	}


	handleSearch = (selectedKeys, confirm, dataIndex) =>
	{
		confirm();
		this.setState({ searchText: selectedKeys[0] });
	};


	handleReset = clearFilters =>
	{
		clearFilters();
		this.setState({ searchText: '' });
	};


	toggleForAllDevices = (columnType) =>
	{
		if (columnType !== undefined && columnType !== '')
		{
			const stateName = columnType === 'points' ? 'allPointsChecked' : 'allPolylinesChecked';

			this.setState({ [stateName]: !this.state[stateName] }, () =>
			{
				let stateDatasource = [...this.state.dataSource];
				stateDatasource.forEach((item) =>
				{
					item[columnType] = this.state[stateName] ? true : false;
				});

				this.getColumns();
				this.setState({ dataSource: stateDatasource });
				this.props.saveCustomizationToParentState(stateDatasource);
			});
		}
	}


	handleSave = (row) =>
	{
		const newData = [...this.state.dataSource];

		const index = newData.findIndex(item => row.key === item.key);
		const item = newData[index];

		newData.splice(index, 1, {
			...item,
			...row,
		});

		this.setState({ dataSource: newData });
	};


	getColumnSearchProps = (dataIndex) =>
	({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 15 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={this.props.t('componentGeoTrackingTable.search')}
					value={selectedKeys[0]}
					onEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					type='primary'
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon='search'
					size='small'
					style={{ width: 90, marginRight: 8 }}
        		>
          			Search
        		</Button>
        		<Button onClick={() => this.handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          			Reset
        		</Button>
			</div>
		),
		filterIcon: filtered => (
			<Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />
		),
		onFilter: (value, record) =>
			record[dataIndex]
			.toString()
			.toLowerCase()
			.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible =>
		{
			if (visible)
			{
				setTimeout(() => this.searchInput.select());
			}
		},
	});


	render()
	{
		if (!this.props.userWantsToCustomizeDevices) { return null; }

		const { dataSource } = this.state;
		const components = {
			body: {
				row: EditableFormRow,
				cell: EditableCell,
			},
		};
		const mapColumns = col =>
		{
			if (!col.editable)
			{
				return col;
			}
			const newCol = {
				...col,
				onCell: record => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					title: col.title,
					key: col.key,
					handleSave: this.handleSave,
					handleColorChange: this.handleColorChange,
					handleCheckboxChange: this.handleCheckboxChange
				})
			};
			if (col.children) {
				newCol.children = col.children.map(mapColumns);
			}
			return newCol;
		};
		const columns = this.columns.map(mapColumns);

		return (

			<div className='geo-tracking-table-container'>
				<Table
					style={{ whiteSpace: 'pre' }}
					components={components}
					rowClassName={() => 'editable-row'}
					scroll={{ x: 130 }}
					dataSource={dataSource}
					columns={columns}
					pagination={{ pageSize: 3 }}
				/>
			</div>
		);
	}
}


export default withTranslation()(GeoTrackingTable);
