import React, { useState, useEffect } from 'react';
import { Icon, Tooltip, Tag } from 'antd';
import { utils } from 'Lib/utils';

import NoData from '../../../WidgetLib/NoData/NoData';


const Help = () =>
{
	return (
		<Tooltip
			title={utils.translate('componentWidgetClassicValue.tooltip')}
		>
			<Icon
				className='form-icon'
				type='question-circle'
				theme="twoTone"
				style={{ verticalAlign: '0.15em' }}
			/>
		</Tooltip>
	);
};


const ClassicValue = (props) =>
{

	const {
		widget,
		config,
		value,
		configUnit,
		label,
		showHelp,
		size,
		getWidgetId,
		showLastDate,
		getWidgetFontSize,
	} = props;

	const [tagColor, setTagColor] = useState(undefined);
	const [title, setTitle] = useState(undefined);

	const highThreshold = widget && widget.DWV_HIGH_THRESHOLD;
	const lowThreshold = widget && widget.DWV_LOW_THRESHOLD;

	const highThresholdCrossed = highThreshold && value > highThreshold;
	const lowThresholdCrossed = lowThreshold && value < lowThreshold;
	const anyThresholdCrossed = lowThresholdCrossed || highThresholdCrossed;

	const widgetColors = widget && widget.DWV_COLORS;
	const widgetColorArray = (widgetColors && widgetColors.split(',')) || [];

	const layout = widget.DWG_DECOR.widgetLayout ? widget.DWG_DECOR.widgetLayout : 'default';


	useEffect(() =>
		{
			findTagColor();
		},
		[]
	);


	useEffect(() =>
		{
			findTagColor();
		},
		[widget, value]
	);


	const findTagColor = () =>
	{
		const lowThresholdColor = widgetColorArray[0];
		const highThresholdColor = widgetColorArray[1];

		if (lowThresholdCrossed && lowThresholdColor)
		{
			setTagColor(lowThresholdColor);
			setTitle(utils.translate('componentWidgetClassicValue.lower') + `(${lowThreshold})`);
		}

		else if (highThresholdCrossed && highThresholdColor)
		{
			setTagColor(highThresholdColor);
			setTitle(utils.translate('componentWidgetClassicValue.higher') + `(${highThreshold})`);
		}

		else
		{
			setTagColor(undefined);
			setTitle(undefined);
		}
	}

	
	const image = props.getWidgetImage(value)[0] &&
		<img
			src={props.getWidgetImage(value)[0]}
			alt=''
			style={{ width: `${Math.min(size.height, size.width) / 2}px`, maxWidth: '100%' }}
		/>;

	const info = 
		<div>
			{props.getWidgetImage(value)[1] &&
			<div className='title'>
				{props.getWidgetImage(value)[1]}
			</div>}
			<div className='title'>
				{props.deviceLabel}
			</div>
			<div className='label'>
				{label}
			</div>
		</div>;

	const data = typeof config !== 'undefined' &&
		<div>
			<div className='value'>
				{value !== undefined &&
				<Tooltip
					title={title}
				>
					<Tag
						color={tagColor}
						className={
							anyThresholdCrossed ?
							'pulsating-tag' : ''
						}
						style={{
							height: 30,
							paddingTop: 4,
							fontSize: getWidgetFontSize(),
						}}
					>
						{value} {configUnit}
						{showHelp &&
						<Help/>}
					</Tag>
				</Tooltip>}
			</div>
			{showLastDate &&
			<div className='date'>
				{config.date}
			</div>}
		</div>;
	
	return (

		<div
			id={getWidgetId + '_CONTENT'}
			className={'CONTENT CLASSIC_VALUE CLASSIC_VALUE_CONTENT'}
		>
            <div className='value-content'>
				{layout === 'default' &&
				<>
					{image}
					{info}
					{data}
				</>}

				{layout === 'col-2' &&
				<>
					{info}
					<div className='DWG_DIS_COL-2'>
						{image}
						{data}
					</div>
				</>}

				{layout === 'col-3' &&
				<div className='DWG_DIS_COL-3'>
					{info}
					{image}
					{data}
				</div>}

				{typeof config === 'undefined' &&
				<>
					<NoData></NoData>
				</>}

            </div>
        </div>
    );
}


export default ClassicValue;
