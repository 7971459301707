import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Value from './Value/Value';
import Html from './Html/Html';
import Chart from './Chart/Chart';
import Piechart from './Piechart/Piechart';
import Gauge from './Gauge/Gauge';
import Map from './Map/Map';
import Sitemap from './Sitemap/Sitemap';
import SitemapMarker from './Sitemap/SitemapMarker/SitemapMarker';
import Table from './Table/Table';
import SingleDevice from './SingleDevice/SingleDevice';
import ScheduledCommand from './Scheduler/ScheduledCommand';
import Scheduler from './Scheduler/Scheduler';
import AlarmRule from './AlarmRule/AlarmRule';

import { request } from 'Lib/request';
import { utils } from 'Lib/utils';

import './Configure.css';


class Configure extends Component {

	constructor(props) {
		super(props);

		this.state =
		{
			configuration: {},
			allDevices: [],
			filteredDevices: [],
			categoryFilter: [],
			containerFilter: [],
			groupFilter: [],
			loading: false,
			currentEvent: null
		}
	}


	componentDidMount = () => {
		this.setState({
			allDevices: this.props.allDevices,
			filteredDevices: this.props.allDevices
		});
	}


	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.allDevices !== undefined && prevProps.allDevices !== this.props.allDevices) {
			this.setState({
				allDevices: this.props.allDevices,
				filteredDevices: this.props.allDevices
			});
		}
	}

	toggleModal = () => {
		this.setState({
			configuration: {},
			categoryFilter: [],
			containerFilter: [],
			groupFilter: [],
			filteredDevices: this.props.allDevices
		});

		this.props.toggleModal();
	}


	configure = () => {
		let params = this.state.configuration;
		if(params.decor) params.decor = JSON.stringify(params.decor);
		this.setState({ loading: true }, () => {
			request.patch(
				'widget/' + this.props.widget.DWG_ID,
				params,
				(res) => {
					//this.props.saveWidgetToState(this.props.widget.DWG_ID); <= TODO not working properly. Fix and use instead of getWidgets for performance boost
					if (this.props.widget.DWG_TYPE === 'DWG_scheduler') {
						this.props.toggleModalConfigureScheduler();
						this.props.getWidgets();
					} else {
						this.props.getWidgets();
						this.toggleModal();
					}
				},
				(e) => {
					utils.openNotification(
						'error',
						utils.translate('componentConfigure.cannot'),
						utils.translate('generic.rights'),
						'bottomRight',
						5
					);
				},
				() => {
					this.setState({ loading: false })
				}
			);
		});
	}


	configureScheduledCommand = () => {
		let params = this.state.configuration;
		this.props.setisLoaded(false);
		this.setState({ loading: true }, () => {
			request.post(
				'scheduledcommand',
				params,
				(res) => {
					//this.props.saveWidgetToState(this.props.widget.DWG_ID); <= TODO not working properly. Fix and use instead of getWidgets for performance boost
					this.props.getDevicesToDisplay();
					this.toggleModal();
				},
				(e) => {
					utils.openNotification(
						'error',
						utils.translate('componentConfigure.cannot'),
						utils.translate('generic.rights'),
						'bottomRight',
						5
					);
				},
				() => {
					this.setState({ loading: false });
					this.props.setisLoaded(true);
					}
			);
		});
	}


	modifyScheduledCommand = () => {
		let params = this.state.configuration;
		this.props.setisLoaded(false);
		this.setState({ loading: true }, () => {
			request.patch(
				'scheduledcommand/' + this.props.eventId,
				params,
				(res) => {
					//this.props.saveWidgetToState(this.props.widget.DWG_ID); <= TODO not working properly. Fix and use instead of getWidgets for performance boost
					this.props.setExistingEvent(undefined);
					this.toggleModal();
				},
				(e) => {
					utils.openNotification(
						'error',
						utils.translate('componentConfigure.cannot'),
						utils.translate('generic.rights'),
						'bottomRight',
						5
					);
				},
				() => {
					this.setState({ loading: false });
					this.props.setisLoaded(true);
				}
			);
		});
	}


	toggleModalScheduler = () => {
		this.setState({
			configuration: {},
			categoryFilter: [],
			containerFilter: [],
			groupFilter: [],
			filteredDevices: this.props.allDevices
		});

		this.props.toggleModalConfigureScheduler();
	}


	onConfigurationChange = (configuration) => {
		if (JSON.stringify(this.state.configuration) !== JSON.stringify(configuration))
		{
			this.setState({ configuration: configuration });
		}
	}


	handleContainerFilterChange = (value) => {
		this.setState({ containerFilter: value }, () => {
			this.filterDevices();
		});
	}


	handleCategoryFilterChange = (value) => {
		this.setState({ categoryFilter: value }, () => {
			this.filterDevices();
		});
	}

	handleGroupFilterChange = (value) => {
		this.setState({ groupFilter: value }, () => {
			this.filterDevices();
		});
	}


	filterDevices = () => {
		const filtering = this.state.containerFilter.length > 0 || this.state.categoryFilter.length > 0 || this.state.groupFilter.length > 0;

		if (this.state.allDevices !== undefined && this.state.categoryFilter !== undefined && this.state.containerFilter !== undefined && this.state.groupFilter !== undefined) {
			let stateDevices = [...this.state.allDevices];
			let filteredDevices = [...stateDevices];

			if (this.state.categoryFilter.length > 0) {
				filteredDevices = filteredDevices.filter(stateDevice => this.state.categoryFilter.includes(stateDevice.category_id));
			}

			if (this.state.containerFilter.length > 0) {
				filteredDevices = filteredDevices.filter(filteredDevice => this.state.containerFilter.includes(filteredDevice.container_id));
			}

			if(this.state.groupFilter.length > 0) {
				filteredDevices = filteredDevices.filter(filteredDevice => {
					let test = false;
					this.state.groupFilter.forEach(group => {
						const groupObject = this.props.allGroups.find(g => g.id === group);
						if (groupObject && groupObject.devices.includes(filteredDevice.id))
						{
							test = true;
							return;
						}
					});
					return test;
				});
			}

			this.setState({ filteredDevices: filtering ? filteredDevices : stateDevices });
		}
	}


	render() {
		const isBooleanValue =
			this.props.widget.DWG_TYPE === 'DWG_boolean' ||
			this.props.widget.DWV_TYPE === 'boolean';

		const isIndexValue =
			this.props.widget.DWG_TYPE === 'DWG_index' ||
			this.props.widget.DWV_TYPE === 'index';

		const onlyAllowNumericAttributes = isBooleanValue || isIndexValue;
		const isClassicValue = !onlyAllowNumericAttributes;
		const widgetValue = this.props.widget.DWV_TYPE !== undefined ? this.props.widget.DWV_TYPE : this.props.widget.DWG_TYPE.replace('DWG_', '');

		return (
			<>
				<Modal onMouseDown={e => e.stopPropagation()} isOpen={this.props.modal} toggle={this.toggleModal} size='xl'>

					<ModalHeader toggle={this.toggleModal}>
						<FontAwesomeIcon className='widget-icon' icon='cog' />
						{this.props.widget.DWG_TYPE !== 'DWG_value' && this.props.widget.DWG_TYPE !== 'DWG_scheduler' ? utils.translate('widgetDescription.' + this.props.widget.DWG_TYPE.replace('DWG_', '')) 
						: this.props.widget.DWG_TYPE === 'DWG_scheduler' ? utils.translate('widgetDescription.scheduledCommand')
						: utils.translate('widgetDescription.' + widgetValue)}
					</ModalHeader>

					<ModalBody>
					{(this.props.widget.DWG_TYPE === 'DWG_status' ||
						this.props.widget.DWG_TYPE === 'DWG_camera') &&
						<SingleDevice
							type={this.props.widget.DWG_TYPE === 'DWG_camera' ? 'camera' : 'status'}
							block={this.props.block}
							widget={this.props.widget}
							configuration={this.state.configuration}
							devices={this.state.filteredDevices}
							containers={this.props.allContainers}
							categories={this.props.allCategories}
							groups={this.props.allGroups}
							containerFilter={this.state.containerFilter}
							categoryFilter={this.state.categoryFilter}
							groupFilter={this.state.groupFilter}
							isCategoryDashboard={this.props.isCategoryDashboard}
							loading={this.props.loading}
							api_getDevices={this.props.api_getDevices}
							saveConfiguration={this.onConfigurationChange}
							handleContainerFilterChange={this.handleContainerFilterChange}
							handleCategoryFilterChange={this.handleCategoryFilterChange}
							handleGroupFilterChange={this.handleGroupFilterChange}
						/>}
						{(this.props.widget.DWG_TYPE === 'DWG_value' ||
							this.props.widget.DWG_TYPE === 'DWG_boolean' ||
							this.props.widget.DWG_TYPE === 'DWG_index') &&
							<Value
								widget={this.props.widget}
								block={this.props.block}
								configuration={this.state.configuration}
								devices={this.state.filteredDevices}
								containers={this.props.allContainers}
								categories={this.props.allCategories}
								groups={this.props.allGroups}
								containerFilter={this.state.containerFilter}
								categoryFilter={this.state.categoryFilter}
								groupFilter={this.state.groupFilter}
								isCategoryDashboard={this.props.isCategoryDashboard}
								loading={this.props.loading}
								onlyAllowNumericAttributes={onlyAllowNumericAttributes}
								isClassicValue={isClassicValue}
								isBooleanValue={isBooleanValue}
								isIndexValue={isIndexValue}
								api_getDevices={this.props.api_getDevices}
								saveConfiguration={this.onConfigurationChange}
								handleContainerFilterChange={this.handleContainerFilterChange}
								handleCategoryFilterChange={this.handleCategoryFilterChange}
								handleGroupFilterChange={this.handleGroupFilterChange}
							/>}
						{this.props.widget.DWG_TYPE === 'DWG_gauge' &&
							<Gauge
								widget={this.props.widget}
								block={this.props.block}
								configuration={this.state.configuration}
								devices={this.state.filteredDevices}
								containers={this.props.allContainers}
								categories={this.props.allCategories}
								groups={this.props.allGroups}
								containerFilter={this.state.containerFilter}
								categoryFilter={this.state.categoryFilter}
								groupFilter={this.state.groupFilter}
								isCategoryDashboard={this.props.isCategoryDashboard}
								loading={this.props.loading}
								api_getDevices={this.props.api_getDevices}
								saveConfiguration={this.onConfigurationChange}
								handleContainerFilterChange={this.handleContainerFilterChange}
								handleCategoryFilterChange={this.handleCategoryFilterChange}
								handleGroupFilterChange={this.handleGroupFilterChange}
							/>}
						{this.props.widget.DWG_TYPE === 'DWG_table' &&
							<Table
								widget={this.props.widget}
								block={this.props.block}
								configuration={this.state.configuration}
								devices={this.state.filteredDevices}
								containers={this.props.allContainers}
								categories={this.props.allCategories}
								groups={this.props.allGroups}
								containerFilter={this.state.containerFilter}
								categoryFilter={this.state.categoryFilter}
								groupFilter={this.state.groupFilter}
								isCategoryDashboard={this.props.isCategoryDashboard}
								loading={this.props.loading}
								api_getDevices={this.props.api_getDevices}
								saveConfiguration={this.onConfigurationChange}
								handleContainerFilterChange={this.handleContainerFilterChange}
								handleCategoryFilterChange={this.handleCategoryFilterChange}
								handleGroupFilterChange={this.handleGroupFilterChange}
							/>}
						{this.props.widget.DWG_TYPE === 'DWG_command' &&
							<SingleDevice
								type='command'
								widget={this.props.widget}
								block={this.props.block}
								configuration={this.state.configuration}
								devices={this.state.filteredDevices}
								containers={this.props.allContainers}
								categories={this.props.allCategories}
								groups={this.props.allGroups}
								containerFilter={this.state.containerFilter}
								categoryFilter={this.state.categoryFilter}
								groupFilter={this.state.groupFilter}
								isCategoryDashboard={this.props.isCategoryDashboard}
								loading={this.props.loading}
								api_getDevices={this.props.api_getDevices}
								saveConfiguration={this.onConfigurationChange}
								handleContainerFilterChange={this.handleContainerFilterChange}
								handleCategoryFilterChange={this.handleCategoryFilterChange}
								handleGroupFilterChange={this.handleGroupFilterChange}
							/>}
						{this.props.widget.DWG_TYPE === 'DWG_piechart' &&
							<Piechart
								widget={this.props.widget}
								block={this.props.block}
								configuration={this.state.configuration}
								devices={this.state.filteredDevices}
								containers={this.props.allContainers}
								categories={this.props.allCategories}
								groups={this.props.allGroups}
								containerFilter={this.state.containerFilter}
								categoryFilter={this.state.categoryFilter}
								groupFilter={this.state.groupFilter}
								isCategoryDashboard={this.props.isCategoryDashboard}
								loading={this.props.loading}
								api_getDevices={this.props.api_getDevices}
								saveConfiguration={this.onConfigurationChange}
								handleContainerFilterChange={this.handleContainerFilterChange}
								handleCategoryFilterChange={this.handleCategoryFilterChange}
								handleGroupFilterChange={this.handleGroupFilterChange}
							/>}
						{(this.props.widget.DWG_TYPE === 'DWG_areachart' || this.props.widget.DWG_TYPE === 'DWG_columnchart') &&
							<Chart
								widget={this.props.widget}
								block={this.props.block}
								configuration={this.state.configuration}
								devices={this.state.filteredDevices}
								containers={this.props.allContainers}
								categories={this.props.allCategories}
								groups={this.props.allGroups}
								containerFilter={this.state.containerFilter}
								categoryFilter={this.state.categoryFilter}
								groupFilter={this.state.groupFilter}
								isCategoryDashboard={this.props.isCategoryDashboard}
								loading={this.props.loading}
								api_getDevices={this.props.api_getDevices}
								saveConfiguration={this.onConfigurationChange}
								handleContainerFilterChange={this.handleContainerFilterChange}
								handleCategoryFilterChange={this.handleCategoryFilterChange}
								handleGroupFilterChange={this.handleGroupFilterChange}
							/>}
						{this.props.widget.DWG_TYPE === 'DWG_html' &&
							<Html
								{...this.props}
								configuration={this.state.configuration}
								saveConfiguration={this.onConfigurationChange}
							/>}
						{this.props.widget.DWG_TYPE === 'DWG_map' &&
							<Map
								widget={this.props.widget}
								block={this.props.block}
								configuration={this.state.configuration}
								devices={this.state.filteredDevices}
								containers={this.props.allContainers}
								categories={this.props.allCategories}
								groups={this.props.allGroups}
								geoZones={this.props.allGeoZones}
								containerFilter={this.state.containerFilter}
								categoryFilter={this.state.categoryFilter}
								groupFilter={this.state.groupFilter}
								isCategoryDashboard={this.props.isCategoryDashboard}
								dashboardCategory={this.props.category}
								loading={this.props.loading}
								api_getDevices={this.props.api_getDevices}
								saveConfiguration={this.onConfigurationChange}
								handleContainerFilterChange={this.handleContainerFilterChange}
								handleCategoryFilterChange={this.handleCategoryFilterChange}
								handleGroupFilterChange={this.handleGroupFilterChange}
							/>}
						{this.props.widget.DWG_TYPE === 'DWG_sitemap' &&
							<Sitemap
								widget={this.props.widget}
								block={this.props.block}
								devices={this.state.filteredDevices}
								isCategoryDashboard={this.props.isCategoryDashboard}
								dashboardCategory={this.props.category}
								configuration={this.state.configuration}
								loading={this.props.loading}
								api_getDevices={this.props.api_getDevices}
								saveConfiguration={this.onConfigurationChange}
							/>}
						{this.props.widget.DWG_TYPE === 'DWG_sitemapMarker' &&
							<SitemapMarker
								widget={this.props.widget}
								block={this.props.block}
								configuration={this.state.configuration}
								devices={this.state.filteredDevices}
								category={this.props.category}
								containers={this.props.allContainers}
								categories={this.props.allCategories}
								groups={this.props.allGroups}
								containerFilter={this.state.containerFilter}
								categoryFilter={this.state.categoryFilter}
								groupFilter={this.state.groupFilter}
								isCategoryDashboard={this.props.isCategoryDashboard}
								loading={this.props.loading}
								api_getDevices={this.props.api_getDevices}
								saveConfiguration={this.onConfigurationChange}
								handleContainerFilterChange={this.handleContainerFilterChange}
								handleCategoryFilterChange={this.handleCategoryFilterChange}
								handleGroupFilterChange={this.handleGroupFilterChange}
							/>}
						{this.props.widget.DWG_TYPE === 'DWG_alarmRule' &&
						<AlarmRule
							widget={this.props.widget}
							block={this.props.block}
							configuration={this.state.configuration}
							devices={this.state.filteredDevices}
							containers={this.props.allContainers}
							categories={this.props.allCategories}
							groups={this.props.allGroups}
							containerFilter={this.state.containerFilter}
							categoryFilter={this.state.categoryFilter}
							groupFilter={this.state.groupFilter}
							isCategoryDashboard={this.props.isCategoryDashboard}
							loading={this.props.loading}
							api_getDevices={this.props.api_getDevices}
							saveConfiguration={this.onConfigurationChange}
							handleContainerFilterChange={this.handleContainerFilterChange}
							handleCategoryFilterChange={this.handleCategoryFilterChange}
							handleGroupFilterChange={this.handleGroupFilterChange}
						/>}
						{this.props.widget.DWG_TYPE === 'DWG_scheduler' &&
							<ScheduledCommand
								{...this.props}
								type='scheduler'
								widget={this.props.widget}
								block={this.props.block}
								configuration={this.state.configuration}
								devices={this.props.schedulerDevices}
								categories={this.props.schedulerCategories}
								containers={this.props.allContainers}
								groups={this.props.allGroups}
								containerFilter={this.state.containerFilter}
								categoryFilter={this.state.categoryFilter}
								groupFilter={this.state.groupFilter}
								isCategoryDashboard={this.props.isCategoryDashboard}
								loading={this.props.loading}
								api_getDevices={this.props.api_getDevices}
								saveConfiguration={this.onConfigurationChange}
								handleContainerFilterChange={this.handleContainerFilterChange}
								handleCategoryFilterChange={this.handleCategoryFilterChange}
								handleGroupFilterChange={this.handleGroupFilterChange}
								newEvent={this.props.newEvent}
								existingEvent={this.props.existingEvent}
								getWidgets={this.props.getWidgets}
								toggleModal={this.toggleModal}
								getScheduledCommand={this.getScheduledCommand}
								currentEvent={this.state.currentEvent}
								setExistingEvent={this.props.setExistingEvent}
								setNewEvent={this.props.setNewEvent}
								schedulerCategories={this.props.schedulerCategories}
							/>}
					</ModalBody>

					<ModalFooter>
						<Button
							className='modalButton'
							icon='close-circle'
							onClick={this.toggleModal}
						>
							{utils.translate('generic.cancel')}
						</Button>
						<Button
							className='modalButton'
							type='primary'
							icon='save'
							loading={this.props.loading}
							onClick={this.props.widget.DWG_TYPE === 'DWG_scheduler' ? !this.props.existingEvent ? this.configureScheduledCommand : this.modifyScheduledCommand : this.configure}
						>
							{utils.translate('generic.save')}
						</Button>
					</ModalFooter>

				</Modal>

				<Modal onMouseDown={e => e.stopPropagation()} isOpen={this.props.modalConfigureScheduler} toggle={this.toggleModalScheduler} size='xl'>

					<ModalHeader toggle={this.toggleModalScheduler}>
						<FontAwesomeIcon className='widget-icon' icon='cog' />
						{this.props.widget.DWG_TYPE !== 'DWG_value' ? utils.translate('widgetDescription.' + this.props.widget.DWG_TYPE.replace('DWG_', '')) :
							utils.translate('widgetDescription.' + widgetValue)}
					</ModalHeader>

					<ModalBody>
						<Scheduler
							type='scheduler'
							widget={this.props.widget}
							block={this.props.block}
							configuration={this.state.configuration}
							devices={this.state.filteredDevices}
							containers={this.props.allContainers}
							categories={this.props.allCategories}
							groups={this.props.allGroups}
							containerFilter={this.state.containerFilter}
							categoryFilter={this.state.categoryFilter}
							groupFilter={this.state.groupFilter}
							isCategoryDashboard={this.props.isCategoryDashboard}
							loading={this.props.loading}
							api_getDevices={this.props.api_getDevices}
							saveConfiguration={this.onConfigurationChange}
							handleContainerFilterChange={this.handleContainerFilterChange}
							handleCategoryFilterChange={this.handleCategoryFilterChange}
							handleGroupFilterChange={this.handleGroupFilterChange}
							getWidgets={this.props.getWidgets}
							toggleModal={this.toggleModal}
						/>
					</ModalBody>

					<ModalFooter>
						<Button
							className='modalButton'
							icon='close-circle'
							onClick={this.toggleModalScheduler}
						>
							{utils.translate('generic.cancel')}
						</Button>
						<Button
							className='modalButton'
							type='primary'
							icon='save'
							loading={this.props.loading}
							onClick={this.configure}
						>
							{utils.translate('generic.save')}
						</Button>
					</ModalFooter>

				</Modal>
			</>
		);
	}
}


export default Configure;
