import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Select, Tooltip, Icon } from 'antd';

import SuperSelect from 'Components/Studio/StudioLib/SuperSelect/SuperSelect';
import LabelItem from '../../FormItems/LabelItem/LabelItem';

import { containerPH, categoryPH, groupPH } from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';
import { utils } from 'Lib/utils';

const { Option } = Select;


const DeviceSection = (props) =>
{
	return (

		<>
			{props.type !== 'scheduledCommand' &&
			<FormGroup>
				<Row>
					<LabelItem
						iconType='mobile'
						label={props.label}
					/>
					<Col md={{size: 9}}>
						<Row>
							<Col md={{size: 6}}>
								<SuperSelect
									value={props.categoryFilter}
									onChange={props.handleCategoryFilterChange}
									showSearch
									showArrow
									allowClear={true}
									optionFilterProp="children"
									style={{ width: '100%' }}
									placeholder={categoryPH}
									mode="multiple"
									disabled={props.disabled}
									filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
								{props.categories.map((category) =>
									<Option key={category.id} value={category.id}>{category.label}</Option>
								)}
								</SuperSelect>
							</Col>
							<Col md={{size: 6}}>
								<SuperSelect
									value={props.containerFilter}
									onChange={props.handleContainerFilterChange}
									showSearch
									showArrow
									allowClear={true}
									optionFilterProp="children"
									style={{ width: '100%' }}
									placeholder={containerPH}
									mode="multiple"
									disabled={props.disabled}
									filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
								{props.containers.map((container) =>
									<Option key={container.id} value={container.id}>{container.name}</Option>
								)}
								</SuperSelect>
							</Col>
						</Row>
						<Row>
							<Col md={{size: 6}}>
								<SuperSelect
									value={props.groupFilter}
									onChange={props.handleGroupFilterChange}
									showSearch
									showArrow
									allowClear={true}
									optionFilterProp="children"
									style={{ width: '100%' }}
									placeholder={groupPH}
									mode="multiple"
									disabled={props.disabled}
									filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
								{props.groups.map((group) =>
									<Option key={group.id} value={group.id}>{group.label}</Option>
								)}
								</SuperSelect>
							</Col>
						</Row>
					</Col>
				</Row>
			</FormGroup>}
			<FormGroup>
				<Row>
					<Col md={{size: 3}}>
						{props.devices.length > 0 && <small>{props.entries} {props.entries === 1 ? utils.translate('componentDeviceSection.entry') : utils.translate('componentDeviceSection.entries')}</small>}
						{props.devices.length === 0 && !props.loading && <small>{utils.translate('componentDeviceSection.noEntries')}</small>}
						<Tooltip title={props.loading ? utils.translate('componentDeviceSection.loading') : utils.translate('componentDeviceSection.refresh')}>
							<Icon
								type='sync'
								style={{marginLeft: '15px', color: '#1890ff'}}
								onClick={props.loading ? undefined : props.api_getDevices}
								spin={props.loading}
							/>
						</Tooltip>
					</Col>
					<Col md={{size: 9}}>
						<SuperSelect
							ref={props.deviceSelectRef}
							value={props.selectedDevices}
							onChange={props.handleDeviceChange}
							allowClear={props.allowClearDeviceSelect}
							showSearch
							optionFilterProp='children'
							mode={props.deviceSelectMode}
							style={{ width: '100%' }}
							placeholder={props.deviceSelectPlaceholder}
							loading={props.loading}
							maxTagCount={20}
							disabled={props.loading}
							showArrow={true}
							filterOption={(input, option) => option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0}
						>
						{props.devices.map((device) =>
							<Option key={device.id} value={device.id}>{device.label} {props.checkGeoloc && props.showGeolocIcon(device)}</Option>
						)}
						</SuperSelect>
					</Col>
				</Row>
			</FormGroup>
		</>
	)
};


export default DeviceSection;
