import React from 'react';
import { Col, Card, Icon, Tag, Divider } from 'antd';

import { utils } from 'Lib/utils';
import { withTranslation } from 'react-i18next';

const statusToColor = {
	'CONNECT': 'green',
	'STARTED': 'green',
	'STOPPED': 'volcano',
	'UNAVAILABLE': 'purple'
};


const DeviceInfo = (props) =>
{
	let deviceLabel = props.isAlternative ? props.device.label + props.t('componentDeviceInfo.geo') : props.device.label;
	
	return (

		<Col
			xl={12}
			lg={24}
			md={24}
			sm={24}
			xs={24}
			key={props.device.id + 'text'}
			style={{ padding: 12 }}
		>
			<Card
				bordered={false}
			>
				<h3>
					<Icon
						type='tag'
						className='greyIcon'
					/>
					{deviceLabel}
				</h3>
				<Divider/>
				<p><Tag color={statusToColor[props.device.status]}>{utils.translate(props.device.status)}</Tag></p>
				<p>
					<Icon
						type='deployment-unit'
						className='greyIcon'
					/>
					{props.device.category ? props.device.category.label : <i>{utils.translate('componentDeviceInfo.noCat')}</i>}
				</p>
				<p>
					<Icon
						type='container'
						className='greyIcon'
					/>
					{props.device.container ? props.device.container.name : <i>{utils.translate('componentDeviceInfo.noCtr')}</i>}
				</p>
				<p>
					<Icon
						type='global'
						className='greyIcon'
					/>
					{props.device.timezone}
				</p>
				<p>
					<Icon
						type='environment'
						className='greyIcon'
					/>
					{props.lat !== 'undefined' && 'Lat: ' + String(props.lat).substring(0,7)}
					{props.lng !== 'undefined' && ' - Lng: ' + String(props.lng).substring(0,7)}
					{props.alt && props.alt !== 'undefined' && ' - Alt: ' + String(props.alt).substring(0,7)}
				</p>
			</Card>
		</Col>
	);
};


export default withTranslation()(DeviceInfo);
