
export const CURRENT_VERSION = '2.9.8p4';
export const MAX_DATATYPES = 30;
export const MAX_MARKER_DATA_ELEMENTS = 5;
export const MAX_VISIBLE_STRING = 20;
export const DEFAULT_PRECISION = 2;
export const DEFAULT_REACT_APP_CREDITS = 'Powered By IoT App Studio®';
export const GMAP_KEY = 'AIzaSyD7fN7-o-RL79Pp406ViBjAnGq8bgrvWLM';
export const MODE = 'dea';


export const getConfig = (script) => // build configs
{
	const isProduction = process.env.NODE_ENV === 'production' ? true : false;

	if (isProduction)
	{
       	const meta = document.getElementById('meta');
		const params = btoa(window.location.href);
		const DNS = window.location.origin;

		script = document.createElement('script');
		script.id = 'external_config';
		script.type = 'text/javascript';
		script.src = DNS + '/.well-known/appstudio/config?url=' + params; // pass parameters to external_config
		//script.src = 'https://cs.pre.vm2m.net/.well-known/appstudio/config?url=' + params; // test local build
		script.async = false;

		document.head.insertBefore(script, meta);
	}

	else // local
	{
		window.ENV = {
			REACT_APP_ID: getUrlAppId(),
			REACT_APP_API_URL: process.env.REACT_APP_API_URL,
			REACT_APP_CS_URL: process.env.REACT_APP_CS_URL,
			REACT_APP_PREFIX: process.env.REACT_APP_PREFIX,
			REACT_APP_CREDITS: process.env.REACT_APP_CREDITS
		};
	};
}


export const transformConfig = (callback) =>
{
	window.addEventListener('load', () => // wait for window object to build before accessing window.ENV
	{
		window.ENV.REACT_APP_PREFIX = window.ENV.REACT_APP_PREFIX ? '/' + window.ENV.REACT_APP_PREFIX : '';
		window.REACT_APP_CREDITS = window.REACT_APP_CREDITS || DEFAULT_REACT_APP_CREDITS;
		callback();
	});
}


function getUrlAppId() // get dap_id from url if mode change when accessing different app with link
{
	const path_array = window.location.pathname.split('/');
	let dap_id = '';

	for (let i = 1; i < path_array.length; i++)
	{
		if (!isNaN(path_array[i]))
		{
			dap_id = path_array[i];
			break;
		};
	};

	return dap_id;
}
