import React from 'react';
import { matchPath } from 'react-router';
import { Select, notification, Tooltip } from 'antd';
import history from './history';
import moment from 'moment';
import Translation from 'Components/Studio/Translation/Translation.js';
import i18next from 'i18next';

import { MAX_VISIBLE_STRING, MODE } from 'Config/config';

const { Option } = Select;


export const utils =
{
    didWidgetResize(prevProps, props)
	{
        let result = false;
        Object.keys(prevProps.layouts).forEach((key) =>
		{
            prevProps.layouts[key].forEach((item, subkey) =>
			{
                if (
                    typeof props.layouts[key] === 'undefined' ||
                    item.i !== props.widget.DBK_ID
                ) { return false; }

                let stateItem = props.layouts[key][subkey];

                if (typeof stateItem === 'undefined' || typeof item === 'undefined') { return false; }
                if(stateItem.w !== item.w || stateItem.h !== item.h) { result = true; }
            });
        });

        return result;
    },


    getSelectedOptionsFromEvent(event)
	{
        let options = event.target.options;
        let selectedOptionsStr = '';

        for (let i = 0, l = options.length; i < l; i++)
		{
            if (options[i].selected)
			{
                selectedOptionsStr += options[i].value
                selectedOptionsStr += ',';
            }
        }

        selectedOptionsStr = selectedOptionsStr.slice(0, -1);

        return selectedOptionsStr;
    },


    isValidJson(string)
	{
        if (string === null) { return false; }

        try
		{
            JSON.parse(string);
        }
		catch(e)
		{
            return false;
        }

        return true;
    },


    setRefresh(refreshFreq, cb)
	{
        if (parseFloat(refreshFreq) !== 0)
		{
            return setInterval(cb, refreshFreq *1000*60); // refresh freq is in minutes
        }

        return null;
    },


    clearRefresh(timerId)
	{
        clearInterval(timerId);
    },


    getBaseUrl()
	{
		let url = window.ENV.REACT_APP_PREFIX + '/' + window.ENV.REACT_APP_ID + '/' + MODE + '/';
		return url;
    },


	goToMode(mode, pageId)
	{
		let currentUrl = utils.getBaseUrl() + pageId;
		let newUrl = currentUrl.replace(utils.isConfigMode() ? 'config' : MODE, mode);

		history.push(newUrl);
	},


    isConfigMode()
	{
		const url = window.location.pathname.replace(window.ENV.REACT_APP_PREFIX, '');
        const { params } = matchPath(url, {
            path: "/:dap_id/:mode"
        });
        return params.mode === 'config' ? true : false;
	},
	

	countPrefix(prefix)
	{
		return prefix ? prefix.split('/').length - 1 : 0;
	},


	pathConstruct(prefix) // construct prefixed url
	{
		let pathString = '/:dap_id/:mode';
		let countPrefix = prefix ? this.countPrefix(prefix) : 0;

		if (countPrefix && countPrefix > 0) 
		{
			for (var i = 0; i < countPrefix; i++)
			{ 
				pathString = '/:prefix' + pathString;
			};
		};
		
		return pathString;
	},


	openNotification(type, message, description, placement, duration = 1.5)
	{
		notification[type]({
			message: message,
			description: description,
			placement: placement,
			duration: duration
		});
	},


	isCategoryDashboard(page)
	{
		return page && page.CAT_ID && page.CAT_ID !== "0" ? true : false;
	},


	isMobileVersion(window)
	{
		return window.innerWidth < 764 ? true : false;
	},


	capitalizeFirstLetter(string)
	{
		if (string !== undefined && string !== '')
		{
			return string.charAt(0).toUpperCase() + string.slice(1);
		}
	},


	getOptionsFromArray(options)
	{
		return options.map(option => {
			return (<Option key={option.key} value={option.value} title={option.title}>{option.label}</Option>);
		});
	},


	getValueFromTags(label, device)
	{
		let newLabel = label; // 'text [ATTRIBUTE_TOTO] [ATTRIBUTE_TITI] text'
		const regex  = /\[([^\]]*)]/g;
		let matches = [];

		matches = label.match(regex) || []; // [ '[ATTRIBUTE_TOTO]', '[ATTRIBUTE_TITI]' ]
		matches = matches.map(match => { return match.slice(1, -1) }); // [ 'ATTRIBUTE_TOTO', 'ATTRIBUTE_TITI' ]

		matches.forEach(match =>
		{
			const fullTag = '[' + match + ']'; // '[ATTRIBUTE_TOTO]'
			const tagType = match.split(/_(.+)/)[0]; // 'ATTRIBUTE'
			const tagValue = match.split(/_(.+)/)[1]; // 'TOTO'

			if (tagType !== '')
			{
				switch (tagType)
				{
					case 'ATTRIBUTE':

						const attributes = device.attributes;

						if (attributes !== undefined && tagValue !== undefined)
						{
							const attribute = attributes.find(attrib => attrib.code === tagValue); // attribute has tag code (may be undefined)

							if (attribute !== undefined)
							{
								const attributeValue = attribute.value; // for example '1234'
								newLabel = newLabel.replace(fullTag, attributeValue); // 'text 1234 [ATTRIBUTE_TITI] text'
							}
							else
							{
								newLabel = newLabel.replace(fullTag, ''); // 'text [ATTRIBUTE_TITI] text'
							}
						}
						break;

					case 'TOKEN':

						newLabel = newLabel.replace(fullTag, sessionStorage.getItem('token'));
						break;

					default:
						break;
				}
			}
		});

		return newLabel;
	},


	getWidgetDateStop(widgetState, widgetProps, identifier)
	{
        var foreverString = '9999-12-31 00:00:00';
		var neverString = '0000-00-00 00:00:00';
        var foreverDate = new Date(foreverString);
        var foreverDatetime = moment(foreverDate).format('YYYY-MM-DD HH:mm:ss');
        var now = moment().format('YYYY-MM-DD HH:mm:ss');

        if (widgetState.currentDate) { return foreverDatetime; } // user has chosen to keep graph up-to-date

        else
		{ // end date is set
            if (widgetProps.widget[identifier] === foreverString)
			{ // graph was previously kept u-t-d (database value)
                if (widgetProps.configuration.date_stop)
				{ // something has been set in config; i.e. user has changed values
                    if (widgetProps.configuration.date_stop === foreverString) { return now; } // config value change was triggered by keep u-t-d checkbox
                    else return widgetProps.configuration.date_stop;
                }

                return now; // nothing set in config and no showable value in database, we'll show current date
            }
            else
			{ // database value is not 'forever'
                if (widgetProps.configuration.date_stop)
				{ // something has been set in config; i.e. user has changed values
                    if (widgetProps.configuration.date_stop === foreverString)
					{ // config value change was triggered by keep u-t-d checkbox
                        if (widgetProps.widget[identifier] && widgetProps.widget[identifier] !== neverString)
						{
							return widgetProps.widget[identifier];
						} // there is a value in database, it is not 'forever' nor 'never'
                        else return now;
                    }
                    else { return widgetProps.configuration.date_stop; } // config value makes sense, returning
                }
                else
				{
                    if (!widgetProps.widget[identifier] || widgetProps.widget[identifier] === neverString)
					{
						return now;
					}
                    return widgetProps.widget[identifier];
                }
            }
        }
    },


	getDatesFromPreset(widget, identifier)
	{
		let date = new Date();
		const format = 'YYYY-MM-DD HH:mm:ss';
		let startTime;
		let endTime;

		switch (widget[identifier + '_DATE_RANGE'])
		{
			case 'week':
				startTime = moment().startOf('week').format(format);
				endTime = moment().endOf('week').format(format);
				break;

			case 'month':
				startTime = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(format);
				endTime = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(format);
				break;

			case 'year':
				startTime = moment(new Date(date.getFullYear(), 0, 1)).format(format);
				endTime = moment(new Date(date.getFullYear(), 11, 31)).format(format);
				break;

			case 'yesterday':
				startTime = moment().subtract(1, 'days').startOf('day').format(format);
				endTime = moment().subtract(1, 'days').endOf('day').format(format);
				break;

			case 'today':
				startTime = moment().startOf('day').format(format);
				endTime = moment().endOf('day').format(format);
				break;

			case 'last_365_days':
				startTime = moment().subtract(365, 'days').format(format);
				endTime = moment().format(format);
				break;

			case 'last_30_days':
				startTime = moment().subtract(30, 'days').format(format);
				endTime = moment().format(format);
				break;

			case 'last_7_days':
				startTime = moment().subtract(7, 'days').format(format);
				endTime = moment().format(format);
				break;

			case 'last_24_hours':
				startTime = moment().subtract(24, 'hours').format(format);
				endTime = moment().format(format);
				break;

			case 'last_hour':
				startTime = moment().subtract(1, 'hours').format(format);
				endTime = moment().format(format);
				break;

			default: // custom date range
				startTime = widget[identifier + '_DATE_START'];
				endTime = widget[identifier + '_DATE_STOP'];
				break;
		}

		return {
			startTime: startTime,
			endTime: endTime
		}
	},


	toSubstring(string)
	{
		let newString = string.substring(0, MAX_VISIBLE_STRING);
		newString = string.length > MAX_VISIBLE_STRING ? <Tooltip title={string}> {newString.concat('...')} </Tooltip> : newString;

		return newString;
	},

	translate(string)
	{
		return <Translation language={string}/>
	},

	getLanguage(lang){
		lang = lang.split('_')[1].toLowerCase();
		this.handleLanguage(lang);
	},


	handleLanguage(lang)
	{
		i18next.changeLanguage(lang);
	},

	translateTypeFR(type){
		switch (type) {
			case 'Status':
				return 'État'
			case 'Value':
				return 'Valeur';
			case 'Index':
				return 'Index';
			case 'Boolean':
				return 'Booléen'
			case 'Gauge':
				return 'Jauge';
			case 'Piechart':
				return 'Camembert';
			case 'Table':
				return 'Tableau';
			case 'Areachart':
				return 'Graphique en Aires';
			case 'Columnchart':
				return 'Graphique en Bâtons';
			case 'Html':
				return 'Html';
			case 'Map':
				return 'Carte';
			case 'Sitemap':
				return 'Plan';
			case 'Command':
				return 'Commande';
			case 'AlarmRule':
				return "Règle d'Alarme";
			case 'Camera':
				return 'Caméra';
			case 'Scheduler':
				return 'Planificateur';
			default: 
				break;
		  }
	},

	getCurrentLanguage()
	{
		return i18next.language ||
		  (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
		  'en';
	},

	convertColorAlphaToHex(color, alpha)
	{
		if (color.length === 4) {
            color = '#' + color.substr(1,1) + color.substr(1,1) + color.substr(2,1) + color.substr(2,1) + color.substr(3,1) + color.substr(3,1);
			color = color.toLowerCase();
		}
		let opacity = Math.round(alpha / 100 * 255);
        opacity = opacity.toString(16);
        if(opacity.length === 1) opacity = '0' + opacity;
		return color + opacity.toString(16);
	},

	convertHexToColorAlpha(hex)
	{
		if (hex.length === 9) {
			const color = hex.substr(0,7);
			let alpha = hex.substr(7, 2);
			alpha = Math.round(parseInt(alpha, 16)/255*100);
			return {color: color, alpha: alpha};
		} else if (hex.length === 7) {
			return {color: hex, alpha: 100};
		}
		return {color: '#ffffff', alpha: 100};
	}
};
