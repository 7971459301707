import React, { useRef } from 'react';
import { Select } from 'antd';


const SmartSelect = (props) =>
{

	const {
		mode,
		options = [],
		value = [],
		onChange,
		showSearch,
		showArrow,
		allowClear,
		disabled,
		loading,
		placeholder,
		style,
	} = props;


	const _options = useRef([]);


	const getValue = () =>
	{
		const validatedValue = validateValue();

		storeOptionProps();

		return validatedValue;
	}


	const validateValue = () =>
	{
		let _value = [...value];

		if (optionsRemoved())
		{
			const optionKeys = options.map(o => o.key);
			let valueChanged = false;

			if (!optionKeys.length)
			{
				_value = [];
				valueChanged = true;
			}

			// else // not working
			// {
			// 	_value.forEach((item, index) =>
			// 	{
			// 		if (!optionKeys.includes(item))
			// 		{
			// 			_value.splice(index, 1);
			// 			valueChanged = true;
			// 		}
			// 	});
			// }

			if (valueChanged)
				onChange(_value);
		}

		return _value;
	}


	const optionsRemoved = () =>
	{
		return _options.current.length > options.length; // not working if options.length > 0
	}


	const storeOptionProps = () =>
	{
		_options.current = options;
	}


	return (

		<Select
			mode={mode}
			value={getValue()}
			onChange={onChange}
			showSearch={showSearch}
			showArrow={showArrow}
			allowClear={allowClear}
			disabled={disabled}
			loading={loading}
			placeholder={placeholder}
			style={style}
		>
			{options}
		</Select>
    );
};


export default SmartSelect;


// import SmartSelect from 'Components/Studio/StudioLib/SmartSelect/SmartSelect';
