import React from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { utils } from 'Lib/utils';

import './EmptyWidget.css';

const EmptyWidget = (props) =>
{

	return (

		<div className='noData' style={{ marginTop: props.size.height / 2 - 10 }}>
			<>
				<Tooltip placement='top' autoAdjustOverflow='true' title={utils.translate('componentEmptyWidget.build')}>
					<FontAwesomeIcon className='confButton smallConfButton' icon='cog' style={{ marginTop: '15px' }} onClick={props.toggleModalConfigure} />
				</Tooltip>
			</>
			<div style={{ marginTop: '10px' }}>
				<p style={{ fontSize: '15px' }}><i>{props.callToAction}</i></p>
			</div>
		</div>
	)
};


export default EmptyWidget;
