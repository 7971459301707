import { createStore } from "redux";
import rootReducer from "../reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {};

// Store the globals states of the application
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools()
);

export default store;
