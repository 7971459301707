import React, { useState, useEffect } from 'react';
import { Popover, Row, Col, Divider, Tag } from 'antd';

import { utils } from 'Lib/utils.js';
import { withTranslation } from 'react-i18next';


const statusToColor = {
	CONNECT: 'green',
	STARTED: 'green',
	STOPPED: 'volcano',
	UNAVAILABLE: 'purple',
};


const DeviceTooltip = (props) =>
{

	const {
		widget,
		device = {},
		children,
		hasDeviceProfile,
		useDataMarkers,
		showGeoloc,
	} = props;


	const [dataSources, setDataSources] = useState([]);


	useEffect(() =>
		{
			if (useDataMarkers)
			{
				let dataSources = getDataSources() || [];
				dataSources = dataSources.sort((a, b) => (a.isMaster ? -1 : 1)); // master 1st

				setDataSources(dataSources);
			}
		},
		[widget]
	);


	const getDataSources = () =>
	{
		const attributeSources = widget.ATR_IDS.flatMap(atrId =>
		{ // flatMap : used to push optionally (return [] to push nothing)
			if (device.attributes)
			{
				const attribute = device.attributes.find(a => a.atr_id === atrId.ID);

				return attribute ? {
					value: attribute.value,
					label: attribute.name,
					isMaster: atrId.IS_MASTER,
				} : [];
			}
		});

		const datatypeSources = widget.DTY_IDS.flatMap(dtyId =>
		{ // flatMap : used to push optionally (return [] to push nothing)
			if (device.configs)
			{
				const config = device.configs.find(c => c.dty_id === dtyId.ID);

				return config ? {
					value: config.value,
					label: config.label,
					unit: config.unite_D1,
					isMaster: dtyId.IS_MASTER,
				} : [];
			}
		});

		return [...attributeSources, ...datatypeSources];
	}


	const getContentBody = () =>
	{
		return (
			<>
				{useDataMarkers &&
				<Col
					span={24}
					className='text-center'
					style={{
						maxWidth: 250,
						overflow: 'auto',
					}}
				>
					{dataSources.map((source, index) =>
						source &&
						<small
							key={index}
							style={{ color: source.isMaster ? 'black' : '' }}
						>
							{source.label} : {source.value} {source.unit}<br/>
						</small>
					)}
				</Col>}
			</>
		);
	}


	const content = (
		<div
			style={{ width: 'auto' }}
		>
			<Row
				className='text-center'
			>
				<b>{props.isAlternative ? device.label + props.t('componentDeviceInfo.geo') : device.label}</b>
				<p style={{ marginBottom: 5 }}>{device.category && device.category.label}</p>
				<Tag
					color={statusToColor[device.status]}
				>
					<small>{utils.translate(`deviceStatus.${device.status}`)}</small>
				</Tag>
				<br/>
				{showGeoloc &&
				<small>
					{props.lat !== 'undefined' && 'Lat: ' + String(props.lat).substring(0,7)}
					{props.lng !== 'undefined' && ' - Lng: ' + String(props.lng).substring(0,7)}
					{props.alt && props.alt !== 'undefined' && ' - Alt: ' + String(props.alt).substring(0,7)}
				</small>}
			</Row>
			{dataSources.length > 0 && <Divider style={{ margin: 10 }}/>}
			<Row className='text-center'>
				{getContentBody()}
			</Row>
			<Row className='text-center'>
				{hasDeviceProfile &&
				<>
					<Divider style={{ margin: 10 }}/>
					<i>{utils.translate('componentDeviceTooltip.click')}</i>
				</>}
			</Row>
		</div>
	);


	return (

		<Popover
			content={content}
			trigger='hover'
			placement='top'
		>
			{children}
		</Popover>
	)
};


export default withTranslation()(DeviceTooltip);
