import React from 'react';
import { Modal, Button, Card, Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { utils } from 'Lib/utils';

import SaveButton from 'Components/Studio/StudioLib/SaveButton/SaveButton';

import AttributeEditSection from
	'Components/Studio/Dashboard/Widgets/Configure/FormSections/AttributeEditSection/AttributeEditSection';
import ContainerSection from
	'Components/Studio/Dashboard/Widgets/Configure/FormSections/ContainerSection/ContainerSection';

import { oneContainerPH } from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';


const AttributeEditorModal = (props) =>
{

	const {
		attributes,
		editorIsOpen,
		toggleEditor,
	} = props;

	const showAttributes = attributes && attributes.length > 0;

	const modalTitle = (
		<>
			<FontAwesomeIcon className="drawerIcon" icon='pencil-alt'/>
			{utils.translate('componentAttributeEditorModal.edit')}
		</>
	);


	return (

		<Modal
			title={modalTitle}
			visible={editorIsOpen}
			onCancel={toggleEditor}
			destroyOnClose
			footer={[
				<>
					<Button
						type='default'
						onClick={toggleEditor}
					>
						{utils.translate('generic.close')}
					</Button>
				</>
			]}
			width={900}
		>
			<Card>
				<ContainerSection
					{...props}
					iconType='container'
					label='Container'
					containerSelectMode='single'
					containerSelectPlaceholder={oneContainerPH}
				/>
				<Divider/>
				{showAttributes &&
				<>
					<AttributeEditSection
						{...props}
					/>
					<Divider/>
				</>}
				<SaveButton
					{...props}
				/>
			</Card>
		</Modal>
	)
};


export default AttributeEditorModal;
