import { utils } from 'Lib/utils';

export const dateRangePresets = [
	{
		key: 'custom',
		value: 'custom',
		label: utils.translate('componentDateRangePreset.cus1'),
		title: utils.translate('componentDateRangePreset.cus2')
	},
	{
		key: 'last_hour',
		value: 'last_hour',
		label: utils.translate('componentDateRangePreset.hour1'),
		title: utils.translate('componentDateRangePreset.hour2')
	},
	{
		key: 'last_24_hours',
		value: 'last_24_hours',
		label: utils.translate('componentDateRangePreset.day1'),
		title: utils.translate('componentDateRangePreset.day2')
	},
	{
		key: 'last_7_days',
		value: 'last_7_days',
		label: utils.translate('componentDateRangePreset.week1'),
		title: utils.translate('componentDateRangePreset.week2')
	},
	{
		key: 'last_30_days',
		value: 'last_30_days',
		label: utils.translate('componentDateRangePreset.month1'),
		title: utils.translate('componentDateRangePreset.month2')
	},
	{
		key: 'last_365_days',
		value: 'last_365_days',
		label: utils.translate('componentDateRangePreset.year1'),
		title: utils.translate('componentDateRangePreset.year2')
	},
	{
		key: 'today',
		value: 'today',
		label: utils.translate('componentDateRangePreset.today1'),
		title: utils.translate('componentDateRangePreset.today2')
	},
	{
		key: 'yesterday',
		value: 'yesterday',
		label: utils.translate('componentDateRangePreset.yesterday1'),
		title: utils.translate('componentDateRangePreset.yesterday2')
	},
	{
		key: 'week',
		value: 'week',
		label: utils.translate('componentDateRangePreset.week3'),
		title: utils.translate('componentDateRangePreset.week4')
	},
	{
		key: 'month',
		value: 'month',
		label: utils.translate('componentDateRangePreset.month3'),
		title: utils.translate('componentDateRangePreset.month4')
	},
	{
		key: 'year',
		value: 'year',
		label: utils.translate('componentDateRangePreset.year3'),
		title: utils.translate('componentDateRangePreset.year4')
	}
];
