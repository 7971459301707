import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Select } from 'antd';

import LabelItem from '../../FormItems/LabelItem/LabelItem';

import { SkinModel } from 'Models/SkinModel';

import { skinPH } from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';
import { utils } from 'Lib/utils.js';
const { Option } = Select;


const SkinConfigure = (props) =>
{

	const {
		getSkinState,
		widget,
		configuration,
		saveConfiguration,
	} = props;


	const [skin, setSkin] = useState(widget && widget.DWV_SKIN === undefined ?
		'SKIN_DEFAULT' : widget.DWV_SKIN);


	useEffect(() =>
		{
			if (widget)
				setSkin(widget.DWV_SKIN);
		},
		[]
	);


	useEffect(() =>
		{
			if (configuration)
			{
				getSkinState(skin);
				saveConfiguration({
					...configuration,
					index_skin: skin,
				});
			}
		},
		[skin]
	);


	return (

		<FormGroup>
			<Row>
				<LabelItem
					iconType='skin'
					label={utils.translate("componentWidgetValue.appearance")}
				/>
				<Col md={{size: 9}}>
					<Select
						mode='single'
						style={{ width: '100%' }}
						placeholder={skinPH}
						defaultValue={skin}
						onChange={setSkin}
						showArrow
					>
						{Object.values(SkinModel).map((skinType) =>
							<Option
								key={skinType.value}
								value={skinType.value}
							>
								{utils.translate("componentSkinModel." + skinType.label)}
							</Option>
						)}
					</Select>
				</Col>
			</Row>
		</FormGroup>
	)
};


export default SkinConfigure;
