import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Input, InputNumber, Select, Icon, Tooltip, Spin } from 'antd';
import { withTranslation } from 'react-i18next';

import ResultModal from './ResultModal/ResultModal';
import EmptyWidget from '../../WidgetLib/EmptyWidget/EmptyWidget';

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils.js';
import { oneCommandPH, selectOptionPH } from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';

import './Command.css';

const { Option } = Select;


class Command extends Component
{

    constructor(props)
	{
        super(props);

        this.state = {
			device: undefined,
			deviceCommands: [],
			selectedCommand: undefined,
			commandTextOption: '',
			commandSelectOption: undefined,
			portNumber: undefined,
			loading: false,
			loadingCommands: false,
			commandResponse: {},
			downlinkIsSuccess: false,
			downlinkIsError: false,
			modalIsVisible: false,
			commandsName : [],
        };

		this.timerId = null;
		this.componentIsMounted = false;
    }


	componentDidMount()
	{
		this.componentIsMounted = true;

		if (!this.props.widget.DVC_ID) { return; }
		this.getDeviceCommands();
		this.timerId = utils.setRefresh(this.props.widget.DWG_REFRESH_FREQ, this.getDeviceCommands);
		if(Object.keys(this.props.widget.JWQ).length === 1){
			this.handleCommandChange(Object.keys(this.props.widget.JWQ)[0]);
		};
	}


	componentDidUpdate(prevProps, prevState)
	{
		if (prevProps.widget.DVC_ID !== this.props.widget.DVC_ID) { this.getDeviceCommands(); }

		if (this.state.selectedCommand && this.state.deviceCommands.length > 0 && this.props.widget.DWG_REFRESH_FREQ !== 0) {
			this.refreshCurrentlySelectedCommand(); // checking if we have a new device reply from broker
		}

		if(Object.keys(prevProps.widget.JWQ).length !== Object.keys(this.props.widget.JWQ).length && Object.keys(this.props.widget.JWQ).length === 1){
			this.handleCommandChange(Object.keys(this.props.widget.JWQ)[0]);
		}

		if(prevProps.widget.JWQ !== this.props.widget.JWQ){
			this.setState({selectedCommand: undefined});
		}

    }



	componentWillUnmount()
	{
		this.componentIsMounted = false;
		if (this.timerId !== null) { utils.clearRefresh(this.timerId); }
    }


	refreshCurrentlySelectedCommand = () =>
	{
		Object.values(this.props.widget.JWQ).forEach(command =>
		{
			if (command.CMD_CODE === this.state.selectedCommand.CMD_CODE && command.lastDeviceReturnDate !== this.state.selectedCommand.lastDeviceReturnDate)
			{ // broker has received a reply and updated database reply timestamp
				this.setState({ selectedCommand : command });
			}
		});
	}


	getDeviceCommands = () =>
	{
		this.setState({
			loadingCommands: true,
		}, () =>
		{
			request.get(
		        'device/' + this.props.widget.DVC_ID,
		        {
					'dependencies': 'available_commands'
				},
		        (device) =>
				{
					if (this.componentIsMounted)
					{
						this.setState({
							device: device[0],
							deviceCommands: device[0].available_commands ? device[0].available_commands : [],
						});
					}
				},
		        (error) => { console.error(error); },
				() => this.setState({ loadingCommands: false })
		    );
		});
	}


	handleCommandChange = (value) =>
	{
		this.setState({
			selectedCommand: this.props.widget.JWQ[value],
			commandTextOption: '',
			commandSelectOption: undefined,
			portNumber: undefined
		});
	}


	handleTextOptionChange = (event) =>
	{
		this.setState({ commandTextOption: event.target.value });
	}


	handleSelectOptionChange = (value) =>
	{
		this.setState({ commandSelectOption: value });
	}


	handlePortNumberChange = (value) =>
	{
		this.setState({ portNumber: value });
	}


	handleModalClose = () =>
	{
		this.setState({ modalIsVisible: false });
	}


	api_sendCommandToDevice = () =>
	{
		if (!this.state.selectedCommand) { return; }

		this.setState({
			loading: true,
			downlinkIsError: false,
			downlinkIsSuccess: false
		}, () =>
		{
			request.post(
				'downlink',
				{
					'device_id': this.props.widget.DVC_ID,
					'command_code': this.state.selectedCommand.CMD_CODE,
					'protocol_id': this.state.selectedCommand.PRO_ID,
					'port_number': this.state.portNumber,
					'parameter': this.state.commandTextOption || this.state.commandSelectOption
				},
				(response) =>
				{
					this.setState({
						downlinkIsSuccess: true,
						modalIsVisible: true,
						commandResponse: response[0]
					});
				},
				(error) =>
				{
					this.setState({
						downlinkIsSuccess: true,
						modalIsVisible: true,
						commandResponse: error.error_description
					});
				},
				() => { this.setState({ loading: false }); }
			);
		});
	}


    render()
	{
		const showEmptyWidget = (!this.state.loadingCommands && utils.isConfigMode() && !this.state.device && !this.props.isCategoryDashboard) || !this.props.widget.CMD_IDS;
		// const commandOptions = this.state.deviceCommands.map((command, index) =>
		// 	<Option key={command.code} value={index}>{command.label}</Option>
		// );
		const selectParamTypeOptions = this.state.selectedCommand && this.state.selectedCommand.CMD_SELECT ? Object.keys(this.state.selectedCommand.CMD_SELECT).map((optionCode) =>
			<Option key={optionCode} value={optionCode}>{this.state.selectedCommand.CMD_SELECT[optionCode]}</Option>
		) : null;

		const selectedCommandHasOpenArgument = this.state.selectedCommand && this.state.selectedCommand.CMD_ARGUMENT === 'ARG_ONE';
		const selectedCommandHasSelectArgument = this.state.selectedCommand && this.state.selectedCommand.CMD_ARGUMENT === 'ARG_SELECT';
		const selectedCommandHasPortArgument = this.state.selectedCommand && this.state.selectedCommand.CMD_ARGUMENT !== 'ARG_NO';
		const isAdvanced = this.props.widget && this.props.widget.DWO_IS_ADVANCED === '1';

		const marginTop = this.props.size.height / 10;

		const isSingleCommand = Object.keys(this.props.widget.JWQ).length === 1;


		const commandOptions = Object.values(this.props.widget.JWQ).map((command) =>
			<Option key={command.CMD_ID} value={command.CMD_ID}>{command.JWQ_NAME}</Option>
		);
 
		let commandOptionsSorted = commandOptions.sort((a, b) => (a.props.children > b.props.children) ? 1 : -1)

        return (

			<>
				{showEmptyWidget &&
				<EmptyWidget
					{...this.props}
					callToAction={utils.translate('componentWidgetCommand.selectDVC')}
				/>}
				{this.state.device &&
				<div 
					id={this.props.getWidgetId + '_CONTENT'}
					className={'CONTENT' + this.props.getWidgetClass + this.props.getWidgetClass + '_CONTENT'} 
					style={{height: `${this.props.size.height}px`}}>
					<Container style={{marginTop: "20px"}}>
						<Row>
							<Col sm="12" md={{ size: 6, offset: 3 }}>
								{this.state.device.label}
							</Col>
						</Row>
						<Row style={{marginTop: `${marginTop}px`}}>
							<Col sm="12" md={{ size: 6, offset: 3 }}>
							{!isSingleCommand &&
								<Select
									showSearch
									optionFilterProp="children"
									style={{ width: '100%' }}
									placeholder={oneCommandPH}
									value={this.state.selectedCommand ? this.state.selectedCommand.JWQ_NAME : undefined}
									onChange={this.handleCommandChange}
								>
									{commandOptionsSorted}
								</Select>}
								{isSingleCommand &&
									<h6>{Object.values(this.props.widget.JWQ) ? Object.values(this.props.widget.JWQ)[0].JWQ_NAME : undefined}</h6>
								}
							</Col>
						</Row>
						<Row style={{marginTop: "20px"}}>
						{selectedCommandHasOpenArgument && isAdvanced &&
							<Col sm="12" md={{ size: 3, offset: 3 }}>
								<Input
									value={this.state.commandTextOption}
									onChange={this.handleTextOptionChange}
									placeholder={this.props.t('componentWidgetCommand.openOption')}
									onMouseDown={e => e.stopPropagation()}
								/>
							</Col>}
						{selectedCommandHasSelectArgument && isAdvanced &&
							<Col sm="12" md={{ size: 3, offset: 3 }}>
								<Select
									value={this.state.commandSelectOption}
									showSearch
									optionFilterProp="children"
									style={{ width: '100%' }}
									placeholder={selectOptionPH}
									onChange={this.handleSelectOptionChange}
								>
									{selectParamTypeOptions}
								</Select>
							</Col>}
						{selectedCommandHasPortArgument && isAdvanced &&
							<Col sm="12" md={{ size: selectedCommandHasSelectArgument || selectedCommandHasOpenArgument ? 3 : 4, offset: selectedCommandHasSelectArgument || selectedCommandHasOpenArgument ? '' : 4}} onMouseDown={e => e.stopPropagation()}>
								<InputNumber
									min={0}
									defaultValue={0}
									value={this.state.portNumber}
									onChange={this.handlePortNumberChange}
									style={{width: "100%"}}
									placeholder={this.props.t('componentWidgetCommand.portNum')}
								/>
							</Col>}
						</Row>
					</Container>
					{!this.state.loading &&
					<Tooltip title={this.state.deviceCommands.length === 0 ? utils.translate('componentWidgetCommand.noCMD') : !this.state.selectedCommand ? utils.translate('componentWidgetCommand.selectCMD') : utils.translate('componentWidgetCommand.sendCMD1')}>
						<Icon
							className={!this.state.selectedCommand ? "disabledSendCommandButton" : "sendCommandButton"}
							type="cloud-upload"
							onClick={this.api_sendCommandToDevice}
							style={{marginTop: `${marginTop}px`, fontSize: `${this.props.size.height / 5}px`}}
						/>
					</Tooltip>}
					{this.state.loading &&
					<Spin
						size="large"
						tip={utils.translate('componentWidgetCommand.sendCMD2')}
						style={{marginTop: `${marginTop}px`}}
					/>}
					{this.state.selectedCommand && this.state.selectedCommand.lastDeviceReturnDate &&
					<div style={{marginTop: `${marginTop / 2}px`, fontSize: 'small'}} onMouseDown={e => e.stopPropagation()}>
						{utils.translate('componentWidgetCommand.lastDTA')}{this.state.selectedCommand.lastDeviceReturnDate}{utils.translate('componentWidgetCommand.UTC')}<br/>
						{utils.translate('componentWidgetCommand.sentOn')}{this.state.selectedCommand.lastDeviceOrderDate}{utils.translate('componentWidgetCommand.UTC')}
						<div style={{marginTop: '10px'}}>
							<span style={{color: '#52c41a', background: '#f6ffed', padding: '5px', borderRadius: '4px', lineHeight: '1.7'}}>
								{this.state.selectedCommand.lastDeviceReturnMessage}
							</span>
						</div>
					</div>}

					<ResultModal
						{...this.state}
						{...this.props}
						handleModalClose={this.handleModalClose}
					/>

				</div>}
			</>
        )
    }
}


export default withTranslation()(Command);
