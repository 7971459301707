import React, { useState, useEffect, useRef } from 'react';
import { Drawer } from 'antd';

import DeviceProfile from '../../../../WidgetLib/DeviceProfile/DeviceProfile';
import DeviceTooltip from '../../../../WidgetLib/DeviceTooltip/DeviceTooltip';
import SvgIcon from 'Components/Studio/StudioLib/SvgIcon/SvgIcon.js';

import { LogicalOperatorModel } from 'Models/LogicalOperatorModel';

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils.js';

import './StatusMarker.css';


let timerId = null;

const CONNECT = 'CONNECT';
const STARTED = 'STARTED';
const UNAVAILABLE = 'UNAVAILABLE';

const DEFAULT_COLORS = {
	GREEN: 'rgb(31, 189, 67)',
	RED: 'rgba(255, 83, 105, 0.85)',
	GRAY: 'rgba(209, 207, 207, 0.9)',
};

const usePrevProps = (value) =>
{
	const ref = useRef();

	useEffect(() => { ref.current = value; });

	return ref.current;
}


const StatusMarker = (props) =>
{

	const {
		widget,
		hasDeviceProfile,
		parentWidget,
	} = props;

	const prevPropsWidget = usePrevProps({ widget });

	const [device, setDevice] = useState({});
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [markerColor, setMarkerColor] = useState(undefined);
	const [masterValue, setMasterValue] = useState(undefined);
	const [masterUnit, setMasterUnit] = useState(undefined);

	const isConfigMode = utils.isConfigMode();
	const deviceHasChanged = !prevPropsWidget ||
		(prevPropsWidget && prevPropsWidget.DVC_ID !== widget.DVC_ID);
	const useDataMarkers = (parentWidget.DTY_IDS && parentWidget.DTY_IDS.length > 0) ||
		(parentWidget.ATR_IDS && parentWidget.ATR_IDS.length > 0);
	const showValueNextToMarkers = parentWidget.DTH_SHOW_VALUE === '1';
	const parentWidgetHasDthConfig = parentWidget.DTH_THRESHOLD_VALUE &&
		parentWidget.DTH_OPERATOR && parentWidget.DTH_ACTIVE_COLOR && parentWidget.DTH_INACTIVE_COLOR;

	const iconClass = isConfigMode ? 'configStatusMarker' : 'viewStatusMarker';


	useEffect(() =>
		{
			if (widget.DVC_ID != null)
			{
				timerId = utils.setRefresh(
					widget.DWG_REFRESH_FREQ,
					() => api_getMarkerDevice()
				);
			}

			return function cleanup ()
			{
				if (timerId !== null)
					utils.clearRefresh(timerId);
			}
		},
		[]
	);


	useEffect(() =>
		{
			if (deviceHasChanged)
	            api_getMarkerDevice();
		},
		[widget]
	);


	useEffect(() =>
		{
			if (device.id)
			{
				const info = getMarkerInfo();

				if (info)
				{
					setMarkerColor(info.color);
					setMasterValue(info.value);
					setMasterUnit(info.unit);
				}
			}
		},
		[device]
	);


	const api_getMarkerDevice = () =>
	{
        request.get(
            'device/' + widget.DVC_ID,
            {},
            (device) =>
			{
                setDevice(device[0]);
            },
            () => console.error('load device failed')
        );
    }


	const getStatusMarkerColor = () =>
	{
		const deviceIsOn = device.status === CONNECT || device.status === STARTED;
		const deviceIsUnavailable = device.status === UNAVAILABLE;

		return deviceIsOn ? DEFAULT_COLORS.GREEN :
			deviceIsUnavailable ? DEFAULT_COLORS.GRAY : DEFAULT_COLORS.RED;
	}


	const getMarkerInfo = () =>
	{
		let markerColor = getStatusMarkerColor(); // defaults to red / green / gray status colors
		let masterDataSourceValue = undefined;
		let masterDataSourceUnit = undefined;

		if (useDataMarkers)
		{
			const masterDataSourceData = getMasterDataSourceData();
			const isApplicable = isApplicableToDevice(masterDataSourceData);

			if (isApplicable)
			{
				masterDataSourceValue = masterDataSourceData.value;
				masterDataSourceUnit = masterDataSourceData.unit;

				if (parentWidgetHasDthConfig)
				{
					if (meetsActiveCondition(masterDataSourceValue))
						markerColor = parentWidget.DTH_ACTIVE_COLOR;

					else markerColor = parentWidget.DTH_INACTIVE_COLOR;
				}
			}

			if (!isApplicable && parentWidgetHasDthConfig)
				markerColor = parentWidget.DTH_DEFAULT_COLOR;
		}

		return {
			color: markerColor,
			value: masterDataSourceValue,
			unit: masterDataSourceUnit,
		};
	}


	const getMasterDataSourceData = () =>
	{
		let masterDataSourceData = { value: undefined, unit: undefined };

		if (parentWidget.DTY_IDS && parentWidget.ATR_IDS)
		{
			let masterSource = undefined;
			const masterDty = parentWidget.DTY_IDS.find((dty) => dty.IS_MASTER === true);
			const masterAtr = parentWidget.ATR_IDS.find((atr) => atr.IS_MASTER === true);

			if (masterDty && device.configs)
			{
				masterSource = device.configs.find(c => c.dty_id === masterDty.ID);
			}

			else if (masterAtr && device.attributes) // one or the other !!
			{
				masterSource = device.attributes.find((a) => a.atr_id === masterAtr.ID);
			}

			if (masterSource)
			{
				masterDataSourceData.value = String(masterSource.value);
				masterDataSourceData.unit = masterSource.unite_D1;
			}
		}

		return masterDataSourceData;
	}


	const meetsActiveCondition = (value) =>
	{ // uses chosen function to compare current value to set threshold
		if (value !== undefined)
		{
			return LogicalOperatorModel[parentWidget.DTH_OPERATOR].function(
				value, parentWidget.DTH_THRESHOLD_VALUE
			);
		}
	}


	const isApplicableToDevice = (data) => data && data.value !== undefined;


	const openDrawer = () => hasDeviceProfile === '1' && setDrawerVisible(true);
	const marker = (
		<span className={isConfigMode ? 'deviceMarkerSpan' : 'deviceMarkerSpanView'}>
			<SvgIcon
				onClick={openDrawer}
				type={widget && widget.DSM_ICON ? widget.DSM_ICON : 'marker' }
				fill={markerColor}
				height={25}
				className={iconClass}
			/>
			{masterValue !== undefined && showValueNextToMarkers &&
			<div
				className='sitemapValueSnippetDiv'
				style={{
					top: isConfigMode ? -5.5 : 2,
					left: isConfigMode ? 47 : 28,
				}}
			>
				<span>{masterValue} {masterUnit}</span>
			</div>}
		</span>
	);


    return device.id ? (

		<div
			className='widget'
		>
			<DeviceTooltip
				widget={parentWidget}
				device={device}
				children={marker}
				hasDeviceProfile={hasDeviceProfile === '1'}
				useDataMarkers={useDataMarkers}
			/>

			<Drawer
				title=''
				placement='left'
				closable
				onClose={() => setDrawerVisible(false)}
				visible={drawerVisible}
				width={utils.isMobileVersion(window) ? '100%' : '60%'}
			>
				<DeviceProfile
					device={device}
				/>
			</Drawer>
		</div>
    ) : null;
}


export default StatusMarker;
