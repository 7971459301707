import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Select } from 'antd';

import LabelItem from '../../FormItems/LabelItem/LabelItem';

const { Option } = Select;

const SelectSection = (props) =>
{
    return (
        <>
            <FormGroup>
                <Row style={{paddingTop: '15px'}}>
                    <LabelItem
                        iconType={props.icon}
                        label={props.label}
                    />
                    <Col md={{size: 3}}>
                        <Select
                            value={props.value}
                            onChange={props.onChange}
                        >
                            {props.options.map(option =>
                                <Option key={option.key} value={option.value}>{option.label}</Option>
                            )}
                        </Select>
                    </Col>
                </Row>
            </FormGroup>
        </>
    );
}

export default SelectSection;