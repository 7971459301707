import React from 'react';
import { Card, Icon, Divider } from 'antd';

import { utils } from 'Lib/utils';


const ValueCard = (props) =>
{
	const CustomTitle = `${props.titleSize}`;

	return (

		<Card className='valueCard'>
			<>
				<p>
					<Icon
						type='unordered-list'
						className='greyIcon'
					/>
					{props.title}
				</p>
				
				<Divider/>
				
				<CustomTitle
					style={{ color: props.number === '' ? 'grey' : '' }}
				>
					{props.number === '' ? utils.translate('componentValueCard.noValue') : props.number}
				</CustomTitle>
				
				{props.description &&
				<>
					<Divider/>
				
					<i>
						<Icon
							type='info-circle' 
							className='greyIcon'
						/>
						{props.description}
					</i>
				</>}
			</>
		</Card>
	);
}


export default ValueCard;