import React, { useState, useEffect } from 'react';
import SwitchSection from '../../FormSections/SwitchSection/SwitchSection';
import { utils } from 'Lib/utils.js';


const LastDataConfigure = (props) =>
{

	const {
		widget,
		configuration,
		saveConfiguration,
	} = props;

	const [switchChecked, setSwitchChecked] = useState(!widget.DWV_SHOW_LAST_DATE || widget.DWV_SHOW_LAST_DATE === '1');


	useEffect(() =>
		{
			if (switchChecked !== undefined)
			{
				saveConfiguration({
					...configuration,
					last_date: switchChecked ? 1 : 0,
				});
			}
		},
		[switchChecked]
	);


	const handleSwitch = (checked) => setSwitchChecked(checked);


	return (

		<SwitchSection
			label={utils.translate('componentStatusTooltip.last')}
			iconType='calendar'
			checked={switchChecked}
			handleSwitch={handleSwitch}
		/>
	)
};


export default LastDataConfigure;
