import React, { Component } from 'react';
import { request } from 'Lib/request';
import DOMPurify from 'dompurify';
import ReactMarkdown from 'react-markdown';

import EmptyWidget from '../../WidgetLib/EmptyWidget/EmptyWidget';

import { utils } from 'Lib/utils.js';

import './Html.css';

class Html extends Component {
	constructor(props) {
		super(props);

		this.state = {
			categoryToLoad: {},
			lastRequestCatId: null,
			alarms: {
				lastTimeCallActivesAlarm: null,
				alarmsActiveCount: null,
			},
		};
	}

	getValueFromTags(label, device) {
		let newLabel = label; // 'text [ATTRIBUTE_TOTO] [ATTRIBUTE_TITI] text'
		const regex = /\[([^\]]*)]/g;
		let matches = [];
		matches = label.match(regex) || []; // [ '[ATTRIBUTE_TOTO]', '[ATTRIBUTE_TITI]' ]
		matches = matches.map((match) => {
			return match.slice(1, -1);
		}); // [ 'ATTRIBUTE_TOTO', 'ATTRIBUTE_TITI' ]
		matches.forEach((match) => {
			const fullTag = '[' + match + ']'; // '[ATTRIBUTE_TOTO]'
			const tagType = match.split(/_(.+)/)[0]; // 'ATTRIBUTE'
			const tagValue = match.split(/_(.+)/)[1]; // 'TOTO'
			if (tagType !== '') {
				switch (tagType) {
					case 'ATTRIBUTE':
						const attributes = device.attributes;
						if (
							attributes !== undefined &&
							tagValue !== undefined &&
							this.props.isCategoryDashboard
						) {
							const attribute = attributes.find(
								(attrib) => attrib.code === tagValue
							); // attribute has tag code (may be undefined)
							if (attribute !== undefined) {
								const attributeValue = attribute.value; // for example '1234'
								newLabel = newLabel.replace(
									fullTag,
									attributeValue
								); // 'text 1234 [ATTRIBUTE_TITI] text'
							} else {
								newLabel = fullTag; // 'text [ATTRIBUTE_TITI] text'
							}
						}
						break;
					case 'TOKEN':
						newLabel = newLabel.replace(
							fullTag,
							sessionStorage.getItem('token')
						);
						break;
					case 'ALARMS':
						if (tagValue === 'ACTIVE') {
							const canMakeRequest =
								Date.now() -
									this.state.alarms.lastTimeCallActivesAlarm >
									1000 ||
								!this.state.alarms.lastTimeCallActivesAlarm;

							canMakeRequest && this.api_getAlarmsActiveCount();
							newLabel = newLabel.replace(
								fullTag,
								this.state.alarms.alarmsActiveCount
							);
						}
						break;
					case 'DEVICES':
						// tag [DEVICES] only for pages by category
						if (
							this.props.isCategoryDashboard &&
							tagValue === undefined
						) {
							let lengthDVC = Object.keys(
								this.props.category.devices
							).length;
							newLabel = newLabel.replace(fullTag, lengthDVC);
						} else if (tagValue !== undefined) {
							const isIdType = (val) => parseInt(val) > 0;
							const firstTagValue = tagValue.split(/_(.+)/)[0]; // 'WORKING / STOPPED / UNAVAILABLE'
							const secondTagValue = tagValue.split(/_(.+)/)[1]; // 'CAT_ID'

							const tagIsForDevicesCountCategoryByStatus =
								['WORKING', 'STOPPED', 'UNAVAILABLE'].includes(
									firstTagValue
								) && isIdType(secondTagValue);

							const tagIsForTotalDevicesCountCategory =
								isIdType(firstTagValue) && !secondTagValue;

							const catId = tagIsForDevicesCountCategoryByStatus
								? secondTagValue
								: tagIsForTotalDevicesCountCategory
								? firstTagValue
								: null;

							const status = tagIsForDevicesCountCategoryByStatus
								? firstTagValue
								: null;

							// Do the api call when the category id of tag has changed
							if (this.state.lastRequestCatId !== catId) {
								this.api_getCategory(catId);
							}

							// After the api call, state update & rerender : display the tag value
							if (
								this.state.categoryToLoad &&
								this.state.categoryToLoad.devices
							) {
								if (tagIsForTotalDevicesCountCategory) {
									let lengthDVC = Object.keys(
										this.state.categoryToLoad.devices
									).length;
									newLabel = newLabel.replace(
										fullTag,
										lengthDVC
									);
								} else if (tagIsForDevicesCountCategoryByStatus) {
									switch (status) {
										case 'STOPPED':
											newLabel = newLabel.replace(
												fullTag,
												this.state.categoryToLoad
													.special_info
													.devices_stopped
											);
											break;
										case 'UNAVAILABLE':
											newLabel = newLabel.replace(
												fullTag,
												this.state.categoryToLoad
													.special_info
													.devices_unavailable
											);
											break;
										case 'WORKING':
											newLabel = newLabel.replace(
												fullTag,
												this.state.categoryToLoad
													.special_info
													.devices_working
											);
											break;
										default:
											break;
									}
								}
							}
						}
						break;

					default:
						break;
				}
			}
		});
		return newLabel;
	}

	api_getAlarmsActiveCount = () => {
		request.get(
			'alarm',
			{},
			(response) => {
				const alarms_active_count = response.find((el) =>
					el.hasOwnProperty('alarms_active_count')
				);
				this.setState({
					alarms: {
						lastTimeCallActivesAlarm: Date.now(),
						alarmsActiveCount:
							alarms_active_count.alarms_active_count,
					},
				});
			},
			(e) => {
				this.setState({
					alarms: {
						lastTimeCallActivesAlarm: Date.now(),
						alarmsActiveCount: null,
					},
				});
			},
			() => {}
		);
	};

	api_getCategory = (catId) => {
		request.get(
			'category/' + catId,
			{},
			(category) => {
				this.setState({
					categoryToLoad: category['0']['0'],
					lastRequestCatId: catId,
				});
			},
			(e) => {
				this.setState({
					categoryToLoad: {},
					lastRequestCatId: catId,
				});
			},
			() => {}
		);
	};

	render() {
		const { widget, catCurrentDevice } = this.props;
		const contentExists =
			widget.DWH_TEXT !== undefined && widget.DWH_TEXT !== null;
		const showEmptyWidget = utils.isConfigMode() && !contentExists;
		const content =
			contentExists &&
			this.getValueFromTags(this.props.widget.DWH_TEXT, catCurrentDevice);

		return (
			<>
				{showEmptyWidget && (
					<EmptyWidget
						{...this.props}
						callToAction={utils.translate(
							'componentWidgetHtml.start'
						)}
					/>
				)}

				{contentExists && (
					<ReactMarkdown
						id={this.props.getWidgetId + '_CONTENT'}
						className={
							'CONTENT' +
							this.props.getWidgetClass +
							this.props.getWidgetClass +
							'_CONTENT'
						}
						source={DOMPurify.sanitize(content, {
							ADD_ATTR: ['allowfullscreen'],
							ADD_TAGS: ['iframe'],
							KEEP_CONTENT: true,
						})}
						escapeHtml={false}
					/>
				)}
			</>
		);
	}
}

export default Html;
