import { TOGGLE_DRAWER, TOGGLE_THEME_BUILDER } from './types';

export const toggleDrawer = () => ({
    type: TOGGLE_DRAWER
})

export const toggleThemeBuilder = () => ({
    type: TOGGLE_THEME_BUILDER
})


