import React, { useEffect } from 'react';
import { Tabs, Card, Icon } from 'antd';

import DatatypeConfigure from './Datatype/DatatypeConfigure';
import AttributeConfigure from './Attribute/AttributeConfigure';

import { ChartWidgetModel } from 'Models/ChartWidgetModel.js';
import { utils } from 'Lib/utils.js';

const { TabPane } = Tabs;

const AREACHART = 'areachart';
const COLUMNCHART = 'columnchart';


const DataSourceConfigure = (props) =>
{

	const {
		widget,
		configuration,
		saveConfiguration,
	} = props;

	const widgetHasCfgIds = widget &&
		widget.CFG_IDS !== undefined && widget.CFG_IDS !== '';
	const widgetHasValIds = widget &&
		widget.VAL_IDS !== undefined && widget.VAL_IDS[0];
	const defaultActiveKey = widgetHasCfgIds ? '1' :
		(widgetHasValIds ? '2' : '1');
	const widgetType = widget && widget.DWG_TYPE === ChartWidgetModel.AREA ?
		AREACHART : COLUMNCHART;


	useEffect(() => // necessary to init configuration
		{ // children will update as values get modified
			if (widget)
			{
				saveConfiguration({
					...configuration,
					config_ids: widget.CFG_IDS,
					value_ids: widget.VAL_IDS,
				});
			}
		},
		[]
	);


	return (

		<Tabs
			tabPosition='top'
			defaultActiveKey={defaultActiveKey}
		>
			<TabPane
				tab={<span>
						<Icon type='api' className='greyIcon'/>
						Datatypes
					</span>}
				key='1'
			>
				<Card bordered={false}>
					<DatatypeConfigure
						{...props}
						widgetType={widgetType}
					/>
				</Card>
			</TabPane>
			<TabPane
				tab={<span>
						<Icon type='unordered-list' className='greyIcon'/>
						{utils.translate('generic.containerAttr')}
					</span>}
				key='2'
			>
				<Card bordered={false}>
					<AttributeConfigure
						{...props}
						widgetType={widgetType}
					/>
				</Card>
			</TabPane>
		</Tabs>
	)
};


export default DataSourceConfigure;
