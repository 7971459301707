import React, { useState, useEffect, useRef } from 'react';
import { Tooltip, Icon, Input, Slider, Badge, Row, Col } from 'antd';
import { CardTitle } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { request } from 'Lib/request';
import { utils } from 'Lib/utils';


import '../WidgetHeader.css';


const NameRefresh = (props) =>
{
	const isBlock = props.isBlock;
	const isHtml = !isBlock ? props.widget.DWG_TYPE === 'DWG_html' : false;
	const isCamera =  props.widget ? props.widget.DWG_TYPE === 'DWG_camera' : false;
	const isCommand =  props.widget ? props.widget.DWG_TYPE === 'DWG_command' : false;

	const [label, setLabel] = useState(!isBlock ? props.widget.DWG_LABEL : props.block.DBK_LABEL);
	const [refresh_freq, setRefreshFreq] = useState(!isBlock ? props.widget.DWG_REFRESH_FREQ : 0);
	const [click, setClick] = useState(false);


	const useOutsideAlerter = (ref) =>
	{
		useEffect(() =>
		{
			// if clicked on outside of element
			function handleClickOutside(event)
			{
				if (ref.current && !ref.current.contains(event.target))
					setClick(false);
			}

			// Bind the event listener
			document.addEventListener('mousedown', handleClickOutside);

			return () => document.removeEventListener('mousedown', handleClickOutside); // unmount
		}, [ref]);
	}


	const handleRefreshFreqChange = (value) =>
	{
		setRefreshFreq(value);
		api_handleHeaderChange({ refresh_freq: value });
	}


	const handleLabelChange = (event) =>
	{
		const label = event.target.value;

		setLabel(label);
		api_handleHeaderChange({ label: label });
	}


	const handleClick = () => setClick(true);


	const handlePressEnter = () => setClick(false);


	const api_handleHeaderChange = (params) =>
	{
		request.patch(
			!isBlock ? 'widget/' + props.widget.DWG_ID : 'block/' + props.block.DBK_ID,
			params,
			(res) =>
			{
				props.getWidgets();
			},
			() => console.error('edit widget failed')
		);
	}


	const formatter = (value) => value + ' min';


	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);


	const marks =
	{
		0: '0',
		60: '60',
	};


	return (
		<div>
			<CardTitle >
				{!click &&
					<Row>
						<Col span={24}style={{
							display: 'flex',
							alignItems: 'center'
						}}>
							<Tooltip placement='bottom' arrowPointAtCenter title={!isBlock ? utils.translate('componentNameRefresh.name1') : utils.translate('widgetDescription.editBlock')}>
								<div
									className='widget-left'
									onClick={handleClick}
									style={{ cursor: 'pointer', paddingRight:'10px' }}
								>
									{label ? label : <Icon type='edit' className='greyIcon'/>}
								</div>
							</Tooltip>
							{!isHtml && !isCamera && !isCommand && !isBlock &&
							<div
								onClick={handleClick}
								style={{ cursor: 'pointer', align: 'left', color: !isBlock ? props.getWidgetColors('text') : ''}}>
									<Tooltip placement='bottom' arrowPointAtCenter title={utils.translate('componentNameRefresh.refresh')}>
										<Badge
											count={refresh_freq}
											style={{ marginLeft: '20px', marginBottom: '2px', backgroundColor: '#1890ff', width:'30px', color: !isBlock ? props.getWidgetColors('background') : '', borderColor: !isBlock ? props.getWidgetColors('text') : '' }}
											showZero={true}
										>
											<Icon
												type='clock-circle'
												className='greyIcon'
												style={{ fontSize: '20px',color: !isBlock ? props.getWidgetColors('text') : ''}}
											/>
										</Badge>
									</Tooltip>
							</div>}
						</Col>
					</Row>}
				{click &&
					<div className={'nameRefresh'} ref={wrapperRef} style={{ fontSize: '20px' }}>
						<Row>
							<Col span={12} >
								<Tooltip 
									placement='bottom' 
									arrowPointAtCenter 
									title={utils.translate('componentNameRefresh.name2')}
									>
									<Input
										autoFocus
										className={'NoDrag'}
										prefix={<Icon type='tag' className='greyIcon' />}
										placeholder={props.t('componentNameRefresh.name3')}
										value={label}
										onChange={handleLabelChange}
										onPressEnter={handlePressEnter}
										style={{ height: '30px' }}
									/>
								</Tooltip>
							</Col>
							{!isHtml && !isBlock && !isCamera && !isCommand &&
							<Col span={12}>
								<Slider
									defaultValue={refresh_freq}
									max={60}
									marks={marks}
									tooltipPlacement={'bottom'}
									tipFormatter={formatter}
									className={'NoDrag'}
									onAfterChange={handleRefreshFreqChange}
									style={{ cursor: 'pointer', align: 'left', width: '75%', marginLeft: '40px', marginTop:'0px' }}
								/>
							</Col>}
						</Row>
					</div>}
			</CardTitle>
		</div >
	);
};


export default withTranslation()(NameRefresh);
