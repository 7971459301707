import React, { Component } from 'react';
import { Spin } from 'antd';

import { request } from 'Lib/request';
import { utils } from 'Lib/utils';
import history from 'Lib/history';

import './EmptyPage.css';



class EmptyPage extends Component
{

	componentDidMount()
	{
		request.get(
			'page',
			{ application_id: window.ENV.REACT_APP_ID },
			(pages) =>
			{
				let foundDefaultPage = false;
				let foundNonDefaultPage = false;
				let nonDefaultPage = '';
				let noAccess = utils.getBaseUrl() + 'noAccess';

				for (let page of pages)
				{
					if (page.DPG_DEFAULT && page.DPG_DEFAULT === '1')
					{
						history.push(utils.getBaseUrl() + page.DPG_ID);
						foundDefaultPage = true;

						break;
					}
					else if (page.DPG_ID && page.DPG_ID !== undefined && foundNonDefaultPage === false)  
					{
						nonDefaultPage = utils.getBaseUrl() + page.DPG_ID;
						foundNonDefaultPage = true;
					}	
				};

				if (!foundDefaultPage && foundNonDefaultPage) {history.push(nonDefaultPage); }
				else if (!foundDefaultPage && !foundNonDefaultPage) {history.push(noAccess); }
			},
			(error) =>
			{
				console.error(error);
			}
		);
	}


	render()
	{
		return (

			<div className='loginLoader'>
				<Spin
					size='large'
				/>
			</div>
		);
	}
}


export default EmptyPage;
