import React from 'react';
import { Form } from 'antd';

import AddDeviceButton from './AddDeviceButton/AddDeviceButton';
import SizeSelect from './SizeSelect/SizeSelect';
import ColumnSelect from './ColumnSelect/ColumnSelect';
import Search from './Search/Search';
import DeviceDataExporter from '../../../WidgetLib/DeviceDataExporter/DeviceDataExporter';
import { utils } from 'Lib/utils';
import ColumnOrderButton from './ColumnOrderButton/ColumnOrderButton';


const ControlBar = (props) =>
{
	const { widget, loading, devices, allDeviceIds, toggleUpdateColumnOrderDisplay, columnsToDisplay } = props;
	
	const hasDevices = widget.DVC_IDS && !loading && devices && devices.length > 0;
	const hasOtherDevices = widget.OTHER_DVC_IDS && widget.OTHER_DVC_IDS.length > 0 && !loading && devices && devices.length > 0;
	const hasExport = widget.DWT_HAS_EXPORT === '1' && (hasDevices || hasOtherDevices);
	const hasAddDevice = widget.DWT_HAS_ADD_DEVICE === '1' && (hasDevices || hasOtherDevices);
	
	return (

		<div className='components-table-demo-control-bar'>
			<Form layout='inline'>
				{hasExport &&
				<Form.Item>
					<DeviceDataExporter
						exportType='device'
						devices={devices}
						allDeviceIds={allDeviceIds}
						widget={widget}
						infoText={utils.translate('componentWidgetTable.downloadDVC')}
						popOverTitle={utils.translate('componentWidgetTable.selectDVC')}
						iconType='mobile'
						tooltipTitle={utils.translate('componentWidgetTable.exportDVC')}
					/>
				</Form.Item>}
				{hasAddDevice &&
				<AddDeviceButton
					{...props}
					onClick={props.openCreateModal}
				/>}
				<SizeSelect
					{...props}
					onChange={props.api_handleSizeChange}
				/>
				{props.isConfigMode &&
				<ColumnSelect
					{...props}
					selectRef={props.selectRef}
					api_handleColumnToDisplayChange={props.api_handleColumnToDisplayChange}
				/>}
				<Search
					{...props}
					handleSearch={props.handleSearch}
				/>
				{!columnsToDisplay.includes("allAttributes") && (
					<ColumnOrderButton
						{...props}
						onClick={toggleUpdateColumnOrderDisplay}
					/>
				)}

			</Form>
		</div>
	);
};


export default ControlBar;
