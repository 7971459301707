import React, { Component } from 'react';
import { Button, Tooltip, Icon, Popover, Checkbox, Row, Col, Divider, Form } from 'antd';

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils';


class DataExporter extends Component
{

    constructor(props)
	{
        super(props);

        this.state = {
			popOverVisible: false,
			datatypesToExport: [],
			downloading: false,
		};
    }


	componentDidMount()
	{
        this.setState({ datatypesToExport: this.props.allDatatypeIds });
	}


	componentDidUpdate(prevProps)
	{
		if (prevProps.allDatatypeIds !== this.props.allDatatypeIds)
		{
            this.setState({ datatypesToExport: this.props.allDatatypeIds });
        }
	}


	exportToCSV = () =>
	{
		let params = {
			export_type: 'datatype',
			datatype_ids: this.state.datatypesToExport,
			date_start: this.props.dateStart,
			date_stop: this.props.dateStop
		};

		this.setState({ downloading: true }, () =>
		{
			request.csvExport(
				params,
				() =>
				{
					this.setState({ downloading: false });
				}
			);
		});
	}


	handlePopOverVisibleChange = (popOverVisible) =>
	{
		this.setState({ popOverVisible });
	}


	onCheckboxChange = (checkedValues) =>
	{
  		this.setState({ datatypesToExport: checkedValues });
	}


	toggleAllCheckboxes = () =>
	{
		if (this.state.datatypesToExport.length > 0)
		{
			this.setState({ datatypesToExport: [] });
		}
		else
		{
			this.setState({ datatypesToExport: this.props.allDatatypeIds });
		}
	}


	getPopOverContent = () =>
	{
		let options = Object.keys(this.props.configs).map((key, index) =>
		{
			let configId = this.props.configs[key][key.replace('configId_', '')].id;
            let configLabel = this.props.widget.JWC[configId] && this.props.widget.JWC[configId].JWC_LABEL !== '' ?
			this.props.widget.JWC[configId].JWC_LABEL : this.props.configs[key][key.replace('configId_', '')].label_D1;

			return (
				<Col span={12}>
					<Checkbox className='shortSpan' value={configId}>{configLabel}</Checkbox>
		        </Col>
			);
		});

		return (
			<>
				<Form.Item>
						<Icon className='formPHIcon' type='info-circle'/>
						<i>{utils.translate('componentDataExporter.download')}</i>
				</Form.Item>
				<Divider style={{ marginTop: '15px' }}/>
				<Checkbox.Group
					value={this.state.datatypesToExport}
					onChange={this.onCheckboxChange}
					style={{ width: '100%' }}
				>
					<Row>
						{options}
					</Row>
				</Checkbox.Group>
				<Row>
					<br/>
					<Button
						size='small'
						icon={this.state.datatypesToExport.length === 0 ? 'check' : 'close'}
						onClick={this.toggleAllCheckboxes}
					>
						{this.state.datatypesToExport.length === 0 ? utils.translate('generic.selectAll') : utils.translate('generic.unselectAll')}
					</Button>
				</Row>
				<Divider/>
				<Col className='text-center'>
					<Button
						type='primary'
						disabled={this.state.datatypesToExport.length === 0}
						onClick={() => this.exportToCSV()}
						icon='download'
						loading={this.state.downloading}
					>
						{utils.translate('generic.exportFile')}
					</Button>
				</Col>
			</>
		)
	}


    render()
	{
		const popOverTitle = (
			<span><Icon type='api' className='greyIcon'/>{utils.translate('componentDataExporter.selectDTY')}</span>
		);

        return (

			<Tooltip title={utils.translate('componentDataExporter.exportDTY')} placement='right'>
				<Popover
					content={this.getPopOverContent()}
					title={popOverTitle}
					trigger='click'
					placement='leftBottom'
					visible={this.state.popOverVisible}
					onVisibleChange={this.handlePopOverVisibleChange}
				>
					<Button
						shape='circle'
						style={{ float: 'right', marginTop: `${this.props.widget.DWC_TYPE === 'column' ? -80 : -35}px` }}
					>
						<Icon type='download' style={{ verticalAlign: '0.15em' }}/>
					</Button>
				</Popover>
			</Tooltip>
        )
    }
}


export default DataExporter;
