import history from './history';
import { utils } from './utils';
import moment from 'moment';


export const request = {

    get(resource, params, cb_success, cb_fail, cb_finish)
	{
        let baseUrl = window.ENV.REACT_APP_API_URL + resource;
        let url = baseUrl + '?' + Object.keys(params).map(k => k + '=' + params[k]).join('&') + '&token=' + sessionStorage.getItem('token');

        fetch(url, {})
        .then((response) =>
		{
            if (response.status !== 200)
			{
                return { fail: true, response: response };
            }

            return response.json();
        }).then((responseJson) =>
		{
			if (responseJson.fail === true)
			{
				responseJson.response.json().then(error =>
				{
					if (error.error_number === 140)
					{ // invalid token
						sessionStorage.removeItem('token');
		                history.push(utils.getBaseUrl() + 'login');
					}

					cb_fail(error);
    			});
            }
			else
			{
                cb_success(typeof responseJson['_embedded'] === 'undefined' ? responseJson : responseJson['_embedded'][resource]);
            }
            if (typeof cb_finish !== 'undefined') { cb_finish(); }
        }).catch(e => console.log(e));
    },

    post(resource, params, cb_success, cb_fail, cb_finish)
	{
        this.genericRequest(resource, params, cb_success, cb_fail, cb_finish, 'POST');
    },

    patch(resource, params, cb_success, cb_fail, cb_finish)
	{
        this.genericRequest(resource, params, cb_success, cb_fail, cb_finish, 'PATCH');
    },

	put(resource, params, cb_success, cb_fail, cb_finish)
	{
        this.genericRequest(resource, params, cb_success, cb_fail, cb_finish, 'PUT');
    },

    delete(resource, params, cb_success, cb_fail, cb_finish)
	{
        this.genericRequest(resource, params, cb_success, cb_fail, cb_finish, 'DELETE');
    },

    genericRequest(resource, params, cb_success, cb_fail, cb_finish, method)
	{
        let url = window.ENV.REACT_APP_API_URL + resource;

        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
				'token': sessionStorage.getItem('token')
            },
            body: JSON.stringify(params)
        }).then((response) =>
		{
            if (response.status !== 200 && response.status !== 201 && response.status !== 202)
			{
                return { fail: true, response: response };
            }

            return response.json();
        }).then((responseJson) =>
		{
            if (responseJson.fail === true)
			{
				responseJson.response.json().then(error =>
				{
					if (error.error_number === 140)
					{ // invalid token
						sessionStorage.removeItem('token');
		                history.push(utils.getBaseUrl() + 'login');
					}

					cb_fail(error);
    			});
            }
			else
			{
                cb_success(responseJson);
            }
            if (typeof cb_finish !== 'undefined')
			{
                cb_finish();
            }
        });
    },

	csvExport(params, callback)
	{
		let now = moment().format('YYYY-MM-DD_HH-mm-ss');
		let fileNames = {
			'text/csv':  params.export_type + '_' + now + '.csv',
			'application/hal+json':  params.export_type + '_' + now + '.csv',
			'application/zip':  params.export_type + '_' + now + '.zip',
		};

		fetch(window.ENV.REACT_APP_API_URL + 'csvexport',
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'token': sessionStorage.getItem('token')
			},
			body: JSON.stringify(params)
		})
        .then(response => response.blob())
        .then(blob =>
		{
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');

		    a.href = url;
            a.download = fileNames[blob.type];
            document.body.appendChild(a);

		    a.click();
            a.remove();

			callback();
        })
		.catch((e) => {
			callback();
		});
	}
}
