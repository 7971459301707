import React from 'react';
import { Form, Input, Icon } from 'antd';
import { withTranslation } from 'react-i18next';


const Search = (props) =>
{

	return (

		<Form.Item style={{ float: 'right' }}>
			<Input
				placeholder={props.t('componentWidgetTable.search')}
				prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }}/>}
				value={props.searchedValue}
				onChange={props.handleSearch}
				onPressEnter={props.handleSearch}
				onMouseDown={e => e.stopPropagation()}
				allowClear
			/>
		</Form.Item>
	);
};


export default withTranslation()(Search);
