import React, { useState, useEffect } from 'react';
import { Card, Divider } from 'antd';

import SwitchSection from '../../FormSections/SwitchSection/SwitchSection';
import OperatorSection from '../../FormSections/OperatorSection/OperatorSection';
import OperandSection from '../../FormSections/OperandSection/OperandSection';

import { OperatorModel } from 'Models/OperatorModel.js';
import { OperandSourceModel } from 'Models/OperandSourceModel.js';
import { utils } from 'Lib/utils';

import {
	oneContainerPH,
	oneAttributePH,
} from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';


const OperationConfigure = (props) =>
{

	const {
		widget,
		containers,
		configuration,
		saveConfiguration,
	} = props;

	const [switchChecked, setSwitchChecked] = useState(false);
	const [selectedOperator, setSelectedOperator] = useState(OperatorModel.NONE);
	const [selectedContainerId, setSelectedContainerId] = useState(undefined);
	const [selectedValueId, setSelectedValueId] = useState(undefined);
	const [selectedContainerObject, setSelectedContainerObject] = useState(undefined);


	useEffect(() =>
		{
			const operandId = widget.DWG_SOURCE_OPERAND2_ID;
			const operandSource = widget.DWG_SOURCE_OPERAND2;

			if (operandId && operandSource !== OperandSourceModel.NONE)
			{
				if (operandSource === OperandSourceModel.ATR)
				{
					const containerObject = containers && containers.find(container =>
						container.attribs && container.attribs.find(attribute =>
							attribute.value_id === operandId));

					if (containerObject)
					{
						setSelectedContainerObject(containerObject);
						setSelectedContainerId(containerObject.id);
						setSelectedValueId(operandId);
					}
				}

				setSwitchChecked(true);
				setSelectedOperator(widget.DWG_OPERATOR);
			}
		},
		[]
	);


	useEffect(() =>
		{
			saveConfiguration({
				...configuration,
				operand_id: selectedValueId,
				operand_source: OperandSourceModel.ATR,
			});
		},
		[selectedValueId]
	);


	useEffect(() =>
		{
			saveConfiguration({
				...configuration,
				operator: selectedOperator,
			});
		},
		[selectedOperator]
	);


	const handleSwitch = (checked) =>
	{
		setSwitchChecked(checked);

		if (!checked)
		{
			saveConfiguration({
				...configuration,
				operand_source: OperandSourceModel.NONE,
				operator: OperatorModel.NONE,
			});
		}
	}


	const handleContainerChange = (containerId) =>
	{
		const selectedContainerObject = containers &&
			containers.find(container => container.id === containerId);

		setSelectedContainerId(containerId);
		setSelectedContainerObject(selectedContainerObject);
		setSelectedValueId(undefined);
	}


	const handleAttributeChange = (valueId) =>
	{
		setSelectedValueId(valueId);
	}


	return (

		<>
			<SwitchSection
				iconType='diff'
				label={utils.translate('componentOperationConfigure.operation')}
				checked={switchChecked}
				handleSwitch={handleSwitch}
			/>
			{switchChecked &&
			<Card>
				<OperatorSection
					selectedOperator={selectedOperator}
					setSelectedOperator={setSelectedOperator}
				/>
				<Divider/>
				<OperandSection
					{...props}
					onlyAllowNumericAttributes
					selectedContainerId={selectedContainerId}
					selectedValueId={selectedValueId}
					selectedSourceObject={selectedContainerObject}
					handleContainerChange={handleContainerChange}
					handleAttributeChange={handleAttributeChange}
					containerSelectMode='single'
					attributeSelectMode='single'
					containerSelectPlaceholder={oneContainerPH}
					attributeSelectPlaceholder={oneAttributePH}
				/>
			</Card>}
		</>
	)
};


export default OperationConfigure;
