import React from 'react';
import { Row, Card } from 'antd';

import DeviceCard from './DeviceCard/DeviceCard';
import DeviceDetails from './DeviceDetails/DeviceDetails';
import Loader from '../Loader/Loader';


const DeviceProfile = ({ device, isAlternative, lat, lng, alt }) =>
{
	return device === undefined ? (<Loader style={{ marginTop: '40vh' }}/>) :
	(
		<>
			<Row style={{ padding: 12 }}>
				<Card>
					<Row
						type='flex'
						style={{ alignItems: 'center' }}
					>
						<DeviceCard
							device={device}
							isAlternative={isAlternative}
							lat={lat}
							lng={lng}
							alt={alt}
						/>
					</Row>
					<Row style={{ padding: 12 }}>
						<DeviceDetails
							device={device}
						/>
					</Row>
				</Card>
			</Row>
		</>
	);
};


export default DeviceProfile;
