import React, { Component } from 'react';
import { request } from 'Lib/request';
import { Form } from 'reactstrap';

import { utils } from 'Lib/utils';
import EmptyWidget from '../../WidgetLib/EmptyWidget/EmptyWidget';
import AlarmRuleSection from '../../Configure/FormSections/AlarmRuleSection/AlarmRuleSection';

import './AlarmRule.css';


class AlarmRule extends Component {

	constructor(props) {
		super(props);

		this.state = {
			allAlarmRules: [],
			allEmails: [],
			allPhones: [],
			newEmail: '',
			newPhone: '',
			inputPhoneVisible: false,
			inputPhoneValue: '',
			inputEmailVisible: false,
			inputEmailValue: '',
			backgroundEmail: 'white',
			backgroundPhone: 'white',
			threshold: undefined,
			loading: true,
		};
		this.timerId = null;

	}

	componentDidMount() {
		this.api_getWidgetAlarmRules();
		this.componentIsMounted = true;
		this.timerId = utils.setRefresh(this.props.widget.DWG_REFRESH_FREQ, this.api_getWidgetAlarmRules);
	}


	componentDidUpdate(prevProps, prevState) {
		if (prevProps.widget.ALR_IDS !== this.props.widget.ALR_IDS) {
			this.api_getWidgetAlarmRules();
		}

	}


	componentWillUnmount() {
		this.componentIsMounted = false;
		if (this.timerId !== null) { utils.clearRefresh(this.timerId); }
	}


	api_getWidgetAlarmRules = (loading = true) => {
		const { widget } = this.props;
		const alrIds = widget.ALR_IDS.split(',').sort((a, b) => a - b);
		let alarmRulesArray = [];

		this.setState({ loading: loading }, () => {
			if (alrIds) {
				alrIds.forEach((alarmruleId) => {
					if (alarmruleId) {
						request.get(
							'alarmrule/' + alarmruleId,
							{
								external_notif: true,
							},
							(alarmrule) => {
								if (alarmrule[0].label !== undefined) {
									alarmRulesArray.push(alarmrule[0]);
									this.setState({ allAlarmRules: alarmRulesArray })
									if (loading === true && alarmrule[0].id === alrIds[0]) {
										this.handleAlarmRuleChange(alrIds[0]);
									}
								}
							},
							(e) => console.error(e),
							() => { this.setState({ loading: false }) }
						);

					}
				});
			}
		})
	}


	api_modifyExternalNotifs = (id) => {

		let emails = this.state.allEmails !== [] ? this.state.allEmails.join(';') : '';
		let phones = this.state.allPhones !== [] ? this.state.allPhones.join(';') : '';

		request.patch(
			'alarmrule/' + id,
			{
				external_notif: true,
				emails: emails,
				phones: phones,
				threshold: this.state.threshold
			},
			(success) => { this.api_getWidgetAlarmRules(false) },
			(error) => console.error(error)
		);
	}


	handleAlarmRuleChange = (id) => {
		let newAlarmRule = this.state.allAlarmRules.find(alarmRule => alarmRule.id === id);
		this.setState({
			selectedAlarmRule: newAlarmRule,
			allEmails: newAlarmRule.anon_recipients.email,
			allPhones: newAlarmRule.anon_recipients.gsm,
			threshold: newAlarmRule.parameter,
		});
	}


	handlePhoneChange = (event) => {
		this.setState({ newPhone: event.target.value });
	}


	handleEmailChange = (event) => {
		this.setState({ newEmail: event.target.value });
	}


	setNewPhoneList = () => {

		if (this.state.newPhone !== '' && this.isPhoneNumber(this.state.newPhone)) {
			let newPhoneList = this.state.allPhones;
			newPhoneList.push(this.state.newPhone);
			this.setState({ allPhones: newPhoneList });
			this.api_modifyExternalNotifs(this.state.selectedAlarmRule.id);
			this.setState({ inputPhoneVisible: false, backgroundPhone: 'white' });
		} else if (this.state.newPhone === '') {
			this.setState({ inputPhoneVisible: false, backgroundPhone: 'white' });
		} else {
			this.setState({ backgroundPhone: 'red' });
		}
	}


	setNewEmailList = () => {

		if (this.state.newEmail !== '' && this.isEmail(this.state.newEmail)) {
			let newEmailList = this.state.allEmails;
			newEmailList.push(this.state.newEmail);
			this.setState({ allEmails: newEmailList });
			this.api_modifyExternalNotifs(this.state.selectedAlarmRule.id);
			this.setState({ inputEmailVisible: false, backgroundEmail: 'white' });
		} else if (this.state.newEmail === '') {
			this.setState({ inputEmailVisible: false, backgroundEmail: 'white' });
		} else {
			this.setState({ backgroundEmail: 'red' });
		}
	}


	setNewThreshold = (event) => {
		this.setState({ threshold: event }, () => this.api_modifyExternalNotifs(this.state.selectedAlarmRule.id));
	}


	setNewThresholdText = (event) => {
		this.setState({ threshold: event.target.value }, () => this.api_modifyExternalNotifs(this.state.selectedAlarmRule.id));
	}


	isEmail = (val) => {
		let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!regEmail.test(val)) {
			return false;
		}
		return true
	}


	isPhoneNumber(val) {
		const regexPhoneNumber = /^((\+))[1-9](\d{2})/;

		if (val.match(regexPhoneNumber) && val.charAt(0) === '+') {
			return true;
		} else {
			return false;
		}
	}


	handleEmailClose = (email) => {
		let filteredEmails = this.state.allEmails.filter(e => e !== email);
		this.setState({ allEmails: filteredEmails }, () => this.api_modifyExternalNotifs(this.state.selectedAlarmRule.id));

	}


	handlePhoneClose = (phone) => {
		let filteredPhones = this.state.allPhones.filter(e => e !== phone);
		this.setState({ allPhones: filteredPhones }, () => this.api_modifyExternalNotifs(this.state.selectedAlarmRule.id));
	}


	showPhoneInput = () => {
		this.setState({ inputPhoneVisible: true, newPhone: '' });
	};


	showEmailInput = () => {
		this.setState({ inputEmailVisible: true, newEmail: '' });
	};


	render() {

		const { loading } = this.state;

		const showEmptyWidget = loading && utils.isConfigMode();

		return (
			<>
				{showEmptyWidget &&
					<EmptyWidget
						{...this.props}
						callToAction={utils.translate('componentWidgetValue.start')}
					/>}

				{!showEmptyWidget &&
					<Form>
						<AlarmRuleSection
							{...this.state}
							currentPage={this.props.currentPage}
							handleAlarmRuleChange={this.handleAlarmRuleChange}
							handlePhoneChange={this.handlePhoneChange}
							handleEmailChange={this.handleEmailChange}
							handleEmailClose={this.handleEmailClose}
							handlePhoneClose={this.handlePhoneClose}
							showPhoneInput={this.showPhoneInput}
							showEmailInput={this.showEmailInput}
							setNewThreshold={this.setNewThreshold}
							setNewThresholdText={this.setNewThresholdText}
							mode='multiple'
							allowClear={true}
							selectedAlarmRules={this.state.allAlarmRules}
							maxTagCount={20}
							setNewPhoneList={this.setNewPhoneList}
							setNewEmailList={this.setNewEmailList}
						/>
					</Form>}
			</>
		)
	}
}


export default AlarmRule;
