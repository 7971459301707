import React from 'react';
import { Row, Col } from 'antd';

import ValueCard from './ValueCard/ValueCard';
import NoData from '../../../NoData/NoData';

import { utils } from 'Lib/utils';

const DatatypePane = ({ device }) =>
{
	return device.configs && device.configs.length > 0 ?
	(
		<Row type='flex'>
			{device.configs.map(datatype =>
				<Col
					xl={8}
					lg={24}
					md={24}
					sm={24}
					key={datatype.id}
					style={{ padding: 12 }}
				>
					<ValueCard
						key={datatype.id}
						title={datatype.label}
						titleSize='h3'
						number={datatype.value}
						unit={datatype.unite_D1}
						date={datatype.date}
					/>
				</Col>
			)}
		</Row>
	) : (
		<NoData
			marginTop={50}
			description={utils.translate('componentdatatypePane.noDty')}
		/>
	);
};


export default DatatypePane;
