import React from 'react';
import { Table, Input, Form } from 'antd';

import './CommandCustomizerTable.css';
import { utils } from 'Lib/utils';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
	<EditableContext.Provider value={form}>
		<tr {...props} />
	</EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);


class EditableCell extends React.Component {


	state = {
		editing: false,
	};


	toggleEdit = () => {

		const editing = !this.state.editing;

		this.setState({ editing }, () => {
			if (editing) {
				this.input.focus();
			}
		});
	};


	save = e => {

		const { record, handleSave } = this.props;

		this.form.validateFields((error, values) => {

			if (error && error[e.currentTarget.id]) { return; }
			this.toggleEdit();
			handleSave({ ...record, ...values });
		});
	};


	renderCell = form => {

		this.form = form;
		const { dataIndex, record } = this.props;
	
		return (

			<Form.Item style={{ margin: 0 }}>
				{form.getFieldDecorator(dataIndex, {
					rules: [
						{
							required: false,
							message: ``,
						},
					],
					initialValue: record[dataIndex],
				})(
					<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />
				)}
			</Form.Item>
		)

	};


	render() {

		const {
			editable,
			dataIndex,
			title,
			record,
			index,
			handleSave,
			children,
			...restProps
		} = this.props;

		return (

			<td {...restProps}>
				{editable ? (
					<EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
				) : (
					children
				)}
			</td>
		);
	}
}


class CommandCustomizerTable extends React.Component {


	constructor(props) {

		super(props);

		this.columns = [
			{
				title: utils.translate('generic.dvc'),
				dataIndex: 'deviceLabel',
				editable: false,
			},
			{
				title: utils.translate('componentDatatypeCustomizerTable.label'),
				dataIndex: 'labelCommand',
				editable: true,
			},

		];
		this.state = {
			dataSource: [],
		};

	}

	componentDidMount() {
		this.getCustomizedCommands();
	}


	componentDidUpdate(prevprops) {
		if (prevprops.userWantsToCustomizeCommands === true && this.props.userWantsToCustomizeCommands === false) {
			this.getCustomizedCommands(true);
		}
		if (prevprops.selectedCommands !== this.props.selectedCommands) {
			this.getCustomizedCommands();
		}
	}


	getCustomizedCommands = (reset) => {
		var selectedCommandsArray = this.props.selectedCommands.length === 1 ? this.props.selectedCommands[0] : this.props.selectedCommands.split(',');
		let index = 0;
		if (!selectedCommandsArray) { return; }
		let stateDatasource = [];
		var allCommandsSorted = this.props.allCommands.sort((a, b) => (a.cmdId > b.cmdId) ? 1 : -1); // sorting so that order matches display order

		allCommandsSorted.forEach(command => {
			if (selectedCommandsArray.includes(command.cmdId)) {
				var row = {
					index: index,
					key: command.cmdId,
					deviceLabel: command.label,
					labelCommand: reset !== true && this.props.widget.JWQ && this.props.widget.JWQ[command.cmdId] && this.props.widget.JWQ[command.cmdId].JWQ_NAME !== '' ? this.props.widget.JWQ[command.cmdId].JWQ_NAME : command.label,
				}

				stateDatasource.push(row);
				index++;
			}
		});
		this.setState({ dataSource: stateDatasource }, () => {
			this.props.saveCustomizationToParentState(this.state.dataSource);
		});
	}


	handleSave = row => {

		const newData = [...this.state.dataSource];

		const index = newData.findIndex(item => row.key === item.key);
		const item = newData[index];

		newData.splice(index, 1, {
			...item,
			...row,
		});

		this.props.saveCustomizationToParentState(newData);
		this.setState({ dataSource: newData });
	};


	render() {

		if (!this.props.userWantsToCustomizeCommands) { return null; }

		const { dataSource } = this.state;


		const components = {
			body: {
				row: EditableFormRow,
				cell: EditableCell,
			},
		};

		const mapColumns = col => {

			const newCol = {
				...col,
				onCell: record => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					title: col.title,
					key: col.key,
					handleSave: this.handleSave
				})
			};
			if (col.children) {
				newCol.children = col.children.map(mapColumns);
			}
			return newCol;
		};

		const columns = this.columns.map(mapColumns);

		return (
			<div className='tableContainer'>
				<Table
					components={components}
					rowClassName={() => 'editable-row'}
					scroll={{ x: 130 }}
					dataSource={dataSource}
					columns={columns}
					pagination={{ pageSize: 10 }}
				/>
			</div>

		);
	}
}


export default CommandCustomizerTable;
