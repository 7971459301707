import React from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { Input, Select, Form } from 'antd';
import { withTranslation } from 'react-i18next';

import { utils } from 'Lib/utils';

const { Option } = Select;


const AttributeEditSection = (props) =>
{

	const {
		attributes,
		handleAttributeEdit,
	} = props;


	return (

		<>
			{attributes && attributes.map((attribute, index) =>
				<FormGroup
					key={attribute.valueId}
				>
					<Row>
						<Col md={{ size: 3 }}>
							<Form.Item>
								<Label>
									{attribute.label}
								</Label>
							</Form.Item>
						</Col>
						<Col md={{ size: 9 }}>
							<Form.Item>
								{attribute.type === 'LIST' ?
								<Select
									showSearch
									placeholder={utils.translate('componentAttributeEditSection.attribute')}
									value={attribute.value}
									style={{ width: '100%' }}
									disabled={attribute.is_read_only === '1'}
									onChange={(value) => handleAttributeEdit(
										{target: { value: value }},
										index
									)}
								>
								{/*
									{target: { value: value }} allows for a single handler function
									(antd inputs send an event)
								*/}
								{attribute.options && Object.values(attribute.options).map((value) =>
									<Option
										key={value}
										value={value}
									>
										{value}
									</Option>
								)}
								</Select>
								:
								<Input
									placeholder={props.t('componentAttributeEditSection.attribute') }
									value={attribute.value}
									disabled={attribute.is_read_only === '1'}
									title={attribute.is_read_only === '1' ? utils.translate('componentAttributeEditSection.readOnly') : ''}
									onChange={(event) => handleAttributeEdit(event, index)}
								/>}
							</Form.Item>
						</Col>
					</Row>
				</FormGroup>
			)}
		</>

	)
};


export default withTranslation()(AttributeEditSection);
