import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils.js';
import { withTranslation } from 'react-i18next';

import './AlarmMarker.css';


class AlarmMarker extends Component { // <SITEMAP> One of many possible implementations of a SitemapMarker

	constructor(props) {

        super(props);

        this.state = {
            device: {},
			newAlarm: null,
        };

		this.timerId = null;

        this.api_getMarkerDevice = this.api_getMarkerDevice.bind(this);
		this.isThereNewAlarm = this.isThereNewAlarm.bind(this);
    }


	componentDidMount() { // init

		if (this.props.widget.DVC_ID == null) { return; }

		this.timerId = utils.setRefresh(this.props.widget.DWG_REFRESH_FREQ, this.api_getMarkerDevice);
        this.api_getMarkerDevice();
    }


    componentDidUpdate(prevProps) {

        if (prevProps.widget.DVC_ID !== this.props.widget.DVC_ID) {
            this.api_getMarkerDevice();
        }
    }


    componentWillUnmount() {

        if (this.timerId !== null) {
            utils.clearRefresh(this.timerId);
        }
    }


	api_getMarkerDevice() { // sets device object linked to marker into local state

        request.get(
            'device/' + this.props.widget.DVC_ID,
            {},
            (device) => {
                this.setState({device: device[0]});
				this.isThereNewAlarm();
            },
            () => console.error('load device failed')
        );
    }


	isThereNewAlarm() {

		this.setState({newAlarm: null});
		let deviceAlarms = this.state.device.active_alarms;

		if (typeof deviceAlarms !== 'undefined') {

			deviceAlarms.map(function (alarm) {

				if (alarm.status === "NEW") {
					this.setState({newAlarm: alarm});
				}

				return true;
			}.bind(this));
		}
	}


    render() {

		const cardStyle = {border: '0', height: '0', backgroundColor: "transparent", paddingTop: utils.isConfigMode() ?  '0' : '15px', borderRadius: utils.isConfigMode() ?  '0' : '50px'};

        return (

			<div className="widget">

				{this.state.newAlarm !== null && // There is an active alarm
					<Tooltip title= {this.state.device.label + "\n --- \n" +
									"Alarm : " + this.state.newAlarm.description + "\n" +
									"Type : " + this.state.newAlarm.type + "\n" +
									"Criticity : " + this.state.newAlarm.level}>
						<Card
							className="activeAlarm"
							style={cardStyle}>
								<FontAwesomeIcon
									className="activeAlarmIcon"
									icon="bell"
									color="rgb(255, 83, 105)"
									style={{margin: 'auto'}}
								/>
		            	</Card>
					</Tooltip>
				}

				{this.state.newAlarm === null && // No active alarm(s)
					<Tooltip title= {this.state.device.label + "\n --- \n " + this.props.t('componentAlarmMarker.noAlarm')}>
						<Card
							className="noAlarmCard"
							style={cardStyle}>
								<FontAwesomeIcon
									icon="bell"
									color="rgb(224, 226, 229)"
									style={{margin: 'auto'}}
								/>
		            	</Card>
					</Tooltip>
				}

			</div>
        );
    }


}


export default withTranslation()(AlarmMarker);
