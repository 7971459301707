import React, { useState, useEffect } from 'react';
import { Card, message } from 'antd';

import SwitchSection from '../../FormSections/SwitchSection/SwitchSection';
import ThresholdSection from '../../FormSections/ThresholdSection/ThresholdSection';
import { utils } from 'Lib/utils';

const WIDGET_TYPE = 'value';
const LOW_THRESHOLD_DEFAULT_COLOR = '#92a3e6';
const HIGH_THRESHOLD_DEFAULT_COLOR = '#ff5369';


const ThresholdConfigure = (props) =>
{

	const {
		widget,
		configuration,
		saveConfiguration
	} = props;

	const [switchChecked, setSwitchChecked] = useState(false);
	const [lowThreshold, setLowThreshold] = useState(undefined);
	const [highThreshold, setHighThreshold] = useState(undefined);
	const [lowThresholdColor, setLowThresholdColor] = useState(LOW_THRESHOLD_DEFAULT_COLOR);
	const [highThresholdColor, setHighThresholdColor] = useState(HIGH_THRESHOLD_DEFAULT_COLOR);

	const colors = (configuration && configuration.colors) || '';
	let colorArray = colors.split(',') || [];


	useEffect(() =>
		{
			const widgetColors = widget && widget.DWV_COLORS;
			const widgetLowThreshold = widget && widget.DWV_LOW_THRESHOLD;
			const widgetHighThreshold = widget && widget.DWV_HIGH_THRESHOLD;

			if (widgetLowThreshold || widgetHighThreshold)
			{
				setSwitchChecked(true);
				widgetLowThreshold && setLowThreshold(widgetLowThreshold);
				widgetHighThreshold && setHighThreshold(widgetHighThreshold);
			}

			if (widgetColors)
			{
				const widgetColorArray = widgetColors.split(',') || [];

				setLowThresholdColor(widgetColorArray[0]);
				setHighThresholdColor(widgetColorArray[1]);
			}
		},
		[]
	);


	useEffect(() =>
		{
			colorArray[0] = lowThresholdColor;
			colorArray[1] = highThresholdColor;

			const colorsToSave = colorArray.toString();

			saveConfiguration({
				...configuration,
				type: WIDGET_TYPE,
				colors: colorsToSave,
			});
		},
		[lowThresholdColor, highThresholdColor]
	);


	useEffect(() =>
		{
			saveConfiguration({
				...configuration,
				type: WIDGET_TYPE,
				low_threshold: lowThreshold,
				high_threshold: highThreshold,
			});
		},
		[lowThreshold, highThreshold]
	);


	const handleSwitch = (checked) =>
	{
		setSwitchChecked(checked);

		if (!checked)
		{
			setLowThreshold(null);
			setHighThreshold(null);
		}
	}


	const handleLowThresholdChange = (value) =>
	{
		if (!highThreshold || value < highThreshold)
		{
			setLowThreshold(value === '' ? null : value);
		}

		else
		{
			message.warning(utils.translate('componentThresholdConfigure.low'));
		}
	}


	const handleHighThresholdChange = (value) =>
	{
		if (!lowThreshold || value > lowThreshold)
		{
			setHighThreshold(value === '' ? null : value);
		}

		else
		{
			message.warning(utils.translate('componentThresholdConfigure.high'));
		}
	}


	const handleLowThresholdColorChange = (color) =>
	{
		color && setLowThresholdColor(color.color);
	}


	const handleHighThresholdColorChange = (color) =>
	{
		color && setHighThresholdColor(color.color);
	}


	return (

		<>
			<SwitchSection
				iconType='vertical-align-middle'
				label={utils.translate('componentThresholdConfigure.define')}
				checked={switchChecked}
				handleSwitch={handleSwitch}
			/>
			{switchChecked &&
			<Card>
				<ThresholdSection
					{...props}
					lowThreshold={lowThreshold}
					highThreshold={highThreshold}
					lowThresholdColor={lowThresholdColor}
					highThresholdColor={highThresholdColor}
					handleLowThresholdChange={handleLowThresholdChange}
					handleHighThresholdChange={handleHighThresholdChange}
					handleLowThresholdColorChange={handleLowThresholdColorChange}
					handleHighThresholdColorChange={handleHighThresholdColorChange}
				/>
			</Card>}
		</>
	)
};


export default ThresholdConfigure;
