import React, { useState, useEffect } from 'react';

import { utils } from 'Lib/utils';
import CommandCustomizer from
	'../../FormSections/CommandCustomizerSection/CommandCustomizer.js';

const CommandCustomizationConfigure = (props) =>
{

	const {
		widget,
		selectedCommands,
		saveConfiguration,
		allCommands,
		getCurrentConfiguration
	} = props;

	const [commandCustomization, setCommandCustomization] = useState([]);
	const [userWantsToCustomizeCommands, setUserWantsToCustomizeCommands] = useState(false);


	useEffect(() =>
		{
			setUserWantsToCustomizeCommands(areCommandsCustomized());
		},
		[]
	);


	useEffect(() =>
		{
			let configuration = getCurrentConfiguration();
			configuration.commandCustomization = commandCustomization;
			saveConfiguration({
				...configuration
			});

		},
		[commandCustomization]
	);


	const areCommandsCustomized = () =>
	{
		let commandsAreCustomized = false;

		if (widget.JWQ)
		{
			Object.keys(widget.JWQ).forEach(key =>
			{
				const value = widget.JWQ[key]

				if (value.JWQ_NAME !== value.CMD_LABEL)
				{ 
					if (value.JWQ_NAME !== "") { commandsAreCustomized = true;}
				}
			});
		}
		return commandsAreCustomized;
	}


	const handleCustomizerToggle = (checked) =>
	{
		setUserWantsToCustomizeCommands(checked);
    }


	const saveCustomizationToState = (commandCustomization) =>
	{
		let commandCustomizationCopy = JSON.parse(JSON.stringify(commandCustomization));
        setCommandCustomization(commandCustomizationCopy);
    }


	return (

		<CommandCustomizer
			label={utils.translate('componentWidgetCommand.label')}
			description={utils.translate('componentDatatypeCustomizationConfigure.select')}
			allCommands={allCommands}
			selectedCommands={selectedCommands}
			widget={widget}
			userWantsToCustomizeCommands={userWantsToCustomizeCommands}
			handleCustomizerToggle={handleCustomizerToggle}
			saveCustomizationToParentState={saveCustomizationToState}
			getCurrentConfiguration={getCurrentConfiguration}
		/>
	)
};


export default CommandCustomizationConfigure;
