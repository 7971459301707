import React, { useState, useEffect, useRef } from 'react';

import ComplexMarkerSectionView from './ComplexMarkerSectionView';

import { request } from 'Lib/request';
import { MAX_MARKER_DATA_ELEMENTS } from 'Config/config';

const ATR = 'ATR';
const DTY = 'DTY';
const DEFAULT_COLORS = {
	INACTIVE: '#15CF3D',
	ACTIVE: '#E30000',
	DEFAULT: '#1890FF',
};

const usePrevProps = (value) =>
{
	const ref = useRef();

	useEffect(() => { ref.current = value; });

	return ref.current;
}


const ComplexMarkerSection = (props) =>
{

	const {
		widget,
		categoryIds,
		saveToParent,
		devicesLoading,
	} = props;

	const prevProps = usePrevProps({ categoryIds });

	const [categoriesLoading, setCategoriesLoading] = useState(false);
	const [showValueNextToMarkers, setShowValueNextToMarkers] = useState(true);
	const [showGeolocOnly, setShowGeolocOnly] = useState(false);
	const [availableDatatypes, setAvailableDatatypes] = useState([]);
	const [availableAttributes, setAvailableAttributes] = useState([]);
	const [availableDatatypesToBind, setAvailableDatatypesToBind] = useState([]);
	const [availableAttributesToBind, setAvailableAttributesToBind] = useState([]);
	const [selectedDatatypes, setSelectedDatatypes] = useState([]);
	const [selectedAtrGeoloc, setSelectedAtrGeoloc] = useState(undefined);
	const [selectedAttributes, setSelectedAttributes] = useState([]);
	const [selectedDataSourceToBind, setSelectedDataSourceToBind] = useState(undefined);
	const [threshold, setThreshold] = useState(undefined);
	const [selectedOperator, setSelectedOperator] = useState(undefined);
	const [inactiveColor, setInactiveColor] = useState(DEFAULT_COLORS.INACTIVE);
	const [activeColor, setActiveColor] = useState(DEFAULT_COLORS.ACTIVE);
	const [defaultColor, setDefaultColor] = useState(DEFAULT_COLORS.DEFAULT);
	const [inactiveColorLegend, setInactiveColorLegend] = useState(undefined);
	const [activeColorLegend, setActiveColorLegend] = useState(undefined);
	const [defaultColorLegend, setDefaultColorLegend] = useState(undefined);

	const noCategoryIds = !categoryIds || categoryIds.length === 0;
	const categoryIdsHaveChanged = !prevProps || (prevProps &&
		JSON.stringify(prevProps.categoryIds) !== JSON.stringify(categoryIds));

	const thresholdIsNumerical = !isNaN(threshold);
	const maxElementsReached = selectedDatatypes.length +
		selectedAttributes.length === MAX_MARKER_DATA_ELEMENTS;


	useEffect(() =>
		{
			if (widget)
			{
				if (widget.ATR_IDS && widget.DTY_IDS)
				{
					const dtyIds = widget.DTY_IDS.map(dty => dty.ID);
					const atrIds = widget.ATR_IDS.map(atr => atr.ID);

					setSelectedDatatypes(dtyIds);
					setSelectedAttributes(atrIds);

					const masterDty = widget.DTY_IDS.find((dty) => dty.IS_MASTER === true);
					const masterAtr = widget.ATR_IDS.find((atr) => atr.IS_MASTER === true);
					const alternativeGeoloc = widget.ATR_IDS.find((atr) => atr.GEO_ALTERNATIVE === true);

					if (masterDty)
						setSelectedDataSourceToBind({ id: masterDty.ID, data_type: DTY });

					else if (masterAtr)
						setSelectedDataSourceToBind({ id: masterAtr.ID, data_type: ATR });

					if (alternativeGeoloc)
						setSelectedAtrGeoloc({ id: alternativeGeoloc.ID, data_type: ATR });
				}

				setThreshold(widget.DTH_THRESHOLD_VALUE);
				setSelectedOperator(widget.DTH_OPERATOR);

				if (widget.DTH_SHOW_VALUE) setShowValueNextToMarkers(widget.DTH_SHOW_VALUE === '1');
				if (widget.DTH_SHOW_GEOLOC_ONLY) setShowGeolocOnly(widget.DTH_SHOW_GEOLOC_ONLY === '1');
				if (widget.DTH_INACTIVE_COLOR) setInactiveColor(widget.DTH_INACTIVE_COLOR);
				if (widget.DTH_ACTIVE_COLOR) setActiveColor(widget.DTH_ACTIVE_COLOR);
				if (widget.DTH_DEFAULT_COLOR) setDefaultColor(widget.DTH_DEFAULT_COLOR);

				setInactiveColorLegend(widget.DTH_INACTIVE_LEGEND);
				setActiveColorLegend(widget.DTH_ACTIVE_LEGEND);
				setDefaultColorLegend(widget.DTH_DEFAULT_LEGEND);
			}
		},
		[]
	);


	useEffect(() =>
		{
			if (categoryIds && categoryIds.length > 0 && categoryIdsHaveChanged)
				getCategories();

			if (noCategoryIds)
			{
				setAvailableDatatypes([]);
				setAvailableAttributes([]);
			}
		},
		[categoryIds]
	);


	useEffect(() =>
		{
			if (!selectedDatatypes.length)
				setAvailableDatatypesToBind([]);

			else
			{
				const selectedDatatypeObjects = availableDatatypes
					.filter(d => selectedDatatypes.includes(d.id));

				selectedDatatypeObjects.forEach(d => d.data_type = DTY);
				setAvailableDatatypesToBind(selectedDatatypeObjects);
			}

			saveToConfiguration('dty_ids', selectedDatatypes);
		},
		[availableDatatypes, selectedDatatypes]
	);


	useEffect(() =>
		{
			if (!selectedAttributes.length)
				setAvailableAttributesToBind([]);

			else
			{
				const selectedAttributeObjects = availableAttributes
					.filter(a => selectedAttributes.includes(a.id));

				selectedAttributeObjects.forEach(a => a.data_type = ATR);
				setAvailableAttributesToBind(selectedAttributeObjects);
			}

			saveToConfiguration('atr_ids', selectedAttributes);
		},
		[availableAttributes, selectedAttributes]
	);


	useEffect(() =>
		saveToConfiguration('alternative_geoloc', selectedAtrGeoloc || null),
		[selectedAtrGeoloc]
	);


	useEffect(() =>
		saveToConfiguration('master_datasource', selectedDataSourceToBind || null),
		[selectedDataSourceToBind]
	);


	useEffect(() =>
		saveToConfiguration('threshold_value', threshold || null),
		[threshold]
	);


	useEffect(
		() => saveToConfiguration('dth_operator', selectedOperator || null),
		[selectedOperator]
	);


	useEffect(
		() => saveToConfiguration('inactive_color', inactiveColor || null),
		[inactiveColor]
	);


	useEffect(
		() => saveToConfiguration('active_color', activeColor || null),
		[activeColor]
	);


	useEffect(
		() => saveToConfiguration('default_color', defaultColor || null),
		[defaultColor]
	);


	useEffect(
		() => saveToConfiguration('inactive_legend', inactiveColorLegend || null),
		[inactiveColorLegend]
	);


	useEffect(
		() => saveToConfiguration('active_legend', activeColorLegend || null),
		[activeColorLegend]
	);


	useEffect(
		() => saveToConfiguration('default_legend', defaultColorLegend || null),
		[defaultColorLegend]
	);


	useEffect(
		() => saveToConfiguration('show_marker_value', showValueNextToMarkers),
		[showValueNextToMarkers]
	);

	
	useEffect(
		() => saveToConfiguration('show_geoloc_only', showGeolocOnly),
		[showGeolocOnly]
	);


	const saveToConfiguration = (key, value) => saveToParent(key, value);


	const getCategories = () =>
	{
		if (categoryIds)
		{
			let datatypeArray = [];
			let attributeArray = [];
			let index = 0;

			setCategoriesLoading(true);

			categoryIds.forEach((categoryId) =>
			{
				request.get(
					'category/' + categoryId, {},
					(category) =>
					{
						category = category['0']['0'];

						const existingDatatypeIds = new Set(datatypeArray.map(d => d.id));
						const existingAttributeIds = new Set(attributeArray.map(a => a.id));

						if (category.datatypes)
						{
							datatypeArray = [
								...datatypeArray,
								...category.datatypes.filter(d => !existingDatatypeIds.has(d.id)),
							];
						}

						if (category.attributes)
						{
							attributeArray = [
								...attributeArray,
								...category.attributes.filter(a => !existingAttributeIds.has(a.id)),
							];
						}
					},
					(error) => console.error(error),
					() =>
					{
						index ++;

						if (index === categoryIds.length)
						{
							setAvailableDatatypes(datatypeArray);
							setAvailableAttributes(attributeArray);
							setCategoriesLoading(false);
						}
					},
				);
			});
		}
	}


	const handleDatatypeSelect = (value) =>
	{
		setSelectedDatatypes(value);
		checkBindSelect(value, DTY);
	}


	const handleAttributeSelect = (value) =>
	{
		setSelectedAttributes(value);
		checkBindSelect(value, ATR);
		checkAlternativeGeoSelect(value, ATR);
	}


	const checkBindSelect = (value, type) =>
	{ // removes selected bind value if no longer present among available options
		if (selectedDataSourceToBind && selectedDataSourceToBind.data_type === type)
		{
			if (value && !value.includes(selectedDataSourceToBind.id))
				setSelectedDataSourceToBind(undefined);
		}
	}


	const checkAlternativeGeoSelect = (value, type) =>
	{ // removes selected bind value if no longer present among available options
		if (selectedAtrGeoloc && selectedAtrGeoloc.data_type === type)
		{
			if (value && !value.includes(selectedAtrGeoloc.id))
				setSelectedAtrGeoloc(undefined);
		}
	}




	const handleBindSelect = (value) =>
	{ // value is json string because antd select cannot have object as value, only string
		if (value) value = JSON.parse(value);
		setSelectedDataSourceToBind(value);
	}


	const handleAtrGeolocSelect = (value) =>
	{   
		if (value) value = JSON.parse(value);
		setSelectedAtrGeoloc(value);
	}


	const handleColorChange = (color, type) =>
	{
		color = color.color;

		switch (type)
		{
			case 'INACTIVE':
				setInactiveColor(color);
				break;
			case 'ACTIVE':
				setActiveColor(color);
				break;
			default:
				setDefaultColor(color);
				break;
		}
	}


	return (

		<ComplexMarkerSectionView
			categoryIds={categoryIds}
			availableDatatypes={availableDatatypes}
			availableAttributes={availableAttributes}
			availableDatatypesToBind={availableDatatypesToBind}
			availableAttributesToBind={availableAttributesToBind}
			selectedDatatypes={selectedDatatypes}
			selectedAttributes={selectedAttributes}
			selectedDataSourceToBind={selectedDataSourceToBind}
			selectedOperator={selectedOperator}
			threshold={threshold}
			inactiveColor={inactiveColor}
			activeColor={activeColor}
			defaultColor={defaultColor}
			inactiveColorLegend={inactiveColorLegend}
			activeColorLegend={activeColorLegend}
			defaultColorLegend={defaultColorLegend}
			categoriesLoading={categoriesLoading}
			devicesLoading={devicesLoading}
			maxElementsReached={maxElementsReached}
			thresholdIsNumerical={thresholdIsNumerical}
			showValueNextToMarkers={showValueNextToMarkers}
			noCategoryIds={noCategoryIds}
			handleBindSelect={handleBindSelect}
			handleDatatypeSelect={handleDatatypeSelect}
			handleAtrGeolocSelect={handleAtrGeolocSelect}
			handleAttributeSelect={handleAttributeSelect}
			setSelectedOperator={setSelectedOperator}
			setThreshold={setThreshold}
			setInactiveColorLegend={setInactiveColorLegend}
			setActiveColorLegend={setActiveColorLegend}
			setDefaultColorLegend={setDefaultColorLegend}
			setShowValueNextToMarkers={setShowValueNextToMarkers}
			showGeolocOnly={showGeolocOnly}
			setShowGeolocOnly={setShowGeolocOnly}
			handleColorChange={handleColorChange}
			selectedAtrGeoloc={selectedAtrGeoloc}
			widget={widget}
		/>
	)
};


export default ComplexMarkerSection;
