import React from 'react';
import Odometer from 'react-odometerjs';

import NoData from '../../../WidgetLib/NoData/NoData';
import { SkinModel } from 'Models/SkinModel';

import 'Styles/SkinIndex/themes/odometer-theme-train-station.css';
import 'Styles/SkinIndex/themes/odometer-theme-car.css';
import 'Styles/SkinIndex/themes/odometer-theme-digital.css';
import 'Styles/SkinIndex/themes/odometer-theme-minimal.css';
import 'Styles/SkinIndex/themes/odometer-theme-plaza.css';
import 'Styles/SkinIndex/themes/odometer-theme-gazpar.css';
import 'Styles/SkinIndex/themes/odometer-theme-heat.css';

import compteur_deau from 'Styles/SkinIndex/picture/compteur_deau.png';
import compteur_de_gaz from 'Styles/SkinIndex/picture/compteur-de-gaz.png';
import compteur_delectricite from 'Styles/SkinIndex/picture/compteur-delectricite.png';
import compteur_linky from 'Styles/SkinIndex/picture/compteur_linky.png';
import gazpar from 'Styles/SkinIndex/picture/compteur-gazpar.png';
import heat_meeter from 'Styles/SkinIndex/picture/heat_meeter.png';



import './IndexValue.css';


const IndexValue = (props) => {

	const {
		showLastDate
	} = props;

	const maxBefore = SkinModel[props.widget.DWV_SKIN].maxBefore;
	const maxAfter = SkinModel[props.widget.DWV_SKIN].maxAfter;

	const layout = props.widget.DWG_DECOR.widgetLayout ? props.widget.DWG_DECOR.widgetLayout : 'default';
	

	const getImageTheme = (image) => {
		if (image === 'water') {
			return compteur_deau;
		}
		if (image === 'gaz') {
			return compteur_de_gaz;
		}
		if (image === 'electricity') {
			return compteur_delectricite;
		}
		if (image === 'linky') {
			return compteur_linky;
		}
		if (image === 'gazpar') {
			return gazpar;
		}
		if (image === 'heat') {
			return heat_meeter;
		}
		
	}

	
	const getZero = (value, maxBefore, maxAfter) => {
		let values = value.toFixed(maxAfter);

		let after = values.includes('.') ? values.split('.')[1] : '';
		let before = values.includes('.') ? values.split('.')[0] : values;
		let lengthAfter = after.length;
		let lengthBefore = before.length;
		
		if (values.length <= 9) {
			if (values.includes('.')) {
				while (lengthBefore < maxBefore) {
					before = '0' + before;
					lengthBefore++;
				}

				before = '1' + before;

				while (lengthAfter < maxAfter) {
					after = after + '0';
					lengthAfter++;
				}

				after = after + '1';
				values = before + '.' + after;

			} else {
				let length = values.length;

				while (length < maxBefore) {
					values = '0' + values;
					length++;
				}
				
				if (values < 0) {
					values = '-' + values;
					values = values + '.0001';	
				} else {
					values = '1' + values;
					values = values + '.0001';
				}
				
			}
		} else {
			values = '1' + values + '1';
		}

		if (lengthBefore > maxBefore && props.widget.DWV_SKIN !== 'SKIN_DEFAULT') {
			props.widget.DWV_SKIN = 'SKIN_DEFAULT';
		}
		return values;
	}

	const image = props.widget.DWV_SKIN === 'SKIN_DEFAULT' && (props.widget.DWV_IMAGE || props.getWidgetImage(props.value)[0]) &&
		<div className='flex-center'>
			<img
				src={props.getWidgetImage(props.value)[0]}
				alt=''
				className="img"
				style={{ width: `${Math.min(props.size.height, props.size.width) / 2}px`, maxWidth: '100%' }}
			/>
		</div>;

		const info = 
			<div>
				{props.getWidgetImage(props.value)[1] &&
				<div className='title-index'>
					{props.getWidgetImage(props.value)[1]}
				</div>}
				<div className="title-index">
					{props.deviceLabel}
				</div>
				<div className='flex-center'>
					<span>{props.label} {props.configUnit && '(' + props.configUnit + ')'}</span>
				</div>
			</div>;

		const data = typeof props.config !== 'undefined' && 
			<div>
				{props.widget.DWV_SKIN !== 'SKIN_DEFAULT' &&
				<div className='flex-center'>
					<img alt='index skin' src={getImageTheme(SkinModel[props.widget.DWV_SKIN].image)} className={
						props.widget.DWV_SKIN === 'SKIN_WATER' ? 'image-water' : 
						props.widget.DWV_SKIN === 'SKIN_GAZ' ? 'image-gaz' : 
						props.widget.DWV_SKIN === 'SKIN_ELECTRICITY' ? 'image-electricity' : 
						props.widget.DWV_SKIN === 'SKIN_LINKY' ? 'image-linky' : 
						props.widget.DWV_SKIN === 'SKIN_GAZPAR' ? 'image-gazpar' :
						props.widget.DWV_SKIN === 'SKIN_HEAT' ? 'image-heat' : ''} 
					/>
				</div>}
				{!isNaN(props.value) &&
				<div className='flex-center'>
					<Odometer key={props.widget.DWV_SKIN} value={getZero(props.value, maxBefore, maxAfter)}
						format='( ddd).dddddddddd' theme={SkinModel[props.widget.DWV_SKIN].theme}
					/>
				</div>}
				{showLastDate &&
					<div className="date-index flex-center">
						{props.config.date}
					</div>
				}
			</div>;

	return (


		<div 
			id={props.getWidgetId + '_CONTENT'}
			className={'INDEX_CONTENT ' + (props.size.height > 230 ? 'flex-center' : props.size.height < 230 && props.widget.DWV_SKIN === 'SKIN_DEFAULT' ? 'flex-center' : '')}
		>
			<div className='value-content'>
				{layout === 'default' &&
				<>
					{image}
					{info}
					{data}
				</>}

				{layout === 'col-2' &&
				<>
					{info}
					<div className='DWG_DIS_COL-2'>
						{image}
						{data}
					</div>
				</>}

				{layout === 'col-3' &&
				<div className='DWG_DIS_COL-3'>
					{info}
					{image}
					{data}
				</div>}
				{typeof props.config === 'undefined' &&
				<>
					<NoData></NoData>
				</>}
			</div>
		</div>
	);
}


export default IndexValue;
