import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup, Label } from 'reactstrap';
import { Input, Divider, Select, Form, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import Marker from '../../Map/Marker/Marker';

import { GMAP_KEY } from 'Config/config';
import { request } from 'Lib/request';
import { utils } from 'Lib/utils';
import { timezones } from 'Lib/timezones';

import './CreateForm.css';

const { Option } = Select;


class CreateForm extends Component
{

    constructor(props)
	{
        super(props);

        this.state = {
			categories: [],
			containers: [],
			protocols: [],
			modalToggled: false,
			missingLabel: true,
			missingCategory: true,
			missingProtocol: true,
			missingContainer: true,
			formIsValid: false,
			labelExists: false,
			deviceToCreate: {
				label: null,
				category: { id: null },
				container: { id: null },
				protocol: { id: null },
				service_provider: null,
				status: "UNAVAILABLE",
				latitude: null,
				longitude: null,
				timezone: null,
				attributes: []
			}
        }

		this.componentIsMounted = false;
    }


	componentWillReceiveProps(newProps)
	{
		if (!newProps.isOpen)
		{
			this.setState({ // resetting form when modal is closed
				deviceToCreate: {
					label: null,
					category: { id: null },
					container: { id: null },
					protocol: { id: null },
					service_provider: null,
					status: "UNAVAILABLE",
					latitude: null,
					longitude: null,
					timezone: null,
					attributes: []
				},
				missingLabel: true,
				missingCategory: true,
				missingProtocol: true,
				missingContainer: true,
				formIsValid: false,
				labelExists: false,
			});
		}
	}


	componentDidMount()
	{
		this.componentIsMounted = true;

		this.api_getCategories();
		this.api_getContainers();
		this.api_getProtocols();
	}


	componentWillUnmount()
	{
		this.componentIsMounted = false;
    }


	api_getCategories = () =>
	{
		request.get(
	        'category',
	        {
				class: "device"
			},
	        (categories) => {
				if (this.componentIsMounted) { this.setState({ categories: categories }); }
			},
	        () => console.error('load categories failed')
	    );
	}


	api_getContainers = () =>
	{
		request.get(
	        'container',
	        {},
	        (containers) => {
				if (this.componentIsMounted) { this.setState({ containers: containers }); }
			},
	        () => console.error('load containers failed')
	    );
	}


	api_getProtocols = () =>
	{
		request.get(
	        'protocol',
	        {},
	        (protocols) => {
				if (this.componentIsMounted) { this.setState({ protocols: protocols }); }
			},
	        () => console.error('load protocols failed')
	    );
	}


	api_getCategory = (categoryId) =>
	{
		let deviceToCreate = this.state.deviceToCreate;

		request.get(
	        'category/' + categoryId,
	        {},
	        (category) => {
				deviceToCreate.category = category[0][0];
				deviceToCreate.attributes = category[0][0].attributes;
				this.setState({
					deviceToCreate: deviceToCreate,
					missingCategory: false
				});
				this.formIsValid();
			},
	        () => console.error('load category failed')
	    );
	}


	getCoordinatesFromMap = (value) =>
	{
		let deviceToCreate = this.state.deviceToCreate;
		deviceToCreate.latitude = value.lat;
		deviceToCreate.longitude = value.lng;

		this.setState({ deviceToCreate: deviceToCreate });
	}


	handleLabelChange = (event) =>
	{
		let newLabel = event.target.value;
		let deviceToCreate = this.state.deviceToCreate;
		deviceToCreate.label = newLabel;

		this.setState({
			deviceToCreate: deviceToCreate,
			missingLabel: false,
			labelExists: false
		});

		this.formIsValid();
	}


	handleCategoryChange = (newCategoryId) =>
	{
		this.api_getCategory(newCategoryId);
	}


	handleProtocolChange = (newProtocolId) =>
	{
		let deviceToCreate = this.state.deviceToCreate;
		deviceToCreate.protocol.id = newProtocolId;

		this.setState({
			deviceToCreate: deviceToCreate,
			missingProtocol: false
		});

		this.formIsValid();
	}


	handleContainerChange = (newContainerId) =>
	{
		let deviceToCreate = this.state.deviceToCreate;
		deviceToCreate.container.id = newContainerId;

		this.state.containers.forEach(container =>
		{
			if (container.id === newContainerId)
			{
				deviceToCreate.service_provider = container.service_provider;
			}
		});

		this.setState({
			deviceToCreate: deviceToCreate,
			missingContainer: false
		});

		this.formIsValid();
	}


	handleTimezoneChange = (newTimezone) =>
	{
		let deviceToCreate = this.state.deviceToCreate;
		deviceToCreate.timezone = newTimezone;

		this.setState({ deviceToCreate: deviceToCreate });
	}


	handleLocationChange = (event, locationType) =>
	{
		let newLocationData = event.target.value;

		if (newLocationData && newLocationData.toString().match(/[a-z]/i)) { return; } // filtering out letters

		let deviceToCreate = this.state.deviceToCreate;
		deviceToCreate[locationType] = newLocationData;

		this.setState({ deviceToCreate: deviceToCreate });
	}


	handleAttributeChange = (event, index) =>
	{
		let newAttributeValue = event.target.value;
		let deviceToCreate = this.state.deviceToCreate;
		deviceToCreate.attributes[index].value = newAttributeValue;

		this.setState({ deviceToCreate: deviceToCreate });
	}


	formIsValid = () =>
	{
		let isValidForm = true;

		if (!this.state.deviceToCreate.label || this.state.deviceToCreate.label.length === 0)
		{
			this.setState({ missingLabel: true });
			isValidForm = false;
		}
		if (!this.state.deviceToCreate.category.id)
		{
			this.setState({ missingCategory: true });
			isValidForm = false;
		}
		if (!this.state.deviceToCreate.protocol.id)
		{
			this.setState({ missingProtocol: true });
			isValidForm = false;
		}
		if (!this.state.deviceToCreate.container.id)
		{
			this.setState({ missingContainer: true });
			isValidForm = false;
		}

		this.setState({ formIsValid: isValidForm });

		return isValidForm;
	}


	api_createDevice = () =>
	{
		if (!this.formIsValid()) { return; }

		let apiAttributes = [];
		let apiAttribute = {};
		let deviceToCreate = this.state.deviceToCreate;

		deviceToCreate.attributes.forEach(stateAttribute =>
		{
			if (stateAttribute.value)
			{
				apiAttribute[stateAttribute.code] = stateAttribute.value;
				apiAttributes.push(apiAttribute);
				apiAttribute = {};
			}
		});

		request.post(
			'device',
			{
				device_label: deviceToCreate.label,
				category: deviceToCreate.category.id,
				container: deviceToCreate.container.id,
				protocol: deviceToCreate.protocol.id,
				service_provider: deviceToCreate.service_provider,
				device_timezone: deviceToCreate.timezone,
				device_latitude: deviceToCreate.latitude,
				device_longitude: deviceToCreate.longitude,
				dynamic_attribs: apiAttributes
			},
			(res) =>
			{
				this.props.closeModal();
				this.props.saveToParent("created", true);

				utils.openNotification(
					"success",
					this.props.t('componentCreateForm.createdDVC'),
					this.props.t('componentCreateForm.success1') + deviceToCreate.label + this.props.t('componentCreateForm.success2') + deviceToCreate.category.label + ').',
					"bottomRight"
				);

				setTimeout(function()
				{ // will reset add button in table
					this.props.saveToParent("created", false);
		        }.bind(this), 2000);
			},
			(error) =>
			{	
				if(error.error_number === 141) {
					utils.openNotification(
						"error",
						utils.translate('componentCreateForm.notCreatedDVC'),
						utils.translate('componentCreateForm.notPermitted'),
						"bottomRight"
					);
				}
				else if (error.error_number === 132)
				{ // api is telling us that device label already exists (see GET /error for all error codes)
					this.setState({ labelExists: true });

					utils.openNotification(
						"error",
						utils.translate('componentCreateForm.labelConflict1'),
						utils.translate('componentCreateForm.labelConflict2'),
						"bottomRight"
					);
				}
			}
		);
	}


    render()
	{
        return (

            <Modal className="wideModal" onMouseDown={e => e.stopPropagation()} isOpen={this.props.isOpen} toggle={this.props.closeModal} size="lg">
                <ModalHeader toggle={this.props.closeModal} style={{paddingBottom: 0}}>
				<FontAwesomeIcon className="form-icon" icon="plus-circle"/>
				{this.state.deviceToCreate.label ? utils.translate('generic.newDvc') + " " + this.state.deviceToCreate.label : utils.translate('generic.newDvc')}
				<small className="smallForm">
					{utils.translate('componentCreateForm.info1')}
				</small>
				<Divider />
				</ModalHeader>
                <ModalBody>
					<p className="formTitle">
						<FontAwesomeIcon className="form-icon" icon="info"/>{utils.translate('componentCreateForm.info2')}
					</p>
					<FormGroup className="deviceForm">
						<Row>
							<Col md={{size: 3}}>
								<Form.Item>
									<Label className="label">
									{utils.translate('generic.labelDvc')}<span style={{color: "red"}}>*</span>
									</Label>
								</Form.Item>
							</Col>
							<Col md={{size: 9}}>
								<Form.Item
									validateStatus={this.state.labelExists ? "error" : ""}
									hasFeedback={this.state.missingLabel || this.state.labelExists ? true : false}
									help={this.state.missingLabel ? utils.translate('componentCreateForm.label2') : (this.state.labelExists ? utils.translate('componentCreateForm.label3') : "")}
								>
									<Input
										placeholder={this.props.t('componentCreateForm.label4')}
										value={this.state.deviceToCreate.label ? this.state.deviceToCreate.label : undefined}
										onChange={this.handleLabelChange}
									/>
								</Form.Item>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className="deviceForm">
						<Row>
							<Col md={{size: 3}}>
								<Form.Item>
									<Label className="label">
										{utils.translate('componentCreateForm.cat1')}<span style={{color: "red"}}>*</span>
									</Label>
								</Form.Item>
							</Col>
							<Col md={{size: 9}}>
								<Form.Item
									hasFeedback={this.state.missingCategory ? true : false}
									help={this.state.missingCategory ? utils.translate('componentCreateForm.cat2') : ""}
								>
									<Select
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) => option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										placeholder={utils.translate('componentCreateForm.cat3')}
										value={this.state.deviceToCreate.category.id ? this.state.deviceToCreate.category.id : undefined}
										style={{width: "100%"}}
										onChange={this.handleCategoryChange}
									>
									{this.state.categories.map((category) =>
										<Option key={category.label} value={category.id}>{category.label}</Option>
									)}
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className="deviceForm">
						<Row>
							<Col md={{size: 3}}>
								<Form.Item>
									<Label className="label">
										{utils.translate('componentCreateForm.pro1')}<span style={{color: "red"}}>*</span>
									</Label>
								</Form.Item>
							</Col>
							<Col md={{size: 9}}>
								<Form.Item
									hasFeedback={this.state.missingProtocol ? true : false}
									help={this.state.missingProtocol ? utils.translate('componentCreateForm.pro2') : ""}
								>
									<Select
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) => option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										placeholder={utils.translate('componentCreateForm.pro3')}
										value={this.state.deviceToCreate.protocol.id ? this.state.deviceToCreate.protocol.id : undefined}
										style={{width: "100%"}}
										onChange={this.handleProtocolChange}
									>
									{this.state.protocols.map((protocol) =>
										<Option key={protocol.reference} value={protocol.id}>{protocol.reference}</Option>
									)}
									<Option key={"4"} value={"4"}>{"protocol.reference"}</Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className="deviceForm">
						<Row>
							<Col md={{size: 3}}>
								<Form.Item>
									<Label className="label">
										{utils.translate('componentCreateForm.ctr1')}<span style={{color: "red"}}>*</span>
									</Label>
								</Form.Item>
							</Col>
							<Col md={{size: 9}}>
								<Form.Item
									hasFeedback={this.state.missingContainer ? true : false}
									help={this.state.missingContainer ? utils.translate('componentCreateForm.ctr2') : ""}
								>
									<Select
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) => option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										placeholder={this.props.t('componentCreateForm.ctr3')}
										value={this.state.deviceToCreate.container.id ? this.state.deviceToCreate.container.id : undefined}
										style={{width: "100%"}}
										onChange={this.handleContainerChange}
									>
									{this.state.containers.map((container) =>
										<Option key={container.name} value={container.id}>{container.name}</Option>
									)}
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className="deviceForm">
						<Row>
							<Col md={{size: 3}}>
								<Form.Item>
									<Label className="label">
										{utils.translate('componentCreateForm.tz1')}
									</Label>
								</Form.Item>
							</Col>
							<Col md={{size: 9}}>
								<Form.Item>
									<Select
										showSearch
										placeholder={this.props.t('componentCreateForm.tz2')}
										value={this.state.deviceToCreate.timezone ? this.state.deviceToCreate.timezone : undefined}
										style={{width: "100%"}}
										onChange={this.handleTimezoneChange}
									>
									{timezones.map((timezone) =>
										<Option key={timezone} value={timezone}>{timezone}</Option>
									)}
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</FormGroup>
					<Divider />
					<p className="formTitle">
						<FontAwesomeIcon className="form-icon" icon="location-arrow"/>{utils.translate('componentCreateForm.loc1')}
						<small className="smallForm">
							{utils.translate('componentCreateForm.loc2')}
						</small>
					</p>
					<FormGroup className="deviceForm">
						<Row>
							<Col md={{size: 3}}>
								<Form.Item>
									<Label className="label">
										{utils.translate('componentCreateForm.lat1')}
									</Label>
								</Form.Item>
							</Col>
							<Col md={{size: 9}}>
								<Form.Item>
									<Input
										placeholder={this.props.t('componentCreateForm.lat2')}
										value={this.state.deviceToCreate.latitude ? this.state.deviceToCreate.latitude : ""}
										onChange={(event) => this.handleLocationChange(event, "latitude")}
									/>
								</Form.Item>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className="deviceForm">
						<Row>
							<Col md={{size: 3}}>
								<Form.Item>
									<Label className="label">
										{utils.translate('componentCreateForm.lon1')}
									</Label>
								</Form.Item>
							</Col>
							<Col md={{size: 9}}>
								<Form.Item>
									<Input
										placeholder={this.props.t('componentCreateForm.lon2')}
										value={this.state.deviceToCreate.longitude ? this.state.deviceToCreate.longitude : ""}
										onChange={(event) => this.handleLocationChange(event, "longitude")}
									/>
								</Form.Item>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className="deviceForm">
						<Row>
							<Col md={{size: 3}}>
							</Col>
							<Col md={{size: 9}}>
								{this.state.deviceToCreate &&
									<Form.Item>
										<div style={{ height: '250px', width: '100%' }}>
											<GoogleMapReact
												bootstrapURLKeys={{ key: GMAP_KEY }}
												center={{
									    			lat: (this.state.deviceToCreate.latitude === '' || this.state.deviceToCreate.longitude === '' || this.state.deviceToCreate.latitude === '0' || this.state.deviceToCreate.longitude === '0' || !this.state.deviceToCreate.longitude || !this.state.deviceToCreate.latitude) ?
													48.864716 : parseFloat(this.state.deviceToCreate.latitude),
									                lng: (this.state.deviceToCreate.latitude === '' || this.state.deviceToCreate.longitude === '' || this.state.deviceToCreate.latitude === '0' || this.state.deviceToCreate.longitude === '0' || !this.state.deviceToCreate.longitude || !this.state.deviceToCreate.latitude) ?
													2.349014 : parseFloat(this.state.deviceToCreate.longitude)
									            }}
												zoom={10}
												yesIWantToUseGoogleMapApiInternals
												onClick={this.getCoordinatesFromMap}
											>
											{this.state.deviceToCreate.latitude && this.state.deviceToCreate.longitude && this.state.deviceToCreate.latitude !== "" && this.state.deviceToCreate.longitude !== "" &&
												<Marker
													widget={this.props.widget}
													icon='marker'
													key={this.state.deviceToCreate.label}
													lat={this.state.deviceToCreate.latitude}
													lng={this.state.deviceToCreate.longitude}
													device={this.state.deviceToCreate}
												/>}
											</GoogleMapReact>
										</div>
									</Form.Item>}
							</Col>
						</Row>
					</FormGroup>
					{this.state.deviceToCreate.attributes.length > 0 &&
					<div>
						<Divider />
						<p className="formTitle">
							<FontAwesomeIcon className="form-icon" icon="bars"/>{utils.translate('generic.attrs')}
						</p>
						{this.state.deviceToCreate.attributes.map((attribute, index) =>
						<FormGroup className="deviceForm">
							<Row>
								<Col md={{size: 3}}>
									<Form.Item>
										<Label className="label">
											{attribute.label}
										</Label>
									</Form.Item>
								</Col>
								<Col md={{size: 9}}>
									<Form.Item>
										{attribute.type === 'LIST' ?
										<Select
											showSearch
											placeholder={utils.translate('componentCreateForm.attributeVal')}
											value={attribute.value}
											style={{ width: '100%' }}
											onChange={(value) => this.handleAttributeChange({target: { value: value }}, index)}
										>
										{attribute.list_items && Object.values(attribute.list_items).map((value) =>
											<Option key={value} value={value}>{value}</Option>
										)}
										</Select>
										:
										<Input
											placeholder={this.props.t('componentCreateForm.attributeVal')}
											value={attribute.value}
											onChange={(event) => this.handleAttributeChange(event, index)}
										/>}
									</Form.Item>
								</Col>
							</Row>
						</FormGroup>)}
					</div>}
                </ModalBody>
                <ModalFooter>
                    <Button className="modalButton" onClick={this.props.closeModal}>{utils.translate('generic.cancel')}</Button>
					<Button className="modalButton" type="primary" disabled={!this.state.formIsValid} style={{cursor: this.state.formIsValid ? "pointer" : "not-allowed"}} onClick={this.api_createDevice}>{utils.translate('componentCreateForm.create')}</Button>{' '}
                </ModalFooter>
				<small style={{textAlign: "right", display: this.state.formIsValid ? "none" : "", marginRight: "10px"}}><FontAwesomeIcon className="form-icon" icon="exclamation"/>{utils.translate('componentCreateForm.missing')}</small>
            </Modal>
        );
    }
}


export default withTranslation()(CreateForm);
