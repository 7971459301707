import React, { Component } from 'react';
import { Form } from 'reactstrap';
import { Divider, Select, Tabs, Tooltip, Icon } from 'antd';

import DeviceSection from '../FormSections/DeviceSection/DeviceSection';
import DatatypeSection from '../FormSections/DatatypeSection/DatatypeSection';
import DatatypeCustomizer from '../FormSections/DatatypeCustomizerSection/DatatypeCustomizer';
import MinMaxValuesSection from '../FormSections/MinMaxValuesSection/MinMaxValuesSection';
import GaugeColorsSection from '../FormSections/ColorSections/GaugeColorsSection/GaugeColorsSection';
import WidgetStyle from 'Components/Studio/Dashboard/Widgets/Style/WidgetStyle.js';

import { request } from 'Lib/request.js';
import {
	oneDevicePH,
	oneDatatypePH,
} from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';
import { categoryDashboardInfo } from 'Components/Studio/StudioLib/GenericHTML/GenericText.js';
import { utils } from 'Lib/utils';

import './Gauge.css';

const { TabPane } = Tabs;

const { Option } = Select;

class Gauge extends Component {
	constructor(props) {
		super(props);

		this.state = {
			configs: [],
			selectedDevices: [],
			selectedDatatype: undefined,
			containerFilter: [],
			categoryFilter: [],
			colors: ['#28a745', '#dddf0d', '#ff5369'],
			userWantsToCustomizeDatatypes: false,
			filter: undefined,
		};
	}

	componentDidMount() {
		if (this.props.widget.DVC_ID !== undefined) {
			this.getConfigsOfDeviceId(this.props.widget.DVC_ID);
			this.setState({ selectedDevices: this.props.widget.DVC_ID });
		}
		if (!!this.props.widget.DWU_COLORS) {
			this.setState({ colors: this.props.widget.DWU_COLORS.split(',') });
		}
		if (
			this.props.widget.CFG_ID !== undefined &&
			this.props.widget.CFG_ID !== ''
		) {
			this.setState({ selectedDatatype: [this.props.widget.CFG_ID] });
		}

		let currentConfiguration = this.getCurrentConfiguration();
		this.props.saveConfiguration(currentConfiguration);

		if (this.props.widget.JWC) {
			Object.keys(this.props.widget.JWC).forEach((key) => {
				var value = this.props.widget.JWC[key];

				if (
					value.JWC_COEFFA !== '1' ||
					value.JWC_COEFFB !== '0' ||
					value.JWC_LABEL !== '' ||
					value.JWC_UNIT !== ''
				) {
					// datatype has been customized
					if (key !== '') {
						this.setState({ userWantsToCustomizeDatatypes: true });
					}
				}
			});
		}
	}

	getConfigsOfDeviceId = (deviceId) => {
		if (deviceId !== undefined && deviceId !== '') {
			request.get(
				'config',
				{ device_id: deviceId },
				(configs) => this.setState({ configs: configs }),
				() => console.error('load configs failed')
			);
		}
	};

	handleDeviceChange = (value) => {
		this.setState({
			selectedDevices: value,
			selectedDatatype: undefined,
			userWantsToCustomizeDatatypes: false,
		});

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.device_id = value;
		currentConfiguration.config_id = undefined;

		this.props.saveConfiguration(currentConfiguration);
		this.getConfigsOfDeviceId(value);
	};

	handleConfigChange = (value) => {
		this.setState({ selectedDatatype: [value] });

		if (this.state.filter) {
			this.setFilter(undefined);
		}

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.config_id = value;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleMinValueChange = (value) => {
		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.min = value;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleMaxValueChange = (value) => {
		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.max = value;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleColor1Change = (color) => {
		let colors = [...this.state.colors];
		let colorToUpdate = { ...colors[0] };
		colorToUpdate = color.color;
		colors[0] = colorToUpdate;

		this.setColorstoStateandConfig(colors);
	};

	handleColor2Change = (color) => {
		let colors = [...this.state.colors];
		let colorToUpdate = { ...colors[1] };
		colorToUpdate = color.color;
		colors[1] = colorToUpdate;

		this.setColorstoStateandConfig(colors);
	};

	handleColor3Change = (color) => {
		let colors = [...this.state.colors];
		let colorToUpdate = { ...colors[2] };
		colorToUpdate = color.color;
		colors[2] = colorToUpdate;

		this.setColorstoStateandConfig(colors);
	};

	handleCustomizerToggle = (userWantsToCustomizeDatatypes) => {
		this.setState({
			userWantsToCustomizeDatatypes: userWantsToCustomizeDatatypes,
		});
	};

	setColorstoStateandConfig = (colors) => {
		this.setState({ colors: colors });

		let currentConfiguration = this.getCurrentConfiguration();
		let colorsStr = '';

		colors.forEach((color) => {
			colorsStr += color + ',';
		});

		colorsStr = colorsStr.slice(0, -1);
		currentConfiguration.colors = colorsStr;

		this.props.saveConfiguration(currentConfiguration);
	};

	getCurrentConfiguration = () => {
		return {
			type: 'gauge',
			device_id: this.getCurrentDeviceId(),
			config_id: this.getCurrentConfigId(),
			min: this.getCurrentMinValue(),
			max: this.getCurrentMaxValue(),
			colors: this.getCurrentColors(),
			datatypeCustomization: this.getCurrentDatatypeCustomization(),
		};
	};

	getCurrentDeviceId = () => {
		return this.props.configuration.device_id
			? this.props.configuration.device_id
			: this.props.widget.DVC_ID && !this.state.containerEdited
			? this.props.widget.DVC_ID
			: undefined;
	};

	getCurrentConfigId = () => {
		return this.props.configuration.config_id
			? this.props.configuration.config_id
			: this.props.widget.CFG_ID &&
			  !this.state.containerEdited &&
			  !this.state.deviceEdited
			? this.props.widget.CFG_ID
			: undefined;
	};

	getCurrentMinValue = () => {
		return this.props.configuration.min !== undefined
			? this.props.configuration.min
			: this.props.widget.DWU_MIN
			? parseInt(this.props.widget.DWU_MIN)
			: 0;
	};

	getCurrentMaxValue = () => {
		return this.props.configuration.max !== undefined
			? this.props.configuration.max
			: this.props.widget.DWU_MAX
			? parseInt(this.props.widget.DWU_MAX)
			: 200;
	};

	getCurrentColors = () => {
		return this.props.configuration.colors
			? this.props.configuration.colors
			: this.props.widget.DWU_COLORS
			? this.props.widget.DWU_COLORS
			: '#28a745,#dddf0d,#ff5369';
	};

	getCurrentDatatypeCustomization = () => {
		return this.state.datatypeCustomization;
	};

	saveCustomizationToState = (datatypeCustomization) => {
		let datatypeCustomizationCopy = JSON.parse(
			JSON.stringify(datatypeCustomization)
		); // child state MUST remain immutable, so we have to use a copy
		// JSON stringify then parse is only way to make sure that all nested objects get cloned and below changes are not mutative... ikr, wtf
		datatypeCustomizationCopy.forEach((customizedDatatype) => {
			this.state.configs.forEach((config) => {
				if (config.id === customizedDatatype.key) {
					if (customizedDatatype.label === config.label_D1) {
						customizedDatatype.label = '';
					}
					if (customizedDatatype.unit === config.unite_D1) {
						customizedDatatype.unit = '';
					}
				}
			});
		});

		this.setState(
			{ datatypeCustomization: datatypeCustomizationCopy },
			() => {
				let currentConfiguration = this.getCurrentConfiguration();
				this.props.saveConfiguration(currentConfiguration);
			}
		);
	};

	getDatatypeSelectOptions = () => {
		const datatypeSelectOptions = [];

		this.state.configs.forEach((datatype) => {
			datatypeSelectOptions.push(
				<Option key={datatype.id} value={datatype.id}>
					{datatype.label_D1}{' '}
					{datatype.unite_D1 && <span>({datatype.unite_D1})</span>}
				</Option>
			);
		});

		return datatypeSelectOptions;
	};

	setFilter = (value) => {
		this.setState({ filter: value });
	};

	render() {
		const canCustomizeDatatypes =
			this.state.selectedDatatype &&
			this.state.selectedDatatype.length > 0 &&
			this.state.configs &&
			this.state.configs.length > 0;
		const entries = this.props.devices.length;

		return (
			<Tabs tabPosition="top" tabBarStyle={{ marginBottom: 25 }}>
				<TabPane
					tab={
						<span>
							<Icon type="wifi" className="greyIcon" />
							{utils.translate('componentWidgetConfigure.data')}
						</span>
					}
					key="1"
				>
					<Form>
						<Divider />

						{!this.props.isCategoryDashboard && (
							<DeviceSection
								label={utils.translate('generic.dvc')}
								entries={entries}
								deviceSelectPlaceholder={oneDevicePH}
								devices={this.props.devices}
								categories={this.props.categories}
								containers={this.props.containers}
								groups={this.props.groups}
								selectedDevices={this.state.selectedDevices}
								categoryFilter={this.props.categoryFilter}
								containerFilter={this.props.containerFilter}
								groupFilter={this.props.groupFilter}
								loading={this.props.loading}
								disabled={this.props.loading}
								handleDeviceChange={this.handleDeviceChange}
								handleCategoryFilterChange={
									this.props.handleCategoryFilterChange
								}
								handleContainerFilterChange={
									this.props.handleContainerFilterChange
								}
								handleGroupFilterChange={
									this.props.handleGroupFilterChange
								}
								api_getDevices={this.props.api_getDevices}
							/>
						)}

						{this.props.isCategoryDashboard && (
							<div>{categoryDashboardInfo}</div>
						)}

						<Divider />

						<DatatypeSection
							label={utils.translate('generic.dty')}
							datatypeSelectPlaceholder={oneDatatypePH}
							selectOptions={this.getDatatypeSelectOptions()}
							selectedDatatypes={this.state.selectedDatatype}
							loading={
								this.props.isCategoryDashboard
									? false
									: this.props.loading
							}
							datatypeSelectDisabled={
								this.props.isCategoryDashboard
									? false
									: this.props.loading
							}
							handleConfigChange={this.handleConfigChange}
							setFilter={this.setFilter}
						/>

						{canCustomizeDatatypes && (
							<DatatypeCustomizer
								label={utils.translate('generic.customizeDty')}
								description={utils.translate(
									'generic.operationInfo'
								)}
								allDatatypes={this.state.configs}
								selectedDatatypes={this.state.selectedDatatype}
								widget={this.props.widget}
								userWantsToCustomizeDatatypes={
									this.state.userWantsToCustomizeDatatypes
								}
								handleCustomizerToggle={
									this.handleCustomizerToggle
								}
								saveCustomizationToParentState={
									this.saveCustomizationToState
								}
							/>
						)}

						<Divider />

						<MinMaxValuesSection
							getCurrentMinValue={this.getCurrentMinValue()}
							getCurrentMaxValue={this.getCurrentMaxValue()}
							handleMinValueChange={this.handleMinValueChange}
							handleMaxValueChange={this.handleMaxValueChange}
						/>

						<Divider />

						<GaugeColorsSection
							firstColor={this.state.colors[0]}
							secondColor={this.state.colors[1]}
							thirdColor={this.state.colors[2]}
							handleColor1Change={this.handleColor1Change}
							handleColor2Change={this.handleColor2Change}
							handleColor3Change={this.handleColor3Change}
						/>

						<Divider />
					</Form>
				</TabPane>
				<TabPane
					tab={
						<Tooltip title="Style">
							<span>
								<Icon type="bg-colors" className="greyIcon" />
								{utils.translate(
									'componentWidgetConfigure.style'
								)}
							</span>
						</Tooltip>
					}
					key="2"
				>
					<WidgetStyle
						{...this.props}
						marginTop={20}
						configuration={this.getCurrentConfiguration()}
					/>
				</TabPane>
			</Tabs>
		);
	}
}

export default Gauge;
