import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Card, Select, Input, Icon, Checkbox } from 'antd';
import { withTranslation } from 'react-i18next';

import LabelItem from '../../FormItems/LabelItem/LabelItem';
import LogicalOperatorBarItem from '../../FormItems/LogicalOperatorBarItem/LogicalOperatorBarItem';
import ColorPickerItem from '../../FormItems/ColorPickerItem/ColorPickerItem';

import HelpTag from 'Components/Studio/Dashboard/Widgets/WidgetLib/HelpTag/HelpTag';
import Loader from 'Components/Studio/Dashboard/Widgets/WidgetLib/Loader/Loader';
import ColorPreview from 'Components/Studio/Dashboard/Widgets/WidgetLib/ColorPreview/ColorPreview';

import SmartSelect from 'Components/Studio/StudioLib/SmartSelect/SmartSelect';
import {
	dataSourceLabelPopoverContent,
	bindLabelPopoverContent,
	geoLabelPopoverContent,
	thresholdLabelPopoverContent,
	operatorLabelPopoverContent,
	colorsLabelPopoverContent,
	legendLabelPopoverContent,
} from 'Components/Studio/StudioLib/GenericHTML/GenericText.js';
import { utils } from 'Lib/utils';

const { Option } = Select;

const rowStyle = {
	padding: 16,
	maxWidth: 400
};


const ComplexMarkerSectionView = (props) =>
{

	const {
		categoryIds,
		availableDatatypes = [],
		availableAttributes = [],
		availableDatatypesToBind,
		availableAttributesToBind,
		selectedDatatypes,
		selectedAttributes,
		selectedDataSourceToBind,
		selectedOperator,
		threshold,
		inactiveColor,
		activeColor,
		defaultColor,
		inactiveColorLegend,
		activeColorLegend,
		defaultColorLegend,
		categoriesLoading,
		devicesLoading,
		maxElementsReached,
		thresholdIsNumerical,
		showValueNextToMarkers,
		noCategoryIds,
		handleBindSelect,
		handleDatatypeSelect,
		handleAttributeSelect,
		setSelectedOperator,
		setThreshold,
		setInactiveColorLegend,
		setActiveColorLegend,
		setDefaultColorLegend,
		setShowValueNextToMarkers,
		setShowGeolocOnly,
		showGeolocOnly,
		handleColorChange,
		selectedAtrGeoloc,
		handleAtrGeolocSelect,
		widget
	} = props;


	const showLoader = categoriesLoading || devicesLoading;


	const getLabel = ({ labelText, popoverContent, }) =>
	{
		return (
			<span>
				<HelpTag
					popoverContent={<Row style={rowStyle}>{popoverContent}</Row>}
					popoverPlacement='right'
					color='green'
					tagText={
						<Icon
							type='info-circle'
							className='formPHIcon'
						/>
					}
					tagStyle={{
						borderRadius: 60,
						marginLeft: 5,
						padding: 0,
					}}
				/>
				{labelText}
			</span>
		);
	}


	const dataSourceLabel = getLabel({
		labelText: utils.translate('componentComplexMarkerSectionView.dataSource'),
		popoverContent: dataSourceLabelPopoverContent,
	});


	const bindLabel = getLabel({
		labelText: utils.translate('componentComplexMarkerSectionView.bind'),
		popoverContent: bindLabelPopoverContent,
	});

	const geolocLabel = getLabel({
		labelText: utils.translate('componentComplexMarkerSectionView.select'),
		popoverContent: geoLabelPopoverContent,
	});

	const thresholdLabel = getLabel({
		labelText: utils.translate('componentComplexMarkerSectionView.threshold'),
		popoverContent: thresholdLabelPopoverContent,
	});


	const operatorLabel = getLabel({
		labelText: utils.translate('componentComplexMarkerSectionView.operator'),
		popoverContent: operatorLabelPopoverContent,
	});


	const colorsLabel = getLabel({
		labelText: utils.translate('componentComplexMarkerSectionView.colors'),
		popoverContent: colorsLabelPopoverContent,
	});


	const legendLabel = getLabel({
		labelText: 'Legend Labels',
		popoverContent: legendLabelPopoverContent,
	});


	const getDatatypeOptions = () =>
	{
		return availableDatatypes.map((datatype) =>
			<Option
				key={datatype.id}
				value={datatype.id}
				disabled={maxElementsReached &&
					!selectedDatatypes.includes(datatype.id)}
			>
				{datatype.label}
				{datatype.unit && ' - ' + datatype.unit}
			</Option>
		);
	}


	const getAttributeOptions = () =>
	{
		return availableAttributes.map((attribute) =>
			<Option
				key={attribute.id}
				value={attribute.id}
				disabled={maxElementsReached &&
					!selectedAttributes.includes(attribute.id)}
			>
				{categoryIds && categoryIds.length > 1 &&
					attribute.cat_label + ' - '}
				{attribute.label}
			</Option>
		);
	}

	return (

		<Card
			bordered={false}
			bodyStyle={{ paddingBottom: 0 }}
		>
			{showLoader ? <Loader spinSize='small'/> :
			<>
				<FormGroup>
					<Row>
						<LabelItem
							label={dataSourceLabel}
							iconType=''
						/>
						<Col md={{size: 9}}>
							<Row>
								<Col md={{size: 6}}>
									<SmartSelect
										mode='multiple'
										options={getDatatypeOptions()}
										value={selectedDatatypes}
										onChange={handleDatatypeSelect}
										showSearch
										showArrow
										allowClear
										disabled={noCategoryIds}
										loading={categoriesLoading}
										placeholder={utils.translate('componentComplexMarkerSectionView.datatypes')}
										style={{ width: '100%' }}
									/>
								</Col>
								<Col md={{size: 6}}>
									<SmartSelect
										mode='multiple'
										options={getAttributeOptions()}
										value={selectedAttributes}
										onChange={handleAttributeSelect}
										showSearch
										showArrow
										allowClear
										disabled={noCategoryIds}
										loading={categoriesLoading}
										placeholder={utils.translate('generic.attrs')}
										style={{ width: '100%' }}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</FormGroup>
				<>
				{widget.DWG_TYPE === "DWG_map" &&
				<FormGroup>
						<Row>
							<LabelItem
								label={geolocLabel}
								iconType=''
							/>
							<Col md={{size: 9}}>
								<Row>
									<Col md={{size: 6}}>
										<Select
											showSearch
											showArrow
											allowClear
											loading={categoriesLoading}
											onChange={handleAtrGeolocSelect}
											value={JSON.stringify(selectedAtrGeoloc)}
											placeholder={utils.translate('generic.attrs')}
											style={{ width: '100%' }}
										>
										{[...availableDatatypesToBind, ...availableAttributesToBind].map((e) =>
											<Option
												key={e.id}
												value={JSON.stringify({ id: e.id, data_type: e.data_type })}
											>
												{e.label}
											</Option>
										)}
										</Select>
									</Col>
									<Col md={{size: 6}}>
										<Checkbox
											defaultChecked={showGeolocOnly}
											onChange={(e) => setShowGeolocOnly(e.target.checked)}
										>
											{utils.translate('componentComplexMarkerSectionView.geolocCheck')}
										</Checkbox>
									</Col>
								</Row>
							</Col>
						</Row>
					</FormGroup>}
				</>
				<>
					<FormGroup>
						<Row>
							<LabelItem
								label={bindLabel}
								iconType=''
							/>
							<Col md={{size: 9}}>
								<Row>
									<Col md={{size: 6}}>
										<Select
											showSearch
											showArrow
											allowClear
											loading={categoriesLoading}
											onChange={handleBindSelect}
											value={JSON.stringify(selectedDataSourceToBind)}
											placeholder={utils.translate('componentComplexMarkerSectionView.bindData')}
											style={{ width: '100%' }}
										>
										{[...availableDatatypesToBind, ...availableAttributesToBind].map((e) =>
											<Option
												key={e.id}
												value={JSON.stringify({ id: e.id, data_type: e.data_type })}
											>
												{e.label}
											</Option>
										)}
										</Select>
									</Col>
									<Col md={{size: 6}}>
										<Checkbox
											defaultChecked={showValueNextToMarkers}
											onChange={(e) => setShowValueNextToMarkers(e.target.checked)}
										>
											{utils.translate('componentComplexMarkerSectionView.master')}
										</Checkbox>
									</Col>
								</Row>
							</Col>
						</Row>
					</FormGroup>
				</>
				<FormGroup>
					<Row>
						<LabelItem
							label={thresholdLabel}
							iconType=''
						/>
						<Col md={{ size: 9 }}>
							<Input
								placeholder={props.t('componentComplexMarkerSectionView.trigger')}
								value={threshold}
								onChange={(e) => setThreshold(e.target.value)}
								maxLength={50}
							/>
						</Col>
					</Row>
				</FormGroup>
				<FormGroup>
					<Row>
						<LabelItem
							label={operatorLabel}
							iconType=''
						/>
						<Col md={{ size: 9 }}>
							<LogicalOperatorBarItem
								selectedOperator={selectedOperator}
								setSelectedOperator={setSelectedOperator}
								thresholdIsNumerical={thresholdIsNumerical}
							/>
						</Col>
					</Row>
				</FormGroup>
				<FormGroup>
					<Row>
						<LabelItem
							label={colorsLabel}
							iconType=''
						/>
						<Col md={{size: 9}}>
							<span>
								<ColorPickerItem
									label={utils.translate('componentComplexMarkerSectionView.inactive')}
									color={inactiveColor}
									onClose={(color) => handleColorChange(color, 'INACTIVE')}
									enableAlpha={false}
								/>
							</span>
							<span
								style={{ marginLeft: 20 }}
							>
								<ColorPickerItem
									label={utils.translate('componentComplexMarkerSectionView.active')}
									color={activeColor}
									onClose={(color) => handleColorChange(color, 'ACTIVE')}
									enableAlpha={false}
								/>
							</span>
							<span
								style={{ marginLeft: 20 }}
							>
								<ColorPickerItem
									label={utils.translate('componentComplexMarkerSectionView.default')}
									color={defaultColor}
									onClose={(color) => handleColorChange(color, 'DEFAULT')}
									enableAlpha={false}
								/>
							</span>
						</Col>
					</Row>
				</FormGroup>
				<FormGroup>
					<Row>
						<LabelItem
							label={legendLabel}
							iconType=''
						/>
						<Col md={{size: 9}}>
							<Row>
								<Col span={8}>
									<ColorPreview backgroundColor={inactiveColor}/>
									<Input
										placeholder={props.t('componentComplexMarkerSectionView.inactive2')}
										value={inactiveColorLegend}
										onChange={(e) => setInactiveColorLegend(e.target.value)}
										maxLength={20}
										style={{ width: '80%' }}
									/>
								</Col>
								<Col span={8}>
									<ColorPreview backgroundColor={activeColor}/>
									<Input
										placeholder={props.t('componentComplexMarkerSectionView.active2')}
										value={activeColorLegend}
										onChange={(e) => setActiveColorLegend(e.target.value)}
										maxLength={20}
										style={{ width: '80%' }}
									/>
								</Col>
								<Col span={8}>
									<ColorPreview backgroundColor={defaultColor}/>
									<Input
										placeholder={props.t('componentComplexMarkerSectionView.default2')}
										value={defaultColorLegend}
										onChange={(e) => setDefaultColorLegend(e.target.value)}
										maxLength={20}
										style={{ width: '80%' }}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</FormGroup>
			</>}
		</Card>
	)
};


export default withTranslation()(ComplexMarkerSectionView);
