import React from 'react';
import { withSize } from 'react-sizeme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';

import { utils } from 'Lib/utils';

import './BlockHeader.css';
import { Menu, Dropdown } from 'antd';


const BlockAdd = (props) => {

	const {
		block,
	} = props;


	const menu = (
		<Menu>
			<Menu.Item>
				<a href={() => false} rel="noopener noreferrer" onClick={() => props.addWidget('DWG_status', block)}>
					{utils.translate('widgetDescription.status2')}
				</a>
			</Menu.Item>
			<Menu.Item>
				<a href={() => false} rel="noopener noreferrer" onClick={() => props.addWidget('DWG_index', block)}>
					{utils.translate('widgetDescription.index2')}
				</a>
			</Menu.Item>
			<Menu.Item>
				<a href={() => false} rel="noopener noreferrer" onClick={() => props.addWidget('DWG_boolean', block)}>
					{utils.translate('widgetDescription.boolean2')}
				</a>
			</Menu.Item>
			<Menu.Item>
				<a href={() => false} rel="noopener noreferrer" onClick={() => props.addWidget('DWG_value', block)}>
					{utils.translate('widgetDescription.value2')}
				</a>
			</Menu.Item>
		</Menu>
	);

	return (

		<Tooltip
			placement='leftBottom'
			title={utils.translate('widgetDescription.addBlock')}
		>
			<Dropdown overlay={menu} placement="bottomRight" arrow>
				<FontAwesomeIcon
					className='widget-icon'
					icon='plus'
				/>
			</Dropdown>
		</Tooltip>

	);
}


export default withSize({ monitorHeight: true })(BlockAdd);
