import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'reactstrap';
import { Tooltip } from 'antd';
import { utils } from 'Lib/utils';

import { MODE } from 'Config/config';


export const CsLink = (props) => (

	<Tooltip placement='bottomLeft' title={utils.translate('componentCsLink.back')}>
		<NavLink id='back' href={window.ENV.REACT_APP_CS_URL + MODE + '/pagelist/DAP_ID/' + window.ENV.REACT_APP_ID}>
			<FontAwesomeIcon icon='arrow-alt-circle-left' />
		</NavLink>
	</Tooltip>
);