import React, { Component } from 'react';
import { Divider, Tabs, Icon, Card, Tooltip } from 'antd';

import ComplexMarkerSection from '../FormSections/ComplexMarkerSection/ComplexMarkerSection';
import CustomizerSwitchItem from '../FormItems/CustomizerSwitchItem/CustomizerSwitchItem';
import ImageUploadSection from '../FormSections/ImageUploadSection/ImageUploadSection';
import SwitchSection from '../FormSections/SwitchSection/SwitchSection';
import WidgetStyle from 'Components/Studio/Dashboard/Widgets/Style/WidgetStyle'

import { deviceProfileInfo } from 'Components/Studio/StudioLib/GenericHTML/GenericText.js';

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils';

const { TabPane } = Tabs;


class Sitemap extends Component
{

    constructor(props)
	{
        super(props);

        this.state = {
			picture: '',
			statusMarkersDvcIds: [],
			statusMarkersDeviceObjects: [],
			statusMarkersCategories: [],
			dty_ids: [],
			atr_ids: [],
			master_datasource: undefined,
			threshold_value: undefined,
			dth_operator: undefined,
			inactive_color: undefined,
			active_color: undefined,
			default_color: undefined,
			inactive_legend: undefined,
			active_legend: undefined,
			default_legend: undefined,
			profileChecked: true,
			userWantsDataMarkers: false,
			show_marker_value: true,
        }
    }


	componentDidMount()
	{
		const { widget } = this.props;

		this.api_getMarkers();

		this.setState({
			profileChecked: !widget.DWS_HAS_DEVICE_PROFILE ||
				widget.DWS_HAS_DEVICE_PROFILE === '1',
		});

		if (widget.ATR_IDS && widget.DTY_IDS)
		{
			if (widget.ATR_IDS.length || widget.DTY_IDS.length)
				this.setState({ userWantsDataMarkers: true });
		}

        this.props.saveConfiguration(this.getCurrentConfiguration());
    }


	componentDidUpdate(prevProps, prevState)
	{
		if (this.props.devices &&
			(prevState.statusMarkersDvcIds !== this.state.statusMarkersDvcIds ||
				prevProps.devices !== this.props.devices))
		{
			this.setState({
				statusMarkersDeviceObjects: this.props.devices
					.filter(device => this.state.statusMarkersDvcIds.includes(device.id))
			});
		}

		if (prevState.statusMarkersDeviceObjects !== this.state.statusMarkersDeviceObjects)
		{
			this.setState({
				statusMarkersCategories: [...new Set(this.state.statusMarkersDeviceObjects
					.map(device => device.category.id))] // keeping array elements unique with Set()
			});
		}

		if (prevState.dty_ids !== this.state.dty_ids ||
			prevState.atr_ids !== this.state.atr_ids ||
			prevState.master_datasource !== this.state.master_datasource ||
			prevState.threshold_value !== this.state.threshold_value ||
			prevState.dth_operator !== this.state.dth_operator ||
			prevState.inactive_color !== this.state.inactive_color ||
			prevState.active_color !== this.state.active_color ||
			prevState.default_color !== this.state.default_color ||
			prevState.inactive_legend !== this.state.inactive_legend ||
			prevState.active_legend !== this.state.active_legend ||
			prevState.default_legend !== this.state.default_legend ||
			prevState.show_marker_value !== this.state.show_marker_value)
				this.props.saveConfiguration(this.getCurrentConfiguration());
	}


	api_getMarkers = () =>
	{
		if (this.props.widget && this.props.widget.DWS_ID)
		{
			request.get(
				'widgetsitemap/' + this.props.widget.DWS_ID,
				{},
				(sitemap) =>
				{
					const markers = sitemap.sitemap.markers;
					let dvcIds = [];

					if (markers)
					{
						dvcIds = markers.flatMap((marker) =>
						{
							return marker.DSM_TYPE === 'DSM_status' && marker.DVC_ID ?
								marker.DVC_ID : [];
						});
					}

					this.setState({ statusMarkersDvcIds: dvcIds });
				},
				() => console.error('load markers failed')
			);
		}
	}


	getCurrentConfiguration = () =>
	{
        return {
            type: 'sitemap',
            image: this.state.picture || this.props.widget.DWS_IMAGE || this.props.configuration.DWS_IMAGE,
			has_device_profile: this.state.profileChecked ? '1' : '0',
			show_marker_value: this.state.show_marker_value ? '1' : '0',
			dty_ids: this.state.dty_ids,
			atr_ids: this.state.atr_ids,
			master_datasource: this.state.master_datasource,
			threshold_value: this.state.threshold_value,
			dth_operator: this.state.dth_operator,
			inactive_color: this.state.inactive_color,
			active_color: this.state.active_color,
			default_color: this.state.default_color,
			inactive_legend: this.state.inactive_legend,
			active_legend: this.state.active_legend,
			default_legend: this.state.default_legend,
        }
    }


	handleImageChange = async (picture) =>
	{
		this.setState({ picture: "" });

        await this.getBase64(picture[0]);

        var currentConfiguration = this.getCurrentConfiguration();
        currentConfiguration.image = this.state.picture;

        this.props.saveConfiguration(currentConfiguration);
    }


	async getBase64(image)
	{
    	return new Promise((resolve, reject) =>
		{
      		var fileReader = new FileReader();

      		fileReader.onerror = () =>
			{
          		reject("Error");
      		}

      		fileReader.onload = () =>
			{
        		if (fileReader.result !== undefined)
				{
	          		resolve(this.setState({
	             		picture: fileReader.result //base64 string stored in state
	          		}), () => {});
        		}
				else
				{
          			reject("Error");
        		}
      		}

			try
			{
      			fileReader.readAsDataURL(image);
			}
			catch (e) {}
    	});
  	}


	handleProfileSwitch = (checked) =>
	{
		let currentConfiguration = this.getCurrentConfiguration();
        this.setState({ profileChecked: checked });
        currentConfiguration.has_device_profile = checked ? 1 : 0;

	    this.props.saveConfiguration(currentConfiguration);
	}


	handleDataMarkersToggle = (checked) =>
	{
		this.setState({ userWantsDataMarkers : checked });

		if (!checked)
		{
			this.setState({
				dty_ids: [],
				atr_ids: [],
				master_datasource: undefined,
				threshold_value: undefined,
				dth_operator: undefined,
				inactive_color: undefined,
				active_color: undefined,
				default_color: undefined,
			});
		}
	}


	saveToParent = (key, value) => this.setState({ [key]: value });


    render()
	{
		const { widget, configuration, loading, isCategoryDashboard, dashboardCategory } = this.props;
		const { statusMarkersCategories, userWantsDataMarkers } = this.state;

		const currentCategoryIds = isCategoryDashboard ? [dashboardCategory.id] : statusMarkersCategories;
		const noCategoryIds = statusMarkersCategories.length === 0; // different from map

        return (

			<Tabs
				tabPosition='top'
				tabBarStyle={{ marginBottom: 25 }}
			>
				<TabPane
					tab={<span>
							<Icon type='picture' className='greyIcon'/>
							Image
						</span>}
					key='1'
				>
					<Card bordered={false}>
						<ImageUploadSection
							buttonText={(this.props.widget.DWS_IMAGE || this.props.configuration.DWS_IMAGE) ? utils.translate('componentWidgetSitemap.change') : utils.translate('componentWidgetSitemap.choose')}
							buttonStyles={{color: 'white', background: '#40a9ff', width: 150, visibility: this.state.picture === "" ? 'visible': 'hidden'}}
							handleImageChange={this.handleImageChange}
						/>
					</Card>
				</TabPane>
				<TabPane
					forceRender
					tab={<span>
							<Icon type='pushpin' className='greyIcon'/>
							{utils.translate('componentWidgetSitemap.markers')}
						</span>}
					key='2'
				>
					<Card bordered={false}>
						<CustomizerSwitchItem
							label={utils.translate('componentWidgetSitemap.markerType')}
							iconType='pushpin'
							switchDisabled={noCategoryIds}
							userWantsToCustomize={userWantsDataMarkers}
							handleCustomizerToggle={this.handleDataMarkersToggle}
							unCheckedChildren={utils.translate('componentWidgetMapConfigure.basic')}
							checkedChildren={utils.translate('componentWidgetMapConfigure.advanced')}
							disabledTooltipText={utils.translate('componentWidgetSitemap.createInfo')}
						/>
						{userWantsDataMarkers &&
						<ComplexMarkerSection
							widget={widget}
							categoryIds={currentCategoryIds}
							configuration={configuration}
							saveToParent={this.saveToParent}
							devicesLoading={loading}
						/>}
						<Divider/>
						<SwitchSection
							checked={this.state.profileChecked}
							handleSwitch={this.handleProfileSwitch}
							label={utils.translate('generic.dvcProfile')}
							iconType='idcard'
							info={deviceProfileInfo}
						/>
					</Card>
				</TabPane>
				<TabPane
					tab={
						<Tooltip
							title='Style'
						>
							<span>
								<Icon type='bg-colors' className='greyIcon'/>
								Style
							</span>
						</Tooltip>
					}
					key='3'
				>
					<WidgetStyle
						{...this.props}
						marginTop={20}
						configuration={this.getCurrentConfiguration()}
					/>
				</TabPane>
			</Tabs>
        );
    }
}


export default Sitemap;
