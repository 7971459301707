import React, { Component } from 'react';
import { Button, Alert, Spin, Select } from 'antd';
import { Row, FormGroup, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/snippets/css';
import 'ace-builds/src-min-noconflict/ext-searchbox';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/theme-textmate';
import { withTranslation } from 'react-i18next';

import MarginSliders from './MarginSliders/MarginSliders';
import ThemeForm from './ThemeForm/ThemeForm';

import { utils } from 'Lib/utils';
import { request } from 'Lib/request';
import { default_theme, dark_theme, sunset_theme, ocean_theme, neoMemphis_theme } from 'Styles/Themes/themes.js';
import PropTypes from "prop-types";

import './ThemeBuilder.css';

const { Option } = Select;

const themes = {
	'default_theme': default_theme, 
	'dark_theme': dark_theme, 
	'sunset_theme': sunset_theme, 
	'ocean_theme': ocean_theme,
	'neoMemphis_theme': neoMemphis_theme,
}

class ThemeBuilder extends Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			appTheme: props.appTheme,
			loading: false,
			themeSaved: false,
			preset: undefined,
			edited: false,
		};
	}

	componentDidMount()
	{
		this.setState({preset: this.getPresetFromTheme(this.state.appTheme)});
	}


	getPresetFromTheme = (theme) =>
	{
		return Object.keys(themes).find(key => themes[key] === theme);
	}


	handleThemeChange = (value) =>
	{
		this.setState({
			appTheme: value,
			preset: undefined,
			edited: true
		});
	}

	handlePresetChange = (value) =>
	{
		this.setState({
			preset: value,
			appTheme: themes[value],
			edited: true,
		});
	}

	handleChange = (object) =>
	{
		this.setState(object);
	}

	cancelEdit = () =>
	{
		this.setState({
			appTheme: this.props.appTheme,
		}, () => {

			if (!Object.values(themes).includes(this.state.appTheme)) {
				this.setState({
					preset: undefined,
					edited: false
				});
			}
		});

	}


	api_saveTheme = () =>
	{
		this.setState({
			loading: true,
			themeSaved: false
		}, () =>
		{
			request.patch(
				'application/' + window.ENV.REACT_APP_ID,
				{
					app_theme: this.state.appTheme
				},
				(success) =>
				{
					this.props.loadPage(this.props.dpgId);

					this.setState({
						themeSaved: true,
						edited: false
					});

					setTimeout(function() {
						this.setState({ themeSaved: false });
			        }.bind(this), 3500);
				},
				(error) =>
				{
					console.error(error);
				},
				() =>
				{
					this.setState({ loading: false });
				}
			);
		})

	}


    render()
	{

		return (

			<>
				<FormGroup>
					<Row>
						<MarginSliders
							dapId={this.props.dapId}
							dpgId={this.props.dpgId}
							currentPage={this.props.currentPage}
							loadPage={this.props.loadPage}
						/>
					</Row>
				</FormGroup>
				<FormGroup>
					<Row>
						<Select
							placeholder={utils.translate('componentThemeBuilder.templateInfo')}
							value={this.state.preset}
							style={{width: '100%'}}
							onChange={this.handlePresetChange}
						>
							{Object.keys(themes).map(theme => 
								<Option key={theme} value={theme}>{utils.translate('componentThemeBuilder.'+theme)}</Option>
							)}
						</Select>
					</Row>
				</FormGroup>
				<FormGroup>
					<Row>
						<ThemeForm
							appTheme={this.state.appTheme}
							handleChange={this.handleChange}
						/>
					</Row>
				</FormGroup>
				<FormGroup>
					<Row>
						<AceEditor
							mode='css'
							theme='textmate'
							placeholder={this.props.t('componentThemeBuilder.addStyle')}
							width='100%'
							wrapEnabled={true}
							onChange={this.handleThemeChange}
							value={this.state.appTheme || ''}
							setOptions={{
								enableBasicAutocompletion: true,
								enableLiveAutocompletion: true,
								enableSnippets: false,
								showLineNumbers: true,
								showPrintMargin: false,
								tabSize: 4
							}}
						/>
					</Row>
				</FormGroup>
				<FormGroup>
					<Row>
						<Col className='largeCol' md={{size: 6}}>
							<Button
								type='primary'
								className='drawerButton-left'
								disabled={this.state.edited ? false : true}
								onClick={this.api_saveTheme}>
									<FontAwesomeIcon className='drawerIcon' icon='hdd'/>
									{utils.translate('componentThemeBuilder.saveTheme')}
							</Button>{' '}
						</Col>
						<Col className='largeCol' md={{size: 6}}>
							<Button
								type={this.state.edited ? 'default' : 'disabled'}
								disabled={!this.state.edited}
								className='drawerButton-right'
								onClick={this.cancelEdit}>
									<FontAwesomeIcon className='drawerIcon' icon='undo'/>
									{utils.translate('componentThemeBuilder.discard')}
							</Button>{' '}
						</Col>
					</Row>
				</FormGroup>
				<FormGroup>
					<Row>
						{this.state.themeSaved &&
							<Alert
								className='themeSuccessAlert'
								message={utils.translate('componentThemeBuilder.savedMessage1')}
								description={utils.translate('componentThemeBuilder.savedMessage2')}
								type='success'
								showIcon
								closable
							/>}
						{this.state.loading &&
							<Spin
								tip={utils.translate('componentThemeBuilder.refresh')}
							 	className='themeBuilderSpinner'
								size='large'
							/>}
					</Row>
				</FormGroup>
			</>
        );
    }
}

ThemeBuilder.propTypes = {
	dapId: PropTypes.string.isRequired,
	loadPage: PropTypes.func.isRequired,
	dpgId: PropTypes.string.isRequired,
	appTheme: PropTypes.string.isRequired
};

export default withTranslation()(ThemeBuilder);
