import React from 'react';


const SmallLabelItem = (props) =>
{

	const {
		label,
		style
	} = props;


	return (

		<small
			style={style}
		>
			{label}
		</small>
	)
};


export default SmallLabelItem;
