import React, { Component } from 'react';
import { Navbar, NavbarBrand, Collapse, Nav } from 'reactstrap';
import { Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ConfigDrawer from './ConfigDrawer/ConfigDrawer';
import CategorySelector from './ConfigDrawer/CategorySelector/CategorySelector';
import MenuMaker from './MenuMaker/MenuMaker';
import { CsLink } from './CsLink/CsLink';
import { HeaderButtons } from './HeaderButtons/HeaderButtons';
import MenuDrawer from './MenuDrawer/MenuDrawer';

import { request } from 'Lib/request';
import { MODE } from 'Config/config';
import { utils } from 'Lib/utils';

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toggleDrawer } from "../../../actions/drawer";

import './Header.css';


class Header extends Component
{

	constructor(props)
	{		
		super(props);

		this.state = {
			isOpen: false,
			showMenu: false,
			managingDashboard: false,
			menuItems: [],
			menu: [],
			arrowDisabled: 'left',
			overflow: false,
			pageId: this.props.currentPage.DPG_ID
		};

		this.pageNav = React.createRef();
	}


	componentDidMount()
	{
		this.api_getMenu();
	}


	componentWillReceiveProps()
	{
		const isMobileVersion = utils.isMobileVersion(window);

		if (!isMobileVersion)
		{
			if (this.pageNav.current) { this.hasOverflowingChildren() }
		};
	}


	api_getMenu = () =>
	{
		request.get(
			'page',
			{ application_id: this.props.dapId },
			(menuItems) =>
			{
				this.setState({ menuItems: menuItems });
			},
			() => { console.error('load pages failed'); }
		);
	}


	switchMode = () =>
	{
		let otherMode = utils.isConfigMode() ? MODE : 'config';

		if (otherMode !== 'config') { this.api_getMenu(); }

		utils.goToMode(otherMode, this.props.dpgId);
	}


	toggle = (pageId = null) =>
	{
		this.setState({ isOpen: !this.state.isOpen, pageId: pageId ? pageId : this.props.currentPage.DPG_ID });
		if (utils.isMobileVersion(window)) {
			if (!utils.isConfigMode()) {
				this.show();
			}	
		}
	}


	showDrawer = () =>
	{
		this.toggle();
		this.props.toggleDrawer();
	}

	toggleMenu = () =>
	{
		this.setState({showMenu: !this.state.showMenu});
	}




	toggleThemeBuilder = () =>
	{
		this.setState({ themeBuilderOpen: !this.state.themeBuilderOpen });
	}


	toggleCategoryDashboardManager = () =>
	{
		this.setState({ managingDashboard: !this.state.managingDashboard });
	}


	scroll = (direction) =>
	{
		let width = this.pageNav.current.clientWidth;
		var maxScrollLeft = this.pageNav.current.scrollWidth - this.pageNav.current.clientWidth;
		let far = parseFloat(width) / 2 * direction;
		this.pageNav.current.scrollLeft += far;
		let pos = parseFloat(this.pageNav.current.scrollLeft);

		if (pos === maxScrollLeft) { this.setState({ arrowDisabled: 'right' }); }
		else if (pos === 0) { this.setState({ arrowDisabled: 'left' }); }
		else { this.setState({ arrowDisabled: 'no' }); };
	}


	hasOverflowingChildren = () =>
	{
		const hasOverflowingChildren = this.pageNav.current.offsetWidth < this.pageNav.current.scrollWidth;
		hasOverflowingChildren ? this.setState({ overflow: true }) : this.setState({ overflow: false });
	}


	show = () => {
		if (utils.isMobileVersion(window)) {
			let elm = document.getElementById('header');
			if(elm.className === 'navbar-container-mobile'){
				elm.className = 'navbar-container';
				document.getElementById("headerButton").className = "d-flex";
				document.getElementById("titleApp").className = "logo-container";
				document.getElementById("lineHeader").className = "line-header";
	
			} else {
					elm.className = 'navbar-container-mobile';
					document.getElementById("headerButton").className = "d-none";
					document.getElementById("titleApp").className = "logo-container-menu";
					document.getElementById("lineHeader").className = "line-header-menu";		
			}
		}
	}


	render()
	{
		if (!this.props.show) { return null; }

		const isConfigMode = utils.isConfigMode();
		const isMobileVersion = utils.isMobileVersion(window);

		const image = <img
				ref='appLogo'
				src={window.ENV.REACT_APP_CS_URL + this.props.currentPage.DAP_ICON_URL}
				alt=''
				style={{ maxHeight: '55px', maxWidth: '100%' }}
			/>;

		const defaultSelectedItem = this.state.menuItems.find(item => item.DPG_ID === this.props.currentPage.DPG_ID);
		const defaultSelectedKeys = defaultSelectedItem ? [defaultSelectedItem.AMI_ID] : [];

		return (

			<>
				<Navbar id='header' expand='md' dark className='navbar-container MAIN_HEADER'>
					{isConfigMode && !isMobileVersion &&
						<CsLink
							dapId={window.ENV.REACT_APP_API_URL}
					/>}

					{isMobileVersion && 
					<div id="lineHeader" className="line-header">

						{!isConfigMode &&
							<button className='menu-toggler' onClick={this.toggleMenu}>
								<FontAwesomeIcon icon='bars' />
								<span style={{fontWeight:'bold'}}> Menu</span>
							</button>
						}
						
						<NavbarBrand id="titleApp" className='logo-container-menu'>
							{this.props.currentPage.DPG_LABEL && (<span>{utils.toSubstring(this.props.currentPage.DPG_LABEL)}</span>)}
						</NavbarBrand>
					</div>}

					{!isMobileVersion &&
					<>
						{image}
						<NavbarBrand id="titleApp" className='logo-container'>
							<span className='logo-cs'>
								{this.props.currentPage.APP_LABEL && 
									<span className='appLabel'>
										{utils.toSubstring(this.props.currentPage.APP_LABEL)} - 
									</span>}
								{this.props.currentPage.DPG_LABEL && (<span>{utils.toSubstring(this.props.currentPage.DPG_LABEL)}</span>)}
							</span>
							<hr className="d-none" id="separateLine"/>
						</NavbarBrand>
					</>}

					{!isMobileVersion && !isConfigMode && 
					<Divider 
						type='vertical' 
						style={{ height: '2em', top: '0.1em', marginLeft: '20px' }} 
					/>}

					{this.props.isCategoryDashboard && isConfigMode && this.props.category && this.props.category.label && !isMobileVersion &&
					<>
						<div className='categoryNameDiv'>
							<CategorySelector
								{...this.props}
								toggleCategoryDashboardManager={this.toggleCategoryDashboardManager}
							/>
						</div>
					</>}

					<div className="image-container" ref={this.pageNav}>
						{isMobileVersion &&
						<div id="headerButton" className="d-flex">
							<HeaderButtons
								{...this.props}
								{...this.state}
								switchMode={this.switchMode}
								isConfigMode={isConfigMode}
								isMobileVersion={isMobileVersion}
								showDrawer={this.showDrawer}
								scroll={this.scroll}
							/>
						</div>}
						{!isMobileVersion && !isConfigMode &&
						<Collapse isOpen={this.state.isOpen} className='mobile' navbar>
							<div className="positionMenu">
								<Nav navbar>
									<MenuMaker
										menuItems={this.state.menuItems}
										defaultSelectedKeys={defaultSelectedKeys}
									/>
								</Nav>
							</div>
						</Collapse>}
					</div>

					{!isMobileVersion &&
					<HeaderButtons
						{...this.props}
						{...this.state}
						switchMode={this.switchMode}
						isConfigMode={isConfigMode}
						isMobileVersion={isMobileVersion}
						showDrawer={this.showDrawer}
						scroll={this.scroll}
					/>}
				</Navbar>

				{isMobileVersion &&
				<MenuDrawer
					image={image}
					showMenu={this.state.showMenu}
					toggleMenu={this.toggleMenu}
					menuItems={this.state.menuItems}
					defaultSelectedKeys={defaultSelectedKeys}
				/>}
				<ConfigDrawer
					api_getWidgets={this.props.api_getWidgets}
					appTheme={this.props.appTheme}
					loadPage={this.props.loadPage}
					currentPage={this.props.currentPage}
					pageId={this.props.currentPage.DPG_ID}
					isCategoryDashboard={this.props.isCategoryDashboard}
					widgets={this.props.widgets}
					category={this.props.category}
					allCategories={this.props.allCategories}
					dpgId={this.props.dpgId}
					api_getCategory={this.props.api_getCategory}
					toggleCategoryDashboardManager={this.toggleCategoryDashboardManager}
				/>
			</>
		);
	}
}

Header.propTypes = {
	loadPage: PropTypes.func.isRequired,
	currentPage: PropTypes.object.isRequired,
	toggleDrawer: PropTypes.func.isRequired,
	category: PropTypes.object.isRequired
  };

const mapStateToProps = (state) => ({
	isDrawerVisible: state.drawer.isDrawerVisible,
	isThemeBuilderOpen: state.drawer.isThemeBuilderOpen,
});

export default connect(mapStateToProps, { toggleDrawer })(Header);
