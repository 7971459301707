import React, { Component } from 'react';
import { Row, Col, Form, FormGroup, Input } from 'reactstrap';
import { Divider, Tabs, Tooltip, Icon } from 'antd';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import AttributeTagInfo from 'Components/Studio/Dashboard/Widgets/WidgetLib/AttributeTagInfo/AttributeTagInfo';
import TypeSection from '../FormSections/TypeSection/TypeSection';
import WidgetStyle from 'Components/Studio/Dashboard/Widgets/Style/WidgetStyle.js';

import { utils } from 'Lib/utils';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';
import './Html.css';

const { TabPane } = Tabs;

const options = [
	{
		key: 'textarea',
		value: 'textarea',
		label: utils.translate('componentWidgetHtmlConfigure.area'),
	},
	{
		key: 'draftjs',
		value: 'draftjs',
		label: utils.translate('componentWidgetHtmlConfigure.draft'),
	},
];

class Html extends Component {
	constructor(props) {
		super(props);

		let text = this.getCurrentText();

		this.state = {
			editorType: 'textarea',
			// for draftjs we have to make a local copy of them editor state, because parent state is just converted html.
			// converted html doesnt remember the cursor position.
			editorState: text
				? this.convertHtmlToDraftjs(text)
				: EditorState.createEmpty(),
		};
	}

	componentDidMount() {
		let currentConfiguration = this.getCurrentConfiguration();
		this.props.saveConfiguration(currentConfiguration);
	}

	convertHtmlToDraftjs = (html) => {
		let contentText = '';
		const contentBlock = htmlToDraft(html);

		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(
				contentBlock.contentBlocks
			);
			contentText = EditorState.createWithContent(contentState);
		}

		return contentText;
	};

	handleInputChange = (value) => {
		this.setState({ editorType: value });
	};

	handleEditorStateChange = (editorState) => {
		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.text = draftToHtml(
			convertToRaw(editorState.getCurrentContent())
		);

		this.props.saveConfiguration(currentConfiguration);

		this.setState({ editorState: editorState });
	};

	handleTextareaChange = (event) => {
		let text = event.target.value;
		text = this.handleIframes(text);

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.text = text;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleIframes = (text) => {
		const safetyStart = '<p>';
		const safetyEnd = '</p>';

		if (text.includes('iframe') && text.substring(0, 3) !== safetyStart) {
			text = safetyStart + text + safetyEnd;
			utils.openNotification(
				'warning',
				utils.translate('componentWidgetHtmlConfigure.iframe1'),
				utils.translate('componentWidgetHtmlConfigure.iframe2') +
					safetyStart +
					safetyEnd +
					'.',
				'bottomRight',
				5
			);
		}

		return text;
	};

	getCurrentConfiguration = () => {
		return {
			type: 'html',
			text: this.getCurrentText(),
		};
	};

	getCurrentText = () => {
		return this.props.configuration.text
			? this.props.configuration.text
			: this.props.widget.DWH_TEXT
			? this.props.widget.DWH_TEXT
			: '';
	};

	render() {
		return (
			<Tabs tabPosition="top" tabBarStyle={{ marginBottom: 25 }}>
				<TabPane
					tab={
						<span>
							<Icon type="edit" className="greyIcon" />
							{utils.translate('componentEditForm.edit')}
						</span>
					}
					key="1"
				>
					<Form>
						<Divider />

						<TypeSection
							iconType="html5"
							label={utils.translate(
								'componentWidgetHtmlConfigure.editor'
							)}
							selectName="editorType"
							options={options}
							selectValue={this.state.editorType}
							onChange={this.handleInputChange}
						/>

						{
							<AttributeTagInfo
								isCategoryDashboard={
									this.props.isCategoryDashboard
								}
								type="HTML"
								contentType="HTML"
								example="http://test.com/"
							/>
						}

						<Divider />

						<FormGroup>
							<Row>
								<Col md={{ size: 12 }}>
									<div className="editor-container">
										{this.state.editorType ===
										'textarea' ? (
											<Input
												className="html-textarea"
												type="textarea"
												name="contentTextarea"
												value={this.getCurrentText()}
												onChange={
													this.handleTextareaChange
												}
											/>
										) : (
											<Editor
												edito
												editorState={
													this.state.editorState
												}
												onEditorStateChange={
													this.handleEditorStateChange
												}
												toolbarClassName="draftjs-toolbar"
												wrapperClassName="draftjs-wrapper"
												editorClassName="draftjs-editor"
											/>
										)}
									</div>
								</Col>
							</Row>
						</FormGroup>

						<Divider />
					</Form>
				</TabPane>
				<TabPane
					tab={
						<Tooltip title="Style">
							<span>
								<Icon type="bg-colors" className="greyIcon" />
								{utils.translate(
									'componentWidgetConfigure.style'
								)}
							</span>
						</Tooltip>
					}
					key="2"
				>
					<WidgetStyle
						{...this.props}
						marginTop={20}
						configuration={this.getCurrentConfiguration()}
					/>
				</TabPane>
			</Tabs>
		);
	}
}

export default Html;
