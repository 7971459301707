import React, { Component } from 'react';
import { Card, Divider, Icon, Tooltip, Tabs } from 'antd';
import moment from 'moment';

import GeoZoneSection from '../FormSections/GeoZoneSection/GeoZoneSection';
import DeviceSection from '../FormSections/DeviceSection/DeviceSection';
import ComplexMarkerSection from '../FormSections/ComplexMarkerSection/ComplexMarkerSection';
import DeviceCustomizerSection from '../FormSections/DeviceCustomizerSection/DeviceCustomizerSection';
import SwitchSection from '../FormSections/SwitchSection/SwitchSection';
import IconSection from '../FormSections/IconSection/IconSection';
import CustomizerSwitchItem from '../FormItems/CustomizerSwitchItem/CustomizerSwitchItem';
import WidgetStyle from 'Components/Studio/Dashboard/Widgets/Style/WidgetStyle.js';

import { utils } from 'Lib/utils.js';
import { multipleDevicesPH } from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';
import { categoryDashboardInfo, geolocCsvInfo, deviceProfileInfo } from 'Components/Studio/StudioLib/GenericHTML/GenericText.js';

const { TabPane } = Tabs;

const all = {
	id: 'all',
	label:
		utils.getCurrentLanguage() === 'fr'
			? 'Tout Selectionner'
			: 'Select All',
};

class Map extends Component {
	constructor(props) {
		super(props);

		this.state = {
			devices: [],
			geoZones: [],
			selectedDevices: '',
			selectedDeviceObjects: [],
			selectedCategories: [],
			selectedGeoZones: '',
			addGeoZoneDevices: true,
			removeGeoZoneDevices: true,
			categoryFilter: [],
			containerFilter: [],
			userWantsToTrackDevices: false,
			userWantsDataMarkers: false,
			show_marker_value: true,
			show_geoloc_only: false,
			customizeIcons: false,
			globalIcon: '',
			deviceCustomization: [],
			iconCustomization: [],
			iconTableCurrentData: [],
			atr_ids: [],
			dty_ids: [],
			master_datasource: undefined,
			alternative_geoloc: undefined,
			threshold_value: undefined,
			dth_operator: undefined,
			inactive_color: undefined,
			active_color: undefined,
			default_color: undefined,
			inactive_legend: undefined,
			active_legend: undefined,
			default_legend: undefined,
			iconTableFiltered: false,
			dateRange: this.props.widget.DWM_DATE_RANGE,
			currentDate: false,
			csvChecked: true,
			profileChecked: true,
        }

		this.allDevices = React.createRef();
		this.allGeoZones = React.createRef();
	}

	componentDidMount() {
		const { widget } = this.props;

		if (
			this.props.devices !== undefined &&
			this.props.devices.length > 0 &&
			this.props.geoZones !== undefined &&
			this.props.geoZones.length > 0
		) {
			this.setDevicesAndGeoZonesToState();
		}

		if (!widget.DWM_DATE_STOP || widget.DWM_DATE_STOP === '2100-12-31 00:00:00'
			|| widget.DWM_DATE_STOP === '0000-00-00 00:00:00')
		{
            this.setState({ currentDate: true });
        }

		this.setState({
			selectedDevices: widget.DVC_IDS,
			selectedGeoZones: widget.GZO_IDS,
			dateRange: widget.DWM_DATE_RANGE ? widget.DWM_DATE_RANGE : 'custom',
			csvChecked: !widget.DWM_HAS_EXPORT || widget.DWM_HAS_EXPORT === '1',
			profileChecked: !widget.DWM_HAS_DEVICE_PROFILE || widget.DWM_HAS_DEVICE_PROFILE === '1',
		}, () =>
		{
			const selectedDevices = this.state.selectedDevices || '';
			const selectedDevicesArray = selectedDevices ? selectedDevices.split(',') : [];
			const jwd = widget.JWD || {};

			const iconCustomization = selectedDevicesArray.map((device) => {
				const jwdDevice = jwd[device] || {};

				return {
					device: device,
					icon: jwdDevice.JWD_ICON || 'marker',
				};
			});

			const initialIcon =
				widget.JWD !== undefined &&
				Object.keys(widget.JWD)[0] !== undefined &&
				widget.JWD[Object.keys(widget.JWD)[0]].JWD_ICON !== null
					? widget.JWD[Object.keys(widget.JWD)[0]].JWD_ICON
					: 'marker';

			this.setState({
				iconCustomization: iconCustomization,
				globalIcon: initialIcon,
			});
		});

		if (widget.JWD !== undefined) {
			let userHasCustomizedDevices = Object.keys(widget.JWD).some(
				(key) => {
					const value = widget.JWD[key];
					return (
						value.JWD_SHOW_TRACKING === '1' ||
						value.JWD_SHOW_POLYLINE === '1'
					);
				}
			);

			let userHasCustomizedIcons = Object.keys(widget.JWD).some(
				(key, index) => {
					const value = widget.JWD[key];
					const nextValue =
						widget.JWD[Object.keys(widget.JWD)[index + 1]];
					return (
						nextValue !== undefined &&
						value.JWD_ICON !== nextValue.JWD_ICON
					);
				}
			);

			if (userHasCustomizedDevices) {
				this.setState({ userWantsToTrackDevices: true });
			}
			if (userHasCustomizedIcons) {
				this.setState({ customizeIcons: true });
			}
		}

		if (widget.ATR_IDS && widget.DTY_IDS) {
			if (widget.ATR_IDS.length || widget.DTY_IDS.length)
				this.setState({ userWantsDataMarkers: true });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			(prevProps.devices !== undefined &&
				prevProps.devices !== this.props.devices) ||
			(prevProps.geoZones !== undefined &&
				prevProps.geoZones !== this.props.geoZones)
		) {
			this.setDevicesAndGeoZonesToState();
		}

		if (
			prevState.selectedDevices !== this.state.selectedDevices ||
			prevState.devices !== this.state.devices
		) {
			let iconCustomization = [...this.state.iconCustomization];
			const previouslySelectedDevices = prevState.selectedDevices || '';
			const previouslySelectedDevicesArray =
				previouslySelectedDevices.split(',');
			const selectedDevices = this.state.selectedDevices || '';
			const selectedDevicesArray = selectedDevices.split(',');
			const devicesRemoved = previouslySelectedDevicesArray.filter(
				(device) =>
					device !== '' && !selectedDevicesArray.includes(device)
			);
			const devicesAdded = selectedDevicesArray.filter(
				(device) =>
					device !== '' &&
					!previouslySelectedDevicesArray.includes(device)
			);

			iconCustomization = iconCustomization.filter(
				(custo) => !devicesRemoved.includes(custo.device)
			);
			devicesAdded.forEach((device) =>
				iconCustomization.push({
					device: device,
					icon: this.state.globalIcon || 'marker',
				})
			);

			this.setState({
				iconCustomization: iconCustomization,
				selectedDeviceObjects: this.state.devices.filter((device) =>
					selectedDevicesArray.includes(device.id)
				),
			});
		}

		if (
			prevState.iconCustomization !== this.state.iconCustomization ||
			prevState.dty_ids !== this.state.dty_ids ||
			prevState.atr_ids !== this.state.atr_ids ||
			prevState.master_datasource !== this.state.master_datasource ||
			prevState.alternative_geoloc !== this.state.alternative_geoloc ||
			prevState.threshold_value !== this.state.threshold_value ||
			prevState.dth_operator !== this.state.dth_operator ||
			prevState.inactive_color !== this.state.inactive_color ||
			prevState.active_color !== this.state.active_color ||
			prevState.default_color !== this.state.default_color ||
			prevState.inactive_legend !== this.state.inactive_legend ||
			prevState.active_legend !== this.state.active_legend ||
			prevState.default_legend !== this.state.default_legend ||
			prevState.show_marker_value !== this.state.show_marker_value ||
			prevState.show_geoloc_only !== this.state.show_geoloc_only
		)
			this.props.saveConfiguration(this.getCurrentConfiguration());

		if (
			prevState.selectedDeviceObjects !== this.state.selectedDeviceObjects
		) {
			this.setState({
				selectedCategories: [
					...new Set(
						this.state.selectedDeviceObjects.map(
							(device) => device.category.id
						)
					),
				], // keeping array elements unique with Set()
			});
		}
	}

	setDevicesAndGeoZonesToState = () => {
		let devices = [...this.props.devices];
		let geoZones = [...this.props.geoZones];
		if (devices !== undefined && devices.length > 0) {
			devices.unshift(all);
		}
		if (geoZones !== undefined && geoZones.length > 0) {
			geoZones.unshift(all);
		}

		this.setState({
			devices: devices,
			geoZones: geoZones,
		});
	};

	handleDeviceChange = (value) => {
		if (value.includes('all')) {
			value = [];
			const allDevices = this.allDevices.current;
			var options = allDevices.props.children;

			for (var i = 0, l = options.length; i < l; i++) {
				if (options[i].key !== 'all') {
					value.push(options[i].key);
				}
			}
		}

		let deviceIdsStr = '';
		value.forEach((deviceId) => (deviceIdsStr += deviceId + ','));
		deviceIdsStr = deviceIdsStr.slice(0, -1);
		this.setState({ selectedDevices: deviceIdsStr });

        let currentConfiguration = this.getCurrentConfiguration();
        currentConfiguration.device_ids = deviceIdsStr;
        this.props.saveConfiguration(currentConfiguration);
    }


	handleGeoZoneChange = (value) => {
		// const { selectedGeoZones, addGeoZoneDevices, removeGeoZoneDevices, devices } = this.state;

		if (value.includes('all')) {
			value = [];
			const allGeoZones = this.allGeoZones.current;
			var options = allGeoZones.props.children;

			for (var i = 0, l = options.length; i < l; i++) {
				if (options[i].key !== 'all') {
					value.push(options[i].key);
				}
			}
		}

		// const zoneRemoved = value.length < selectedGeoZones.split(',').length;
		// const zoneAdded = value.length > selectedGeoZones.split(',').length;
		//
		// if (zoneAdded)
		// {
		// 	value.forEach((geoZoneId) => {
		// 		let relatedDevices = devices.filter(device => device.geozone !== '0' && device.geozone_id === geoZoneId);
		// 		console.error(relatedDevices);
		// 	});
		//
		// }
		// else if (zoneRemoved)
		// {
		//
		// }

		let geoZoneIdsStr = '';
		value.forEach((geoZoneId) => (geoZoneIdsStr += geoZoneId + ','));
		geoZoneIdsStr = geoZoneIdsStr.slice(0, -1);
		this.setState({ selectedGeoZones: geoZoneIdsStr });

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.geozone_ids = geoZoneIdsStr;
		this.props.saveConfiguration(currentConfiguration);
	};

	handleCustomizerToggle = (userWantsToTrackDevices) => {
		this.setState({
			userWantsToTrackDevices: userWantsToTrackDevices,
			csvChecked: userWantsToTrackDevices,
		});
	};

	setCustomizeIcons = (customizeIcons) => {
		if (!customizeIcons) {
			this.handleIconChange(this.state.globalIcon);
		} // resetting to global icon for every device
		this.setState({ customizeIcons: customizeIcons });
	};

	handleDateRangeChange = (value) => {
		this.setState({ dateRange: value });

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.date_range = value;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleDatesChange = (date, type) => {
		let formatDate = '';

		try {
			formatDate = date.format('YYYY-MM-DD HH:mm:ss');
		} catch (error) {
			return;
		}

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration[type] = formatDate;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleCurrentDateChange = (event) => {
		this.setState({ currentDate: event.target.checked }, () => {
			let currentConfiguration = this.getCurrentConfiguration();
			this.props.saveConfiguration(currentConfiguration);
		});
	};

	handleCsvSwitch = (checked) => {
		let currentConfiguration = this.getCurrentConfiguration();
		this.setState({ csvChecked: checked });
		currentConfiguration.has_export = checked ? 1 : 0;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleProfileSwitch = (checked) => {
		let currentConfiguration = this.getCurrentConfiguration();
		this.setState({ profileChecked: checked });
		currentConfiguration.has_device_profile = checked ? 1 : 0;

		this.props.saveConfiguration(currentConfiguration);
	};

	handleIconChange = (icon, device) => {
		// if 'device' is undefined, this handler applies changes to all items in customization / all filtered items
		const { iconCustomization, iconTableFiltered, iconTableCurrentData } =
			this.state;
		const localIconCustomization = [...iconCustomization];

		if (iconTableFiltered && device === undefined) {
			// only for main picker, when filtered
			iconTableCurrentData.forEach((row) => {
				const custoItemIndex = localIconCustomization.findIndex(
					(item) => item.device === row.key
				);
				localIconCustomization[custoItemIndex] = {
					device: row.key,
					icon: icon,
				};
			});

			this.setState({ iconCustomization: localIconCustomization });
		} else {
			this.setState((prevState) => ({
				globalIcon: device === undefined ? icon : prevState.globalIcon,
				iconCustomization: prevState.iconCustomization.map((custo) => {
					return {
						device: custo.device,
						icon:
							device === undefined || device === custo.device
								? icon
								: custo.icon,
					};
				}),
			}));
		}
	};

	handleIconTableDataChange = (currentDataSource, filtered) => {
		this.setState({
			iconTableCurrentData: currentDataSource,
			iconTableFiltered: filtered,
		});
	};

	handleDataMarkersToggle = (checked) => {
		this.setState({ userWantsDataMarkers: checked });

		if (!checked) {
			this.setState({
				dty_ids: [],
				atr_ids: [],
				master_datasource: undefined,
				alternative_geoloc: undefined,
				threshold_value: undefined,
				dth_operator: undefined,
				inactive_color: undefined,
				active_color: undefined,
				default_color: undefined,
				show_geoloc_only: false,
			});
		}
	}


    getCurrentConfiguration = () =>
	{
        return {
            type: 'map',
			device_ids: this.getCurrentDeviceIds(),
            geozone_ids: this.getCurrentGeoZoneIds(),
			deviceCustomization: this.state.deviceCustomization,
			iconCustomization: this.state.iconCustomization,
			date_range: this.state.dateRange,
			date_start: this.getCurrentDateStart(),
			date_stop: this.getCurrentDateStop(),
			has_export: this.state.csvChecked ? '1' : '0',
			has_device_profile: this.state.profileChecked ? '1' : '0',
			show_marker_value: this.state.show_marker_value ? '1' : '0',
			show_geoloc_only: this.state.show_geoloc_only ? '1' : '0',
			dty_ids: this.state.dty_ids,
			atr_ids: this.state.atr_ids,
			master_datasource: this.state.master_datasource,
			alternative_geoloc: this.state.alternative_geoloc,
			threshold_value: this.state.threshold_value,
			dth_operator: this.state.dth_operator,
			inactive_color: this.state.inactive_color,
			active_color: this.state.active_color,
			default_color: this.state.default_color,
			inactive_legend: this.state.inactive_legend,
			active_legend: this.state.active_legend,
			default_legend: this.state.default_legend,
		};
	};

	getCurrentDeviceIds = () => {
		if (this.props.configuration.device_ids === undefined) {
			return this.props.widget.DVC_IDS
				? this.props.widget.DVC_IDS
				: undefined;
		} else if (this.props.configuration.device_ids === '') {
			return undefined;
		} else {
			return this.props.configuration.device_ids;
		}
	};

	getCurrentGeoZoneIds = () => {
		if (this.props.configuration.geozone_ids === undefined) {
			return this.props.widget.GZO_IDS
				? this.props.widget.GZO_IDS
				: undefined;
		} else if (this.props.configuration.geozone_ids === '') {
			return undefined;
		} else {
			return this.props.configuration.geozone_ids;
		}
	};

	getCurrentDateStart = () => {
		let date = new Date();

		return this.props.configuration.date_start
			? this.props.configuration.date_start
			: this.props.widget.DWM_DATE_START &&
			  this.props.widget.DWM_DATE_START !== '0000-00-00 00:00:00'
			? this.props.widget.DWM_DATE_START
			: moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
					'YYYY-MM-DD HH:mm:ss'
			  );
	};

	getCurrentDateStop = () =>
		utils.getWidgetDateStop(this.state, this.props, 'DWM_DATE_STOP');

	saveCustomizationToState = (deviceCustomization) => {
		this.setState({ deviceCustomization }, () => {
			let currentConfiguration = this.getCurrentConfiguration();
			this.props.saveConfiguration(currentConfiguration);
		});
	};

	saveToParent = (key, value) => this.setState({ [key]: value });

	showGeolocIcon = (device) => {
		if (device.id !== 'all') {
			let deviceHasNoGeoloc =
				device.latitude === '0' && device.longitude === '0';
			let tooltipTitle = deviceHasNoGeoloc
				? utils.translate('componentWidgetMapConfigure.noGeo')
				: utils.translate('componentWidgetMapConfigure.geo');

			return (
				<Tooltip title={tooltipTitle}>
					<Icon
						type="environment"
						className="greyIcon"
						theme="twoTone"
						twoToneColor={deviceHasNoGeoloc ? '#eb2f96' : '#52c41a'}
					/>
				</Tooltip>
			);
		}
	}


    render()
	{
		const { loading, isCategoryDashboard, dashboardCategory,
			configuration, widget, } = this.props;
		const { devices, selectedDevices, selectedGeoZones, selectedCategories,
			userWantsToTrackDevices, customizeIcons, globalIcon, userWantsDataMarkers, } = this.state;

		const currentDeviceIds = selectedDevices ? (selectedDevices.length > 0 ? selectedDevices.split(',') : undefined) : undefined;
		const currentGeoZoneIds = selectedGeoZones ? (selectedGeoZones.length > 0 ? selectedGeoZones.split(',') : undefined) : undefined;
		const currentCategoryIds = isCategoryDashboard ? [dashboardCategory.id] : selectedCategories;
		const noCategoryIds = !currentCategoryIds || currentCategoryIds.length === 0;
		const entries = devices.length - 1;

		const dateStart = moment(
			this.getCurrentDateStart(),
			'YYYY-MM-DD HH:mm:ss'
		);
		const dateStop = moment(
			this.getCurrentDateStop(),
			'YYYY-MM-DD HH:mm:ss'
		);

		const userWantsToCustomizeDevices =
			userWantsToTrackDevices && !loading && selectedDevices !== '';
		const userWantsToCustomizeIcons =
			customizeIcons && !loading && selectedDevices !== '';
		const toggleDisabled = loading || !selectedDevices;

		return (
			<Tabs tabPosition="top" tabBarStyle={{ marginBottom: 25 }}>
				<TabPane
					tab={
						<span>
							<Icon type="wifi" className="greyIcon" />
							{utils.translate('componentWidgetConfigure.data')}
						</span>
					}
					key="1"
				>
					<Card bordered={false}>
						{!this.props.isCategoryDashboard &&
						<DeviceSection
							label={utils.translate('generic.dvcs') }
							deviceSelectMode='multiple'
							checkGeoloc={true}
							deviceSelectRef={this.allDevices}
							entries={entries}
							deviceSelectPlaceholder={multipleDevicesPH}
							devices={this.state.devices}
							categories={this.props.categories}
							containers={this.props.containers}
							groups={this.props.groups}
							selectedDevices={currentDeviceIds}
							categoryFilter={this.props.categoryFilter}
							containerFilter={this.props.containerFilter}
							groupFilter={this.props.groupFilter}
							loading={this.props.loading}
							disabled={this.props.loading}
							allowClearDeviceSelect={true}
							handleDeviceChange={this.handleDeviceChange}
							handleCategoryFilterChange={this.props.handleCategoryFilterChange}
							handleContainerFilterChange={this.props.handleContainerFilterChange}
							handleGroupFilterChange={this.props.handleGroupFilterChange}
							api_getDevices={this.props.api_getDevices}
							showGeolocIcon={this.showGeolocIcon}
						/>}
						{this.props.isCategoryDashboard &&
						<div>{categoryDashboardInfo}</div>}
					</Card>
				</TabPane>
				<TabPane
					forceRender
					tab={
						<span>
							<Icon type="pushpin" className="greyIcon" />
							{utils.translate('componentWidgetSitemap.markers')}
						</span>
					}
					key="2"
				>
					<Card bordered={false}>
						<CustomizerSwitchItem
							label={utils.translate('generic.markerType')}
							iconType="pushpin"
							switchDisabled={noCategoryIds}
							userWantsToCustomize={userWantsDataMarkers}
							handleCustomizerToggle={
								this.handleDataMarkersToggle
							}
							unCheckedChildren={utils.translate(
								'componentWidgetMapConfigure.basic'
							)}
							checkedChildren={utils.translate(
								'componentWidgetMapConfigure.advanced'
							)}
							disabledTooltipText={utils.translate(
								'componentWidgetMapConfigure.select'
							)}
						/>
						{userWantsDataMarkers && (
							<ComplexMarkerSection
								widget={widget}
								categoryIds={currentCategoryIds}
								configuration={configuration}
								saveToParent={this.saveToParent}
								devicesLoading={loading}
							/>
						)}
						<Divider />
						<IconSection
							{...this.props}
							{...this.state}
							label={utils.translate(
								'componentWidgetMapConfigure.marker'
							)}
							initialIcon={globalIcon}
							switchDisabled={toggleDisabled}
							userWantsToCustomizeDevices={
								userWantsToCustomizeIcons
							}
							setIconToParent={this.handleIconChange}
							setIconTableDataToParent={
								this.handleIconTableDataChange
							}
							handleCustomizerToggle={this.setCustomizeIcons}
						/>
						<Divider />
						<SwitchSection
							checked={this.state.profileChecked}
							handleSwitch={this.handleProfileSwitch}
							label={utils.translate('generic.dvcProfile')}
							iconType="idcard"
							info={deviceProfileInfo}
						/>
					</Card>
				</TabPane>
				<TabPane
					forceRender
					tab={
						<span>
							<Icon type="environment" className="greyIcon" />
							{utils.translate(
								'componentWidgetMapConfigure.geoTracking2'
							)}
						</span>
					}
					key="3"
				>
					<Card bordered={false}>
						<DeviceCustomizerSection
							customizerType="geoTracking"
							label={utils.translate(
								'componentWidgetMapConfigure.geoTracking'
							)}
							info={geolocCsvInfo}
							iconType="flag"
							allDevices={this.state.devices}
							devices={this.state.selectedDevices}
							widget={this.props.widget}
							dateRange={this.state.dateRange}
							dateStart={dateStart}
							dateStop={dateStop}
							currentDate={this.state.currentDate}
							userWantsToCustomizeDevices={
								userWantsToCustomizeDevices
							}
							loading={this.props.loading}
							switchDisabled={toggleDisabled}
							csvChecked={this.state.csvChecked}
							handleCustomizerToggle={this.handleCustomizerToggle}
							handleDateRangeChange={this.handleDateRangeChange}
							handleCurrentDateChange={
								this.handleCurrentDateChange
							}
							handleDatesChange={this.handleDatesChange}
							handleCsvSwitch={this.handleCsvSwitch}
							saveCustomizationToParentState={
								this.saveCustomizationToState
							}
						/>
						<Divider />
						<GeoZoneSection
							selectLabel={utils.translate(
								'componentWidgetMapConfigure.geoZones'
							)}
							toggleLabel={utils.translate(
								'componentWidgetMapConfigure.show'
							)}
							geoZoneSelectRef={this.allGeoZones}
							geoZones={this.state.geoZones}
							selectedGeoZones={currentGeoZoneIds}
							addGeoZoneDevices={this.state.addGeoZoneDevices}
							removeGeoZoneDevices={
								this.state.removeGeoZoneDevices
							}
							handleGeoZoneChange={this.handleGeoZoneChange}
							handleAddGeoZoneDevicesChange={() =>
								this.setState({
									addGeoZoneDevices:
										!this.state.addGeoZoneDevices,
								})
							}
							handleRemoveGeoZoneDevicesChange={() =>
								this.setState({
									removeGeoZoneDevices:
										!this.state.removeGeoZoneDevices,
								})
							}
							disabled={this.props.loading}
						/>
					</Card>
				</TabPane>
				<TabPane
					tab={
						<Tooltip title="Style">
							<span>
								<Icon type="bg-colors" className="greyIcon" />
								{utils.translate(
									'componentWidgetConfigure.style'
								)}
							</span>
						</Tooltip>
					}
					key="4"
				>
					<WidgetStyle
						{...this.props}
						marginTop={20}
						configuration={this.getCurrentConfiguration()}
					/>
				</TabPane>
			</Tabs>
		);
	}
}

export default Map;
