import React, { Component } from 'react';

import EmptyWidget from '../../WidgetLib/EmptyWidget/EmptyWidget';
import AlarmTable from './AlarmTable/AlarmTable';
import StatusTable from './StatusTable/StatusTable';

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils.js';

import './Table.css';



class Table extends Component
{

    constructor(props)
	{
        super(props);

        this.state = {
            devices: [],
			allDeviceIds: [],
			columnsToDisplay: undefined,
			loading: false,
			dataLoaded: false,
        };

		this.timerId = null;
		this.componentIsMounted = false;
    }


    componentDidMount()
	{
		this.componentIsMounted = true;

		const { widget } = this.props;

        if (!widget.DVC_IDS && widget.OTHER_DVC_IDS && widget.OTHER_DVC_IDS.length === 0) { return; }

        this.timerId = utils.setRefresh(widget.DWG_REFRESH_FREQ, () => this.api_getDevices(true));

		this.api_getDevices(true);
    }


    componentDidUpdate(prevProps, prevState)
	{
		const { widget } = this.props;

		if ((prevProps.widget.DVC_IDS && widget.DVC_IDS.length === 0 && widget.OTHER_DVC_IDS.length === 0) ||
			(prevProps.widget.OTHER_DVC_IDS && prevProps.widget.OTHER_DVC_IDS.length > 0 &&
				widget.OTHER_DVC_IDS.length === 0 && widget.DVC_IDS.length === 0))
		{
			this.setState({ devices: [] });
			return;
		}
        if ((prevProps.widget.DVC_IDS !== widget.DVC_IDS) ||
			(JSON.stringify(prevProps.widget.OTHER_DVC_IDS) !== JSON.stringify(widget.OTHER_DVC_IDS)))
		{
			this.api_getDevices(true);
        }
		if (prevState.columnsToDisplay !== undefined &&
			this.state.columnsToDisplay !== undefined &&
			prevState.columnsToDisplay !== this.state.columnsToDisplay &&
			prevState.columnsToDisplay.length < this.state.columnsToDisplay.length
		)
		{
			const newColumnsArray = this.state.columnsToDisplay.filter(column => !prevState.columnsToDisplay.includes(column)) || [];
			const newColumn = newColumnsArray['0'];
			// checking if new column is a datatype (is a number). If so, reload
			if (!isNaN(newColumn)) { this.api_getDevices(true, true); }
		}
    }


    componentWillUnmount()
	{
		this.componentIsMounted = false;
        if (this.timerId !== null) { utils.clearRefresh(this.timerId); }
    }


	api_getDevices = (getData = false, partialUpdate = false) =>
	{
		const { widget } = this.props;
		const isAlarmTable = widget.DWT_TYPE === 'alarm';
		const allDeviceIds = widget.DVC_IDS ? widget.DVC_IDS.split(',') : widget.OTHER_DVC_IDS ? widget.OTHER_DVC_IDS : [];
		const columns = this.state.columnsToDisplay ? this.state.columnsToDisplay.toString() : widget.DWT_COLUMNS;
		const otherDvcIdsString = widget.OTHER_DVC_IDS ? widget.OTHER_DVC_IDS.join() : '';

		let params = {
			dependencies: isAlarmTable ? null : columns,
			device_ids: widget.DVC_IDS || otherDvcIdsString,
		};

		if (getData)
		{
			params.get_data = 'true';
			this.setState({ dataLoaded: false });
		}

		this.setState({ loading: !partialUpdate }, () =>
		{
			request.post(
				'deadevice',
				params,
				(api_devices) =>
				{
					if (this.componentIsMounted)
						this.setState({ devices: api_devices.deadevice });
				},
				() => console.error('load device failed'),
				() =>
				{
					if (this.componentIsMounted)
					{
						this.setState({
							allDeviceIds: allDeviceIds,
							loading: false,
						});

						if (getData) { this.setState({ dataLoaded: true }); }
					}
				}
			);
		});
	}


	setColumns = (columns) => this.setState({ columnsToDisplay: columns });


    render()
	{
		const { widget, size, isCategoryDashboard } = this.props;
		const { devices, loading, allDeviceIds, dataLoaded } = this.state;

		const widgetIsEmpty = utils.isConfigMode() && devices && devices.length === 0 && !isCategoryDashboard && !loading;
		const isAlarmTable = widget.DWT_TYPE === 'alarm';
		const isDeviceTable = widget.DWT_TYPE === 'status';

        return (

			<div
				className='widget'
				style={{ overflow: 'auto' }}
			>

				{!widgetIsEmpty && isAlarmTable &&
				<AlarmTable
					className='alarmTable'
					size={size}
					devices={devices}
					allDeviceIds={allDeviceIds}
					widget={widget}
					DWG_REFRESH_FREQ={widget.DWG_REFRESH_FREQ}
					loading={loading}
				/>}

				{!widgetIsEmpty && isDeviceTable &&
				<StatusTable
					size={size}
					devices={devices}
					allDeviceIds={allDeviceIds}
					widget={widget}
					DWG_REFRESH_FREQ={widget.DWG_REFRESH_FREQ}
					loading={loading}
					dataLoaded={dataLoaded}
					saveColumnsToParent={this.setColumns}
					isCategoryDashboard={isCategoryDashboard}
				/>}

				{widgetIsEmpty &&
				<EmptyWidget
					{...this.props}
					callToAction={utils.translate('componentWidgetTable.start')}
				/>}

			</div>
        );
    }
}


export default Table;
