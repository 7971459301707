import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Select } from 'antd';

import LabelItem from '../../FormItems/LabelItem/LabelItem';

import { utils } from 'Lib/utils';


const TypeSection = (props) =>
{
	return (

		<FormGroup>
			<Row>
				<LabelItem
					iconType={props.iconType}
					label={props.label}
				/>
				<Col md={{size: 9}}>
					<Select
						name={props.selectName}
						value={props.selectValue}
						onChange={props.onChange}
						placeholder={props.selectPlaceholder}
						style={{ width: '100%' }}
					>
						{utils.getOptionsFromArray(props.options)}
					</Select>
				</Col>
			</Row>
		</FormGroup>
	)
};


export default TypeSection;
