import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Switch, Tooltip } from 'antd';

import LabelItem from '../../FormItems/LabelItem/LabelItem';

import { utils } from 'Lib/utils.js';


const SwitchSection = (props) => {

	const {
		checked,
		handleSwitch,
		otherChecked,
	} = props;


	return (

		<FormGroup>
			<Row className='align-items-center' style={{ paddingTop: '15px' }}>
				<LabelItem
					{...props}
				/>
				<Col md={{ size: 9 }}>
					<Tooltip
						title={
							otherChecked ?
								utils.translate("componentStyleSwitchSection.tooltip") : ''
						}
					>
						<Switch
							disabled={otherChecked ? true : false}
							checked={otherChecked ? false : checked}
							onChange={handleSwitch}
						/>
					</Tooltip>
				</Col>
			</Row>
			<Row>
				<Col>
					{props.info}
				</Col>
			</Row>
		</FormGroup>
	)
};


export default SwitchSection;
