import React, { Component } from 'react';
import { Card, CardTitle, CardBody, CardText } from 'reactstrap';

class NotFound extends Component {

    render() {
        return(
            <div className="content">
				<Card style={{marginTop: '-12px', width: '100%'}}>
					<CardBody className="text-center body">
						<CardTitle className="text-center title">
							<img src={window.location.origin + '/404-error.png'} alt='Not Found' style={{ borderRadius: '5px', maxWidth: '100%' }}/>
						</CardTitle>
						<h2>Oops... that's a 404.</h2>
						<p>This page does not exist, or you are not currently authorized to access it.</p>
						<CardText>
							<small>IoT App Studio®</small>
						</CardText>
					</CardBody>
				</Card>
            </div>
        );
    }
}

export default NotFound;
