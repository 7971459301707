import React from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { utils } from 'Lib/utils';


const SaveButton = (props) =>
{

	const {
		saveChanges,
		saveButtonDisabled,
	} = props;


	return (

		<Button
			block
			type='primary'
			onClick={saveChanges}
			disabled={saveButtonDisabled}
		>
			<FontAwesomeIcon className='drawerIcon' icon='hdd'/>
			{utils.translate('generic.save')}
		</Button>
	)
};


export default SaveButton;
