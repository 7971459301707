import React from 'react';
import { FormGroup } from 'reactstrap';
import { Button } from 'antd';
import ImageUploader from 'react-images-upload';

import { utils } from 'Lib/utils';


const ImageUploadSection = (props) =>
{

	const {
		showRemoveButton,
		handleImageChange,
		removeImage,
		buttonText,
		buttonStyles,
		small,
	} = props;


	const infoText = (
		<span>
			<center><i>{utils.translate('componentImageUploadSection.max')}</i></center>
			<i>{utils.translate('componentImageUploadSection.formats')}</i>
		</span>
	);


	return (

		<FormGroup style={{ textAlign: 'center' }}>
			<ImageUploader
				label={!small && infoText}
				singleImage={true}
				withIcon={!small && true}
				withPreview={true}
				fileSizeError={utils.translate('componentImageUploadSection.larger')}
				fileTypeError={utils.translate('componentImageUploadSection.formatInvalid')}
				buttonText={buttonText}
				buttonStyles={buttonStyles}
				fileContainerStyle={{boxShadow: 'none'}}
				onChange={handleImageChange}
				imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
				maxFileSize={1242880}
			/>
			{showRemoveButton &&
			<Button
				onClick={removeImage}
				type='danger'
				icon='close'
				style={{
					width: 150,
					marginTop: -50,
				}}
			>
				{utils.translate('componentImageUploadSection.remove')}
			</Button>}
		</FormGroup>
	)
};


export default ImageUploadSection;
