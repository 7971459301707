import React, { useEffect, useRef } from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Select, Form, Input, InputNumber, Tag, Icon } from 'antd';
import SuperSelect from 'Components/Studio/StudioLib/SuperSelect/SuperSelect';
import { utils } from 'Lib/utils';
const { Option } = Select;

const AlarmRuleSection = (props) => {

	const {
		allAlarmRules,
		selectedAlarmRule,
		allEmails,
		allPhones,
		inputPhoneVisible,
		inputEmailVisible,
		backgroundEmail,
		backgroundPhone,
		loading,
	} = props;

	const phoneInputRefs = useRef(null);
	const emailInputRefs = useRef(null);
	const haveRights = props.currentPage.ARC_RIGHT === 'rw';
	const isThresholdNumber = selectedAlarmRule && !isNaN(selectedAlarmRule.param1);
	const isData20 = selectedAlarmRule && selectedAlarmRule.alarm_type.type === 'DATA_020' && selectedAlarmRule.alarm_type.param1_array !== null;
	const isValid = selectedAlarmRule && selectedAlarmRule.alarm_type.type !== 'DATA_100' && selectedAlarmRule && !selectedAlarmRule.alarm_type.type.includes('GEO_');
	let selectData20 = isData20 ? JSON.parse(selectedAlarmRule.alarm_type.param1_array) : {};
	let isDeviceList = selectedAlarmRule && selectedAlarmRule.scope.type === 'DEVICE_LIST';

	useEffect(() => {
		if (inputPhoneVisible) {
			phoneInputRefs.current.focus();
		}

		if (inputEmailVisible) {
			emailInputRefs.current.focus();
		}
	}, [inputPhoneVisible, inputEmailVisible]);


	const getDevicesList = () => {
		let deviceList = []
		selectedAlarmRule.scope.devices.map((device) => {
			deviceList.push(device.label);
			return null
		})
		return deviceList;
	}

	const getCatList = () => {
		let catList = []
		selectedAlarmRule.scope.categories.map((category) => {
			catList.push(category.label);
			return null
		})
		return catList;
	}


	return (
		<>
			{props.selectedAlarmRules.length !== 0 &&
				<>
					<FormGroup>
						<Row>
							<Col md={{ size: 9 }}>
								<SuperSelect
									value={selectedAlarmRule ? selectedAlarmRule.id : allAlarmRules && allAlarmRules[0] && allAlarmRules[0].id}
									onChange={props.handleAlarmRuleChange}
									showSearch
									optionFilterProp='children'
									style={{ width: '100%' }}
									maxTagCount={20}
									showArrow={true}
									filterOption={(input, option) => option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
									{allAlarmRules.sort((a, b) => a.id - b.id).map((alarmrule) =>
										<Option key={alarmrule.id} value={alarmrule.id}>{alarmrule.id + ' - ' + alarmrule.label}</Option>
									)}
								</SuperSelect>
							</Col>
						</Row>
					</FormGroup>
					{selectedAlarmRule && !loading &&
						<FormGroup>
							{selectedAlarmRule.description &&
								<Row>
									<Col md={{ size: 12 }}>
										<p key={selectedAlarmRule}>{utils.translate('componentWidgetAlarmRule.description')}{selectedAlarmRule.description}</p>
									</Col>
								</Row>}
							{isDeviceList && selectedAlarmRule.scope.devices &&
								<>
									<Row>
										<Col md={{ size: 12 }}>
											<div key={selectedAlarmRule}>
												{utils.translate('componentWidgetAlarmRule.devices')}{' '}
												<SuperSelect
													value={getDevicesList()}
													disabled={true}
													mode="multiple"
													style={{ width: '70%' }}
												/>
											</div>
										</Col>
									</Row>
									<br />
								</>}
							{!isDeviceList && selectedAlarmRule.scope.categories &&
								<>
									<Row>
										<Col md={{ size: 12 }}>
											<div key={selectedAlarmRule}>
												{utils.translate('componentWidgetAlarmRule.cats')}{' '}
												<SuperSelect
													value={getCatList()}
													disabled={true}
													mode="multiple"
													style={{ width: '70%' }}
												/>
											</div>
										</Col>
									</Row>
									<br />
								</>}

							{isThresholdNumber && !isData20 && isValid &&
								<Row>
									<Col md={{ size: 12 }}>
										<div key={selectedAlarmRule}>
											{utils.translate('componentWidgetAlarmRule.threshold')}{selectedAlarmRule.alarm_type.param1_label && selectedAlarmRule.alarm_type.param1_label} {' '}
											{<InputNumber
												className={'NoDrag'}
												disabled={!haveRights}
												value={props.threshold} step={0.1}
												placeholder={'Change Threshold'}
												onChange={props.setNewThreshold}
											/>}
											{selectedAlarmRule.alarm_type.param1_unit && ' (' + selectedAlarmRule.alarm_type.param1_unit + ')'}
										</div>
									</Col>
								</Row>}
							{!isThresholdNumber && !isData20 && isValid &&
								<Row>
									<Col md={{ size: 12 }}>
										<div key={selectedAlarmRule}>
											{utils.translate('componentWidgetAlarmRule.threshold')}{selectedAlarmRule.alarm_type.param1_label && selectedAlarmRule.alarm_type.param1_label} {' '}
											{<Input
												className={'NoDrag'}
												disabled={!haveRights}
												style={{ width: '150px' }}
												value={props.threshold}
												placeholder={'Change Threshold'}
												onChange={props.setNewThresholdText}
											/>}
											{selectedAlarmRule.alarm_type.param1_unit && ' (' + selectedAlarmRule.alarm_type.param1_unit + ')'}
										</div>
									</Col>
								</Row>}
							{isData20 && isValid &&
								<Row>
									<Col md={{ size: 12 }}>
										<div key={selectedAlarmRule}>
											{utils.translate('componentWidgetAlarmRule.threshold')}{selectedAlarmRule.alarm_type.param1_label && selectedAlarmRule.alarm_type.param1_label} {' '}
											<SuperSelect
												value={props.threshold}
												disabled={!haveRights}
												onChange={props.setNewThreshold}
												style={{ width: '150px' }}
												showArrow={true}
											>
												{Object.keys(selectData20).sort((a, b) => a - b).map((value) =>
													<Option key={value} value={value}>{value}</Option>
												)}
											</SuperSelect>
										</div>
									</Col>
								</Row>}
							<Row style={{ alignItems: 'stretch', paddingTop: '10px' }}>
								<Col md={{ size: 6 }}>
									<Form.Item
										label={utils.translate('componentWidgetAlarmRule.emails')}
									>
										{inputEmailVisible && haveRights && (
											<Input
												ref={emailInputRefs}
												style={{ width: 150, backgroundColor: backgroundEmail }}
												className={'NoDrag'}
												placeholder='xxx@xxx.com'
												type="text"
												size="small"
												onChange={props.handleEmailChange}
												onBlur={() => props.setNewEmailList()}
												onPressEnter={() => props.setNewEmailList()}
											/>
										)}
										{!inputEmailVisible && haveRights && (
											<Tag onClick={props.showEmailInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
												<Icon type="plus" /> {utils.translate('componentWidgetAlarmRule.newEmail')}
											</Tag>
										)}
										<br />
										{allEmails.map((email) =>
											<Tag key={email} closable={haveRights} onClose={() => props.handleEmailClose(email)}>
												{email}
											</Tag>
										)}
									</Form.Item>
								</Col>
								<Col md={{ size: 6 }}>
									<Form.Item
										label={utils.translate('componentWidgetAlarmRule.phones')}
									>
										{inputPhoneVisible && haveRights && (
											<Input
												ref={phoneInputRefs}
												placeholder='+33655555555'
												className={'NoDrag'}
												style={{ width: 150, backgroundColor: backgroundPhone }}
												type="text"
												size="small"
												onChange={props.handlePhoneChange}
												onBlur={() => props.setNewPhoneList()}
												onPressEnter={() => props.setNewPhoneList()}
											/>
										)}
										{!inputPhoneVisible && haveRights && (
											<Tag onClick={props.showPhoneInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
												<Icon type="plus" /> {utils.translate('componentWidgetAlarmRule.newPhone')}
											</Tag>
										)}
										<br />
										{allPhones.map((phone) =>
											<Tag key={phone} closable={haveRights} onClose={() => props.handlePhoneClose(phone)}>
												{phone}
											</Tag>
										)}
									</Form.Item>
								</Col>
							</Row>

						</FormGroup>}
				</>}
			{props.selectedAlarmRules.length === 0 && !props.loading &&
				<div className='noData'>
					<div style={{ marginTop: '10px' }}>
						<p style={{ fontSize: '15px' }}><i>{utils.translate('componentWidgetAlarmRule.noData')}</i></p>
					</div>
				</div>
			}
		</>
	)
};


export default AlarmRuleSection;
