import React, { useState, useEffect } from 'react';
import { Form } from 'reactstrap';
import { Divider } from 'antd';

import { utils } from 'Lib/utils';
import ContainerSection from
	'../../../FormSections/ContainerSection/ContainerSection';
import MultipleAttributeSection from
	'../../../FormSections/MultipleAttributeSection/MultipleAttributeSection';

import { multipleContainersPH, multipleAttributesPH } from
	'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';


const AttributeConfigure = (props) =>
{

	const {
		widget,
		widgetType,
		containers,
		configuration,
		saveConfiguration,
	} = props;

	const [selectedContainerIds, setSelectedContainerIds] = useState(undefined);
	const [selectedValueIds, setSelectedValueIds] = useState(undefined);
	const [attributeOptions, setAttributeOptions] = useState(undefined);
	const [attributeSelectDisabled, setAttributeSelectDisabled] = useState(true);


	useEffect(() =>
		{
			const valIds = widget.VAL_IDS;

			if (valIds && valIds[0])
			{
				const containerObjects = containers && containers.filter(container =>
					container.attribs && container.attribs.find(attribute =>
						valIds.includes(attribute.value_id)));

				setSelectedContainerIds(getContainerIds(containerObjects));
				setAttributeOptions(getAttributeOptions(containerObjects));
				setSelectedValueIds(valIds);
			}
		},
		[]
	);


	useEffect(() =>
		{
			const noContainerSelected = !selectedContainerIds ||
				!selectedContainerIds[0];

			setAttributeSelectDisabled(noContainerSelected);
		},
		[selectedContainerIds]
	);


	useEffect(() =>
		{
			saveConfiguration({
				...configuration,
				type: widgetType,
				value_ids: selectedValueIds,
			});
		},
		[selectedValueIds]
	);


	const handleContainerChange = (containerIds) =>
	{
		const containerObjects = containers &&
			containers.filter(container => containerIds.includes(container.id));

		setSelectedContainerIds(containerIds);
		setAttributeOptions(getAttributeOptions(containerObjects));
		containerIds.length === 0 && setSelectedValueIds(undefined);
	}


	const handleAttributeChange = (valueIds) =>
	{
		setSelectedValueIds(valueIds);
	}


	const getAttributeOptions = (containerObjects) =>
	{
		if (containerObjects)
		{
			const attributeOptions = [];

			containerObjects.forEach((containerObject) =>
			{
				containerObject.attribs &&
					containerObject.attribs.forEach((attribute) =>
					{
						attributeOptions.push(attribute);
					});
			});

			return attributeOptions;
		}
	}


	const getContainerIds = (containerObjects) =>
	{
		if (containerObjects)
		{
			return containerObjects.map((containerObject) =>
			{
				return containerObject.id;
			});
		}
	}


	return (

		<Form>

			<ContainerSection
				{...props}
				label={utils.translate('componentAttributeConfigure.containers')}
				iconType='container'
				containerSelectMode='multiple'
				containerSelectPlaceholder={multipleContainersPH}
				selectedContainerId={selectedContainerIds}
				handleContainerChange={handleContainerChange}
			/>

			<Divider/>

			<MultipleAttributeSection
				{...props}
				label={utils.translate('componentAttributeConfigure.attributes')}
				iconType='unordered-list'
				attributeSelectMode='multiple'
				attributeSelectPlaceholder={multipleAttributesPH}
				onlyAllowNumericAttributes
				disabled={attributeSelectDisabled}
				attributeOptions={attributeOptions}
				selectedValueIds={selectedValueIds}
				handleAttributeChange={handleAttributeChange}
			/>

		</Form>
	)
};


export default AttributeConfigure;
