import React, { useState, useEffect } from 'react';

import { utils } from 'Lib/utils';
import DatatypeCustomizer from
	'../../../../FormSections/DatatypeCustomizerSection/DatatypeCustomizer';

const JWC_DEFAULT_A_VALUE = '1';
const JWC_DEFAULT_B_VALUE = '0';
const JWC_DEFAULT_COLORS = '#fafafa,#dddf0d,#ff5369';


const DatatypeCustomizationConfigure = (props) =>
{

	const {
		widget,
		widgetType,
		configs,
		configuration,
		selectedDatatypes,
		saveConfiguration,
	} = props;

	const [datatypeCustomization, setDatatypeCustomization] = useState([]);
	const [userWantsToCustomizeDatatypes, setUserWantsToCustomizeDatatypes] = useState(false);


	useEffect(() =>
		{
			setUserWantsToCustomizeDatatypes(areDatatypesCustomized());
		},
		[]
	);


	useEffect(() =>
		{
			saveConfiguration({
				...configuration,
				type: widgetType,
				datatypeCustomization: datatypeCustomization,
			});
		},
		[datatypeCustomization]
	);


	const areDatatypesCustomized = () =>
	{
		let datatypesAreCustomized = false;

		if (widget.JWC)
		{
			Object.keys(widget.JWC).forEach(key =>
			{
				const value = widget.JWC[key]

				if (value.JWC_COEFFA !== JWC_DEFAULT_A_VALUE ||
					value.JWC_COEFFB !== JWC_DEFAULT_B_VALUE ||
					value.JWC_LABEL !== '' || value.JWC_UNIT !== '' ||
					value.JWC_COLORS !== JWC_DEFAULT_COLORS ||
					value.JWC_LOW_THRES !== '' || value.JWC_HIGH_THRES !== ''
				)
				{ if (key !== '') { datatypesAreCustomized = true; } }
			});
		}

		return datatypesAreCustomized;
	}


	const handleCustomizerToggle = (checked) =>
	{
		setUserWantsToCustomizeDatatypes(checked);
    }


	const saveCustomizationToState = (datatypeCustomization) =>
	{
		let datatypeCustomizationCopy = JSON.parse(JSON.stringify(datatypeCustomization));
		// child state must remain immutable, so we have to use a copy
		// JSON stringify then parse is only way to make sure that all nested objects get cloned
		datatypeCustomizationCopy.forEach(customizedDatatype =>
		{
			configs.forEach(config =>
			{
				if (config.id === customizedDatatype.key)
				{
					if (customizedDatatype.label === config.label_D1) { customizedDatatype.label = ''; }
					if (customizedDatatype.unit === config.unite_D1) { customizedDatatype.unit = ''; }
					if (customizedDatatype.colors === config.colors) { customizedDatatype.colors = ''; }
					if (customizedDatatype.lowThres === null) { customizedDatatype.lowThres = ''; }
					if (customizedDatatype.highThres === null) { customizedDatatype.highThres = ''; }
                }
            })
        });

        setDatatypeCustomization(datatypeCustomizationCopy);
    }


	return (

		<DatatypeCustomizer
			label={utils.translate('componentDatatypeCustomizationConfigure.datatypes')}
			description={utils.translate('componentDatatypeCustomizationConfigure.select')}
			allDatatypes={configs}
			selectedDatatypes={selectedDatatypes}
			widget={widget}
			userWantsToCustomizeDatatypes={userWantsToCustomizeDatatypes}
			handleCustomizerToggle={handleCustomizerToggle}
			saveCustomizationToParentState={saveCustomizationToState}
		/>
	)
};


export default DatatypeCustomizationConfigure;
