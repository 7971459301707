import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Container } from 'reactstrap';
import { Row, List, Col, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LabelItem from 'Components/Studio/Dashboard/Widgets/Configure/FormItems/LabelItem/LabelItem';
import { utils } from 'Lib/utils';


class DetailsScheduledCommand extends Component {

	constructor(props) {
		super(props);

		this.state =
		{
			eventId: undefined,
			eventDate: undefined,
			command: undefined,
			deviceId: undefined,
			deviceLabel: '',
			devices: [],
			set: '',
			status: undefined,
			executionDate: '',
			executionReturn: '',
			param: utils.translate('componentWidgetScheduler.none'),
			port: 'Default',
			org: '',
			endDate: '',
			startDate: '',
			mode: ''
		}
	}


	componentDidMount = () => {

		this.componentIsMounted = true;
	}


	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.newEvent !== this.props.newEvent) {
			if (this.props.newEvent) {
				this.setState({
					eventId: this.props.newEvent.SCM_ID,
					eventDate: this.props.recurringDate ? this.props.recurringDate : this.props.newEvent.SCM_REPEAT_START,
					command: this.props.newEvent.SCM_LABEL,
					set: this.props.newEvent.SCM_SET,
					deviceId: this.props.newEvent.DVC_ID,
					deviceLabel: !this.props.widget.CAT_IDS.length > 0 ? this.props.newEvent.devices[0].DVC_LABEL : '',
					status: this.props.eventRecurringDetails ? this.props.eventRecurringDetails.return : this.props.newEvent.SCM_EXESTATUS,
					executionDate: this.props.newEvent.SCM_EXECUTIONDATE ? this.props.newEvent.SCM_EXECUTIONDATE : '',
					executionReturn: this.props.eventRecurringDetails ? this.props.eventRecurringDetails.return : this.props.newEvent.SCM_EXECUTIONRETURN ? this.props.newEvent.SCM_EXECUTIONRETURN : utils.translate('componentWidgetScheduler.none'),
					param: this.props.newEvent.SCM_CMDPARAM ? this.props.newEvent.SCM_CMDPARAM : utils.translate('componentWidgetScheduler.none'),
					port: this.props.newEvent.SCM_CMDPORT ? this.props.newEvent.SCM_CMDPORT : utils.translate('componentWidgetScheduler.none'),
					org: this.props.newEvent.ORG_NAME,
					devices: this.props.newEvent.devices,
					endDate: this.props.newEvent.SCM_REPEAT_END ? this.props.newEvent.SCM_REPEAT_END : '',
					startDate: this.props.newEvent.SCM_REPEAT_START ? this.props.newEvent.SCM_REPEAT_START : '',
					mode: this.props.newEvent.SCM_REPEAT_MODE ? this.props.newEvent.SCM_REPEAT_MODE : '',

				}, () => this.props.setNewEvent(undefined), this.props.setEventRecurringDetails(undefined))
			}
		}
	}



	render() {


		let deviceLabel = '';
		let isCategoryCommand = this.state.set === 'SCM_ST_ONECAT';

		if (!isCategoryCommand) {
			deviceLabel = this.state.deviceLabel;
		}

		if (isCategoryCommand) {
			deviceLabel = 'Category'
		} 

		let status = this.state.status === 'SCM_ES_INIT' ?
		utils.translate('componentWidgetScheduler.init2') : this.state.status === 'SCM_ES_CALLED' ?
		utils.translate('componentWidgetScheduler.called')  : this.state.status === 'SCM_ES_DONEOK' || this.state.status === 'SUCCES' ?
		utils.translate('componentWidgetScheduler.done2')  : this.state.status === 'SCM_ES_DONEFAILED'|| this.state.status === '' ?
		utils.translate('componentWidgetScheduler.failed')  : utils.translate('componentWidgetScheduler.none') ;


		const { Option } = Select;
		return (
			<>
				<Modal onMouseDown={e => e.stopPropagation()} isOpen={this.props.modal} toggle={this.props.toggleModal} size='lg'>

					<ModalHeader toggle={this.toggleModal}>
						<FontAwesomeIcon className='widget-icon' icon='eye' />
						{this.state.command && utils.toSubstring(this.state.command)}
					</ModalHeader>

					<ModalBody>
						<Container style={{ width: '100%' }}>
							<Row>
								<Col span={12}>
									<List>
										<List.Item>
											<List.Item.Meta
												title={isCategoryCommand ? utils.translate('componentWidgetScheduler.cat') : utils.translate('generic.dvc')}
												description={isCategoryCommand ? this.props.widget.DWE_CATLABEL : deviceLabel}
											/>
										</List.Item>
										<List.Item>
											<List.Item.Meta
												title={utils.translate('componentWidgetScheduler.status')}
												description={status}
											/>
										</List.Item>
										<List.Item>
											<List.Item.Meta
												title={utils.translate('componentWidgetScheduler.param') }
												description={this.state.param && this.state.param}
											/>
										</List.Item>
										{this.state.executionDate && !this.props.recurringDate &&
										<List.Item>
											<List.Item.Meta
												title={utils.translate('componentWidgetScheduler.exec')}
												description={this.state.executionDate && this.state.executionDate}
											/>
										</List.Item>}
										{this.state.mode === 'SCM_RM_ON' && this.state.startDate &&
											<List.Item>
											<List.Item.Meta
												title={utils.translate('componentWidgetScheduler.start')}
												description={this.state.startDate}
											/>
										</List.Item>}
									</List>
								</Col>
								<Col span={12}>
									<List>
										<List.Item>
											<List.Item.Meta
												title={utils.translate('componentWidgetScheduler.org') }
												description={this.state.org && this.state.org}
											/>
										</List.Item>
										<List.Item>
											<List.Item.Meta
												title={utils.translate('componentWidgetScheduler.date')}
												description={this.state.eventDate && this.state.eventDate}
											/>
										</List.Item>
										<List.Item>
											<List.Item.Meta
												title={utils.translate('componentWidgetScheduler.port')}
												description={this.state.port && this.state.port}
											/>
										</List.Item>
										{this.state.executionDate && !this.state.mode === 'SCM_RM_ON' &&
										<List.Item>
											<List.Item.Meta
												title={utils.translate('componentWidgetScheduler.res')}
												description={this.state.executionReturn && this.state.executionReturn}
											/>
										</List.Item>}
										{this.state.mode === 'SCM_RM_ON' &&
											<List.Item>
											<List.Item.Meta
												title={utils.translate('componentWidgetScheduler.end')}
												description={this.state.endDate ? this.state.endDate : '∞'}
											/>
										</List.Item>}
									</List>
								</Col>
							</Row>
							{isCategoryCommand &&
								<Row
									className='align-items-center'
									style={{ paddingTop: '20px' }}
								>
									<Col span={5}>
										<LabelItem
											label={utils.translate('generic.dvcs')}
										/>
									</Col>
									<Col span={7}>
										<Select
											mode='single'
											style={{ width: '150%' }}
											placeholder={utils.translate('componentWidgetScheduler.view')}
											showArrow
										>
											{Object.values(this.state.devices).map((device) =>
												<Option
													key={device.DVC_ID}
													value={device.DVC_LABEL}
													disabled={true}
												>
													{device.DVC_LABEL}
												</Option>
											)}
										</Select>
									</Col>
								</Row>}
						</Container>
					</ModalBody>

				</Modal>

			</>
		);
	}
}


export default DetailsScheduledCommand;
