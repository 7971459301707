import React, { useState } from 'react';
import { Card, Divider } from 'antd';

import DataSourceConfigure from './DataSourceConfigure/DataSourceConfigure';
import OperationConfigure from './OperationConfigure/OperationConfigure';
import ThresholdConfigure from './ThresholdConfigure/ThresholdConfigure';
import PrecisionConfigure from './PrecisionConfigure/PrecisionConfigure';
import LastDataConfigure from './LastDataConfigure/LastDataConfigure';
import ImageConfigure from './ImageConfigure/ImageConfigure';
import CustomizerSwitchItem from 'Components/Studio/Dashboard/Widgets/Configure/FormItems/CustomizerSwitchItem/CustomizerSwitchItem.js';
import MultipleImageConfigure from './MultipleImageConfigure/MultipleImageConfigure';
import SkinConfigure from './SkinConfigure/SkinConfigure';
import { utils } from 'Lib/utils.js';


const Value = (props) =>
{

	const {
		isClassicValue,
		isBooleanValue,
		isIndexValue,
	} = props;

	const [skin, setSkin] = useState(props.widget.DWV_SKIN ? props.widget.DWV_SKIN : 'SKIN_DEFAULT');
	const [currentAttribute, setCurrentAttribute] = useState(undefined);
	const [switchChecked, setSwitchChecked] = useState(props.widget.JWI.length > 0);

	const getSkinState = (skin) => {
		setSkin(skin);
	}

	const getAttribute = (attribute = undefined) => {
		setCurrentAttribute(attribute);
	}

	const handleSwitch = (checked) => {
		setSwitchChecked(checked);
		if(!checked){
			props.saveConfiguration({
				...props.configuration,
				jwi_images: {},
			});
		}
	}

	const isAttributeNumber = currentAttribute === undefined ? true : currentAttribute.type === 'NUMBER';

	return (

		<>
			<Card bordered={false}>
				<DataSourceConfigure
					{...props}
					getAttribute={getAttribute}
				/>
				{!isBooleanValue &&
				<Divider/>}
				<LastDataConfigure
					{...props}
				/>
				{isAttributeNumber && !isBooleanValue &&
				<>
				<Divider/>
				<OperationConfigure
					{...props}
				/>
				</>}
				{isClassicValue && isAttributeNumber &&
				<>
					<Divider/>
					<ThresholdConfigure
						{...props}
					/>
				</>}
				{isClassicValue && isAttributeNumber &&
				<>
					<Divider/>
					<PrecisionConfigure
						{...props}
					/>
				</>}
				{isIndexValue && props.block.length === 1 &&
				<>
					<Divider/>
					<SkinConfigure
						{...props}
						getSkinState={getSkinState}
					/>
				</>}
			</Card>

			{skin === 'SKIN_DEFAULT' &&
			<Card hoverable>
				{!isBooleanValue &&
					<CustomizerSwitchItem
						label={utils.translate('componentImageConfigure.images')}
						iconType='file-image'
						checked={switchChecked}
						userWantsToCustomize={switchChecked}
						handleCustomizerToggle={handleSwitch}
						unCheckedChildren={utils.translate('componentWidgetMapConfigure.single')}
						checkedChildren={utils.translate('componentWidgetMapConfigure.multi')}
					/>}
				{!switchChecked &&
				<ImageConfigure
					{...props}
				/>}
				{switchChecked &&
				<>	
					<MultipleImageConfigure
						{...props}
					/>
					<i>{utils.translate('componentImageConfigure.details')}</i>
					<br/>
					<i>{utils.translate('componentImageConfigure.details1')}</i>
					<br/>
					<i>{utils.translate('componentImageConfigure.details2')}</i>
					<br/>
					<i>{utils.translate('componentImageConfigure.details3')}</i>
				</>}
			</Card>
			}
			{skin !== 'SKIN_DEFAULT' &&
			<Card hoverable>
				<p>{utils.translate("componentWidgetValue.default")}</p>
			</Card>
			}					
		</>
	)
};


export default Value;
