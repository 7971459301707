import React from 'react';

import GeoTrackingTable from './GeoTrackingTable/GeoTrackingTable';
import DateRangeSection from '../DateRangeSection/DateRangeSection';
import SwitchSection from '../SwitchSection/SwitchSection';
import { utils } from 'Lib/utils';


const GeoTrackingSection = (props) =>
{

	return (

		<>
			{props.userWantsToCustomizeDevices &&
			<DateRangeSection
				{...props}
				upToDateDescription={utils.translate('componentGeoTrackingSection.upToDate')}
			/>}

			<GeoTrackingTable
				{...props}
			/>

			{props.userWantsToCustomizeDevices &&
			<>
				<SwitchSection
					{...props}
					label={utils.translate('componentGeoTrackingSection.history')}
					iconType='export'
					checked={props.csvChecked}
					handleSwitch={props.handleCsvSwitch}
				/>
			</>}
		</>
	);
};


export default GeoTrackingSection;
