import { utils } from 'Lib/utils';

export const ChartGroupingModel = {
	NONE: {
		value: 'none',
		label: utils.translate('modelChartGrouping.none'),
	},
	HOUR: {
		value: 'hour',
		label: utils.translate('modelChartGrouping.hour')
	},
	DAY: {
		value: 'day',
		label: utils.translate('modelChartGrouping.day')
	},
	WEEK: {
		value: 'week',
		label: utils.translate('modelChartGrouping.week')
	},
	MONTH: {
		value: 'month',
		label: utils.translate('modelChartGrouping.month')
	},
	YEAR: {
		value: 'year',
		label: utils.translate('modelChartGrouping.year')
	},
};
