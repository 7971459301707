import React, { useState, useEffect } from 'react';

import SliderSection from '../../FormSections/SliderSection/SliderSection';

import { DEFAULT_PRECISION } from 'Lib/globals.js';
import { utils } from 'Lib/utils';


const PrecisionConfigure = (props) =>
{

	const {
		widget,
		configuration,
		saveConfiguration,
	} = props;

	const [precision, setPrecision] = useState(undefined);

	const defaultValue = widget && widget.DWV_PRECISION === undefined ?
		DEFAULT_PRECISION : widget.DWV_PRECISION;

	useEffect(() =>
		{
			if (precision !== undefined)
			{
				saveConfiguration({
					...configuration,
					decimal_precision: precision,
				});
			}
		},
		[precision]
	);


	const handlePrecisionChange = (value) => setPrecision(value);


	return (

		<SliderSection
			label={utils.translate('componentPrecisionConfigure.decimal')}
			iconType='small-dash'
			defaultValue={parseInt(defaultValue)}
			onAfterChange={handlePrecisionChange}
			min={0}
			max={10}
			marks={{ 0: '0', 10: '10' }}
		/>
	)
};


export default PrecisionConfigure;
