import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { NavLink as RrNavLink } from 'react-router-dom';
import { Menu, Icon, Tooltip } from 'antd';

import { utils } from 'Lib/utils';
import { MAX_VISIBLE_STRING } from 'Config/config';


class MenuMaker extends Component
{

	constructor(props)
	{
        super(props);

        this.state = { menuItems: [] };
		this.counter = 0;
    }


	shouldComponentUpdate(nextProps, nextState)
	{ // to avoid unecessary re-renders when parent component updates anything other than menu items
		return !(this.state.menuItems.length > 0 && nextState.menuItems.length > 0);
	}

	componentDidMount()
	{
		this.updateMenuItems();
	}


	componentDidUpdate(prevprops)
	{
		if (!this.props.menuItems || this.props.menuItems.length === 0) { return; }
		this.updateMenuItems();
	}

	updateMenuItems = () =>
	{
		let stateMenuItems = [...this.props.menuItems];
		stateMenuItems.sort((a,b) => (a.PARENT_ID > b.PARENT_ID) ? 1 : ((b.PARENT_ID > a.PARENT_ID) ? -1 : 0)); // needs to be sorted to work
		this.setState({ menuItems: stateMenuItems });
	}


	recursive_submenuHasAuthorizedChildren = (currentMenuItem) =>
	{ // returns true if currentMenuItem has at least one authorized link somewhere in its tree of children
		return this.state.menuItems.some(itemToCheck => // truthy if at least one found
		{
			return itemToCheck.PARENT_ID === currentMenuItem.AMI_ID && // itemToCheck's parent is our current folder
				(itemToCheck.DPG_ID !== undefined || this.recursive_submenuHasAuthorizedChildren(itemToCheck));
				// and it is an authorized link, or a folder which has authorized links
		});
	}

	recursive_constructMenu = (menuItem) =>
	{
		if (this.counter > 500) { return; }
		if (menuItem.AMI_TYPE === 'submenu' && !this.recursive_submenuHasAuthorizedChildren(menuItem)) { return; }

		this.counter += 1;

		const isRootItem = menuItem.PARENT_ID === '0';
		const isAuthorizedLink = !(menuItem.DPG_ID === undefined);

		const subMenuTitle = (
			<span style={{ marginRight: isRootItem ? '' : '20px', position: 'relative', top: '-1px' }}>
				{utils.toSubstring(menuItem.AMI_LABEL)}
			</span> );

		


		return (
			
			menuItem.AMI_TYPE === 'submenu' ? (

				<Menu.SubMenu key={menuItem.AMI_ID} id={'MENU_ITEM_' + menuItem.AMI_ID} className='MAIN_HEADER MENU_ITEM' title={subMenuTitle}>
					{this.state.menuItems.map(item => {
		  				return (item.PARENT_ID === menuItem.AMI_ID) && this.recursive_constructMenu(item)
					})}
				</Menu.SubMenu>
							
			) : (

				<Menu.Item
					key={menuItem.AMI_ID}
					id={'MENU_ITEM_' + menuItem.AMI_ID}
					className='MAIN_HEADER MENU_ITEM'
					style={{ maxHeight: '57px', display: isAuthorizedLink ? '' : 'none' }}
				>
					<Tooltip title={menuItem.AMI_LABEL.length > MAX_VISIBLE_STRING ? menuItem.AMI_LABEL : null}>
						<NavLink
							tag={RrNavLink}
							to={utils.getBaseUrl() + menuItem.DPG_ID}
							exact
							activeClassName='active'
							className='header-link-menu'
							style={{ marginTop: isRootItem ? '' : '-5px'}}
						>
							{menuItem.DPG_DEFAULT === '1' && <Icon className='menuIcon' type='home'/>}
							{menuItem.CAT_ID === '0' && <Icon className='menuIcon' type='dot-chart'/>}
							{menuItem.CAT_ID !== '0' && <Icon className='menuIcon' type='deployment-unit'/>}
							{utils.toSubstring(menuItem.AMI_LABEL)}
						</NavLink>
					</Tooltip>
				</Menu.Item>
			)
		);
	}

    render()
	{
		const isMobileVersion = utils.isMobileVersion(window);
		const mode = isMobileVersion ? 'inline' : 'horizontal';
		const onClick = isMobileVersion ? this.props.closeDrawer : () => {};

		const key = this.props.defaultSelectedKeys.length === 0 ? '0' : '1'; // display component with different keys because defaultSelectedKeys may be empty at first and the component doesn't update
		return (
			<Menu 
				key={key}
				mode={mode} 
				inlineCollapsed={false} 
				onClick={onClick}
				defaultSelectedKeys={this.props.defaultSelectedKeys}
			>
				{this.state.menuItems.map(item => {
					return (item.PARENT_ID === '0') && this.recursive_constructMenu(item)
				})}
			</Menu>
		);
	}
}


export default MenuMaker;
