import React from 'react';
import { Icon, Tag, Popover } from 'antd';


const HelpTag = (props) =>
{

	const {
		tagText,
		popoverContent,
		popoverTrigger,
		iconType,
		color,
		popoverPlacement,
		tagStyle,
	} = props;


	return (

		<span>
			<Popover
				content={popoverContent}
				trigger='click'
				placement={popoverPlacement}
			>
				{popoverTrigger ||
				<Tag color={color} style={{ cursor: 'pointer', ...tagStyle }}>
					<Icon type={iconType} className='formPHIcon'/>
						{tagText}
				</Tag>}
			</Popover>
		</span>
	);
};


export default HelpTag;

// import HelpTag from 'Components/Studio/Dashboard/Widgets/WidgetLib/HelpTag/HelpTag';
