import React, { Component } from 'react';
import { Form } from 'reactstrap';
import { Divider, Select } from 'antd';

import DeviceSection from '../../../FormSections/DeviceSection/DeviceSection';
import DatatypeSection from '../../../FormSections/DatatypeSection/DatatypeSection';
import DeviceAttributeSection from 'Components/Studio/Dashboard/Widgets/Configure/FormSections/DeviceAttributeSection/DeviceAttributeSection';
import DatatypeCustomizer from '../../../FormSections/DatatypeCustomizerSection/DatatypeCustomizer';
import DualColorsSection from '../../../FormSections/ColorSections/DualColorsSection/DualColorsSection';

import { request } from 'Lib/request.js';
import { oneDevicePH, oneDatatypePH } from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';
import { categoryDashboardInfo } from 'Components/Studio/StudioLib/GenericHTML/GenericText.js';
import { utils } from 'Lib/utils';

const { Option } = Select;


class DatatypePane extends Component
{

    constructor(props)
	{
        super(props);

        this.state = {
			configs: [],
			attributes: [],
			selectedDevices: [],
			selectedDatatype: undefined,
			selectedAttribute: undefined,
			containerFilter: [],
			categoryFilter: [],
			colors: ['#28a745', '#ff5369'],
			userWantsToCustomizeDatatypes: false,
			filter: undefined,
			pva: undefined,
			displayAllColors: true
        }
    }


    componentDidMount = () =>
	{
        if (this.props.widget.DVC_ID !== undefined)
		{
            this.getConfigsOfDeviceId(this.props.widget.DVC_ID);
			this.getAttribsOfDeviceId(this.props.widget.VAL_ID);
			this.setState({ selectedDevices: this.props.widget.DVC_ID });
        }
		if (!!this.props.widget.DWV_COLORS)
		{
			this.setState({ colors: this.props.widget.DWV_COLORS.split(',') });
		}
		if (this.props.widget.CFG_ID !== undefined && this.props.widget.CFG_ID !== '')
		{
			this.setState({ selectedDatatype: [this.props.widget.CFG_ID] });
		}

		if (this.props.widget.VAL_ID !== undefined && this.props.widget.VAL_ID !== '')
		{
			this.setState({ selectedAttribute: this.props.widget.JWA_TYPE === 'DVC' ? [this.props.widget.VAL_ID] : undefined });
		}
		this.getCurrentDeviceIdFromAttr();

        let currentConfiguration = this.getCurrentConfiguration();
        this.props.saveConfiguration(currentConfiguration);

		if (this.props.widget.JWC)
		{
			Object.keys(this.props.widget.JWC).forEach(key =>
			{
				var value = this.props.widget.JWC[key]

				if (value.JWC_COEFFA !== '1' || value.JWC_COEFFB !== '0' || value.JWC_LABEL !== '' || value.JWC_UNIT !== '' || value.JWC_DEVICE_LABEL !== '')
				{ // datatype has been customized
					if (key !== '') { this.setState({ userWantsToCustomizeDatatypes: true }); }
				}
			});
		}
    }


	componentDidUpdate = (prevProps, prevState) =>
	{
		if (prevState.selectedDatatype !== this.state.selectedDatatype)
		{
			this.props.setAttributePaneIsDisabled(
				this.state.selectedDatatype !== undefined
			); // if nothing selected, enable other pane
		}

		if (prevState.selectedAttribute !== this.state.selectedAttribute)
		{
			this.props.setAttributePaneIsDisabled(
				this.state.selectedAttribute !== undefined
			); // if nothing selected, enable other pane
		}
	}


    handleDeviceChange = (value) =>
	{
		this.setState({
			selectedDevices: value,
			selectedDatatype: undefined,
			selectedAttribute: undefined,
			userWantsToCustomizeDatatypes: false
		});

		this.props.getAttribute();

        let currentConfiguration = this.getCurrentConfiguration();
        currentConfiguration.device_id = value;
		currentConfiguration.config_id = undefined;

		let currentAttribute = this.getCurrentAttribute();
		currentAttribute.value_ids = undefined;
	
		this.props.saveConfiguration(currentConfiguration);
        this.props.saveConfiguration(currentAttribute);
		this.getAttribsOfDeviceId(value);
        this.getConfigsOfDeviceId(value);
    }


    handleConfigChange = (value) =>
	{
		this.setState({ selectedDatatype: value ? [value] : undefined, });
		this.props.getAttribute();
		if(this.state.filter){ this.setFilter(undefined);}

        let currentConfiguration = this.getCurrentConfiguration();
        currentConfiguration.config_id = value;
		this.getAttribsOfDeviceId(this.state.selectedDevices);
        this.getConfigsOfDeviceId(this.state.selectedDevices);
        this.props.saveConfiguration(currentConfiguration);
    }


	handleAttribChange = (value) =>
	{
		this.setState({ selectedAttribute: value ? [value] : undefined, });

		if(this.state.filter){ this.setFilter(undefined);}

        let currentAttribute = this.getCurrentAttribute(value);
		this.getAttribsOfDeviceId(this.state.selectedDevices);
        this.getConfigsOfDeviceId(this.state.selectedDevices);
        this.props.saveConfiguration(currentAttribute);
    }


    getConfigsOfDeviceId = (deviceId) =>
	{
		if (deviceId !== undefined && deviceId !== '')

		{
	        request.get(
	            'config',
	            { device_id: deviceId },
	            (configs) => {
					this.setState({ configs: configs })
				},
	            () => console.error('load configs failed')
	        )
		}
    }


	getAttribsOfDeviceId = (deviceId) =>
	{
		if (deviceId !== undefined && deviceId !== '')
		{
	        request.get(
	            'attribute',
	            { device_id: deviceId },
	            (attributes) => {
					
					this.setState({ attributes: attributes })
					
					attributes.forEach(attribute => {
					if (this.state.selectedAttribute && this.state.selectedAttribute[0] === attribute.id){
						this.props.getAttribute(attribute);
					}
				})
				}
				,
	            () => console.error('load attributes failed')
	        )
		}
    }


	api_getPVA = (valId) => {
		request.get(
			'value',
			{
				value: valId
			},
			(value) => {
				this.api_getDeviceId(value[0].pva);
			},
			() => console.error('load value failed')
		);
	}


	api_getDeviceId = (pvaId) => {
		request.get(
			'device',
			{
				pva_id: pvaId
			},
			(device) => {
				if(device[0]){
					this.setState({selectedDevices : device[0].id}, () => this.getAttribsOfDeviceId(device[0].id));
				}
			},
			() => console.error('load value failed')
		)
	}


	handleCustomizerToggle = (userWantsToCustomizeDatatypes) =>
	{
		this.setState({ userWantsToCustomizeDatatypes: userWantsToCustomizeDatatypes });
    }


    getCurrentConfiguration = () =>
	{
        return {
            type: 'value',
			value_source: 'CFG',
            device_id: this.getCurrentDeviceId(),
            config_id: this.getCurrentConfigId(),
			colors: this.getCurrentColors(),
			datatypeCustomization: this.getCurrentDatatypeCustomization(),
			image: this.props.configuration && this.props.configuration.image
        }
    }


	getCurrentAttribute = (value) =>
	{
        return {
			type: 'value',
			jwa_type: 'DVC',
			value_ids: [value],
			value_source: value && 'ATR',
			image: this.props.configuration && this.props.configuration.image
        }
    }


    getCurrentDeviceId = () =>
	{
        return this.props.configuration.device_id ? // if something is set in configuration, user has changed input values
                this.props.configuration.device_id : // configuration is the source of truth
                (this.props.widget.DVC_ID && !this.state.containerEdited ? // if not check if current widget has device ids, meaning we are editing an existing widget
                    this.props.widget.DVC_ID :
                    undefined
                );
    }


    getCurrentDeviceIdFromAttr = () =>
	{
	const valId = this.props.widget.VAL_ID;
	
			if (valId)
			{
				this.api_getPVA(valId);
			}
	}
	

    getCurrentConfigId = () =>
	{
        return this.props.configuration.config_id ?
            this.props.configuration.config_id :
            (this.props.widget.CFG_ID ?
                this.props.widget.CFG_ID :
                undefined
            );
    }


	getCurrentColors = () =>
	{
		return this.props.configuration.colors ?
            this.props.configuration.colors :
            (this.props.widget.DWV_COLORS ?
                this.props.widget.DWV_COLORS : '#28a745,#ff5369'
            );
	}


	getCurrentDatatypeCustomization = () =>
	{
        return this.state.datatypeCustomization;
    }


	saveCustomizationToState = (datatypeCustomization) =>
	{
		let datatypeCustomizationCopy = JSON.parse(JSON.stringify(datatypeCustomization)); // child state MUST remain immutable, so we have to use a copy
		// JSON stringify then parse is only way to make sure that all nested objects get cloned and below changes are not mutative... ikr, wtf
		datatypeCustomizationCopy.forEach(customizedDatatype =>
		{
			this.state.configs.forEach(config =>
			{
		
				if (config.id === customizedDatatype.key)
				{
					if (customizedDatatype.label === config.label_D1) { customizedDatatype.label = ''; }
					if (customizedDatatype.deviceLabel === config.device.label) { customizedDatatype.deviceLabel = ''; }
					if (customizedDatatype.unit === config.unite_D1) { customizedDatatype.unit = ''; }
				}
			})
		});

		this.setState({ datatypeCustomization: datatypeCustomizationCopy }, () =>
		{
			let currentConfiguration = this.getCurrentConfiguration();
			this.props.saveConfiguration(currentConfiguration);
		});
	}


	handleColorChange = (color, index) =>
	{
	    let colors = [...this.state.colors];
	    let colorToUpdate = {...colors[index]};
	    colorToUpdate = color.color;
	    colors[index] = colorToUpdate;

		this.setColorstoStateandConfig(colors);
    }


	setColorstoStateandConfig = (colors) =>
	{
		this.setState({ colors: colors });

		let currentConfiguration = this.getCurrentConfiguration();
		let colorsStr = '';

		colors.forEach((color) =>
		{
			colorsStr += color + ',';
		})

		colorsStr = colorsStr.slice(0, -1);
		currentConfiguration.colors = colorsStr;

		this.props.saveConfiguration(currentConfiguration);
	}


	getDatatypeSelectOptions = () =>
	{
		const datatypeSelectOptions = [];
		this.state.configs.forEach((datatype) =>
		{
			datatypeSelectOptions.push(
				<Option key={datatype.id} value={datatype.id}>
					{datatype.label_D1} {datatype.unite_D1 && <span>({datatype.unite_D1})</span>}
				</Option>
			);
		});

		return datatypeSelectOptions;
	}

	
	getAttributeSelectOptions = () =>
	{
		const attributesSelectOptions = [];

		this.state.attributes.forEach((attribute) =>
		{
			if(attribute.type !== 'PASSWORD'){
				attributesSelectOptions.push(
					<Option key={attribute.id} value={attribute.id}>
						{attribute.label_D1} {attribute.name}
					</Option>
				);
			}
		});

		return attributesSelectOptions;
	}


	setFilter = (value) => {
		this.setState({filter: value});
	}

	
    render()
	{
		const isBoolean = this.props.widget.DWV_TYPE === 'boolean' || this.props.widget.DWG_TYPE === 'DWG_boolean';
		const canCustomizeDatatypes = this.state.selectedDatatype &&
			this.state.selectedDatatype.length > 0 && this.state.configs && this.state.configs.length > 0;
		const entries = this.props.devices.length;
		
		
        return (

            <Form style={{ marginTop: this.props.marginTop }}>

				{!this.props.isCategoryDashboard &&
				<DeviceSection
					label={utils.translate('generic.dvc')}
					entries={entries}
					deviceSelectPlaceholder={oneDevicePH}
					devices={this.props.devices}
					categories={this.props.categories}
					containers={this.props.containers}
					groups={this.props.groups}
					selectedDevices={this.state.selectedDevices}
					categoryFilter={this.props.categoryFilter}
					containerFilter={this.props.containerFilter}
					groupFilter={this.props.groupFilter}
					loading={this.props.loading}
					disabled={this.props.loading}
					handleDeviceChange={this.handleDeviceChange}
					handleCategoryFilterChange={this.props.handleCategoryFilterChange}
					handleContainerFilterChange={this.props.handleContainerFilterChange}
					handleGroupFilterChange={this.props.handleGroupFilterChange}
					api_getDevices={this.props.api_getDevices}
				/>}

				{this.props.isCategoryDashboard &&
				<div>{categoryDashboardInfo}</div>}

				<Divider/>

				<DatatypeSection
					label={utils.translate('generic.dty')}
					allowClear
					datatypeSelectPlaceholder={oneDatatypePH}
					disabled={this.state.selectedAttribute}
					selectOptions={this.getDatatypeSelectOptions()}
					selectedDatatypes={this.state.selectedDatatype}
					loading={this.props.isCategoryDashboard ? false : this.props.loading}
					datatypeSelectDisabled={this.props.isCategoryDashboard ? false : this.props.loading}
					handleConfigChange={this.handleConfigChange}
					setFilter={this.setFilter}
				/>

				<DeviceAttributeSection
					label={utils.translate('generic.dvcAttr')}
					allowClear
					disabled={this.state.selectedDatatype}
					datatypeSelectPlaceholder={oneDatatypePH}
					selectOptions={this.getAttributeSelectOptions()}
					selectedDatatypes={this.state.selectedAttribute}
					loading={this.props.isCategoryDashboard ? false : this.props.loading}
					datatypeSelectDisabled={this.props.isCategoryDashboard ? false : this.props.loading}
					handleConfigChange={this.handleAttribChange}
					setFilter={this.setFilter}
				/>

				{canCustomizeDatatypes &&
				<DatatypeCustomizer
					label={utils.translate('generic.customizeDty')}
					description={utils.translate('generic.operationInfo')}
					allDatatypes={this.state.configs}
					selectedDatatypes={this.state.selectedDatatype}
					widget={this.props.widget}
					userWantsToCustomizeDatatypes={this.state.userWantsToCustomizeDatatypes}
					handleCustomizerToggle={this.handleCustomizerToggle}
					saveCustomizationToParentState={this.saveCustomizationToState}
				/>}

                {isBoolean &&
				<DualColorsSection
					label={utils.translate('generic.colors')}
					active={1}
					inactive={0}
					colorZero={this.state.colors[0]}
					colorOne={this.state.colors[1]}
					handleColorChange={this.handleColorChange}
					widget={this.props.widget}
					displayAllColors={this.state.displayAllColors}
					enableAlpha={false}
				/>}

            </Form>
        );
    }
}


export default DatatypePane;
