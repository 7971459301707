import React from 'react';
import { Slider, Card, Switch } from 'antd';
import { Row, Col } from 'reactstrap';
import ColorPicker from 'rc-color-picker';
import { utils } from 'Lib/utils';

const patterns = {
	'border-radius': /\/\*<border-radius>\*\/[\s\S]*?px\/\*<border-radius>\*\//g,
	'border': /\/\*<border>\*\/[\s\S]*?px\/\*<border>\*\//g,
	'background-color': /\/\*<background-color>\*\/[\s\S]*?\/\*<background-color>\*\//g,
	'box-shadow': /\/\*<box-shadow>\*\/[\s\S]*?\/\*<box-shadow>\*\//g
}

const ThemeForm = (props) =>
{
    const {
        appTheme
    } = props;


	const handleBorderRadiusChange = (value) => {
		const newValue = appTheme.replace(patterns['border-radius'], '/*<border-radius>*/'+value+'px/*<border-radius>*/');

		props.handleChange({
			preset: undefined,
			appTheme: newValue,
			edited: true
		})
	}

    
	const handleBorderChange = (value) => {
        const newValue = appTheme.replace(patterns['border'], '/*<border>*/'+value+'px/*<border>*/');
        
		props.handleChange({
            preset: undefined,
			appTheme: newValue,
			edited: true
		})
	}

    
	const handleBackgroundColorChange = (value) => {
        let color = utils.convertColorAlphaToHex(value.color, value.alpha);
            
		const newValue = appTheme.replace(patterns['background-color'], '/*<background-color>*/'+color+'/*<background-color>*/');
        
		props.handleChange({
            preset: undefined,
			appTheme: newValue,
			edited: true
		})
        
	}
    
    
	const handleBoxShadowChange = (value) => {
        const boxShadow = value ? '0 3px 7px rgba(0, 0, 0, 0.5), 0 5px 5px rgba(0, 0, 0, 0.2)' : 'none';
        
		const newValue = appTheme.replace(patterns['box-shadow'], '/*<box-shadow>*/' + boxShadow + '/*<box-shadow>*/');
        
		props.handleChange({
            preset: undefined,
			appTheme: newValue,
			edited: true
		});
	}
    

    const parseBorderRadius = () => {
        const matches = appTheme.match(patterns['border-radius']);
        if (matches !== null)
        return parseInt(matches[0].slice(19, -21));
        else 
        return 0;
    }


    const parseBorder = () => {
        const matches = appTheme.match(patterns['border']);
        if (matches !== null)
            return parseInt(matches[0].slice(12, -14));
            return 0;
    }


    const parseBackgroundColor = () => {
        const matches = appTheme.match(patterns['background-color']);
        if (matches !== null) {
            const hex = matches[0].slice(22, -22);
            return utils.convertHexToColorAlpha(hex)
        }
        return {color: '#ffffff', alpha: 100};
    }


	const parseBoxShadow = () => {
        const matches = appTheme.match(patterns['box-shadow']);
		if (matches !== null)
        return matches[0].slice(16, -16) === 'none' ? false : true;
		return false;
	}


    const backgroundColor = parseBackgroundColor();
    const border = parseBorder();
    const borderRadius = parseBorderRadius();
    const boxShadow = parseBoxShadow();
    

    return (
        <Card style={{ width: '100%' }}>
            <Row>
                <Col span={11}>
                    {utils.translate('componentThemeBuilder.border-radius')}
                    <Slider
                        value={borderRadius}
                        onChange={handleBorderRadiusChange}
                        min={0}
                        max={50}
                        marks={{ 0: '0px', 50: '50px' }}
                    />
                </Col>
                <Col span={11} offset={2}>
                    {utils.translate('componentThemeBuilder.border')}
                    <Slider
                        value={border}
                        onChange={handleBorderChange}
                        min={0}
                        max={20}
                        marks={{ 0: '0px', 20: '20px' }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    {utils.translate('componentThemeBuilder.background-color')}&nbsp;
                    <ColorPicker
                        animation='slide-up'
                        defaultColor='#ffffff'
                        color={backgroundColor.color}
                        defaultAlpha={100}
                        alpha={backgroundColor.alpha}
                        style={{ zIndex: 9999 }}
                        onClose={handleBackgroundColorChange}
                    />
                </Col>
                <Col span={11} offset={2}>
                    {utils.translate('componentThemeBuilder.box-shadow')}&nbsp;
                    <Switch
                        checked={boxShadow}
                        onChange={handleBoxShadowChange}
                    />
                </Col>
            </Row>
        </Card>
    );
}

export default ThemeForm;