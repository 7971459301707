import React from 'react';
import { Button, Form } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { utils } from 'Lib/utils';


const AddDeviceButton = (props) =>
{

	return (

		<Form.Item>
			<Button
				type='primary'
				onClick={props.onClick}>
				<FontAwesomeIcon className='form-icon' icon={props.created ? 'check' : 'plus'}/>{props.created ? utils.translate('componentAddDeviceButton.created') : utils.translate('generic.newDvc')}
			</Button>
		</Form.Item>
	)
};


export default AddDeviceButton;
