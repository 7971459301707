import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar, NavbarToggler, Collapse, Nav, NavLink, NavItem } from 'reactstrap';
import { Tooltip, Icon } from 'antd';

import { utils } from 'Lib/utils';
import './Toolbar.css';


class Toolbar extends Component
{

    constructor(props) {

        super(props);

        this.state = {
            isOpen: false,
        }
    }


    toggle = () =>
	{
        this.setState({ isOpen: !this.state.isOpen });
    }


    render() {

        return (
			
            <div>
                <Navbar id="toolBar" expand="md" light className="toolbar-container">
                    <NavbarToggler className="ml-auto">
						<Icon type={this.state.isOpen ? 'menu-fold' : 'menu-unfold'} style={{color: 'white'}} onClick={this.toggle} />
					</NavbarToggler>
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav id="actionIcons" className="ml-auto hidden-xs" navbar>

							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_status')}>
	                            <NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_status')}>
	                                <FontAwesomeIcon icon="power-off" />
	                            </NavItem>
							</Tooltip>

							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_value')}>
								<NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_value')}>
									<FontAwesomeIcon icon="newspaper" />
								</NavItem>
							</Tooltip>

							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_index')}>
								<NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_index')}>
									<FontAwesomeIcon icon="sort-numeric-up" />
								</NavItem>
							</Tooltip>

							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_boolean')}>
								<NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_boolean')}>
	                                <FontAwesomeIcon icon="toggle-on" />
	                            </NavItem>
							</Tooltip>

							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_gauge')}>
	                            <NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_gauge')}>
	                                <FontAwesomeIcon icon="tachometer-alt" />
	                            </NavItem>
							</Tooltip>

							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_table')}>
								<NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_table')}>
	                                <FontAwesomeIcon icon="table" />
	                            </NavItem>
							</Tooltip>

							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_command')}>
								<NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_command')}>
	                                <FontAwesomeIcon icon="cloud-upload-alt" />
	                            </NavItem>
							</Tooltip>

							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_piechart')}>
	                            <NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_piechart')}>
	                                <FontAwesomeIcon icon="chart-pie" />
	                            </NavItem>
							</Tooltip>

							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_areachart')}>
	                            <NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_areachart')}>
	                                <FontAwesomeIcon icon="chart-area" />
	                            </NavItem>
							</Tooltip>

							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_columnchart')}>
								<NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_columnchart')}>
	                                <FontAwesomeIcon icon="chart-bar" />
	                            </NavItem>
							</Tooltip>

							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_html')}>
								<NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_html')}>
									<FontAwesomeIcon icon={['fab', 'html5']} />
								</NavItem>
							</Tooltip>

							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_map')}>
								<NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_map')}>
									<FontAwesomeIcon icon="map-marked" />
								</NavItem>
							</Tooltip>

							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_sitemap')}>
								<NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_sitemap')}>
	                                <FontAwesomeIcon icon="industry" />
	                            </NavItem>
							</Tooltip>
							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_scheduler')}>
								<NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_scheduler')}>
	                                <FontAwesomeIcon icon="calendar" />
	                            </NavItem>
							</Tooltip>

							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_alarmrule')}>
								<NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_alarmRule')}>
	                                <FontAwesomeIcon icon="bell" />
	                            </NavItem>
							</Tooltip>
							<Tooltip placement="bottom" autoAdjustOverflow="true" title={utils.translate('widgetDescription.DWG_camera')}>
								<NavItem className="toolbar-item" onClick={() => this.props.addWidget('DWG_camera')}>
	                                <FontAwesomeIcon icon="camera" />
	                            </NavItem>
							</Tooltip>

                        </Nav>

                        <Nav className="ml-auto visible-xs" navbar style={{textAlign: 'center'}}>
                            <NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_status')}>
                                <NavLink><FontAwesomeIcon icon="power-off" color='white'/></NavLink>
								<div className="toolbar-separator-mobile"></div>
                            </NavItem>
							<NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_value')}>
								<NavLink><FontAwesomeIcon icon="newspaper" color='white'/></NavLink>
								<div className="toolbar-separator-mobile"></div>
							</NavItem>
							<NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_index')}>
								<NavLink><FontAwesomeIcon icon="sort-numeric-up" color='white'/></NavLink>
								<div className="toolbar-separator-mobile"></div>
							</NavItem>
							<NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_boolean')}>
                                <NavLink><FontAwesomeIcon icon="toggle-on" color='white'/></NavLink>
								<div className="toolbar-separator-mobile"></div>
                            </NavItem>
                            <NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_gauge')}>
                                <NavLink><FontAwesomeIcon icon="tachometer-alt" color='white'/></NavLink>
								<div className="toolbar-separator-mobile"></div>
                            </NavItem>
							<NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_table')}>
                                <NavLink><FontAwesomeIcon icon="table" color='white'/></NavLink>
								<div className="toolbar-separator-mobile"></div>
                            </NavItem>
							<NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_command')}>
                                <NavLink><FontAwesomeIcon icon="cloud-upload-alt" color='white'/></NavLink>
                                <div className="toolbar-separator-mobile"></div>
                            </NavItem>
                            <NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_piechart')}>
                                <NavLink><FontAwesomeIcon icon="chart-pie" color='white'/></NavLink>
                                <div className="toolbar-separator-mobile"></div>
                            </NavItem>
                            <NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_areachart')}>
                                <NavLink><FontAwesomeIcon icon="chart-area" color='white'/></NavLink>
                                <div className="toolbar-separator-mobile"></div>
                            </NavItem>
							<NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_columnchart')}>
                                <NavLink><FontAwesomeIcon icon="chart-bar" color='white'/></NavLink>
                                <div className="toolbar-separator-mobile"></div>
                            </NavItem>
							<NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_html')}>
								<NavLink><FontAwesomeIcon icon={['fab', 'html5']} color='white'/></NavLink>
								<div className="toolbar-separator-mobile"></div>
							</NavItem>
							<NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_map')}>
								<NavLink><FontAwesomeIcon icon="map-marked" color='white'/></NavLink>
								<div className="toolbar-separator-mobile"></div>
							</NavItem>
							<NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_sitemap')}>
                                <NavLink><FontAwesomeIcon icon="industry" color='white'/></NavLink>
                            </NavItem>
							<NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_alarmRule')}>
                                <NavLink><FontAwesomeIcon icon="bell" color='white'/></NavLink>
                            </NavItem>
							<NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_camera')}>
                                <NavLink><FontAwesomeIcon icon="bell" color='white'/></NavLink>
                            </NavItem>
							<NavItem className="toolbar-item-mobile" onClick={() => this.props.addWidget('DWG_scheduler')}>
                                <NavLink><FontAwesomeIcon icon="calendar" color='white'/></NavLink>
							</NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}


export default Toolbar;
