import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Select } from 'antd';

import SuperSelect from 'Components/Studio/StudioLib/SuperSelect/SuperSelect';
import LabelItem from '../../FormItems/LabelItem/LabelItem';
import { utils } from 'Lib/utils';

const { Option } = Select;


const AttributeSection = (props) =>
{

	const {
		label,
		iconType,
		selectedSourceObject,
		selectedValueId,
		handleAttributeChange,
		attributeSelectMode,
		attributeSelectPlaceholder,
		loading,
	} = props;


	const attributeOptionIsDisabled = (attribute) =>
	{
		return props.onlyAllowNumericAttributes &&
			attribute && isNaN(attribute.value);
	}


	return (

		<>
			<FormGroup>
				<Row
					className='align-items-center'
				>
					<LabelItem
						label={label}
						iconType={iconType}
					/>
					<Col md={{size: 9}}>
						<SuperSelect
							value={selectedValueId}
							onChange={handleAttributeChange}
							mode={attributeSelectMode}
							placeholder={attributeSelectPlaceholder}
							optionFilterProp='children'
							loading={loading}
							disabled={loading}
							showSearch
							allowClear
							showArrow
							style={{ width: '100%' }}
						>
						{selectedSourceObject && selectedSourceObject.attribs &&
						selectedSourceObject.attribs.map((attribute) =>
							<Option
								key={attribute.value_id}
								value={attribute.value_id}
								disabled={attributeOptionIsDisabled(attribute)}
								title={
									attributeOptionIsDisabled(attribute) &&
									utils.translate('componentAttributeSection.attribute')
								}
							>
								{attribute.label}
							</Option>
						)}
						</SuperSelect>
					</Col>
				</Row>
			</FormGroup>
		</>
	);
};


export default AttributeSection;
