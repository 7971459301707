import React, { useEffect, useState, useRef } from 'react';
import { Table, Input, Button, Icon } from 'antd';

import IconPicker from '../../../../WidgetLib/IconPicker/IconPicker';
import { utils } from 'Lib/utils';
import i18n from "Config/i18nConfig";


const IconCustomizerTable = ({ iconCustomization, selectedDeviceObjects, globalIcon, setIconToParent, setIconTableDataToParent }) =>
{
	
	const [columns, setColumns] = useState([]);
	const [dataSource, setDataSource] = useState([]);
	const searchInputRefs = useRef({});
	
	
	useEffect(() =>
		{
			setColumns(getColumns());
		},
		[]
	);
	
	
	useEffect(() =>
		{
			setDataSource(getDataSource());
		},
		[selectedDeviceObjects]
	);
	
	
	useEffect(() =>
		{
			setDataSource(getDataSource());
		},
		[globalIcon, iconCustomization]
	);
	
	
	const getColumns = () =>
	{
		return ([
			{
				title: utils.translate('generic.labelDvc'),
				dataIndex: 'device',
				key: 'device',
				...getColumnSearchProps('device', 'Devices')
			},
			{
				title: utils.translate('componentWidgetTable.cat'),
				dataIndex: 'category',
				key: 'category',
				...getColumnSearchProps('category', 'Categories')
			},
			{
				title: utils.translate('componentWidgetTable.icon'),
				dataIndex: 'icon',
				key: 'icon',
				render: (icon, record) => getIconPicker(icon, record),
				...getColumnSearchProps('icon', 'Icons')
			},
		]);
	};
	
	
	const getDataSource = () =>
	{
		return selectedDeviceObjects.map(device =>
		{
			const custoItem = iconCustomization.filter(custo => custo.device === device.id)[0];
			return {
				key: device.id,
				device: device.label,
				category: device.category.label,
				icon: custoItem.icon,
			};
		});
	};
	
	
	const applyGlobalIconToCurrentData = (pagination, filters, sorter, extra) =>
	{
		const filtered = extra.currentDataSource.length !== selectedDeviceObjects.length;
		const currentDataSource = extra.currentDataSource || [];
		
		setIconTableDataToParent(currentDataSource, filtered); // sending table rows after filter / reset
	}

	
	const getIconPicker = (icon, record) =>
	{
		return (
			<IconPicker
				initialIcon={icon}
				setIconToParent={setIconToParent}
				device={record.key}
			/>
		);
	};
	
	
	const getColumnSearchProps = (dataIndex, columnName) =>
	{
		return {
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
				<div style={{ padding: 15 }}>
					<Input
						ref={node => (searchInputRefs.current[dataIndex] = node)}
						placeholder={i18n.t('componentWidgetTable.search2') + ' ' + columnName}
						value={selectedKeys[0]}
						onPressEnter={confirm}
						onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
						style={{ width: 188, marginBottom: 8, display: 'block' }}
					/>
					<Button
						type='primary'
						onClick={confirm}
						icon='search'
						size='small'
						style={{ width: 90, marginRight: 8 }}
	        		>
	          			{utils.translate('componentWidgetTable.search2')}
	        		</Button>
	        		<Button onClick={clearFilters} size='small' style={{ width: 90 }}>
						{utils.translate('componentWidgetTable.reset')}
	        		</Button>
				</div>
			),
			filterIcon: filtered => (
				<Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />
			),
			onFilter: (value, record) =>
				record[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
			onFilterDropdownVisibleChange: (visible) =>
			{
				if (visible) { setTimeout(() => searchInputRefs.current[dataIndex].focus()); }
			},
		}
	};

	
	return (
		
		<Table
			columns={columns}
			dataSource={dataSource}
			pagination={{ pageSize: 3 }}
			onChange={applyGlobalIconToCurrentData}
		/>
	);
};


export default IconCustomizerTable;