import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Select } from 'antd';

import SuperSelect from 'Components/Studio/StudioLib/SuperSelect/SuperSelect';
import LabelItem from '../../FormItems/LabelItem/LabelItem';

const { Option } = Select;


const CategorySection = (props) =>
{

	const {
		iconType,
		label,
		selectedCategoryIds,
		categorySelectMode,
		categorySelectPlaceholder,
		handleCategoryChange,
		categories,
		loading,
	} = props;


	return (

		<>
			<FormGroup>
				<Row
					className='align-items-center'
				>
					<LabelItem
						iconType={iconType}
						label={label}
					/>
					<Col md={{size: 9}}>
						<SuperSelect
							value={selectedCategoryIds}
							onChange={handleCategoryChange}
							allowClear
							showSearch
							showArrow
							optionFilterProp='children'
							mode={categorySelectMode}
							style={{ width: '100%' }}
							placeholder={categorySelectPlaceholder}
							loading={loading}
							maxTagCount={20}
							disabled={loading}
							filterOption={(input, option) =>
								option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						>
						{categories && categories.map((category) =>
							<Option
								key={category.id}
								value={category.id}
							>
								{category.label}
							</Option>
						)}
						</SuperSelect>
					</Col>
				</Row>
			</FormGroup>
		</>
	)
};


export default CategorySection;
