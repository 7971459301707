import { request } from './request.js';


export const auth = {

    isAuthenticated: (sessionStorage.getItem('token') !== null && sessionStorage.getItem('token') !== '' ? true : false),

    authenticate(username, password, cb_success, cb_fail) {

		request.post(
            'token',
            {
				login: username,
				password: password
			},
            (successContent) => {

				sessionStorage.setItem('token', successContent[0].token);
                this.isAuthenticated = true;
                cb_success();
            },
            (errorContent) => {

				if (errorContent.error_number === 139) {
					cb_fail();
				}

				return;
			}
        );
    },

    signout(cb) {

        sessionStorage.removeItem('token');
        this.isAuthenticated = false;
        cb();
    },

	isUserAuthenticated(query) {

		let userIsAuthenticated = false;
		let firstSplit = query.split('token=')[1]; // two splits necessary in case of several urlparams
		let queryToken = firstSplit ? firstSplit.split('&')[0] : null;

		if (queryToken !== null && queryToken !== '' && queryToken !== undefined) {

			sessionStorage.setItem('token', queryToken);
			this.isAuthenticated = true;
			userIsAuthenticated = true;
		}

		if (sessionStorage.getItem('token') !== null && sessionStorage.getItem('token') !== '') {
			userIsAuthenticated = true;
		}

		return userIsAuthenticated;
	}
};
