import React from 'react';
import { Row, Col } from 'antd';

import ValueCard from './ValueCard/ValueCard';
import NoData from '../../../NoData/NoData';
import { utils } from 'Lib/utils';


const AlarmPane = ({ device }) =>
{	
	return device.active_alarms && device.active_alarms.length > 0 ?
	(
		<Row type='flex'>
			{device.active_alarms.map(alarm =>
				<Col
					xl={12}
					lg={24}
					md={24}
					sm={24}
					key={alarm.alr_id}
					style={{ padding: 12 }}
				>
					<ValueCard
						key={alarm.alr_id}
						alarm={alarm}
					/>
				</Col>
			)}
		</Row>
	) : (
		<NoData
			marginTop={50}
			description={utils.translate('componentAlarmPane.noAlarms')}
		/>
	);
};


export default AlarmPane;
