import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';

import LabelItem from '../../../FormItems/LabelItem/LabelItem';
import ColorPickerItem from '../../../FormItems/ColorPickerItem/ColorPickerItem';
import { utils } from 'Lib/utils';


const GaugeColorsSection = (props) =>
{

	return (

		<FormGroup>
			<Row>
				<LabelItem
					iconType='bg-colors'
					label={utils.translate('componentGaugeColorsSection.gradient')}
				/>
				<Col md={{size: 3}}>
					<ColorPickerItem
						label='10%'
						color={props.firstColor}
						onClose={props.handleColor1Change}
						enableAlpha={false}
					/>
				</Col>
				<Col md={{size: 3}}>
					<ColorPickerItem
						label='50%'
						color={props.secondColor}
						onClose={props.handleColor2Change}
						enableAlpha={false}
					/>
				</Col>
				<Col md={{size: 3}}>
					<ColorPickerItem
						label='90%'
						color={props.thirdColor}
						onClose={props.handleColor3Change}
						enableAlpha={false}
					/>
				</Col>
			</Row>
		</FormGroup>
	)
};


export default GaugeColorsSection;
