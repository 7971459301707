import React from 'react';

import IconCustomizerTable from './IconCustomizerTable/IconCustomizerTable';


const IconCustomizerSection = (props) =>
{
	return (

		<>
			{props.userWantsToCustomizeDevices &&
			<IconCustomizerTable
				{...props}
			/>}
		</>
	);
};


export default IconCustomizerSection;
