import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import { NoDataToDisplay } from 'react-highcharts-no-data-to-display';
import moment from 'moment';
import 'moment/locale/fr';
import hexToRgba from 'hex-to-rgba';

import EmptyWidget from '../../WidgetLib/EmptyWidget/EmptyWidget';
import DataExporter from './DataExporter/DataExporter';
import ZoomResetButton from './ZoomResetButton/ZoomResetButton';
import GroupByControl from './GroupByControl/GroupByControl';
import DateSelected from './DateSelected/DateSelected';
import Loader from '../../WidgetLib/Loader/Loader';
import NoData from '../../WidgetLib/NoData/NoData';

import { getDefaultOptions } from './Config/DefaultOptions';

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils.js';
import { chartColors } from 'Components/Studio/StudioLib/GenericHTML/ChartColors.js';

import './Chart.css';

NoDataToDisplay ( ReactHighcharts.Highcharts );


class Chart extends Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			data: [],
			config: {},
			dateStart: undefined,
			dateStop: undefined,
			loading: false,
			grouping: props.widget.DWC_GROUPING ?
				props.widget.DWC_GROUPING : "none",
			options: getDefaultOptions(
				props.widget,
				this.afterSetExtremes,
			),
			allDatatypeIds: [],
			zoomActive: false,
			dateFormat: '%A, %b %e %Y - %H:%M',
			slideDate: this.props.widget.DWC_HAS_SLIDING_DATE
		};

		this.timerId = null;
		this.componentIsMounted = false;
	}


	async componentDidMount()
	{
		this.componentIsMounted = true;
		this.getLanguage();
		if (!this.props.widget.CFG_IDS &&
			!this.props.widget.VAL_IDS) { return; }

		this.timerId = utils.setRefresh(
			this.props.widget.DWG_REFRESH_FREQ,
			this.updateGraph
		);

		await this.setDateRangeToState();
		this.updateGraph();
	}


	async componentDidUpdate(prevProps)
	{
		if (
			prevProps.widget.DWC_HAS_STACKING !== this.props.widget.DWC_HAS_STACKING || 
			prevProps.widget.DWC_HAS_MULTI_AXIS !== this.props.widget.DWC_HAS_MULTI_AXIS || 
			prevProps.widget.CFG_IDS !== this.props.widget.CFG_IDS ||
			JSON.stringify(prevProps.widget.VAL_IDS) !== JSON.stringify(this.props.widget.VAL_IDS) ||
			prevProps.widget.DWC_DATE_START !== this.props.widget.DWC_DATE_START ||
			prevProps.widget.DWC_DATE_STOP !== this.props.widget.DWC_DATE_STOP ||
			prevProps.widget.DWC_DATE_RANGE !== this.props.widget.DWC_DATE_RANGE ||
			JSON.stringify(prevProps.widget.JWC) !== JSON.stringify(this.props.widget.JWC) ||
			prevProps.widget.DWC_TYPE !== this.props.widget.DWC_TYPE ||
			prevProps.widget.DWC_GROUPING !== this.props.widget.DWC_GROUPING
		) {
			await this.setDateRangeToState();

			if (prevProps.widget.DWC_GROUPING !== this.props.widget.DWC_GROUPING)
			{
				this.setState(
					{ grouping: this.props.widget.DWC_GROUPING },
					() => this.updateGraph()
				);
			}

			else this.updateGraph();
		}

		if (utils.didWidgetResize(prevProps, this.props) && this.refs.chart)
			this.refs.chart.getChart().reflow(); // adjusts chart to container
	}


	componentWillUnmount()
	{
		this.componentIsMounted = false;
		if (this.timerId !== null) { utils.clearRefresh(this.timerId); }
	}


	api_handleGroupingChange = (event) =>
	{ // only for columnchart
		this.setState({
			grouping: event.target.value,
		}, () => {
			this.updateGraph();
		});

		request.patch(
			'widget/' + this.props.widget.DWG_ID,
			{
				type: 'columnchart',
				grouping: event.target.value
			},
			(success) => { },
			(error) => console.error(error)
		);
	}

	setDateStart = (start) => {
		this.setState({
			dateStart: moment(start).format('YYYY-MM-DD HH:mm:ss'),
			zoomActive: true,
		});
	}

	setDateStop = (end) => {
		this.setState({
			dateStop: moment(end).format('YYYY-MM-DD HH:mm:ss'),
			zoomActive: true,
		});
	}

	getAllData = () => {
		this.setState({
			dateStart: moment("1900-01-01 00:00:00").format('YYYY-MM-DD HH:mm:ss'),
			dateStop: moment("3000-01-01 00:00:00").format('YYYY-MM-DD HH:mm:ss'),
			zoomActive: true,
		}, () => {
			this.updateGraph();
		});
} 

	setDateRangeToState = () =>
	{
		const dateRange = utils.getDatesFromPreset(this.props.widget, 'DWC');

		this.setState({
			zoomActive: false,
			dateStart: dateRange.startTime,
			dateStop: dateRange.endTime
		});
	}


	afterSetExtremes = (event) =>
	{
		// <CS-330> -1 and +1 second to timestamps to prevent data loss on interval's bounds
		var min = moment.utc(event.min / 1000 - 1, 'X')
			.format('YYYY-MM-DD HH:mm:ss');
		var max = moment.utc(event.max / 1000 + 1, 'X')
			.format('YYYY-MM-DD HH:mm:ss');

		this.setState({
			dateStart: min,
			dateStop: max,
			zoomActive: true,
		}, () =>
		{
			this.updateGraph();
		});
	}


	onClickReset = async () =>
	{
		await this.setDateRangeToState();

		this.setState({ zoomActive: false });
		this.updateGraph();
	}


	updateGraph = () =>
	{
		this.setState({
			options: getDefaultOptions(
				this.props.widget,
				this.afterSetExtremes,
			),
		}, () =>
		{
			this.buildDatatypeSeries();
			this.buildAttributeSeries();
		});
	}


	buildDatatypeSeries = () =>
	{
		let stateData = [];
		let stateConfig = [];
		let dataIndex = 0;
		let configIndex = 0;
		let configIdsArr = this.props.widget.CFG_IDS.split(',');
		const groupingToHours = {
			'none': 'none',
			'hour': 'hour',
			'day': 'day',
			'week': 'week',
			'month': 'month',
			'year': 'year'
		}

		this.setState({ loading: true }, () =>
		{
			configIdsArr.forEach((configId, i) =>
			{
				if (configId && configId !== 'none')
				{
					request.get(
						'data/' + configId,
						{
							max_data: 300,
							date_start: this.state.dateStart,
							date_stop: this.state.dateStop,
							extrema: true,
							timeseries: 'get',
							choice_display: 'graph',
							cumul: groupingToHours[this.state.grouping],
							convertToAgtTimeZone: 'true',
						},
						(data) =>
						{
							stateData['configId_' + configId] = data;
						},
						() => {},
						() =>
						{
							dataIndex++;
							// only render when all datas loaded
							if (dataIndex === configIdsArr.length &&
								this.componentIsMounted)
							{
								this.setState({
									data: stateData,
									loading: false
								}, () =>
								{
									if (configIndex === configIdsArr.length)
									{
										this.setAreachartOptions();
									}
								});
							}
						}
					);

					request.get(
						'config/' + configId,
						{},
						(config) =>
						{
							stateConfig['configId_' + configId] = config;
						},
						() => console.error('load config failed'),
						() =>
						{
							configIndex++;

							if (configIndex === configIdsArr.length &&
								this.componentIsMounted)
							{
								this.setState({
									config: stateConfig,
									allDatatypeIds: configIdsArr
								}, () =>
								{
									if (dataIndex === configIdsArr.length)
									{
										this.setAreachartOptions();
									}
								});
							}
						}
					);
				}
			});
		});
	}


	setAreachartOptions = () =>
	{
		const { widget } = this.props;
		const unorderedConfigs = this.state.config;
		const orderedConfigs = {};
		let yAxisCount = -1;

		Object.keys(unorderedConfigs).sort().forEach(function (key) {
			orderedConfigs[key] = unorderedConfigs[key];
		});

		let series = Object.keys(orderedConfigs).map((key, index) =>
		{ // looping through state-stored datatypes
			const datatype = this.state.config[key][key.replace('configId_', '')];
			let configId = datatype.id;
			let datas = this.state.data[key]; // getting datatype data
			let configUnit = widget.JWC[configId] && widget.JWC[configId].JWC_UNIT !== '' ? ' (' + widget.JWC[configId].JWC_UNIT + ')' : datatype.unite_D1 ? ' (' + datatype.unite_D1 + ')' : '';
			let configLabel = widget.JWC[configId] && widget.JWC[configId].JWC_LABEL !== '' ? widget.JWC[configId].JWC_LABEL : datatype.label_D1;
			let colors = widget.JWC[configId] && widget.JWC[configId].JWC_COLORS !== '' ? widget.JWC[configId].JWC_COLORS.split(',') : ['#fafafa', '#dddf0d', '#ff5369'];
			let lowThres = widget.JWC[configId] && widget.JWC[configId].JWC_LOW_THRES !== '' ? widget.JWC[configId].JWC_LOW_THRES : '';
			let highThres = widget.JWC[configId] && widget.JWC[configId].JWC_HIGH_THRES !== '' ? widget.JWC[configId].JWC_HIGH_THRES : '';

			configLabel = utils.getValueFromTags(configLabel, datatype.device);
			yAxisCount = yAxisCount + 1;
			if (!datas)
			{
				return {
					id: 'CFG_' + configId,
					name: datatype.device.label + ' - ' + configLabel + configUnit,
					unit: configUnit,
					data: [],
					showInLegend: false,
				};
			}
			

			delete datas['_links'];

			return {
				id: 'CFG_' + configId,
				name: datatype.device.label + ' - ' + configLabel + configUnit,
				unit: configUnit,
				yAxis: widget.DWC_HAS_MULTI_AXIS === '1' ? yAxisCount : 0,
				data: Object.keys(datas).map(i =>
				{
					let coeffA = widget.JWC[configId] ? widget.JWC[configId].JWC_COEFFA : 1;
					let coeffB = widget.JWC[configId] ? widget.JWC[configId].JWC_COEFFB : 0;
					let value = parseFloat(coeffA) * datas[i].graph.data + parseFloat(coeffB);

					return [
						this.getTimeStamp(datas[i].graph.date_creation),	
						value
					];
				}),
				// Display default color or custom if changed
				color: colors[0] !== '#fafafa' ? hexToRgba(colors[0], 0.4) : chartColors[index].replace('0.6', '0.4'),
				zones: [{
					value: lowThres ? lowThres : -Infinity,
					color: lowThres !== -Infinity ?
						colors[1] !== '#dddf0d' ? hexToRgba(colors[1], 0.4)
							: colors[0] !== '#fafafa' ? hexToRgba(colors[0], 0.4) : chartColors[index].replace('0.6', '0.4')
						:
						colors[0] !== '#fafafa' ? hexToRgba(colors[0], 0.4) : chartColors[index].replace('0.6', '0.4')
				}, {
					value: highThres !== '' ? highThres : '',
					color: colors[0] !== '#fafafa' ? hexToRgba(colors[0], 0.4) : chartColors[index].replace('0.6', '0.4')
				}, {
					color: colors[2] !== '#ff5369' ? hexToRgba(colors[2], 0.4) : colors[0] !== '#fafafa' ?
						hexToRgba(colors[0], 0.4) : chartColors[index].replace('0.6', '0.4')
				}]
			}
		});

		let stateSeries = [];
		let yAxis = [];
		let opposite = true;

		if (this.state.options.series) // undefined || array
		{
			stateSeries = [...this.state.options.series];
		}
		series && series.forEach((seriesToPush) =>
		{
			stateSeries = stateSeries.concat(seriesToPush);
			
			if(widget.DWC_HAS_MULTI_AXIS === '1' && seriesToPush.length !== 0){
				yAxis = yAxis.concat({
					gridLineWidth: 0,
					title: {
						text: seriesToPush.name,
						style: {
							color: seriesToPush.color ? seriesToPush.color.replace('0.4', '1') : '',
						}
					},
					labels: {
						formatter: function() {
							if (this.value >= 1E6 || this.value <= -1E6) {
								return (this.value / 1000000).toFixed(2) + 'M';
							} else if (this.value >= 1E3 || this.value <= -1E3){
								return this.value / 1000 + 'k';
							} else {
								return this.value;
							}
					  	},
						format: '{value}' + seriesToPush.unit,
						style: {
							color: seriesToPush.color ? seriesToPush.color.replace('0.4', '1') : '',
						}
					},
					opposite: opposite,
				})
				opposite = !opposite;
			} else {
				yAxis= [{title:false}]
			}
		});

		this.setState(prevState => ({
			options: {
				...prevState.options,
				series: stateSeries,
				tooltip: {
					pointFormat: this.getPointFormat(),
					useHTML:true,
					headerFormat: '<div style="text-align: center;font-weight: bold;color:#686868;">{series.name}</div>',
				},
				yAxis: widget.DWC_HAS_MULTI_AXIS === '1' ? yAxis : [{title:false}],
			}
		}));
	}


	buildAttributeSeries = () =>
	{
		const { widget } = this.props;
		const valIds = widget.VAL_IDS;

		if (valIds)
		{
			let dataSeries = [];
			let index = 0; // using custom counter
			// built-in forEach index randomly not correct (due to callback ?)
			this.setState({ loading: true }, () =>
			{
				valIds.forEach((valueId) =>
				{
					if (valueId)
					{
						
						request.get(
							'attributedata/' + valueId,
							{
								date_start: this.state.dateStart,
								date_stop: this.state.dateStop,
							},
							(data) =>
							{
								dataSeries.push(data);
							},
							(e) => console.error(e),
							() =>
							{
								index++;

								if (index === valIds.length)
								{
									this.createAttributeSeries(dataSeries);
									this.setState({ loading: false });
								}
							}
						);
						
					}
				});
			});
		}
	}

	createAttributeSeries = (dataSeries) =>
	{
		if (dataSeries)
		{
			let series = dataSeries.map((series, index) =>
			{
				if (series.attribute && series.data)
				{
					return {
						id: 'ATR_' + series.attribute.id,
						name: series.attribute.label,
						data: series.data.map((dataPoint) =>
						{
							const value = isNaN(dataPoint.vlo_value) ?
								undefined : Number(dataPoint.vlo_value);

							return [
								this.getTimeStamp(dataPoint.vlo_date),
								value,
							];
						}),
						color: this.getRandomRgba(),
					}
				}

				return {};
			});

			let stateSeries = [];

			if (this.state.options.series) // undefined || array
				{ stateSeries = [...this.state.options.series]; }

			series && series.forEach((seriesToPush) =>
				{ stateSeries = stateSeries.concat(seriesToPush); });

			this.setState(prevState => ({
				options: {
					...prevState.options,
					series: stateSeries,
					tooltip: {
					pointFormat: this.getPointFormat(),
					useHTML:true,
					headerFormat: '<div style="text-align: center;font-weight: bold;color:#686868;">{series.name}</div>',
				}
				}
			}));
		}
	}


	getTimeStamp = (date) =>
	{
		if (date)
		{
			let datetimeSplit = date.split(' ');
			let dateSplit = datetimeSplit[0].split('-');
			let timeSplit = datetimeSplit[1].split(':');

			return Date.UTC(
				dateSplit[0], dateSplit[1] - 1,
				dateSplit[2], timeSplit[0],
				timeSplit[1], timeSplit[2]
			); // -1 because months range from 0 to 11 in js
		}
	}


	getRandomRgba = () =>
	{
		const num = Math.round(0xffffff * Math.random());
		const r = num >> 16;
		const g = (num >> 8) & 255;
		const b = num & 255;

		return 'rgba(' + r + ', ' + g + ', ' + b + ', 0.4)';
	}


	getPointFormat = () =>
	{
		return this.state.grouping === "month" ?
			'<div style="text-align: center;font-weight: bolder">{point.y:.2f}</div><div style="text-align: center;font-style: italic;">{point.x: %b %Y}</div>'
		:
		(this.state.grouping === "year" ?
		'<div style="text-align: center;font-weight: bolder">{point.y:.2f}</div><div style="text-align: center;font-style: italic;">{point.x: %Y}</div>'
		:
		'<div style="text-align: center;font-weight: bolder">{point.y:.2f}</div><div style="text-align: center;font-style: italic;">{point.x: ' + this.state.dateFormat + ' }</div>');
	}

	getLanguage = () =>
	{
		if(utils.getCurrentLanguage() === 'fr')
		{
			moment.locale('fr')
			ReactHighcharts.Highcharts.setOptions({
				  lang: {
					months: moment.months(),
					weekdays: moment.weekdays(),
					shortMonths: moment.monthsShort()
				 }
			})
			this.setState({dateFormat: '%A %e %b %Y - %H:%M'})
		}
	}

	handleDatesChange = () =>
	{
		
    }


	render()
	{
		const { widget, size } = this.props;

		const widgetHasCfgIds = widget.CFG_IDS !== undefined && widget.CFG_IDS !== '';
		const widgetHasValIds = widget.VAL_IDS !== undefined && widget.VAL_IDS[0];

		const showGraph = widgetHasCfgIds || widgetHasValIds;

		return (

			<>
				{utils.isConfigMode() && !showGraph &&
				<EmptyWidget
					{...this.props}
					callToAction={utils.translate('componentWidgetChart.start')}
				/>}

				{this.state.loading && widget.CFG_IDS && widget.CFG_IDS !== 'none' &&
				<Loader style={{ marginTop: `${size.height / 2}px` }}/>}

				{!this.state.loading && showGraph &&
				<>
					{widget.DWC_TYPE === 'column' && widget.CFG_IDS
						&& !widgetHasValIds && widget.DWC_ALLOWED_GROUPING_OPTS &&
					<GroupByControl
						{...this.props}
						value={this.state.grouping}
						onChange={this.api_handleGroupingChange}
					/>}
					{widget.DWC_HAS_SLIDING_DATE === '1' && widget.CFG_IDS
						&& !widgetHasValIds && widget.DWC_ALLOWED_GROUPING_OPTS &&
					<DateSelected
					{...this.props}
					{...this.state}
					setDateStart={this.setDateStart}
					setDateStop={this.setDateStop}
					getAllData={this.getAllData}
					updateGraph={this.updateGraph}
					/>}
					<div 
						id={this.props.getWidgetId + '_CONTENT'}
						className={'highcharts-wrapper NoDrag CONTENT' + this.props.getWidgetClass + this.props.getWidgetClass + '_CONTENT'} 
						style={{ height: `${size.height}px`, paddingBottom: '20px' }}>
						{this.state.zoomActive &&
						<ZoomResetButton
							onClickReset={this.onClickReset}
						/>}
						<ReactHighcharts
							isPureConfig
							config={this.state.options}
							ref='chart'
						/>
						{widget.DWC_HAS_EXPORT === '1' && widget.CFG_IDS && !this.state.loading
						&& this.state.config && Object.keys(this.state.config).length > 0 &&
						<DataExporter
							configs={this.state.config}
							allDatatypeIds={this.state.allDatatypeIds}
							dateStart={this.state.dateStart}
							dateStop={this.state.dateStop}
							widget={widget}
						/>}
					</div>
				</>}
				{widget.CFG_IDS === 'none' &&
				<>
					<NoData></NoData>
				</>}
			</>
		);
	}
}


export default Chart;
