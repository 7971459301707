import React, { Component } from 'react';
import { Table, Tooltip, Button, Tag, Icon, Spin, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ControlBar from '../ControlBar/ControlBar';
import EditForm from '../../Form/DeviceForm/EditForm';
import CreateForm from '../../Form/DeviceForm/CreateForm';

import { request } from 'Lib/request';
import { utils } from 'Lib/utils.js';

import 'antd/dist/antd.css';
//import './StatusTable.css';


class StatusTable extends Component
{
	
	constructor(props)
	{

		super(props);

		this.state = {
			updated: {},
			created: false,
			devices: [],
			loading: true,
			editModalIsOpen: false,
			createModalIsOpen: false,
			deviceToEdit: undefined,
			columnsToDisplay: [],
			allAvailableDatatypes: [],
			allAvailableAttributes: [],
			size: this.props.widget.DWT_ROW_SIZE,
			searchedValue: '',
			updateColumnOrderIsOpen: false
	
		};

		this.allColumns = ['device', 'id', 'category', 'container', 'device_status', 'active_alarms', 'resolved_alarms', 'actions', 'allAttributes'];
	}

	static getDerivedStateFromProps(nextProps, prevState)
	{
		return {
    		devices: nextProps.devices,
			loading: nextProps.loading
  		};
	}


	componentDidMount()
	{	
		this.setState({
			columnsToDisplay: this.props.widget.DWT_COLUMNS ?
				this.props.widget.DWT_COLUMNS.split(',') : this.allColumns
		});
		this.props.isCategoryDashboard && this.fillColumnSelect(true);
	}


	componentDidUpdate(prevProps, prevState)
	{	
		if (prevState.devices !== this.state.devices)
		{
			this.fillColumnSelect();
		}
		if (prevState.columnsToDisplay !== this.state.columnsToDisplay)
		{
			this.props.saveColumnsToParent(this.state.columnsToDisplay);
		}
	}


	api_handleSizeChange = (event) =>
	{
		this.setState({ size: event.target.value });

		request.patch(
            'widget/' + this.props.widget.DWG_ID,
            {
				type: 'table',
				row_size: event.target.value
			},
            (success) => {},
            (error) => console.error(error)
        );
	}


	api_handleColumnToDisplayChange = (value) =>
	{	
		this.setState(value.includes("allAttributes") ? { columnsToDisplay: value, updateColumnOrderIsOpen: false } : { columnsToDisplay: value } );

		let columnsToDisplayString = '';

		value.forEach((column) => {
			columnsToDisplayString += column + ',';
		})
		
		columnsToDisplayString = columnsToDisplayString.slice(0, -1);

		request.patch(
            'widget/' + this.props.widget.DWG_ID,
            {
				type: 'table',
				columns: columnsToDisplayString
			},
            (success) => {},
            (error) => console.error(error)
        );
	}


	api_getDevice = (deviceId) =>
	{
		request.get(
			'device/' + deviceId,
			{},
			(api_device) =>
			{
				this.setState({ deviceToEdit: api_device[0] });
			},
			() => console.error('load data failed'),
			() => {}
		);
	}


	openCreateModal = () =>
	{
		this.setState({ createModalIsOpen: true });
	}


	openEditModal = (deviceId) =>
	{
		this.api_getDevice(deviceId);
		this.setState({ editModalIsOpen: true });
	}


	closeModal = () =>
	{
		this.setState({
			editModalIsOpen: false,
		 	createModalIsOpen: false
		});
	}


	saveToState = (index, value) =>
	{
		this.setState ({ [index]: value });
	}


	setColumnsToDisplay = () =>
	{
		const statusToColor = {
			'CONNECT': 'green',
			'STARTED': 'green',
			'STOPPED': 'volcano',
			'UNAVAILABLE': 'purple'
		}

		const actionsTitle = <span><Tooltip title={utils.translate('componentWidgetTable.actions')}>
			<Icon type='thunderbolt' className='greyIcon'/></Tooltip>{utils.translate('componentWidgetTable.actions')}</span>;

		const propertyTitle = (columnName) =>
		{
			return (
				<div>
					<span>
						<Tooltip
							title={utils.translate(
								"componentWidgetTable.generalProp"
							)}
						>
							<Icon type="setting" className="greyIcon" />
						</Tooltip>
						{columnName}{" "}
					</span>
				</div>
			);
		}

		const allAvailableColumns = {
			actions : {
    			title: actionsTitle,
    			dataIndex: 'actions',
    			key: 'actions',
				width: 300,
				render: (text, record) => (
					<span>
						<Button
							style={{width: '100px'}}
						 	type={this.state.updated[record.id] ? 'primary' : ''}
							onClick={() => this.openEditModal(record.id)}
						>
							<FontAwesomeIcon className='form-icon' icon='pencil-alt'/>
							{this.state.updated[record.id] ? 'Updated' : 'Edit'}
						</Button>
					</span>
				),
  			},
			id :{
    			title: propertyTitle(utils.translate('componentWidgetTable.id')),
    			dataIndex: 'id',
    			key: 'id',
				width: 300,
				defaultSortOrder: 'descend',
    			sorter: (a, b) => {return a.id.localeCompare(b.id)},
  			},
			device:{
    			title: propertyTitle(utils.translate('generic.labelDvc')),
    			dataIndex: 'device',
    			key: 'device',
				width: 300,
    			sorter: (a, b) => {return a.device.localeCompare(b.device)},
  			},
			category :{
    			title: propertyTitle(utils.translate('componentWidgetTable.cat')),
    			dataIndex: 'category',
    			key: 'category',
				width: 300,
    			sorter: (a, b) => {return a.category.localeCompare(b.category)},
  			},
			container :{
				title: propertyTitle(utils.translate('componentWidgetTable.container')),
				dataIndex: 'container',
				key: 'container',
				width: 300,
				sorter: (a, b) => {return a.container.localeCompare(b.container)},
  			},
			device_status:{
    			title: propertyTitle(utils.translate('componentWidgetTable.statusDVC')),
    			dataIndex: 'device_status',
    			key: 'device_status',
				width: 300,
    			sorter: (a, b) => {return a.device_status.localeCompare(b.device_status)},
				render: (text, record) => (
					<span>
						<Tag color={statusToColor[record.device_status]}>{utils.translate(`deviceStatus.${record.device_status}`)}</Tag>
					</span>
    			),
  			},
			active_alarms :{
    			title: propertyTitle(utils.translate('componentWidgetTable.activeALA')),
    			dataIndex: 'active_alarms',
    			key: 'active_alarms',
				width: 300,
				render: (text, record) => (
					<span>
						<Popover
							id='popoverAlarms'	
							placement="bottom"
							title={record.alarms.length > 1 ? record.alarms[0].type + ' - ' + record.alarms[0].description + ' - '  + record.alarms[0].date_creation : ''}
							content={record.alarms.length > 0 ? record.alarms.map((alarm,i ) =>
								<li key={i}>{alarm.type + ' - ' + alarm.description + ' - ' + alarm.date_creation}</li>) : utils.translate('componentWidgetTable.noALA2')}
							trigger="hover"
						>
							<div className={text !== '0' ? 'flash' : ''}>{record.active_alarms}</div>
					  </Popover>
					</span>
    			),
    			sorter: (a, b) => {return a.active_alarms.localeCompare(b.active_alarms)},
  			},
			resolved_alarms:{
    			title: propertyTitle(utils.translate('componentWidgetTable.resolvedALA')),
    			dataIndex: 'resolved_alarms',
    			key: 'resolved_alarms',
				width: 300,
    			sorter: (a, b) => {return a.resolved_alarms.localeCompare(b.resolved_alarms)},
  			},
		};

		this.state.devices && this.state.devices.forEach(device =>
		{	
			device.attributes && device.attributes.forEach(attribute =>
			{
				if (!Object.keys(allAvailableColumns).find(colName => colName === attribute.code))
				{									
					const attributeTitle = <span><Tooltip title={utils.translate('generic.attr')}>
						<Icon type='unordered-list' className='greyIcon'/></Tooltip>{attribute.name}</span>;

					allAvailableColumns[`${attribute.code}`] = {
						title: attributeTitle,
						dataIndex: attribute.code,
						key: attribute.code,
						width: 300,
						sorter: (a, b, sortOrder) => {
							if (a[attribute.code] !== undefined && b[attribute.code] !== undefined)
							{
								return a[attribute.code].localeCompare(b[attribute.code]);
							}
							else if (a[attribute.code] !== undefined)
							{
								return sortOrder === 'ascend' ? -1 : 1;
							}
						}
					};
				}
			});

			if (device.configs !== undefined)
			{
				device.configs.forEach(config =>
				{
					if (!Object.keys(allAvailableColumns).find(colName => colName === config.number))
					{
						const datatypeTitle = (
							<span>
								<Tooltip title={utils.translate('generic.dty')}>
									<Icon type='api' className='greyIcon'/>
								</Tooltip>
									{config.unite_D1 ? config.label + ' (' + config.unite_D1 + ')' : config.label}
							</span>
						);

						allAvailableColumns[`${config.number}`] = {
							title: datatypeTitle,
							dataIndex: config.number,
							key: config.number,
							width: 300,
							sorter: (a, b, sortOrder) => {
								if (a[config.number] !== undefined && b[config.number] !== undefined)
								{
									return a[config.number] > b[config.number] ? -1 : 1;
								}
								else if (a[config.number] !== undefined)
								{
									return sortOrder === 'ascend' ? -1 : 1;
								}
							}
						};
					}
				});
			}
		});
		
		let visibleColumns = [];
		let count = 0;

		const updateColumnOrder = (index, direction)  => {
			index = parseInt(index);
			const columnsToDisplay = [...this.state.columnsToDisplay];
			
			if(direction === "<" && index > 0) {
				[columnsToDisplay[index - 1], columnsToDisplay[index]] = [columnsToDisplay[index], columnsToDisplay[index - 1]]   
			}
			if(direction === ">" && index < columnsToDisplay.length - 1)  {
				[columnsToDisplay[index], columnsToDisplay[index + 1 ]] = [columnsToDisplay[index + 1], columnsToDisplay[index]];   
			}
			this.api_handleColumnToDisplayChange(columnsToDisplay);
		}
	
		const columnsToDisplayFiltered = this.state.columnsToDisplay.filter(
			(col) => col !== "allAttributes"
		); 

		columnsToDisplayFiltered.forEach((columnToDisplay) => {
			if (Object.keys(allAvailableColumns).includes(columnToDisplay)) {

				if(this.state.columnsToDisplay.includes("allAttributes") ||!this.state.updateColumnOrderIsOpen) {
						visibleColumns.push({
						...allAvailableColumns[columnToDisplay],
					});
				}
				
				else if (this.state.updateColumnOrderIsOpen) {
					if (count === 0) {
						visibleColumns.push({
							title: (
								<div>
									<Icon
										type="arrow-right"
										id={`R-${count}`}
										onClick={(e) =>
											updateColumnOrder(
												e.currentTarget.id.slice(2),
												">"
											)
										}
									/>
								</div>
							),
							children: [
								{ ...allAvailableColumns[columnToDisplay] },
							],
							align: "center",
						});
					} else if (count === columnsToDisplayFiltered.length - 1) {
						visibleColumns.push({
							title: (
								<div>
									<Icon
										type="arrow-left"
										id={`L-${count}`}
										onClick={(e) =>
											updateColumnOrder(
												e.currentTarget.id.slice(2),
												"<"
											)
										}
									/>
								</div>
							),
							children: [
								{ ...allAvailableColumns[columnToDisplay] },
							],
							align: "center",
						});
					} else {
						visibleColumns.push({
							title: (
								<div>
									<Icon
										type="arrow-left"
										id={`L-${count}`}
										onClick={(e) =>
											updateColumnOrder(
												e.currentTarget.id.slice(2),
												"<"
											)
										}
									/>
									<Icon
										type="arrow-right"
										id={`R-${count}`}
										onClick={(e) =>
											updateColumnOrder(
												e.currentTarget.id.slice(2),
												">"
											)
										}
									/>
								</div>
							),
							children: [
								{ ...allAvailableColumns[columnToDisplay] },
							],
							align: "center",
						});
					}
				} 
				count++;
			}
		});
		return visibleColumns;
	}

	// Checks if currently selected columns all exist among available options, updates if necessary
	updateColumnsToDisplay = () =>
	{
		let columnsToDisplay = [...this.state.columnsToDisplay]; // copies state array
		let hasChanged = false;

		columnsToDisplay && columnsToDisplay.forEach(columnToTest =>
		{
			if (!this.state.allAvailableDatatypes.find(availableDatatype => availableDatatype.number === columnToTest)
				&& // not in available datatypes
				!this.state.allAvailableAttributes.find(availableAttribute => availableAttribute.code === columnToTest)
				&& // not in available attributes
				!this.allColumns.find(availableColumn => availableColumn === columnToTest))
				{ // not in general columns
					columnsToDisplay = columnsToDisplay.filter(columnToDisplay => columnToDisplay !== columnToTest);
					hasChanged = true; // value removed from columns to display
				}
		});

		if (hasChanged) { this.api_handleColumnToDisplayChange(columnsToDisplay); }
	}


	fillColumnSelect = (firstLoad = false) =>
	{
		let allAvailableDatatypes = [];
		let allAvailableAttributes = [];

		this.state.devices &&
			this.state.devices.forEach((device) =>
		{
			if (device.attributes !== undefined)
			{
				device.attributes.forEach(attribute =>
				{
					if (!allAvailableAttributes.find(availableAttribute => availableAttribute.code === attribute.code))
					{
						allAvailableAttributes.push(attribute);
					}
				});
			}

			if (device.configs !== undefined)
			{
				device.configs.forEach(config =>
				{
					if (!allAvailableDatatypes.find(availableDatatype => availableDatatype.number === config.number))
					{
						allAvailableDatatypes.push(config);
					}
				});
			}
		});

		this.setState({
			allAvailableDatatypes: allAvailableDatatypes,
			allAvailableAttributes: allAvailableAttributes
		},  () => {
			// !firstLoad && this.updateColumnsToDisplay(); // CS-492 -> was blocking value update, don't know the full impact
		});
	}


	customExpandIcon = (props) =>
	{
		if (!this.state.columnsToDisplay.includes('allAttributes'))
		{
			return;
		}

	    if (props.expanded) {
	        return <a href={() => false} onClick={e => {
	            props.onExpand(props.record, e);
	        }}>
			<Tooltip className='tableTooltip' placement='top' title={utils.translate('componentWidgetTable.hide')}>
				<Icon type='up' className='greyIcon-no-margin'/>
			</Tooltip>
			</a>
	    }

		else {
	        return <a href={() => false} onClick={e => {
	            props.onExpand(props.record, e);
	        }}>
			<Tooltip className='tableTooltip' placement='top' title={utils.translate('componentWidgetTable.show')}>
				<Icon type='unordered-list' className='greyIcon-no-margin'/>
			</Tooltip>
			</a>
	    }
	}

	toggleUpdateColumnOrderDisplay = () => {
		this.setState({updateColumnOrderIsOpen: !this.state.updateColumnOrderIsOpen });
	}

	handleSearch = (event) =>
	{
		this.setState({ searchedValue: event.target.value })
	}


    render()
	{
		const isConfigMode = utils.isConfigMode();
		const data = [];

		this.state.devices &&
			this.state.devices.forEach((device) =>
		{
			let deviceNewAlarms = [];
			let deviceResolvedAlarms = [];

			if (device.active_alarms !== undefined)
			{
				device.active_alarms.forEach((alarm) =>
				{
					if (alarm.status === 'NEW')
					{
						deviceNewAlarms.push(alarm);
					}
					else if (alarm.status === 'RESOLVED')
					{
						deviceResolvedAlarms.push(alarm);
					}
				});
			}

			let dataObject = {
				key: device.id,
				id: device.id,
				device: device.label,
				container: device.container ? device.container.name : '',
				category: device.category.label,
				device_status: device.status,
				active_alarms: '' + deviceNewAlarms.length, // converting to string (for sorter)
				resolved_alarms: '' + deviceResolvedAlarms.length, // converting to string (for sorter)
				alarms: deviceNewAlarms,
				attributes: device.attributes ? device.attributes : []
			};

			if (device.attributes !== undefined)
			{
				device.attributes.forEach(attribute =>
				{
					if (attribute.code !== undefined && attribute.value !== undefined)
					{
						dataObject[attribute.code] = attribute.value
					}
				});
			};

			if (device.configs !== undefined)
			{
				device.configs.forEach(config =>
				{
					if (config.number !== undefined && config.value !== undefined)
					{
						if (!this.props.dataLoaded)
						{
							dataObject[config.number] = <Spin size='small'/>;
						}
						else
						{
							if (config.value === null)
							{
								dataObject[config.number] = undefined;
							}
							else
							{
								dataObject[config.number] = config.value;
							}
						}
					}
				});
			};
			data.push(dataObject);
		});

		const expandedRowRender = (props) => 
		{
			const title = <span><Icon type='unordered-list' className='greyIcon'/>{utils.translate('generic.attrs')}</span>

			const columns = [
				{ title: 'Id', dataIndex: 'id', key: 'id', defaultSortOrder: 'descend',
    			sorter: (a, b) => {return a.id.localeCompare(b.id)},},
				{ title: 'Label', dataIndex: 'name', key: 'name', defaultSortOrder: 'descend',
    			sorter: (a, b) => {return a.name.localeCompare(b.name)}, },
				{ title: 'Value', dataIndex: 'value', key: 'value', defaultSortOrder: 'descend',
    			sorter: (a, b) => {return a.value.localeCompare(b.value)}, },
			];

			const data = [];

			props.attributes.forEach(attribute => {
				data.push({
					key: attribute.id,
					id: attribute.id,
					name: attribute.name,
					value: attribute.value,
				});
			});

			return <Table
					columns={columns}
					dataSource={data}
					pagination={{pageSize: 5, size: 'small', hideOnSinglePage: true}}
					title={() => title}
				/>;
		};

        return (

			<>
				<ControlBar
					{...this.state}
					widget={this.props.widget}
					allDeviceIds={this.props.allDeviceIds}
					selectRef={this.selectRef}
					isConfigMode={isConfigMode}
					openCreateModal={this.openCreateModal}
					api_handleSizeChange={this.api_handleSizeChange}
					handleSearch={this.handleSearch}
					toggleUpdateColumnOrderDisplay={this.toggleUpdateColumnOrderDisplay}
					api_handleColumnToDisplayChange={this.api_handleColumnToDisplayChange}
				/>

				<Table
					{...this.state}
					columns={this.setColumnsToDisplay()}
					expandedRowRender={this.state.columnsToDisplay.includes('allAttributes') ? expandedRowRender : null}
					dataSource={data.filter(data => Object.keys(data).filter(key => data[key] && data[key].toString().toLowerCase().includes(this.state.searchedValue.toLowerCase()) && this.state.columnsToDisplay.includes(key)).length > 0 ? true : false)}
					pagination={{pageSize: parseInt(this.props.widget.DWT_PAGE_SIZE)}}
					expandIcon={(props) => this.customExpandIcon(props)}
					bordered={true}
					scroll={{ x: true }}
					//loading={this.state.loading} -- If malfunction put back, problem of Gaia, table not leading each time
				/>

				<EditForm
					{...this.props}
                    isOpen={this.state.editModalIsOpen}
					closeModal={this.closeModal}
					devices={this.state.devices}
					deviceToEdit={this.state.deviceToEdit}
					saveToParent={this.saveToState}
                />

				<CreateForm
					{...this.props}
					isOpen={this.state.createModalIsOpen}
					closeModal={this.closeModal}
					devices={this.state.devices}
					saveToParent={this.saveToState}
				/>
			</>
        );
    }
}


export default StatusTable;
