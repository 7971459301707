import React, { Component } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Card } from 'reactstrap';
import { Spin, BackTop, Tooltip } from 'antd';
import { animateScroll as scroll } from 'react-scroll';
import { withTranslation } from 'react-i18next';

import Header from 'Components/Studio/Header/Header';
import Widget from 'Components/Studio/Dashboard/Widgets/Widget';
import Toolbar from 'Components/Studio/Toolbar/Toolbar';
import DeviceSelector from 'Components/Studio/Toolbar/DeviceSelector/DeviceSelector';
import NotFound from 'Components/Routing/NotFound/NotFound';
import NoAccess from 'Components/Routing/NoAccess/NoAccess';
import Inactive from 'Components/Routing/Inactive/Inactive';
import BlockHeader from 'Components/Studio/Dashboard/Widgets/WidgetLib/BlockHeader/BlockHeader';

import { auth } from 'Lib/auth';
import { request } from 'Lib/request';
import { utils } from 'Lib/utils';
import { default_theme } from 'Styles/Themes/themes.js';

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css';

import './Dashboard.css';

const ResponsiveGridLayout = WidthProvider(Responsive);


class Dashboard extends Component {

	constructor(props) {
		super(props);

		this.state = {
			widgets: [],
			allDevices: [],
			allCategories: [],
			allContainers: [],
			allGroups: [],
			allGeoZones: [],
			category: {},
			currentPage: {},
			catCurrentDevice: {},
			layouts: {},
			firstDeviceOfCategory: '',
			loading: true,
			appTheme: undefined,
			layoutsLoaded: false,
			isCategoryDashboard: false,
			notFound: false,
			noAccess: false,
			isVisible: undefined,
			language: undefined,
			groupDBK: [],
		};

		this.pageLoaded = false;
		this.widgetsLoaded = false;
	}


	componentDidMount() {
		this.api_getWidgets(this.props.match.params.dpg_id);
		this.api_getDevices();
		this.api_getCategories();
		this.api_getContainers();
		this.api_getGroups();
		this.api_getGeoZones();
	}


	componentWillReceiveProps(newProps) {
		this.api_getWidgets(newProps.match.params.dpg_id);
	}


	api_getWidgets = (propsDpgId) => {
		let dpgId = typeof propsDpgId !== 'undefined' ? propsDpgId : this.props.match.params.dpg_id;

		request.get(
			'widget',
			{ page_id: dpgId },
			(widgets) => {

				let tableIndexes = [];

				widgets.forEach((widget) => {

					widget.DWG_DECOR = (utils.isValidJson(widget.DWG_DECOR) && widget.DWG_DECOR !== null) ? JSON.parse(widget.DWG_DECOR) : {};

					if (widget.DWG_TYPE === 'DWG_table') { // tables must go last, to be loaded last, to allow time for other widgets to get their data
						tableIndexes.push(widgets.indexOf(widget));
					}
				});

				tableIndexes.reverse().forEach((index) => widgets.push(widgets.splice(index, 1)[0])); // move tables at the end of the array

				let groupDBK = widgets.reduce((r, a) => {
					r[a.DBK_ID] = [...r[a.DBK_ID] || [], a];
					return r;
				}, {});
				this.setState({ widgets: widgets, groupDBK: groupDBK }, () => { this.api_loadPage(dpgId); });
				this.widgetsLoaded = true;
				
			},
			() => { console.error('load widgets failed'); }
		);
	}


	api_getDevices = (containerId, categoryId) => {
		this.setState({ loading: true }, () => {
			request.get(
				'device',
				{
					container_id: containerId,
					category_id: categoryId,
					dependencies: 'category'
				},
				(devices) => this.setState({ allDevices: devices }),
				() => console.error('load devices failed'),
				() => { this.setState({ loading: false }); }
			);
		});
	}


	api_getCategories = () => {
		request.get(
			'category',
			{
				class: 'device'
			},
			(categories) => {
				this.setState({ allCategories: categories });
			},
			() => console.error('load categories failed')
		);
	}


	api_getContainers = () => {
		request.get(
			'container',
			{},
			(containers) => {
				this.setState({ allContainers: containers });
			},
			() => console.error('load containers failed')
		);
	}


	api_getGroups = () => {
		request.get(
			'devicegroup',
			{},
			(devicegroups) => {
				this.setState({ allGroups: devicegroups});
			},
			() => console.error('load groups failed')
		);
	}


	api_getGeoZones = () => {
		request.get(
			'geozone',
			{},
			(geoZones) => {
				this.setState({ allGeoZones: geoZones });
			},
			() => console.error('load geo-zones failed')
		);
	}


	api_getCategory = (catId) => {
		request.get(
			'category/' + catId,
			{},
			(category) => {
				this.setState({
					isCategoryDashboard: true,
					category: category['0']['0']
				}, () => {
					if (utils.isConfigMode() && this.state.category.devices && this.state.category.devices.length === 0) { //cat is empty of devices
						this.displayEmptyCategoryAlert();
					}
					if (this.state.category.devices && this.state.category.devices.length !== 0) // cat is not empty
					{
						this.assignFirstDeviceOfCategoryToWidgets();
					}
				});
			},
			() => {
				this.setState({
					isCategoryDashboard: false,
					category: {}
				});
			},
			() => { }
		);
	}


	api_deleteBlock = (blockId) => {
		request.delete(
			'block/' + blockId,
			{},
			() => this.api_getWidgets(),
			(e) => {
				utils.openNotification(
					'error',
					utils.translate('componentWidget.error1'),
					utils.translate('generic.rights'),
					'bottomRight',
					5
				);
			},
		);
	}


	assignFirstDeviceOfCategoryToWidgets = () => {
		const query = this.props.location.search; // if url includes a device id, we'll try to use it as default device
		let firstSplit = query.split('device=')[1];	// two splits necessary in case of several urlparams
		const urlDeviceId = !utils.isConfigMode() ? (firstSplit ? firstSplit.split('&')[0] : undefined) : undefined; // getting device id (only is view mode)
		
		if (this.state.category.devices !== undefined && Object.keys(this.state.category.devices)[0] !== undefined) {
			let newWidgets = [];
			const urlDeviceIdIsValid = Object.keys(this.state.category.devices).includes(urlDeviceId); // id is actually in category
			const firstDeviceOfCategory = (urlDeviceIdIsValid && urlDeviceId) || Object.keys(this.state.category.devices)[0];
			// if urlDeviceId is invalid or undefined, use first device of cat as default
			this.state.widgets.forEach(widget => {
				let widgetCopy = Object.assign({}, widget); // state has to remain immutable (widget.DVC_IDS = ... would mutate state directly)

				if (widgetCopy.DVC_IDS !== undefined) { widgetCopy.DVC_IDS = firstDeviceOfCategory; }
				else if (widgetCopy.DVC_ID !== undefined) { widgetCopy.DVC_ID = firstDeviceOfCategory; }

				newWidgets.push(widgetCopy);
			});
			let groupDBK = newWidgets.reduce((r, a) => {
				r[a.DBK_ID] = [...r[a.DBK_ID] || [], a];
				return r;
			}, {});

			this.setState({
				widgets: newWidgets,
				groupDBK: groupDBK,
				firstDeviceOfCategory: firstDeviceOfCategory,
			});
		}
	}


	api_loadPage = (propsDpgId) => {
		let dpgId = typeof propsDpgId !== 'undefined' ? propsDpgId : this.props.match.params.dpg_id;
		let isConfigMode = utils.isConfigMode();

		this.setState({
			layoutsLoaded: isConfigMode ? true : false, // to prevent layout re-render on widget delete
		}, () => {
			request.get(
				'page/' + dpgId,
				{},
				(page) => {
					let isActive = page.APP_ACTIVE === '0' ? false : true;
					this.setState({
						noAccess: isConfigMode && page.ARC_RIGHT === 'r',
						isVisible: isActive ? true : page.ARC_RIGHT === 'rw' ? true : false
					});
					const layouts = utils.isValidJson(page.DPG_LAYOUT) ? JSON.parse(page.DPG_LAYOUT) : {};
					let docTitle = page.APP_LABEL || '';
					if (docTitle !== '') { docTitle = ' - ' + docTitle; }
					document.title = page.DPG_LABEL + docTitle;
					
					const hasFavicon = page.DAP_FAVICON_URL !== window.ENV.REACT_APP_CS_URL;
					const favicon = document.getElementById("favicon");
					favicon.href = hasFavicon && window.ENV.REACT_APP_CS_URL + page.DAP_FAVICON_URL;

					this.switchToStatic(
						layouts,
						(layouts) => this.setState({ layouts: layouts })
					);

					this.pageLoaded = true;

					this.setState({
						currentPage: page,
						appTheme: page.DAP_STYLESHEET === null ? default_theme : page.DAP_STYLESHEET,
						layouts: layouts,
						language: page.DAP_LANGUAGE
					}, () => {
						if (utils.isCategoryDashboard(this.state.currentPage)) {
							this.api_getCategory(this.state.currentPage.CAT_ID);
						}
						else {
							this.setState({ isCategoryDashboard: false });
						}

						let appTheme = document.getElementById('appTheme');
						if (appTheme) { appTheme.remove(); }

						this.styleTag = document.createElement('style');
						this.styleTag.type = 'text/css';
						this.styleTag.id = 'appTheme';
						this.styleTag.textContent = this.state.appTheme;
						document.head.appendChild(this.styleTag);
					});
				},
				(e) => { if (e.status === 404) { dpgId !== 'noAccess' ? this.setState({ notFound: true }) : this.setState({ noAccess: true }); } },
				() => { this.setState({ layoutsLoaded: true }); }
			);
		});
	}


	api_handleBlockChange = (blockId, params) =>
	{
		request.patch(
			'block/' + blockId,
			params,
			(res) =>
			{
				this.api_getWidgets(this.props.match.params.dpg_id);
			},
			() => console.error('edit widget failed')
		);
	}


	switchToStatic = (layouts, callback) => {
		Object.values(layouts).forEach((breakpoint) => {
			breakpoint.forEach((widget) =>
				widget.static = !utils.isConfigMode());
		}); // if in view mode, set widgets to static

		if (callback) { callback(layouts); }
	}


	onBreakpointChange = (breakpoint, cols) => {
		this.setState({
			breakpoint: breakpoint,
			cols: cols
		});
	}


	displayEmptyCategoryAlert = () => {
		utils.openNotification(
			'error',
			utils.translate('componentDashboard.empty1'),
			this.props.t('componentDashboard.empty2') + this.state.category.label + this.props.t('componentDashboard.empty3'),
			'bottomRight',
			5
		);
	}


	addWidget = (type, block = null) => {
		let params = {
			label: this.getLanguage(type),
			refresh_freq: 0,
			page_id: this.props.match.params.dpg_id,
			block: block !== null ? block[0].DBK_ID : block,
			type: type.replace('DWG_', ''),
			order: block !== null ? parseFloat(block[block.length -1].DWG_ORDER) + 1 : 1,
			dbk_label: this.getLanguageBlock(),
		};

		if (this.state.isCategoryDashboard) {
			if (!this.state.category) { return; }

			else if (this.state.category.devices && this.state.category.devices.length === 0) { //cat is empty of devices
				this.displayEmptyCategoryAlert();
				return;
			}
			else { // we'll cover both variants (DVC_ID and DVC_IDS to ensure that all types of widgets are included)
				params.device_id = Object.keys(this.state.category.devices)[0];
				params.device_ids = params.device_id; //obsolete : DVC_IDS is now set by assignFirstDeviceOfCategoryToWidgets, 100% client-side
			}
		}

		request.post(
			'widget',
			params,
			(response) => {
				this.setState(prevState => ({
					widgets: [...prevState.widgets,
					{
						DWG_LABEL: this.getLanguage(type),
						DWG_TYPE: type,
						DWG_REFRESH_FREQ: 0,
						DWG_ID: response.id,
						DVC_ID: params.device_id, // again, covering both options <CAT_DASHBOARD>
						DVC_IDS: params.device_ids, // again, covering both options <CAT_DASHBOARD>
					}
					]
				}));
				this.api_getWidgets(this.props.match.params.dpg_id);
				if (!block){
					scroll.scrollToBottom({ duration: 700, smooth: 'easeInQuad' });
				}
			},
			() => console.error('create widget failed')
		);
	}


	saveWidgetToState = (widgetToSet, id) => {
		let filteredWidgets = this.state.widgets.filter(widgetInState => id !== widgetInState.DWG_ID);
		filteredWidgets.push(widgetToSet);
		let groupDBK = filteredWidgets.reduce((r, a) => {
			r[a.DBK_ID] = [...r[a.DBK_ID] || [], a];
			return r;
		}, {});
		this.setState({ groupDBK: groupDBK, widgets: filteredWidgets });
	}

	// This method is called even when layout does not change, which has been causing errors when switching between pages in view mode
	onLayoutChange = (layout, layouts) => {
		let oldLayouts = JSON.stringify(layouts);
		let newLayouts = JSON.stringify(this.state.layouts);

		if (oldLayouts !== newLayouts) {
			const minSize = 6;
			// so we're neutralazing it in view mode, we don't need to save anything if not in config mode anyway
			layout.forEach(layoutItem => {
				if (layoutItem.h < minSize) { layoutItem.h = minSize; }
				if (layoutItem.w < minSize) { layoutItem.w = minSize; }

				layoutItem.minW = minSize;
				layoutItem.minH = minSize;
			});

			if (utils.isConfigMode()) { this.saveLayouts(layouts); }
		}
	}


	saveLayouts = (layouts) => {
		this.setState({ layouts: layouts });

		request.patch(
			'page/' + this.props.match.params.dpg_id,
			{
				layout: layouts
			},
			() => {
				let currentPage = this.state.currentPage;
				currentPage.DPG_LAYOUT = layouts;
				this.setState({ currentPage: currentPage });
			},
			() => console.error('layout save failed')
		)
	}


	saveCurrentDeviceToState = (device) => {
		this.setState({ catCurrentDevice: device });
	}


	getLanguage = (type) => {
		type = utils.capitalizeFirstLetter(type.replace('DWG_', ''))
		if (this.state.language === "DAP_FR") {
			return 'Nouveau Widget ' + utils.translateTypeFR(type)
		} else {
			return 'New ' + type + ' Widget'
		}
	}


	getLanguageBlock = () => {
		if (this.state.language === "DAP_FR") {
			return 'Nouveau Bloc';
		} else {
			return 'New Block';
		}
	}


	getWidgetClass = (widget) => {
		let widgetClass = ' ' + widget.DWG_TYPE;
		return widgetClass.replace('DWG_', '').toUpperCase();
	}


	getWidgetId = (widget) => {
		let widgetId = widget.DWG_TYPE + '_' + widget.DWG_ID;
		return widgetId.replace('DWG_', '').toUpperCase();
	}


	orderWidgetInBlock = (array) => {
		return array.sort((a, b) => parseFloat(a.DWG_ORDER) - parseFloat(b.DWG_ORDER));
	}


	moveWidgetUp = (widget, block) => {
		let newOrder = undefined;
	
		block.map((widgetCurrent, index) =>{
			if( widgetCurrent.DWG_ID === widget.DWG_ID && index - 1 > 0 ){
				newOrder = (parseFloat(block[index - 1].DWG_ORDER) + parseFloat(block[index - 2].DWG_ORDER)) / 2

				this.saveOrder(widget, newOrder);
			} else if(widgetCurrent.DWG_ID === widget.DWG_ID && index - 1 === 0 ) {
				newOrder = parseFloat(block[index - 1].DWG_ORDER) - 1;

				this.saveOrder(widget, newOrder);
			}
			return null;
		})
	}


	moveWidgetDown = (widget, block) => {

		let newOrder = undefined;

		block.map((widgetCurrent, index) =>{
			if( widgetCurrent.DWG_ID === widget.DWG_ID && index + 1 < block.length -1 ){
				newOrder = (parseFloat(block[index + 1].DWG_ORDER) + parseFloat(block[index + 2].DWG_ORDER)) / 2

				this.saveOrder(widget, newOrder);
			} else if(widgetCurrent.DWG_ID === widget.DWG_ID && index + 1 === block.length -1 ) {
				newOrder = parseFloat(block[index + 1].DWG_ORDER) + 1;

				this.saveOrder(widget, newOrder);
			}
			return null;
		})
	}


	saveOrder = (widget, order) => {
		
		request.patch(
			'widget/' + widget.DWG_ID,
			{
				order: order
			},
			() => {
				this.api_getWidgets(this.props.match.params.dpg_id);
			},
			() => console.error('order save failed')
		)
	}

	
	reassignOrder = (widgetDeleted) => {
		this.api_getWidgets(this.props.match.params.dpg_id);
		let index = 1;
		Object.keys(this.state.groupDBK).map(key =>{
			this.state.groupDBK[key].map((widget) =>{
				if(widget.DWG_ID !== widgetDeleted){
					this.saveOrder(widget, index);
					index++;
				}
				return null;
			})
			return null;
		})
	}

	assignWidgetConfigs = () => {
		Object.values(this.state.groupDBK).forEach(widget =>
			{
				if (widget['0'].CFG_ID && !widget['0'].DWG_CFGS)
				{
					widget['0'].DWG_CFGS = widget['0'].CFG_ID;
				}
				else if (widget['0'].CFG_IDS && !widget['0'].DWG_CFGS)
				{
					widget['0'].DWG_CFGS = widget['0'].CFG_IDS;
				}
			});
	}

	render() {
		if (this.state.notFound) { return <NotFound />; }
		if (this.state.noAccess) { return <NoAccess {...this.state} /> }
		if (this.pageLoaded && !this.state.isVisible) { return <Inactive {...this.state.currentPage} /> }
		const isConfigMode = utils.isConfigMode();
		const isCategoryDashboard = this.state.isCategoryDashboard;
		const isMobileVersion = utils.isMobileVersion(window);
		const waitforDevice = !isConfigMode &&
			this.props.location.search.split('device=')[1] !== undefined &&
			this.state.firstDeviceOfCategory === '';

		const marginX = this.state.currentPage && Number(this.state.currentPage.DAP_WGT_MARGIN_X);
		const marginY = this.state.currentPage && Number(this.state.currentPage.DAP_WGT_MARGIN_Y);

		this.assignWidgetConfigs(); // save initial widget configs
		
		return (

			<>
				<Tooltip placement='top' title='To Top!'>
					<BackTop visibilityHeight={50} />
				</Tooltip>
				{this.state.appTheme !== undefined &&
					<Header
						{...this.state}
						show={auth.isAuthenticated}
						dapId={window.ENV.REACT_APP_ID}
						dpgId={this.props.match.params.dpg_id}
						loadPage={this.api_loadPage}
						isCategoryDashboard={isCategoryDashboard}
						api_getWidgets={this.api_getWidgets}
						api_getCategory={this.api_getCategory}
						api_getContainers={this.api_getContainers}
					/>}
				{isConfigMode && this.state.notFound !== undefined &&
					<Toolbar
						dpgId={this.props.match.params.dpg_id}
						getWidgets={this.api_getWidgets}
						addWidget={this.addWidget}
					/>}
				{!isConfigMode && isCategoryDashboard &&
					<DeviceSelector
						{...this.state}
						saveCurrentDeviceToParent={this.saveCurrentDeviceToState}
						saveToParentState={this.saveWidgetToState}
					/>}
				<div className='content' style={{ marginTop: isConfigMode || isCategoryDashboard ? isMobileVersion && isConfigMode ? '124px' : '103px' : '56px' }}>
					{(this.pageLoaded && this.widgetsLoaded && this.state.layoutsLoaded && !waitforDevice) &&
						<div className='dashboard-container'>
							<ResponsiveGridLayout
								id='grid'
								className='BACKGROUND'
								breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
								cols={{ lg: 36, md: 30, sm: 18, xs: 1, xxs: 1 }}
								rowHeight={50}
								layouts={this.state.layouts}
								onBreakpointChange={this.onBreakpointChange}
								margin={[marginX, marginY]}
								onLayoutChange={(layout, layouts) => this.onLayoutChange(layout, layouts)}
								draggableCancel='.NoDrag'
							>
								{Object.keys(this.state.groupDBK).map(key =>
									<Card
										key={key}
										id={this.getWidgetId(this.state.groupDBK[key][0])}
										className={'WIDGET' + this.getWidgetClass(this.state.groupDBK[key][0]) + this.getWidgetClass(this.state.groupDBK[key][0]) + '_CARD widget-container'}
										style={{ backgroundColor: this.state.groupDBK[key].length > 1 ? this.state.groupDBK[key][0].DBK_COLOR : ''}}
									>
										<BlockHeader
											block={this.state.groupDBK[key]}
											addWidget={this.addWidget}
											deleteBlock={this.api_deleteBlock}
											getWidgetClass={this.getWidgetClass}
											getWidgets={this.api_getWidgets}
											handleBlockChange={this.api_handleBlockChange}
											className={'WIDGET' + this.getWidgetClass(this.state.groupDBK[key][0]) + this.getWidgetClass(this.state.groupDBK[key][0]) + '_CARD widget-container'}
										/>

										{this.orderWidgetInBlock(this.state.groupDBK[key]).map((widget) =>
											<Widget
												{...this.state}
												widget={widget}
												key={widget.DWG_ORDER}
												dpgId={this.props.match.params.dpg_id}
												isCategoryDashboard={isCategoryDashboard}
												getWidgets={this.api_getWidgets}
												addWidget={this.addWidget}
												api_getDevices={this.api_getDevices}
												saveToState={this.saveWidgetToState}
												getWidgetClass={this.getWidgetClass(widget)}
												getWidgetId={this.getWidgetId(widget)}
												block={this.state.groupDBK[key]}
												moveWidgetUp={this.moveWidgetUp}
												moveWidgetDown={this.moveWidgetDown}
												reassignOrder={this.reassignOrder}
											/>)}
									</Card>
								)}
							</ResponsiveGridLayout>
						</div>}
					{(!this.pageLoaded || !this.widgetsLoaded) &&
						<div className='pageLoader'>
							<Spin />
						</div>}
				</div>
			</>
		);
	}
}


export default withTranslation()(Dashboard);
