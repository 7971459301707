import React from 'react';
import { Row, FormGroup } from 'reactstrap';
import { Col, Slider } from 'antd';

import LabelItem from '../../FormItems/LabelItem/LabelItem';


const SliderSection = (props) =>
{

	const {
		label,
		iconType,
		defaultValue,
		onAfterChange,
		min,
		max,
		marks,
	} = props;


	return (

		<FormGroup>
			<Row
				className='align-items-center'
			>
				<LabelItem
					label={label}
					iconType={iconType}
				/>
				<Col
					span={6}
					className='text-center'
					style={{ marginLeft: 15 }}
				>
					<Slider
						key={defaultValue}
						defaultValue={defaultValue}
						onAfterChange={onAfterChange}
						min={min}
						max={max}
						marks={marks}
					/>
				</Col>
			</Row>
		</FormGroup>
	)
};


export default SliderSection;
