import React, { Component } from 'react';
import { Select, Icon } from 'antd';
import { Navbar } from 'reactstrap';

import { utils } from 'Lib/utils';
import { request } from 'Lib/request';
import { dynamicOneDevicePH } from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js'

const { Option } = Select;


class DeviceSelector extends Component
{

    constructor(props)
	{

        super(props);

        this.state = {
			category: props.category,
			devices: [],
			selectedDevice: undefined
        };
    }


	componentDidMount()
	{
		this.setCategoryToState();
		this.api_updateWidgets(this.props.firstDeviceOfCategory);
	}


	componentDidUpdate(prevProps)
	{
		if (JSON.stringify(prevProps.category) !== JSON.stringify(this.props.category)) { this.setCategoryToState(); }
		if (prevProps.firstDeviceOfCategory !== this.props.firstDeviceOfCategory
			&& this.props.firstDeviceOfCategory !== undefined)
		{
			this.setState({ selectedDevice: this.props.firstDeviceOfCategory });
			this.api_updateWidgets(this.props.firstDeviceOfCategory);
		}
	}


	setCategoryToState = () =>
	{
		this.setState({
			category: this.props.category,
			devices: [],
			selectedDevice: this.props.category.devices ? Object.keys(this.props.category.devices)[0] : undefined
		}, () =>
		{
			this.getDevicesInCategory(this.state.category);
		});
	}


	getDevicesInCategory = (category) =>
	{
		if (category.devices)
		{
			let devices = category.devices;
			let stateDevices = [];

			Object.entries(devices).forEach(function(entry)
			{
				let device = {
					id: entry[0],
					label: entry[1]
				};
				stateDevices.push(device);
			});

			this.setState({ devices: stateDevices });
		}
	}


	handleDeviceChange = (deviceId) =>
	{
		this.setState({ selectedDevice: deviceId }, () =>
		{
			this.api_updateWidgets(deviceId);
		});
	}


	api_updateWidgets = (deviceId) =>
	{
		if (deviceId)
		{
			request.get( // getting newly selected device info, we want to know its CFGs
				'device/' + deviceId,
				{},
				(device) =>
				{
					this.props.saveCurrentDeviceToParent(device['0']);
					
					this.props.widgets.forEach(widget =>
					{
						let widgetCopy = Object.assign({}, widget); // parent state MUST remain immutable, so we have to use a copy

						if ((!widgetCopy.CFG_ID && widgetCopy.DVC_ID) || widgetCopy.DWG_TYPE === 'DWG_sitemap')
						{ // single-device widget (or sitemap)

							widgetCopy.DVC_ID = device['0'].id;
							let oldAttrib = widgetCopy.VAL_ID;

							if (oldAttrib !== undefined)
							{
								this.api_getNewAttrib(widgetCopy, device, oldAttrib).then(newAttrib =>
								{
									widgetCopy.VAL_ID = newAttrib;
									
									this.props.saveToParentState(widgetCopy, widgetCopy.DWG_ID);
								});
							}
							else 
							{
								this.props.saveToParentState(widgetCopy, widgetCopy.DWG_ID);
							}
							
						}
						
						else if (!widgetCopy.CFG_IDS && widgetCopy.DVC_IDS)
						{ // multi-device widget
							widgetCopy.DVC_IDS = device['0'].id;
							this.props.saveToParentState(widgetCopy, widget.DWG_ID);
						}
						
						else if (widgetCopy.CFG_ID && widgetCopy.DVC_ID)
						{ // single device, single datatype widget
							widgetCopy.DVC_ID = device['0'].id;
							let oldConfigs = [widgetCopy.DWG_CFGS];

							this.api_getNewConfigs(widgetCopy, device, oldConfigs).then(newConfigs =>
							{
								let newConfigsString = this.getNewConfigsAsString(newConfigs);
								widgetCopy.CFG_ID = newConfigsString;
								
								widgetCopy = this.modifyWidgetCopy(widgetCopy, oldConfigs, newConfigs);
								this.props.saveToParentState(widgetCopy, widgetCopy.DWG_ID);
							});
						}
						
						else if (widgetCopy.CFG_IDS && widgetCopy.DVC_IDS)
						{ // multi-device, multi-datatype widget
							widgetCopy.DVC_IDS = device['0'].id;
							let oldConfigs = widgetCopy.DWG_CFGS.split(',');

							this.api_getNewConfigs(widgetCopy, device, oldConfigs).then(newConfigs =>
							{
								let newConfigsString = this.getNewConfigsAsString(newConfigs);
								widgetCopy.CFG_IDS = newConfigsString;

								widgetCopy = this.modifyWidgetCopy(widgetCopy, oldConfigs, newConfigs);
								this.props.saveToParentState(widgetCopy, widgetCopy.DWG_ID);
							});
						}
					});
				},
				(error) => { console.error(error); },
				() => {}
			);
		}
	}


	api_getNewConfigs = (widgetCopy, device, oldConfigs) =>
	{
		return new Promise(function(resolve, reject)
		{
			var newConfigs = [];
			var index = 0;
	
			oldConfigs.forEach(oldConfig =>
			{
				request.get(
					'config/' + oldConfig,
					{},
					(datatype) =>
					{
						index ++;
						
						device['0'].configs.forEach(config =>
						{ // CFGs from different DVCs but pointing to same datatype are linked together by common CFG_NUMBER
							if (config.number === datatype[oldConfig].number)
							{
								var pair = {};
								pair[oldConfig] = config.id;

								newConfigs.push(pair);

							}
						});

						if (index === oldConfigs.length)
						{
							resolve(newConfigs);
						}
					},
					(error) => { console.error(error); },
					() => {}
				);		
			});
		});
	}

	api_getNewAttrib = (widgetCopy, device, oldAttrib) =>
	{
		return new Promise(function(resolve, reject)
		{
			request.get(
				'value',
				{value: oldAttrib},
				(value) =>
				{
					device['0'].attributes.forEach(attrib =>
					{
						if (attrib.atr_id === value[0].attribute)
						{
							resolve(attrib.id);
						}
					});
				},
				(error) => { console.error(error); },
				() => {}
			);		
		});
	}


	getNewConfigsAsString = (newConfigs) =>
	{
		let newConfigsString = '';

		newConfigs.forEach(newConfig =>
		{
			newConfigsString += Object.values(newConfig)[0] + ',';
		});

		newConfigsString = newConfigsString.substring(0, newConfigsString.length - 1);

		if (newConfigsString === '')
		{
			return 'none';
		}

		return newConfigsString;
	}

	getNewAttribsAsString = (newAttribs) =>
	{
		let newAttribsString = '';

		newAttribs.forEach(newAttrib =>
		{
			newAttribsString += Object.values(newAttrib)[0] + ',';
		});

		newAttribsString = newAttribsString.substring(0, newAttribsString.length - 1);
		return newAttribsString;
	}


	modifyWidgetCopy = (widgetCopy, oldConfigs, newConfigs) =>
	{
		if (widgetCopy.JWC)
		{
			oldConfigs.forEach((oldConfig, key) =>
			{
				newConfigs.forEach((newConfigPair) =>
				{
					var key = Object.keys(newConfigPair)[0];
					var value = newConfigPair[key];

					if (key === oldConfig)
					{
						widgetCopy.JWC[value] = widgetCopy.JWC[oldConfig];
					}
				});
			});

			return widgetCopy;
		}
	}


    render()
	{
		const categoryColor = '#6a7071';
		const isMobileVersion = utils.isMobileVersion(window);
		const isEmptyCategory = this.state.devices.length === 0;

		return (

			<>
				<Navbar id='toolBar' expand='md' light className='toolbar-container'>
					<Select
						value={this.state.selectedDevice}
						onChange={this.handleDeviceChange}
						showSearch
						optionFilterProp='children'
						placeholder={dynamicOneDevicePH(isEmptyCategory, this.state.category.label, categoryColor)}
						style={{ width: isMobileVersion ? '98%' : '30%', margin: 'auto' }}
					>
						{this.state.devices.map((device) =>
							<Option key={device.id} value={device.id}><Icon className='formPHIcon' type='deployment-unit'/><span style={{color: categoryColor}}>{this.state.category.label}</span> | {device.label}</Option>
						)}
					</Select>
				</Navbar>
			</>
		);
    }
}


export default DeviceSelector;
