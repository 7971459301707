import React from 'react';
import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LogicalOperatorModel } from 'Models/LogicalOperatorModel.js';
import { utils } from 'Lib/utils';

const buttonStyle = { marginRight: 10 };
const buttonColor = 'rgba(0, 0, 0, 0.65)';
const disabledButtonColor = '#E6E6E6';


const LogicalOperatorBarItem = (props) =>
{

	const {
		selectedOperator,
		setSelectedOperator,
		thresholdIsNumerical,
	} = props;

	const isLowerThan = selectedOperator === LogicalOperatorModel.LOWER_THAN.value;
	const isGreaterThan = selectedOperator === LogicalOperatorModel.GREATER_THAN.value;
	const isEquals = selectedOperator === LogicalOperatorModel.EQUAL_TO.value;

	const buttonsDisabled = !thresholdIsNumerical;


	return (

		<>
			<Tooltip title={utils.translate('componentLogicalOperatorBarItem.lower')} placement='bottom'>
				<Button
					onClick={() => setSelectedOperator(LogicalOperatorModel.LOWER_THAN.value)}
					type={isLowerThan ? 'dashed' : 'default'}
					shape='circle'
					ghost
					style={buttonStyle}
					disabled={buttonsDisabled}
				>
					<FontAwesomeIcon
						icon='less-than'
						color={buttonsDisabled ? disabledButtonColor : buttonColor}
					/>
				</Button>
			</Tooltip>
			<Tooltip title={utils.translate('componentLogicalOperatorBarItem.greater')} placement='bottom'>
				<Button
					onClick={() => setSelectedOperator(LogicalOperatorModel.GREATER_THAN.value)}
					type={isGreaterThan ? 'dashed' : 'default'}
					shape='circle'
					ghost={!isGreaterThan}
					style={buttonStyle}
					disabled={buttonsDisabled}
				>
					<FontAwesomeIcon
						icon='greater-than'
						color={buttonsDisabled ? disabledButtonColor : buttonColor}
					/>
				</Button>
			</Tooltip>
			<Tooltip title={utils.translate('componentLogicalOperatorBarItem.equal')} placement='bottom'>
				<Button
					onClick={() => setSelectedOperator(LogicalOperatorModel.EQUAL_TO.value)}
					type={isEquals ? 'dashed' : 'default'}
					shape='circle'
					ghost
					style={buttonStyle}
				>
					<FontAwesomeIcon
						icon='equals'
						color={buttonColor}
					/>
				</Button>
			</Tooltip>
		</>
	)
};


export default LogicalOperatorBarItem;
