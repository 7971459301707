import React from 'react';
import { Tooltip, Button, Icon, Popconfirm } from 'antd';
import { Row } from 'reactstrap';

import { request } from 'Lib/request';
import { utils } from 'Lib/utils';


const BulkDelete = (props) =>
{

	const {
		dpgId,
		pageIsEmpty,
		api_getWidgets,
	} = props;


	const deleteWidgets = () =>
	{
		request.delete(
            'widget',
            { page_id: dpgId },
            () =>
			{
				api_getWidgets();

				utils.openNotification(
					'success', 
					utils.translate('componentBulkDelete.success1'),
					utils.translate('componentBulkDelete.success2'),
					'bottomRight', 5
				);
			},
            () =>
			{
				utils.openNotification(
					'error',
					utils.translate('componentBulkDelete.error1'),
					utils.translate('generic.rights'),
					'bottomRight', 5
				);
			},
        );
	}


	const popconfirmTitle = (
		<span>
			{utils.translate('componentBulkDelete.popconfirmTitle1')}
			<b>{utils.translate('componentBulkDelete.popconfirmTitle2')}</b>
		</span>
	);


	return (

		<Tooltip
			placement='left'
			title={pageIsEmpty ? utils.translate('componentBulkDelete.pageIsEmpty') : ''}
		>
			<Popconfirm
				placement='rightTop'
				icon={<Icon type='question-circle-o' style={{ color: 'red' }}/>}
				title={popconfirmTitle}
				onConfirm={deleteWidgets}
				okText={utils.translate('generic.yes')}
				cancelText={utils.translate('generic.no')}
				disabled={pageIsEmpty}
			>
				<Row>
					<Button
						type={pageIsEmpty ? 'disabled' : 'primary'}
						className='drawerButton-category'
					>
						<Icon
							className='drawerIcon-category'
							type='close-circle'
						/>
						{utils.translate('componentBulkDelete.clearPage')}
					</Button>{' '}
				</Row>
			</Popconfirm>
		</Tooltip>
	)
};


export default BulkDelete;
