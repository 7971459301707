import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';

import EmptyWidget from '../../WidgetLib/EmptyWidget/EmptyWidget';
import Loader from '../../WidgetLib/Loader/Loader';
import NoData from '../../WidgetLib/NoData/NoData';

import { request } from 'Lib/request.js';
import { utils } from 'Lib/utils.js';


class Gauge extends Component
{

    constructor(props)
	{
        super(props);

        this.state = {
            data: {},
            config: {},
			loading: false,
            options: this.getDefaultOptions()
        };

		this.timerId = null;
		this.componentIsMounted = false;
    }


    componentDidMount()
	{
		this.componentIsMounted = true;

		if (!this.props.widget.CFG_ID) { return; }

        this.timerId = utils.setRefresh(this.props.widget.DWG_REFRESH_FREQ, this.api_updateGraph);

        this.api_updateGraph();
    }


    componentDidUpdate(prevProps)
	{
        if (prevProps.widget.DVC_ID !== this.props.widget.DVC_ID ||
			prevProps.widget.CFG_ID !== this.props.widget.CFG_ID ||
			prevProps.widget.DWU_MIN !== this.props.widget.DWU_MIN ||
			prevProps.widget.DWU_MAX !== this.props.widget.DWU_MAX ||
			prevProps.widget.DWU_COLORS !== this.props.widget.DWU_COLORS ||
			JSON.stringify(prevProps.widget.JWC) !== JSON.stringify(this.props.widget.JWC))
			{
				this.api_updateGraph();
			}

        if (this.refs.chart && utils.didWidgetResize(prevProps, this.props)) { this.refs.chart.getChart().reflow(); }
    }


    componentWillUnmount()
	{
		this.componentIsMounted = false;
        if (this.timerId !== null) { utils.clearRefresh(this.timerId); }
    }


    api_updateGraph = () =>
	{
        let configId = this.props.widget.CFG_ID;
        let dataLoaded = false;
        let configLoaded = false;

		if (configId && configId !== 'none')
		{
			this.setState({ loading: true }, () =>
			{
				request.get(
					'data/' + configId,
					{
						data_type: 'last_data'
					},
					(data) =>
					{
						if (this.componentIsMounted)
						{
							this.setState({
								data: data[0].data === "" ? undefined : data[0]
							}, () =>
							{
								if (configLoaded)
								{
									this.setGaugeOptions();
								}
							});
						}
					},
					() => console.error('load data failed'),
					() => dataLoaded = true
				);

				request.get(
					'config/' + configId,
					{},
					(config) => {
						if (this.componentIsMounted)
						{
							this.setState({
								config: config[configId]
							}, () =>
							{
								if (dataLoaded)
								{
									this.setGaugeOptions();
								}
							});
						}
					},
					() => console.error('load config failed'),
					() => { configLoaded = true; this.setState({ loading: false }); }
				);
			});
		}
		else
		{
			this.setState({
				config: {}
			});
		}
    }


    setGaugeOptions = () =>
	{
        let series = [];
		const colors = this.props.widget.DWU_COLORS ? this.props.widget.DWU_COLORS.split(',') : ["#28a745", "#dddf0d", "#ff5369"];

		let configId = this.state.config.id;
		let configUnit = this.props.widget.JWC[configId] && this.props.widget.JWC[configId].JWC_UNIT !== '' ? this.props.widget.JWC[configId].JWC_UNIT : this.state.config.unite_D1 ? this.state.config.unite_D1 : '';
		let configLabel = this.props.widget.JWC[configId] && this.props.widget.JWC[configId].JWC_LABEL !== '' ? this.props.widget.JWC[configId].JWC_LABEL : this.state.config.label_D1;

		let coeffA = this.props.widget.JWC[configId] ? this.props.widget.JWC[configId].JWC_COEFFA : 1;
		let coeffB = this.props.widget.JWC[configId] ? this.props.widget.JWC[configId].JWC_COEFFB : 0;
		let configValue = !this.state.data || !this.state.data.data ? undefined : parseFloat(coeffA) * parseFloat(this.state.data.data) + parseFloat(coeffB);
		configValue = !this.state.data || this.state.data.data === undefined ? undefined : this.state.data.data === 0 ? this.state.data.data : Number(configValue.toFixed(2)); // cast to Number removes insignificant trailing zeroes
		const mini = parseInt(this.props.widget.DWU_MIN);
		const maxi = parseInt(this.props.widget.DWU_MAX);
		let coef = maxi === mini ? 1 : (configValue - mini) / (maxi - mini); //eviter division par 0
        if (!this.state.data) {
            series = [{
                data: [0]
            }];
        }

        series = [{
            name: configLabel,
            data: [
				{
					color: {
						linearGradient: {
							x1: 0,
							x2: 1/coef,
							y1: 0,
							y2: 0
						},
						stops: [
							[0.1, colors[0]],
		                    [0.5, colors[1]],
		                    [0.9, colors[2]]
						]
					},
					y: configValue,
      			}
			],
            dataLabels: {
                format: '<div style="text-align:center"><span style="font-size:16px;color:' +
                    ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y}</span></br>' +
                       '<span style="font-size:12px;color:silver">' + configUnit + '</span></div>',
			   	y: 30
            },
            tooltip: {
                valueSuffix: ' ' + configUnit
            }
        }];

        let options = this.getDefaultOptions();
        options.series = series;

        this.setState({ options: options });
    }


    getDefaultOptions = () =>
	{
		let configLabel = this.state && this.props.widget.JWC[this.state.config.id] && this.props.widget.JWC[this.state.config.id].JWC_LABEL !== '' ? this.props.widget.JWC[this.state.config.id].JWC_LABEL : this.state && this.state.config ? this.state.config.label_D1 : '';
		configLabel = this.state && this.state.config && utils.getValueFromTags(configLabel, this.state.config.device);

		const title = this.state && this.state.data && this.state.data.data ? this.state.config.device.label + '<br><b>' + configLabel + '<b>' : null;
		const min = parseInt(this.props.widget.DWU_MIN);
		const max = parseInt(this.props.widget.DWU_MAX);

        return {

            chart: {
                type: 'solidgauge',
				backgroundColor: null
            },
            title: null,
            pane: {
                center: ['50%', '85%'],
                size: '140%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },
            tooltip: {
                enabled: false
            },
            // the value axis
            yAxis: {
                lineWidth: 0,
                minorTickInterval: null,
                title: {
					text: title,
					y: 25
                },
                labels: {
                    y: 16
                },
				tickPositioner: function() {
				  return [this.min, this.max];
			  	},
                min: min,
                max: max,
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            credits: {
                enabled: false
            }
        };
    }


    render()
	{
        HighchartsMore(ReactHighcharts.Highcharts);
        SolidGauge(ReactHighcharts.Highcharts);

		const { config, loading } = this.state;
		const datatypeExists = Object.entries(config).length !== 0;
		const showEmptyWidget = !loading && utils.isConfigMode() && !datatypeExists;

        return (

			<>
				{showEmptyWidget &&
				<EmptyWidget
					{...this.props}
					callToAction={utils.translate('componentWidgetGauge.start')}
				/>}

				{loading &&
				<Loader style={{ marginTop: `${this.props.size.height / 2}px` }}/>}

				{datatypeExists && !loading &&
				<div 
					id={this.props.getWidgetId + '_CONTENT'}
					className={'highcharts-wrapper CONTENT' + this.props.getWidgetClass + this.props.getWidgetClass + '_CONTENT'}
					style={{height: `${this.props.size.height}px`}}
				>
	                <ReactHighcharts
	                    isPureConfig
	                    config={this.state.options}
	                    ref="chart"
	                />
	            </div>}
				{this.props.widget.CFG_ID === 'none' &&
				<>
					<NoData></NoData>
				</>}
			</>
        )
    }
}


export default Gauge;
