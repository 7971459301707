import React from 'react';
import { Divider, Icon, Row } from 'antd';

import HelpTag from 'Components/Studio/Dashboard/Widgets/WidgetLib/HelpTag/HelpTag';

import { iconCustomizationInfo } from 'Components/Studio/StudioLib/GenericHTML/GenericText.js';
import { utils } from 'Lib/utils';

const IconHelpTag = () =>
{

	const infoIcon = 'info-circle';
	const rowStyle = {
		padding: 16,
		width: 400
	};


	const tagInfoText = () =>
	{
		return (
			<Row style={rowStyle}>
				<p>
					<Icon type={infoIcon} className='formPHIcon'/>
					<b>{utils.translate('componentIconHelpTag.about1')}</b>
				</p>
				<Divider/>
				{iconCustomizationInfo}
			</Row>
		);
	};


	return (

		<p style={{ marginTop: 10 }}>
			<HelpTag
				tagText={utils.translate('componentIconHelpTag.about2')}
				popoverContent={tagInfoText()}
				iconType={infoIcon}
				color='green'
				popoverPlacement='bottom'
			/>
		</p>
	);
};


export default IconHelpTag;
