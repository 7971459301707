import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { InputNumber } from 'antd';

import LabelItem from '../../FormItems/LabelItem/LabelItem';
import { utils } from 'Lib/utils';


const PageSizeSection = (props) =>
{

	return (

		<FormGroup>
			<Row>
				<LabelItem
					iconType='number'
					label={utils.translate('componentPageSizeSection.pageSize')}
				/>
				<Col md={{size: 9}}>
					<InputNumber
						min={5}
						max={25}
						defaultValue={5}
						value={props.getCurrentPageSize}
						name='page_size'
						onChange={props.handlePageSizeChange}
						style={{ width: '100%' }}
					/>
				</Col>
			</Row>
		</FormGroup>
	)
};


export default PageSizeSection;
