import React from 'react';
import { Icon } from 'antd';
import { utils } from 'Lib/utils';

const containerIcon = 'container';
const categoryIcon = 'deployment-unit';
const deviceIcon = 'mobile';
const datatypeicon = 'api';
const attributeIcon = 'unordered-list';
const tableIcon = 'table';
const markerIcon = 'environment';
const commandIcon = 'cloud-upload';
const selectOptionIcon = 'bars';
const nameIcon = 'tag';
const geoZoneIcon = 'compass';
const groupIcon = 'apartment';

const callToAction = utils.translate('placeHolders.callToAction');

export const geoZonePH = <span><Icon className='formPHIcon' type={geoZoneIcon}/>{utils.translate('genericText.geoZonePH')}{callToAction}</span>;
export const containerPH = <span><Icon className='formPHIcon' type={containerIcon}/>{utils.translate('placeHolders.containerPH')}</span>;
export const categoryPH = <span><Icon className='formPHIcon' type={categoryIcon}/>{utils.translate('placeHolders.categoryPH')}</span>;
export const groupPH = <span><Icon className='formPHIcon' type={groupIcon}/>{utils.translate('placeHolders.groupPH')}</span>;
export const oneDevicePH = <span><Icon className='formPHIcon' type={deviceIcon}/>{utils.translate('placeHolders.oneDevicePH')}{callToAction}</span>;
export const oneDatatypePH = <span><Icon className='formPHIcon' type={datatypeicon}/>{utils.translate('placeHolders.oneDatatypePH')}{callToAction}</span>;
export const oneAttributePH = <span><Icon className='formPHIcon' type={attributeIcon}/>{utils.translate('placeHolders.oneAttributePH')}{callToAction}</span>;
export const oneGroupPH = <span><Icon className='formPHIcon' type={groupIcon}/>{utils.translate('placeHolders.oneGroupPH')}{callToAction}</span>;

export const multipleDevicesPH = <span><Icon className='formPHIcon' type={deviceIcon}/>{utils.translate('placeHolders.multipleDevicesPH')}{callToAction}</span>;
export const multipleDatatypesPH = <span><Icon className='formPHIcon' type={datatypeicon}/>{utils.translate('placeHolders.multipleDatatypesPH')}{callToAction}</span>;

export const multipleContainersPH = <span><Icon className='formPHIcon' type={containerIcon}/>{utils.translate('placeHolders.multipleContainersPH')}{callToAction}</span>;
export const multipleAttributesPH = <span><Icon className='formPHIcon' type={attributeIcon}/>{utils.translate('placeHolders.multipleAttributesPH')}{callToAction}</span>;
export const multipleCategoriesPH = <span><Icon className='formPHIcon' type={categoryIcon}/>{utils.translate('placeHolders.multipleCategoriesPH')}{callToAction}</span>;
export const multipleGroupsPH = <span><Icon className='formPHIcon' type={groupIcon}/>{utils.translate('placeHolders.multipleGroupsPH')}{callToAction}</span>;

export const tableTypePH = <span><Icon className='formPHIcon' type={tableIcon}/>{utils.translate('placeHolders.tableTypePH')}</span>;
export const markerTypePH = <span><Icon className='formPHIcon' type={markerIcon}/>{utils.translate('placeHolders.markerTypePH')}</span>;

export const oneCommandPH = <span><Icon className='formPHIcon' type={commandIcon}/>{utils.translate('placeHolders.oneCommandPH')}</span>;
export const selectOptionPH = <span><Icon className='formPHIcon' type={selectOptionIcon}/>{utils.translate('placeHolders.selectOptionPH')}</span>;

export const widgetNamePrefix = <Icon className='formPHIcon' type={nameIcon} style={{color: '#C4C4C4'}}/>;

export function dynamicOneDevicePH(categoryIsEmpty, category, color) {
	return <div><span><Icon className='formPHIcon' type={categoryIcon}/></span><span style={{color: color}}>{category}</span><span> | {categoryIsEmpty ? utils.translate('placeHolders.dynamicOneDevicePH.1') : utils.translate('placeHolders.dynamicOneDevicePH.2')} {!categoryIsEmpty && <span>{callToAction}</span>}</span></div>
};

export const oneCategoryPH = <span><Icon className='formPHIcon' type={categoryIcon}/>{utils.translate('placeHolders.oneCategoryPH')}{callToAction}</span>;
export const oneContainerPH = <span><Icon className='formPHIcon' type={containerIcon}/>{utils.translate('placeHolders.oneContainerPH')}{callToAction}</span>;
export const skinPH = <span><Icon className='formPHIcon' type={selectOptionIcon}/>{utils.translate('placeHolders.skinPH')}{callToAction}</span>;
