import React, { Component } from 'react';

import './SitemapMarker.css';

import StatusMarker from './StatusMarker/StatusMarker';
import AlarmMarker from './AlarmMarker/AlarmMarker';


class SitemapMarker extends Component {


    render() {

        return (

            <>
	            {this.props.widget.DSM_TYPE === 'DSM_status' &&
	                <StatusMarker
	                    {...this.props}
	                />
				}
				{this.props.widget.DSM_TYPE === 'DSM_alarm' &&
	                <AlarmMarker
	                    {...this.props}
	                />
				}
            </>
        );
    }


}


export default SitemapMarker;
