import React from 'react';
import { Button, Tooltip } from 'antd';

import { OperatorModel } from 'Models/OperatorModel.js';
import { utils } from 'Lib/utils';

const buttonStyle = { marginRight: 10 };


const OperatorBarItem = (props) =>
{

	const isTimes = props.selectedOperator === OperatorModel.TIMES;
	const isDiv = props.selectedOperator === OperatorModel.DIV;
	const isPlus = props.selectedOperator === OperatorModel.PLUS;
	const isMinus = props.selectedOperator === OperatorModel.MINUS;

	return (

		<>
			<Tooltip title={utils.translate('componentOperatorBarItem.multiply')} placement='left'>
				<Button
					onClick={() => props.setSelectedOperator(OperatorModel.TIMES)}
					type={isTimes ? 'primary' : 'default'}
					shape='circle'
					icon='close'
					ghost={isTimes}
					style={buttonStyle}
				/>
			</Tooltip>
			<Tooltip title={utils.translate('componentOperatorBarItem.divide')}>
			<Button
				onClick={() => props.setSelectedOperator(OperatorModel.DIV)}
				type={isDiv ? 'primary' : 'default'}
				shape='circle'
				icon='check'
				ghost={isDiv}
				style={buttonStyle}
			/>
			</Tooltip>
			<Tooltip title={utils.translate('componentOperatorBarItem.add')}>
				<Button
					onClick={() => props.setSelectedOperator(OperatorModel.PLUS)}
					type={isPlus ? 'primary' : 'default'}
					shape='circle'
					icon='plus'
					ghost={isPlus}
					style={buttonStyle}
				/>
			</Tooltip>
			<Tooltip title={utils.translate('componentOperatorBarItem.subtract')} placement='right'>
				<Button
					onClick={() => props.setSelectedOperator(OperatorModel.MINUS)}
					type={isMinus ? 'primary' : 'default'}
					shape='circle'
					icon='minus'
					ghost={isMinus}
					style={buttonStyle}
				/>
			</Tooltip>
		</>
	)
};


export default OperatorBarItem;
