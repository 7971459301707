import React from 'react';
import { Divider } from 'antd';

import { CURRENT_VERSION } from 'Config/config';


const VersionSpan = (props) =>
{
	return (
		<>
			<Divider/>
			<div style={{ textAlign: 'center' }}>
				<small><i>Version {CURRENT_VERSION}</i></small>
			</div>
		</>
	)
};


export default VersionSpan;
