import React from 'react';
import { Card, Row, Col, Slider } from 'antd';
import { utils } from 'Lib/utils';
import { request } from 'Lib/request';
import PropTypes from "prop-types";


const MarginSliders = (props) =>
{

	const {
		dapId,
		dpgId,
		currentPage,
		loadPage,
	} = props;


	const handleMarginChange = (margin, axis) =>
	{
		request.patch(
			'application/' + dapId,
			{
				[axis]: margin,
			},
			(success) => loadPage(dpgId),
			(error) => console.error(error),
			() => {},
		);
	}


	return (

		<Card style={{ width: '100%' }}>
			<Row>
				<Col
					span={11}
				>
					{utils.translate('componentMarginSlider.margin')} X
					<Slider
						defaultValue={parseInt(currentPage.DAP_WGT_MARGIN_X)}
						onAfterChange={(value) =>
							handleMarginChange(value, 'widget_margin_X')}
						min={0}
						max={30}
						marks={{ 0: '0px', 30: '30px' }}
					/>
				</Col>
				<Col
					span={11}
					offset={2}
				>
					{utils.translate('componentMarginSlider.margin')} Y
					<Slider
						defaultValue={parseInt(currentPage.DAP_WGT_MARGIN_Y)}
						onAfterChange={(value) =>
							handleMarginChange(value, 'widget_margin_Y')}
						min={0}
						max={30}
						marks={{ 0: '0px', 30: '30px' }}
					/>
				</Col>
			</Row>
		</Card>
	);
};

MarginSliders.propTypes = {
	dapId: PropTypes.string.isRequired,
	loadPage: PropTypes.func.isRequired,
	dpgId: PropTypes.string.isRequired,
	currentPage: PropTypes.object.isRequired
};


export default MarginSliders;
