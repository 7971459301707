import React, { Component } from 'react';
import { Row, Col, FormGroup, Form } from 'reactstrap';
import LabelItem from '../../FormItems/LabelItem/LabelItem';
import { Divider } from 'antd';
import { withTranslation } from 'react-i18next';

import IconPicker from '../../../WidgetLib/IconPicker/IconPicker';
import TypeSection from '../../FormSections/TypeSection/TypeSection';
import DeviceSection from '../../FormSections/DeviceSection/DeviceSection';

import { oneDevicePH, markerTypePH } from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';
import { categoryDashboardSitemapInfo } from 'Components/Studio/StudioLib/GenericHTML/GenericText.js';
import { utils } from 'Lib/utils';

class SitemapMarker extends Component {

	constructor(props) {
		super(props);

		this.state = {
			devices: [],
			selectedDevices: [],
			categoryFilter: [],
			containerFilter: [],
			groupFilter: [],
			icon: ''
		}
	}

	options = [
		{
			key: 'Status',
			value: 'DSM_status',
			label: this.props.t('componentWidgetSitemap.Marker.selectOptions.device.label'),
			title:  this.props.t('componentWidgetSitemap.Marker.selectOptions.device.title'),
		},
		{
			key: 'Alarm',
			value: 'DSM_alarm',
			label: this.props.t('componentWidgetSitemap.Marker.selectOptions.alarm.label'),
			title: this.props.t('componentWidgetSitemap.Marker.selectOptions.alarm.title'),
		}
	];


	componentDidMount = () => {
		if (this.props.devices !== undefined && this.props.devices.length > 0) {
			this.setDevicesToState();
		}
		this.setIconToState();
		this.setState({ selectedDevices: this.props.widget.DVC_ID });
	}


	componentDidUpdate(prevProps, prevState) {
		if (prevProps.devices !== undefined && prevProps.devices !== this.props.devices) {
			this.setDevicesToState();
		}
	}


	setDevicesToState = () => {
		let devices = [...this.props.devices];

		if (this.props.isCategoryDashboard && this.props.category !== undefined && devices !== undefined) {
			devices = devices.filter(device => device.category_id === this.props.category.id);
		}

		this.setState({ devices: devices });
	}


	setIconToState= () => {
		this.setState({icon: this.props.widget ? // if something is set in configuration, user has changed input values
		this.props.widget.DSM_ICON : 'marker'})
	}


	handleDeviceChange = (value) => {
		this.setState({ deviceEdited: true });

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.device_id = value;

		this.props.saveConfiguration(currentConfiguration);
	}


	handleTypeChange = (value) => {
		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.marker_type = value;
		this.props.saveConfiguration(currentConfiguration);
	}


	getCurrentConfiguration = () => {
		return {
			type: "sitemapMarker", // widget type
			marker_type: this.getCurrentType(), // marker type
			device_id: this.getCurrentDeviceId(), // device id
			icon: this.getCurrentIcon()
		}
	}


	getCurrentDeviceId = () => {
		return this.props.configuration.device_id ? // if something is set in configuration, user has changed input values
			this.props.configuration.device_id : // configuration is the source of truth
			(this.props.widget.DVC_ID && !this.state.containerEdited ? // if not check if current widget has device ids, meaning we are editing an existing widget
				this.props.widget.DVC_ID :
				undefined
			);
	}


	getCurrentIcon = () => {
		return this.props.widget.widget ? // if something is set in configuration, user has changed input values
		this.props.widget.widget.DSM_ICON : 'marker'
	}


	getCurrentType = () => {
		return this.props.configuration.marker_type ? this.props.configuration.marker_type : // same as ()
			(this.props.widget.DSM_TYPE ? this.props.widget.DSM_TYPE : "DSM_status");
	}


	setIconToParent = (icon, device) => {
		this.setState({ icon: icon });

		let currentConfiguration = this.getCurrentConfiguration();
		currentConfiguration.icon = icon;

		this.props.saveConfiguration(currentConfiguration);
	}

	render() {
		const entries = this.state.devices.length;
		return (

			<Form>

				<Divider />

				<TypeSection
					iconType='environment'
					label={utils.translate('generic.markerType')}
					selectName='markerType'
					options={this.options}
					selectValue={this.getCurrentType()}
					onChange={this.handleTypeChange}
					selectPlaceholder={markerTypePH}
				/>

				<Divider />

				{this.props.isCategoryDashboard &&
					<div>
						{categoryDashboardSitemapInfo}
						<Divider />
					</div>}

				<DeviceSection
					label={utils.translate('generic.dvc')}
					entries={entries}
					deviceSelectPlaceholder={oneDevicePH}
					devices={this.state.devices}
					categories={this.props.categories}
					containers={this.props.containers}
					groups={this.props.groups}
					selectedDevices={this.state.selectedDevices}
					categoryFilter={this.props.categoryFilter}
					containerFilter={this.props.containerFilter}
					groupFilter={this.props.groupFilter}
					loading={this.props.loading}
					disabled={this.props.isCategoryDashboard || this.props.loading}
					handleDeviceChange={this.handleDeviceChange}
					handleCategoryFilterChange={this.props.handleCategoryFilterChange}
					handleContainerFilterChange={this.props.handleContainerFilterChange}
					handleGroupFilterChange={this.props.handleGroupFilterChange}
					api_getDevices={this.props.api_getDevices}
				/>

				<Divider />
				{this.getCurrentType() === 'DSM_status' &&
				<FormGroup>
					<Row>
						<LabelItem
							iconType='picture'
							label={utils.translate('componentWidgetMapConfigure.marker')}
						/>
						<Col md={{size: 9}}>
							<IconPicker
								{...this.props}
								initialIcon={this.state.icon}
								disabled={false}
								setIconToParent={this.setIconToParent}
							/>
						</Col>
					</Row>
				</FormGroup>}
			</Form>
		);
	}
}


export default withTranslation()(SitemapMarker);
