import React from 'react';
import { Tabs, Icon, Tooltip } from 'antd';

import DeviceSection from '../FormSections/DeviceSection/DeviceSection';
import CategorySection from '../FormSections/CategorySection/CategorySection';
import ContainerSection from '../FormSections/ContainerSection/ContainerSection';
import GroupSection from '../FormSections/GroupSection/GroupSection';

import { utils } from 'Lib/utils';
import {
	multipleDevicesPH,
	multipleCategoriesPH,
	multipleContainersPH,
	multipleGroupsPH
} from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js'

const { TabPane } = Tabs;


const DeviceSelection = (props) =>
{

    const {
        activeKey,
        devicePaneDisabled,
        categoryPaneDisabled,
        containerPaneDisabled,
        groupPaneDisabled,
        unselect,
        entries,
        loading,
        currentDeviceIds,
        allDevices
    } = props;

    
    return (
        <Tabs
            tabPosition='left'
            activeKey={activeKey}
            onChange={(key) => props.handleActiveKeyChange(key)}
        >
            <TabPane
                tab={
                    <Tooltip
                        title={
                            devicePaneDisabled ? utils.translate('componentWidgetTableConfigure.' + unselect) : ''
                        }
                    >
                        <span>
                            <Icon type='mobile' className='greyIcon'/>
                            Devices
                        </span>
                    </Tooltip>
                }
                key='data_source_1'
                disabled={devicePaneDisabled}
            >
                <DeviceSection
                    {...props}
                    label={utils.translate('generic.dvcs')}
                    deviceSelectMode='multiple'
                    deviceSelectRef={allDevices}
                    entries={entries}
                    deviceSelectPlaceholder={multipleDevicesPH}
                    selectedDevices={currentDeviceIds}
                    disabled={loading}
                    allowClearDeviceSelect={true}
                    handleDeviceChange={props.handleDeviceChange}
                />
            </TabPane>
            <TabPane
                forceRender
                tab={
                    <Tooltip
                        title={
                            categoryPaneDisabled ? utils.translate('componentWidgetTableConfigure.' + unselect) : ''
                        }
                    >
                        <span>
                            <Icon type='deployment-unit' className='greyIcon'/>
                            Categories
                        </span>
                    </Tooltip>
                }
                key='data_source_2'
                disabled={categoryPaneDisabled}
            >
                <CategorySection
                    {...props}
                    iconType='deployment-unit'
                    label={utils.translate('componentWidgetTableConfigure.cats')}
                    categorySelectMode='multiple'
                    categorySelectPlaceholder={multipleCategoriesPH}
                    handleCategoryChange={props.handleCategoryChange}
                />
            </TabPane>

            <TabPane
                forceRender
                tab={
                    <Tooltip
                        title={
                            containerPaneDisabled ? utils.translate('componentWidgetTableConfigure.' + unselect) : ''
                        }
                    >
                        <span>
                            <Icon type='container' className='greyIcon'/>
                            Containers
                        </span>
                    </Tooltip>
                }
                key='data_source_3'
                disabled={containerPaneDisabled}
            >
                <ContainerSection
                    {...props}
                    // {...this.state}
                    iconType='container'
                    label={utils.translate('componentWidgetTableConfigure.ctrs')}
                    containerSelectMode='multiple'
                    containerSelectPlaceholder={multipleContainersPH}
                    handleContainerChange={props.handleContainerChange}
                />
            </TabPane>

            <TabPane
                forceRender
                tab={
                    <Tooltip
                        title={
                            groupPaneDisabled ? utils.translate('componentWidgetTableConfigure.' + unselect) : ''
                        }
                    >
                        <span>
                            <Icon type='apartment' className='greyIcon'/>
                            Groups
                        </span>
                    </Tooltip>
                }
                key='data_source_4'
                disabled={groupPaneDisabled}
            >
                <GroupSection
                    {...props}
                    iconType='apartment'
                    label={utils.translate('componentWidgetTableConfigure.grps')}
                    groupSelectMode='multiple'
                    groupSelectPlaceholder={multipleGroupsPH}
                    handleGroupChange={props.handleGroupChange}
                />
            </TabPane>

        </Tabs>
    );
}

export default DeviceSelection;