import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { Select } from 'antd';

import SuperSelect from 'Components/Studio/StudioLib/SuperSelect/SuperSelect';
import LabelItem from '../../FormItems/LabelItem/LabelItem';

import { geoZonePH } from 'Components/Studio/StudioLib/GenericHTML/PlaceHolders.js';

const { Option } = Select;


const GeoZoneSection = (props) =>
{
	return (

		<>
			<FormGroup>
				<Row>
					<LabelItem
						iconType='compass'
						label={props.selectLabel}
					/>
					<Col md={{size: 9}}>
						<Row>
							<Col md={{size: 12}}>
								<SuperSelect
									ref={props.geoZoneSelectRef}
									value={props.selectedGeoZones}
									onChange={props.handleGeoZoneChange}
									showSearch
									showArrow
									allowClear={true}
									optionFilterProp='children'
									style={{ width: '100%' }}
									placeholder={geoZonePH}
									mode='multiple'
									maxTagCount={5}
									disabled={props.disabled}
									filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
								{props.geoZones.map((geoZone) =>
									<Option key={geoZone.id} value={geoZone.id}>{geoZone.label}</Option>
								)}
								</SuperSelect>
							</Col>
						</Row>
					</Col>
				</Row>
			</FormGroup>
		</>
	);
};


export default GeoZoneSection;

// <FormGroup>
// 	<Row>
// 		<LabelItem/>
// 		<Col md={{size: 9}}>
// 			<Row>
// 				<Col md={{size: 6}}>
// 					<Switch
// 						size='small'
// 						checked={props.addGeoZoneDevices}
// 						onChange={props.handleAddGeoZoneDevicesChange}
// 					/><span style={{ marginLeft: '10px' }}><small>Auto-add related Devices</small></span>
// 				</Col>
// 				<Col md={{size: 6}}>
// 					<Switch
// 						size='small'
// 						checked={props.removeGeoZoneDevices}
// 						onChange={props.handleRemoveGeoZoneDevicesChange}
// 					/><span style={{ marginLeft: '10px' }}><small>Auto-remove related Devices</small></span>
// 				</Col>
// 			</Row>
// 		</Col>
// 	</Row>
// </FormGroup>
