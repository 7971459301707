import React from 'react';

const legendSpanStyle = {
    width: 20,
	height: 20,
    verticalAlign: 'middle',
	display: 'inline-block',
    borderRadius: 20,
    boxShadow: 'none',
    marginRight: 10,
    marginLeft: 5,
	padding: 2,
}


const ColorPreview = (props) =>
{

	const {
		backgroundColor,
	} = props;


	return (

		<span
			style={{
				...legendSpanStyle,
				backgroundColor: backgroundColor,
			}}
		/>
	);
};


export default ColorPreview;

// import ColorPreview from 'Components/Studio/Dashboard/Widgets/WidgetLib/ColorPreview/ColorPreview';
