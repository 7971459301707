import React from 'react';
import { Row, Collapse } from 'antd';

import ColorPreview from 'Components/Studio/Dashboard/Widgets/WidgetLib/ColorPreview/ColorPreview';

import { LegendModel } from 'Models/LegendModel';
import { utils } from 'Lib/utils.js';

const { Panel } = Collapse;

const defaultColors = {
	GREEN: 'rgb(31, 189, 67)',
	RED: 'rgba(255, 83, 105, 0.85)',
	GRAY: 'rgba(209, 207, 207, 0.9)',
	BLACK: 'rgba(0, 0, 0, 0.9)',
};


const Legend = (props) =>
{

	const {
		widget,
		top,
		bottom,
		right,
		left,
	} = props;

	const legendStyle = {
		position: 'absolute',
		top: top,
		bottom: bottom,
		right: right,
		left: left,
		maxWidth: 275,
		textAlign: 'left',
		background: 'rgb(255, 255, 255, 0.85)',
		boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
	};

	const rowStyle = {
		overflow: 'hidden',
    	whiteSpace: 'nowrap',
    	textOverflow: 'ellipsis',
    	padding: 5,
	};

	const legendModelValues = Object.values(LegendModel);

	const showDynamicLegend = widget.DTH_THRESHOLD_VALUE && widget.DTH_OPERATOR &&
		widget.DTH_ACTIVE_COLOR && widget.DTH_INACTIVE_COLOR && ((widget.ATR_IDS &&
			widget.ATR_IDS.length) || (widget.DTY_IDS && widget.DTY_IDS.length));

	const showLegend = !showDynamicLegend || legendModelValues.some(v => widget[v.legendValue] !== null);


	const getDynamicLegend = () =>
	{
		return legendModelValues.map((value, index) =>
			widget[value.legendValue] &&
			<Row key={index} style={rowStyle}>
				<ColorPreview backgroundColor={widget[value.colorValue]}/>
				{widget[value.legendValue]}
			</Row>
		);
	}


	const getStaticLegend = () =>
	{
		return (
			<>
				<Row style={rowStyle}>
					<ColorPreview backgroundColor={defaultColors.GREEN}/>
					{utils.translate('componentLegend.working')}
				</Row>
				<Row style={rowStyle}>
					<ColorPreview backgroundColor={defaultColors.RED}/>
					{utils.translate('componentLegend.stopped')}
				</Row>
				<Row style={rowStyle}>
					<ColorPreview backgroundColor={defaultColors.GRAY}/>
					{utils.translate('componentLegend.unavailable')}
				</Row>
				{props.haveGeoAlternative && !props.showGeolocOnly &&
					<Row style={rowStyle}>
						<ColorPreview backgroundColor={defaultColors.BLACK}/>
						{utils.translate('componentLegend.alternative')}
					</Row>
				}
			</>
		);
	}


	return showLegend ? (

		<Collapse
			style={legendStyle}
		>
			<Panel
				header={utils.translate('componentLegend.legend')}
				key='1'
			>
				{showDynamicLegend ? getDynamicLegend() : getStaticLegend()}
			</Panel>
		</Collapse>
	) : null;
};


export default Legend;

// import Legend from 'Components/Studio/Dashboard/Widgets/WidgetLib/Legend/Legend';
