export const LegendModel = {
	INACTIVE: {
		legendValue: 'DTH_INACTIVE_LEGEND',
		colorValue: 'DTH_INACTIVE_COLOR',
	},
	ACTIVE: {
		legendValue: 'DTH_ACTIVE_LEGEND',
		colorValue: 'DTH_ACTIVE_COLOR',
	},
	DEFAULT: {
		legendValue: 'DTH_DEFAULT_LEGEND',
		colorValue: 'DTH_DEFAULT_COLOR',
	},
};

// import { LegendModel } from 'Models/LegendModel';
