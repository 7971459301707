import React from 'react';
import { Spin } from 'antd';


const Loader = (props) =>
{

	const {
		style,
		spinSize,
	} = props;


	return (

		<div className='spinContainer' style={style}>
			<Spin size={spinSize}/>
		</div>
	)
};


export default Loader;

// import Loader from 'Components/Studio/Dashboard/Widgets/WidgetLib/Loader/Loader';
