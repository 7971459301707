import React, { Component } from 'react';
import { Row, FormGroup, Col } from 'reactstrap';
import { Drawer, Button, Icon, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AttributeEditor from './AttributeEditor/AttributeEditor';
import BulkDelete from './BulkDelete/BulkDelete';
import ThemeBuilder from './ThemeBuilder/ThemeBuilder';
import CategorySelectorModal from './CategorySelector/CategorySelectorModal/CategorySelectorModal';
import VersionSpan from './VersionSpan/VersionSpan';

import { utils } from 'Lib/utils';

import './ConfigDrawer.css';

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toggleThemeBuilder, toggleDrawer } from "../../../../actions/drawer";


class ConfigDrawer extends Component
{

	constructor(props)
	{
        super(props);
		
        this.state = {
			modalIsVisible: false,
			removingCategory: false,
        }
    }


	openModal = (action) =>
	{
		this.setState({
			modalIsVisible: true,
			removingCategory: action === 'remove',
		});
	}


	closeModal = () =>
	{
		this.setState({ modalIsVisible: false });
	}


    render()
	{
		const drawerTitle = <span>
			 <FontAwesomeIcon icon="cogs" style={{marginRight: "10px"}}/>{utils.translate('componentHeaderButtons.appSetting')}
		</span>

		const canEdit = this.props.widgets && this.props.widgets.length === 0;

        return (

			<>
				<Drawer
					title={drawerTitle}
					placement="right"
					closable={true}
					onClose={this.props.toggleDrawer}
					visible={this.props.isDrawerVisible}
					width={utils.isMobileVersion(window) ? '85vw' : '35vw'}
					zIndex='1002'
					className='config-drawer'
				>
					<FormGroup>
						<Tooltip placement="left" title={canEdit ? "" : utils.translate('componentConfigDrawer.clearAlert')}>
							<Row>
								<Button
									type={canEdit || this.props.isCategoryDashboard ? "primary" : "disabled"}
									className="drawerButton-category"
									onClick={this.props.isCategoryDashboard ? this.props.toggleCategoryDashboardManager : canEdit ? this.openModal : null}>
									<Icon className="drawerIcon-category" type="deployment-unit"/>
									{this.props.isCategoryDashboard ? this.props.managingDashboard ? utils.translate('componentConfigDrawer.info1') : utils.translate('componentConfigDrawer.info2') : utils.translate('componentConfigDrawer.info3')}
								</Button>{' '}
							</Row>
						</Tooltip>
					</FormGroup>

					<FormGroup>
						<BulkDelete
							{...this.props}
							pageIsEmpty={canEdit}
						/>
					</FormGroup>

					{this.props.managingDashboard && this.props.isCategoryDashboard &&
					<FormGroup>
						<Row>
							<Col md={{size: 6}}>
								<Tooltip placement="left" title={canEdit ? "" : utils.translate('componentConfigDrawer.deleteAlert')}>
									<Button
										type={canEdit ? "primary" : "disabled"}
										className={this.props.managingDashboard ? "drawerButton-category themeBuilderOpen" : "drawerButton-category themeBuilderHidden"}
										onClick={canEdit ? this.openModal : null}>
										<Icon className="drawerIcon-category" type="edit"/>
										{utils.translate('componentConfigDrawer.switchCategories')}
									</Button>{' '}
								</Tooltip>
							</Col>
							<Col md={{size: 6}}>
								<Tooltip placement="bottom" title={utils.translate('componentConfigDrawer.unbind1')}>
									<Button
										type={"primary"}
										className={this.props.managingDashboard ? "drawerButton-category themeBuilderOpen" : "drawerButton-category themeBuilderHidden"}
										onClick={() => this.openModal('remove')}>
										<Icon className="drawerIcon-category" type="close-circle"/>
										{utils.translate('componentConfigDrawer.unbind2')}
									</Button>{' '}
								</Tooltip>
							</Col>
						</Row>
					</FormGroup>}

					<AttributeEditor
						{...this.props}
					/>

					<FormGroup>
						<Row>
							<Button
								type="primary"
								className="drawerButton-theme"
								onClick={this.props.toggleThemeBuilder}>
									<FontAwesomeIcon className="drawerIcon" icon={['fab', 'css3']}/>
									{this.props.isThemeBuilderOpen ? utils.translate('componentConfigDrawer.close') : ''} {utils.translate('componentConfigDrawer.themeBuilder')}
							</Button>{' '}
						</Row>
					</FormGroup>

					<div className={this.props.isThemeBuilderOpen ? "themeBuilderOpen" : "themeBuilderHidden"}>
						<ThemeBuilder
							dapId={window.ENV.REACT_APP_ID}
							currentPage={this.props.currentPage}
							loadPage={this.props.loadPage}
							dpgId={this.props.dpgId}
							appTheme={this.props.appTheme}
						/>
					</div>

					<VersionSpan/>

					<CategorySelectorModal
						closeModal={this.closeModal}
						modalIsVisible={this.state.modalIsVisible}
						widgets={this.props.widgets}
						allCategories={this.props.allCategories}
						category={this.props.category}
						dpgId={this.props.dpgId}
						api_getCategory={this.props.api_getCategory}
						isCategoryDashboard={this.props.isCategoryDashboard}
						removingCategory={this.state.removingCategory}
						toggleCategoryDashboardManager={this.props.toggleCategoryDashboardManager}
					/>

	        	</Drawer>
			</>
        );
    }
}

ConfigDrawer.propTypes = {
	loadPage: PropTypes.func.isRequired,
	toggleThemeBuilder: PropTypes.func.isRequired,
	toggleDrawer: PropTypes.func.isRequired,
	isCategoryDashboard : PropTypes.bool.isRequired,
	widgets: PropTypes.array.isRequired,
	allCategories: PropTypes.array.isRequired,
	dpgId: PropTypes.string.isRequired,
	api_getCategory: PropTypes.func.isRequired,
	toggleCategoryDashboardManager: PropTypes.func.isRequired,
	category: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	isDrawerVisible: state.drawer.isDrawerVisible,
	isThemeBuilderOpen: state.drawer.isThemeBuilderOpen,
});

export default connect(mapStateToProps, { toggleThemeBuilder, toggleDrawer })(ConfigDrawer);
